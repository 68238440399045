import MetaView, { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";
import DocMetaView from "components/DocMetaView/DocMetaView";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuditDetailDTO, getAuditDetail, patchAuditDetail } from "app/api/auditApi";
import DocView from "components/DocView/DocView";
import { Box, Button, Step, StepButton, Stepper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import { AssessmentDetails } from "./pages/AssessmentDetails";
import { AssessmentResponses } from "./pages/AssessmentResponses";
import { AUDIT_METHODOLOGY, AUDIT_METHODOLOGY_TYPES, AUDIT_PAGE, AUDIT_STATUS } from "../audit/AuditTypes";
import { useMetaView } from "app/contexts/meta-view-context";
import { useTasks } from "app/contexts/tasks-context";
import CompletedIcon from "../../../../assets/images/assessment/statuses/completed.svg";
import DraftIcon from "../../../../assets/images/assessment/statuses/draft.svg";
import EditIcon from "../../../../assets/images/assessment/statuses/in-progress.svg";
import ReviewIcon from "../../../../assets/images/assessment/statuses/in-review.svg";
import AssessmentIcon from "../../../../assets/images/assessment/assessment.svg";
import SelfAssessmentIcon from "@mui/icons-material/AssignmentIndOutlined";
import InterviewIcon from "@mui/icons-material/AssignmentOutlined";
import { AssessmentQuestionnaire } from "./pages/AssessmentQuestionnaire";
import { AuditSummary } from "../audit/pages/AuditSummary";

const Assessment = () => {
  const { t } = useTranslation("audit_details");
  const id = useParams()?.id || "";
  const { page } = useParams();
  const [audit, setAudit] = useState<Partial<AuditDetailDTO> | null>(null);
  const [auditName, setAuditName] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activePage, setActivePage] = useState<string>("");
  const navigate = useNavigate();
  const [documentNotFound, setDocumentNotFound] = useState<boolean>(false);
  const { reloadDocRelatedTasks } = useTasks();
  const [metaViewContent, setMetaViewContent] = useState<React.ReactNode>(<></>);
  const { setInfo, setQuestionId, setSelectedTab } = useMetaView();
  const noWritePermission = useMemo(() => !audit?.pagesWithWrite?.includes?.("general"), [audit?.pagesWithWrite]);

  const reloadAuditCallback = useCallback(async () => {
    if (!id) {
      setAudit(null);
      setDocumentNotFound(true);
      return;
    }

    const auditDetail = await getAuditDetail({ id: id });
    if (auditDetail) {
      setAudit(auditDetail);
      setAuditName(auditDetail?.title || "");
      setDocumentNotFound(false);
      reloadDocRelatedTasks();
    } else {
      setDocumentNotFound(true);
    }
  }, [id, reloadDocRelatedTasks]);

  const updateQuestionnaireMetaView = useCallback((value: React.ReactNode) => {
    setMetaViewContent(value);
  }, []);

  const pagePermission = useCallback(
    (page: string) => {
      if (page === "general") return !audit?.pages?.includes?.("general");
      else if (page === "questionnaire") return !audit?.pages?.includes?.("template");
      else if (page === "responses") return !audit?.pages?.includes?.("questionnaire");
      else if (page === "summary") return !audit?.pages?.includes?.("result");
      else return true;
    },
    [audit?.pages]
  );

  const steps = useMemo(() => {
    return ["general", "questionnaire", "responses", "summary"]
      .filter(page => pagePermission(page) === false)
      .map((page, index) => ({
        label: page,
        number: index,
        disabled: pagePermission(page)
      }));
  }, [pagePermission]);

  useEffect(() => {
    const pageFromParam = steps.find(({ label }) => label === page);
    setActiveStep(pageFromParam?.number || 0);
    setActivePage(pageFromParam?.label || "");
  }, [page, steps]);

  useEffect(() => {
    reloadAuditCallback();
  }, [reloadAuditCallback]);

  const handleStep = useCallback(
    (step: number) => () => {
      setQuestionId(null);
      setSelectedTab(META_VIEW_TABS.ASSISTANT);
      const stepData = steps[step];
      if (stepData?.disabled) {
        return;
      }
      const pageName = stepData?.label || "general";
      navigate(`/audits/instances/${id}/${pageName}`);
    },
    [id, navigate, setQuestionId, setSelectedTab, steps]
  );

  const steppersEl = (
    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
      {steps.map(({ label, disabled }, index) => {
        return (
          <Step key={label}>
            <StepButton disabled={disabled} onClick={handleStep(index)}>
              {t(label)}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );

  /* TITLE */
  const onDocTitleFocus = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t(`titleInfoCardContent`)
    });
  }, [setInfo, t]);

  const onTitleChange = useCallback(
    async title => {
      await patchAuditDetail({ id: id, payload: { title } });
      await reloadAuditCallback();
    },
    [id, reloadAuditCallback]
  );

  const onStatusChange = useCallback(
    async status => {
      await patchAuditDetail({ id: id, payload: { status } });
      const updatedAudit: Partial<AuditDetailDTO> = { ...audit, status: status };
      setAudit(updatedAudit);
    },
    [id, audit]
  );

  const statusIconMapper = useMemo(
    () => ({
      [AUDIT_STATUS.COMPLETED]: <CompletedIcon />,
      [AUDIT_STATUS.DRAFT]: <DraftIcon />,
      [AUDIT_STATUS.EDIT]: <EditIcon />,
      [AUDIT_STATUS.REVIEW]: <ReviewIcon />
    }),
    []
  );
  const statuses = useMemo(() => {
    return Object.values(AUDIT_STATUS).map(status => ({
      id: status,
      label: t(`audit_status:${status.toLowerCase()}`),
      icon: statusIconMapper[status]
    }));
  }, [statusIconMapper, t]);

  const docProperties = useMemo(() => {
    return [
      { icon: <AssessmentIcon />, label: t("assessment_answerset:title") },
      audit?.methodology === AUDIT_METHODOLOGY.INTERVIEW
        ? { icon: <InterviewIcon />, label: t("audit_methodologies:interview") }
        : { icon: <SelfAssessmentIcon />, label: t("audit_methodologies:self-assessment") }
    ];
  }, [audit?.methodology, t]);

  /* TABS */
  const tabs = useMemo(() => {
    if (audit) {
      const allTabs: META_VIEW_TABS_TYPES[] = [META_VIEW_TABS.ASSISTANT];
      const step = steps[activeStep].label;

      if (step === AUDIT_PAGE.GENERAL) {
        allTabs.push(META_VIEW_TABS.TODOS);
        allTabs.push(META_VIEW_TABS.COMMENTS);
      }
      return allTabs;
    } else return [];
  }, [activeStep, audit, steps]);

  const step = steps[activeStep]?.label;
  return (
    <DocMetaView
      loading={audit === null}
      notFound={documentNotFound}
      collection={COLLECTIONS.AUDITS}
      metaViewContent={
        activePage === "questionnaire" && metaViewContent ? (
          metaViewContent
        ) : (
          <MetaView collection={COLLECTIONS.AUDITS} translationKey={"audit_details"} tabs={tabs} docId={id || ""} />
        )
      }
    >
      <DocView
        pagination={steppersEl}
        header={auditName}
        statusId={audit?.status}
        statuses={statuses}
        docProperties={docProperties}
        onHeaderChange={onTitleChange}
        onStatusChange={onStatusChange}
        disabled={noWritePermission}
        onHeaderFocus={onDocTitleFocus}
      >
        {step === AUDIT_PAGE.GENERAL && <AssessmentDetails audit={audit} onUpdate={reloadAuditCallback} />}
        {step === AUDIT_PAGE.QUESTIONNAIRE && (
          <AssessmentQuestionnaire
            onUpdate={reloadAuditCallback}
            auditId={id}
            updateQuestionnaireMetaView={updateQuestionnaireMetaView}
            onClickNext={handleStep(activeStep + 1)}
          />
        )}
        {step === AUDIT_PAGE.RESPONSES && (
          <AssessmentResponses
            auditId={id}
            auditMethodology={audit?.methodology as AUDIT_METHODOLOGY_TYPES}
            onClickNext={handleStep(activeStep + 1)}
          />
        )}
        {step === AUDIT_PAGE.SUMMARY && <AuditSummary auditId={id} />}
        <Box mt={5} display="flex" justifyContent="flex-end">
          {activeStep !== steps.length - 1 && (
            <Button variant="contained" color="primary" onClick={handleStep(activeStep + 1)}>
              {t("common:nextContinue")}
            </Button>
          )}
        </Box>
      </DocView>
    </DocMetaView>
  );
};

export default Assessment;
