import { LabelField } from "components/LabelField";
import React from "react";
import { QuestionProps } from "../../Question";

const LabelsQuestion = ({
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onBlur,
  orgUnitIds,
  onlyIntersectingOrgUnitIds,
  onFocus
}: QuestionProps) => {
  const _value: string[] = Array.isArray(value) ? value : [value as string];
  return (
    <LabelField
      selectedIDs={_value}
      onSelectionChanged={onChange}
      onBlur={onBlur}
      disabled={disabled}
      orgUnitIds={orgUnitIds}
      onlyIntersectingOrgUnitIds={onlyIntersectingOrgUnitIds}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default LabelsQuestion;
