import { v4 as uuidv4 } from "uuid";
import { COLLECTION_TYPES } from "app/collections";
import { sortBy } from "lodash-es";
import { getHasuraClientSDK } from "../api/hasuraApi";

export interface Section {
  readonly id: string;
  readonly createdBy: string;
  readonly collection: string;
  readonly docIds: string[];
  readonly orderWeight: number;
  readonly created: string;
  readonly updated: string;
  readonly name: string;
  readonly deleted: boolean;
}

export async function getAllSectionsForCollection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES
): Promise<Section[]> {
  const client = await getHasuraClientSDK();
  const response = await client.userOverviewSectionsGetByUserAndCollection({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection
  });

  return response.frontend_access_user_overview_section.map(
    it =>
      ({
        id: it.id,
        createdBy: it.created_by,
        collection: it.collection,
        docIds: it.doc_ids,
        orderWeight: it.order_weight,
        created: it.created_at,
        updated: it.updated_at,
        name: it.name,
        deleted: false // part of the query to fetch non deleted
      }) satisfies Section
  );
}

export async function getSingleSection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES,
  sectionId: string
): Promise<Section | null> {
  const client = await getHasuraClientSDK();
  const response = await client.userOverviewSectionGetById({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection,
    id: sectionId
  });
  if (!response.frontend_access_user_overview_section_by_pk) {
    return null;
  }

  return {
    id: response.frontend_access_user_overview_section_by_pk.id,
    createdBy: response.frontend_access_user_overview_section_by_pk.created_by,
    collection: response.frontend_access_user_overview_section_by_pk.collection,
    docIds: response.frontend_access_user_overview_section_by_pk.doc_ids,
    orderWeight: response.frontend_access_user_overview_section_by_pk.order_weight,
    created: response.frontend_access_user_overview_section_by_pk.created_at,
    updated: response.frontend_access_user_overview_section_by_pk.updated_at,
    name: response.frontend_access_user_overview_section_by_pk.name,
    deleted: !!response.frontend_access_user_overview_section_by_pk.deleted_at
  };
}

export async function createSection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES,
  name: string
): Promise<string> {
  const allSections = await getAllSectionsForCollection(tenantId, userId, collection);
  const sectionId = uuidv4();
  const client = await getHasuraClientSDK();
  await client.userOverviewSectionUpsert({
    collection: collection,
    id: sectionId,
    name: name,
    doc_ids: [],
    order_weight: allSections.length === 0 ? 100 : Math.max(...allSections.map(section => section.orderWeight)) + 100,
    deleted_at: null
  });

  return sectionId;
}

export async function reorderSection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES,
  sectionId: string,
  lowerEdgeSectionId?: string | null
): Promise<void> {
  // dragged section id must be defined
  if (!sectionId) {
    return;
  }
  let upperEdgeSectionOrderWeight, lowerEdgeSectionOrderWeight;

  const allSections = await getAllSectionsForCollection(tenantId, userId, collection);
  const allSectionSorted = sortBy(allSections, "orderWeight");

  // lower edge section id is defined
  if (lowerEdgeSectionId) {
    let upperEdgeSection, lowerEdgeSection;
    const indexOfLower = allSectionSorted.findIndex(section => section.id === lowerEdgeSectionId);
    if (indexOfLower < 0) {
      return;
    }

    if (indexOfLower === 0) {
      lowerEdgeSection = allSectionSorted[indexOfLower];
      upperEdgeSection = null;
    }

    if (indexOfLower > 0) {
      lowerEdgeSection = allSectionSorted[indexOfLower];
      upperEdgeSection = allSectionSorted[indexOfLower - 1];
    }

    lowerEdgeSectionOrderWeight = lowerEdgeSection?.orderWeight;
    upperEdgeSectionOrderWeight = upperEdgeSection
      ? upperEdgeSection.orderWeight
      : (lowerEdgeSection?.orderWeight || 0) - 100;
  }

  // upper edge section id is not defined
  // this is when the target is the last item at the bottom
  if (!lowerEdgeSectionId) {
    const lowerEdgeSection = allSectionSorted[allSectionSorted.length - 1];
    if (!lowerEdgeSection) {
      return;
    }
    lowerEdgeSectionOrderWeight = lowerEdgeSection.orderWeight;
    upperEdgeSectionOrderWeight = lowerEdgeSectionOrderWeight + 100;
  }

  const orderWeight = ((lowerEdgeSectionOrderWeight || 0) + (upperEdgeSectionOrderWeight || 0)) / 2;

  const client = await getHasuraClientSDK();
  await client.userOverviewSectionUpdateById({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection,
    id: sectionId,
    payload: {
      order_weight: orderWeight
    }
  });
}

export async function addItemToSection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES,
  sectionId: string,
  itemDocId: string
): Promise<void> {
  const existingSection = await getSingleSection(tenantId, userId, collection, sectionId);
  if (!existingSection) {
    return;
  }

  const client = await getHasuraClientSDK();
  await client.userOverviewSectionUpdateById({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection,
    id: sectionId,
    payload: {
      doc_ids: [...new Set([...existingSection.docIds, itemDocId])]
    }
  });
}

export async function removeItemFromSection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES,
  sectionId: string,
  itemDocId: string
): Promise<void> {
  const existingSection = await getSingleSection(tenantId, userId, collection, sectionId);
  if (!existingSection) {
    return;
  }

  const client = await getHasuraClientSDK();
  await client.userOverviewSectionUpdateById({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection,
    id: sectionId,
    payload: {
      doc_ids: existingSection.docIds.filter(it => it !== itemDocId)
    }
  });
}

export async function renameSection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES,
  sectionId: string,
  newName: string
): Promise<void> {
  const client = await getHasuraClientSDK();
  await client.userOverviewSectionUpdateById({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection,
    id: sectionId,
    payload: {
      name: newName
    }
  });
}

export async function deleteSection(
  tenantId: string,
  userId: string,
  collection: COLLECTION_TYPES,
  sectionId: string
): Promise<void> {
  const client = await getHasuraClientSDK();
  await client.userOverviewSectionUpdateById({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection,
    id: sectionId,
    payload: {
      deleted_at: new Date()
    }
  });
}
