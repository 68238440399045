import React, { useCallback, useEffect, useMemo } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { useMetaView } from "app/contexts/meta-view-context";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import MoveItemFolderSelector from "./../MoveItemFolderSelector";
import MoveItemConformation from "./../MoveItemConformation";
import DocumentOverviewRow from "components/Overview/collections/document/DocumentOverviewRow";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import Uploader from "../Uploader";
import { Box, Collapse, LinearProgress } from "@material-ui/core";
import { AccountNotice } from "app/pages/authentication/AccountNotice";
import { useDocumentPermissions } from "hook/useDocumentPermissions";
import { useDocumentMoveHandler } from "hook/useDocumentMove";
import { useDocumentUpload } from "hook/useDocumentUpload";

export default function DocumentOverview() {
  const { t, i18n } = useTranslation("documentCenter");
  const { setInfo } = useMetaView();
  const dispatch = useOverviewDispatch();
  const reloadOverview = useCallback(async () => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.DOCUMENTS, reload });
  }, [dispatch]);
  const { isFoldersEnabled, userHasFoldersWritePermission, userHasUploadPermission } = useDocumentPermissions();

  const { loading, onUploadStart, onUploadEnd } = useDocumentUpload(reloadOverview);
  const {
    destinationItem,
    handleMoveConfirmationClose,
    handleMoveConfirmationConfirm,
    handleMoveEnd,
    itemToMove,
    moveConfirmationModalOpen,
    openMoveModal,
    overviewItems,
    setDestinationItem,
    setItemToMove,
    setOpenMoveModal
  } = useDocumentMoveHandler(reloadOverview);

  // refresh info when language changes
  useEffect(() => {
    setInfo({ title: t("enterInfoHeader"), text: t("enterInfoContent") });
  }, [i18n.language, setInfo, t]);

  const toolbarActions = useMemo(() => [{ action: "sort" }], []);

  const getUploadAction = useMemo(() => {
    if (userHasUploadPermission) {
      return [{ action: OVERVIEW_ADD_TYPE.SINGLE, title: t("documentCenter:upload") }];
    }
    return [];
  }, [t, userHasUploadPermission]);

  const getFolderAction = useMemo(() => {
    if (isFoldersEnabled && userHasFoldersWritePermission) {
      return [{ action: OVERVIEW_ADD_TYPE.MULTIPLE, title: t("documentCenter:createFolder") }];
    }
    return [];
  }, [isFoldersEnabled, t, userHasFoldersWritePermission]);

  const addActions = useMemo(() => [...getUploadAction, ...getFolderAction], [getFolderAction, getUploadAction]);

  const defaultMetaView = useMemo(() => <MetaView translationKey={"attachmentsOverviewMetaView"} />, []);

  return (
    <DocMetaView metaViewContent={defaultMetaView}>
      <AccountNotice mt={2} mb={2} mr={2} ml={2} />
      <Collapse in={loading}>
        <Box mt={4}>
          <LinearProgress />
        </Box>
      </Collapse>
      <Overview
        addActions={addActions}
        collection={COLLECTIONS.DOCUMENTS}
        dynamicIndentation={true}
        header={t("documentCenter")}
        rowComponent={DocumentOverviewRow}
        selectable={false}
        setItemToMove={setItemToMove}
        setOpenMoveModal={setOpenMoveModal}
        shadowLoading={true}
        toolbarActions={toolbarActions}
        toolbarMode="tabs"
        showSelectAll={false}
      />
      <Uploader
        id="file-upload-input"
        onUploadStart={onUploadStart}
        onUploadEnd={onUploadEnd}
        disabled={!userHasUploadPermission}
      />
      {openMoveModal && itemToMove && (
        <MoveItemFolderSelector
          destinationItem={destinationItem}
          itemToMove={itemToMove}
          items={overviewItems || []}
          onMoveEnd={handleMoveEnd}
          setDestinationItem={setDestinationItem}
          setItemToMove={setItemToMove}
        />
      )}
      <MoveItemConformation
        onCLose={handleMoveConfirmationClose}
        onConfirm={handleMoveConfirmationConfirm}
        openModal={moveConfirmationModalOpen}
      />
    </DocMetaView>
  );
}
