import { Box } from "@mui/material";
import { TomStatus } from "../../../api/tomApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { TOM_STATUSES } from "../../../handlers/tomHandler";
import Question from "../../../../components/Question/Question";
import MultiAutocomplete from "../../../../components/MultiAutocomplete/MultiAutocomplete";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";

export interface TomModalData {
  readonly name: string | null;
  readonly status: TomStatus | null;
  readonly statusDate: Date | null;
  readonly description: string | null;
  readonly protectionObjectiveIds: string[];
  readonly labelIds: string[];
}

export interface TomContentProps {
  readonly data: TomModalData;
  readonly onDataChanged: (data: TomModalData) => void;
  readonly docOrgUnitIds?: string[];
  readonly disabled?: boolean;
}

export const TomContent = ({ data, docOrgUnitIds, onDataChanged, disabled }: TomContentProps) => {
  const { t } = useTranslation("tom_description_tab");

  const [name, setName] = useState<string>("");
  const [status, setStatus] = useState<TomStatus | null>(null);
  const [statusDate, setStatusDate] = useState<string | null>(null);
  const [description, setDescription] = useState<string>("");
  const [protectionObjectiveIds, setProtectionObjectiveIds] = useState<string[]>([]);
  const [labelIds, setLabelIds] = useState<string[]>([]);

  useEffect(() => {
    setName(data.name || "");
    setStatus(data.status);
    setStatusDate(data.statusDate ? new Date(data.statusDate).toISOString() : null);
    setDescription(data.description || "");
    setProtectionObjectiveIds(data.protectionObjectiveIds);
    setLabelIds(data.labelIds);
  }, [data.description, data.labelIds, data.name, data.protectionObjectiveIds, data.status, data.statusDate]);

  const onNameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
      onDataChanged({
        ...data,
        name: event.target.value
      });
    },
    [data, onDataChanged]
  );

  const onStatusChanged = useCallback(
    (status: TomStatus | null) => {
      setStatus(status);
      onDataChanged({
        ...data,
        status
      });
    },
    [data, onDataChanged]
  );

  const onStatusDateChanged = useCallback(
    (date: string | null) => {
      setStatusDate(date);
      onDataChanged({
        ...data,
        statusDate: date ? new Date(date) : null
      });
    },
    [data, onDataChanged]
  );

  const onDescriptionChanged = useCallback(
    (description: string) => {
      setDescription(description);
      onDataChanged({
        ...data,
        description
      });
    },
    [data, onDataChanged]
  );

  const onProtectionObjectiveIdsChanged = useCallback(
    (protectionObjectiveIds: string[]) => {
      setProtectionObjectiveIds(protectionObjectiveIds);
      onDataChanged({
        ...data,
        protectionObjectiveIds
      });
    },
    [data, onDataChanged]
  );

  const onLabelIdsChanged = useCallback(
    (labelIds: string[]) => {
      setLabelIds(labelIds);
      onDataChanged({
        ...data,
        labelIds
      });
    },
    [data, onDataChanged]
  );

  return (
    <Box>
      <Box mt={4}>
        <TextField
          id={"name"}
          required
          fullWidth={true}
          label={t("name")}
          variant="outlined"
          value={name}
          onChange={onNameChanged}
          disabled={disabled}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mt={4} alignContent="center" gap={1}>
        <Box flex={1} py={3}>
          <TomStatusEnumField status={status} onChange={onStatusChanged} disabled={disabled} />
        </Box>
        {status === "IMPLEMENTED" ? (
          <Box flex={1}>
            <Question
              qType={QUESTION_TYPE.DATE}
              questionId={"dueDate"}
              questionName={t("processes_overview:implementationDate")}
              value={statusDate}
              onChange={onStatusDateChanged}
              disabled={disabled}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.TEXT_EDITOR}
          questionName={t("description")}
          value={description}
          onChange={onDescriptionChanged}
          disabled={disabled}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.PROTECTION_OBJECTIVE}
          value={protectionObjectiveIds}
          onChange={onProtectionObjectiveIdsChanged}
          orgUnitIds={docOrgUnitIds}
          disabled={disabled}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.LABELS}
          value={labelIds}
          onChange={onLabelIdsChanged}
          orgUnitIds={docOrgUnitIds}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

const TomStatusEnumField = ({
  status,
  onChange,
  disabled
}: {
  status: TomStatus | null;
  onChange: (status: TomStatus | null) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const statuses = useMemo(() => Object.values(TOM_STATUSES), []);
  const getStatusLabel = useCallback(
    status => {
      return t(`tom_description_tab:status_${status}`, status);
    },
    [t]
  );

  return (
    <MultiAutocomplete
      id="status"
      label={t("processes_overview:status")}
      hasMultiSelect={false}
      selected={status}
      updateSelected={onChange}
      options={statuses}
      getOptionLabel={getStatusLabel}
      disabled={disabled}
    />
  );
};
