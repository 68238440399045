import { DataLocationDTO, getDataLocationsApi } from "../app/api/externalRecipientApi";
import useSWR from "swr";

const SWR_KEY = "dataLocations";

export function useDataLocations() {
  const { data, isValidating, mutate } = useSWR(SWR_KEY, () => getDataLocationsApi());
  return {
    dataLocationsLoaded: data && !isValidating,
    dataLocations: data?.dataLocations || emptyDataLocations,
    dataLocationsReload: mutate
  };
}

const emptyDataLocations: DataLocationDTO[] = [];
