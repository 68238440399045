import i18n from "app/i18n";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.infoCardUrl}/api/infocard`
});

export interface InfoCardProps {
  readonly id: string;
  readonly defaultValue: boolean;
  readonly language: string;
  readonly title: string;
  readonly description: string;
}

interface GetInfoCardProps {
  readonly id: string;
  readonly language?: string;
}

interface PostPatchInfoCardProps {
  readonly id: string;
  readonly language: string;
  readonly title: string;
  readonly description: string;
}

interface DeleteInfoCardProps {
  readonly id: string;
  readonly language: string;
}

export async function getInfoCardByIdApi({ id, language }: GetInfoCardProps): Promise<InfoCardProps | null> {
  const response = await axiosInstance.get(`/${id}`, {
    headers: {
      "Accept-Language": language || i18n.language
    }
  });
  return response?.data || null;
}

export async function postInfoCardByIdApi({
  id,
  language,
  title,
  description
}: PostPatchInfoCardProps): Promise<number> {
  const response = await axiosInstance.post(`/`, {
    id,
    language,
    title,
    description
  });
  return response.status;
}

export async function patchInfoCardByIdApi({
  id,
  language,
  title,
  description
}: PostPatchInfoCardProps): Promise<number> {
  const response = await axiosInstance.patch(`/${id}/${language}`, {
    title,
    description,
    language
  });
  return response.status;
}

export async function deleteInfoCardByIdApi({ id, language }: DeleteInfoCardProps): Promise<number> {
  const response = await axiosInstance.delete(`/${id}/${language}`);
  return response.status;
}
