import { useCallback } from "react";
import overviewBaseController, {
  OverviewItem,
  OverviewNewItem,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { COLLECTIONS } from "app/collections";
import {
  createTask,
  deleteRecurringTask,
  deleteTask,
  TYPES,
  updateRecurringTask,
  updateTaskInformation
} from "../../../handlers/tasksHandler";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import {
  getCollectionsFilter,
  getLabelsFilter,
  getTaskDoneIntervalFilter,
  getTaskPriorityFilter,
  getTaskStatusFilter
} from "app/pages/shared/Filters/filters";
import { useTranslation } from "react-i18next";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { AxiosInstance } from "axios";
import { NewTask } from "app/api/taskApi";
import { deleteGroupTaskApi } from "app/api/groupTaskApi";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";

interface TaskOverviewCollectionParams {
  readonly setSelectedRow: (row: OverviewItem | null) => void;
}

const TaskOverviewController = (axiosInstance: AxiosInstance, collectionParams: TaskOverviewCollectionParams) => {
  const { t } = useTranslation();
  const { auth } = useAuthentication();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.TASKS, undefined, [resourcesDecorator]);
  const dispatch = useOverviewDispatch();

  const getFilters = useCallback(
    data => {
      return [
        getTaskDoneIntervalFilter("doneInterval", t),
        getTaskPriorityFilter("priority", t),
        getTaskStatusFilter("status", t),
        getLabelsFilter("labelIds", data._resources, t),
        getCollectionsFilter("collection", data.collections, t)
      ].filter(notNull => notNull);
    },
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t("filter_criteria:aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t("filter_criteria:zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: t("filter_criteria:newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: t("filter_criteria:oldFirst")
      }
    ],
    [t]
  );

  const createTaskObject = (title: string): NewTask => ({
    type: TYPES.individual,
    dueAt: null,
    assigneeUID: auth?.uid || "",
    collection: "tasks",
    title: title || createOverviewItemDefaultName("tasks"),
    description: null,
    labels: [],
    priority: null,
    status: null
  });

  const getCurrentPage = () => {
    if (window.location.pathname.indexOf("other") > -1) return "other";
    else return "my";
  };

  const getPaginatedOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getPaginatedOverview(setup, `/${getCurrentPage()}/paginated`);
    if (!data) {
      return null;
    }

    collectionParams.setSelectedRow(data.selectedItem || null);
    return {
      ...data,
      sortings: getSortings(),
      filters: getFilters(data)
    };
  };

  const loadAllPaginatedPages = async (setup: OverviewSetup) => {
    const data = await baseController.loadAllPaginatedPages(setup, `/${getCurrentPage()}/paginated`);
    return data || null;
  };

  const updatePaginatedOverview = async (setup: OverviewSetup) => {
    const data = await baseController.updatePaginatedOverview(setup, `/${getCurrentPage()}/paginated`);
    if (!data) {
      return null;
    }

    collectionParams.setSelectedRow(data.selectedItem || null);
    return {
      ...data,
      sortings: getSortings(),
      filters: getFilters(data)
    };
  };

  const addItem = async ({ title, parentId }: OverviewNewItem) => {
    const taskId = await createTask({
      tenantId: auth?.tenantId || "",
      task: createTaskObject(title || ""),
      sectionKey: parentId
    });
    if (taskId) {
      await addToSeenItemsOfUserHook(COLLECTIONS.TASKS, taskId);
      window.history.pushState("", "", "/tasks/my/" + taskId);
    }
    return taskId;
  };

  const addItemAndGo = async (data?: OverviewNewItem) => {
    const taskData = createTaskObject(data?.title || "");
    const taskId = await createTask({ tenantId: auth?.tenantId || "", task: taskData });
    if (taskId) {
      await addToSeenItemsOfUserHook(COLLECTIONS.TASKS, taskId);
      window.history.pushState("", "", "/tasks/my/" + taskId);
    }

    return taskId;
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";

    const item = baseController.getById(id);
    if (item) {
      if (item.detailType === "RECURRING") {
        await updateRecurringTask(id, { title });
      } else if (item.detailType === "DEFAULT") {
        updateTaskInformation(auth?.tenantId || "", auth?.uid || "", id, { title });
      }
    }
  };

  const deleteItem = async (id: string) => {
    const item = baseController.getById(id);
    if (item) {
      if (item.detailType === "RECURRING") {
        await deleteRecurringTask(id);
      } else if (item.detailType === "GROUP") {
        await deleteGroupTaskApi(id);
      } else {
        await deleteTask(id);
      }

      if (window.location.pathname.endsWith(item.id)) {
        dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.TASKS, selectedId: null });
        window.history.pushState("", "", `/tasks/${getCurrentPage()}`);
      }
    }
  };

  const goToItem = () => {
    // do nothing
  };

  return {
    ...baseController,
    patchItem,
    deleteItem,
    goToItem,
    addItem,
    addItemAndGo,
    getPaginatedOverview,
    updatePaginatedOverview,
    loadAllPaginatedPages
  };
};

export default TaskOverviewController;
