import { RESOURCE_TYPES, translateResource } from "./resourceHandler";
import { getDataClassificationIdFromDataCategory, SPECIAL_CLASSIFICATIONS } from "./dataClassificationHandler";
import isString from "lodash-es/isString";
import { traverseMergedInto } from "./utility/mergedInto";

const unifyDataTypeAndCategoryTypeTuple = dataTypeOrCategoryTypeTuple => {
  return {
    dataTypeKey: dataTypeOrCategoryTypeTuple.dataTypeKey || dataTypeOrCategoryTypeTuple.type || null,
    dataCategoryKey: dataTypeOrCategoryTypeTuple.dataCategoryKey || dataTypeOrCategoryTypeTuple.category || null,
    personGroupKey: dataTypeOrCategoryTypeTuple.personGroupKey || dataTypeOrCategoryTypeTuple.personGroup || null,
    id: dataTypeOrCategoryTypeTuple.id || dataTypeOrCategoryTypeTuple.id || null,
    mergedInto: dataTypeOrCategoryTypeTuple.mergedInto || dataTypeOrCategoryTypeTuple.mergedInto || null,
    dataClassificationId:
      dataTypeOrCategoryTypeTuple.dataClassificationId || dataTypeOrCategoryTypeTuple.dataClassificationId || null,
    typeDeleted: !!dataTypeOrCategoryTypeTuple.typeDeleted,
    categoryDeleted: !!dataTypeOrCategoryTypeTuple.categoryDeleted,
    personGroupDeleted: !!dataTypeOrCategoryTypeTuple.personGroupDeleted
  };
};

const translateType = ({ t, dataType }) => {
  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  if (normalizedType.typeDeleted) {
    return tDeletedEntry({ t });
  }

  return t(`lists_data_types_categories_person_groups:${normalizedType.dataTypeKey}`, normalizedType.dataTypeKey);
};

export const translateCategory = ({ t, dataType }) => {
  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  if (normalizedType.categoryDeleted) {
    return tDeletedEntry({ t });
  }

  return t(
    `lists_data_types_categories_person_groups:${normalizedType.dataCategoryKey}`,
    normalizedType.dataCategoryKey
  );
};

export const translatePersonGroup = ({ t, dataType }) => {
  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  if (normalizedType.personGroupDeleted) {
    return tDeletedEntry({ t });
  }

  return t(`lists_data_types_categories_person_groups:${normalizedType.personGroupKey}`, normalizedType.personGroupKey);
};

const translateClassificationForDataType = ({ t, dataClassificationId, classifications }) => {
  const classification = traverseMergedInto(dataClassificationId, classifications);
  if (!classification) {
    return tDeletedEntry({ t });
  }

  return translateResource({ t, resourceType: RESOURCE_TYPES.DATA_CLASSIFICATION, nameKey: classification.nameKey });
};

const translateTypeClassification = ({ t, dataType, classifications }) => {
  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  if (!normalizedType.dataClassificationId) {
    return "";
  }

  return translateClassificationForDataType({
    t,
    dataClassificationId: normalizedType.dataClassificationId,
    classifications
  });
};

const translateCategoryClassification = ({ t, dataType, classifications, dataTypeTree }) => {
  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  const dataClassificationId = getDataClassificationIdFromDataCategory({
    personGroupKey: normalizedType.personGroupKey,
    dataCategoryKey: normalizedType.dataCategoryKey,
    dataTypeTree
  });

  if (!dataClassificationId) {
    return "";
  }

  if (dataClassificationId === SPECIAL_CLASSIFICATIONS.MIXED) {
    return translateResource({
      t,
      resourceType: RESOURCE_TYPES.DATA_CLASSIFICATION,
      nameKey: SPECIAL_CLASSIFICATIONS.MIXED
    });
  }

  return translateClassificationForDataType({
    t,
    dataClassificationId,
    classifications
  });
};

// Data Type - Type Classification [Data Category]
export const tDataTypeClassificationCategory = ({ t, dataType, classifications }) => {
  if (isString(dataType)) {
    return dataType;
  }

  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  if (normalizedType.typeDeleted) {
    return tDeletedEntry({ t });
  }

  const translatedCategory = translateCategory({ t, dataType });
  const translatedType = translateType({ t, dataType });
  const translatedClassification = translateTypeClassification({
    t,
    dataType,
    classifications
  });

  if (!translatedClassification) {
    return `${translatedType} [${translatedCategory}]`;
  }
  return `${translatedType} - ${translatedClassification} [${translatedCategory}]`;
};

// Person Group - [Data Category - Category Classification]
export const tPersonGroupDataCategoryClassification = ({ t, dataType, classifications, dataTypeTree }) => {
  if (isString(dataType)) {
    return dataType;
  }
  const translatedPersonGroup = translatePersonGroup({ t, dataType });
  const translatedCategory = translateCategory({ t, dataType });
  const translatedClassification = translateCategoryClassification({ t, dataType, classifications, dataTypeTree });

  if (!translatedClassification) {
    return `${translatedPersonGroup} [${translatedCategory}]`;
  }
  return `${translatedPersonGroup} [${translatedCategory} - ${translatedClassification}]`;
};

// Person Group - [Category Classification]
export const tPersonGroupClassification = ({ t, dataType, classifications, dataTypeTree }) => {
  if (isString(dataType)) {
    return dataType;
  }

  const translatedPersonGroup = translatePersonGroup({ t, dataType });
  const translatedClassification = translateCategoryClassification({ t, dataType, classifications, dataTypeTree });

  if (!translatedClassification) {
    return `${translatedPersonGroup}`;
  }
  return `${translatedPersonGroup} [${translatedClassification}]`;
};

// Data Type [Type Classification]
export const tDataTypeClassification = ({ t, dataType, classifications }) => {
  if (isString(dataType)) {
    return dataType;
  }

  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  if (normalizedType.typeDeleted) {
    return tDeletedEntry({ t });
  }

  const translatedType = translateType({ t, dataType });
  const translatedClassification = translateTypeClassification({
    t,
    dataType,
    classifications
  });

  if (!translatedClassification) {
    return `${translatedType}`;
  }
  return `${translatedType} [${translatedClassification}]`;
};

// Data Category [Category Classification]
export const tDataCategoryClassification = ({ t, dataType, classifications, dataTypeTree }) => {
  if (isString(dataType)) {
    return dataType;
  }

  const normalizedType = unifyDataTypeAndCategoryTypeTuple(dataType);
  if (normalizedType.categoryDeleted) {
    return tDeletedEntry({ t });
  }

  const translatedCategory = translateCategory({ t, dataType });
  const translatedClassification = translateCategoryClassification({ t, dataType, classifications, dataTypeTree });

  if (!translatedClassification) {
    return `${translatedCategory}`;
  }
  return `${translatedCategory} [${translatedClassification}]`;
};

// Data-Type/Data-Category/Person-Group. Does not handle deleted entry.
export const tSimpleTranslate = ({ t, typeCategoryOrPersonGroup }) => {
  return t(`lists_data_types_categories_person_groups:${typeCategoryOrPersonGroup}`, typeCategoryOrPersonGroup);
};

// "Deleted" or "Gelöscht"
export const tDeletedEntry = ({ t }) => t("lists_data_types_categories_person_groups:deletedEntry");
