import MultiAutocomplete from "./MultiAutocomplete/MultiAutocomplete";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { useResources } from "app/contexts/resource-context";
import { ResourceDTO } from "app/api/resourceApi";
import { removeAddText } from "app/pages/questionnaires/utils/helper-functions";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { RESOURCE_TYPE } from "app/handlers/resourceHandler";
import { useNonDeletableChipRenderTags } from "./MultiAutocomplete/MultiAutocompleteTags";
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete/Autocomplete";
import { useUserDepartments } from "../app/contexts/department-context";
import { naturalSortBy } from "../app/utils/naturalSort";
import { useSidebarSWR } from "../app/pages/shared/Sidebar/useSidebarUnseen";
import { useAuthentication } from "../app/handlers/authentication/authentication-context";

export const ResourceField = ({
  allowAdd,
  disabled,
  displayDeleted,
  docOrgUnitIds,
  onlyIntersectingOrgUnitIds,
  error,
  helperText,
  icon,
  id,
  isNotDeletable,
  label,
  multiSelect,
  notDeletableTooltip,
  renderOption,
  resourceType,
  sortOptionsByName,
  tooltip,
  value,
  whitelistedIDs,
  onAddCallback,
  onBlur,
  onChange,
  onChipClick,
  onFocus
}: ResourceFieldProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { resourcesLoaded, traverseMergedId, translateById, translate, create, resources } = useResources();

  const [loaded, setLoaded] = useState(false);
  const { departmentsLoaded, isPartOfUserDepartments, getDepartmentRecursively } = useUserDepartments();
  const [resourcesOfType, setResourcesOfType] = useState<Partial<ResourceDTO>[]>([]);
  const [selectableByUserResourceIds, setSelectableByUserResourceIds] = useState<string[]>([]);
  const { sidebarNewItemsMutate } = useSidebarSWR();
  const { auth } = useAuthentication();
  const userHasResourceAddPermission = auth?.permissions?.some(
    permission => permission === "resource_write_all" || permission === "resource_write_org"
  );

  const setAllStates = useCallback(() => {
    const resourcesOfType = resources[resourceType] || [];
    const filteredResourcesOfType = whitelistedIDs?.length
      ? resourcesOfType.filter(resourceOfType => whitelistedIDs.includes(resourceOfType.id))
      : resourcesOfType;

    const sortedResourcesOfType = sortOptionsByName
      ? naturalSortBy(filteredResourcesOfType, [it => translate(resourceType, it.nameKey || "")])
      : filteredResourcesOfType;

    setResourcesOfType(sortedResourcesOfType);
    if (docOrgUnitIds && docOrgUnitIds.length > 0) {
      const docOrgUnitIdsAndChildren = new Set(
        onlyIntersectingOrgUnitIds ? docOrgUnitIds : docOrgUnitIds.flatMap(getDepartmentRecursively).map(it => it.id)
      );
      setSelectableByUserResourceIds(
        sortedResourcesOfType
          .filter(resource => resource.orgUnitIds?.some(it => it === "*" || docOrgUnitIdsAndChildren.has(it)))
          .map(resource => resource.id || "")
      );
    } else {
      setSelectableByUserResourceIds(
        sortedResourcesOfType
          .filter(resource => isPartOfUserDepartments(...(resource.orgUnitIds || [])))
          .map(resource => resource.id || "")
      );
    }
    setLoaded(true);
  }, [
    isPartOfUserDepartments,
    resourceType,
    resources,
    sortOptionsByName,
    translate,
    whitelistedIDs,
    docOrgUnitIds,
    onlyIntersectingOrgUnitIds,
    getDepartmentRecursively
  ]);

  useEffect(() => {
    if (!resourcesLoaded || !departmentsLoaded) {
      return;
    }
    setAllStates();
  }, [
    resourceType,
    resourcesLoaded,
    translate,
    sortOptionsByName,
    whitelistedIDs,
    departmentsLoaded,
    isPartOfUserDepartments,
    resources,
    setAllStates
  ]);

  const addNewText = useMemo(() => t("questionnaires:add_pg"), [t]);
  const getOptionLabel = useCallback(
    itemIdOrMultiAutoCompleteHackForNewOption => {
      if (!itemIdOrMultiAutoCompleteHackForNewOption) {
        return itemIdOrMultiAutoCompleteHackForNewOption;
      }
      if (itemIdOrMultiAutoCompleteHackForNewOption.startsWith(addNewText)) {
        return itemIdOrMultiAutoCompleteHackForNewOption;
      }

      return translateById(resourceType, itemIdOrMultiAutoCompleteHackForNewOption);
    },
    [translateById, resourceType, addNewText]
  );

  const [existingResourceNames, setExistingResourceNames] = useState<Set<string>>(new Set());
  useEffect(() => {
    const allNames = resourcesOfType.flatMap(resourceOfType => [
      translate(resourceType, resourceOfType.nameKey || ""),
      resourceOfType.nameKey || ""
    ]);
    setExistingResourceNames(new Set(allNames));
  }, [resourcesOfType, translate, resourceType]);
  const isNameAlreadyExisting = useCallback(
    newName => {
      if (!allowAdd) {
        return true;
      }

      return existingResourceNames.has(newName) || existingResourceNames.has(translate(resourceType, newName));
    },
    [allowAdd, translate, resourceType, existingResourceNames]
  );

  const [filteredValue, setFilteredValue] = useState<string | string[] | null>(value);
  useEffect(() => {
    if (!resourcesLoaded) {
      return;
    }

    const filterValue = (resourceId: string) => {
      const traversedResourceId = traverseMergedId(resourceType, resourceId);
      if (traversedResourceId) {
        return traversedResourceId;
      }

      if (displayDeleted) {
        return resourceId;
      }
      return null;
    };

    if (Array.isArray(value)) {
      const valueWithoutMergedResourceId = value
        .map(filterValue)
        .filter((resourceId): resourceId is string => !!resourceId);
      setFilteredValue([...new Set(valueWithoutMergedResourceId)]);
      return;
    }
    setFilteredValue(filterValue(value));
  }, [resourcesLoaded, resourceType, value, traverseMergedId, displayDeleted]);

  const addNewResource = useCallback(
    async multiAutoCompleteOptions => {
      // the last option is the newly added option
      const newItemName = removeAddText(
        multiAutoCompleteOptions[multiAutoCompleteOptions.length - 1],
        addNewText
      ).trim();

      if (isNameAlreadyExisting(newItemName)) {
        const errorMessage = t(`${newItemName} ${t("error_messages:generic_already_exists")}`);
        enqueueSnackbar(errorMessage, { variant: "error" });
        return;
      }

      const createdResourceId = await create(resourceType, newItemName);
      if (multiSelect) {
        onChange(Array.isArray(filteredValue) ? [...filteredValue, createdResourceId] : [createdResourceId]);
      } else {
        onChange(createdResourceId);
      }

      await sidebarNewItemsMutate();

      onAddCallback?.(createdResourceId);
    },
    [
      addNewText,
      isNameAlreadyExisting,
      create,
      resourceType,
      multiSelect,
      sidebarNewItemsMutate,
      onAddCallback,
      t,
      enqueueSnackbar,
      onChange,
      filteredValue
    ]
  );

  const [singleSelectInputField, setSingleSelectInputField] = useState("");
  useEffect(() => {
    if (!multiSelect) {
      const optionLabel = getOptionLabel(value);
      setSingleSelectInputField(optionLabel || "");
    }
  }, [multiSelect, value, getOptionLabel]);
  const onInputChange = useCallback((event, value) => {
    setSingleSelectInputField(value);
  }, []);

  const itemRenderTags = useNonDeletableChipRenderTags({
    getLabel: getOptionLabel,
    getTooltip: tooltip,
    isNotDeletable: isNotDeletable,
    getNonDeletableTooltip: notDeletableTooltip,
    disabled,
    onClick: onChipClick
  });

  const renderOptionCallback = useCallback(
    (resourceId: string, state: AutocompleteRenderOptionState): React.ReactNode => {
      return renderOption?.(resourceId, getOptionLabel(resourceId), state);
    },
    [renderOption, getOptionLabel]
  );

  if (!loaded) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <MultiAutocomplete
      id={id}
      icon={icon}
      disabled={disabled}
      label={label}
      hasMultiSelect={multiSelect}
      selected={filteredValue}
      updateSelected={onChange}
      options={selectableByUserResourceIds}
      updateOptions={allowAdd ? addNewResource : undefined}
      addText={userHasResourceAddPermission ? addNewText : undefined}
      newOptionEntryAlreadyExists={isNameAlreadyExisting}
      getOptionLabel={getOptionLabel}
      onFocus={onFocus}
      onBlur={onBlur}
      inputValue={multiSelect ? singleSelectInputField : undefined}
      onInputChange={multiSelect ? onInputChange : undefined}
      renderTags={itemRenderTags}
      renderOption={renderOption ? renderOptionCallback : undefined}
      error={error}
      helperText={helperText}
    />
  );
};

export interface ResourceFieldProps {
  readonly id?: string;
  readonly value: string | string[];
  readonly resourceType: RESOURCE_TYPE;
  readonly onChange: (values: string | string[] | null) => void;
  readonly label: string;
  readonly multiSelect?: boolean;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
  readonly disabled?: boolean;
  readonly allowAdd?: boolean;
  readonly whitelistedIDs?: string[];
  readonly sortOptionsByName?: boolean;
  readonly icon?: React.ReactNode;
  readonly isNotDeletable?: (resourceId: string) => boolean;
  readonly notDeletableTooltip?: (resourceId: string) => string;
  readonly tooltip?: (resourceId: string) => string;
  readonly onChipClick?: (resourceId: string) => void;
  readonly renderOption?: (
    resourceId: string,
    translated: string,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  readonly onAddCallback?: (newResourceId: string) => void;
  readonly displayDeleted?: boolean;
  readonly docOrgUnitIds?: string[];
  readonly onlyIntersectingOrgUnitIds?: boolean;
  readonly error?: boolean;
  readonly helperText?: string;
}

ResourceField.defaultProps = {
  multiSelect: true,
  disabled: false,
  allowAdd: false,
  whitelistedIDs: [],
  sortOptionsByName: true,
  docOrgUnitIds: []
};
