import React, { useCallback, useMemo } from "react";
import { QuestionProps } from "../../Question";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useTranslation } from "react-i18next";

const InternalDataRecipientsQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onBlur,
  onChange,
  onFocus,
  orgUnitIds,
  onlyIntersectingOrgUnitIds
}: QuestionProps) => {
  const { t } = useTranslation("questionnaires");
  const _value: string[] = useMemo(() => (Array.isArray(value) ? value : ([value] as string[])), [value]);
  const onChangeCallback = useCallback(
    (ids: string | string[] | null) => {
      onChange?.(ids);
    },
    [onChange]
  );
  return (
    <ResourceField
      value={_value}
      resourceType={RESOURCE_TYPES.INTERNAL_RECIPIENT}
      label={questionName || t("dataRecipients:internal")}
      onChange={onChangeCallback}
      onFocus={onFocus}
      onBlur={onBlur}
      allowAdd={true}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onlyIntersectingOrgUnitIds={onlyIntersectingOrgUnitIds}
      docOrgUnitIds={orgUnitIds}
    />
  );
};

export default InternalDataRecipientsQuestion;
