import { FilterItemProps } from "../../../../components/Overview/controllers/overviewBaseController";

export const getStandardFilter = (input: {
  readonly name: string;
  readonly filterField: string;
  readonly standardFilters: { readonly id: string; readonly name: string }[];
}): FilterItemProps => {
  return {
    filterTree: [
      {
        checkable: false,
        name: input.name,
        children: input.standardFilters.map(l => ({ name: l.name, id: l.id }) as any)
      }
    ],
    filterField: input.filterField
  };
};
