import { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash-es";
import { getDocumentFolderMetaDataApi, moveDocumentFileOrFolderApi } from "app/api/file-storage/documentApi";
import { OverviewItem, OverviewNewItem } from "components/Overview/controllers/overviewBaseController";
import { useOverviewData } from "hook/useOverviewData";
import { useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import overviewControllerFabric from "components/Overview/controllers/overviewControllerFabric";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

function useDocumentMoveHandler(reloadOverview: () => void) {
  const { t } = useTranslation("documentCenter");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useOverviewDispatch();
  const { documents: overviewSetup } = useOverviewState();
  const [controller] = useState(overviewControllerFabric(COLLECTIONS.DOCUMENTS, undefined));
  const { overviewData } = useOverviewData({
    controller,
    overviewSetup,
    dispatch,
    collection: COLLECTIONS.DOCUMENTS,
    collectionParams: undefined,
    paginated: false
  });

  const [itemToMove, setItemToMove] = useState<OverviewNewItem | null>(null);
  const [destinationItem, setDestinationItem] = useState<OverviewNewItem | null>(null);
  const [moveConfirmationModalOpen, setMoveConfirmationModalOpen] = useState<boolean>(false);
  const [openMoveModal, setOpenMoveModal] = useState<boolean>(false);
  const [overviewItems, setOverviewItems] = useState<OverviewItem[]>([]);

  const moveCallback = useCallback(async () => {
    if (itemToMove && destinationItem) {
      try {
        await moveDocumentFileOrFolderApi(itemToMove.path, destinationItem.path);
        setItemToMove(null);
        setDestinationItem(null);
        reloadOverview();
      } catch (e: any) {
        if (e?.response?.status === 409) {
          enqueueSnackbar(
            !itemToMove.fileType ? t("error_messages:folder_name_conflict") : t("error_messages:file_name_conflict"),
            { variant: "error" }
          );
        }
        if (e?.response?.status === 403) {
          enqueueSnackbar(t("error_messages:insufficient_permissions"), { variant: "error" });
        } else {
          throw e;
        }
      } finally {
        setMoveConfirmationModalOpen(false);
        setItemToMove(null);
        setDestinationItem(null);
        reloadOverview();
      }
    }
  }, [itemToMove, destinationItem, reloadOverview, enqueueSnackbar, t]);

  const handleMoveEnd = useCallback(async () => {
    if (itemToMove && destinationItem) {
      if (!itemToMove.fileType && !destinationItem.fileType) {
        const currentMetaData = await getDocumentFolderMetaDataApi(itemToMove.path);
        if (!isEqual(currentMetaData.orgUnitIds, ["*"])) {
          setMoveConfirmationModalOpen(true);
        } else {
          await moveCallback();
        }
      } else {
        await moveCallback();
      }
    }
  }, [destinationItem, itemToMove, moveCallback]);

  const handleMoveConfirmationClose = useCallback(() => {
    setMoveConfirmationModalOpen(false);
    setItemToMove(null);
    setDestinationItem(null);
  }, []);

  const handleMoveConfirmationConfirm = useCallback(async () => {
    await moveCallback();
    setMoveConfirmationModalOpen(false);
  }, [moveCallback]);

  useEffect(() => {
    if (openMoveModal) {
      setOverviewItems(overviewData?.responseItems || []);
    }
  }, [openMoveModal, overviewData, overviewItems]);

  return {
    destinationItem,
    handleMoveConfirmationClose,
    handleMoveConfirmationConfirm,
    handleMoveEnd,
    itemToMove,
    moveConfirmationModalOpen,
    openMoveModal,
    overviewItems,
    setDestinationItem,
    setItemToMove,
    setOpenMoveModal,
    setOverviewItems
  };
}

export { useDocumentMoveHandler };
