export const DATA_SUBJECT_REQUEST_TYPES = {
  ACCESS: "access",
  RECTIFICATION: "rectification",
  DELETION: "deletion",
  RESTRICTION: "restriction",
  DATA_PORTABILITY: "data_portability",
  OBJECT_ABS_1: "object_abs_1",
  OBJECT_ABS_2: "object_abs_2",
  WITHDRAWAL: "withdrawal"
};

export const sortTasks = (requestTypes: string[], tasks: any[]): any[] => {
  const tasksSortedByRequestType = groupTasksBasedOnRequestType(requestTypes, tasks);
  tasksSortedByRequestType.sort((a, b) => getAverageOfCreationTimestamp(a) - getAverageOfCreationTimestamp(b));
  return tasksSortedByRequestType.flat();
};

const getAverageOfCreationTimestamp = (element: any) => {
  if (Array.isArray(element)) {
    const aggregatedTimestamp = element
      .map(e => new Date(e.createdAt).getTime())
      .reduce((acc, value) => acc + value, 0);
    return aggregatedTimestamp / element.length;
  }

  return new Date(element.createdAt).getTime() || 0;
};

const groupTasksBasedOnRequestType = (requestTypes: string[], tasks: any[]) => {
  const sortedTasksForRequestType: any[] = [];
  requestTypes.forEach(type => {
    const requestTypeTasks: any[] = [];
    const taskKeys = getTaskKeysForRequestType(type);
    taskKeys.forEach(key => {
      const taskWithQuestionId = tasks.find(t => t.questionId === key);
      if (taskWithQuestionId) {
        requestTypeTasks.push(taskWithQuestionId);
      }
    });

    if (requestTypeTasks.length) {
      sortedTasksForRequestType.push(requestTypeTasks);
    }
  });

  const flattenedTaskForRequestType = sortedTasksForRequestType.flat();
  const restOfTasks = tasks.filter(t => !flattenedTaskForRequestType.includes(t));
  return [...sortedTasksForRequestType, ...restOfTasks];
};

const getTaskKeysForRequestType = (requestType: string) => {
  switch (requestType) {
    case DATA_SUBJECT_REQUEST_TYPES.ACCESS:
      return [
        "access_gather_personal_data",
        "access_preparation_data",
        "access_approval_dpa",
        "access_send_to_subject"
      ];
    case DATA_SUBJECT_REQUEST_TYPES.RECTIFICATION:
      return [
        "rectification_identification_personal_data",
        "rectification_rectification",
        "rectification_written_confirmation_subject"
      ];
    case DATA_SUBJECT_REQUEST_TYPES.DELETION:
      return [
        "deletion_identify_data_locations",
        "deletion_alignment_with_legal",
        "deletion_delete_data",
        "deletion_written_confirmation_subject",
        "deletion_filing"
      ];
    case DATA_SUBJECT_REQUEST_TYPES.RESTRICTION:
      return [
        "restriction_identification_personal_data",
        "restriction_compare_scope",
        "restriction_preliminary_restriction",
        "restriction_written_confirmation_subject",
        "deletion_filing"
      ];
    case DATA_SUBJECT_REQUEST_TYPES.DATA_PORTABILITY:
      return [
        "data_portability_gather_personal_data",
        "data_portability_prepare_data",
        "data_portability_dpo_approval",
        "data_portability_send_to_subject",
        "deletion_filing"
      ];
    case DATA_SUBJECT_REQUEST_TYPES.OBJECT_ABS_1:
      return [
        "object_req_1_validation",
        "object_req_1_examination",
        "object_req_1_block",
        "object_req_1_written_confirmation_subject",
        "deletion_filing"
      ];
    case DATA_SUBJECT_REQUEST_TYPES.OBJECT_ABS_2:
      return [
        "object_req_2_verify_direct_marketing",
        "object_req_2_block",
        "object_req_2_written_confirmation_subject"
      ];
    case DATA_SUBJECT_REQUEST_TYPES.WITHDRAWAL:
      return [
        "withdrawal_consent_verification",
        "withdrawal_add_to_blacklist",
        "withdrawal_written_confirmation_subject"
      ];
    default:
      return [];
  }
};
