import React, { useCallback } from "react";
import { QuestionProps } from "../../Question";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useTranslation } from "react-i18next";

const ProtectionObjectiveQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  isNotDeletableChip,
  notDeletableChipTooltip,
  onFocus,
  onBlur,
  onChange,
  orgUnitIds,
  onlyIntersectingOrgUnitIds
}: QuestionProps) => {
  const { t } = useTranslation("questionnaires");
  const _value: string[] = Array.isArray(value) ? value : [value as string];
  const onChangeCallback = useCallback(
    (protectionObjectiveIds: string | string[] | null) => {
      onChange?.(protectionObjectiveIds);
    },
    [onChange]
  );
  return (
    <ResourceField
      label={questionName || t("risk_general_page:protectionObjectiveIds")}
      resourceType={RESOURCE_TYPES.PROTECTION_OBJECTIVE}
      onFocus={onFocus}
      onBlur={onBlur}
      value={_value}
      onChange={onChangeCallback}
      isNotDeletable={isNotDeletableChip}
      notDeletableTooltip={notDeletableChipTooltip}
      disabled={disabled}
      error={error}
      helperText={helperText}
      docOrgUnitIds={orgUnitIds}
      onlyIntersectingOrgUnitIds={onlyIntersectingOrgUnitIds}
    />
  );
};

export default ProtectionObjectiveQuestion;
