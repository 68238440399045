import { useAuthentication } from "app/handlers/authentication/authentication-context";
import useSWR, { mutate } from "swr";
import DataLoader from "dataloader";
import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { ExecutionMode, MultilingualServiceApi } from "./generated/multilingual-service";
import { SWR_KEYS } from "app/swrKeys";
import { isAxiosError } from "axios";
import { NEW_TRANSLATION_KEY } from "components/Multilingual/MultilingualModal";
import useDebouncedMemo from "app/utils/useDebouncedMemo";

export const multilingualClient = new MultilingualServiceApi(
  undefined,
  apiEndpoints.multilingualUrl,
  defaultOTCAuthenticatedAxios()
);

// This may look like magic, because it is. If you see 23 resources in your screen,
// the translations for all of them are fetched in this single request, instead of making 23 individual requests.
const batchTranslationsLoader = new DataLoader(
  async (
    keys: readonly {
      readonly languageCode: string;
      readonly translationKey: string;
    }[]
  ) => {
    // @ts-expect-error - readonly stuff, should we care here
    const translations = await multilingualClient.batchLoadTranslations({ translations: keys });
    return translations.data;
  },
  { cache: false, maxBatchSize: 30 }
);

const useMultilingualTranslation = (translationKey: string) => {
  const { auth } = useAuthentication();
  const translations = useSWR(
    [SWR_KEYS.multilingual, translationKey],
    async () => {
      if (translationKey === "") {
        return {};
      }
      const response = await multilingualClient.getTranslation(translationKey);
      return response.data;
    },
    {
      isPaused: () => !auth
    }
  );

  return translations;
};

/**
 * Load translations from the cache or fetch them from the server, mandatory when the translationkey is being edited
 * So the user can see the most updated translations from the frontend cache
 */
export const useTranslatedKey = (args: { translationKey: string; languageCode: string; disabled?: boolean }) => {
  const { translationKey, languageCode } = args;
  const translation = useSWR(
    [SWR_KEYS.multilingual, languageCode, translationKey],
    async () => {
      const response = await batchTranslationsLoader.load({ languageCode, translationKey });
      return response?.value;
    },
    {
      isPaused: () => !!args.disabled
    }
  );

  return translation;
};

export class MultilingualConflictError extends Error {
  constructor(public languageCodes: string[]) {
    super("Conflict found in languages: " + languageCodes.join(", "));
  }
}

export const useMultilingualEditor = (args: { translationKey: string }) => {
  const translations = useMultilingualTranslation(args.translationKey);
  const updateTranslations = async (
    newTranslations: Record<string, string>,
    newTranslationKey?: string,
    mode?: ExecutionMode
  ) => {
    const key = newTranslationKey || args.translationKey;
    if (key === "") return;
    const existingTranslations = translations.data || {};
    const duplicatesFoundInLanguages: string[] = [];
    await Promise.all(
      Object.entries(newTranslations)
        .filter(([languageCode, value]) => existingTranslations[languageCode] !== value)
        .map(async ([languageCode, value]) => {
          return multilingualClient
            .updateTranslation(key, languageCode, { value }, undefined, mode ?? undefined)
            .then(() => {
              mutate([SWR_KEYS.multilingual, languageCode, key], value, false);
            })
            .catch(e => {
              if (isAxiosError(e) && e.response?.status === 409) {
                duplicatesFoundInLanguages.push(languageCode);
              } else {
                throw e;
              }
            });
        })
    );
    if (duplicatesFoundInLanguages.length > 0) {
      throw new MultilingualConflictError(duplicatesFoundInLanguages);
    }
    translations.mutate({
      ...existingTranslations,
      ...newTranslations
    });
  };

  return {
    translations: translations.data || {},
    isLoading: translations.isLoading,
    updateTranslations
  };
};

export const useSupportedLanguages = () => {
  const supportedLanguages = useSWR([SWR_KEYS.multilingual, "languages"], async () => {
    const response = await multilingualClient.getTenantLanguages();
    return response.data;
  });

  return supportedLanguages;
};

export const useTranslatedKeysInLng = (args: { translationKeys: string[]; languageCode: string }) => {
  const { translationKeys, languageCode } = args;
  const translations = useSWR(
    [SWR_KEYS.multilingual, languageCode, translationKeys],
    async () => {
      const response = await batchTranslationsLoader.loadMany(
        translationKeys.map(translationKey => ({ languageCode, translationKey }))
      );
      return response;
    },
    {
      isPaused: () => !languageCode
    }
  );

  return translations;
};

export const useMissingTranslations = (translationKey: string) => {
  const missingTranslations = useSWR(
    [SWR_KEYS.multilingual, translationKey, "missing-translations"],
    async () => {
      const response = await multilingualClient.getMissingTranslations(translationKey);
      return response.data;
    },
    {
      revalidateOnFocus: false
    }
  );

  return { untranslatedLngs: missingTranslations.data || [], reloadMissingTranslations: missingTranslations.mutate };
};

export const useFindTranslationDuplicates = (
  translationKey: string,
  translations: Record<string, string>,
  namespace: string
) => {
  const debouncedTranslations = useDebouncedMemo(() => translations, [translations], 300);
  return useSWR(
    [SWR_KEYS.multilingual, translationKey, namespace, "duplicates", debouncedTranslations],
    async () => {
      if (!Object.keys(debouncedTranslations).find(() => true)) {
        return {};
      }
      const response = await multilingualClient.findDuplicatesInNamespace(namespace, {
        translations: debouncedTranslations,
        excludeTranslationKey: translationKey
      });
      return response.data;
    },
    {
      keepPreviousData: true
    }
  );
};
