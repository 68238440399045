import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssetShortDTO } from "../types/AssetTypes";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { tDeletedEntry } from "../../../handlers/dataTypeTranslatorHandler";

interface AiAssetSelectProps {
  readonly availableAssetsById: Map<string, AssetShortDTO>;
  readonly disabled?: boolean;
  readonly selectedAssetId?: string;
  readonly onChange?: (assetId: string) => void;
  readonly onAddNewOption?: (newOption: string) => void;
}

/**
 * Component for selecting an one asset.
 * @param availableAssetsById The available assets.
 * @param disabled Whether the component is disabled.
 * @param selectedAssetId The selected asset id.
 * @param onChange The function to call when the value changes.
 * @param onAddNewOption The function to call when a new option is added.
 * @returns The asset select component.
 */
const AiAssetSelect = ({
  availableAssetsById,
  disabled,
  selectedAssetId,
  onChange,
  onAddNewOption
}: AiAssetSelectProps) => {
  const { t } = useTranslation("asset_details");
  const [currentSelectedId, setCurrentSelectedId] = useState<string | undefined>(selectedAssetId);

  useEffect(() => {
    if (selectedAssetId) {
      setCurrentSelectedId(selectedAssetId);
    }
  }, [selectedAssetId]);

  const options = useMemo(() => [...availableAssetsById.keys()], [availableAssetsById]);

  const onSelectAsset = useCallback(
    assetId => {
      if (!options.includes(assetId)) {
        return;
      }
      onChange?.(assetId);
    },
    [onChange, options]
  );
  const getOptionLabel = useCallback(
    value => {
      if (value.includes(t("asset_details:add_text"))) {
        // to not display "deleted" when writing new value
        return value;
      }
      return availableAssetsById.get(value)?.name || tDeletedEntry({ t });
    },
    [availableAssetsById, t]
  );

  const { auth } = useAuthentication();
  const userHasAssetWritePermission = auth?.permissions?.some(
    permission => permission === "asset_write_org" || permission === "asset_write_all"
  );

  const updateOptions = useCallback(
    async updatedOptions => {
      const newOptionName = updatedOptions.find((newName: string) => !options.includes(newName));
      if (newOptionName) {
        onAddNewOption?.(newOptionName);
      }
    },
    [onAddNewOption, options]
  );

  return (
    <MultiAutocomplete
      addText={userHasAssetWritePermission ? t("add_text") : undefined}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      hasMultiSelect={false}
      id={"aiAssetSelect"}
      label={t("search_single")}
      options={options}
      placeholder={t("search_single")}
      selected={currentSelectedId}
      updateSelected={onSelectAsset}
      updateOptions={updateOptions}
    />
  );
};

export default AiAssetSelect;
