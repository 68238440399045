import React, { useCallback, useMemo } from "react";
import { QuestionProps } from "../../Question";
import { useDataTypeTree } from "app/api/dataAssetApi";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";
import { DataCategory } from "app/api/generated/asset-service";

const DataCategoryQuestion = ({
  questionName,
  value,
  disabled,
  multiSelect,
  multiSelectHiddenIds,
  personGroupId,
  error,
  helperText,
  onChange,
  onFocus
}: QuestionProps) => {
  const { t } = useTranslation("dataCategory");
  const { dataById } = useDataTypeTree();
  const _value = multiSelect ? (value as string[]) : (value as string);

  const dataCategoryOptionIds = useMemo(() => {
    if (personGroupId) {
      return dataById?.personGroups[personGroupId].dataCategories
        .map(({ id }) => id)
        .filter(id => id && !(multiSelectHiddenIds || []).includes(id)) as string[];
    } else return [];
  }, [dataById?.personGroups, multiSelectHiddenIds, personGroupId]);

  const dataCategoryMap = useMemo(() => {
    if (personGroupId) {
      return ((dataById?.personGroups[personGroupId].dataCategories || []) as DataCategory[]).reduce<
        Record<string, DataCategory>
      >((acc, next) => ({ ...acc, [next.id || ""]: next }), {});
    } else return {};
  }, [dataById?.personGroups, personGroupId]);

  const getDataCategoryOptionsLabel = useCallback(
    id =>
      `${t(
        `lists_data_types_categories_person_groups:${dataCategoryMap?.[id]?.dataCategoryKey || ""}`,
        dataCategoryMap?.[id]?.dataCategoryKey || ""
      )}`,
    [dataCategoryMap, t]
  );

  return (
    <MultiAutocomplete
      label={questionName || t("dataCategory:inputLabel")}
      options={dataCategoryOptionIds}
      getOptionLabel={getDataCategoryOptionsLabel}
      hasMultiSelect={multiSelect}
      onChange={onChange}
      disabled={disabled}
      selected={_value}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default DataCategoryQuestion;
