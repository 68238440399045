import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { TaskStatus } from "./taskApi";

export interface GroupTask {
  readonly id: string;
  readonly createdAt: Date;
  readonly tenantId: string;
  readonly taskId: string;
  readonly groupId: string;
  readonly updatedAt: Date;
  readonly creatorUID: string;
  readonly assigneeUID: string;
  readonly status: TaskStatus;
  readonly seen: boolean | null;
  readonly title: string;
  readonly description: string;
  readonly priority: string;
  readonly dueAt: Date;
  readonly collection: string | null;
  readonly questionId: string | null;
  readonly type: string | null;
  readonly message: string | null;
}

export interface UpdateGroupTask {
  readonly status?: string | null;
  readonly seen?: boolean | null;
  readonly collection?: string | null;
}

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.taskUrl}/api/v1/groupTasks`
});

export async function getGroupTasksApi(param: { collectionId: string }) {
  const response = await axiosInstance.get("/", {
    params: { param }
  });
  return response.data.tasks;
}

export async function updateGroupTaskApi(id: string, { seen, status }: UpdateGroupTask = {}) {
  const response = await axiosInstance.patch(`/` + id, {
    seen,
    status
  });
  return response.data;
}

export async function getGroupTaskByIdApi(id: string) {
  const response = await axiosInstance.get("/" + id);
  return response ? response.data : null;
}

export async function deleteGroupTaskApi(id: string) {
  return axiosInstance.delete("/" + id);
}

export async function getAllGroupStatusByTaskIdApi(id: string) {
  const axiosResponse = await axiosInstance.get(`/stats/${id}`);
  return axiosResponse.data;
}
