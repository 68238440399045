import React, { useCallback, useMemo, useState } from "react";
import ListViewItem, { ItemStatus, RowAction } from "components/ListViewItem/ListViewItem";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { Box, CircularProgress } from "@mui/material";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { WarningAmber } from "@mui/icons-material";
import { useAITechDocStatuses } from "../../../../app/pages/ai-tech-docs/overview/useAITechDocStatuses";
import { useAuthentication } from "../../../../app/handlers/authentication/authentication-context";
import AddIcon from "@mui/icons-material/Add";
import { AITechDocAssetLinkError, createAITechDocAndLinkToAsset } from "../../../../app/api/aiTechDocApi";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { mutatePAAiTechDoc } from "../../../../app/pages/questionnaires/aitechdoc-page/processAITechDocApi";
import { SxProps } from "@mui/system/styleFunctionSx";

export const PATechDocsOverviewReadOnlyRow = ({
  item
}: CustomRowComponentProps<
  OverviewItem & {
    readonly assetId?: string;
    readonly techDocId?: string;
  }
>) => {
  const overviewSetup = useOverviewState()[COLLECTIONS.PROCESSES_AITECHDOCS];
  const dispatch = useOverviewDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { id: paId } = useParams();
  const { t } = useTranslation();
  const aiTechDocStatuses: ItemStatus[] = useAITechDocStatuses();

  const statuses: ItemStatus[] = useMemo(
    () => [
      ...aiTechDocStatuses,
      {
        icon: <WarningAmber sx={warningColor} />,
        label: t(`process_aitechdoc_page:no-documentation`),
        status: "documentation-missing"
      }
    ],
    [aiTechDocStatuses, t]
  );

  const { auth } = useAuthentication();
  const [isCreatingTechDoc, setIsCreatingTechDoc] = useState(false);
  const onCreateTechDoc = useCallback(async () => {
    if (!auth?.tenantId) {
      return;
    }

    setIsCreatingTechDoc(true);

    let createdTechDocId: string;
    try {
      createdTechDocId = await createAITechDocAndLinkToAsset({
        payload: {
          name: t("process_aitechdoc_page:defaultTechDocName", { assetName: item.subTitle }),
          assigneeUID: auth.uid,
          mainOrgUnitId: item.responsibleOrgUnitId,
          furtherOrgUnitIds: item.furtherOrgUnitIds
        },
        assetId: item.assetId || "",
        tenantId: auth.tenantId,
        userId: auth.uid
      });
    } catch (e: unknown) {
      setIsCreatingTechDoc(false);
      if (e instanceof AITechDocAssetLinkError) {
        enqueueSnackbar(t("common:missingPermission"), { variant: "error" });
        return;
      }
      throw e;
    }

    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      reloadOverview: Date.now()
    });
    mutatePAAiTechDoc(paId || "");
    setIsCreatingTechDoc(false);
    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      selectedId: createdTechDocId
    });
  }, [
    auth?.tenantId,
    auth?.uid,
    dispatch,
    paId,
    t,
    item?.subTitle,
    item?.responsibleOrgUnitId,
    item?.furtherOrgUnitIds,
    item?.assetId,
    enqueueSnackbar
  ]);

  const handleClick = useCallback(() => {
    if (!item.techDocId) {
      onCreateTechDoc();
      return;
    }

    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      selectedId: item.techDocId
    });
  }, [dispatch, item.techDocId, onCreateTechDoc]);

  const rowActions = useMemo<RowAction[]>(() => {
    if (item.techDocId) {
      return [];
    }

    return [
      {
        action: "create-techdoc",
        title: t("common:create"),
        icon: isCreatingTechDoc ? <CircularProgress size={24} /> : <AddIcon />,
        onHandle: onCreateTechDoc,
        item
      }
    ] satisfies RowAction[];
  }, [item, onCreateTechDoc, isCreatingTechDoc, t]);

  return (
    <>
      <Box onClick={handleClick} sx={pointer}>
        <ListViewItem
          deletable={false}
          icon={item.icon}
          reserveCheckboxSpace={true}
          searchText={overviewSetup.search}
          hasCheckbox={false}
          allowedStatuses={statuses}
          status={item.status || "edit"}
          subTitle={item.subTitle}
          title={item.title}
          rowActions={rowActions}
          alwaysShowIcon={isCreatingTechDoc}
        />
      </Box>
    </>
  );
};

const warningColor: SxProps = { color: "warning.main" };

const pointer: SxProps = {
  cursor: "pointer"
};
