import { useUserDepartments } from "app/contexts/department-context";
import { QuestionProps } from "../../Question";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";
import HouseIcon from "@mui/icons-material/House";
import React from "react";

const FurtherOrgUnitQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onBlur,
  onFocus
}: QuestionProps) => {
  const { t } = useTranslation();
  const { getDepartmentWithParentName, authUserSelectableDepartmentIds } = useUserDepartments();

  const _value: string[] = (Array.isArray(value) ? value : [value as string]).filter(notNull => notNull);
  return (
    <MultiAutocomplete
      icon={!disabled ? <HouseIcon color="primary" /> : <></>}
      label={questionName || t("risk_general_page:furtherAffectedOrgUnit")}
      options={authUserSelectableDepartmentIds}
      selected={_value}
      updateSelected={onChange}
      getOptionLabel={getDepartmentWithParentName}
      hasMultiSelect
      disableClearable={disabled}
      onBlur={onBlur}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default FurtherOrgUnitQuestion;
