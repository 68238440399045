import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { AssetShortProps, getAssets } from "app/pages/assets/handler/assetHandler";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { ResourceMetaviewProps } from "../ResourceTypeOverviewDeps";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { AssetStatusEnum } from "app/api/generated/asset-service";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import MetaViewList, { MetaViewListItemProps } from "components/MetaViewList/MetaViewList";
import { getDepartmentName } from "../../../utils/getFirstDepartmentName";
import { useUserDepartments } from "app/contexts/department-context";

const SWR_KEY = "assets-in-metaview";

const assetStatusIcons = {
  [AssetStatusEnum.Planned]: <LazySvgIcon name="Planned" />,
  [AssetStatusEnum.Testing]: <LazySvgIcon name="Testing" />,
  [AssetStatusEnum.Active]: <LazySvgIcon name="Completed" />,
  [AssetStatusEnum.Inactive]: <LazySvgIcon name="On_Halt" />
};

const AssetsInResourceMetaView = ({ resourceType, resourceId }: ResourceMetaviewProps) => {
  const { t } = useTranslation();
  const { departments } = useUserDepartments();
  const { data, isValidating, mutate } = useSWR(SWR_KEY, () => filter && getAssets(filter), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const [filter, setFilter] = useState<Record<string, string | string[]> | null>(null);
  const [items, setItems] = useState<MetaViewListItemProps[]>([]);

  useEffect(() => {
    if (resourceType && resourceId) {
      switch (resourceType) {
        case RESOURCE_TYPES.ASSET_TYPE: {
          setFilter({
            resourceAssetTypeIds: [resourceId]
          });
        }
      }
    } else {
      setFilter(null);
    }
  }, [resourceId, resourceType]);

  useEffect(() => {
    if (data && !isValidating) {
      const _items = data.items.map((asset: AssetShortProps) => {
        const { id, name, status, mainOrgUnitId } = asset;
        return {
          id: id,
          title: name,
          subTitle: getDepartmentName(departments, mainOrgUnitId),
          icon: assetStatusIcons[status as AssetStatusEnum],
          iconTooltip: t(`assets_overview:${(status as AssetStatusEnum).toLocaleLowerCase()}`)
        } satisfies MetaViewListItemProps;
      });

      setItems(_items);
    } else {
      setItems([]);
    }
  }, [data, departments, isValidating, t]);

  useEffect(() => {
    mutate();
  }, [filter, mutate]);

  const onItemClickCallback = useCallback((id: string) => {
    window.open(`/asset-management/${id}/general`);
  }, []);

  return (
    <Box p={3}>
      <MetaViewList
        header={t("collection:assets_cap")}
        items={items}
        loading={isValidating}
        onItemClick={onItemClickCallback}
        pagination={true}
      />
    </Box>
  );
};

export default AssetsInResourceMetaView;
