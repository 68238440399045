import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import React from "react";
import { useTranslation } from "react-i18next";

export const ConfirmAutoFill = ({
  onConfirm,
  open,
  onClose
}: {
  readonly open: boolean;
  readonly onConfirm: () => void;
  readonly onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      variant="info"
      modalOpen={open}
      modalTitle={t("questionnaires:storage_deletion_overwrite_title")}
      modalText={t("questionnaires:storage_deletion_overwrite_body")}
      onClose={onClose}
      buttons={[
        {
          confirmButton: true,
          title: t("common:no"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onClose
        },
        {
          confirmButton: true,
          title: t("common:yes"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: () => {
            onConfirm();
            onClose();
          }
        }
      ]}
    />
  );
};
