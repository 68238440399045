import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { apiEndpoints } from "./apiEndpoint";
import i18n from "app/i18n";
import useSWR from "swr";
import { useCallback } from "react";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.tomUrl}/api/toms`
});

export async function getTomsIndexApi(type?: string) {
  const response = await axiosInstance.get<TomModelsDTO>("/", { params: { type } });
  return response.data.toms;
}

export async function getTomApi(id: string) {
  const response = await axiosInstance.get<TomModelDTO>(`/${id}`);
  return response.data;
}

export async function getTomRisksApi(id: string) {
  const response = await axiosInstance.get<TomModelDTO>(`/${id}/risks`);
  return response.data;
}

export async function deleteTomApi(id: string) {
  await axiosInstance.delete(`/${id}`);
}

export async function createTomApi(
  name: string,
  additionalPayload: Partial<Omit<TomPayload, "name">> = {}
): Promise<string> {
  const response = await axiosInstance.post(`/`, {
    name,
    ...additionalPayload
  });
  return response.headers["x-resource-id"] || "";
}

export const getTomExportsExcel = async (params: { readonly tomIDs: string[] }): Promise<Blob> => {
  const response = await axiosInstance.post(
    `/overview/exports/excel`,
    {
      tomIDs: params.tomIDs
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      responseType: "blob",
      timeout: 300000 // 5 minutes
    }
  );
  return new Blob([response.data]);
};

export const getTomExportsPdf = async (params: { readonly tomIDs: string[] }): Promise<any> => {
  const response = await axiosInstance.post(
    `/overview/exports/pdf`,
    {
      tomIDs: params.tomIDs
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      timeout: 300000 // 5 minutes
    }
  );
  return response.data;
};

export async function updateTomApi(
  id: string,
  {
    name,
    status,
    statusDate,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    processSpecific,
    riskIds,
    labelIds,
    protectionObjectiveIds
  }: UpdateTomModelDTO = {}
) {
  const response = await axiosInstance.patch(`/${id}`, {
    name,
    status,
    statusDate,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    processSpecific,
    riskIds,
    labelIds,
    protectionObjectiveIds
  });

  return response.data;
}

export const importTomsJob = async (params: { readonly base64Excel: string }): Promise<{ readonly runId: string }> => {
  const response = await axiosInstance.post<{ readonly runId: string }>(
    `/imports`,
    {
      base64Excel: params.base64Excel
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      }
    }
  );
  return response.data;
};

export const TomStatusValues = ["PLANNED", "IMPLEMENTED"] as const;
export type TomStatus = (typeof TomStatusValues)[number];

export interface TomPayload {
  readonly orgUnitId: string | null;
  readonly furtherAffectedOrgUnitIds: string[];
  readonly name: string;
  readonly description: string | null;
  readonly statusDate: Date | null;
  readonly status: TomStatus | null;
  readonly protectionObjectiveIds: string[];
  readonly riskIds: string[];
  readonly labelIds: string[];
  readonly processSpecific: boolean | null;
  readonly ownerUID: string | null;
  readonly openTasks: string;
}

export interface TomModel extends TomPayload {
  readonly tenantId: string;
  readonly id: string;
  readonly permission: "read" | "write";
  readonly creatorUID: string;
  readonly updaterUID: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export type TomModelDTO = Omit<TomModel, "tenantId">;

export interface TomModelsDTO {
  readonly toms: TomModelDTO[];
}

export type UpdateTomModelDTO = Partial<TomPayload>;

export type CreateTomModelDTO = Partial<Omit<TomPayload, "name">> & {
  readonly name: string;
};

export const useGetTOMApi = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["tom", args.documentId] : null, () => {
    return getTomApi(args.documentId);
  });
};

export const useUpdateTOMApi = (args: { documentId: string }) => {
  const { mutate, isValidating } = useGetTOMApi({ documentId: args.documentId });

  return {
    updateTOMApi: useCallback(
      async (payload: UpdateTomModelDTO) => {
        await mutate(
          async data => {
            await updateTomApi(args.documentId, payload);
            return data;
          },
          {
            populateCache: false,
            revalidate: true
          }
        );
      },
      [args.documentId, mutate]
    ),
    isValidating
  };
};
