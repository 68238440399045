import { COLLECTIONS } from "../../../../collections";
import TemplateModal, { TemplateModalButtonProps } from "../../../../../components/TemplateModal/TemplateModal";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ResourceDialogProps } from "../../ResourceTypeOverviewDeps";
import { useResources } from "../../../../contexts/resource-context";
import { RESOURCE_TYPE } from "../../../../handlers/resourceHandler";

export const DSRTemplateUploadDialog = (props: ResourceDialogProps) => {
  const { t } = useTranslation();
  const { translateById } = useResources();
  const buttons: TemplateModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: props.onClose
      } satisfies TemplateModalButtonProps
    ],
    [t, props.onClose]
  );
  return (
    <TemplateModal
      modalOpen={!!props.resourceId}
      onClose={props.onClose}
      modalTitle={`${t("common:attachments")}: ${translateById(props.resourceType as RESOURCE_TYPE, props.resourceId)}`}
      modalText={t("resources_overview:modal_template_text")}
      modalBody={t("resources_overview:modal_template_body")}
      category={`${COLLECTIONS.RESOURCES}-${props.resourceType}`}
      docId={props.resourceId}
      buttons={buttons}
    />
  );
};
