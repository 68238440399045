import { Box, CircularProgress, MenuItem } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { OverviewExportProps } from "./OverviewExport";

export const ExportMenuItem = ({
  text,
  onExport,
  format
}: { readonly text: string; readonly format: string } & OverviewExportProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const onExportClicked = useCallback(async () => {
    setIsExporting(true);
    try {
      await onExport?.(format);
    } catch (error: any) {
      setIsExporting(false);
      throw error;
    }
    setIsExporting(false);
  }, [format, onExport]);

  return (
    <MenuItem onClick={onExportClicked} disabled={isExporting}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>{text}</Box>
        {isExporting && (
          <Box ml={1}>
            <CircularProgress size={14} />
          </Box>
        )}
      </Box>
    </MenuItem>
  );
};
