import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { UserDTO } from "../app/api/user/userApi";
import { useTranslation } from "react-i18next";

export default function UserDataDisplay({
  userData,
  separator,
  ellipsis
}: {
  readonly userData?: {
    readonly name?: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly email?: string;
  } | null;
  readonly separator?: string;
  readonly ellipsis?: boolean;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const email = userData?.email;

  const displayName = useMemo(
    function () {
      if (userData?.name) {
        setShowTooltip(true);
        return userData.name;
      }

      if (userData?.firstName && userData?.lastName) {
        setShowTooltip(true);
        return `${userData.firstName} ${userData.lastName}`;
      }

      if (userData?.email) {
        return userData.email;
      }
    },
    [userData]
  );

  const textStyle = (ellipsis && ellipsisStyle) || undefined;

  return (
    <>
      {!userData && <span style={textStyle}>{"n/a"}</span>}
      {userData && showTooltip && (
        <Tooltip title={email || ""}>
          <span style={textStyle}>
            {displayName}
            {separator ? ", " : ""}
          </span>
        </Tooltip>
      )}
      {userData && !showTooltip && (
        <span style={textStyle}>
          {email}
          {separator ? ", " : ""}
        </span>
      )}
    </>
  );
}

const ellipsisStyle: CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block"
};

export const AutomaticUserDataDisplay = ({
  uid,
  separator
}: {
  readonly uid?: string;
  readonly separator?: string;
}) => {
  const { getUserHook } = useUserAndTenantData();
  const [user, setUser] = useState<UserDTO | null>(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!uid) {
      setUser(null);
      return;
    }

    const _user = getUserHook(uid);
    setUser(_user || null);
  }, [getUserHook, uid]);

  if (!uid) {
    return <>{t("common:not_available")}</>;
  }

  if (!user) {
    return <>{t("system")}</>;
  }

  return <UserDataDisplay userData={user} separator={separator} />;
};
