import { Divider } from "@material-ui/core";
import { PRIORITIES } from "app/handlers/tasksHandler";
import ChipsStack from "components/ChipsStack/ChipsStack";
import { useTranslation } from "react-i18next";

interface TaskPriorityProps {
  readonly priority: string;
  readonly disabled?: boolean;
  readonly onChangePriority?: (priority: string) => void;
}
const TaskPriority = ({ priority, onChangePriority, disabled }: TaskPriorityProps) => {
  const { t } = useTranslation("task_details");

  return (
    <>
      <ChipsStack
        chips={Object.values(PRIORITIES).map(value => ({
          label: t("priority_" + value),
          value: value,
          color: "primary",
          variant: "outlined",
          onClick: () => {
            onChangePriority?.(value);
          },
          size: "medium",
          selected: priority === value
        }))}
        header={t("priority")}
        readOnly={disabled}
      />
      <Divider />
    </>
  );
};

export default TaskPriority;
