import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";

const timeUnits = ["year", "month", "week", "day"];
const UnitOfTimeQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation();
  const _value: string = Array.isArray(value) ? value[0] : (value as string);

  const getOptionLabel = useCallback(
    option => {
      return option ? t(`time:${option}`, option) : "";
    },
    [t]
  );

  const [singleSelectInputField, setSingleSelectInputField] = useState<string>("");
  useEffect(() => {
    const optionLabel = getOptionLabel(_value);
    setSingleSelectInputField(optionLabel || "");
  }, [_value, getOptionLabel]);
  const onInputChange = useCallback((event, value) => {
    setSingleSelectInputField(value);
  }, []);

  return (
    <MultiAutocomplete
      label={questionName || t("time:unit")}
      disabled={disabled}
      hasMultiSelect={false}
      selected={_value || ""}
      updateSelected={onChange}
      options={timeUnits}
      getOptionLabel={getOptionLabel}
      onFocus={onFocus}
      onBlur={onBlur}
      inputValue={singleSelectInputField}
      error={error}
      helperText={helperText}
      onInputChange={onInputChange}
    />
  );
};

export default React.memo(UnitOfTimeQuestion);
