import { useTranslation } from "react-i18next";
import GeneralPageStepper from "../../../components/Pagination/GeneralPageStepper";
import React, { useEffect, useState } from "react";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";
import { CircularProgress, Grid } from "@material-ui/core";
import { useRisk } from "../../contexts/risk-context";

function usePaginationSteps() {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<
    {
      label: string;
      path: string;
    }[]
  >([]);

  useEffect(() => {
    setSteps([
      {
        label: t("pagination:general"),
        path: "general"
      },
      {
        label: t("pagination:risk_assessments_first"),
        path: "initial-assessment"
      },
      {
        label: t("pagination:risk_treatment"),
        path: "treatment"
      },
      {
        label: t("pagination:risk_assessments_second"),
        path: "treatment-assessment"
      },
      {
        label: t("pagination:assets"),
        path: "assets"
      }
    ]);
  }, [t]);

  return {
    steps
  };
}

export function RiskPageStepper() {
  const { steps } = usePaginationSteps();

  return <GeneralPageStepper steps={steps} />;
}

export function RiskPageButtons({ leftArea }: { readonly leftArea?: React.ReactNode }) {
  const { steps } = usePaginationSteps();
  const { loading } = useRisk();

  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
      <Grid item>{loading && <CircularProgress size={14} />}</Grid>
      <Grid item>
        <GeneralPageButtonPagination steps={steps} leftArea={leftArea} />
      </Grid>
    </Grid>
  );
}
