import { useCallback } from "react";
import overviewBaseController, {
  FilterItemProps,
  OverviewController,
  OverviewItem,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  getAssetsStatusFilter,
  getAssetsTypeFilter,
  getDataStorageLocationFilter,
  getLabelsFilter
} from "app/pages/shared/Filters/filters";
import { AxiosInstance, AxiosResponse } from "axios";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { getTextFromTextEditorJsonString } from "../../questionnaires/utils/textEditorConverter";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { getAssetOverview } from "app/api/assetApi";
import { getPAsForOverview } from "app/api/paApi";
import { getOrganizationFilter } from "../../shared/Filters/OrganizationFilter";
import { getStandardFilter } from "../../shared/Filters/standardFilter";
import { tenantDecorator } from "../../../../components/Overview/controllers/decorator/tenantDecorator";
import { FEATURES } from "../../../features";

export interface AssetsOverviewCollectionParams {
  readonly filter?: object;
  readonly openInNewTab?: boolean;
}

const AssetsOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: AssetsOverviewCollectionParams
): OverviewController => {
  const { t } = useTranslation();
  const { addToSeenItemsOfUserHook, loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { t: tFilter } = useTranslation("filter_criteria");
  const normalize = useCallback(
    (item: Pick<OverviewItem, "title" | "subTitle" | "id">) => ({
      ...item,
      subTitle: getTextFromTextEditorJsonString(item.subTitle || "")
    }),
    []
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.ASSETS, normalize, [
    resourcesDecorator,
    departmentsDecorator,
    tenantDecorator
  ]);

  const getFilters = useCallback(
    (overviewResult: OverviewResult) =>
      [
        getAssetsStatusFilter("status", t),
        getAssetsTypeFilter("resourceAssetTypeId", t),
        getLabelsFilter("labelIds", overviewResult._resources, t),
        getOrganizationFilter("allOrgUnitIds", overviewResult._departments, t),
        getOrganizationFilter("mainOrgUnitId", overviewResult._departments, t, "responsibleDepartment"),
        getOrganizationFilter("orgUnitIds", overviewResult._departments, t, "furtherDepartments"),
        getDataStorageLocationFilter("dataLocationIds", overviewResult.dataLocations || [], t),
        getStandardFilter({
          filterField: "externalRecipientIds",
          standardFilters: overviewResult.ers || [],
          name: t("manage-user-page:external_recipients")
        }),
        getStandardFilter({
          filterField: "riskIds",
          standardFilters: overviewResult.risks || [],
          name: t("manage-user-page:risk")
        }),
        overviewResult._tenant.features.includes(FEATURES.AI_TECH_DOCS)
          ? getStandardFilter({
              filterField: "aiTechDocIds",
              standardFilters: overviewResult.atds || [],
              name: t("sidebar:ai-tech-docs")
            })
          : null
      ].flatMap(it => (it ? [it] : [])) satisfies FilterItemProps[],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: tFilter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: tFilter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: tFilter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: tFilter("oldFirst")
      }
    ],
    [tFilter]
  );

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    let data: OverviewResult | null;
    if (collectionParams?.filter && Object.keys(collectionParams?.filter)?.length > 0) {
      data = await getFilteredOverview(setup, collectionParams);
    } else {
      data = await baseController.getOverview(setup);
    }
    if (!data) {
      return null;
    }
    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/asset-management/${id}/general`);
  };

  const addItem = async (data: OverviewNewItem): Promise<AxiosResponse> => {
    const response = await baseController.addItem({
      name: data?.title || createOverviewItemDefaultName("asset")
    });
    const assetId = response.headers["x-resource-id"] || "";

    // add to seen items
    await addToSeenItemsOfUserHook(COLLECTIONS.ASSETS, assetId);
    await loadSeenItemsOfUserHook();
    return response;
  };

  const deleteItem = async (id: string): Promise<void> => {
    await baseController.deleteItem(id);
    await loadSeenItemsOfUserHook();
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, {
      name: "title" in data && data.title
    });
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem(data);
    goToItem(response.headers["x-resource-id"] || "");
  };

  const getFilteredOverview = async (
    setup: OverviewSetup,
    collectionParams: AssetsOverviewCollectionParams
  ): Promise<OverviewResult | null> => {
    const filteredAssets = await baseController.getOverview({ ...setup, shadowLoading: false }, "/", {
      params: collectionParams?.filter || {}
    });
    if (filteredAssets === null) {
      return null;
    }
    // filtered assets are lacking some data, so we need to enrich them
    const allAssets = await getAssetOverview();
    const processes = (await getPAsForOverview({ assetIDs: filteredAssets?.items.map(item => item.id) }))?.items;
    const enrichedItems = (filteredAssets?.items ?? []).map(item => {
      const asset = (allAssets?.items ?? []).find(asset => asset.id === item.id);
      return {
        ...item,
        ...asset,
        processes: processes?.filter(process => process.allAssetIds.includes(item.id)) ?? [],
        subTitle: item.subTitle ?? undefined,
        title: item.title ?? item.name ?? undefined
      } as OverviewItem;
    });
    return {
      ...allAssets,
      ...filteredAssets,
      allItems: enrichedItems,
      items: enrichedItems,
      responseItems: enrichedItems,
      selectedItem: null
    };
  };
  return {
    ...baseController,
    addItem,
    addItemAndGo,
    deleteItem,
    getOverview,
    goToItem,
    patchItem
  };
};

export default AssetsOverviewController;
