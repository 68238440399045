import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, makeStyles } from "@material-ui/core";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { detailType, TaskDetailsDTO, TaskStatus } from "app/api/taskApi";
import { RecurringTask } from "app/api/recurringTaskApi";
import TaskOverviewRowStatus from "components/Overview/collections/task/TaskOverviewRowStatus";
import TaskOverviewRowPriority from "components/Overview/collections/task/TaskOverviewRowPriority";
import TaskOverviewRowDueDate from "components/Overview/collections/task/TaskOverviewRowDueDate";
import TaskOverviewRowAssignUser from "components/Overview/collections/task/TaskOverviewRowAssignUser";
import { PRIORITIES } from "app/handlers/tasksHandler";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { TaskInfoLabel } from "./TaskInfoLabel";
import { TaskRecurringChip } from "./recurring/RecurringTaskChip";
// icons
import StatusIcon from "assets/images/tasks/status/status-icon.svg";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Group } from "@material-ui/icons";
import RepeatIcon from "@mui/icons-material/Repeat";
import PersonIcon from "@mui/icons-material/Person";
import { COLLECTION_TYPES } from "app/collections";
import TaskOverviewRowAssignGroups from "components/Overview/collections/task/TaskOverviewRowAssignGroups";

const useStyles = makeStyles(theme => ({
  taskDetailInfoRow: {
    display: "flex",
    paddingLeft: 0,
    minWidth: theme.spacing(40)
  },
  label: {
    flexBasis: "50%"
  },
  taskInfoValue: {
    flexShrink: 1,
    whiteSpace: "nowrap"
  }
}));

interface TaskInfoRowProps {
  readonly icon: any;
  readonly label: string;
  readonly valueEl: string | JSX.Element | null;
  readonly inline?: boolean;
}
const TaskInfoRow = ({ icon, inline = true, label, valueEl }: TaskInfoRowProps) => {
  const cls = useStyles();
  return (
    <Box
      p={2}
      className={cls.taskDetailInfoRow}
      flexDirection={inline ? "row" : "column"}
      alignItems={inline ? "center" : "flex-start"}
    >
      <Box className={cls.label}>
        <TaskInfoLabel icon={icon} text={label} />
      </Box>
      {valueEl}
    </Box>
  );
};

interface TaskInfoRowsProps {
  readonly detailType: detailType;
  readonly item: TaskDetailsDTO | RecurringTask;
  readonly readOnly?: boolean;
  readonly updateTask: (
    taskId: string,
    detailType: detailType,
    data: Partial<TaskDetailsDTO> | Partial<RecurringTask>,
    currentTask: TaskDetailsDTO | RecurringTask
  ) => void;
  readonly strictlyAvailableMentionUserIds?: string[];
}
const TaskInfoRows = ({
  item,
  updateTask,
  detailType,
  readOnly,
  strictlyAvailableMentionUserIds
}: TaskInfoRowsProps) => {
  const { t } = useTranslation("task_details");
  const { getUserNameHook } = useUserAndTenantData();
  const { auth } = useAuthentication();
  const { uid } = auth || { tenantId: "", uid: "" };

  const userNameDisplayText = useMemo(() => {
    const isCurrentUserAssigned = item.assigneeUID === uid;
    if (isCurrentUserAssigned) {
      return t("common:you").charAt(0).toUpperCase() + t("common:you").slice(1);
    }
    return item.assigneeUID ? getUserNameHook(item.assigneeUID) : undefined;
  }, [item.assigneeUID, uid, getUserNameHook, t]);

  function isRecurringTaskType(item: any): item is RecurringTask {
    return "dayOfWeek" in item && "startTime" in item && "endTime" in item && "recurrenceType" in item;
  }
  const isRecurringTask = useMemo(() => isRecurringTaskType(item), [item]);

  const onChangeStatus = useCallback(
    async (status: TaskStatus | "ACTIVE" | "INACTIVE") => {
      if ("active" in item) {
        updateTask(item.id, detailType, { active: status === "ACTIVE" }, item);
      } else {
        const taskStatus = status as TaskStatus;
        updateTask(item.id, detailType, { status: taskStatus }, item);
      }
    },
    [detailType, item, updateTask]
  );

  const onChangePriority = useCallback(
    async (priority: string) => {
      updateTask(item.id, detailType, { priority }, item);
    },
    [detailType, item, updateTask]
  );

  const onChangeDueDate = useCallback(
    async (dueAt: Date | null) => {
      if (!dueAt) {
        updateTask(item.id, detailType, { dueAt: null }, item);
      } else {
        const now = new Date();
        const endOfDayDateDue = new Date(new Date(dueAt).setHours(23, 59, 59, 999));
        // when click on "clear"
        const finalDueDate = now.getTime() > endOfDayDateDue.getTime() ? null : endOfDayDateDue;
        updateTask(item.id, detailType, { dueAt: finalDueDate }, item);
      }
    },
    [detailType, item, updateTask]
  );

  const onChangeRepetition = useCallback(
    async data => {
      updateTask(item.id, detailType, data, item);
    },
    [detailType, item, updateTask]
  );

  const onChangeAssignee = useCallback(
    async (userId: string) => {
      updateTask(item.id, detailType, { assigneeUID: userId }, item);
    },
    [detailType, item, updateTask]
  );

  const onChangeGroupIds = useCallback(
    async (groupIds: string[]) => {
      updateTask(item.id, detailType, { groupIds }, item);
    },
    [detailType, item, updateTask]
  );

  const StatusEl = (
    <Box display={"flex"}>
      <TaskOverviewRowStatus
        status={!("status" in item) && "active" in item ? (item.active ? "ACTIVE" : "INACTIVE") : item.status}
        detailType={detailType}
        onChange={onChangeStatus}
        showStatusText={true}
      />
    </Box>
  );

  const PriorityEl = (
    <Box display={"flex"}>
      <TaskOverviewRowPriority
        readOnly={readOnly}
        priority={((item.priority as unknown) || "medium") as keyof typeof PRIORITIES}
        onChange={onChangePriority}
        status={""}
      />
    </Box>
  );

  const DueDateEl =
    !isRecurringTask && "status" in item ? (
      <Box display={"flex"} style={{ whiteSpace: "nowrap", maxWidth: "10vw" }}>
        <TaskOverviewRowDueDate
          dueDate={item.dueAt ? new Date(item.dueAt) : null}
          onChange={onChangeDueDate}
          readOnly={readOnly}
          showAddDueDate={true}
          status={item.status}
        />
      </Box>
    ) : null;

  const RecurringEl =
    isRecurringTask && "dayOfWeek" in item && "startTime" in item && "endTime" in item && "recurrenceType" in item ? (
      <Box display={"flex"}>
        <TaskRecurringChip
          dayOfWeek={item.dayOfWeek}
          startTime={item.startTime}
          endTime={item.endTime}
          type={item.recurrenceType}
          onChangeRepetition={onChangeRepetition}
        />
      </Box>
    ) : null;

  const showAssignToGroups = useMemo(() => "assignedToType" in item && item.assignedToType === "GROUP", [item]);

  const AssignedToEl =
    "assignedToType" in item && showAssignToGroups ? (
      <TaskOverviewRowAssignGroups
        key={`assign-to-groups-${item.id}`}
        taskId={item.id}
        groupIds={item.groupIds}
        onChange={onChangeGroupIds}
        showGroups={true}
        showAddGroup={true}
      />
    ) : (
      <Box display={"flex"}>
        <TaskOverviewRowAssignUser
          collection={item.collection as COLLECTION_TYPES}
          readOnly={readOnly}
          onChange={onChangeAssignee}
          showAddUser={true}
          userId={item.assigneeUID || ""}
          userNameDisplay={userNameDisplayText}
          strictlyAvailableMentionUserIds={strictlyAvailableMentionUserIds}
        />
      </Box>
    );

  const taskDetails = [
    ...(!isRecurringTask
      ? [{ icon: StatusIcon, label: t("status"), value: StatusEl }]
      : [{ icon: StatusIcon, label: t("status"), value: StatusEl }]),
    { icon: LowPriorityIcon, label: t("priority"), value: PriorityEl },
    ...(!isRecurringTask
      ? [{ icon: CalendarTodayIcon, label: t("due_date"), value: DueDateEl }]
      : [{ icon: RepeatIcon, label: t("schedule"), value: RecurringEl }]),
    {
      icon: showAssignToGroups ? Group : PersonIcon,
      label: t("assigned_to"),
      value: AssignedToEl,
      inline: !showAssignToGroups
    }
  ];

  return (
    <Box mt={4}>
      {taskDetails.map((detail, index) => (
        <React.Fragment key={index}>
          <TaskInfoRow
            icon={detail.icon}
            label={detail.label}
            valueEl={detail.value as string | JSX.Element | null}
            inline={detail.inline}
          />
          <Divider />
        </React.Fragment>
      ))}
    </Box>
  );
};

export { TaskInfoRows };
