import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo } from "react";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useParams } from "react-router-dom";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "../../../contexts/overview-context";
import Overview from "../../../../components/Overview/Overview";
import { COLLECTIONS } from "../../../collections";
import { CollectionParams } from "../../../../hook/useOverviewData";
import { PATechDocsOverviewReadOnlyRow } from "../../../../components/Overview/collections/processes/PATechDocsOverviewReadOnlyRow";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import { Box } from "@mui/material";
import { usePAAITechDoc } from "./processAITechDocApi";
import { WindowFocus } from "../../../router/router-filters";
import { AITechDocModal } from "../../ai-tech-docs/AITechDocModal";
import { useGetAITechDocApi } from "../../../api/aiTechDocApi";
import { useSnackbar } from "notistack";

const ProcessAITechDocPage = ({ readonly }: { readonly readonly?: boolean }) => {
  const { id } = useParams();
  const overviewParams = useMemo<CollectionParams>(() => ({ id, openInNewTab: true }), [id]);
  const { t } = useTranslation("process_aitechdoc_page");

  const { data, mutate } = usePAAITechDoc(id || "");

  const alertSeverity = useMemo(() => {
    switch (data?.aiRiskLevel) {
      case "high":
        return data?.allAIAssetsHaveValidTechDocs ? "success" : "warning";
      case "unacceptable":
        return "error";
      default:
        return "";
    }
  }, [data?.aiRiskLevel, data?.allAIAssetsHaveValidTechDocs]);

  const dispatch = useOverviewDispatch();
  const refreshOverviewOnWindowRefocus = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      reloadOverview: Date.now()
    });
    mutate();
  }, [dispatch, mutate]);

  const { enqueueSnackbar } = useSnackbar();
  const overviewSetup = useOverviewState()[COLLECTIONS.PROCESSES_AITECHDOCS];
  const selectedTechDocId = overviewSetup?.selectedId || "";
  const { isLoading: aiTechDocLoading, error: aiTechDocError } = useGetAITechDocApi({
    documentId: selectedTechDocId
  });

  useEffect(() => {
    if (aiTechDocError) {
      enqueueSnackbar(t("common:missingPermission"), { variant: "error" });
      dispatch({
        type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
        collection: COLLECTIONS.PROCESSES_AITECHDOCS,
        selectedId: ""
      });
    }
  }, [aiTechDocError, dispatch, enqueueSnackbar, t]);

  const onTechDocModalClose = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      selectedId: ""
    });
  }, [dispatch]);

  const onTechDocModalConfirm = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      reloadOverview: Date.now()
    });
    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      selectedId: ""
    });
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
        collection: COLLECTIONS.PROCESSES_AITECHDOCS,
        selectedId: ""
      });
    };
  }, [dispatch]);

  return (
    <>
      <QuestionnaireSubHeader text={t("title")} />
      {alertSeverity ? (
        <Box mt={3.5} mb={3}>
          <CustomAlert severity={alertSeverity}>
            {t(`process_aitechdoc_page:message_level_${data?.aiRiskLevel || ""}`)}
          </CustomAlert>
        </Box>
      ) : (
        <></>
      )}
      <Box mx={-6}>
        <Overview
          toolbarActions={empty}
          collectionParams={overviewParams}
          collection={COLLECTIONS.PROCESSES_AITECHDOCS}
          CustomRowComponent={PATechDocsOverviewReadOnlyRow}
          showSelectAll={false}
        />
        <WindowFocus onFocus={refreshOverviewOnWindowRefocus} />
        <AITechDocModal
          open={!aiTechDocLoading && !!selectedTechDocId}
          id={selectedTechDocId}
          onClose={onTechDocModalClose}
          onConfirm={onTechDocModalConfirm}
        />
      </Box>
    </>
  );
};
const empty: any[] = [];

export default ProcessAITechDocPage;
