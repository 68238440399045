import React, { useEffect, useState } from "react";
import { Divider, Card, CardContent, MenuList, ListItemButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { languageToggles } from "app/handlers/languageHandler";
import { useTranslator } from "../../../contexts/translator-context";

export default function TranslatorMenu({ translationId, onSelectLanguage }) {
  const [hiddenMenu, setHiddenMenu] = useState("sourceLanguage");
  const {
    findTranslation,
    hideTranslationHook,
    loading,
    setSelectedSourceLanguageHook,
    setTargetLanguageHook,
    translateHook
  } = useTranslator();
  const [translation, setTranslation] = useState({});

  useEffect(() => {
    setTranslation(findTranslation(translationId));
  }, [findTranslation, translationId]);

  useEffect(() => {
    if (translation?.targetLanguage) {
      translateHook(translation);
    }
  }, [translation?.targetLanguage, translation.selectedSourceLanguage, translation, translateHook]);

  return (
    <div data-testid={"translator_menu"}>
      <div hidden={hiddenMenu === "translateLanguage"}>
        <TargetLanguageMenu
          detectedSourceLanguage={translation?.detectedSourceLanguage}
          hideLanguageDisabled={loading}
          hideTranslation={() => hideTranslationHook(translationId)}
          selectedLanguage={translation?.targetLanguage}
          showDetected={() => !translation?.selectedSourceLanguage && !!translation?.detectedSourceLanguage}
          updateHiddenMenu={() => setHiddenMenu("translateLanguage")}
          updateSelectedTargetLanguage={language => {
            setTargetLanguageHook(translationId, language);
            onSelectLanguage?.(language);
          }}
        />
      </div>
      <div hidden={hiddenMenu === "sourceLanguage"}>
        <SourceLanguageMenu
          sourceLanguage={translation?.selectedSourceLanguage}
          updateHiddenMenu={() => setHiddenMenu("sourceLanguage")}
          updateSelectedSourceLanguage={language => setSelectedSourceLanguageHook(translationId, language)}
        />
      </div>
    </div>
  );
}

function TargetLanguageMenu({
  detectedSourceLanguage,
  hideLanguageDisabled,
  hideTranslation,
  selectedLanguage,
  showDetected,
  updateHiddenMenu,
  updateSelectedTargetLanguage
}) {
  const { t, i18n } = useTranslation("deepL_menu");
  const [sortedTargetLanguages, setSortedTargetLanguages] = useState([]);

  useEffect(() => {
    setSortedTargetLanguages(
      translateAndSortDeepLLanguageCodes(["DE", "EN-US", "EN-GB"], Object.keys(languageToggles), t)
    );
  }, [i18n.language, t]);

  return (
    <Card data-testid={"target_language_menu"} sx={{ width: 250, maxHeight: 350, overflow: "auto" }}>
      <CardContent>
        <MenuList>
          <ListItemButton
            button
            data-testid={"hide_translation"}
            disableRipple
            disabled={hideLanguageDisabled}
            hidden={!selectedLanguage}
            onClick={hideTranslation}
          >
            <span>
              <b>{t("hide_translation")}</b>
            </span>
          </ListItemButton>
          <ListItemButton button disableRipple onClick={updateHiddenMenu} data-testid={"switch_source_menu"}>
            <span>
              {t("detected_source_language")} <b>{t("deepL_language:" + detectedSourceLanguage)}</b>{" "}
              {showDetected() ? t("detected") : ""}
            </span>
            <ArrowRightOutlined />
          </ListItemButton>
          <Divider sx={{ marginBottom: 1 }} />
          <ListItemButton button disableRipple>
            <span>
              <b>{t("translate_to")}</b>
            </span>
          </ListItemButton>
          {sortedTargetLanguages.map(language => (
            <ListItemButton
              button
              data-testid={"target_language_" + language.langCode}
              disableRipple
              key={language.langCode}
              selected={language.langCode === selectedLanguage}
              onClick={() => updateSelectedTargetLanguage(language.langCode)}
            >
              <span>{language.translation}</span>
            </ListItemButton>
          ))}
        </MenuList>
      </CardContent>
    </Card>
  );
}

function SourceLanguageMenu({ updateHiddenMenu, updateSelectedSourceLanguage, sourceLanguage }) {
  const { t, i18n } = useTranslation("deepL_menu");
  const [sortedSourceLanguages, setSortedSourceLanguages] = useState([]);

  useEffect(() => {
    setSortedSourceLanguages(translateAndSortDeepLLanguageCodes(["DE", "EN"], Object.keys(languageToggles), t));
  }, [i18n.language, t]);

  return (
    <Card data-testid={"source_language_menu"} sx={{ width: 250, maxHeight: 350, overflow: "auto" }}>
      <CardContent>
        <MenuList>
          <ListItemButton button disableRipple onClick={updateHiddenMenu} data-testid={"switch_target_menu"}>
            <ArrowLeftOutlined sx={{ marginLeft: -1 }} />
            <span>Select Source Language</span>
          </ListItemButton>
          <Divider sx={{ marginBottom: 1 }} />
          {sortedSourceLanguages.map(language => (
            <ListItemButton
              button
              data-testid={"source_language_" + language.langCode}
              disableRipple
              key={language.langCode}
              selected={sourceLanguage === language.langCode}
              onClick={() => updateSelectedSourceLanguage(language.langCode)}
            >
              <span>{language.translation}</span>
            </ListItemButton>
          ))}
        </MenuList>
      </CardContent>
    </Card>
  );
}

const translateAndSortDeepLLanguageCodes = (exceptions, languages, t) => {
  const translatedLanguages = languages.map(langCode => ({
    translation: t(`sidebar:${langCode}`),
    langCode
  }));
  return translatedLanguages.sort((a, b) => {
    if (exceptions.includes(a.langCode) && exceptions.includes(b.langCode)) {
      return a.langCode.localeCompare(b.langCode);
    }
    if (exceptions.includes(a.langCode)) return -1;
    if (exceptions.includes(b.langCode)) return 1;
    return a.translation.localeCompare(b.translation);
  });
};
