import { getHasuraClientSDK } from "../../api/hasuraApi";

export interface EmailNotificationConfig {
  readonly receiveNotification: boolean;
  readonly onMention: boolean;
  readonly onReplyToComment: boolean;
  readonly onTaskAssignment: boolean;
  readonly onProcessAssignment: boolean;
  readonly expiredDueDate: boolean;
  readonly requestAllowEdit: boolean;
  readonly onProcessApproved: boolean;
  readonly onDSRSubmission: boolean;
  readonly onAuditAssignment: boolean;
}

export const defaultNotificationSettings: EmailNotificationConfig = {
  receiveNotification: true,
  onMention: true,
  onReplyToComment: true,
  onTaskAssignment: true,
  onProcessAssignment: true,
  expiredDueDate: true,
  requestAllowEdit: true,
  onProcessApproved: true,
  onAuditAssignment: true,
  onDSRSubmission: true
};

const turnedOffNotificationSettings = () =>
  Object.entries(defaultNotificationSettings).reduce((notificationSettings, [key, value]) => {
    return {
      ...notificationSettings,
      [key]: false
    };
  }, {});

export const createEmailNotificationConfigForLoggedInUser = async () => {
  const client = await getHasuraClientSDK();
  return client.userNotificationSettingUpsert({
    master_enable: defaultNotificationSettings.receiveNotification,
    dsr_submission: defaultNotificationSettings.onDSRSubmission,
    mention: defaultNotificationSettings.onMention,
    process_approved: defaultNotificationSettings.onProcessApproved,
    process_assignment: defaultNotificationSettings.onProcessAssignment,
    comment_reply: defaultNotificationSettings.onReplyToComment,
    task_assignment: defaultNotificationSettings.onTaskAssignment,
    audit_assignment: defaultNotificationSettings.onAuditAssignment,
    edit_request: defaultNotificationSettings.requestAllowEdit,
    due_date: defaultNotificationSettings.expiredDueDate
  });
};

export const getEmailNotificationConfig = async (
  tenantId: string,
  userId: string
): Promise<EmailNotificationConfig> => {
  const client = await getHasuraClientSDK();
  let userNotificationConfigDoc = (
    await client.userNotificationSettingGetOne({
      tenant_id: tenantId,
      user_id: userId
    })
  ).frontend_access_user_notification_setting_by_pk;

  if (!userNotificationConfigDoc) {
    await createEmailNotificationConfigForLoggedInUser();
    userNotificationConfigDoc = (
      await client.userNotificationSettingGetOne({
        tenant_id: tenantId,
        user_id: userId
      })
    ).frontend_access_user_notification_setting_by_pk;
    if (!userNotificationConfigDoc) {
      throw new Error(`user ${userId} can't create notification configuration`);
    }
  }

  return {
    receiveNotification: userNotificationConfigDoc.master_enable,
    onMention: userNotificationConfigDoc.mention,
    onReplyToComment: userNotificationConfigDoc.comment_reply,
    onTaskAssignment: userNotificationConfigDoc.task_assignment,
    onProcessAssignment: userNotificationConfigDoc.process_assignment,
    expiredDueDate: userNotificationConfigDoc.due_date,
    requestAllowEdit: userNotificationConfigDoc.edit_request,
    onProcessApproved: userNotificationConfigDoc.process_approved,
    onDSRSubmission: userNotificationConfigDoc.dsr_submission,
    onAuditAssignment: userNotificationConfigDoc.audit_assignment
  };
};

export const updateEmailNotificationConfig = async (
  tenantId: string,
  userId: string,
  configData: Partial<EmailNotificationConfig>
) => {
  const emailNotificationSetting: Partial<EmailNotificationConfig> =
    configData.receiveNotification === false ? turnedOffNotificationSettings() : { ...configData };

  const client = await getHasuraClientSDK();
  await client.userNotificationSettingUpdate({
    tenant_id: tenantId,
    user_id: userId,
    update_payload: {
      master_enable: emailNotificationSetting.receiveNotification,
      dsr_submission: emailNotificationSetting.onDSRSubmission,
      mention: emailNotificationSetting.onMention,
      process_approved: emailNotificationSetting.onProcessApproved,
      process_assignment: emailNotificationSetting.onProcessAssignment,
      comment_reply: emailNotificationSetting.onReplyToComment,
      task_assignment: emailNotificationSetting.onTaskAssignment,
      audit_assignment: emailNotificationSetting.onAuditAssignment,
      edit_request: emailNotificationSetting.requestAllowEdit,
      due_date: emailNotificationSetting.expiredDueDate
    }
  });
};
