import { CategoryTypeTupleModel } from "../CategoryTypeTupleModel";

export const getSelectedChips = (items: { categoryTypeTuples: CategoryTypeTupleModel[] }[]) => {
  if (items.length > 1) {
    const [firstItem, ...restItems] = items;

    const categoryTypeTuplesForFirstPeriod = firstItem.categoryTypeTuples;
    const categoryTypeTuplesForOther = restItems.flatMap(({ categoryTypeTuples }) => categoryTypeTuples);

    return categoryTypeTuplesForFirstPeriod
      .filter(({ category, personGroup }) => {
        return !categoryTypeTuplesForOther.some(otherCategotyTuple => {
          return otherCategotyTuple.personGroup === personGroup && otherCategotyTuple.category === category;
        });
      })
      .map(({ id }) => id);
  } else return ["*"];
};

export const accordionEquality = (
  a: { id?: string; accordionId?: string },
  b: { id?: string; accordionId?: string }
) => {
  return (a.id && b.id && a.id === b.id) || (a.accordionId && b.accordionId && a.accordionId === b.accordionId);
};
