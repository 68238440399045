import MultiAutocomplete from "../MultiAutocomplete/MultiAutocomplete";
import React from "react";

interface OrgUnitSelector {
  label?: string;
  value?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  noDefaultValue?: boolean;
  icon?: React.ReactNode;
  error?: boolean;
  helperText?: string;
  options: string[];
  getOptionLabel: (option: string) => string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  tooltipText?: string;
}

export function OrgUnitSelector({
  label,
  onFocus,
  onBlur,
  value,
  onChange,
  fullWidth,
  disabled,
  error,
  helperText,
  options,
  getOptionLabel,
  icon,
  tooltipText
}: OrgUnitSelector) {
  return (
    <MultiAutocomplete
      disabled={disabled}
      hasMultiSelect={false}
      options={options}
      selected={value}
      updateSelected={onChange}
      getOptionLabel={getOptionLabel}
      label={label}
      disableClearable={true}
      onFocus={onFocus}
      onBlur={onBlur}
      icon={disabled ? undefined : icon}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      tooltipText={tooltipText}
    />
  );
}
