import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { ALLOWED_FILE_EXTENSIONS, normaliseFileNameExtension } from "../shared/Attachments/fileAttachmentUtils";
import { uploadDocumentFileApi } from "app/api/file-storage/documentApi";

interface UploaderProps {
  readonly id: string;
  readonly disabled?: boolean;
  readonly onUploadStart: () => void;
  readonly onUploadEnd: () => void;
  readonly path?: string;
}

interface UploaderRef {
  triggerFileInput: () => void;
}

const Uploader = forwardRef<UploaderRef, UploaderProps>(({ id, disabled, onUploadStart, onUploadEnd, path }, ref) => {
  const [localFiles, setLocalFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    triggerFileInput: () => {
      // to not propagate the event to the parent, to prevent unwanted closing/opening of folders
      inputRef.current?.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: false,
          cancelable: true
        })
      );
    }
  }));

  const allowedFileTypesToUpload = useMemo(() => Object.values(ALLOWED_FILE_EXTENSIONS), []);

  const buildFileExtensionString = useCallback((allowedFileTypes: string[]) => allowedFileTypes.join(", "), []);

  const processFiles = useCallback((files: FileList) => {
    return Array.from(files).map(file => {
      const formattedFileName = normaliseFileNameExtension(file.name);
      return new File([file], formattedFileName);
    });
  }, []);

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target?.files;
      if (files) {
        const processedFiles = processFiles(files);
        setLocalFiles(existingFiles => [...existingFiles, ...processedFiles]);
      }
      e.target.value = "";
    },
    [processFiles]
  );

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      try {
        onUploadStart();
        await Promise.all(files.map((file: File) => uploadDocumentFileApi({ file, path: path || "" })));
      } catch (error) {
        console.error("Failed to upload files:", error);
      } finally {
        onUploadEnd();
      }
    },
    [onUploadEnd, onUploadStart, path]
  );

  useEffect(() => {
    if (localFiles.length) {
      handleUploadFiles(localFiles);
    }
  }, [localFiles, handleUploadFiles]);

  const style = useMemo(() => ({ display: "none" as const }), []);

  return (
    <input
      accept={buildFileExtensionString(allowedFileTypesToUpload)}
      disabled={disabled}
      id={id}
      multiple
      onChange={handleFileInputChange}
      ref={inputRef}
      style={style}
      type="file"
    />
  );
});

Uploader.displayName = "Uploader";

export default React.memo(Uploader);
