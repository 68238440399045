import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { ProcessesOverviewReadOnly } from "../../processes/overview/ProcessesOverviewReadOnly";
import { useMetaView } from "../../../contexts/meta-view-context";
import { ProcessFilterOptions } from "../../processes/overview/ProcessesOverviewController";
import { Box } from "@mui/material";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";

const TOMProcessesPage = ({ id, readonly }: { id: string; readonly readonly?: boolean }) => {
  const { t } = useTranslation("");
  const { setMeta } = useMetaView();
  const [hoveredPaId, setHoveredPaId] = useState<string | null>(null);
  const filter = useMemo<Partial<ProcessFilterOptions>>(() => ({ tomIDs: [id] }), [id]);

  const handleRowOver = useCallback(
    item => {
      if (hoveredPaId === item.id) {
        return;
      }
      setHoveredPaId(item.id);
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} separator={undefined} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("processes_overview:status_" + item.status)
      });
    },
    [hoveredPaId, setMeta, t]
  );

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("tom:processTabTitle")} />
      </Box>
      <Box>
        <ProcessesOverviewReadOnly filter={filter} onRowOver={handleRowOver} />
      </Box>
    </Box>
  );
};

export default TOMProcessesPage;
