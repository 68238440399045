import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const useStyles = makeStyles(() => ({
  docViewText: {
    marginBottom: "40px"
  },
  staticDocViewContent: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "500px"
  }
}));

export default function StaticDocViewTextContent({
  text,
  parseHtml = false
}: {
  readonly text: string;
  readonly parseHtml?: boolean;
}) {
  const classes = useStyles();

  return (
    <div className={classes.staticDocViewContent}>
      <Typography variant="h4" component="div" className={classes.docViewText} align={"center"}>
        {parseHtml ? parse(DOMPurify.sanitize(text)) : text}
      </Typography>
    </div>
  );
}
