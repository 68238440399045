import Grid from "@material-ui/core/Grid";
import SecurityIcon from "@material-ui/icons/Security";
import InboxIcon from "@material-ui/icons/Inbox";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import React, { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "@cubejs-client/react";
import { calculateByGoneTimeUTC } from "../../../components/BygoneTime";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import { GraphContainer, GraphContainerLoader } from "./GraphContainer";

const icon = { marginRight: 20, marginTop: -4 };
const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: "pointer"
  },
  tableCell: {
    borderBottom: "1px solid #f0f0f0"
  },
  iconContainer: {
    display: "flex",
    marginTop: 3
  },
  iconMeasure: {
    ...icon,
    color: theme.palette.blue[500]
  },
  iconTask: {
    ...icon,
    marginTop: -2,
    color: theme.palette.blue[500]
  },
  iconProcess: {
    ...icon,
    color: theme.palette.blue[500]
  },
  iconDPIA: {
    ...icon,
    color: theme.palette.yellow[500]
  },
  iconServiceProvider: {
    ...icon,
    color: theme.palette.blue[500]
  },
  table: {
    radius: 16
  }
}));

function ActivitiesTable({ activityData }) {
  const { t } = useTranslation("dashboardPage");
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid container alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead style={{ backgroundColor: "#fafafa" }}>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <span>{t("description")}</span>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <span>{t("type")}</span>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <span>{t("time")}</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityData.map(activity => {
                let icon;
                const typeTranslated = t(activity["Activities.doctype"]);
                switch (activity["Activities.doctype"]) {
                  case "measure":
                    icon = (
                      <div className={classes.iconMeasure}>
                        <SecurityIcon />
                      </div>
                    );
                    break;
                  case "task":
                    icon = (
                      <div className={classes.iconTask}>
                        <InboxIcon />
                      </div>
                    );
                    break;
                  case "serviceProvider":
                    icon = (
                      <div className={classes.iconServiceProvider}>
                        <LocationCityIcon />
                      </div>
                    );
                    break;
                  case "process":
                    icon = (
                      <div className={classes.iconProcess}>
                        <InsertDriveFileIcon />
                      </div>
                    );
                    break;
                  case "dpia":
                    icon = (
                      <div className={classes.iconDPIA}>
                        <VerifiedUserIcon />
                      </div>
                    );
                    break;
                  default:
                    icon = <div />;
                }

                return (
                  <TableRow
                    key={activity.key}
                    className={classes.tableRow}
                    hover={true}
                    onClick={() => {
                      navigate(activity["Activities.url"]);
                    }}
                  >
                    <TableCell className={classes.tableCell}>{activity["Activities.doctitle"]}</TableCell>
                    <TableCell className={classes.tableCell}>
                      <div className={classes.iconContainer}>
                        {icon} <span>{typeTranslated}</span>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>{activity["Activities.timestamp"]}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export const ActivitiesChartRenderer = function ({ cubejsApi }) {
  const { t } = useTranslation("dashboardPage");
  const numberActivities = 7;

  const query = useRef({
    dimensions: ["Activities.doctype", "Activities.doctitle", "Activities.timestamp", "Activities.url"],
    timeDimensions: [
      {
        dimension: "Activities.timestamp"
      }
    ],
    order: {
      "Activities.timestamp": "desc"
    },
    measures: [],
    limit: numberActivities
  });

  const render = useCallback(
    ({ resultSet, error }) => {
      if (error) {
        console.error("render activities tale error", error);
        return <div>{error.toString()}</div>;
      }

      if (!resultSet) {
        return <GraphContainerLoader />;
      }

      const pivotConfig = {
        x: ["Activities.doctitle", "Activities.doctype", "Activities.timestamp", "Activities.url"],
        y: [],
        fillMissingDates: true,
        joinDateRange: false
      };
      const activityData =
        resultSet?.tablePivot?.(pivotConfig)?.map(activity => {
          if (!activity["Activities.timestamp"]) {
            return activity;
          }

          const date = new Date(activity["Activities.timestamp"]);
          return {
            ...activity,
            key: date.getTime() + activity["Activities.url"],
            "Activities.timestamp": calculateByGoneTimeUTC(date, t)
          };
        }) || [];

      return <ActivitiesTable activityData={activityData} />;
    },
    [t]
  );

  return (
    <GraphContainer title={t("activitiesTitle")} loading={!cubejsApi}>
      <QueryRenderer query={query.current} cubejsApi={cubejsApi} resetResultSetOnChange={false} render={render} />
    </GraphContainer>
  );
};
