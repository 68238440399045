import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";
import { useAvailableLanguages } from "hook/useAvailableLanguages";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";

const LanguageQuestion = ({ questionName, value, disabled = false, error, helperText, onChange }: QuestionProps) => {
  const { t } = useTranslation("sidebar");
  const { availableLanguages } = useAvailableLanguages();

  const [lng, setLng] = useState<string>("");

  useEffect(() => {
    const _value: string = value as string;
    setLng(_value);
  }, [value]);

  const onChangeCallback = useCallback(value => {
    setLng(value);
  }, []);

  const getOptionLabel = useCallback(
    id => {
      return t(id);
    },
    [t]
  );

  return (
    <MultiAutocomplete
      label={questionName || t("lng")}
      disabled={disabled}
      hasMultiSelect={false}
      selected={lng || ""}
      updateSelected={onChange}
      options={availableLanguages}
      getOptionLabel={getOptionLabel}
      onChange={onChangeCallback}
      error={error}
      helperText={helperText}
    />
  );
};

export default React.memo(LanguageQuestion);
