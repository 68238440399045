import { ItemStatus } from "../../../../components/ListViewItem/ListViewItem";
import React, { useMemo } from "react";
import { LazySvgIcon } from "../../../../components/LazySvgIcon/LazySvgIcon";
import { AITechDocDTOStatusEnum } from "../../../api/generated/aitechdoc-service";
import { useTranslation } from "react-i18next";

export const useAITechDocStatuses = () => {
  const { t } = useTranslation();
  const aiTechDocStatuses: ItemStatus[] = useMemo(
    () => [
      {
        icon: <LazySvgIcon name="In_Progress" />,
        label: t(`aitechdoc_overview:status_${AITechDocDTOStatusEnum.Edit}`),
        status: AITechDocDTOStatusEnum.Edit
      },
      {
        icon: <LazySvgIcon name="In_Review" />,
        label: t(`aitechdoc_overview:status_${AITechDocDTOStatusEnum.Review}`),
        status: AITechDocDTOStatusEnum.Review
      },
      {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`aitechdoc_overview:status_${AITechDocDTOStatusEnum.Approved}`),
        status: AITechDocDTOStatusEnum.Approved
      }
    ],
    [t]
  );

  return aiTechDocStatuses;
};
