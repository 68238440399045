import { useEffect, useState } from "react";
import { getOAuth2SSOConfigApi, SSOConfigDTO } from "app/api/user/userSSOApi";
import { getLoginPreference } from "app/handlers/authentication/authenticationStorage";
import { withAbortController } from "../app/api/axios/axiosErrorHandler";

export const useSSOConfigs = ({ typedEmail }: { typedEmail: string }) => {
  const [loading, setLoading] = useState(true);
  const [emailDomain, setEmailDomain] = useState("");
  useEffect(() => {
    const emailParts = typedEmail.split("@");
    const emailDomain = emailParts[emailParts.length - 1] || "";
    if (emailDomain.split(".").length < 2) {
      return;
    }
    setEmailDomain(emailDomain || "");
  }, [typedEmail]);

  const [ssoConfig, setSSOConfig] = useState<Required<SSOConfigDTO> | null>(null);
  useEffect(() => {
    if (!emailDomain) {
      setSSOConfig(null);
      return;
    }

    return withAbortController({
      executeFn: async abortController => {
        setLoading(true);
        const ssoConfig = await getOAuth2SSOConfigApi({ emailDomain, abortController });
        setSSOConfig(ssoConfig ? toSSOConfig(ssoConfig) : null);
        setLoading(false);
      }
    });
  }, [emailDomain]);

  const [usedSSOs, setUsedSSOs] = useState<Required<SSOConfigDTO>[]>([]);
  useEffect(() => {
    const fetchAllSSOConfigs = async () => {
      const ssoIDs = (await getLoginPreference())?.ssoIds || [];
      const ssoConfigDTOs = await Promise.all(
        ssoIDs.map(ssoID => getOAuth2SSOConfigApi({ callbackIdentifier: ssoID }))
      );
      setUsedSSOs(
        ssoConfigDTOs.flatMap(ssoConfigDTO => {
          if (!ssoConfigDTO) {
            return [];
          }
          const ssoConfig = toSSOConfig(ssoConfigDTO);
          return ssoConfig ? [ssoConfig] : [];
        })
      );
    };

    fetchAllSSOConfigs();
  }, []);

  return { ssoConfig, emailDomain, usedSSOs, loading };
};

const toSSOConfig = (ssoConfig: SSOConfigDTO): Required<SSOConfigDTO> | null => {
  if (!ssoConfig?.callbackIdentifier) {
    return null;
  }
  return {
    callbackIdentifier: ssoConfig.callbackIdentifier,
    ssoOnly: !!ssoConfig.ssoOnly,
    name: ssoConfig.name || ssoConfig.callbackIdentifier
  };
};
