import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.publicLinkUrl}/api`
});

export const postDSRPublicLinkApi = async (payload: CreatePublicLinkDTO) => {
  const response = await axiosInstance.post("/", payload);
  return response.data.id || "";
};

export const patchDSRPublicLinkApi = async (id: string, payload: UpdatePublicLinkDTO) => {
  const response = await axiosInstance.patch(`/${id}`, payload);
  return response.data.id || "";
};

export const deleteDSRPublicLinkApi = async (id: string) => {
  await axiosInstance.delete(`/${id}`);
};

export const getDSRPublicLinksApi = async (): Promise<PublicLinksDTO> => {
  const response = await axiosInstance.get("/");
  return response.data || { links: [] };
};

export const getDSRPublicLinkApi = async (id: string): Promise<PublicLinkDTO | null> => {
  try {
    const response = await axiosInstance.get<PublicLinkDTO | null>(`/${id}`);
    return response.data || null;
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export interface CreatePublicLinkDTO {
  readonly orgUnitId: string;
  readonly collection?: string;
  readonly furtherOrgUnitIds?: string[];
  readonly description?: string;
  readonly email?: string;
}

export interface UpdatePublicLinkDTO {
  readonly description?: string;
  readonly email?: string;
  readonly orgUnitId?: string;
  readonly furtherOrgUnitIds?: string[];
  readonly collection?: string;
}

export interface PublicLinksDTO {
  readonly links: PublicLinkDTO[];
}

export interface PublicLinkDTO {
  readonly id: string;
  readonly description: string;
  readonly email: string;
  readonly collection: string;
  readonly orgUnitId: string;
  readonly furtherOrgUnitIds: string[];
  readonly link: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: string;
  readonly updatedBy: string;
}
