import { getHasuraClientSDK } from "app/api/hasuraApi";

export interface UserSeenItem {
  readonly id: string;
  readonly collection: string;
  readonly itemId: string;
  readonly created: Date;
}

export const getSeenItemsOfUsers = async (tenantId: string, userId: string): Promise<UserSeenItem[]> => {
  const client = await getHasuraClientSDK();
  const response = await client.seenItemsGetByUser({
    tenant_id: tenantId,
    user_id: userId
  });

  return response.frontend_access_user_seen_item.map(it => ({
    id: it.item_id,
    collection: it.collection,
    itemId: it.item_id,
    created: it.created_at
  }));
};

export const addToSeenItemsOfUser = async (tenantId: string, userId: string, collection: string, newItemId: string) => {
  const client = await getHasuraClientSDK();
  await client.seenItemUpsert({
    collection: collection,
    item_id: newItemId
  });
};

export const removeFromSeenItemsOfUser = async (
  tenantId: string,
  userId: string,
  collection: string,
  itemId: string
) => {
  const client = await getHasuraClientSDK();
  await client.seenItemDelete({
    tenant_id: tenantId,
    user_id: userId,
    collection: collection,
    item_id: itemId
  });
};
