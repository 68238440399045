export const getPersonGroupAndDataCategoryKeysFromDataTypeIds = (dataTypeIds, dataTypeCategoryPersonGroupList) => {
  const personGroupKeys = [];
  const dataCategoryKeys = [];
  if (dataTypeCategoryPersonGroupList?.length) {
    dataTypeCategoryPersonGroupList.forEach(personGroup => {
      personGroup.dataCategories.forEach(category => {
        if (!category?.dataTypes) {
          return;
        }
        category.dataTypes
          .filter(type => !type.mergedInto)
          .forEach(type => {
            if (dataTypeIds?.includes(type.id)) {
              dataCategoryKeys.push(category.id);
              personGroupKeys.push(personGroup.id);
            }
          });
      });
    });
  }
  return {
    dataCategoryKeys: [...new Set(dataCategoryKeys)],
    personGroupKeys: [...new Set(personGroupKeys)]
  };
};

/**
 * return a property-tree in the form
 * personGroups
 *  categories
 *    dataTypes
 * with the corresponding entity-key, label (the translation) and children (if present)
 * persongroups and categories that do not have a relation with the given dataTypeId (i.e. are not its grandparent/parent)
 * are excluded
 * @param dataTypeIds
 * @param dataTypeCategoryPersonGroupList
 * @param translateResourceByKey
 */
export const getResourceTreeRelatedToDataTypeIds = (
  dataTypeIds,
  dataTypeCategoryPersonGroupList,
  translateResourceByKey
) => {
  const { dataCategoryKeys, personGroupKeys } = getPersonGroupAndDataCategoryKeysFromDataTypeIds(
    dataTypeIds,
    dataTypeCategoryPersonGroupList
  );

  return dataTypeCategoryPersonGroupList
    ?.filter(personGroup => personGroupKeys.includes(personGroup.personGroupKey))
    .map(personGroup => {
      const relatedCategories = personGroup.dataCategories
        .filter(category => dataCategoryKeys.includes(category.dataCategoryKey))
        .map(category => ({
          key: category.dataCategoryKey,
          label: translateResourceByKey(category.dataCategoryKey),
          dataTypes: category.dataTypes
            .filter(dataType => dataTypeIds.includes(dataType.id))
            .map(dataType => ({
              key: dataType.dataTypeKey,
              label: translateResourceByKey(dataType.dataTypeKey)
            }))
        }));

      return {
        key: personGroup.personGroupKey,
        label: translateResourceByKey(personGroup.personGroupKey),
        categories: relatedCategories
      };
    });
};
