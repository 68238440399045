import React from "react";
import Grid from "@material-ui/core/Grid";
import { PAChart } from "./PAChart";
import { ExternalRecipientPieChart } from "./ExternalRecipientChart";
import { AuditsChart } from "./AuditsChart";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { Box, CircularProgress } from "@material-ui/core";

export const ChartRenderer = ({ timePeriod, orgUnitId, label }) => {
  const isAuditEnabled = useIsFeaturePresent(FEATURES.AUDIT);
  if (isAuditEnabled === null) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={470}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
      <Grid item xs={true}>
        <PAChart timePeriod={timePeriod} orgUnitId={orgUnitId || ""} labelId={label || ""} onlyDPIA={false} />
      </Grid>
      <Grid item xs={true}>
        <PAChart timePeriod={timePeriod} orgUnitId={orgUnitId || ""} labelId={label || ""} onlyDPIA={true} />
      </Grid>
      <Grid item xs={true}>
        <ExternalRecipientPieChart label={label} orgUnitId={orgUnitId} timePeriod={timePeriod} />
      </Grid>
      {isAuditEnabled && (
        <Grid item xs={true}>
          <AuditsChart timePeriod={timePeriod} orgUnitId={orgUnitId || ""} labelId={label || ""} />
        </Grid>
      )}
    </Grid>
  );
};
