import { Box, Typography } from "@material-ui/core";
import { useComments } from "app/api/commentApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import CommentsDisplay from "app/pages/shared/CommentsDisplay/CommentsDisplay";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "../../../collections";
import { UserDTO } from "../../../api/user/userApi";
import { TaskInfoLabel } from "../details/components/TaskInfoLabel";
import CommentIcon from "@mui/icons-material/Comment";

const commentNamespace = "task";

interface TaskCommentsProps {
  readonly taskId: string;
  readonly groupId?: string;
  readonly assigneeUID: string;
  readonly creatorUID: string;
  readonly onChangeParticipants: (participants: string[]) => void;
  readonly participants: string[];
  readonly handleAddMentions?: (mentionedUsers: UserDTO[]) => void;
  readonly newlyAddedCommentId: string;
  readonly setNewlyAddedCommentId: (commentId: string) => void;
  readonly taskDetailsContainerRef: React.RefObject<HTMLDivElement | null>;
}

export default function TaskComments({
  taskId,
  groupId,
  assigneeUID,
  creatorUID,
  onChangeParticipants,
  participants,
  handleAddMentions,
  newlyAddedCommentId,
  setNewlyAddedCommentId,
  taskDetailsContainerRef
}: TaskCommentsProps) {
  const { t } = useTranslation("task_details");
  const { user } = useAuthentication();
  const [showCommentForm, setShowCommentForm] = React.useState(false);

  const handleToggleCommentForm = React.useCallback(() => {
    setShowCommentForm(state => !state);
  }, []);

  const namespace = groupId
    ? assigneeUID
      ? `task groupTaskId ${groupId} assigneeUID ${assigneeUID}`
      : undefined
    : commentNamespace;
  const namespacePrefix = !groupId || assigneeUID ? undefined : `task groupTaskId ${taskId}`;

  const comments = useComments({
    documentId: taskId,
    namespace,
    namespacePrefix
  });

  const canComment = React.useMemo(() => {
    if (!user || !assigneeUID || !creatorUID) return false;
    return user.id === creatorUID || user.id === assigneeUID || participants.includes(user.id);
  }, [assigneeUID, creatorUID, participants, user]);

  return (
    <Box mt={2} mb={2}>
      <TaskInfoLabel icon={CommentIcon} text={t("comments")} />
      <CommentsDisplay
        assigneeUID={assigneeUID}
        buttonTextCancel={t("common:cancel")}
        buttonTextReply={t("common:reply")}
        buttonTextSave={t("common:save")}
        clickOnCommentBadge={showCommentForm}
        collection={COLLECTIONS.TASK_DETAIL}
        docId={taskId}
        docName={"common:comments"}
        groupId={groupId}
        handleAddMentions={handleAddMentions}
        hidePagination={true}
        newlyAddedCommentId={newlyAddedCommentId}
        onCancelComment={handleToggleCommentForm}
        onChangeParticipants={onChangeParticipants}
        onSubmitComment={handleToggleCommentForm}
        pageId={"pageId"}
        participants={participants}
        preselectedCommentId={""}
        questionId={commentNamespace}
        setNewlyAddedCommentId={setNewlyAddedCommentId}
        taskDetailsContainerRef={taskDetailsContainerRef}
      />
      {!showCommentForm && canComment && !comments.data?.commentModels?.length && (
        <Box mt={1}>
          <Typography variant="body2" color="textSecondary">
            {t("no_comments_yet")}.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
