export const logSecurityMessage = () => {
  console.log(`
                     _                  _ 
                    | |                | |
  ___ __ _ _ __ __ _| | ___  __ _  __ _| |
 / __/ _\` | '__/ _\` | |/ _ \\/ _\` |/ _\` | |
| (_| (_| | | | (_| | |  __/ (_| | (_| | |
 \\___\\__,_|_|  \\__,_|_|\\___|\\__, |\\__,_|_|
                             __/ |        
                            |___/         



v-${import.meta.env.VITE_RELEASE_ID || "local"}
    `);
  console.info("Found a security issue? security@caralegal.eu");
  console.info("Please do not copy paste any code you found in the console. It might pose a security risk.");
};
