import React from "react";
import { Box, Card, Typography } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import CatImage from "../../assets/images/icons/cat.svg?img";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const useStyles = makeStyles((theme: any) => ({
  infoCard: {
    backgroundColor: theme.palette.green[100]
  },
  infoCardHeader: {
    fontWeight: "bold",
    fontSize: "20px",
    color: theme.palette.green[500]
  },
  infoCardContent: {
    lineHeight: "24px",
    color: theme.palette.green[500]
  },
  infoCardIcon: {
    color: theme.palette.common.black
  }
}));

export default function InsightCard({
  infoCardHeader,
  infoCardContent
}: {
  readonly infoCardHeader?: React.ReactNode;
  readonly infoCardContent?: React.ReactNode | string;
}) {
  const cls = useStyles();
  return (
    <Box mt={3}>
      <Card className={cls.infoCard}>
        {infoCardHeader && (
          <Box p={1}>
            <CardHeader
              avatar={<CatImage className={cls.infoCardIcon} alt="caracat (caralegal avatar)" />}
              title={<span className={cls.infoCardHeader}>{infoCardHeader}</span>}
            />
          </Box>
        )}
        {infoCardContent && (
          <Box p={1} mt={-3}>
            <CardContent>
              {typeof infoCardContent === "string" ? (
                <Typography className={cls.infoCardContent} variant="body2" component="span">
                  {parse(DOMPurify.sanitize(infoCardContent))}
                </Typography>
              ) : (
                infoCardContent
              )}
            </CardContent>
          </Box>
        )}
      </Card>
    </Box>
  );
}
