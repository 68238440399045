import React, { useCallback, useEffect, useState } from "react";
import InlineIcons from "../InlineIcons/InlineIcons";
import TextBody1 from "../TextBody1/TextBody1";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export default function InlineConfirmation({
  index,
  value,
  confirmToolTip,
  cancelToolTip,
  confirmActionText,
  onConfirm,
  onCancel,
  startIcon
}) {
  const [itemName, setItemName] = useState(value ?? "");
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(() => {
    onConfirm(index);
    setLoading(true);
    setItemName("");
  }, [index, onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel();
    setItemName("");
    setLoading(false);
  }, [onCancel]);

  const [inlineIconsData, setInlineIconsData] = useState([]);
  useEffect(() => {
    setInlineIconsData([
      {
        icon: "CheckIcon",
        toolTipTitle: confirmToolTip,
        callBackFunction: handleConfirm
      },
      {
        icon: "ClearIcon",
        toolTipTitle: cancelToolTip,
        callBackFunction: handleCancel
      }
    ]);
  }, [confirmToolTip, cancelToolTip, handleConfirm, handleCancel]);

  return (
    <div data-testid={`inline-confirmation-${index}`} style={{ flex: 1 }}>
      {loading && <CircularProgress size={24} />}

      {!loading && (
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          {startIcon && (
            <Grid item>
              <Grid container alignItems="center">
                <ListItemIcon>{startIcon}</ListItemIcon>
              </Grid>
            </Grid>
          )}
          <Grid item xs={true} zeroMinWidth={true}>
            <TextBody1 text={`${confirmActionText}${itemName}?`} />
          </Grid>
          <Grid item>
            <InlineIcons iconSize={"medium"} data={inlineIconsData} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
