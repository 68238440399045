import { Typography } from "@material-ui/core";
import { Stack, useTheme } from "@mui/material";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { useCallback } from "react";

export type ItemPaginationProps = {
  total: number;
  current: number;
  onChange: (page: number) => void;
  itemName?: string;
};

export default function ItemPagination(props: ItemPaginationProps) {
  const theme = useTheme();
  const handlePrev = useCallback(() => {
    props.onChange(props.current - 1);
  }, [props]);
  const handleNext = useCallback(() => {
    props.onChange(props.current + 1);
  }, [props]);
  const canGoPrev = props.current > 1;
  const canGoNext = props.current < props.total;
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        "> *": {
          margin: "5px !important"
        },
        alignItems: "center"
      }}
    >
      <LazySvgIcon name="ArrowLeft" color={theme.palette.grey[600]} onClick={handlePrev} disabled={!canGoPrev} />
      <Typography variant="body2" color="textSecondary">
        {props.current} of {props.total} {props.itemName ?? ""}
      </Typography>
      <LazySvgIcon name="ArrowRight" color={theme.palette.grey[600]} onClick={handleNext} disabled={!canGoNext} />
    </Stack>
  );
}
