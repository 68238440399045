import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { useMetaView } from "../../../contexts/meta-view-context";
import DateDisplay from "../../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../../components/UserDataDisplay";
import { useNavigate, useParams } from "react-router-dom";
import { RiskMatrixPage } from "../RiskMatrixPage";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { exportRisksExcel } from "app/export/createdExcelExportData";
import { riskWritePermissions } from "../../../handlers/permissionHandler";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { OverviewPageProps } from "../../../../components/Overview/controls/OverviewPagesMenu";
import { CollectionParams } from "../../../../hook/useOverviewData";
import MetaViewList, { MetaViewListItemProps } from "../../../../components/MetaViewList/MetaViewList";
import useSWR from "swr";
import { getRisksProcesses } from "../../../api/riskApi";
import { useUserDepartments } from "../../../contexts/department-context";
import { Box } from "@mui/material";
import EditIcon from "../../../../assets/images/icons/edit.svg";
import SearchIcon from "@material-ui/icons/Search";
import DoneIcon from "@material-ui/icons/Done";

export enum RiskPages {
  GENERAL = "general",
  PROCESSING_ACTIVITY = "processing-activity",
  ASSET = "asset",
  RISK_MATRIX = "risk-matrix"
}

const RiskOverview = () => {
  const { t } = useTranslation("risks_overview");
  const navigate = useNavigate();
  const dispatch = useOverviewDispatch();
  const { auth } = useAuthentication();
  const assetRisksEnabled = useIsFeaturePresent(FEATURES.ASSET_RISKS);
  const currentPage = (useParams()?.page || RiskPages.GENERAL) as RiskPages;

  const exportToXLSX = useCallback(
    async ids => {
      if (auth?.tenantId) {
        return await exportRisksExcel(auth?.tenantId, t, ids);
      }
    },
    [auth?.tenantId, t]
  );
  const selectionActions = useMemo(() => [{ action: "export-xlsx" }, { action: "remove" }], []);

  const { setInfo, setMeta } = useMetaView();
  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("risks_overview:enteringInfoCardTitle"),
        text: t("risks_overview:enteringInfoCardText")
      },
      creating: {
        title: t("risks_overview:creating_info_card_title"),
        text: t("risks_overview:creating_info_card_text")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);
  const showCreateInfo = useCallback(() => {
    setInfo(infoCard?.creating);
  }, [infoCard, setInfo]);
  const [onHoverId, setOnHoverId] = useState<string>("");
  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        riskOwner: <AutomaticUserDataDisplay uid={item.ownerUID} />
      });
      setOnHoverId(item.id);
    },
    [setMeta]
  );

  const pages = useMemo<OverviewPageProps[]>(
    () =>
      [
        { title: t("tabGeneral"), route: RiskPages.GENERAL, current: currentPage === RiskPages.GENERAL },
        {
          title: t("tabProcess"),
          route: RiskPages.PROCESSING_ACTIVITY,
          current: currentPage === RiskPages.PROCESSING_ACTIVITY
        },
        assetRisksEnabled
          ? {
              title: t("tabAsset"),
              route: RiskPages.ASSET,
              current: currentPage === RiskPages.ASSET
            }
          : null,
        { title: t("riskMatrix"), route: RiskPages.RISK_MATRIX, current: currentPage === RiskPages.RISK_MATRIX }
      ].flatMap(it => (it ? [it] : [])),
    [assetRisksEnabled, currentPage, t]
  );

  const collectionParams = useMemo<CollectionParams>(
    () => ({
      exportToXLSX,
      filter: {
        riskIds: currentPage === RiskPages.RISK_MATRIX ? ["empty-id-to-return-nothing"] : undefined,
        type:
          currentPage === RiskPages.GENERAL
            ? RiskPages.GENERAL
            : currentPage === RiskPages.PROCESSING_ACTIVITY
              ? RiskPages.PROCESSING_ACTIVITY
              : undefined
      }
    }),
    [currentPage, exportToXLSX]
  );
  const showAddActions =
    auth?.permissions.find(permission => riskWritePermissions.includes(permission)) &&
    currentPage !== RiskPages.RISK_MATRIX;
  const riskTemplatesEnabled = useIsFeaturePresent(FEATURES.RISKS_TEMPLATES);

  const addActions = useMemo(
    () => [
      { action: OVERVIEW_ADD_TYPE.SINGLE },
      { action: OVERVIEW_ADD_TYPE.MULTIPLE },
      ...(riskTemplatesEnabled ? [{ action: OVERVIEW_ADD_TYPE.TEMPLATE }] : [])
    ],
    [riskTemplatesEnabled]
  );

  const onPageChange = useCallback(
    ({ route }) => {
      navigate(`/risks/${route}`);
      dispatch({
        type: OVERVIEW_ACTIONS.RELOAD,
        collection: COLLECTIONS.RISK,
        reload: {
          reloadOverview: Date.now(),
          reloadMetaview: Date.now(),
          selectedId: null
        }
      });
    },
    [dispatch, navigate]
  );

  const toolbarActions = useMemo(() => {
    return currentPage === RiskPages.RISK_MATRIX
      ? []
      : [{ action: "sort" }, { action: "filter" }, { action: "export-xlsx" }];
  }, [currentPage]);

  const rowActions = useMemo(() => {
    return currentPage === RiskPages.RISK_MATRIX ? [] : [{ action: "edit" }, { action: "remove" }];
  }, [currentPage]);

  return (
    <DocMetaView
      metaViewContent={
        <>
          <MetaView translationKey={"risks_overview"}>
            {currentPage === RiskPages.PROCESSING_ACTIVITY ? (
              <Box mt={3}>
                <ProcessesOfRisk riskId={onHoverId} />
              </Box>
            ) : (
              <></>
            )}
          </MetaView>
        </>
      }
    >
      <Overview
        header={t("risks_overview:header")}
        key={`risks-${currentPage}`}
        pages={pages}
        toolbarActions={toolbarActions}
        addActions={showAddActions ? addActions : undefined}
        collection={COLLECTIONS.RISK}
        rowActions={rowActions}
        checkable={true}
        collectionParams={collectionParams}
        selectionActions={selectionActions}
        toolbarMode={"tabs"}
        hideSearch={currentPage === RiskPages.RISK_MATRIX}
        hideCount={currentPage === RiskPages.RISK_MATRIX}
        hideNoEntries={currentPage === RiskPages.RISK_MATRIX}
        onPageChange={onPageChange}
        onAddOpen={showCreateInfo}
        onAddClose={showEnterInfo}
        onRowOver={onRowOver}
        showSelectAll={currentPage !== RiskPages.RISK_MATRIX}
      >
        {currentPage === RiskPages.RISK_MATRIX && <RiskMatrixPage key="all-risks-heatmap" />}
      </Overview>
    </DocMetaView>
  );
};

const ProcessesOfRisk = ({ riskId }: { riskId: string }) => {
  const { getDepartmentWithParentName } = useUserDepartments();
  const { t } = useTranslation("risks_overview");

  const { data, error, isValidating } = useSWR(riskId ? ["risks", riskId, "pas"] : null, async ([, selectedId]) =>
    getRisksProcesses(selectedId)
  );

  const items = useMemo<MetaViewListItemProps[]>(
    () =>
      (data?.pas || []).map(
        it =>
          ({
            id: it.id,
            title: it.name,
            subTitle: (it.orgUnit || []).map(getDepartmentWithParentName).join(", "),
            icon: paStatusIcons[it.status] || <></>
          }) satisfies MetaViewListItemProps
      ),
    [data?.pas, getDepartmentWithParentName]
  );

  const onItemClickCallback = useCallback((id: string) => {
    window.open(`/processes/${id}/general`, "_blank");
  }, []);

  if (!riskId) {
    return <></>;
  }

  if (error) {
    return <></>;
  }

  return (
    <MetaViewList
      header={t("processes_overview:list_processes")}
      items={items}
      loading={isValidating}
      onItemClick={onItemClickCallback}
    />
  );
};

const paStatusIcons: Record<string, React.ReactNode> = {
  edit: <EditIcon />,
  review: <SearchIcon />,
  approved: <DoneIcon />
};

export default RiskOverview;
