import { useEffect } from "react";
import { SmartLookTracker } from "../../tool/tracker";
import { FEATURES } from "../features";
import { useIsFeaturePresent } from "../../hook/useIsFeaturePresent";

export const SmartlookLoader = () => {
  const isSmartLookTurnedOff = useIsFeaturePresent(FEATURES.TURNOFF_SMARTLOOK);

  useEffect(() => {
    const featureFeatureNotLoaded = isSmartLookTurnedOff === null;
    if (featureFeatureNotLoaded) {
      return;
    }

    if (isSmartLookTurnedOff) {
      SmartLookTracker.disableSmartLook();
    } else {
      SmartLookTracker.enableSmartLook();
    }
  }, [isSmartLookTurnedOff]);

  return <></>;
};
