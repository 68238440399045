import React, { useCallback } from "react";
import { QuestionProps } from "../../Question";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useTranslation } from "react-i18next";

const DeletionTriggerQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange,
  orgUnitIds,
  onlyIntersectingOrgUnitIds
}: QuestionProps) => {
  const { t } = useTranslation("questionnaires");
  const _value: string = (Array.isArray(value) ? value[0] : value) as string;
  const onChangeCallback = useCallback(
    (deletiontriggerId: string | string[] | null) => {
      onChange?.(deletiontriggerId);
    },
    [onChange]
  );
  return (
    <ResourceField
      disabled={disabled}
      value={_value}
      resourceType={RESOURCE_TYPES.DELETION_TRIGGER}
      label={questionName || t("resources_legal-retention-period_overview:deletionTrigger")}
      onChange={onChangeCallback}
      onFocus={onFocus}
      onBlur={onBlur}
      multiSelect={false}
      allowAdd={true}
      error={error}
      helperText={helperText}
      docOrgUnitIds={orgUnitIds}
      onlyIntersectingOrgUnitIds={onlyIntersectingOrgUnitIds}
    />
  );
};

export default DeletionTriggerQuestion;
