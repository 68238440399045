import { Box, Checkbox } from "@mui/material";
import { Department } from "../../../handlers/departmentHandler";
import React, { useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { createNaturalSorter } from "../../../utils/naturalSort";

export const OrgUnitList = ({
  allDepartments,
  checkedDepartmentIds,
  enabledDepartmentIds,
  onChecked,
  searchText
}: {
  readonly allDepartments: Map<string, Department>;
  readonly checkedDepartmentIds: Set<string>;
  readonly enabledDepartmentIds: Set<string>;
  readonly onChecked: (department: Department, checked: boolean) => void;
  readonly searchText?: string;
}) => {
  // we do this since we don't want that by uncheck it disappears from the list
  const [uncontrolledInitialDepartments] = useState<Set<string>>(checkedDepartmentIds);
  const initiallyCheckedDepartments = useMemo(() => {
    return [...uncontrolledInitialDepartments]
      .map(id => allDepartments.get(id))
      .filter((department): department is Department => !!department);
  }, [uncontrolledInitialDepartments, allDepartments]);

  const departmentsToShow = useMemo(() => {
    const natSorter = createNaturalSorter();
    return initiallyCheckedDepartments
      .filter(department => {
        return searchText ? department.name.toLowerCase().includes(searchText.toLowerCase()) : true;
      })
      .map(department => ({
        department,
        nameForSort: `${enabledDepartmentIds.has(department.id) ? "000-first" : "999-last"}-${department.name}`
      }))
      .sort((a, b) => natSorter(a.nameForSort, b.nameForSort))
      .map(({ department }) => department);
  }, [enabledDepartmentIds, initiallyCheckedDepartments, searchText]);

  const Row = ({ index, style }: { readonly index: number; readonly style: React.CSSProperties }) => {
    const department = departmentsToShow[index];
    return (
      <Box key={department.id} display="flex" alignItems="center" height={40} style={style}>
        <Checkbox
          disabled={!enabledDepartmentIds.has(department.id)}
          checked={checkedDepartmentIds.has(department.id) /* eslint-disable-next-line react/jsx-no-bind */}
          onChange={() => onChecked(department, !checkedDepartmentIds.has(department.id))}
        />
        <LabelWithSearch name={department.name} searchText={searchText} />
      </Box>
    );
  };

  return (
    <Box>
      <Box height={180}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList height={height} width={width} itemSize={40} itemCount={departmentsToShow.length}>
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

const LabelWithSearch = ({ name, searchText }: { readonly name: string; readonly searchText?: string }) => {
  let text = <Box>{name}</Box>;
  if (searchText && searchText.length) {
    const index = name.toLowerCase().indexOf(searchText.toLowerCase());
    if (index !== -1) {
      text = (
        <Box>
          <span>{name.substring(0, index)}</span>
          <span style={{ fontWeight: 700 }}>{name.substring(index, index + searchText.length)}</span>
          <span>{name.substring(index + searchText.length)}</span>
        </Box>
      );
    }
  }

  return text;
};
