import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewItem,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { AxiosInstance, AxiosResponse } from "axios";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { getTextFromTextEditorJsonString } from "../../questionnaires/utils/textEditorConverter";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { getAITechDocStatusFilter, getLabelsFilter } from "../../shared/Filters/filters";
import { getOrganizationFilter } from "../../shared/Filters/OrganizationFilter";
import { CollectionParams } from "../../../../hook/useOverviewData";

const AITechDocsOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: CollectionParams
): OverviewController => {
  const { addToSeenItemsOfUserHook, loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { t } = useTranslation();
  const { t: tFilter } = useTranslation("filter_criteria");
  const normalize = useCallback(
    (item: Pick<OverviewItem, "title" | "subTitle" | "id">) => ({
      ...item,
      subTitle: getTextFromTextEditorJsonString(item.subTitle || ""),
      allOrgUnitIds: [(item as any).mainOrgUnitId, ...((item as any).furtherOrgUnitIds || [])]
    }),
    []
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.AI_TECH_DOCS, normalize, [
    resourcesDecorator,
    departmentsDecorator
  ]);

  const getFilters = useCallback(
    (overviewResult: OverviewResult) => [
      getAITechDocStatusFilter("status", t),
      getLabelsFilter("labelIds", overviewResult._resources, t),
      getOrganizationFilter("allOrgUnitIds", overviewResult._departments, t, undefined),
      getOrganizationFilter("mainOrgUnitId", overviewResult._departments, t, "responsibleDepartment"),
      getOrganizationFilter("furtherOrgUnitIds", overviewResult._departments, t, "furtherDepartments")
    ],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: tFilter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: tFilter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: tFilter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: tFilter("oldFirst")
      }
    ],
    [tFilter]
  );

  const goToItem = (id: string) => {
    baseController.goToItem(`/ai-tech-docs/${id}/general`);
  };

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup, "/overview");
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const addItem = async (data: OverviewNewItem): Promise<AxiosResponse> => {
    const response = await baseController.addItem({
      name: data?.title || createOverviewItemDefaultName("ai-tech-docs")
    });
    const aiTechDocId = response.headers["x-resource-id"] || "";

    // add to seen items
    await addToSeenItemsOfUserHook(COLLECTIONS.AI_TECH_DOCS, aiTechDocId);
    await loadSeenItemsOfUserHook();
    return response;
  };

  const deleteItem = async (id: string): Promise<void> => {
    await baseController.deleteItem(id);
    await loadSeenItemsOfUserHook();
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, {
      name: "title" in data && data.title
    });
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem(data);
    goToItem(response.headers["x-resource-id"] || "");
  };

  const exportItems = async (format: string, ids: string[], setup: OverviewSetup) => {
    await collectionParams?.exportToXLSX?.(ids);
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup);
    if (data) {
      const ids = data.allItems.map(({ id }) => id);
      await exportItems(format, ids, setup);
    }
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    deleteItem,
    patchItem,
    addItemAndGo,
    exportItems,
    exportAllItems
  };
};

export default AITechDocsOverviewController;
