import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { getDataStorageLocationFilter, getPersonGroupFilter } from "app/pages/shared/Filters/filters";
import { dataLocationsDecorator } from "../../../../components/Overview/controllers/decorator/dataLocationsDecorator";
import { tSimpleTranslate } from "../../../handlers/dataTypeTranslatorHandler";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { AxiosInstance } from "axios";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { getOrganizationFilter } from "../../shared/Filters/OrganizationFilter";

const DeletionConceptOverviewController = (axiosInstance: AxiosInstance): OverviewController => {
  const { t } = useTranslation("lists_data_types_categories_person_groups");
  const dataTypeTree = useDataTypeTree();

  const translateItem = useCallback(
    obj => ({ ...obj, title: tSimpleTranslate({ t, typeCategoryOrPersonGroup: obj.title }) }),
    [t]
  );

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.DELETION_CONCEPT, translateItem, [
    dataLocationsDecorator,
    departmentsDecorator
  ]);

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t("filter_criteria:aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t("filter_criteria:zToA")
      }
    ],
    [t]
  );

  const getFilters = useCallback(
    (data: OverviewResult) => [
      getOrganizationFilter("orgUnitIds", data._departments, t, undefined),
      getPersonGroupFilter("personGroupId", dataTypeTree.data || [], t),
      getDataStorageLocationFilter("dataStorageIds", data._dataLocations, t)
    ],
    [dataTypeTree.data, t]
  );

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    if (!dataTypeTree.data) {
      console.error("Person Groups Data not loaded yet");
    }
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings(),
      items: data.items
    };
  };

  const goToItem = () => {
    // do nothing as deletion concept have no click action
  };
  return {
    ...baseController,
    getOverview,
    goToItem
  };
};

export default DeletionConceptOverviewController;
