import { COLLECTIONS } from "app/collections";
import { AxiosInstance } from "axios";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getWebsiteGDPRRiskLevelFilter } from "app/pages/shared/Filters/filters";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useAwayNavigation } from "components/NavigateAway";

const WebsiteOverviewController = (axiosInstance: AxiosInstance): OverviewController => {
  const { t } = useTranslation();

  const sortings = useMemo(
    () => [
      {
        field: "title",
        type: "asc",
        label: t("filter_criteria:aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t("filter_criteria:zToA")
      },
      {
        field: "startedAt",
        type: "desc",
        label: t("filter_criteria:newFirst")
      },
      {
        field: "startedAt",
        type: "asc",
        label: t("filter_criteria:oldFirst")
      },
      {
        field: "riskLevel",
        type: "desc",
        label: t("filter_criteria:riskLevelLowToHigh")
      },
      {
        field: "riskLevel",
        type: "asc",
        label: t("filter_criteria:riskLevelHighToLow")
      }
    ],
    [t]
  );
  const riskLevelFilter = getWebsiteGDPRRiskLevelFilter("riskLevel", t);
  const filters = useMemo(() => [riskLevelFilter], [riskLevelFilter]);

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      items: data?.items || [],
      sortings: sortings,
      filters: filters
    };
  };

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.WEBSITES, (item: any) => ({
    ...item,
    subTitle: t("websites_overview:scannedAt", { scanTime: new Date(item.subTitle).toLocaleString() })
  }));

  const { setLink } = useAwayNavigation();
  const goToItem = async (id: string) => {
    setLink((await axiosInstance.get(`/${id}/scans/latest/links`)).data.scanUrl);
  };

  const addItem = async (data: OverviewNewItem) => {
    return await baseController.addItem({
      url: data.title
    });
  };

  const patchItem = async (id: string, data: object) => {
    const url = ("title" in data && (data.title as string)) || "";
    return await baseController.patchItem(id, {
      url: url
    });
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await baseController.addItem({
      url: data.title
    });
    return goToItem(response.headers["x-resource-id"] || "");
  };

  return {
    ...baseController,
    goToItem,
    addItem,
    patchItem,
    addItemAndGo,
    getOverview,
    validateItem: (data: object) => {
      const title: string = ("title" in data && (data.title as string)) || "";
      if (!title.includes(".")) {
        return t("websites_overview:invalidURL");
      }

      try {
        new URL(title.startsWith("http") ? title : `https://${title}`);
      } catch (error) {
        return t("websites_overview:invalidURL");
      }

      return null;
    }
  };
};

export default WebsiteOverviewController;
