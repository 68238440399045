import { useCallback } from "react";
import { yyyyMMddTODate } from "app/handlers/utility/date-helper";

export interface BizchartModel {
  readonly date: string;
  readonly type: string;
  readonly count: number;
}

export const useBizChartModel = () => {
  const convertStatsToBizChartModel = useCallback(
    (
      stats: { readonly date: string; readonly value: Record<string, number> }[],
      format: Intl.DateTimeFormatOptions,
      language: Intl.LocalesArgument
    ): BizchartModel[] => {
      const statisticValues = stats.flatMap(stat => {
        const date = yyyyMMddTODate(stat.date).toLocaleDateString(language || "default", format);
        return Object.entries(stat.value).map(([key, value]) => ({
          date,
          type: key,
          count: value
        }));
      });
      if (statisticValues.every(statValue => statValue.count === 0)) {
        // if all stats are 0 then empty array as it's really just no data (instead of empty chart)
        return [];
      }
      return statisticValues;
    },
    []
  );

  const combinePieChartDataOnType = useCallback((bizChartModel: BizchartModel[]): Omit<BizchartModel, "date">[] => {
    const simplifiedType = bizChartModel.reduce<Record<string, number>>((acc, next) => {
      return {
        ...acc,
        [next.type]: (acc[next.type] || 0) + next.count
      };
    }, {});
    return Object.entries(simplifiedType).map(([key, value]) => ({ type: key, count: value }));
  }, []);

  return { convertStatsToBizChartModel, combinePieChartDataOnType };
};
