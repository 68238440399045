import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DocMetaView from "components/DocMetaView/DocMetaView";
import AccountSettingsReauthenticate from "../AccountSettingsReauthenticate";
import DocViewWithBackButton from "components/DocView/DocViewWithBackButton";
import { CircularProgress } from "@material-ui/core";
import AccountSettingsChangePassword from "./AccountSettingsChangePassword";
import { useNavigateWithQueryParams } from "app/router/router-custom-hooks";
import MetaView from "components/MetaView/MetaView";

export const AccountSettingsChangePasswordFlow = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { t } = useTranslation("accountSettingsChangePasswordFlow");

  const steps = {
    PASSWORD_CONFIRM: "password_confirm",
    CHANGE_PASSWORD: "change_password",
    FINAL_PAGE: "final"
  };

  const [currentStep, setCurrentStep] = useState(steps.PASSWORD_CONFIRM);

  const navigateToSecurityTabCallback = useCallback(
    () => navigateWithQueryParams("/account-settings", { tab: "security" }),
    [navigateWithQueryParams]
  );

  useEffect(() => {
    if (currentStep === steps.FINAL_PAGE) {
      navigateToSecurityTabCallback();
    }
  }, [currentStep, navigateToSecurityTabCallback, steps.FINAL_PAGE]);

  const [currentPassword, setCurrentPassword] = useState("");
  const reauthenticateCallback = useCallback(
    async (currentPassword: string) => {
      setCurrentPassword(currentPassword);
      setCurrentStep(steps.CHANGE_PASSWORD);
    },
    [setCurrentStep, steps.CHANGE_PASSWORD]
  );

  const finalStepCallback = useCallback(async () => {
    setCurrentStep(steps.FINAL_PAGE);
  }, [setCurrentStep, steps.FINAL_PAGE]);

  return (
    <DocMetaView
      docViewContent={
        <DocViewWithBackButton header={t("title")} onBack={navigateToSecurityTabCallback}>
          {currentStep === steps.PASSWORD_CONFIRM && (
            <AccountSettingsReauthenticate
              onSuccess={reauthenticateCallback}
              title={t("confirm_password_desc")}
              buttonText={t("confirm_password_action")}
            />
          )}
          {currentStep === steps.CHANGE_PASSWORD && (
            <AccountSettingsChangePassword oldPassword={currentPassword} onSuccess={finalStepCallback} />
          )}
          {currentStep === steps.FINAL_PAGE && (
            <div>
              <CircularProgress />
            </div>
          )}
        </DocViewWithBackButton>
      }
      metaViewContent={<MetaView translationKey="accountSettingsChangePasswordFlow" />}
    />
  );
};
