import React, { useEffect, useCallback, useState } from "react";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckIcon from "@material-ui/icons/Check";
import AssignToGroupIcon from "../../../../assets/images/tasks/assign-to-group.svg";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CommentIcon from "@material-ui/icons/Comment";
import CloseIcon from "@material-ui/icons/Close";
import CommentsDisplay from "app/pages/shared/CommentsDisplay/CommentsDisplay";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "../../../collections";
import { commentClient, useComments } from "app/api/commentApi";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  ListItemText,
  Typography,
  makeStyles,
  MenuItem,
  Button,
  Grid
} from "@material-ui/core";
import { GroupTask } from "app/api/groupTaskApi";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import useSWR from "swr";

const useStyles = makeStyles(theme => ({
  sidebarWrapper: {
    position: "fixed",
    left: "250px",
    width: "0",
    height: "100%",
    marginLeft: "-250px",
    backgroundColor: theme.palette.red[400],
    zIndex: 1000,
    overflowY: "auto"
  },
  sidebarNav: {
    position: "absolute",
    top: 0,
    width: "250px",
    margin: 0,
    padding: 0
  },
  flex: {
    display: "flex",
    flexDirection: "row"
  },
  split: {
    height: "500px",
    overflowY: "scroll"
  },
  editModalIconWarning: {
    color: theme.palette.alert.warning
  },
  listactive: {
    ".MuiMenuItem-root": {
      "&.selected": {
        backgroundColor: theme.palette.blue[50],
        fontWeight: 500
      }
    },
    "&$selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 500
    },
    "&.selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 500
    },
    "&:selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 500
    },
    ".MuiListItem-selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 600
    },
    "& .MuiListItem-selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 600
    },
    ".MuiMenuItem-selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 600
    },
    "& .MuiMenuItem-selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 600
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 500
    },
    "&:hover": {
      backgroundColor: theme.palette.blue[50],
      fontWeight: 600
    }
  },
  cursorPointer: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  groupIconContainer: {
    width: "24px",
    height: "24px",
    marginTop: "2px"
  }
}));
const commentNamespace = "task";
export const TaskMember = (props: {
  membersDetails: GroupTask[];
  onClickName: (assigneeUID: string) => void;
  onClickComment: () => void;
  onClickAttachment: () => void;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getUserNameHook } = useUserAndTenantData();

  const getAssigneeName = function (userId: string) {
    const name = getUserNameHook(userId);
    return name === "" ? t("deleted") : name;
  };

  const onMenuItemClick = useCallback(
    (assigneeUID: string) => () => {
      props.onClickName(assigneeUID);
    },
    [props]
  );

  return (
    <>
      {props.membersDetails.length > 0 && (
        <Box>
          <Typography style={{ color: "#828282", marginLeft: "15px", marginTop: "20px", paddingBottom: "5px" }}>
            {t("metaview_groupdialog:direct")}
          </Typography>
          {props.membersDetails.map((member, index) => (
            <MenuItem
              onClick={onMenuItemClick(member.assigneeUID)}
              key={index}
              style={{ marginLeft: "-5%" }}
              className={classes.listactive}
            >
              {member.status === "TODO" && <AssignmentTurnedInOutlinedIcon style={{ paddingRight: "5px" }} />}
              {member.status === "OPEN" && <HourglassEmptyIcon style={{ paddingRight: "5px" }} />}
              {member.status === "DONE" && <CheckIcon style={{ paddingRight: "5px" }} />}
              <ListItemText key={index}>{getAssigneeName(member.assigneeUID)}</ListItemText>
            </MenuItem>
          ))}
        </Box>
      )}
    </>
  );
};

export const TwoColumn = (props: { specificGroupDetail: GroupTask[] }) => {
  const classes = useStyles();
  const { getUserNameHook } = useUserAndTenantData();
  const comments = useComments({
    documentId: `${props.specificGroupDetail[0].taskId}`,
    namespacePrefix: `${commentNamespace} groupTaskId ${props.specificGroupDetail[0].groupId}`
  });
  const { t } = useTranslation();
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [isShowingComment, setIsShowingComment] = useState(true);
  const [documentId, setDocumentId] = useState("");
  const [selectedSpecificIndividual, setSelectedSpecificIndividual] = useState("");

  useEffect(() => {
    const documentId = selectedSpecificIndividual
      ? `${props.specificGroupDetail[0].taskId}${props.specificGroupDetail[0].groupId}${selectedSpecificIndividual}`
      : `${props.specificGroupDetail[0].taskId}${props.specificGroupDetail[0].groupId}`;
    setDocumentId(documentId);
  }, [props, selectedSpecificIndividual]);

  const individualComments = useSWR(["comments", "preselected", documentId, selectedSpecificIndividual], async () => {
    if (!documentId || !selectedSpecificIndividual) return null;

    const namespace = selectedSpecificIndividual
      ? `${commentNamespace} groupTaskId ${props.specificGroupDetail[0].groupId} assigneeUID ${selectedSpecificIndividual}`
      : undefined;
    const namespacePrefix = !selectedSpecificIndividual
      ? `${commentNamespace} groupTaskId ${props.specificGroupDetail[0].groupId}`
      : undefined;

    const comments = await commentClient
      .getComments(namespace, props.specificGroupDetail[0].taskId, namespacePrefix)
      .then(resp => resp.data || null);
    return comments;
  });

  const handleToggleCommentForm = useCallback(() => {
    setShowCommentForm(state => !state);
    individualComments.mutate();
  }, [individualComments]);

  const onClickSpecificIndividual = useCallback((assigneeUID: string) => {
    setSelectedSpecificIndividual(assigneeUID);
  }, []);

  const onClickShowAllComments = useCallback(() => {
    setSelectedSpecificIndividual("");
    setIsShowingComment(true);
  }, []);

  const onClickShowAllAttachments = useCallback(() => {
    setSelectedSpecificIndividual("");
    setIsShowingComment(false);
  }, []);

  const onClickIndividualComments = useCallback(() => {
    setIsShowingComment(true);
  }, []);

  const onClickIndividualAttachments = useCallback(() => {
    setIsShowingComment(false);
  }, []);

  return (
    <>
      <div className={classes.flex}>
        <div className={classes.split} style={{ width: "35%" }}>
          <Box>
            <MenuItem onClick={onClickShowAllComments} style={{ marginLeft: "-5%" }} className={classes.listactive}>
              <ListItemText>{t("metaview_groupdialog:allComments")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={onClickShowAllAttachments} style={{ marginLeft: "-5%" }} className={classes.listactive}>
              <ListItemText>{t("metaview_groupdialog:allAttachments")}</ListItemText>
            </MenuItem>
          </Box>
          <Box style={{ height: "250px" }}>
            <TaskMember
              membersDetails={props.specificGroupDetail}
              onClickName={onClickSpecificIndividual}
              onClickComment={onClickIndividualComments}
              onClickAttachment={onClickIndividualAttachments}
            />
          </Box>
        </div>
        <div className={classes.split} style={{ width: "70%", paddingLeft: 20, paddingRight: 20, paddingTop: 15 }}>
          {selectedSpecificIndividual !== "" && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ paddingBottom: "10px" }}
            >
              <Grid item>
                <Typography variant="h4" component="span" style={{ fontSize: "17px" }}>
                  {getUserNameHook(selectedSpecificIndividual)}
                </Typography>
              </Grid>
              <Grid item>
                <CommentIcon
                  style={{ marginRight: "18px", color: "#49454F" }}
                  onClick={onClickIndividualComments}
                  className={classes.cursorPointer}
                />
                <AttachFileIcon
                  style={{ marginRight: "10px", color: "#49454F" }}
                  onClick={onClickIndividualAttachments}
                  className={classes.cursorPointer}
                />
              </Grid>
            </Grid>
          )}
          {isShowingComment && (
            <CommentsDisplay
              hidePagination
              questionId={undefined}
              collection={COLLECTIONS.TASK_DETAIL}
              buttonTextSave={t("common:save")}
              docId={props.specificGroupDetail[0].taskId}
              docName={"common:comments"}
              clickOnCommentBadge={showCommentForm}
              buttonTextCancel={t("common:cancel")}
              onCancelComment={handleToggleCommentForm}
              onSubmitComment={handleToggleCommentForm}
              buttonTextReply={selectedSpecificIndividual ? t("common:reply") : ""}
              pageId={"pageId"}
              preselectedCommentId={""}
              groupId={props.specificGroupDetail[0].groupId}
              assigneeUID={selectedSpecificIndividual}
            />
          )}
          {!showCommentForm &&
            selectedSpecificIndividual !== "" &&
            individualComments.data?.commentModels &&
            individualComments.data?.commentModels?.length <= 0 &&
            isShowingComment && (
              <>
                <Grid item style={{ paddingBottom: "10px" }}>
                  <Typography variant="h5" component="span">
                    {t("Comments")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button size="small" variant="contained" color="primary" onClick={handleToggleCommentForm}>
                    {t("common:addComment")}
                  </Button>
                </Grid>
              </>
            )}
          {!comments.data?.commentModels?.length && isShowingComment && selectedSpecificIndividual === "" && (
            <>
              <Grid item style={{ paddingBottom: "10px" }}>
                <Typography variant="h5" component="span" style={{ fontWeight: 400 }}>
                  {t("metaview_groupdialog:no_group_comments")}
                </Typography>
              </Grid>
            </>
          )}

          {!isShowingComment && (
            <>
              <Typography variant="h5" component="span">
                {t("task_details:headline_attachments")}
              </Typography>
              <AttachmentsOverviewOBS
                docId={documentId}
                category={"tasks"}
                hidePagination={true}
                withPrefix={selectedSpecificIndividual ? false : true}
                disabled={selectedSpecificIndividual ? false : true}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const GroupChipHeader = (props: { onClose: () => void; selectedGroupName: string; members: number }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        paddingLeft: "12px",
        position: "relative"
      }}
    >
      <div
        style={{
          display: "inline-flex",
          alignItems: "flex-start",
          position: "relative",
          flex: "0 0 auto",
          gap: "12px"
        }}
      >
        <Box className={classes.groupIconContainer}>
          <AssignToGroupIcon />
        </Box>
        <div
          style={{
            display: "inline-flex",
            alignItems: "flex-start",
            position: "relative",
            flex: "0 0 auto",
            gap: "4px",
            flexDirection: "column"
          }}
        >
          <Typography variant="h3" style={{ fontWeight: "bold" }}>
            {props.selectedGroupName}
          </Typography>
          <Typography component="span">
            {props.members} {props.members > 1 ? t("metaview_groupdialog:members") : t("metaview_groupdialog:member")}
          </Typography>
        </div>
      </div>
      <CloseIcon onClick={props.onClose} className={classes.cursorPointer} />
    </div>
  );
};

export const GroupChipInfo = (props: { onClose: () => void; groupDetails: GroupTask[]; selectedGroupName: string }) => {
  return (
    <Dialog open={true} maxWidth="md" onClose={props.onClose}>
      <DialogContent style={{ minWidth: 700, maxWidth: 700 }}>
        <GroupChipHeader
          onClose={props.onClose}
          selectedGroupName={props.selectedGroupName}
          members={props.groupDetails.length}
        />
        <Divider style={{ marginTop: 15, marginBottom: 15 }} />
        {props.groupDetails.length > 0 && <TwoColumn specificGroupDetail={props.groupDetails} />}
      </DialogContent>
    </Dialog>
  );
};
