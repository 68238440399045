import { Chip, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUserDepartments } from "../../../../app/contexts/department-context";
import { SxProps } from "@mui/system/styleFunctionSx";
import colors from "../../../../theme/palette/colors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { MultiPickerDialog } from "../../../Measures/MultiPickerDialog";

/**
 * moved this out of OrgUnitsPicker as its own component
 * but we should not custom make this logic and view for each question, it should be based on a generic component
 * currently, this is duplicated in many places. once the generic component is ready,
 * then this component should just refer to that other one and this here should only contain department specific logic
 * and then the deprecated tag can be removed
 *
 * @deprecated
 */
export const OrgUnitSuggestions = ({
  suggestionIds,
  value,
  onChange
}: {
  suggestionIds?: string[];
  value: string[];
  onChange?: (departmentIds: string[]) => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const xlScreen = theme.breakpoints.up("xl");
  const [maxVisibleChips, setMaxVisibleChips] = useState(1);
  const { getDepartmentWithParentName } = useUserDepartments();
  const [notYetSelectedOrgUnitIds, setNotYetSelectedOrgUnitIds] = useState<string[]>([]);

  useEffect(() => {
    setMaxVisibleChips(xlScreen ? 2 : 1);
  }, [xlScreen]);

  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    if (suggestionIds && suggestionIds.length > 0) {
      const notYetSelected = suggestionIds.filter(id => !value.includes(id));
      setNotYetSelectedOrgUnitIds(notYetSelected);
    }
  }, [suggestionIds, value]);

  const onAddSuggestionChip = useCallback(
    (suggestionChipId: string) => {
      const selectedDepartmentIds = [suggestionChipId, ...value];
      onChange?.(selectedDepartmentIds);
    },
    [onChange, value]
  );
  const onChangeCallback = useCallback(
    ids => {
      const selectedDepartmentIds = [...ids, ...value];
      onChange?.(selectedDepartmentIds);
    },
    [onChange, value]
  );

  const onAddAllSuggestionChip = useCallback(() => {
    const selectedDepartmentIds = [...(suggestionIds || []), ...value];
    onChange?.(selectedDepartmentIds);
  }, [onChange, value, suggestionIds]);

  const getOptionLabelCallback = useCallback(
    (option: string) => {
      const fullOrgUnitName = getDepartmentWithParentName(option);
      const countChildOrgUnit = fullOrgUnitName.split(" > ");
      if (countChildOrgUnit.length > 3) {
        return `${countChildOrgUnit[0]} > ${countChildOrgUnit[1]} > ... > ${countChildOrgUnit[countChildOrgUnit.length - 1]}`;
      } else {
        return fullOrgUnitName;
      }
    },
    [getDepartmentWithParentName]
  );

  const closePicker = useCallback(() => {
    setPickerOpen(false);
  }, []);
  const openPicker = useCallback(() => {
    setPickerOpen(true);
  }, []);

  const chipSx = useMemo<SxProps>(() => {
    const chipMaxWidth = 80 / maxVisibleChips;
    return {
      maxWidth: `${chipMaxWidth}%`,
      flexShrink: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      alignSelf: "center",
      backgroundColor: `${theme.palette.blue[100]} !important`,
      "&:hover": {
        backgroundColor: colors.blue.blue400
      }
    };
  }, [maxVisibleChips, theme.palette.blue]);

  if (notYetSelectedOrgUnitIds.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Stack width="100%" direction="row" spacing={1} alignItems="center" mt={1}>
        <Typography variant="body2"> {t("multilingual:suggestion")}</Typography>
        <Stack direction="row" spacing={1} flexGrow={1} flexShrink={1} overflow="hidden" display="flex" width="90%">
          {notYetSelectedOrgUnitIds.slice(0, maxVisibleChips).map(option => (
            <Tooltip title={getDepartmentWithParentName(option)} key={option}>
              <Chip
                key={option}
                sx={chipSx}
                label={getOptionLabelCallback(option)}
                clickable
                deleteIcon={
                  <AddCircleIcon
                    fontSize="small"
                    style={{
                      color: colors.indigo.indigo500
                    }}
                  />
                }
                // eslint-disable-next-line react/jsx-no-bind
                onDelete={() => onAddSuggestionChip(option)}
              />
            </Tooltip>
          ))}
          {/* Show "+N" if there are more chips than maxVisibleChips */}
          {notYetSelectedOrgUnitIds.length > maxVisibleChips && (
            <Typography variant="body2" color="primary" onClick={openPicker} alignSelf="center">
              +{notYetSelectedOrgUnitIds.length - maxVisibleChips}
            </Typography>
          )}
          {notYetSelectedOrgUnitIds.length > 1 && (
            <Typography
              variant="body2"
              color="primary"
              onClick={onAddAllSuggestionChip}
              marginLeft="24px!important"
              alignSelf="center"
            >
              {t("processor_pa_page:addAll")}
            </Typography>
          )}
        </Stack>
      </Stack>
      <MultiPickerDialog
        title={t("processor_pa_page:addProcessorOrgUnits")}
        open={pickerOpen}
        onClose={closePicker}
        onConfirm={onChangeCallback}
        value={notYetSelectedOrgUnitIds}
        options={notYetSelectedOrgUnitIds}
        allowAdd={false}
        getOptionName={getDepartmentWithParentName}
      />
    </>
  );
};
