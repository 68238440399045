import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  overdue: {
    color: theme.palette.warning.dark
  }
}));

export default function NotificationTitle({ notification }) {
  const { t } = useTranslation("notifications_overview");
  const classes = useStyles();

  const constructNotificationText = function () {
    switch (notification.title) {
      case "comment_reply":
      case "comment_mention":
      case "resubmission_date_updated":
        return <>{notification.text}</>;
      case "process_assignment":
      case "process_approval":
      case "assigned_process_submit":
      case "assigned_processor_pa_submit":
      case "processor_pa_approval":
      case "assessment_response_submit":
      case "process_set_to_edit":
      case "process_request_edit":
      case "task_done":
      case "task_due_date_in_tomorrow":
      case "task_due_date_in_5_days":
      case "task_reassignment":
      case "task_due_date_today":
      case "process_due_date_today":
      case "process_due_date_in_tomorrow":
      case "process_due_date_in_5_days":
      case "task_assignment":
      case "task_participation":
      case "task_participation_removal":
      case "dsr_due_in_5_days":
      case "dsr_due_in_tomorrow":
      case "dsr_due_in_today":
      case "dsr_overdue":
      case "dsr_due_changed":
      case "dsr_assigned":
      case "dsr_reassigned":
      case "dsr_external_submission":
      case "dsr_public_form_submission":
      case "dsr_done":
      case "task_due_changed":
      case "dbreach_assigned":
      case "dbreach_task_due_today":
      case "dbreach_task_due_tomorrow":
      case "dbreach_task_due_date_changed":
      case "dbreach_due_date_changed":
      case "dbreach_due_tomorrow":
      case "dbreach_due_today":
      case "audit_assignment":
      case "dbreach_overdue":
        return (
          <>
            {notification.text}
            <br />
            <b>{notification.docName}</b>
          </>
        );
      case "task_overdue":
      case "dbreach_task_overdue":
      case "process_overdue":
        return (
          <>
            <span className={classes.overdue}>{t("overdue")} </span>
            {notification.text}
            <br />
            <b>{notification.docName}</b>
          </>
        );
      case "audit_due_in_5_days":
      case "audit_due_in_tomorrow":
      case "audit_due_in_today":
      case "audit_overdue":
        return (
          <>
            {notification.text}
            <br />
            <b>{notification.docName}</b>
          </>
        );
      default:
        return <>{notification.title}</>;
    }
  };

  return (
    <div className="notifications__item__title">
      <span>{constructNotificationText()}</span>
    </div>
  );
}
