import React from "react";
import QuestionTitle from "components/QuestionTitle/QuestionTitle";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";

interface SimpleQuestionProps {
  readonly title?: string;
  readonly pt?: number;
  readonly pb?: number;
  readonly onFocus?: () => void;
  readonly children?: React.ReactNode;
}

const SimpleQuestion = ({ title, pt, pb, onFocus, children }: SimpleQuestionProps) => {
  return (
    <Box pt={pt === undefined ? 3 : pt} pb={pb === undefined ? 3 : pb} onClick={onFocus} position="relative" sx={sx}>
      {title && <QuestionTitle>{title}</QuestionTitle>}
      {children}
    </Box>
  );
};

const sx: SxProps = {
  cursor: "pointer"
};

export default SimpleQuestion;
