import React from "react";
import Grid from "@material-ui/core/Grid";
import { Canvas } from "../Canvas/Canvas";
import { rectangleWithRoundedCorners } from "../Canvas/drawing-helper";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  subHeader: {
    marginTop: "40px",
    marginBottom: "20px"
  },
  xLabel: {
    fontSize: 13
  }
}));

export default function HeatMap({ mapSize, numberSquaresPerRow, markersToDisplay, labelXAxis, labelYAxis }) {
  const classes = useStyles();

  const widthWhiteBorder = 2;
  const widthSquare = mapSize / numberSquaresPerRow;
  const widthSquareMinusBorder = widthSquare - widthWhiteBorder;

  const drawBackground = context => {
    const path1 = new Path2D();

    // draw tiles
    for (let x = 0; x < numberSquaresPerRow; x++) {
      for (let y = 0; y < numberSquaresPerRow; y++) {
        // we are drawing the background tiles by taking steps of the size of the square/tile
        const xPosition = x * widthSquare;
        const yPosition = y * widthSquare;
        // draw a rounded rectangle (tile)
        rectangleWithRoundedCorners(path1, xPosition, yPosition, widthSquareMinusBorder, widthSquareMinusBorder);
      }
    }

    // Create gradient for color transition
    const grd = context.createLinearGradient(0, mapSize, mapSize, 0);
    grd.addColorStop(0, "#34A159");
    grd.addColorStop(0.35, "#D1BB55");
    grd.addColorStop(0.5, "#FFC354");
    grd.addColorStop(0.6, "#FBA752");
    grd.addColorStop(1, "#EC4D4C");
    // Fill with gradient
    context.fillStyle = grd;
    context.fill(path1);
  };

  // we have to invert the Y position of the marker because we render the grid from the top down -> i.e:  in a 4*4 grid the top row is not y=3 but y=0
  const invertYPosition = yPosition => Math.abs(yPosition - (numberSquaresPerRow - 1));

  const renderMarker = (xIndex, yIndex) => {
    for (let marker of markersToDisplay) {
      if (marker.xPosition === xIndex && invertYPosition(marker.yPosition) === yIndex) {
        return marker.objectToRender;
      }
    }
    return <></>;
  };

  // render grid for placing objects/markers
  const grid = (
    <div style={{ position: "absolute", marginBottom: -mapSize }}>
      <Grid container justifyContent={"center"}>
        {Array(numberSquaresPerRow)
          .fill(0)
          .map((yElement, yIndex) => {
            return (
              <Grid container direction={"row"} key={"Grid Container " + (yIndex + 1)}>
                {Array(numberSquaresPerRow)
                  .fill(0)
                  .map((xElement, xIndex) => {
                    return (
                      <Grid
                        style={{
                          width: widthSquare,
                          height: widthSquare
                        }}
                        key={"Grid Item " + (xIndex + 1) * (yIndex + 1)}
                        container
                        justifyContent={"center"}
                        alignContent={"center"}
                      >
                        {renderMarker(xIndex, yIndex)}
                      </Grid>
                    );
                  })}
              </Grid>
            );
          })}
      </Grid>
    </div>
  );

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <div
          style={{
            position: "relative",
            top: mapSize / 2 - 12,
            transform: "rotate(-90deg)",
            width: 220,
            right: 110 + mapSize / 25,
            fontSize: 13
          }}
        >
          <span>{labelYAxis}</span>
        </div>
        <Grid container justifyContent="center">
          <Grid item>
            <div style={{ position: "relative" }}>
              {grid}
              <Canvas width={mapSize} height={mapSize} draw={drawBackground} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <span className={classes.xLabel}>{labelXAxis}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

HeatMap.propTypes = {
  mapSize: PropTypes.number,
  numberSquaresPerRow: PropTypes.number,
  markersToDisplay: PropTypes.arrayOf(PropTypes.object),
  labelXAxis: PropTypes.string,
  labelYAxis: PropTypes.string
};

HeatMap.defaultProps = {
  mapSize: 200,
  numberSquaresPerRow: 4
};
