import React, { useCallback, useEffect, useMemo, useState } from "react";
import OverviewSearchTextField from "./LegacyOverview/OverviewSearchTextField";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import { AvailableSearchFilterDTO, searchOwlit, SearchResultsDTO } from "app/api/owlitApi";
import { OwlitMetaViewEntry } from "./OwlitMetaViewEntry";
import { FilterItem, FilterSelector, FilterTree } from "./FilterSelector";
import { withAbortController } from "../app/api/axios/axiosErrorHandler";
import { useProcessPage } from "../app/contexts/process-page-context";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";

export const OwlitMetaView = () => {
  const { t } = useTranslation();
  const { processMeta } = useProcessPage();
  const [isSearching, setIsSearching] = useState(false);
  const [searchKeywordInput, setSearchKeywordInput] = useState("");

  const processingActivityName = processMeta?.title || "";

  useEffect(() => {
    if (processingActivityName) {
      setSearchKeywordInput(processingActivityName);
    }
  }, [processingActivityName]);

  const [searchKeyword, setSearchKeyword] = useState("");
  const debouncedSetSearchKeyword = useMemo(() => debounce(setSearchKeyword, 700), []);
  useEffect(() => {
    setIsSearching(true);
    setSearchResults(null);
    debouncedSetSearchKeyword(searchKeywordInput);
  }, [debouncedSetSearchKeyword, searchKeywordInput]);

  const [searchResults, setSearchResults] = useState<SearchResultsDTO | null>(null);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>([]);

  useEffect(() => {
    const cleanedSearchKeyword = (searchKeyword || "").trim();
    if (!cleanedSearchKeyword) {
      setSearchResults(null);
      setIsSearching(false);
      return;
    }

    return withAbortController({
      executeFn: async abortController => {
        const searchResults = await searchOwlit(cleanedSearchKeyword, null, selectedFilterIds, abortController);
        setSearchResults(searchResults || null);
        setIsSearching(false);
      },
      onError: error => {
        setIsSearching(false);
        throw error;
      }
    });
  }, [searchKeyword, selectedFilterIds]);

  const [filterTree, setFilterTree] = useState<FilterTree | null>(null);
  useEffect(() => {
    const noAvailableFilters = !(
      searchResults?.filter?.lawTaxonomy?.length && searchResults?.filter?.publicationType?.length
    );
    if (noAvailableFilters) {
      return;
    }

    const toFilter = (input: AvailableSearchFilterDTO[]): FilterItem[] =>
      input
        .map(filter => ({
          title: filter.title || "",
          id: filter.id || "",
          count: filter.count || 0,
          childFilters: []
        }))
        .filter(filter => filter.id);
    const filterItems = [
      ...toFilter(searchResults?.filter?.lawTaxonomy || []),
      ...toFilter(searchResults?.filter?.publicationType || [])
    ];
    setFilterTree({
      items: filterItems
    });
  }, [searchResults]);

  const onSelectedFilterIdsChanged = useCallback<(setter: (input: string[]) => string[]) => void>(
    setter => {
      const getAvailableFilterId = (input: FilterItem): string[] => {
        return [input.id, ...input.childFilters.flatMap(getAvailableFilterId)];
      };
      const availableFilters = (filterTree?.items || []).flatMap(getAvailableFilterId);
      setSelectedFilterIds(selectedFilterIds => {
        const modifiedSelectedFilterIds = setter(selectedFilterIds);
        return modifiedSelectedFilterIds.filter(id => availableFilters.includes(id));
      });
      setIsSearching(true);
      setSearchResults(null);
    },
    [filterTree]
  );

  return (
    <Box borderRadius={6} bgcolor="info.light">
      <Box p={4}>
        <Box mb={4}>
          <Typography variant="h2" align="center" component="div">
            {t("owlit:title")}
          </Typography>
        </Box>
        <Box display="flex" justifyContent={"space-between"} alignItems={"center"} gap={1}>
          <Box flex={1}>
            <OverviewSearchTextField
              searchKeyword={searchKeywordInput}
              setSearchKeyword={setSearchKeywordInput}
              fullWidth={true}
              size={"small"}
              placeholder={t("owlit:search")}
              variant={"outlined"}
            />
          </Box>
          {!!filterTree?.items?.length && (
            <Box>
              <FilterSelector
                filterTree={filterTree}
                selectedFilterIds={selectedFilterIds}
                onSelectedFilterIdsChanged={onSelectedFilterIdsChanged}
              />
            </Box>
          )}
        </Box>
        <Box>
          {isSearching && (
            <Box mt={2}>
              <Box display="flex" justifyContent="center">
                <Box>
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          )}
          {(searchResults?.hits || []).map((searchResult, index) => (
            <React.Fragment key={`${searchResult.documentId}-${index}`}>
              {index > 0 && <Divider />}
              <OwlitMetaViewEntry
                title={searchResult?.title || ""}
                documentId={searchResult?.documentId || ""}
                snippets={searchResult?.snippets || []}
                imageData={searchResult?.imageData}
                authors={searchResult?.authors || []}
                breadcrumbs={searchResult?.breadcrumbs || []}
                publishedAt={searchResult?.publishedAt || ""}
                decidedAt={searchResult?.decidedAt || ""}
              />
            </React.Fragment>
          ))}
          {!(searchResults?.hits || []).length && !isSearching && (
            <Box mt={2}>
              <Box display="flex" justifyContent="center">
                <Box>
                  <p>{t("common:emptySearch")}</p>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
