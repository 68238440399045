import React from "react";
import { Typography, Box, Icon, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  label: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: "16px",
      color: "rgba(129, 129, 129, 1)",
      marginTop: theme.spacing(0.25)
    }
  },
  labelText: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    color: "rgba(129, 129, 129, 1)"
  },
  fullWidth: {
    width: "100%"
  }
}));

interface LabelProps {
  readonly icon: any;
  readonly text: string;
  readonly inline?: boolean;
}
export const TaskInfoLabel = ({ icon, text, inline }: LabelProps) => {
  const cls = useStyles();

  return (
    <Box className={inline ? cls.label : [cls.label, cls.fullWidth].join(" ")}>
      <Icon component={icon} />
      <Typography variant="body1" className={cls.labelText}>
        {text}
      </Typography>
    </Box>
  );
};
