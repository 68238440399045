import { createNaturalSorter } from "app/utils/naturalSort";
import { OverviewItem } from "../controllers/overviewBaseController";
import { orderBy } from "lodash-es";

const _orderBy = (items: OverviewItem[], key: string, sortType?: "asc" | "desc"): OverviewItem[] => {
  if (key === "title") {
    const sorter = createNaturalSorter({
      desc: sortType === "desc"
    });
    return [...items].sort((a, b) => {
      return sorter(a[key], b[key]);
    });
  }

  return orderBy(
    items,
    [
      (item: OverviewItem) => {
        return item[key];
      }
    ],
    [sortType || "asc"]
  );
};

const orderOverviewItems = (items: OverviewItem[], key: string, sortType?: "asc" | "desc"): OverviewItem[] => {
  const _order = (i: OverviewItem): OverviewItem => {
    return { ...i, children: i.children ? _orderBy(i.children.map(_order), key, sortType) : i.children };
  };
  const _items = _orderBy([...items], key, sortType);
  return _items.map(_order);
};

export { orderOverviewItems };
