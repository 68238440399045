import Question from "../../../../components/Question/Question";
import RadioButtonQuestion from "../../../../components/RadioButtonQuestion/RadioButtonQuestion";
import QuestionTitle from "../../../../components/QuestionTitle/QuestionTitle";
import TextEditor from "../utils/TextEditor";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface OnSavePayload {
  readonly fieldName: string;
  readonly radioSelect: "yes" | "no" | "";
  readonly text: string;
}

type RadioEditorComboProps = {
  readonly disabled?: boolean;
  readonly editorTitle?: string;
  readonly fieldName: string;
  readonly questionId: string;
  readonly radioSelect: "yes" | "no" | "";
  readonly radioTitle: string;
  readonly tRadioOptions: string[];
  readonly text: string;
  readonly onFocus?: (fieldName: string) => void;
  readonly onSave: (value: OnSavePayload) => Promise<void>;
};
const RadioEditorCombo = ({
  disabled,
  editorTitle,
  fieldName,
  questionId,
  radioSelect,
  radioTitle,
  tRadioOptions,
  text,
  onFocus,
  onSave
}: RadioEditorComboProps) => {
  const { t } = useTranslation("questionnaire_four_three_page");

  const [currentText, setCurrentText] = useState("");
  useEffect(() => {
    setCurrentText(text);
  }, [text]);

  const onRadioButtonChange = useCallback(
    async (questionId, value) => {
      await onSave({ radioSelect: value, text: "", fieldName: fieldName });
    },
    [fieldName, onSave]
  );
  const onTextEditorBlur = useCallback(async () => {
    await onSave({ radioSelect: radioSelect, text: currentText, fieldName: fieldName });
  }, [currentText, fieldName, onSave, radioSelect]);

  const onFocusCallback = useCallback(async () => {
    if (onFocus) {
      onFocus(fieldName);
    }
  }, [fieldName, onFocus]);

  return (
    <>
      <Question
        disabled={disabled}
        key={fieldName}
        questionId={questionId}
        questionName={t(fieldName)}
        translatable={currentText}
        translationId={questionId}
        onFocus={onFocusCallback}
      >
        <RadioButtonQuestion
          defaultValue={undefined}
          disabled={disabled}
          disabledOptions={undefined}
          id={undefined}
          index={0}
          infoAlertText={undefined}
          infoIcon={undefined}
          initialValue={radioSelect}
          key={fieldName}
          optionLabels={tRadioOptions}
          question={radioTitle}
          questionId={fieldName}
          radioOptions={options}
          onChange={onRadioButtonChange}
          onFocus={undefined}
        />
        {radioSelect === "yes" && (
          <>
            <QuestionTitle>{editorTitle}</QuestionTitle>
            <TextEditor
              disabled={disabled}
              inputValue={currentText}
              onBlur={onTextEditorBlur}
              onChange={setCurrentText}
            />
          </>
        )}
      </Question>
    </>
  );
};

const options = ["yes", "no"];

export default RadioEditorCombo;
