import overviewBaseController, {
  OverviewController,
  OverviewItem,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import {
  addDocumentFolderApi,
  deleteDocumentFileApi,
  deleteDocumentFolderApi,
  getDocumentFileAsBlob,
  getDocumentFolderMetaDataApi,
  renameDocumentFolderFileOrFolderApi
} from "app/api/file-storage/documentApi";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { sortings } from "./DocumentOverviewHelper";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const DocumentOverviewController = (axiosInstance: AxiosInstance): OverviewController => {
  const { t } = useTranslation("documentCenter");
  const { enqueueSnackbar } = useSnackbar();

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.DOCUMENTS, normalizeOverviewItem);

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: [],
      sortings
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/document-center/${id}/details`);
  };

  const addItem = async ({ title }: OverviewNewItem): Promise<AxiosResponse> => {
    try {
      if (title) {
        return await addDocumentFolderApi(title);
      }
      return Promise.reject("Title is required");
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const deleteItem = async (path: string): Promise<void> => {
    try {
      if (!path) {
        throw new Error("Failed to delete: No path");
      }
      const file = await getDocumentFileAsBlob({ filePath: path });
      const itemIsFile = file !== null;
      if (itemIsFile) {
        await deleteDocumentFileApi(path);
      } else {
        const { s3ObjectKey } = await getDocumentFolderMetaDataApi(path);
        if (s3ObjectKey && s3ObjectKey.endsWith(`${path}/`)) {
          await deleteDocumentFolderApi(path);
        } else {
          throw new Error("Failed to delete folder");
        }
      }
    } catch (error) {
      throw new Error(`Failed to delete item`);
    }
  };

  const patchItem = async (
    id: string,
    data: object,
    url?: string,
    options?: AxiosRequestConfig,
    originalItem?: OverviewItem
  ) => {
    if (!originalItem) {
      return;
    }
    if (!("path" in originalItem)) {
      return;
    }

    const path = originalItem.path as string;
    const originalTitleWithExtension = originalItem.title;
    const originalExtension = originalTitleWithExtension.split(".").pop();

    const newTitleWithoutExtension = "title" in data && (data.title as string);
    const newTitle = [newTitleWithoutExtension, originalExtension].join(".");

    const fileType = originalItem && "fileType" in originalItem && !!originalItem.fileType;
    const isFolder = !fileType;
    try {
      if (isFolder) {
        const pathSegments = path.split("/");
        pathSegments.pop();
        const newPath = `${pathSegments.join("/")}/${newTitleWithoutExtension}`;
        await renameDocumentFolderFileOrFolderApi(path, newPath, fileType || false);
      } else {
        // is file
        const pathSegments = path.split("/");
        pathSegments[pathSegments.length - 1] = newTitle;
        const newPath = pathSegments.join("/");
        await renameDocumentFolderFileOrFolderApi(path, newPath, fileType || true);
      }
    } catch (e: any) {
      if (e?.response?.status === 409) {
        enqueueSnackbar(isFolder ? t("error_messages:folder_name_conflict") : t("error_messages:file_name_conflict"), {
          variant: "error"
        });
      } else {
        throw e;
      }
    }
  };

  const addItemAndGo = async () => {
    document.getElementById("file-upload-input")?.click();
  };

  return {
    ...baseController,
    addItem,
    addItemAndGo,
    deleteItem,
    getOverview,
    goToItem,
    patchItem
  };
};

/**
 * make sure file does not have children so that they can't be clicked
 * make sure files inherit the folder org unit ids just so that our UI render is simpler
 * add editable title which exclude file extension to prevent the user from renaming the file extension
 */
const normalizeOverviewItem = (item: OverviewItem): OverviewItem => {
  if (!("fileType" in item)) {
    return item;
  }

  const isFile = !!item.fileType;
  if (isFile) {
    return {
      ...item,
      editableTitle: item.title.replace(/\.[^/.]+$/, ""),
      children: undefined
    };
  }

  if (!item.children) {
    return item;
  }

  const updatedChildren = item.children
    .map(child => {
      const isFile = "fileType" in child && !!child.fileType;
      if (!isFile) {
        return child;
      }

      return {
        ...child,
        orgUnitIds: ("orgUnitIds" in item && item.orgUnitIds) || []
      };
    })
    .map(normalizeOverviewItem);
  return { ...item, children: updatedChildren };
};

export default DocumentOverviewController;
