import { testAxios } from "./axios/axiosProvider";

const axiosInstance = testAxios();

export async function testGetApiFrom(baseUrl: string, customHealthPath?: string) {
  const response = await axiosInstance.get(`${baseUrl}${customHealthPath || "/health"}`);
  if (response?.data?.status !== "ok") {
    throw new Error(`Unknown health response from ${baseUrl}`);
  }
  return response.data || null;
}
