import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import RedoIcon from "@material-ui/icons/Redo";
import { useNavigateWithQueryParams } from "app/router/router-custom-hooks";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { getSingleAnswerSetApi } from "app/api/assessmentApi";

export function useNavigation() {
  const navigate = useNavigate();
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t: t_error_messages } = useTranslation("error_messages");
  const [answersetAffectedUser, setAnswersetAffectedUser] = useState([]);
  const { auth } = useAuthentication();

  const loadAnswerSet = useCallback(async (auditId, id) => {
    const data = await getSingleAnswerSetApi({ auditId, id });
    if (data) {
      setAnswersetAffectedUser([data.assigneeUID || "", ...data.participantsUIDs]);
    }
  }, []);

  const getBaseUrl = useCallback(
    collection => {
      switch (collection) {
        case COLLECTIONS.PROCESSES:
          return "processes";
        case COLLECTIONS.EXTERNAL_RECIPIENTS:
          return "service-providers";
        case COLLECTIONS.TOM:
          return "toms";
        case COLLECTIONS.DPIAS:
          return "dpias";
        case COLLECTIONS.TASKS:
        case COLLECTIONS.USER: // task created by user used to have collection "user"
          return "task-details";
        case COLLECTIONS.DATA_SUBJECT_REQUESTS:
          return "data-subject-requests";
        case COLLECTIONS.DATA_BREACHES:
          return "data-breaches";
        case COLLECTIONS.AUDITS:
          return "audits/instances";
        case COLLECTIONS.RECURRING_TASKS:
          return "tasks/recurring";
        case COLLECTIONS.TOM_RISKS:
        case COLLECTIONS.RISK:
          return "risks";
        case COLLECTIONS.RISK_MATRIX:
          return "risks";
        case COLLECTIONS.PROCESSOR_PAS:
          return "processor-pas";
        case COLLECTIONS.ASSETS:
          return "asset-management";
        case COLLECTIONS.ASSESSMENT_RESPONSE:
          return "audits";
        case COLLECTIONS.AI_TECH_DOCS:
          return "ai-tech-docs";
        default:
          enqueueSnackbar(t_error_messages("generic"), { variant: "error" });
          throw new Error(`Can't identify path to navigate to for collection ${collection}`);
      }
    },
    [enqueueSnackbar, t_error_messages]
  );

  const getUrlWithParams = useCallback(
    ({ collection, documentId, pageId, questionId, taskId }) => {
      const url = `/${getBaseUrl(collection)}/${documentId}`;
      const collectionWithPages =
        collection === COLLECTIONS.PROCESSES ||
        collection === COLLECTIONS.TOM ||
        collection === COLLECTIONS.EXTERNAL_RECIPIENTS ||
        collection === COLLECTIONS.AUDITS ||
        collection === COLLECTIONS.RISK ||
        collection === COLLECTIONS.TOM_RISKS ||
        collection === COLLECTIONS.RISK_MATRIX ||
        collection === COLLECTIONS.PROCESSOR_PAS ||
        collection === COLLECTIONS.ASSETS ||
        collection === COLLECTIONS.ASSESSMENT_RESPONSE ||
        collection === COLLECTIONS.AI_TECH_DOCS;
      if (collectionWithPages) {
        let params = {};
        if (questionId) {
          params.questionId = questionId;
        }
        if (taskId) {
          params.taskId = taskId;
        }
        let noQuestionAndPageThoughItShould = !params.questionId && !pageId;
        if (noQuestionAndPageThoughItShould) {
          if (collection === COLLECTIONS.TOM) {
            return { url: `${url}/description`, params };
          }
          if (collection === COLLECTIONS.AUDITS) {
            return { url: `${url}/questionnaire`, params };
          }
          return { url: `${url}/general`, params };
        }
        if (collection === COLLECTIONS.ASSESSMENT_RESPONSE) {
          if (answersetAffectedUser.length > 0 && answersetAffectedUser.includes(auth.uid)) {
            return { url: `${url}/${pageId}`, params };
          } else {
            const auditId = documentId.split("/answerset/");
            return { url: "/" + getBaseUrl(COLLECTIONS.AUDITS) + "/" + auditId[0] + "/responses", params };
          }
        }
        return { url: `${url}/${pageId}`, params };
      } else if (collection === COLLECTIONS.DATA_SUBJECT_REQUESTS || collection === COLLECTIONS.DATA_BREACHES) {
        return { url: `${url}/tasks` };
      } else {
        return { url: "/" + getBaseUrl(collection) + "/" + documentId };
      }
    },
    [getBaseUrl]
  );

  return ({ collection, documentId, pageId, questionId, taskId, openInNewTab = false }) => {
    if (collection === COLLECTIONS.ASSESSMENT_RESPONSE) {
      const docId = documentId.split("/answerset/");
      loadAnswerSet(docId[0], docId[1]);
    }
    const baseUrl = getBaseUrl(collection);
    if (!baseUrl) return;
    const { url, params } = getUrlWithParams({ collection, documentId, pageId, questionId, taskId });
    if (openInNewTab) {
      window.open(url, "_blank");
    } else {
      if (params) {
        navigateWithQueryParams(url, params);
      } else {
        navigate(url);
      }
    }
  };
}

function GoTo({ collection, documentId, pageId, questionId, tooltipText, showIconText = true, taskId, openInNewTab }) {
  const { t } = useTranslation("task_details");
  const navigateTo = useNavigation();

  const goTo = () => {
    navigateTo({ collection, documentId, pageId, questionId, taskId, openInNewTab });
  };

  const button = showIconText && (
    <Button
      data-testid="goto"
      style={{ paddingLeft: "16px", paddingRight: "16px" }}
      size="medium"
      color="primary"
      endIcon={<RedoIcon icon="RedoIcon" iconType="gotoButton" iconSize="small" />}
      onClick={goTo}
    >
      {t("goTo")}
    </Button>
  );

  const iconButton = !showIconText && (
    <IconButton data-testid="goto" onClick={goTo} size="medium" color="primary">
      <RedoIcon />
    </IconButton>
  );

  return <Tooltip title={tooltipText}>{button || iconButton}</Tooltip>;
}

export default GoTo;
