import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { apiEndpoints } from "./apiEndpoint";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userNotificationUrl}/api/`
});

export interface UserNotificationDTO {
  readonly title: string;
  readonly receivers: string[];
  readonly collection: string;
  readonly docId: string;
  readonly docName: string;
  readonly pageId?: string;
  readonly message?: string;
  readonly assignee?: string;
  readonly origin?: Origin;
  readonly idempotencyId?: string;
}
export interface Origin {
  readonly taskId?: string;
  readonly questionId?: string;
  readonly type?: string;
  readonly commentId?: string;
  readonly commentReplyId?: string;
}

export async function sendNotificationApi(notificationPayload: UserNotificationDTO) {
  await axiosInstance.post(`/sendNotification`, {
    ...notificationPayload
  });
}
