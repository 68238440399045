import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { isAxiosErrorWithCode, WithAbortController } from "./axios/axiosErrorHandler";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api/roles`
});

export interface RoleDTO {
  readonly tenantId?: string;
  readonly id?: string;
  readonly name?: string;
  readonly permissions?: string[];
  readonly assignableFields?: string[];
}

export interface RolesDTO {
  readonly roles: RoleDTO[];
}

export interface SingleNewRole {
  readonly name: string;
  readonly permissions: string[];
  readonly assignableFields: string[];
}

export const getRolesApi = async (options: WithAbortController = {}): Promise<RoleDTO[]> => {
  const response = await axiosInstance.get<RolesDTO>("/", {
    signal: options.abortController?.signal
  });
  return response.data?.roles || [];
};

export const getSingleRoleApi = async (id: string): Promise<RoleDTO | null> => {
  try {
    const response = await axiosInstance.get<RoleDTO | null>(`/${id}`);
    return response.data || null;
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404, "NotFound")) {
      return null;
    }
    throw error;
  }
};

export const createRoleApi = async (payload: SingleNewRole): Promise<string> => {
  const response = await axiosInstance.post("/", payload);
  return response.headers["x-resource-id"] || "";
};

export const updateRoleApi = async (id: string, payload: SingleNewRole): Promise<void> => {
  await axiosInstance.patch(`/${id}`, payload);
};

export const deleteRoleApi = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/${id}`);
};
