import React, { useCallback } from "react";
import { BottomNavigation, BottomNavigationAction, Box, makeStyles } from "@material-ui/core";
import { MetaViewTabProps } from "components/MetaView/MetaView";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  tabs: {
    background: theme.palette.blue[50],
    height: "80px",
    width: "100%"
  },
  content: {
    flex: 1,
    overflow: "auto"
  },
  action: {
    "& .MuiBottomNavigationAction-label": {
      marginTop: "6px",
      fontSize: "12px"
    },
    minWidth: "80px"
  }
}));

interface MetaViewTabsProps {
  readonly initialTabId?: string;
  readonly tabs: Array<
    Omit<MetaViewTabProps, "tabId"> & {
      tabId: string;
    }
  >;
}

export default function GenericMetaViewTabs({ tabs, initialTabId }: MetaViewTabsProps) {
  const [state, setState] = React.useState({
    tabId: initialTabId || tabs[0].tabId
  });
  const activeTab = React.useMemo(() => {
    return tabs.find(tab => tab.tabId === state.tabId);
  }, [tabs, state.tabId]);
  const cls = useStyles();

  const onTabChangeCallback = useCallback((event, value) => {
    setState({ tabId: value.tabId });
  }, []);

  return (
    <Box display={"flex"} className={cls.root}>
      <Box display={"flex"} justifyContent="space-between" className={cls.tabs}>
        <BottomNavigation value={activeTab} onChange={onTabChangeCallback} className={cls.tabs}>
          {tabs.map((tab, index) => (
            <BottomNavigationAction key={index} className={cls.action} label={tab.title} value={tab} icon={tab.icon} />
          ))}
        </BottomNavigation>
      </Box>
      <Box className={cls.content}>{activeTab?.content}</Box>
    </Box>
  );
}
