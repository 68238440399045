import { useTranslation } from "react-i18next";
import GroupIcon from "@material-ui/icons/Group";
import StorageIcon from "@material-ui/icons/Storage";
import DescriptionIcon from "@material-ui/icons/Description";
import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import { SeenBadge } from "./SeenBadge";
import { UntranslatedCountBadge } from "./UntranslatedCountBadge";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";

const ResourceDataAssetsComponent = ({
  kind,
  seen,
  id
}: {
  readonly kind: string;
  readonly seen?: boolean;
  readonly id: string;
}) => {
  const { t } = useTranslation("");
  const isMultilingualEnabled = !!useIsFeaturePresent(FEATURES.MULTILINGUAL);

  const getIcon = () => {
    if (kind === "PERSON_GROUP") {
      return <GroupIcon fontSize="small" />;
    } else if (kind === "DATA_CATEGORY") {
      return <StorageIcon fontSize="small" />;
    } else if (kind === "DATA_TYPE") {
      return <DescriptionIcon fontSize="small" />;
    } else return <SeenBadge />;
  };

  const getTitle = () => {
    if (kind === "PERSON_GROUP") {
      return t("personGroup:inputLabel");
    } else if (kind === "DATA_CATEGORY") {
      return t("dataCategory:inputLabel");
    } else if (kind === "DATA_TYPE") {
      return t("dataType:inputLabel");
    }
    return "";
  };

  // noinspection PointlessBooleanExpressionJS
  if (seen === false) {
    // seen have to be explicitly false
    return <SeenBadge />;
  }

  const getStyle = () => {
    if (kind === "PERSON_GROUP") {
      return {
        bgcolor: "green.50",
        color: "green.500"
      };
    } else if (kind === "DATA_CATEGORY" || kind === "DATA_TYPE") {
      return {
        bgcolor: "blue.50",
        color: "blue.500"
      };
    }
    return null;
  };
  const colorStyle = getStyle();

  return (
    <>
      <Box py={1} mr={2}>
        <Tooltip title={getTitle()}>
          <Box
            width={36}
            height={36}
            display="flex"
            borderRadius="50%"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            bgcolor={colorStyle?.bgcolor}
            color={colorStyle?.color}
          >
            {getIcon()}
          </Box>
        </Tooltip>
      </Box>
      {isMultilingualEnabled && <UntranslatedCountBadge id={id} />}
    </>
  );
};

export const ResourceDataAssets = React.memo(ResourceDataAssetsComponent);
