import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress } from "@material-ui/core";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import { AccountSettingsMFABanner } from "../mfa/AccountSettingsMFABanner";
import { useMetaView } from "../../../contexts/meta-view-context";
import useSWR from "swr";
import { getEnforcementInfoApi } from "../../../api/user/userMeApi";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "8px 0 0 0",
    lineHeight: "72px"
  },
  mfaBanner: {
    margin: "0 0 16px 0"
  },
  navigationSpinner: {
    display: "flex",
    width: "120px",
    justifyContent: "center"
  }
}));

export default function AccountSettingsMFARemoval({ onSuccess }: { onSuccess?: () => Promise<void> }) {
  const classes = useStyles();
  const { t } = useTranslation("accountSettingsMFARemoval");
  const { loading: authLoading, removeAllMFAsHook, user } = useAuthentication();
  const { catchAsSnackbar } = useErrorSnackbar();

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo(null);
  }, [setInfo]);

  useEffect(() => {
    if (!authLoading && !user?.mfaActivated && onSuccess) {
      const timeoutId = setTimeout(() => {
        return onSuccess();
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [authLoading, user?.mfaActivated, onSuccess]);

  const [loading, setLoading] = useState(false);

  const removeMFAs = useCallback(() => {
    setLoading(true);
    return removeAllMFAsHook()
      .catch(catchAsSnackbar("failed to remove MFAs"))
      .then(() => {
        setLoading(false);
        onSuccess?.();
      })
      .catch(() => setLoading(false));
  }, [removeAllMFAsHook, onSuccess, catchAsSnackbar]);

  const { data: enforcementInfo } = useSWR("/me/enforcements", () => getEnforcementInfoApi());

  return (
    <div>
      <h2 className={classes.title}>{t("title")}</h2>
      <AccountSettingsMFABanner className={classes.mfaBanner} />
      <div>
        {user?.mfaActivated && (
          <Button
            variant="contained"
            color="primary"
            disabled={loading || enforcementInfo?.mfaEnforced}
            onClick={removeMFAs}
          >
            {t("removeButton")}
          </Button>
        )}
        {enforcementInfo?.mfaEnforced && <p>{t("accountSettingsSecurity:mfaEnforced")}</p>}
        {!user?.mfaActivated && (
          <div className={classes.navigationSpinner}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
    </div>
  );
}
