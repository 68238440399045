import { AxiosInstance } from "axios";
import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export interface TokenDTO {
  readonly id: string;
  readonly title: string;
  readonly expiresAt?: Date;
  readonly expiresIn: string;
  readonly orgUnitId: string;
  readonly furtherOrgUnitIds: string[];
  readonly permissions: string[];
}

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api/tokens`
});

export const getTokensApi = async (): Promise<TokenDTO[]> => {
  const response = await axiosInstance.get("/");
  return response.data?.tokens || [];
};

export const getTokenApi = async (tokenId: string): Promise<TokenDTO | null> => {
  const response = await axiosInstance.get("/" + tokenId);
  return response.data || null;
};

export const createTokenApi = async (
  payload: Omit<TokenDTO, "id">
): Promise<{
  readonly tokenId: string;
  readonly refreshToken: string;
}> => {
  const response = await axiosInstance.post("/", payload);
  return {
    tokenId: response.headers["x-resource-id"] || "",
    refreshToken: response.data.refreshToken || ""
  };
};

export const updateTokenApi = async (tokenId: string, payload: Partial<Omit<TokenDTO, "id" | "expiresIn">>) => {
  return await axiosInstance.patch("/" + tokenId, payload);
};

export const deleteTokenApi = async (tokenId: string) => {
  return await axiosInstance.delete("/" + tokenId);
};
