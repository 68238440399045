import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { QuestionProps } from "../../Question";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";

const ParticipantTypeQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  multiSelectHiddenIds,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation("add_participant");
  const isExternalUserEnabled = useIsFeaturePresent(FEATURES.EXTERNAL_USER_ASSESSMENT);
  const _value = Array.isArray(value) ? (value[0] as string) : (value as string);
  const options = (isExternalUserEnabled ? ["internal", "external"] : ["internal"]).filter(id => {
    if (multiSelectHiddenIds) {
      return !multiSelectHiddenIds.includes(id);
    }
    return true;
  });
  const getOptionLabel = useCallback(option => t(`participant_type_${option}`), [t]);

  return (
    <MultiAutocomplete
      label={questionName || t("tasks_page:type")}
      selected={_value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      options={options}
      disableClearable={true}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default React.memo(ParticipantTypeQuestion);
