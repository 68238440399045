import { Box, Typography } from "@material-ui/core";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { t } from "i18next";
import { useMemo } from "react";

interface OrgUnitDependenciesDialogProps {
  readonly openModal: boolean;
  readonly message: string;
  readonly onCLose: () => void;
}

const OrgUnitDependenciesDialog = ({ openModal, message, onCLose }: OrgUnitDependenciesDialogProps) => {
  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCLose
        }
      ] as ConfirmationModalButtonProps[],
    [onCLose]
  );

  const modalBody = useMemo(() => {
    return (
      <Box>
        <Typography>{message}</Typography>
      </Box>
    );
  }, [message]);

  return (
    <ConfirmationModal
      variant={"info"}
      modalBody={modalBody}
      modalOpen={openModal}
      modalTitle={t("common:information")}
      onClose={onCLose}
      buttons={buttons}
      modalText={""}
    />
  );
};

export default OrgUnitDependenciesDialog;
