import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Checkbox, Radio, makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { uniq } from "lodash-es";
import { useInView } from "react-intersection-observer";
import { naturalSortBy } from "../../app/utils/naturalSort";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(() => ({
  treeItem: {
    cursor: "pointer",
    "& label": {
      cursor: "pointer"
    },
    "& .row": {
      "&:hover": {
        backgroundColor: "#f0f0f0"
      }
    },
    "& .shevron": {
      cursor: "pointer",
      paddingTop: "4px",
      minWidth: "32px",
      textAlign: "center"
    }
  }
}));

const anyChildrenChecked = (items = [], checkedItems) => {
  return items.length && items.some(i => checkedItems.includes(i.id) || anyChildrenChecked(i.children, checkedItems));
};

const allChildrenChecked = (items = [], checkedItems) => {
  return items.length && items.every(i => checkedItems.includes(i.id) && anyChildrenChecked(i.children, checkedItems));
};

const anyItemsOnLevelHasChild = item => {
  return (item.children || []).some(c => c.children?.length);
};

const buildTree = (parent, tree, checkedItems) => {
  return (tree || []).map(item => {
    const checked = checkedItems.includes(item.id) || allChildrenChecked(item.children, checkedItems);
    return {
      id: item.id,
      name: item.name,
      checkable: item.checkable,
      children: buildTree({ checked: checked }, item.children || [], checkedItems),
      checked: checked,
      indeterminate: item.checked || anyChildrenChecked(item.children, checkedItems)
    };
  });
};

const getAllCheckedIds = tree => {
  let ids = [];
  const deepCheck = item => {
    if (item.checked) {
      ids.push(item.id);
    }
    if (item.children) {
      item.children.forEach(deepCheck);
    }
  };
  tree.forEach(deepCheck);
  return ids.filter(i => i !== null && i !== undefined);
};

const getAllEndCheckedIds = tree => {
  let ids = [];
  const deepCheck = item => {
    if (item.checked && (!item.children || !item.children.length)) {
      ids.push(item.id);
    }
    if (item.children) {
      item.children.forEach(deepCheck);
    }
  };
  tree.forEach(deepCheck);
  return ids.filter(i => i !== null && i !== undefined);
};

const getAllInnerIds = tree => {
  let ids = [];
  const deepCheck = item => {
    ids.push(item.id);
    if (item.children) {
      item.children.forEach(deepCheck);
    }
  };
  tree.forEach(deepCheck);
  return ids;
};

const treeInViewStyle = { minHeight: 38 };

const TreeItemInView = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  return (
    <div style={treeInViewStyle} ref={ref}>
      {inView ? children : null}
    </div>
  );
};

const TreeItem = ({ item, parent, level, singleSelect, onChecked }) => {
  const cls = useStyles();
  const [expand, setExpand] = useState(item.expand);
  const onToogleCallback = useCallback(() => {
    setExpand(prev => !prev);
  }, []);
  const onCheckedCallback = useCallback(() => {
    onChecked(item, parent, Boolean(!item.checked));
  }, [item, onChecked, parent]);
  const onShevronClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      onToogleCallback();
    },
    [onToogleCallback]
  );

  const sortedItemChildren = useMemo(
    () => naturalSortBy(item.children || [], [it => it.sortingPosition || 0, it => it.name.trim()]),
    [item.children]
  );
  const childrenEl = expand && (
    <Box className="children">
      {sortedItemChildren.map((i, index) => (
        <TreeItemInView key={index}>
          <TreeItem parent={item} item={i} level={level + 1} onChecked={onChecked} />
        </TreeItemInView>
      ))}
    </Box>
  );

  const controlEl = useMemo(() => {
    if (item.checkable === false) {
      return <Box flex={1}>{item.name}</Box>;
    } else {
      if (singleSelect === true) {
        return (
          <FormControlLabel
            value={item.value}
            control={
              <Radio
                size="small"
                checked={Boolean(item.checked)}
                indeterminate={Boolean(!item.checked && item.indeterminate)}
                onChange={event => onCheckedCallback(event)}
                color="primary"
              />
            }
            label={item.name}
          />
        );
      } else {
        return (
          <FormControlLabel
            value={item.value}
            control={
              <Checkbox
                size="small"
                checked={Boolean(item.checked)}
                indeterminate={Boolean(!item.checked && item.indeterminate)}
                onChange={event => onCheckedCallback(event)}
                color="primary"
              />
            }
            label={item.name}
          />
        );
      }
    }
  }, [item.checkable, item.checked, item.indeterminate, item.name, item.value, onCheckedCallback, singleSelect]);

  return (
    <Box className={`${expand ? "expand" : ""} ${cls.treeItem}`}>
      <Box
        pr={4}
        pl={2 + level * 2}
        className="row"
        onClick={() => (level === 0 ? onToogleCallback() : onCheckedCallback())}
      >
        <Box display="flex" alignItems={"center"} justifyContent={"start"} minHeight={38}>
          {(!parent || level > 1 || anyItemsOnLevelHasChild(parent)) && (
            <Box className="shevron" onClick={onShevronClick}>
              {item.children?.length > 0 && (expand ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />)}
            </Box>
          )}
          {controlEl}
        </Box>
      </Box>
      {childrenEl}
    </Box>
  );
};

const TreeFilter = ({
  tree,
  checkedItems,
  onChange,
  field = "orgUnitId",
  singleSelect,
  uncheckParentForUncheckedChildrens = false
}) => {
  const [innerTree, setInnerTree] = useState();
  const onChecked = useCallback(
    (item, parent, checked) => {
      const prevCheckedIds = uncheckParentForUncheckedChildrens
        ? getAllEndCheckedIds(innerTree)
        : getAllCheckedIds(innerTree);
      let newCheckedIds;
      let allInnersIds = getAllInnerIds(item.children || []);
      if (checked) {
        newCheckedIds = singleSelect ? [item.id] : [...prevCheckedIds, item.id, ...allInnersIds];
      } else {
        newCheckedIds = singleSelect ? [] : prevCheckedIds.filter(i => i !== item.id && allInnersIds.indexOf(i) < 0);
      }

      newCheckedIds = uniq(newCheckedIds);
      const newTree = buildTree(null, tree, newCheckedIds);
      setInnerTree(newTree);
      const itemIds = getAllCheckedIds(newTree);
      onChange({ [field]: itemIds });
    },
    [innerTree, uncheckParentForUncheckedChildrens, tree, onChange, field, singleSelect]
  );

  useEffect(() => {
    const newTree = buildTree(null, tree, checkedItems || []);
    setInnerTree(newTree);
  }, [checkedItems, tree]);

  const treeEl = useMemo(() => {
    return (innerTree || []).map((item, index) => (
      <TreeItem key={index} level={0} item={item} singleSelect={singleSelect} onChecked={onChecked} />
    ));
  }, [innerTree, onChecked, singleSelect]);

  return <Box height="100%">{treeEl || null}</Box>;
};

export default TreeFilter;
