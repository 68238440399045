import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import { isAxiosErrorWithCode } from "../axios/axiosErrorHandler";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.fileStorageRequestUrl}/api/v1/document-center`
});

export interface FileTreeStructureResponse {
  readonly items: FileTreeStructure[];
}

export interface FileTreeStructure {
  readonly title: string;
  readonly fileType: boolean;
  readonly path: string;
  readonly size: number;
  readonly updated: Date;
  readonly orgUnitIds: string[];
  readonly children: FileTreeStructure[];
}

export interface FolderMetaData {
  readonly orgUnitIds: string[];
  readonly s3ObjectKey: string;
}

export const getDocumentOverviewApi = async (): Promise<FileTreeStructureResponse> => {
  const { data } = await axiosInstance.get(`/overview`);
  return data;
};

export const uploadDocumentFileApi = async (input: { readonly file: string | Blob; readonly path?: string }) => {
  const { file, path } = input;
  const formData = new FormData();
  formData.append("formFile", file);

  return await axiosInstance.post(`/upload/${path && path.length > 0 && encodeURIComponent(path)}`, formData, {
    timeout: 0, // no timeout to accomodate large files
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const deleteDocumentFileApi = async (path: string) => {
  await axiosInstance.delete(`/${encodeURIComponent(path)}`);
};

export const deleteDocumentFolderApi = async (path: string) => {
  await axiosInstance.delete(`/folder/${encodeURIComponent(path)}`);
};

export const setDocumentFolderMetaDataApi = async (path: string, orgUnitIds: string[]): Promise<void> => {
  await axiosInstance.patch(`/metadata/${encodeURIComponent(path)}`, { orgUnitIds });
};

export const getDocumentFolderMetaDataApi = async (path: string): Promise<FolderMetaData> => {
  const response = await axiosInstance.get(`/metadata/${encodeURIComponent(path)}`);
  return response.data;
};
export const getDocumentParentFolderMetaDataApi = async (path: string): Promise<FolderMetaData> => {
  const response = await axiosInstance.get(`/parent-metadata/${encodeURIComponent(path)}`);
  return response.data;
};

export const moveDocumentFileOrFolderApi = async (orignalPath: string, newPath: string): Promise<void> => {
  await axiosInstance.patch(`/move/${encodeURIComponent(orignalPath)}/${encodeURIComponent(newPath)}`);
};

export const addDocumentFolderApi = async (path: string) => {
  return await axiosInstance.post(`/upload-folder/${encodeURIComponent(path)}`);
};

export const renameDocumentFolderFileOrFolderApi = async (
  originalPath: string,
  newPath: string,
  fileType: boolean
): Promise<void> => {
  await axiosInstance.patch(`/${encodeURIComponent(originalPath)}/${encodeURIComponent(newPath)}?fileType=${fileType}`);
};

/**
 *
 * @param orgUnitIds org unit ids to delete
 * @returns COLLECTION_TYPES or null
 * COLLECTION.FILE_STORAGE if we can NOT delete passed org unit ids
 * null if we can delete passed org unit ids
 */
export const deleteOrgUnitDocumentCheckApi = async (orgUnitIds: string[]): Promise<COLLECTION_TYPES | null> => {
  try {
    const response = await axiosInstance.post(`/delete-check`, { orgUnitIds });
    return response.status === 204 ? null : COLLECTIONS.FILE_STORAGE;
  } catch {
    return COLLECTIONS.FILE_STORAGE;
  }
};

export const downloadDocumentFileApi = async (input: { readonly filePath: string }): Promise<void> => {
  const blob = await getDocumentFileAsBlob(input);
  if (!blob) {
    throw new Error(`File not found: ${input.filePath}`);
  }
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const name = input.filePath.split("/").pop() || "no-name";
  link.setAttribute("download", name as string);
  document.body.appendChild(link);
  link.click();
};

export const getDocumentFileAsBlob = async (input: { readonly filePath: string }): Promise<Blob | null> => {
  try {
    const response = await axiosInstance.get(`/${encodeURIComponent(input.filePath)}`, {
      responseType: "blob",
      timeout: 0 // no timeout to accomodate large files
    });
    return new Blob([response.data]);
  } catch (error: unknown) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};
