import React, { Suspense, lazy, memo } from "react";
import { SvgIcon } from "@mui/material";

function _LazySvgIcon(props: { name: string; onClick?: () => void; color?: string; disabled?: boolean }) {
  const { name, onClick, color, disabled } = props;

  const LazyIcon = lazy(() => {
    return import(`../../assets/images/icons/${name}.svg`);
  });

  return (
    <SvgIcon
      onClick={disabled ? undefined : onClick}
      sx={{
        height: 24,
        width: 24,
        cursor: disabled || !onClick ? undefined : "pointer",
        color,
        opacity: disabled ? 0.5 : 1
      }}
    >
      <Suspense fallback={<div />}>
        <LazyIcon />
      </Suspense>
    </SvgIcon>
  );
}

export const LazySvgIcon = memo(_LazySvgIcon);
