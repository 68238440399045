import {
  createUserApi,
  deleteUserApi,
  getSingleUserApi,
  getUsersApi,
  SingleTenantNewUser,
  SingleTenantUpdateUser,
  updateUserApi,
  UserDTO
} from "app/api/user/userApi";
import { retryUntil } from "app/handlers/utility/retry";
import { removeEqualOrUndefined } from "app/handlers/utility/removeEqualOrUndefined";
import { isEqual } from "lodash-es";
import { WithAbortController } from "../../api/axios/axiosErrorHandler";

export const getUsers = (options: WithAbortController = {}) => getUsersApi(options);

export const getUserData = (userId: string, isGroupFeaturesEnabled?: boolean) =>
  getSingleUserApi(userId, isGroupFeaturesEnabled);

export const deleteUser = async (userId: string) => {
  await deleteUserApi(userId);

  // we do not need to delete the firestore doc as they contain no personal info
  // also if the user with same email is re-added to the tenant, they will have the same id, and will just pick up
  // the last preference etc, that's why we do not delete them here

  await retryUntil(async () => {
    const createdUser = await getUserData(userId);
    if (createdUser) {
      throw new Error("Timeout on delete user");
    }
  });
};

export const createUser = async (tenantId: string, payload: SingleTenantNewUser): Promise<UserDTO> => {
  let createdUserId: string;
  try {
    createdUserId = await createUserApi(payload);
  } catch (error) {
    console.error(error);
    throw new Error("create-user/auth-creation-failed");
  }

  return await retryUntil(async () => {
    const createdUser = await getUserData(createdUserId);
    if (!createdUser) {
      throw new Error("Timeout on create user");
    }
    return createdUser;
  });
};

export const updateUser = async (userId: string, payload: SingleTenantUpdateUser): Promise<UserDTO> => {
  const user = await getUserData(userId, payload.groupIds !== undefined);
  if (!user) {
    throw new Error("Trying to update non existing user");
  }

  const changedPayload: SingleTenantUpdateUser = removeEqualOrUndefined(payload, user);
  if (Object.keys(changedPayload).length === 0) {
    return user;
  }

  await updateUserApi(userId, changedPayload);

  return await retryUntil(async () => {
    const updatedUser = await getUserData(userId, payload.groupIds !== undefined);
    const [changedKey, changedValue] = Object.entries(changedPayload)[0];

    if (!updatedUser || !isEqual((updatedUser as any)[changedKey], changedValue)) {
      throw new Error("Timeout on update user");
    }
    return updatedUser;
  });
};
