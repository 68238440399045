import React, { useCallback } from "react";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentIcon from "@material-ui/icons/AssignmentInd";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Button, IconButton } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ClassIcon from "@material-ui/icons/Class";
import PublishIcon from "@material-ui/icons/Publish";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import LinkIcon from "@material-ui/icons/Link";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import DateRangeIcon from "@material-ui/icons/DateRange";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CloseIcon from "@material-ui/icons/Close";
import RedoIcon from "@material-ui/icons/Redo";
import CancelIcon from "@material-ui/icons/Cancel";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupIcon from "@material-ui/icons/Group";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExportIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FilterListIcon from "@material-ui/icons/FilterList";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import StyleIcon from "@material-ui/icons/Style";
import SettingsIcon from "@material-ui/icons/Settings";
import ProcessInReviewIcon from "../../assets/images/icons/inReview.svg";
import ProcessEditIcon from "../../assets/images/icons/edit.svg";
import ApprovedIcon from "../../assets/images/icons/approved.svg";
import DoubleCheckIconSVG from "../../assets/images/icons/double-check.svg";
import AssignNewIcon from "../../assets/images/icons/assignedTo.svg";
import PrivacyExpertIcon from "../../assets/images/icons/dataPrivacyExpert.svg";
import MarkAllAsReadIcon from "../../assets/images/icons/markAllAsRead.svg";

const useStyles = makeStyles(theme => ({
  inlineIcons: {
    paddingRight: "10px",
    cursor: "pointer",
    width: "45px"
  },
  checkIcon: {
    color: theme.palette.primary.main,
    marginRight: "5px",
    cursor: "pointer"
  },
  disabledConfirmIcon: {
    color: theme.palette.grey[200],
    marginRight: "5px",
    cursor: "default"
  },
  doubleCheckIcon: {
    marginRight: "5px",
    height: "25px",
    cursor: "default"
  },
  addIcon: {
    color: theme.palette.primary.main,
    marginRight: "5px",
    cursor: "pointer"
  },
  removeIcon: {
    color: theme.palette.error.main,
    marginRight: "5px",
    cursor: "pointer"
  },
  clearIcon: {
    color: theme.palette.error.main,
    marginRight: "5px",
    cursor: "pointer"
  },
  regularIcon: {
    marginRight: "5px",
    cursor: "pointer"
  },
  successIcon: {
    marginRight: "5px",
    cursor: "pointer",
    color: theme.palette.success.main
  },
  warningIcon: {
    marginRight: "5px",
    cursor: "pointer",
    color: theme.palette.warning.main
  },
  errorIcon: {
    marginRight: "5px",
    cursor: "pointer",
    color: theme.palette.error.main
  },
  infoIcon: {
    color: theme.palette.warning.main
  },
  buttonHoverOver: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  confirmButton: {
    marginRight: "5px",
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));

/**
 * Render icon with the button
 */
export function RenderIconButton({
  icon,
  iconType,
  iconSize,
  disabled,
  elementId,
  classes,
  additionalClasses,
  toolTipTitle,
  color,
  callBackFunction,
  ...otherProps
}) {
  const styles = useStyles();

  const iconClasses = {
    ...(classes ?? styles),
    ...(additionalClasses || {})
  };

  const onIconClick = useCallback(
    event => {
      return callBackFunction(elementId, event);
    },
    [callBackFunction, elementId]
  );

  return (
    <span className={iconClasses.inlineIcons}>
      <Tooltip title={toolTipTitle}>
        <IconButton color={color} onClick={onIconClick} disabled={disabled}>
          <RenderPlainIcon
            {...otherProps}
            icon={icon}
            iconType={iconType}
            iconSize={iconSize}
            elementId={elementId}
            disabled={disabled}
            classes={iconClasses}
            callBackFunction={callBackFunction}
          />
        </IconButton>
      </Tooltip>
    </span>
  );
}

export function RenderPlainIcon({
  icon,
  iconSize,
  callBackFunction = () => {},
  elementId,
  disabled,
  classes,
  additionalClasses,
  iconType = "regular",
  ...otherProps
}) {
  const styles = useStyles();

  const iconClasses = {
    ...(classes ?? styles),
    ...(additionalClasses || {})
  };

  const onClick = useCallback(
    event => {
      event?.stopPropagation?.();
      if (!disabled) {
        callBackFunction(elementId, event);
      }
    },
    [callBackFunction, elementId, disabled]
  );

  const iconColor = disabled ? "disabled" : "inherit";

  switch (icon) {
    case "GroupIcon":
      return (
        <GroupIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "DescriptionIcon":
      return (
        <DescriptionIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "VpnKeyIcon":
      return (
        <VpnKeyIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AccountBalanceIcon":
      return (
        <AccountBalanceIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ShareIcon":
      return (
        <ShareIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "EditIcon":
      return (
        <EditIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "CheckBoxIcon":
      return (
        <CheckBoxIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "GetAppIcon":
      return (
        <GetAppIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "FileCopyIcon":
      return (
        <FileCopyIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "PersonAddIcon":
      return (
        <PersonAddIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AddIcon":
      return (
        <AddIcon
          {...otherProps}
          className={iconClasses.addIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "RemoveIcon":
      return (
        <RemoveIcon
          {...otherProps}
          className={iconClasses.removeIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "InfoIcon":
      return (
        <InfoOutlinedIcon
          {...otherProps}
          className={iconClasses.infoIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "CreateIcon":
      return (
        <CreateIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "DeleteIcon":
      return (
        <DeleteIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "FiberNewIcon":
      return (
        <FiberNewIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "PlaylistAddIcon":
      return (
        <PlaylistAddIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AddBoxIcon":
      return (
        <AddBoxIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AddToPhotosIcon":
      return (
        <AddToPhotosIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "DateRangeIcon":
      return (
        <DateRangeIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "CheckIcon":
      return (
        <span>
          <Button className={iconClasses.buttonHoverOver} onClick={onClick} disabled={!!disabled}>
            <CheckIcon
              {...otherProps}
              className={disabled ? iconClasses.disabledConfirmIcon : iconClasses.checkIcon}
              fontSize={iconSize}
              data-testid="check-icon"
            />
          </Button>
        </span>
      );
    case "ConfirmIcon":
      return (
        <CheckIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "DisabledConfirmIcon":
      return (
        <CheckIcon
          {...otherProps}
          className={iconClasses.disabledConfirmIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "DoubleCheckIcon":
      return (
        <DoubleCheckIconSVG
          {...otherProps}
          className={iconClasses.doubleCheckIcon}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
          fill="#009907"
        />
      );
    case "ClearIcon":
      return (
        <span>
          <Button className={iconClasses.buttonHoverOver} onClick={onClick} disabled={!!disabled}>
            <ClearIcon
              {...otherProps}
              className={disabled ? iconClasses.disabledConfirmIcon : iconClasses.clearIcon}
              fontSize={iconSize}
              data-testid="clear-icon"
            />
          </Button>
        </span>
      );
    case "CloseIcon":
      return (
        <CloseIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
          data-testid="close-icon"
        />
      );
    case "VisibilityIcon":
      return (
        <VisibilityIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "VisibilityOffIcon":
      return (
        <VisibilityOffIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AssignmentIcon":
      return (
        <AssignmentIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "PublishIcon":
      return (
        <PublishIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "LinkIcon":
      return (
        <LinkIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "UnlinkIcon":
      return (
        <LinkOffIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ArrowForwardIcon":
      return (
        <span>
          <Button className={iconClasses.buttonHoverOver} onClick={onClick} disabled={!!disabled}>
            <ArrowForwardIcon
              {...otherProps}
              className={iconClasses[iconType + "Icon"]}
              fontSize={iconSize}
              onClick={onClick}
              color={iconColor}
            />
          </Button>
        </span>
      );
    case "ArrowBackIcon":
      return (
        <span>
          <Button className={iconClasses.buttonHoverOver} onClick={onClick} disabled={!!disabled}>
            <ArrowBackIcon
              {...otherProps}
              className={iconClasses[iconType + "Icon"]}
              fontSize={iconSize}
              onClick={onClick}
              color={iconColor}
            />
          </Button>
        </span>
      );
    case "CheckCircleOutline":
      return (
        <CheckCircleOutlineIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ErrorOutline":
      return (
        <ErrorOutlineIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "WarningOutline":
      return (
        <WarningOutlinedIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AccountCircleIcon":
      return (
        <AccountCircleIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "SupervisedUserCircleIcon":
      return (
        <SupervisedUserCircleIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "StyleIcon":
      return (
        <StyleIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "CancelIcon":
      return (
        <CancelIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AccountBoxIcon":
      return (
        <AccountBoxIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ExpandMoreIcon":
      return (
        <ExpandMoreIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ExpandLessIcon":
      return (
        <ExpandLessIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "RedoIcon":
      return (
        <RedoIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AssignmentIndIcon":
      return (
        <AssignNewIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "AddCircleOutlineIcon":
      return (
        <AddCircleOutlineIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "MergeTypeIcon":
      return (
        <MergeTypeIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "SettingsIcon":
      return (
        <SettingsIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ApprovedIcon":
      return (
        <ApprovedIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ProcessInReviewIcon":
      return (
        <ProcessInReviewIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ProcessEditIcon":
      return (
        <ProcessEditIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "PrivacyExpertIcon":
      return (
        <PrivacyExpertIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "MarkAllAsReadIcon":
      return (
        <MarkAllAsReadIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          cursor={disabled ? "not-allowed" : "pointer"}
        />
      );
    case "HomeWorkIcon":
      return (
        <HomeWorkIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "InsertDriveFileIcon":
      return (
        <InsertDriveFileIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "FilterListIcon":
      return (
        <FilterListIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ExportIcon":
      return (
        <ExportIcon
          {...otherProps}
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
        />
      );
    case "ClassIcon":
      return (
        <ClassIcon
          className={iconClasses[iconType + "Icon"]}
          fontSize={iconSize}
          onClick={onClick}
          color={iconColor}
          data-testid="classify-icon"
        />
      );
    default:
      throw Error("Invalid Icon requested in RenderIcon: " + icon);
  }
}

/**
 * Render icon without the button
 */
export default function RenderIcon({
  icon,
  iconSize = undefined,
  toolTipTitle = "",
  callBackFunction = () => {},
  elementId = "",
  disabled = false,
  classes = {},
  additionalClasses = {},
  iconType = "regular",
  ...otherProps
}) {
  const styles = useStyles();

  const iconClasses = {
    ...(classes ?? styles),
    ...(additionalClasses || {})
  };

  const onIconClick = useCallback(
    event => {
      return callBackFunction(elementId, event);
    },
    [callBackFunction, elementId]
  );

  return (
    <span className={iconClasses.inlineIcons}>
      <Tooltip title={toolTipTitle}>
        {/**
         tooltip child needs ref-able element, but plain icon returns svg and is not ref-able
         so wrap with span, and it breaks click, so adds on click on top too
         */}
        <span onClick={onIconClick}>
          <RenderPlainIcon
            {...otherProps}
            icon={icon}
            iconSize={iconSize}
            callBackFunction={callBackFunction}
            elementId={elementId}
            disabled={disabled}
            classes={iconClasses}
            iconType={iconType}
          />
        </span>
      </Tooltip>
    </span>
  );
}
