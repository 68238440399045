import React, { useCallback, useMemo } from "react";
import { Box, IconButton, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTranslation } from "react-i18next";
import { AuditRemarkContext } from "app/api/auditApi";

const AuditRemarkView = ({
  auditRemark,
  onEdit,
  onDelete
}: {
  readonly auditRemark: AuditRemarkContext;
  readonly onEdit: () => void;
  readonly onDelete: () => void;
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const menuEl = useMemo(() => {
    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem key={"edit"} onClick={onEdit}>
            {t("common:edit")}
          </MenuItem>
          <MenuItem key={"delete"} onClick={onDelete}>
            {t("common:delete")}
          </MenuItem>
        </Menu>
      </>
    );
  }, [anchorEl, handleClick, handleClose, onDelete, onEdit, t]);

  return (
    <Paper>
      <Box p={2}>
        <Box mb={1} display="flex" alignItems="center">
          <Box flex={1}>
            <Typography style={{ fontWeight: "bold" }} variant="h4">
              {t("audit_details:remark")}
            </Typography>
          </Box>
          <Box>{menuEl}</Box>
        </Box>
        <Box mt={2} whiteSpace="pre-line">
          {auditRemark.value || ""}
        </Box>
      </Box>
    </Paper>
  );
};

export default AuditRemarkView;
