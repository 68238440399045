const getApiEndpoint = (
  subdomain: string,
  options?: { readonly protocol?: "http" | "https"; readonly domain?: string }
) => {
  const protocol = options?.protocol || "https";
  const domain = options?.domain || import.meta.env.VITE_API_DOMAIN || "dev-caralegal.de";
  return `${protocol}://${subdomain}.${domain}`;
};

export const apiEndpoints = {
  assetUrl: getApiEndpoint("asset"),
  auditUrl: getApiEndpoint("audit"),
  cookieBoxUrl: getApiEndpoint("cookiebox"),
  dataBreachUrl: getApiEndpoint("databreach"),
  dataSubjectRequestUrl: getApiEndpoint("dsr"),
  deeplUrl: getApiEndpoint("translation"),
  deletionConceptUrl: getApiEndpoint("deletionconcept"),
  externalRecipientUrl: getApiEndpoint("external-recipient"),
  fileStorageRequestUrl: getApiEndpoint("filestorage"),
  owlitUrl: getApiEndpoint("owlit"),
  paUrl: getApiEndpoint("pa"),
  resourceUrl: getApiEndpoint("resource"),
  riskUrl: getApiEndpoint("risk"),
  taskUrl: getApiEndpoint("task"),
  tomUrl: getApiEndpoint("tom"),
  userUrl: getApiEndpoint("user"),
  commentUrl: getApiEndpoint("comment"),
  userNotificationUrl: getApiEndpoint("usernotification"),
  hasuraUrl: getApiEndpoint("hasura"),
  cubeJsHelperUrl: getApiEndpoint("cubejs"),
  mlUrl: getApiEndpoint("ml"),
  dataAssets: getApiEndpoint("asset"),
  publicLinkUrl: getApiEndpoint("publiclink"),
  infoCardUrl: getApiEndpoint("infovault"),
  documentUrl: getApiEndpoint("filestorage"),
  orgUnitUrl: getApiEndpoint("orgunit"),
  multilingualUrl: getApiEndpoint("multilingual"),
  aiTechDocUrl: getApiEndpoint("aitechdoc")
} as const;
