import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DataLocationsPicker } from "./DataLocationsPicker";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";

export default function DataLocationsPickerModal({
  open,
  onChange,
  onSelectionChanged,
  selectedIDs,
  label,
  onClose,
  additionalInfo
}: {
  readonly open: boolean;
  readonly selectedIDs: string[];
  readonly label?: string;
  readonly onChange: (dlIDs: string[]) => void; // this one returns on save
  readonly onSelectionChanged?: (dlIDs: string[]) => void; // this one returns in real time what user picked
  readonly onClose: () => void;
  readonly additionalInfo?: React.ReactNode;
}) {
  const { t } = useTranslation();
  const [selectedDataLocationIds, setSelectedDataLocationIds] = useState<string[]>([]);
  const onSave = useCallback(() => onChange(selectedDataLocationIds), [onChange, selectedDataLocationIds]);
  useEffect(() => {
    setSelectedDataLocationIds(selectedIDs);
  }, [selectedIDs]);
  const onChangeSelection = useCallback(
    ids => {
      setSelectedDataLocationIds(ids);
      onSelectionChanged?.(ids);
    },
    [onSelectionChanged]
  );
  const modalBodyEl = useMemo(
    () => (
      <>
        <DataLocationsPicker onChange={onChangeSelection} selectedIDs={selectedDataLocationIds} />
        {additionalInfo}
      </>
    ),
    [additionalInfo, onChangeSelection, selectedDataLocationIds]
  );
  const buttons: ConfirmationModalButtonProps[] = [
    {
      confirmButton: false,
      title: t("common:cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: onClose
    },
    {
      confirmButton: true,
      title: t("service_providers_overview:save"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: onSave
    }
  ];
  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onClose}
      modalTitle={t("service_providers_overview:link_tooltip_activate_data_location")}
      modalText={label || ""}
      buttons={buttons}
      modalBody={modalBodyEl}
      variant={"info"}
    />
  );
}
