import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { UpdateTomModelDTO, useGetTOMApi, useUpdateTOMApi } from "../../../api/tomApi";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { AttachmentsOverviewOBS } from "../../shared/Attachments/AttachmentsOverviewOBS";

export const TOMDetails = ({ readonly, id }: { readonly readonly?: boolean; readonly id: string }) => {
  const { data: tom } = useGetTOMApi({ documentId: id || "" });
  const { updateTOMApi } = useUpdateTOMApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateTomModelDTO, UpdateTomModelDTO>({
    triggerUpdate: updateTOMApi
  });

  const { auth } = useAuthentication();

  const { t } = useTranslation("tom_description_tab");
  const { setInfo, setMeta } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoTitle"),
        text: t("enteringInfoText")
      },
      name: {
        title: t("title"),
        text: t("titleText")
      },
      description: {
        title: t("title"),
        text: t("descriptionText")
      },
      labels: {
        title: t("labels:info_header"),
        text: t("labels:info_text_measure")
      },
      protectionObjective: {
        title: t("title"),
        text: t("protectionObjectiveText")
      },
      orgUnit: {
        title: t("title"),
        text: t("orgUnitText")
      },
      furtherAffectedOrgUnit: {
        title: t("title"),
        text: t("furtherOrgUnitText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const [mainOrgUnitId, setMainOrgUnitId] = useState<string>("");
  const [furtherOrgUnitIds, setFurtherOrgUnitIds] = useState<string[]>([]);
  const [statusDate, setStatusDate] = useState<string | null>(null);
  const [description, setDescription] = useState<string>("");
  const [protectionObjectiveIds, setProtectionObjectiveIds] = useState<string[]>([]);
  const [labelIds, setLabelIds] = useState<string[]>([]);
  useEffect(() => {
    if (tom) {
      setDescription(tom.description || "");
      setMainOrgUnitId(tom.orgUnitId || auth?.orgUnitId || "");
      setFurtherOrgUnitIds(tom.furtherAffectedOrgUnitIds || []);
      setStatusDate(tom.statusDate ? new Date(tom.statusDate).toISOString() : null);
      setProtectionObjectiveIds(tom.protectionObjectiveIds || []);
      setLabelIds(tom.labelIds || []);
    }
  }, [tom, auth?.orgUnitId]);

  const onMainOrgUnitIdChange = useCallback(
    (value: string) => {
      setMainOrgUnitId(value);
      queueUpdates({ orgUnitId: value });
    },
    [queueUpdates]
  );

  const onFurtherOrgUnitIdsChange = useCallback(
    (value: string[]) => {
      setFurtherOrgUnitIds(value);
      queueUpdates({ furtherAffectedOrgUnitIds: value });
    },
    [queueUpdates]
  );

  const onStatusDateChange = useCallback(
    (value: string | null) => {
      setStatusDate(value);
      queueUpdates({ statusDate: value ? new Date(value) : null });
    },
    [queueUpdates]
  );

  const onDescriptionChange = useCallback(
    (value: string) => {
      setDescription(value);
      queueUpdates({ description: value });
    },
    [queueUpdates]
  );

  const onChangeProtectionObjectiveIds = useCallback(
    (value: string[]) => {
      setProtectionObjectiveIds(value);
      queueUpdates({ protectionObjectiveIds: value });
    },
    [queueUpdates]
  );

  const onLabelIdsChange = useCallback(
    (value: string[]) => {
      setLabelIds(value);
      queueUpdates({ labelIds: value });
    },
    [queueUpdates]
  );

  const docOrgUnitIds = useMemo(
    () => [tom?.orgUnitId, ...(tom?.furtherAffectedOrgUnitIds || [])].filter((it): it is string => !!it),
    [tom?.orgUnitId, tom?.furtherAffectedOrgUnitIds]
  );

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("generalInformation")} />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
          questionId={"orgUnit"}
          questionName={t("data_breaches_general_page:org_unit")}
          value={mainOrgUnitId}
          onChange={onMainOrgUnitIdChange}
          info={infoCard.orgUnit}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.ORG_UNITS_PICKER}
          questionId={"furtherAffectedOrgUnit"}
          questionName={t("risk_general_page:furtherAffectedOrgUnit")}
          value={furtherOrgUnitIds}
          onChange={onFurtherOrgUnitIdsChange}
          info={infoCard.furtherAffectedOrgUnit}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.DATE}
          questionId={"dueDate"}
          questionName={t("processes_overview:implementationDate")}
          value={statusDate}
          onChange={onStatusDateChange}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.TEXT_EDITOR}
          questionId={"description"}
          translatable={description}
          questionName={t("description")}
          value={description}
          onChange={onDescriptionChange}
          info={infoCard.description}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.PROTECTION_OBJECTIVE}
          questionId={"protectionObjective"}
          questionName={t("protectionObjective")}
          value={protectionObjectiveIds}
          onChange={onChangeProtectionObjectiveIds}
          info={infoCard.protectionObjective}
          disabled={readonly}
          orgUnitIds={docOrgUnitIds}
          onlyIntersectingOrgUnitIds={true}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.LABELS}
          questionId={"labels"}
          questionName={t("labels:label")}
          value={labelIds}
          onChange={onLabelIdsChange}
          disabled={readonly}
          info={infoCard.labels}
          orgUnitIds={docOrgUnitIds}
          onlyIntersectingOrgUnitIds={true}
        />
      </Box>
      <Question questionName={t("attachments")} questionId={"attachments"}>
        <QuestionnaireSubHeader text={t("common:attachments")} />
        <AttachmentsOverviewOBS docId={id} category={"tom"} setMeta={setMeta} disabled={readonly} />
      </Question>
    </Box>
  );
};
