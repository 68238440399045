import { getI18n } from "app/i18n";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";

export const defaultAxios = (axiosOptions?: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create({
    timeout: 10000,
    headers: {
      Accept: "application/json"
    },
    ...(axiosOptions || {})
  });

  // provide translation for axios errors
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (!axios.isAxiosError(error)) {
        return Promise.reject(error);
      }

      const httpErrorCode = `${error.response?.status}`;
      const is4xxError = httpErrorCode.startsWith("4");
      const is5xxError = httpErrorCode.startsWith("5");
      if (is4xxError || is5xxError) {
        const errorKey = `http_errors:${httpErrorCode}`;
        const message = getI18n().t(errorKey);
        error.message = message === errorKey ? error.message : message;
      }
      return Promise.reject(error);
    }
  );

  axiosRetry(axiosInstance, {
    retries: 3,
    shouldResetTimeout: true,
    retryCondition: error => {
      // only retry on get which is a timeout because this is the safe case to retry
      const isTimeoutError = error.code === "ECONNABORTED" && error.message.includes("timeout");
      const isGetMethod = error.config?.method?.toLowerCase() === "get";
      return isGetMethod && isTimeoutError;
    },
    retryDelay: axiosRetry.exponentialDelay,
    onRetry: (retryCount, error, config) => {
      console.warn(
        `axios-retry ${retryCount} for ${config?.method || ""} ${config?.baseURL || ""}${config?.url || ""}`
      );
    }
  });
  return axiosInstance;
};

export const testAxios = (): AxiosInstance => {
  return defaultAxios({
    validateStatus: () => true
  });
};
