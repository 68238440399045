import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress } from "@material-ui/core";
import { useExternalRecipients } from "../../contexts/external-recipient-context";
import { useUserDepartments } from "../../contexts/department-context";
import { DataLocationDTO } from "../../api/externalRecipientApi";
import { useDataLocations } from "../../../hook/useDataLocations";
import { useUserProcesses } from "../../../hook/useUserProcesses";
import EditIcon from "assets/images/icons/edit.svg";
import SearchIcon from "@material-ui/icons/Search";
import DoneIcon from "@material-ui/icons/Done";
import MetaViewList, { MetaViewListItemProps } from "components/MetaViewList/MetaViewList";
import ApprovedIcon from "assets/images/icons/approved.svg";

const paStatusIcons: Record<string, React.ReactNode> = {
  edit: <EditIcon />,
  review: <SearchIcon />,
  approved: <DoneIcon />
};

export default function DataLocationDetailsInMetaview({ dataLocationId }: { dataLocationId: string }) {
  const {
    processes: relatedProcesses,
    processesLoaded,
    isLoading
  } = useUserProcesses({
    dataLocationIDs: [dataLocationId]
  });

  const { t } = useTranslation("lists_data_locations");
  const [dataLocationDetailsLoaded, setDataLocationDetailsLoaded] = useState(false);
  const { getDepartmentName } = useUserDepartments();
  const { externalRecipients, initialized } = useExternalRecipients();
  const { dataLocations, dataLocationsLoaded } = useDataLocations();

  const [dataLocation, setDataLocation] = useState<DataLocationDTO | null>();

  useEffect(() => {
    const found = dataLocations.find(item => item.id === dataLocationId);
    setDataLocation(found || null);
  }, [dataLocationId, dataLocations]);

  useEffect(() => {
    if (!processesLoaded || !dataLocationsLoaded || !dataLocation) {
      return;
    }
    setDataLocationDetailsLoaded(false);
    setDataLocationDetailsLoaded(true);
  }, [dataLocation, processesLoaded, dataLocationsLoaded]);

  const externalRecipient = useMemo(
    () => externalRecipients.find(externalRecipient => externalRecipient.id === dataLocation?.externalRecipientId),
    [dataLocation, externalRecipients]
  );

  const externalDataRecipients = useMemo(() => {
    if (!externalRecipient) {
      return [];
    }
    return [
      {
        id: externalRecipient?.id,
        title: externalRecipient.name,
        subTitle: externalRecipient.serviceType
          ? t("lists_service_provider_types:" + externalRecipient.serviceType)
          : "",
        icon: externalRecipient?.approvedAt ? <ApprovedIcon /> : <EditIcon />
      }
    ];
  }, [externalRecipient, t]);

  /* PROCESS ACTIVITIES */
  const relatedProcessingActivities = useMemo(
    () =>
      relatedProcesses.map(
        process =>
          ({
            id: process.id,
            title: process.title,
            subTitle: process.orgUnitIds?.length ? getDepartmentName(process.orgUnitIds[0]) : "",
            icon: paStatusIcons[process.status] || <></>,
            iconTooltip: t("processes_overview:" + process.status)
          }) satisfies MetaViewListItemProps
      ),
    [getDepartmentName, relatedProcesses, t]
  );
  const onProcessItemClickCallback = useCallback((id: string) => {
    window.open(`/processes/${id}/general`);
  }, []);

  /* EXTERNAL RECIPIENTS */
  const onERItemClickCallback = useCallback((id: string) => {
    window.open(`/external-recipients/general/${id}`);
  }, []);
  const metaViewContent = (
    <>
      {dataLocationDetailsLoaded && (
        <Box p={3}>
          <MetaViewList
            header={t("service_providers_overview:externalRecipient")}
            items={externalDataRecipients}
            loading={!initialized}
            onItemClick={onERItemClickCallback}
            pagination={false}
          />
          <Box mt={3} />
          <MetaViewList
            header={t("processes_overview:list_processes")}
            items={relatedProcessingActivities}
            loading={isLoading}
            onItemClick={onProcessItemClickCallback}
            pagination={false}
          />
        </Box>
      )}
      {!dataLocationDetailsLoaded && (
        <Box pt={2} display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );

  return <>{metaViewContent}</>;
}
