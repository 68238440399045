import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CustomAlert from "components/CustomAlert/CustomAlert";

const useStyles = makeStyles(theme => ({
  manageMFAAlert: {
    width: "365px"
  },
  customWarningColor: {
    color: theme.palette.warning.dark
  },
  manageMFALink: {
    "&:hover": {
      textDecoration: "none"
    }
  }
}));

export const AccountSettingsMFABanner = ({ className }: { className?: string }) => {
  const classes = useStyles();
  const { t } = useTranslation("accountSettingsMFABanner");
  const { user } = useAuthentication();

  return (
    <div className={className || ""}>
      {user?.mfaActivated && (
        <div className={classes.manageMFAAlert}>
          <CustomAlert severity="success" icon={<CheckCircleOutline />}>
            {t("multiFactorEnabled")}
          </CustomAlert>
        </div>
      )}
      {!user?.mfaActivated && (
        <div className={classes.manageMFAAlert}>
          <CustomAlert severity="warning" icon={<ErrorOutlineIcon />}>
            {t("multiFactorDisabled")}
          </CustomAlert>
        </div>
      )}
    </div>
  );
};
