import Box from "@material-ui/core/Box";
import Chart from "bizcharts/lib/components/Chart";
import Tooltip from "bizcharts/lib/components/Tooltip";
import Coordinate from "bizcharts/lib/components/Coordinate";
import Interaction from "bizcharts/lib/components/Interaction";
import AnnotationText from "bizcharts/lib/components/Annotation/text";
import Interval from "bizcharts/lib/geometry/Interval";
import Line from "bizcharts/lib/geometry/Line";
import Point from "bizcharts/lib/geometry/Point";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Axis from "bizcharts/lib/components/Axis";
import Legend from "bizcharts/lib/components/Legend";
import { STATS_TYPE } from "../../handlers/dashboardHandler";
import { useBizChartModel } from "./bizchart";

export const TimeAndCountChart = ({
  className,
  explicitChartType,
  chartData,
  chartScale,
  chartHeight,
  color,
  timePeriodId,
  stackIfBarchart
}) => {
  const { t } = useTranslation("dashboardPage");
  const { combinePieChartDataOnType } = useBizChartModel();

  const automaticChartType = useMemo(() => {
    if (chartData?.length === 0) {
      return "empty";
    }

    if (timePeriodId === STATS_TYPE.ALL_TIME) {
      return "bar";
    }

    if (chartData?.length === 2) {
      return "pie";
    }

    return "bar";
  }, [timePeriodId, chartData?.length]);

  const chartType = useMemo(() => {
    if (explicitChartType) {
      return explicitChartType;
    }

    return automaticChartType;
  }, [explicitChartType, automaticChartType]);

  const [combinedChartData, setCombinedChartData] = useState([]);
  useEffect(() => {
    if (chartType !== "pie") {
      setCombinedChartData([]);
      return;
    }
    setCombinedChartData(combinePieChartDataOnType(chartData));
  }, [chartType, combinePieChartDataOnType, chartData]);

  const [visibleTotal, setVisibleTotal] = useState(0);

  useEffect(() => {
    if (chartType !== "pie") return;
    const total = combinedChartData.reduce((sum, item) => sum + item.count, 0);
    setVisibleTotal(total);
  }, [chartType, combinedChartData]);

  return (
    <Box className={className} m={3}>
      {chartType === "empty" && (
        <Box mt={4} ml={3}>
          <em>{t("noDataPeriod")}</em>
        </Box>
      )}
      {chartType === "line" && (
        <Chart autoFit height={chartHeight || defaultChartHeight} data={chartData} scale={chartScale}>
          <Line position="date*count" color={color} shape="smooth" />
          <Point position="date*count" color={color} shape="circle" />
          <Tooltip shared showCrosshairs region={null} position="left" />
        </Chart>
      )}
      {chartType === "pie" && (
        <Chart
          autoFit
          height={chartHeight || defaultChartHeight}
          data={combinedChartData}
          scale={chartScale}
          onGetG2Instance={chart => {
            chart.on("legend-item:click", () => {
              const currentData = chart.filteredData || chart.getData();
              const newTotal = currentData.reduce((sum, item) => sum + item.count, 0);
              setVisibleTotal(newTotal);
            });
          }}
        >
          <Coordinate type="theta" radius={0.85} innerRadius={0.81} />
          <Legend />
          <Interval position="count" adjust="stack" color={color} shape="sliceShape" />
          <Interaction type="element-active" />
          <AnnotationText
            position={["50%", "45%"]}
            content={visibleTotal}
            style={{
              fontSize: 24,
              fontWeight: 700,
              textAlign: "center"
            }}
          />
          <AnnotationText
            position={["50%", "55%"]}
            content={t("total")}
            style={{
              fontSize: 12,
              fontWeight: 700,
              textAlign: "center"
            }}
          />
          <Tooltip />
        </Chart>
      )}
      {chartType === "bar" && (
        <Chart autoFit height={chartHeight || defaultChartHeight} data={chartData} scale={chartScale}>
          <Interval adjust={stackIfBarchart ? stackBarChart : barChart} color={color} position="date*count" />
          <Tooltip shared />
        </Chart>
      )}
    </Box>
  );
};

TimeAndCountChart.propTypes = {
  timePeriodId: PropTypes.string,
  className: PropTypes.string,
  explicitChartType: PropTypes.string,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired,
  chartScale: PropTypes.object.isRequired,
  color: PropTypes.array.isRequired,
  stackIfBarchart: PropTypes.bool,
  chartHeight: PropTypes.number
};

TimeAndCountChart.defaultProps = {
  timePeriodId: STATS_TYPE.ALL_TIME,
  stack: false
};

const barChart = [
  {
    type: "dodge",
    marginRatio: 0
  }
];

const stackBarChart = [
  {
    type: "stack"
  }
];

const defaultChartHeight = 440;

// eslint-disable-next-line no-unused-vars
const justSomeUsageToPreventTheseAutomaticallyRemoved = {
  Axis,
  Legend
};
