import { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate();
  return (pathname: string, params = {}) =>
    navigate({
      pathname,
      ...(params && Object.entries(params).length && { search: `?${createSearchParams(params)}` })
    });
};

export const useQueryParamByKey = (key: string) => {
  const [searchParams] = useSearchParams();
  return searchParams.get(key);
};

export const usePathParamByKey = (key: string) => {
  const params = useParams();
  return params[key];
};

export const usePathName = () => {
  const location = useLocation();
  return location.pathname;
};

export const useAllQueryParams = () => {
  const [searchParams] = useSearchParams();
  const result: Record<string, string> = {};
  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }
  return result;
};

export const useHashFragments = () => {
  const location = useLocation();
  const [hashFragments, setHashFragments] = useState<Record<string, string>>({});
  useEffect(() => {
    const hashFragmentString = location?.hash;
    if (!hashFragmentString?.length) {
      return;
    }
    const hashFragment = hashFragmentString.slice(1); // without the initial #
    const urlSearchParams = new URLSearchParams(hashFragment);
    setHashFragments(Object.fromEntries(urlSearchParams));
  }, [location?.hash]);

  return hashFragments;
};
