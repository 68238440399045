import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, Grid, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachmentsOverviewOBS from "../../app/pages/shared/Attachments/AttachmentsOverviewOBS";
import { ButtonProps } from "@material-ui/core/Button/Button";
import CustomAlert from "../CustomAlert/CustomAlert";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import TextBody2 from "components/TextBody2/TextBody2";

const useStyles = makeStyles((theme: any) => ({
  circularLoading: {
    color: theme.palette.blue[50],
    position: "relative",
    top: "3px",
    right: "3px"
  },
  editModalIcon: {
    color: theme.palette.alert.info
  },
  editModalIconWarning: {
    color: theme.palette.alert.warning
  }
}));

TemplateModal.defaultProps = {
  modalBody: "",
  variant: "warning",
  buttons: []
};

export interface TemplateModalButtonProps
  extends Pick<ButtonProps, "variant" | "color" | "size" | "onClick" | "disabled" | "title"> {
  readonly confirmButton?: boolean;
}

export interface TemplateModalProps {
  readonly modalOpen: boolean;
  readonly onClose: () => void;
  readonly modalTitle: string;
  readonly modalText: string;
  readonly modalBody?: React.ReactNode;
  readonly docId: string;
  readonly category: string;
  readonly buttons?: TemplateModalButtonProps[];
}

export default function TemplateModal({
  modalOpen,
  onClose,
  category,
  docId,
  modalTitle,
  modalText,
  modalBody,
  buttons
}: TemplateModalProps) {
  const [loadingTemplate, setLoadingTemplate] = useState(false);

  useEffect(() => {
    if (!modalOpen) {
      setLoadingTemplate(false);
    }
  }, [modalOpen]);

  const classes = useStyles();
  return (
    <Dialog open={modalOpen} onClose={onClose}>
      <DialogContent style={{ minWidth: 600 }}>
        <CustomAlert severity={"info"} icon={<ErrorOutline className={classes.editModalIcon} />}>
          {modalTitle}
        </CustomAlert>
        <Box whiteSpace="pre-wrap" mt={2}>
          <TextBody2 text={modalText} />
        </Box>
        {modalBody && <Box mt={2}>{modalBody}</Box>}
        <AttachmentsOverviewOBS category={category} docId={docId} />
        {Array.isArray(buttons) && (
          <Box mt={2} mb={2}>
            <Grid container justifyContent={buttons.length > 1 ? "space-between" : "flex-end"} spacing={1}>
              {buttons.map((button, index) => (
                <Grid item key={"button_" + index}>
                  <TemplateModalButton button={button} loading={loadingTemplate} onLoadingChange={setLoadingTemplate} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const TemplateModalButton = ({
  button,
  loading,
  onLoadingChange
}: {
  readonly button: TemplateModalButtonProps;
  readonly loading: boolean;
  readonly onLoadingChange: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const onClick = button.onClick;
  const onClickCallback = useCallback(
    (event: any) => {
      onLoadingChange(true);
      onClick?.(event);
    },
    [onClick, onLoadingChange]
  );

  return (
    <Button
      variant={button.variant}
      color={button.color ?? "primary"}
      size={button.size ?? "medium"}
      disabled={loading || button.disabled}
      onClick={onClickCallback}
    >
      {!button.confirmButton && <span id={"confirmation_modal" + button.title}>{button.title}</span>}
      {!loading && button.confirmButton && <span>{button.title}</span>}
      {loading && button.confirmButton && (
        <span>
          <CircularProgress size={14} className={classes.circularLoading} /> {button.title}
        </span>
      )}
    </Button>
  );
};
