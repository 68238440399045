import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DocTitle from "components/DocTitle/DocTitle";

const useStyles = makeStyles(theme => ({
  iconColumn: {
    width: "60px",
    padding: "23px 0 0 0"
  },
  iconButton: {
    display: "block",
    margin: "0 auto"
  },
  documentViewContent: {
    padding: "30px 30px 30px 0"
  },
  docViewContainer: {
    marginBottom: "50px"
  }
}));

export default function DocViewWithBackButton({
  header,
  children,
  onBack
}: {
  header?: string;
  children?: React.ReactNode;
  onBack?: () => void;
}) {
  const classes = useStyles();

  const backCallback = useCallback(() => {
    onBack?.();
  }, [onBack]);

  return (
    <Grid container style={{ overflowY: "auto", height: "100%" }}>
      <Grid item className={classes.iconColumn}>
        <IconButton className={classes.iconButton} onClick={backCallback}>
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid item className={classes.docViewContainer}>
        <Grid container spacing={0} direction="column" justifyContent="center" className={classes.documentViewContent}>
          <Grid item xs={12}>
            <DocTitle title={header || ""} />
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
