import i18next from "../i18n";

export const createOverviewItemDefaultName = function (collection) {
  const now = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000);
  const timeString = now
    .toISOString()
    .split("T")
    .reduce((a, b) => a + " " + b.split(".")[0], "");
  return i18next.t("overview_default_items:" + collection) + timeString + " " + now.toString().split(" ")[5];
};
