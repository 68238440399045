import { apiEndpoints } from "app/api/apiEndpoint";
import i18n from "app/i18n";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.deletionConceptUrl}/api/deletionconcepts`
});

export const getDeletionConceptExports = async (params: {
  readonly orgUnitIds?: string[];
  readonly personGroupIds?: string[];
  readonly dataStorageIds?: string[];
}): Promise<Blob> => {
  const response = await axiosInstance.get(`/overview/exports`, {
    responseType: "blob",
    params: {
      orgUnitIds: params.orgUnitIds,
      personGroupIds: params.personGroupIds,
      dataStorageIds: params.dataStorageIds
    },
    headers: {
      "Accept-Language": i18n.language || "en-US"
    },
    timeout: 0
  });
  return new Blob([response.data]);
};

export const getDataCategoryUsageApi = async (input: {
  readonly personGroupId: string;
  readonly dataCategoryId: string;
}): Promise<Partial<DataCategoryUsageDetailDTO>> => {
  const response = await axiosInstance.get<Partial<DataCategoryUsageDetailDTO>>(
    `/usages/personGroups/${input.personGroupId}/dataCategories/${input.dataCategoryId}`
  );

  return response.data || {};
};

export interface DataCategoryUsageDTO {
  readonly personGroupId: string;
  readonly dataCategoryId: string;
  readonly processingActivityIds: string[];
}

export interface DataCategoryUsageDetailDTO extends DataCategoryUsageDTO {
  readonly usagePerProcess: DataCategoryUsagePerProcessDTO[];
  readonly usagePerStoragePeriod: DataCategoryUsagePerStoragePeriodsDTO[];
}

export interface DataCategoryUsagePerProcessDTO {
  readonly processActivityId: string;
  readonly title: string;
  readonly status: string;
  readonly purposes: string[];
  readonly storagePeriod: string;
  readonly storagePeriodUnit: string;
  readonly deletionTriggerId: string | null;
  readonly justificationOfStoragePeriod: string | null;
  readonly deletionTypeIds: string[];
  readonly deletionPractice: string | null;
  readonly orgUnitId: string;
  readonly furtherOrgUnitIds: string[];
  readonly internalRecipientIds: string[];
  readonly externalRecipientIds: string[];
  readonly dataStorageIds: string[];
}

export interface DataCategoryUsagePerStoragePeriodsDTO {
  readonly storagePeriod: string;
  readonly storagePeriodUnit: string;
  readonly mainOrgUnitIds: string[];
  readonly furtherOrgUnitIds: string[];
  readonly deletionTriggerIds: string[];
  readonly justificationOfStoragePeriods: string[];
  readonly deletionTypeIds: string[];
  readonly deletionPractices: string[];
  readonly internalRecipientIds: string[];
  readonly dataStorageIds: string[];
  readonly externalRecipientIds: string[];
  readonly purposes: string[];
  readonly processingActivityIds: string[];
  readonly processingActivityTitles: string[];
}
