import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CommentsDisplay from "app/pages/shared/CommentsDisplay/CommentsDisplay";
import { useQueryParamByKey } from "app/router/router-custom-hooks";
import { useMetaView } from "app/contexts/meta-view-context";

export default function MetaViewCommentsTab({
  collection,
  docId,
  docName,
  pageId
}: {
  readonly collection: string;
  readonly docId: string;
  readonly docName: string;
  readonly pageId: string;
}) {
  const { t } = useTranslation();
  const paramCommentId = useQueryParamByKey("commentId");
  const { questionId, newCommentForm, setNewCommentForm } = useMetaView();

  const resetNewCommentForm = useCallback(() => {
    setNewCommentForm(false);
  }, [setNewCommentForm]);

  return (
    <CommentsDisplay
      buttonTextCancel={t("common:cancel")}
      buttonTextReply={t("common:reply")}
      buttonTextSave={t("common:save")}
      clickOnCommentBadge={newCommentForm}
      collection={collection}
      docId={docId}
      docName={docName}
      onCancelComment={resetNewCommentForm}
      onSubmitComment={resetNewCommentForm}
      pageId={pageId}
      preselectedCommentId={paramCommentId || ""}
      questionId={questionId}
    />
  );
}
