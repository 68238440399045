import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { useMetaView } from "../../../contexts/meta-view-context";
import DateDisplay from "../../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../../components/UserDataDisplay";
import { tSimpleTranslate } from "app/handlers/dataTypeTranslatorHandler";
import { exportDataSubjectRequest } from "../utils/createDsrExportData";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useResources } from "../../../contexts/resource-context";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { dsrWritePermissions, publicLinkWritePermissions } from "../../../handlers/permissionHandler";
import { DSRPublicLinksModal } from "../DSRPublicLinks";

const DataSubjectRequestOverview = () => {
  const { t, i18n } = useTranslation();
  const { setInfo, setMeta } = useMetaView();
  const { auth } = useAuthentication();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("data_subject_requests_overview:enteringInfoCardTitle"),
        text: t("data_subject_requests_overview:enteringInfoCardText")
      },
      creating: {
        title: t("data_subject_requests_overview:creatingInfoCardTitle"),
        text: t("data_subject_requests_overview:creatingInfoCardText")
      }
    }),
    [t]
  );
  const notAvailable = useMemo(() => <span className="faded">{t("common:not_available")}</span>, [t]);
  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setMeta(null);
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo, setMeta]);
  const showCreateInfo = useCallback(() => {
    setMeta(null);
    setInfo(infoCard?.creating);
  }, [infoCard, setInfo, setMeta]);

  const { translateById } = useResources();
  const dataTypeTree = useDataTypeTree();

  const onRowOver = useCallback(
    item => {
      const meta = {
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        [t("case_type")]: (item.types || []).map((dsrType: string) => {
          return translateById(RESOURCE_TYPES.DSR_REQUEST_TYPE, dsrType);
        }),

        person_groups: item.personGroups?.length
          ? item.personGroups?.map((personGroupId: string) => {
              const personGroup = dataTypeTree.dataById?.personGroups?.[personGroupId];
              return tSimpleTranslate({
                t,
                typeCategoryOrPersonGroup: personGroup?.personGroupKey || "deletedEntry"
              });
            })
          : notAvailable
      };
      setMeta(meta);
    },
    [notAvailable, setMeta, t, translateById, dataTypeTree.dataById?.personGroups]
  );

  const exportToXLSX = useCallback(
    async ids => {
      if (auth?.tenantId) {
        return await exportDataSubjectRequest(auth?.tenantId, t, i18n, ids);
      }
    },
    [auth?.tenantId, t, i18n]
  );

  const collectionParams = useMemo(
    () => ({
      exportToXLSX
    }),
    [exportToXLSX]
  );

  const publicLinkEnabled = useIsFeaturePresent(FEATURES.PUBLIC_REQUESTS);
  const [publicLinkModalOpen, setPublicLinkModalOpen] = useState(false);
  const onPublicLinkModelOpen = useCallback(() => {
    setPublicLinkModalOpen(true);
  }, []);
  const onPublicLinkModelClose = useCallback(() => {
    setPublicLinkModalOpen(false);
  }, []);

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }, { action: "export-xlsx" }], []);
  const havePublicLinkWritePermissions = useMemo(
    () => auth?.permissions.some(permission => publicLinkWritePermissions.includes(permission)),
    [auth?.permissions]
  );
  const addActions = useMemo(
    () => [
      { action: OVERVIEW_ADD_TYPE.SINGLE },
      { action: OVERVIEW_ADD_TYPE.MULTIPLE },
      ...(publicLinkEnabled && havePublicLinkWritePermissions
        ? [
            {
              action: OVERVIEW_ADD_TYPE.CUSTOM,
              title: t("overview:createPublicLink"),
              onHandle: onPublicLinkModelOpen
            }
          ]
        : [])
    ],
    [publicLinkEnabled, havePublicLinkWritePermissions, t, onPublicLinkModelOpen]
  );
  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }], []);
  const selectionActions = useMemo(() => [{ action: "export-xlsx" }, { action: "remove" }], []);

  const showAddActions = auth?.permissions.find(permission => dsrWritePermissions.includes(permission));
  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"data_subject_requests_overview"} />}>
      <Overview
        collection={COLLECTIONS.DATA_SUBJECT_REQUESTS}
        onAddOpen={showCreateInfo}
        onAddClose={showEnterInfo}
        onRowOver={onRowOver}
        onRowLeave={showEnterInfo}
        header={t("data_subject_requests_overview:header")}
        toolbarActions={toolbarActions}
        rowActions={rowActions}
        addActions={showAddActions ? addActions : undefined}
        checkable={true}
        collectionParams={collectionParams}
        selectionActions={selectionActions}
        paginated={true}
        toolbarMode={"tabs"}
      />
      <DSRPublicLinksModal open={publicLinkModalOpen} onClose={onPublicLinkModelClose} />
    </DocMetaView>
  );
};

export default React.memo(DataSubjectRequestOverview);
