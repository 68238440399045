import React from "react";
import { useTranslation } from "react-i18next";
import { toJSDateObjectIfFirebaseDate } from "../app/handlers/utility/date-helper";

export default function DateDisplay({ displaySeconds, timestamp }: { displaySeconds?: boolean; timestamp: unknown }) {
  const { t } = useTranslation("");

  const formatDate = function () {
    if (!timestamp) {
      return <span className="faded">{t("common:not_available")}</span>;
    }
    const dateObj =
      typeof timestamp === "object"
        ? toJSDateObjectIfFirebaseDate(timestamp)
        : typeof timestamp === "string"
          ? new Date(timestamp)
          : timestamp;
    if (isNaN(dateObj.getTime())) {
      return <span className="faded">{t("common:not_available")}</span>;
    }
    if (displaySeconds) {
      return dateObj.toLocaleString();
    } else {
      return dateObj.toLocaleDateString();
    }
  };

  return <span>{formatDate()}</span>;
}
