import { React, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import { STATUSES, updateGroupTaskInformation, updateTaskInformation } from "../../../handlers/tasksHandler";
import { Create, Done, RadioButtonUnchecked } from "@material-ui/icons";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 50,
    paddingTop: "5px"
  },
  todo: {
    "& >button": {
      background: theme.palette.common.white,
      color: theme.palette.grey[700]
    },
    "& >button:hover": {
      background: theme.palette.common.white
    }
  },
  open: {
    "& >button": {
      background: theme.palette.warning.main
    },
    "& >button:hover": {
      background: theme.palette.warning.main
    }
  },
  done: {
    "& >button": {
      background: theme.palette.success.main
    },
    "& >button:hover": {
      background: theme.palette.success.main
    }
  },
  action: {
    "& button": {
      width: "40px",
      height: "40px"
    },
    "& .MuiSpeedDial-actions": {
      position: "absolute",
      zIndex: 1001,
      top: "30px",
      "& button": {
        width: "36px",
        height: "36px"
      }
    }
  },
  openAction: {
    "& .MuiSpeedDial-actions": {
      marginTop: 21,
      padding: "4px 0",
      background: theme.palette.common.white,
      boxShadow:
        "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      borderRadius: "24px"
    }
  }
}));

export default function TaskStatus({ task, auth, onChangeStatus }) {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const [status, setStatus] = useState(task.status);
  const [open, setOpen] = useState(false);

  const updateTask = useCallback(
    async value => {
      if (task.groupId && task.assignedToType === "GROUP") {
        await updateGroupTaskInformation(auth.tenantId, auth.uid, task.id, {
          status: value
        });
      } else {
        await updateTaskInformation(auth.tenantId, auth.uid, task.id, {
          status: value
        });
      }
      setStatus(value);
      if (onChangeStatus) {
        onChangeStatus(value);
      }
    },
    [auth.tenantId, auth.uid, onChangeStatus, task.id]
  );

  const handleClose = event => {
    //setOpen(false);
  };

  const handleOpen = event => {
    event.stopPropagation();
    setOpen(true);
  };

  const actions = [
    { icon: <RadioButtonUnchecked />, value: STATUSES.todo },
    { icon: <Create />, value: STATUSES.open },
    { icon: <Done />, value: STATUSES.done }
  ];

  const getStatusIcon = useCallback(() => {
    return {
      TODO: <RadioButtonUnchecked />,
      OPEN: <Create />,
      DONE: <Done />
    }[status];
  }, [status]);

  return (
    <Tooltip title={t("status_" + status)} placement="top" arrow>
      <Box className={cls.root} onMouseLeave={handleClose} onClick={handleOpen}>
        <SpeedDial
          size="small"
          ariaLabel=""
          direction="down"
          className={`${cls.action} ${open && cls.openAction} ${cls[status.toLowerCase()]}`}
          open={open}
          icon={getStatusIcon()}
        >
          {actions
            .filter(a => a.value !== status)
            .map((action, index) => (
              <SpeedDialAction
                key={`${action.name}-${index}`}
                icon={action.icon}
                tooltipTitle={t("status_" + action.value)}
                onClick={() => {
                  updateTask(action.value);
                }}
              />
            ))}
        </SpeedDial>
      </Box>
    </Tooltip>
  );
}
