import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useCallback } from "react";
import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";

export const YesNoQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation();

  const onChangeWrapper = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "true") {
        onChange?.(true);
      }
      if (event.target.value === "false") {
        onChange?.(false);
      }
    },
    [onChange]
  );

  return (
    <RadioGroup row name={questionName} value={value} onChange={onChangeWrapper} onFocus={onFocus} onBlur={onBlur}>
      <FormControlLabel value={true} control={<Radio color="primary" />} label={t("common:yes")} disabled={disabled} />
      <FormControlLabel value={false} control={<Radio color="primary" />} label={t("common:no")} disabled={disabled} />
    </RadioGroup>
  );
};
