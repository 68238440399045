import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.error.main,
    marginLeft: "8px",
    borderRadius: "16px",
    minWidth: "20px",
    padding: "4px 6px",
    color: theme.palette.common.white,
    fontSize: "11px",
    lineHeight: "11px",
    textAlign: "center"
  }
}));

interface OverviewUnseeCountProps {
  readonly count: number;
}
const OverviewUnseeCount = ({ count }: OverviewUnseeCountProps) => {
  const cls = useStyles();
  return count ? <Typography className={cls.root}>{count}</Typography> : <></>;
};

export default OverviewUnseeCount;
