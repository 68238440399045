import React from "react";
import { Box, Button } from "@material-ui/core";
import { noUppercaseOpenSansButton } from "../../app/utils/standardStyles";

export default function StaticDocViewSubmitButton({
  text,
  onClick
}: {
  readonly text: string;
  readonly onClick: () => void;
}) {
  return (
    <Box display="flex" justifyContent="center">
      <Button variant="contained" color="primary" onClick={onClick} style={noUppercaseOpenSansButton}>
        {text}
      </Button>
    </Box>
  );
}
