import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { AITechDocsApi, CreateAITechDocDTO, UpdateAITechDocDTO } from "./generated/aitechdoc-service";
import useSWR from "swr";
import { useCallback } from "react";
import { addToSeenItemsOfUser } from "../handlers/userAndTenant/userSeenItemsHandler";
import { COLLECTIONS } from "../collections";
import { getSingleApi, updateApi } from "./assetApi";
import { retryUntil } from "../handlers/utility/retry";

const aiTechDocClient = new AITechDocsApi(undefined, apiEndpoints.aiTechDocUrl, defaultOTCAuthenticatedAxios());
export const useGetAITechDocApi = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["asset", args.documentId] : null, () => {
    return aiTechDocClient.getAITechDoc(args.documentId).then(resp => resp.data);
  });
};

export const useUpdateAITechDocApi = (args: { documentId: string }) => {
  const { mutate, isValidating } = useGetAITechDocApi({ documentId: args.documentId });

  return {
    updateAITechDoc: useCallback(
      async (payload: UpdateAITechDocDTO) => {
        await mutate(
          async data => {
            await aiTechDocClient.updateAITechDoc(args.documentId, payload);
            return data;
          },
          {
            populateCache: false,
            revalidate: true
          }
        );
      },
      [args.documentId, mutate]
    ),
    isValidating
  };
};

export const getAITechDocOverview = async () => {
  const response = await aiTechDocClient.getOverviewOfAITechDocs();
  return response.data;
};

export const getAITechDocExcelExport = async (ids: string[]) => {
  const response = await aiTechDocClient.exportAITechDocsAsExcel(
    {
      aiTechDocIds: ids
    },
    {
      responseType: "blob",
      timeout: 0
    }
  );
  return new Blob([response.data]);
};

export const createAITechDoc = async (payload: CreateAITechDocDTO, wait?: boolean) => {
  const response = await aiTechDocClient.createAITechDoc(payload, wait ? "true" : undefined);
  return response.headers["x-resource-id"] || "";
};

export class AITechDocAssetLinkError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export const createAITechDocAndLinkToAsset = async (input: {
  payload: CreateAITechDocDTO;
  assetId: string;
  tenantId: string;
  userId: string;
}) => {
  const asset = await getSingleApi(input.assetId);
  if (!asset) {
    throw new AITechDocAssetLinkError("No permission to read access");
  }
  const haveAccessToUpdateApi = await updateApi(input.assetId, {})
    .then(() => true)
    .catch(() => false);
  if (!haveAccessToUpdateApi) {
    throw new AITechDocAssetLinkError("No permission to link techdoc to asset");
  }

  const createdAITechDocId = await createAITechDoc(input.payload, true);
  addToSeenItemsOfUser(input.tenantId, input.userId, COLLECTIONS.AI_TECH_DOCS, createdAITechDocId);
  if (!createdAITechDocId) {
    throw new AITechDocAssetLinkError("Error in creating techdoc");
  }

  // retry for 1 sec since it might be that the tech doc is not yet available for asset
  await retryUntil(
    () =>
      updateApi(input.assetId, {
        aiTechDocIds: [...(asset.aiTechDocIds || []), createdAITechDocId]
      }),
    1000
  );

  return createdAITechDocId;
};
