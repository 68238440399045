/* tslint:disable */
/* eslint-disable */
/**
 * Comment Service API
 * Comment Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CommentModelDB
 */
export interface CommentModelDB {
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof CommentModelDB
     */
    'contentState': object;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'value': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentModelDB
     */
    'mentionedUsers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'documentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'parentCommentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'creatorUID': string;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDB
     */
    'updaterUID'?: string | null;
}
/**
 * 
 * @export
 * @interface CommentModelDTO
 */
export interface CommentModelDTO {
    /**
     * 
     * @type {object}
     * @memberof CommentModelDTO
     */
    'contentState'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDTO
     */
    'value'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentModelDTO
     */
    'mentionedUsers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDTO
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDTO
     */
    'documentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentModelDTO
     */
    'parentCommentId'?: string | null;
}
/**
 * 
 * @export
 * @interface DeleteComment403Response
 */
export interface DeleteComment403Response {
    /**
     * 
     * @type {string}
     * @memberof DeleteComment403Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteComment403Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface GetComment404Response
 */
export interface GetComment404Response {
    /**
     * 
     * @type {string}
     * @memberof GetComment404Response
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface GetComments200Response
 */
export interface GetComments200Response {
    /**
     * 
     * @type {Array<CommentModelDB>}
     * @memberof GetComments200Response
     */
    'commentModels'?: Array<CommentModelDB>;
}
/**
 * 
 * @export
 * @interface GetComments401Response
 */
export interface GetComments401Response {
    /**
     * 
     * @type {string}
     * @memberof GetComments401Response
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface MigrateCommentsRequest
 */
export interface MigrateCommentsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MigrateCommentsRequest
     */
    'migrationAll'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MigrateCommentsRequest
     */
    'tenantIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OkResponse
 */
export interface OkResponse {
    /**
     * 
     * @type {string}
     * @memberof OkResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface UpdateComment403Response
 */
export interface UpdateComment403Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateComment403Response
     */
    'error': string;
}

/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a comment
         * @summary Create a comment
         * @param {CommentModelDTO} commentModelDTO Comment object that needs to be added
         * @param {string} [wait] Wait for the comment to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (commentModelDTO: CommentModelDTO, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentModelDTO' is not null or undefined
            assertParamExists('createComment', 'commentModelDTO', commentModelDTO)
            const localVarPath = `/api/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentModelDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a comment
         * @summary Delete a comment
         * @param {string} commentId Comment ID
         * @param {string} [wait] Wait for the comment to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (commentId: string, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/api/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a comment
         * @summary Get a comment
         * @param {string} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getComment', 'commentId', commentId)
            const localVarPath = `/api/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all replies of a comment
         * @summary Get all replies of a comment
         * @param {string} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentReplies: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getCommentReplies', 'commentId', commentId)
            const localVarPath = `/api/comments/{commentId}/replies`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all comments
         * @summary Get all comments
         * @param {string} [namespace] Namespace
         * @param {string} [documentId] Document ID
         * @param {string} [namespacePrefix] Namespace Prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: async (namespace?: string, documentId?: string, namespacePrefix?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (namespacePrefix !== undefined) {
                localVarQueryParameter['namespacePrefix'] = namespacePrefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Migrate comments
         * @summary Migrate comments
         * @param {string} [wait] Wait for the migration to be completed
         * @param {MigrateCommentsRequest} [migrateCommentsRequest] Migration object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateComments: async (wait?: string, migrateCommentsRequest?: MigrateCommentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/migration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateCommentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a comment
         * @summary Update a comment
         * @param {string} commentId Comment ID
         * @param {CommentModelDTO} commentModelDTO Comment object that needs to be updated
         * @param {string} [wait] Wait for the comment to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (commentId: string, commentModelDTO: CommentModelDTO, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateComment', 'commentId', commentId)
            // verify required parameter 'commentModelDTO' is not null or undefined
            assertParamExists('updateComment', 'commentModelDTO', commentModelDTO)
            const localVarPath = `/api/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentModelDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a comment
         * @summary Create a comment
         * @param {CommentModelDTO} commentModelDTO Comment object that needs to be added
         * @param {string} [wait] Wait for the comment to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(commentModelDTO: CommentModelDTO, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(commentModelDTO, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a comment
         * @summary Delete a comment
         * @param {string} commentId Comment ID
         * @param {string} [wait] Wait for the comment to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(commentId: string, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(commentId, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a comment
         * @summary Get a comment
         * @param {string} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComment(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentModelDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all replies of a comment
         * @summary Get all replies of a comment
         * @param {string} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentReplies(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentReplies(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all comments
         * @summary Get all comments
         * @param {string} [namespace] Namespace
         * @param {string} [documentId] Document ID
         * @param {string} [namespacePrefix] Namespace Prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComments(namespace?: string, documentId?: string, namespacePrefix?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(namespace, documentId, namespacePrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Migrate comments
         * @summary Migrate comments
         * @param {string} [wait] Wait for the migration to be completed
         * @param {MigrateCommentsRequest} [migrateCommentsRequest] Migration object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateComments(wait?: string, migrateCommentsRequest?: MigrateCommentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateComments(wait, migrateCommentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a comment
         * @summary Update a comment
         * @param {string} commentId Comment ID
         * @param {CommentModelDTO} commentModelDTO Comment object that needs to be updated
         * @param {string} [wait] Wait for the comment to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(commentId: string, commentModelDTO: CommentModelDTO, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComment(commentId, commentModelDTO, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * Create a comment
         * @summary Create a comment
         * @param {CommentModelDTO} commentModelDTO Comment object that needs to be added
         * @param {string} [wait] Wait for the comment to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(commentModelDTO: CommentModelDTO, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.createComment(commentModelDTO, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a comment
         * @summary Delete a comment
         * @param {string} commentId Comment ID
         * @param {string} [wait] Wait for the comment to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentId: string, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.deleteComment(commentId, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a comment
         * @summary Get a comment
         * @param {string} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment(commentId: string, options?: any): AxiosPromise<CommentModelDB> {
            return localVarFp.getComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all replies of a comment
         * @summary Get all replies of a comment
         * @param {string} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentReplies(commentId: string, options?: any): AxiosPromise<GetComments200Response> {
            return localVarFp.getCommentReplies(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all comments
         * @summary Get all comments
         * @param {string} [namespace] Namespace
         * @param {string} [documentId] Document ID
         * @param {string} [namespacePrefix] Namespace Prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(namespace?: string, documentId?: string, namespacePrefix?: string, options?: any): AxiosPromise<GetComments200Response> {
            return localVarFp.getComments(namespace, documentId, namespacePrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * Migrate comments
         * @summary Migrate comments
         * @param {string} [wait] Wait for the migration to be completed
         * @param {MigrateCommentsRequest} [migrateCommentsRequest] Migration object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateComments(wait?: string, migrateCommentsRequest?: MigrateCommentsRequest, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.migrateComments(wait, migrateCommentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a comment
         * @summary Update a comment
         * @param {string} commentId Comment ID
         * @param {CommentModelDTO} commentModelDTO Comment object that needs to be updated
         * @param {string} [wait] Wait for the comment to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(commentId: string, commentModelDTO: CommentModelDTO, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.updateComment(commentId, commentModelDTO, wait, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * Create a comment
     * @summary Create a comment
     * @param {CommentModelDTO} commentModelDTO Comment object that needs to be added
     * @param {string} [wait] Wait for the comment to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public createComment(commentModelDTO: CommentModelDTO, wait?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).createComment(commentModelDTO, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a comment
     * @summary Delete a comment
     * @param {string} commentId Comment ID
     * @param {string} [wait] Wait for the comment to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public deleteComment(commentId: string, wait?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).deleteComment(commentId, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a comment
     * @summary Get a comment
     * @param {string} commentId Comment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getComment(commentId: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all replies of a comment
     * @summary Get all replies of a comment
     * @param {string} commentId Comment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentReplies(commentId: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getCommentReplies(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all comments
     * @summary Get all comments
     * @param {string} [namespace] Namespace
     * @param {string} [documentId] Document ID
     * @param {string} [namespacePrefix] Namespace Prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getComments(namespace?: string, documentId?: string, namespacePrefix?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getComments(namespace, documentId, namespacePrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Migrate comments
     * @summary Migrate comments
     * @param {string} [wait] Wait for the migration to be completed
     * @param {MigrateCommentsRequest} [migrateCommentsRequest] Migration object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public migrateComments(wait?: string, migrateCommentsRequest?: MigrateCommentsRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).migrateComments(wait, migrateCommentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a comment
     * @summary Update a comment
     * @param {string} commentId Comment ID
     * @param {CommentModelDTO} commentModelDTO Comment object that needs to be updated
     * @param {string} [wait] Wait for the comment to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public updateComment(commentId: string, commentModelDTO: CommentModelDTO, wait?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).updateComment(commentId, commentModelDTO, wait, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<OkResponse> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Health check
     * @summary Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


