import { Box, Typography, makeStyles } from "@material-ui/core";
import OverviewUnseeCount from "./OverviewUnseeCount";
import OverviewHighlight from "./OverviewHighlight";

const useStyles = makeStyles(theme => ({
  rowSubTitle: {
    fontSize: "0.8em",
    color: theme.palette.grey[500],
    paddingTop: "4px"
  },
  unseen: {
    fontWeight: 900,
    "&::before": {
      content: "''",
      display: "inline-block",
      height: 8,
      width: 8,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      marginRight: "12px"
    }
  }
}));

interface OverviewRowTitleProps {
  readonly title: string;
  readonly subTitle?: string;
  readonly searchTerm: string;
  readonly unseenCount?: number;
  readonly seen?: boolean;
}
const OverviewRowTitle = ({ title, subTitle, searchTerm, unseenCount, seen }: OverviewRowTitleProps) => {
  const cls = useStyles();

  /* UNSEEN COUNT */
  const unseenCountEl = unseenCount && <OverviewUnseeCount count={unseenCount} />;

  /* TITLE VALUE WITH SEARCH TERM HIGHLIGHT */
  const titleEl = searchTerm ? <OverviewHighlight text={title} searchTerm={searchTerm} /> : title;

  return (
    <Box minWidth={0}>
      <Box display={"flex"} alignItems={"center"}>
        <Typography data-testid={`overview-row-title`} noWrap className={`${seen === false ? cls.unseen : ""}`}>
          {titleEl}
        </Typography>
        {unseenCountEl}
      </Box>
      {subTitle && (
        <Box>
          <Typography noWrap className={cls.rowSubTitle} data-testid={`overview-row-sub-title`}>
            {subTitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default OverviewRowTitle;
