import { DefaultApi, GetDpia200Response, UpdateDpiaPageDTO } from "../generated/process-service";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import useSWR, { useSWRConfig } from "swr";
import { useCallback, useEffect, useMemo } from "react";
import { useProcessPage } from "app/contexts/process-page-context";
import { isMissingFieldsSWRKey } from "./submissionAndApprovalApi";
import { isPAPermissionsKey } from "../../pages/questionnaires/usePAPermission";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());
export const useDpia = (args: { documentId: string }) => {
  const { mutate: globalMutate } = useSWRConfig();

  const responseDpia = useSWR(args.documentId ? "dpia_" + args.documentId : null, async () => {
    return processClient.getDpia(args.documentId).then(resp => resp.data);
  });

  const emptyProcessData: GetDpia200Response = useMemo(
    () => ({
      processMeta: { id: args.documentId, status: "", relativeOrgUnitIds: [], assignUserIds: [], title: "" },
      processPage: {
        blackOrWhitelist: "",
        profiling: "",
        automaticDecisionMaking: "",
        systematicMonitoring: "",
        specialCategories: "",
        largeQuantities: "",
        setComparison: "",
        vulnerablePersons: "",
        innovativeUse: "",
        preventionOfRights: "",
        dpiaRequiredDecision: "",
        dpiaRequiredUsersChoice: false,
        dpiaRequiredStandardAnswerExplanation: "",
        dpiaRequiredStandardAnswerIndex: 0
      }
    }),
    [args.documentId]
  );

  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  useEffect(() => {
    if (responseDpia.data?.processMeta) {
      setProcessMeta(responseDpia.data.processMeta);
    }
  }, [responseDpia.data?.processMeta, setProcessMeta]);
  const { mutate } = responseDpia;

  const updateDpia = useCallback(
    async (payload: UpdateDpiaPageDTO) => {
      await mutate(
        async () => {
          await onBeforeProcessUpdate(
            async () => {
              await processClient.updateDpia(args.documentId, payload);
              await Promise.all([globalMutate(isMissingFieldsSWRKey), globalMutate(isPAPermissionsKey)]);
            },
            async () => {
              // reset data
              await mutate(responseDpia.data);
            }
          );
          return undefined;
        },
        {
          populateCache: false,
          revalidate: true,
          optimisticData(currentData, displayedData) {
            if (!displayedData) {
              return emptyProcessData;
            }

            return {
              ...displayedData,
              processPage: { ...displayedData.processPage, ...payload }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate, onBeforeProcessUpdate, responseDpia.data, globalMutate]
  );

  const actions = useMemo(
    () => ({
      updateDpia: updateDpia
    }),
    [updateDpia]
  );

  return {
    ...responseDpia,
    actions
  };
};
