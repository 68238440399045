import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Divider, Link, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Collapse from "@material-ui/core/Collapse";
import { useUserDepartments } from "app/contexts/department-context";
import { useResources } from "app/contexts/resource-context";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";

import BasicPagination from "components/BasicPagination/BasicPagination";
import { chunk } from "lodash-es";
import { DataCategoryUsagePerStoragePeriodsDTO } from "../../../api/deletionConceptApi";

import { displayArrayTextInDeletionConcept } from "./displayArrayTextInDeletionConcept";

const primaryTypographyProps = { noWrap: true };
const secondaryTypographyProps = { ...primaryTypographyProps, fontSize: "12px" };

const useStylesProcesses = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.blue[50],
    borderRadius: "10px",
    "& .MuiList-root": {
      padding: 0
    }
  },
  fieldName: {
    color: theme.palette.grey[500],
    fontSize: "11px"
  },
  fieldValue: {
    fontSize: "13px",
    "& a:hover": {
      cursor: "pointer"
    },
    whiteSpace: "pre-wrap"
  }
}));

const DeletionConceptStoragePeriodRowMetaView = ({
  dataStorage
}: {
  readonly dataStorage: DataCategoryUsagePerStoragePeriodsDTO;
}) => {
  const cls = useStylesProcesses();
  const { translateById } = useResources();
  const { t } = useTranslation("processActivitiesInMetaView");
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded(lastExpandedValue => !lastExpandedValue), []);
  const { getDepartmentWithParentName } = useUserDepartments();

  const getStoragePeriodText = useCallback(
    (storagePeriod: DataCategoryUsagePerStoragePeriodsDTO) => {
      if (!storagePeriod?.storagePeriod || !storagePeriod?.storagePeriodUnit || storagePeriod?.storagePeriod === "0") {
        return t("notSet");
      }
      return `${storagePeriod.storagePeriod} ${t(storagePeriod.storagePeriodUnit)}`;
    },
    [t]
  );

  const headerTextOrgUnits = useCallback(
    (orgUnitIds, furtherOrgUnitIds) => {
      if ([...orgUnitIds, ...(furtherOrgUnitIds || [])].length > 1) {
        return t("multipleOrganizations");
      }
      return getDepartmentWithParentName(orgUnitIds[0]);
    },
    [getDepartmentWithParentName, t]
  );

  const orgUnitDisplayData = useCallback(
    (orgUnitIds, furtherOrgUnitIds) => {
      return (
        <>
          {[...orgUnitIds, ...(furtherOrgUnitIds || [])]
            .map(orgUnitId => getDepartmentWithParentName(orgUnitId))
            .map((orgUnitName, index) => (
              <React.Fragment key={`${orgUnitName}-${index}`}>
                {index > 0 && <br />}
                {orgUnitName}
              </React.Fragment>
            ))}
        </>
      );
    },
    [getDepartmentWithParentName]
  );

  const navigateToProcess = useCallback(id => {
    const url = `/processes/${id}/periods`;
    window.open(url, "_blank");
  }, []);

  const displayDeletionType = useCallback(
    (deletionTypeIds: string[]) => {
      const length = deletionTypeIds.length || 0;
      if (length > 1) {
        return t("miscellaneous");
      } else {
        return deletionTypeIds
          .map(el => translateById(RESOURCE_TYPES.DATA_DELETION, el || ""))
          .reduce(displayArrayTextInDeletionConcept, "");
      }
    },
    [t, translateById]
  );

  const fieldsToDisplay = useMemo(
    () => ({
      orgUnitName: orgUnitDisplayData(dataStorage.mainOrgUnitIds, dataStorage.furtherOrgUnitIds),
      purposes: dataStorage.purposes.reduce(displayArrayTextInDeletionConcept, ""),
      deletionTriggerId: dataStorage.deletionTriggerIds
        .map(el => translateById(RESOURCE_TYPES.DELETION_TRIGGER, el || ""))
        .reduce(displayArrayTextInDeletionConcept, ""),
      justification: dataStorage.justificationOfStoragePeriods.reduce(displayArrayTextInDeletionConcept, ""),
      deletionTypeId: displayDeletionType(dataStorage.deletionTypeIds),
      processes: dataStorage.processingActivityIds.map((id, index) => ({
        id,
        name: dataStorage.processingActivityTitles[index] || ""
      })),
      deletionPractice: dataStorage.deletionPractices.reduce(displayArrayTextInDeletionConcept, ""),
      internalRecipients: dataStorage.internalRecipientIds
        .map(el => translateById(RESOURCE_TYPES.INTERNAL_RECIPIENT, el || ""))
        .reduce(displayArrayTextInDeletionConcept, "")
    }),
    [
      dataStorage.deletionPractices,
      dataStorage.deletionTriggerIds,
      dataStorage.deletionTypeIds,
      dataStorage.furtherOrgUnitIds,
      dataStorage.internalRecipientIds,
      dataStorage.justificationOfStoragePeriods,
      dataStorage.mainOrgUnitIds,
      dataStorage.processingActivityIds,
      dataStorage.processingActivityTitles,
      dataStorage.purposes,
      displayDeletionType,
      orgUnitDisplayData,
      translateById
    ]
  );

  const bodyEl = Object.keys(fieldsToDisplay).map(field => (
    <Box key={field}>
      <Box my={2}>
        <Typography className={cls.fieldName}>{t(field)}</Typography>
        <Typography className={cls.fieldValue}>
          {field === "processes" && fieldsToDisplay[field].length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              {fieldsToDisplay[field].map(process => (
                <NavigateToProcess
                  key={process.id}
                  id={process.id}
                  name={process.name}
                  navigateToProcess={navigateToProcess}
                />
              ))}
            </Box>
          )}
          {field === "processes" && fieldsToDisplay[field].length === 0 && t("notSet")}
          {field === "orgUnitName" && (fieldsToDisplay[field] || t("notSet"))}
          {field !== "orgUnitName" &&
            field !== "processes" &&
            (fieldsToDisplay[field as keyof typeof fieldsToDisplay] || t("notSet"))}
        </Typography>
      </Box>
    </Box>
  ));
  const rowEl = (
    <>
      <ListItem button onClick={toggle}>
        <ListItemText
          primary={getStoragePeriodText(dataStorage)}
          secondary={headerTextOrgUnits(dataStorage.mainOrgUnitIds, dataStorage.furtherOrgUnitIds)}
          primaryTypographyProps={primaryTypographyProps}
          secondaryTypographyProps={secondaryTypographyProps}
        />
      </ListItem>
      <Collapse in={expanded}>
        <Box mx={1}>{bodyEl}</Box>
      </Collapse>
    </>
  );

  return (
    <>
      {rowEl}
      <Divider />
    </>
  );
};

const NavigateToProcess = (props: {
  readonly id: string;
  readonly name: string;
  readonly navigateToProcess: (id: string) => void;
}) => {
  const go = useCallback(() => props.navigateToProcess(props.id), [props]);
  return (
    <Link key={props.id} onClick={go}>
      {props.name}
    </Link>
  );
};

const DeletionConceptStoragePeriodMetaView = ({
  dataStorage
}: {
  readonly dataStorage: DataCategoryUsagePerStoragePeriodsDTO[];
}) => {
  const cls = useStylesProcesses();
  const { t } = useTranslation("processActivitiesInMetaView");
  const [page, setPage] = useState(1);
  const elementsPerPage = 5;

  useEffect(() => setPage(1), [dataStorage]);

  const pages = chunk(dataStorage, elementsPerPage);

  const paginationEl = dataStorage.length > 5 && (
    <BasicPagination
      elementsPerPage={elementsPerPage}
      numberElements={dataStorage.length}
      page={page}
      setPage={setPage}
    />
  );

  const listEl = (
    <Box>
      <Divider />
      <List>
        {(pages[page - 1] || []).map(storage => (
          <DeletionConceptStoragePeriodRowMetaView
            key={`${storage.storagePeriod}${storage.storagePeriodUnit}`}
            dataStorage={storage}
          />
        ))}
      </List>
    </Box>
  );

  const noDataStorageEl = useMemo(
    () =>
      !dataStorage?.length && (
        <Box my={2} mx={1} p={1}>
          <Typography>{t("no_dataStorage_dataType")}</Typography>
        </Box>
      ),
    [dataStorage?.length, t]
  );

  return (
    <Box className={cls.card} p={3}>
      <Box mb={3}>
        <Typography align="center" variant="h3">
          {t("storagePeriod")}
        </Typography>
      </Box>
      {listEl}
      {paginationEl}
      {noDataStorageEl}
    </Box>
  );
};

export default DeletionConceptStoragePeriodMetaView;
