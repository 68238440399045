import { tDeletedEntry } from "../handlers/dataTypeTranslatorHandler";
import { DataLocationDTO } from "../api/externalRecipientApi";
import { TFunction } from "i18next";

export function getDataLocationWithExternalRecipient(
  dataLocationId: string,
  dataLocations: Map<string, DataLocationDTO>,
  t: TFunction
) {
  const dataLocation = dataLocations.get(dataLocationId);
  if (!dataLocation) {
    return dataLocationId;
  }

  const dataLocationName = t(`lists_data_locations:${dataLocation.nameKey}`, dataLocation.nameKey);
  if (!dataLocation.externalRecipientId) {
    return dataLocationName;
  }

  if (!dataLocation.externalRecipientName) {
    return `${dataLocationName} [${tDeletedEntry({ t })}]`;
  }
  return `${dataLocationName} [${dataLocation.externalRecipientName}]`;
}
