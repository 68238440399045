export const retryUntil = <T>(retryFn: () => Promise<T>, timeoutMs = 5000, intervalMs = 250): Promise<T> => {
  const deadline = new Date().getTime() + timeoutMs;

  return new Promise((resolve, reject) => {
    const retryInternal = async () => {
      try {
        const result = await retryFn();
        resolve(result);
      } catch (error) {
        if (new Date().getTime() > deadline) {
          reject(error);
          return;
        }
        setTimeout(retryInternal, intervalMs);
      }
    };

    retryInternal();
  });
};
