import Axios from "axios";
import useSWR from "swr";
import { DefaultApi, SubmitProcess400Response } from "../generated/process-service";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const useSubmissionMissingFields = (input: { documentId: string }) => {
  return useSWR<SubmitProcess400Response, unknown, [string, string, string] | null>(
    input.documentId ? ["missing-fields", "submission", input.documentId] : null,
    async ([, , documentId]) => {
      try {
        await processClient.submitProcess(documentId, "true");
        return { missingFields: [], error: "" };
      } catch (err) {
        if (Axios.isAxiosError(err) && err.response?.status === 400) {
          return {
            missingFields: err.response?.data?.missingFields || [],
            error: err.response?.data?.error || ""
          };
        }
        throw err;
      }
    }
  );
};

export const useApprovalMissingFields = (input: { documentId: string }) => {
  return useSWR<SubmitProcess400Response, unknown, [string, string, string] | null>(
    input.documentId ? ["missing-fields", "approval", input.documentId] : null,
    async ([, , documentId]) => {
      try {
        await processClient.approveProcess(documentId, "true");
        return { missingFields: [], error: "" };
      } catch (err) {
        if (Axios.isAxiosError(err) && err.response?.status === 400) {
          return {
            missingFields: err.response?.data?.missingFields || [],
            error: err.response?.data?.error || ""
          };
        }
        throw err;
      }
    }
  );
};

export const isMissingFieldsSWRKey = (key: unknown) => {
  return Array.isArray(key) && key[0] === "missing-fields";
};
