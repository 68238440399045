import { useCallback, useMemo } from "react";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

export const useUserPermissions = () => {
  const { auth } = useAuthentication();

  const readOnlyUser = useMemo(
    () => auth?.permissions && auth.permissions.length > 0 && auth.permissions.every(it => it.includes("_read_")),
    [auth]
  );

  const hasPermission = useCallback(
    (permission: string) => auth?.permissions && auth.permissions.includes(permission),
    [auth]
  );

  return {
    readOnlyUser,
    hasPermission
  };
};
