import { QuestionProps } from "../../Question";
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import TextEditor from "../../../../app/pages/questionnaires/utils/TextEditor";
import { getTextFromTextEditorJsonString } from "../../../../app/pages/questionnaires/utils/textEditorConverter";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";

export const EditableText = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  textEditorToolbarOptions,
  onFocus,
  onBlur,
  onChange,
  onActivate
}: QuestionProps) => {
  const [_value, _setValue] = useState<string | undefined>("");
  useEffect(() => {
    return _setValue((Array.isArray(value) ? value[0] : value) as string);
  }, [value]);
  const [isEditing, setIsEditing] = useState(false);

  const onChangeCallback = useCallback(
    (val: string) => {
      if (!isEditing) {
        setIsEditing(true);
      }
      _setValue(val);
    },
    [isEditing]
  );
  const onFocusCallback = useCallback(
    event => {
      onFocus?.(event);
    },
    [onFocus]
  );

  const onTextEditorBlur = useCallback(
    event => {
      setIsEditing(false);
      onChange?.(_value);
      onBlur?.(event);
    },
    [_value, onBlur, onChange]
  );

  const onEditIconClick = useCallback(
    event => {
      setIsEditing(true);
      onActivate?.(event);
    },
    [onActivate]
  );

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  const simpleValueText = useMemo(() => getTextFromTextEditorJsonString(_value || ""), [_value]);
  const isValueSingleWord = useMemo(() => {
    const parsedText = _value && _value.startsWith("{") && JSON.parse(_value);
    const text = Array.isArray(parsedText?.blocks) ? parsedText?.blocks[0]?.text?.trim() : "";
    return text && text.split(" ").length === 1;
  }, [_value]);

  if (isEditing || simpleValueText === "") {
    return (
      <TextEditor
        disabled={disabled}
        title={questionName}
        inputValue={_value}
        error={error}
        helperText={helperText}
        onChange={onChangeCallback}
        onFocus={onFocusCallback}
        onBlur={onTextEditorBlur}
        toolbarOptions={textEditorToolbarOptions}
      />
    );
  }

  return (
    <Box
      width="100%"
      position="relative"
      display="inline-block"
      borderRadius={isHovered ? "8px" : 0}
      padding="0.5rem"
      paddingRight={isValueSingleWord ? "3rem" : "unset"}
      bgcolor={isHovered ? "rgba(0, 0, 0, 0.04)" : "transparent"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box width="96.5%">
        <span>{simpleValueText}</span>
      </Box>
      {isHovered && <EditIcon data-testid={"edit-icon"} style={editIconStyle} onClick={onEditIconClick} />}
    </Box>
  );
};

const editIconStyle: CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  color: "#707477" + "60",
  width: "24px",
  height: "24px"
};
