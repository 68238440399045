import { useInView } from "react-intersection-observer";
import React, { CSSProperties } from "react";

const OverviewInView = ({ children }: { children: React.ReactNode }) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  return (
    <div style={minHeight} ref={ref}>
      {inView ? children : null}
    </div>
  );
};

const minHeight: CSSProperties = {
  minHeight: 58
};

export default OverviewInView;
