import React from "react";
import { useTranslation } from "react-i18next";
import { DateField, DateFieldProps } from "components/DateField";

export type DueDateProps = Pick<
  DateFieldProps,
  | "className"
  | "onFocus"
  | "onBlur"
  | "date"
  | "onDateChange"
  | "locale"
  | "label"
  | "color"
  | "disabled"
  | "withTime"
  | "margin"
>;

export const DueDate = ({
  className,
  onFocus,
  onBlur,
  date,
  onDateChange,
  locale,
  label,
  color,
  disabled,
  withTime,
  margin
}: DueDateProps) => {
  const { t } = useTranslation();
  return (
    <DateField
      className={className}
      disablePast={!disabled}
      locale={locale}
      onFocus={onFocus}
      date={date}
      onDateChange={onDateChange}
      onBlur={onBlur}
      label={label || t("dueDate:fieldName")}
      minDateMessage={t("dueDate:pastDate")}
      disabled={disabled}
      color={color}
      withTime={withTime}
      margin={margin}
    />
  );
};
