import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import DocMetaView from "components/DocMetaView/DocMetaView";
import AccountSettingsReauthenticate from "../AccountSettingsReauthenticate";
import DocViewWithBackButton from "components/DocView/DocViewWithBackButton";
import AccountSettingsMFASetup from "app/pages/account-settings/mfa-flow/AccountSettingsMFASetup";
import AccountSettingsMFARemoval from "app/pages/account-settings/mfa-flow/AccountSettingsMFARemoval";
import { CircularProgress } from "@material-ui/core";
import { useNavigateWithQueryParams } from "app/router/router-custom-hooks";
import MetaView from "components/MetaView/MetaView";
import useSWR from "swr";
import { getEnforcementInfoApi } from "../../../api/user/userMeApi";

export const AccountSettingsMFAFlow = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { t } = useTranslation("accountSettingsMFAFlow");
  const { user } = useAuthentication();

  const steps = {
    PASSWORD_CONFIRM: "password_confirm",
    MFA_SETUP: "mfa_setup",
    MFA_REMOVAL: "mfa_removal",
    FINAL_PAGE: "final"
  };
  const [currentStep, setCurrentStep] = useState(steps.PASSWORD_CONFIRM);

  const navigateToSecurityTabCallback = useCallback(
    () => navigateWithQueryParams("/account-settings", { tab: "security" }),
    [navigateWithQueryParams]
  );

  const { mutate } = useSWR("/me/enforcements", () => getEnforcementInfoApi());
  useEffect(() => {
    if (currentStep === steps.FINAL_PAGE) {
      mutate();
      navigateToSecurityTabCallback();
    }
  }, [currentStep, mutate, navigateToSecurityTabCallback, steps.FINAL_PAGE]);

  const reauthenticateCallback = useCallback(async () => {
    if (user?.mfaActivated) {
      setCurrentStep(steps.MFA_REMOVAL);
    } else {
      setCurrentStep(steps.MFA_SETUP);
    }
  }, [setCurrentStep, user?.mfaActivated, steps.MFA_REMOVAL, steps.MFA_SETUP]);

  const finalStepCallback = useCallback(async () => {
    setCurrentStep(steps.FINAL_PAGE);
  }, [setCurrentStep, steps.FINAL_PAGE]);

  return (
    <DocMetaView
      docViewContent={
        <DocViewWithBackButton header={t("title")} onBack={navigateToSecurityTabCallback}>
          {currentStep === steps.PASSWORD_CONFIRM && (
            <AccountSettingsReauthenticate
              onSuccess={reauthenticateCallback}
              title={t("confirm_password_desc")}
              buttonText={t("confirm_password_action")}
            />
          )}
          {currentStep === steps.MFA_SETUP && <AccountSettingsMFASetup onSuccess={finalStepCallback} />}
          {currentStep === steps.MFA_REMOVAL && <AccountSettingsMFARemoval onSuccess={finalStepCallback} />}
          {currentStep === steps.FINAL_PAGE && (
            <div>
              <CircularProgress />
            </div>
          )}
        </DocViewWithBackButton>
      }
      metaViewContent={<MetaView translationKey="accountSettingsMFAFlow" />}
    />
  );
};
