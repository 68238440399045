import React, { CSSProperties, useCallback } from "react";
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import Question from "components/Question/Question";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import { getTextFromTextEditorJsonString } from "../utils/textEditorConverter";
import { useTranslation } from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import { isEmpty } from "lodash-es";
import { useTheme } from "@mui/material";
import colors from "theme/palette/colors";

interface ResultSectionProps {
  dpiaRequiredDecision: string | undefined;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  isEditing: boolean;
  isReasonHovered: boolean;
  isReasonSingleWord: boolean;
  onDpiaNecessaryNoCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDpiaNecessaryYesCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onEditReasonCallback: () => void;
  overwrittenReasonEl: React.ReactNode;
  overwrittenResultEl: React.ReactNode;
  readonly: boolean;
  reasonText: string;
  setReasonText: (text: string) => void;
  updateReasonText: (text: string) => void;
}

const ResultSection: React.FC<ResultSectionProps> = ({
  dpiaRequiredDecision,
  handleMouseEnter,
  handleMouseLeave,
  isEditing,
  isReasonHovered,
  isReasonSingleWord,
  onDpiaNecessaryNoCallback,
  onDpiaNecessaryYesCallback,
  onEditReasonCallback,
  overwrittenReasonEl,
  overwrittenResultEl,
  readonly,
  reasonText,
  setReasonText,
  updateReasonText
}) => {
  const theme = useTheme();
  const { t } = useTranslation("pa_assessment");

  const resultEl = (
    <Box mt={-1}>
      <Question questionId={"result"} questionName={t("result")}>
        <RadioGroup row name="isDataSubjectVerified" value={dpiaRequiredDecision}>
          <Box width="100%">
            <Box
              onClick={onDpiaNecessaryYesCallback}
              border={1}
              borderColor={dpiaRequiredDecision === "yes" ? theme.palette.primary.main : "#ABABAB"}
              borderRadius={4}
              p={1}
              mb={1}
              mt={0.5}
              width="50%"
              bgcolor={dpiaRequiredDecision === "yes" ? colors.blue.blue50 : "transparent"}
            >
              <FormControlLabel
                id={"dpia_required"}
                label={t("dpia_required")}
                value={"yes"}
                control={<Radio style={radioStyle} color={"primary"} />}
              />
            </Box>
          </Box>

          <Box width="100%">
            <Box
              border={1}
              borderColor={dpiaRequiredDecision === "no" ? theme.palette.primary.main : "#ABABAB"}
              borderRadius={4}
              p={1}
              width="50%"
              bgcolor={dpiaRequiredDecision === "no" ? colors.blue.blue50 : "transparent"}
              onClick={onDpiaNecessaryNoCallback}
            >
              <FormControlLabel
                id={"dpia_not_required"}
                value={"no"}
                label={t("dpia_not_required")}
                control={<Radio style={radioStyle} color={"primary"} />}
              />
            </Box>
          </Box>
        </RadioGroup>
      </Question>
    </Box>
  );

  const reasonTextEl = (
    <Box
      width="100%"
      position="relative"
      display="inline-block"
      borderRadius={isReasonHovered ? "8px" : 0}
      padding="0.5rem"
      paddingRight={isReasonSingleWord ? "3rem" : "unset"}
      bgcolor={isReasonHovered ? "rgba(0, 0, 0, 0.04)" : "transparent"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box width="96.5%">
        <span>{getTextFromTextEditorJsonString(reasonText)}</span>
      </Box>
      {isReasonHovered && <EditIcon data-testid={"edit-icon"} style={editIconStyle} onClick={onEditReasonCallback} />}
    </Box>
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      updateReasonText(event.currentTarget.value);
    },
    [updateReasonText]
  );

  return (
    <>
      <Typography variant="subtitle2">{t("dpia_result")}</Typography>
      {resultEl}
      {overwrittenResultEl}

      {(!isEmpty(dpiaRequiredDecision) || reasonText !== "") && (
        <Box mt={3} mb={5}>
          <Typography variant="subtitle2">{t("dpia_reason")}</Typography>
          <Box mt={-1}>
            <Question questionId={"reason"} questionName={t("reason")} translatable={reasonText}>
              {isEditing ? (
                <TextEditor
                  inputValue={reasonText}
                  onBlur={handleBlur}
                  onChange={setReasonText}
                  disabled={readonly}
                  testId={"reason-text-editor"}
                />
              ) : (
                reasonTextEl
              )}
            </Question>
          </Box>
          {overwrittenReasonEl}
        </Box>
      )}
    </>
  );
};

const editIconStyle: CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  color: "#707477" + "60",
  width: "24px",
  height: "24px"
};

const radioStyle: CSSProperties = { paddingLeft: "18px" };

export default ResultSection;
