export interface SheetData<T> {
  readonly rows: T[];
  readonly tabName: string;
  readonly columnWidths: number[];
}

export const jsonToXlsxHandler = async <T>(fileName: string, sheetData: SheetData<T>[], headerRow?: string[]) => {
  const { utils, writeFile } = await import("xlsx");
  const wb = utils.book_new();
  sheetData.forEach((sheet: SheetData<T>, index) => {
    const ws = utils.json_to_sheet<T>(sheet.rows, { header: headerRow });
    utils.book_append_sheet(wb, ws, sheet.tabName);
    // set column width if width specifications are passed
    ws["!cols"] = sheet.columnWidths.map((columnWidth: number) => {
      return { wch: columnWidth };
    });
  });

  writeFile(wb, fileName + ".xlsx");
};
