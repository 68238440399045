import React, { useCallback, useEffect, useMemo } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "app/contexts/meta-view-context";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../../contexts/overview-context";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import AITechDocsOverviewRow from "./AITechDocsOverviewRow";
import { exportAITechDocsExcel } from "../../../export/createdExcelExportData";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { CollectionParams } from "../../../../hook/useOverviewData";

const AITechDocsOverview = () => {
  const { t } = useTranslation();
  const { setInfo, setMeta } = useMetaView();

  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const dispatch = useOverviewDispatch();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("aitechdoc_overview:enteringInfoCardTitle"),
        text: t("aitechdoc_overview:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const toolbarActions = useMemo(
    () => [
      { action: "sort" },
      { action: "filter" },
      { action: "add", types: [OVERVIEW_ADD_TYPE.SINGLE, OVERVIEW_ADD_TYPE.MULTIPLE] },
      { action: "mark-all-as-read" },
      { action: "export-xlsx" }
    ],
    []
  );

  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.creatorUID} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("aitechdoc_overview:" + item.status.toLowerCase())
      });
    },
    [setMeta, t]
  );

  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }, { action: OVERVIEW_ADD_TYPE.MULTIPLE }], []);

  const rowClick = useCallback(
    async item => {
      if (item.seen === false) {
        await addToSeenItemsOfUserHook(COLLECTIONS.AI_TECH_DOCS, item.id);
        dispatch({
          type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
          collection: COLLECTIONS.AI_TECH_DOCS,
          reloadOverview: Date.now()
        });
      }
    },
    [addToSeenItemsOfUserHook, dispatch]
  );

  const selectionActions = useMemo(() => [{ action: "export-xlsx" }, { action: "remove" }], []);

  const { auth } = useAuthentication();
  const exportToXLSX = useCallback(
    async ids => {
      if (!auth?.tenantId) {
        return;
      }
      return exportAITechDocsExcel(auth?.tenantId, t, ids);
    },
    [t, auth?.tenantId]
  );

  const collectionParams = useMemo<CollectionParams>(
    () => ({
      exportToXLSX
    }),
    [exportToXLSX]
  );

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"aitechdoc_overview"} />}>
      <Overview
        collectionParams={collectionParams}
        addActions={addActions}
        checkable={true}
        collection={COLLECTIONS.AI_TECH_DOCS}
        header={t("aitechdoc_overview:header")}
        onAddClose={showEnterInfo}
        onRowLeave={showEnterInfo}
        onRowOver={onRowOver}
        onRowClick={rowClick}
        CustomRowComponent={AITechDocsOverviewRow}
        toolbarActions={toolbarActions}
        selectionActions={selectionActions}
        toolbarMode="tabs"
        showSelectAll={true}
      />
    </DocMetaView>
  );
};

export default AITechDocsOverview;
