import { useUserAndTenantData } from "../app/handlers/userAndTenant/user-tenant-context";
import { useEffect, useState } from "react";
import { languageToggles } from "../app/handlers/languageHandler";

const languageStorageNamespace = "caralegal::languages::";

export const useAvailableLanguages = () => {
  const { tenantData } = useUserAndTenantData();
  const [isLoadedFromTenantData, setIsLoadedFromTenantData] = useState<boolean>(false);
  const [availableLanguages, setAvailableLanguages] = useState<string[]>(["en", "de"]);

  useEffect(() => {
    if (tenantData === null) {
      return;
    }

    const availableLanguages: string[] = Object.entries(languageToggles).reduce<string[]>((acc, [key, value]) => {
      const languageAvailable = tenantData.features.includes(value);
      if (!languageAvailable) {
        return acc;
      }

      return [...acc, key];
    }, []);

    window.localStorage.setItem(`${languageStorageNamespace}availableLanguages`, JSON.stringify(availableLanguages));
    setAvailableLanguages(availableLanguages);
    setIsLoadedFromTenantData(true);
  }, [tenantData]);

  useEffect(() => {
    if (tenantData !== null) {
      return;
    }

    const storedAvailableLanguage = window.localStorage.getItem(`${languageStorageNamespace}availableLanguages`);
    if (!storedAvailableLanguage) {
      return;
    }

    try {
      const parsedAvailableLanguages = JSON.parse(storedAvailableLanguage);
      if (!Array.isArray(parsedAvailableLanguages)) {
        return;
      }

      setAvailableLanguages(parsedAvailableLanguages);
      setIsLoadedFromTenantData(false);
    } catch (e) {
      console.error(e);
      // not important to handle this, just fallback
      return;
    }
  }, [tenantData]);

  return { isLoadedFromTenantData, availableLanguages };
};
