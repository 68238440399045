import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

export default function StaticDocViewHeader({ text }: { readonly text: string }) {
  return (
    <Box display="flex" justifyContent="center" mb={5}>
      <Typography variant="h1" component="div">
        {text}
      </Typography>
    </Box>
  );
}
