import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import { useTranslation } from "react-i18next";
import MetaView, { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";
import { useMetaView } from "app/contexts/meta-view-context";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { AuditDetailDTO } from "app/api/auditApi";
import { COLLECTIONS } from "app/collections";
import DocumentNotFoundPage from "app/pages/shared/DocumentNotFound/DocumentNotFound";
import CompletedIcon from "../../../../../../assets/images/assessment/statuses/completed.svg";
import DraftIcon from "../../../../../../assets/images/assessment/statuses/draft.svg";
import EditIcon from "../../../../../../assets/images/assessment/statuses/in-progress.svg";
import AssessmentIcon from "../../../../../../assets/images/assessment/assessment.svg";
import SelfAssessmentIcon from "../../../../../../assets/images/assessment/self-assessment.svg";
import { ANSWERSET_STATUS, AUDIT_STATUS } from "app/pages/audits/audit/AuditTypes";
import { AnswersetPageButtons, AnswersetPageStepper } from "./AnswersetPagination";
import AnswersetGeneral from "./AnswersetGeneral";
import AnswersetConfirmation from "./AnswersetConfirmation";
import AnswersetQuestionnaire from "./AnswersetQuestionnaire";
import { getSingleAnswerSetApi, patchAnswerSetApi } from "app/api/assessmentApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useSnackbar } from "notistack";

export const AssessmentSubmitButton = ({
  readonly,
  onApproved,
  isCheckConfirmation
}: {
  readonly readonly?: boolean;
  readonly onApproved?: () => void;
  readonly isCheckConfirmation?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { auditid, id } = useParams();
  const onSubmitCallback = useCallback(async () => {
    try {
      const payload = {
        status: "completed"
      };
      await patchAnswerSetApi({ auditId: auditid || "", id: id || "", payload });
      enqueueSnackbar(t("assessment_answerset:thanks_for_submitting_the_form"), { variant: "success" });
      navigate("/audits/answersets");
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
      console.error("Failed to submit answerset", error);
    }
  }, [auditid, id, navigate, t, enqueueSnackbar]);

  return (
    <Button variant="contained" color="primary" onClick={onSubmitCallback} disabled={!isCheckConfirmation || readonly}>
      {t("common:submit")}
    </Button>
  );
};

export default function AnswersetPage() {
  const { auditid, id, page } = useParams();
  const { auth } = useAuthentication();
  const { t } = useTranslation("assessment_answerset");
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [docName, setDocName] = useState("");
  const [docStatus, setDocStatus] = useState("");
  const [participant, setParticipant] = useState("");
  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckConfirmation, setIsCheckConfirmation] = useState(false);
  const { setInfo } = useMetaView();
  const metaViewId = `${auditid}/answerset/${id}`;
  const loadAnswerSet = useCallback(async () => {
    const data = await getSingleAnswerSetApi({ auditId: auditid || "", id: id || "" });
    if (!data || data.audit.status === AUDIT_STATUS.DRAFT) {
      setDocumentNotFound(true);
    } else {
      setAuditData(data.audit);
      setDocName(data.title);
      setDocStatus(data.status);
      setParticipant(data.assigneeUID || "");
    }
    setIsLoading(false);
  }, [id, auditid]);

  useEffect(() => {
    loadAnswerSet();
  }, [loadAnswerSet]);

  const defaultInfo = useMemo(
    () =>
      id
        ? {
            title: t("roles_overview:roleDetailsInfoTitle"),
            text: t("roles_overview:roleDetailsInfoText")
          }
        : {
            title: t("roles_overview:addNewRoleInfoTitle"),
            text: t("roles_overview:addNewRoleInfoText")
          },
    [id, t]
  );

  useEffect(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);

  const onCheckConfirmation = useCallback(() => {
    setIsCheckConfirmation(!isCheckConfirmation);
  }, [isCheckConfirmation]);

  const onAnswer = useCallback(() => {
    setDocStatus(ANSWERSET_STATUS.INPROGRESS);
  }, []);

  const metaViewTabIds: META_VIEW_TABS_TYPES[] = useMemo(
    () =>
      page === "questionnaire"
        ? [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS]
        : [META_VIEW_TABS.ASSISTANT],
    [page]
  );

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.ASSESSMENT_RESPONSE} />;
  }

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          collection={COLLECTIONS.ASSESSMENT_RESPONSE}
          docId={metaViewId}
          tabs={metaViewTabIds}
          translationKey={"assessment_answerset"}
          docName={docName}
          pageId={page}
        />
      }
      loading={isLoading}
    >
      <AnswerSetDocView key={page} docName={docName} docStatus={docStatus} isParticipant={auth?.uid === participant}>
        <Box>
          <Box mt={4}>
            {page === "general" && <AnswersetGeneral id={id || ""} auditid={auditid || ""} />}
            {page === "questionnaire" && (
              <AnswersetQuestionnaire id={id || ""} auditid={auditid || ""} onAnswer={onAnswer} />
            )}
            {page === "confirmation" && (
              <AnswersetConfirmation readOnly={docStatus === "completed"} onCheckConfirmation={onCheckConfirmation} />
            )}
          </Box>
          <Box px={6}>
            <AnswersetPageButtons
              rightArea={
                page === "confirmation" && (
                  <Box display="flex" alignItems="center" gridGap={8} justifyContent="space-between">
                    <AssessmentSubmitButton
                      readonly={docStatus === "completed"}
                      isCheckConfirmation={isCheckConfirmation}
                    />
                  </Box>
                )
              }
            />
          </Box>
        </Box>
      </AnswerSetDocView>
    </DocMetaView>
  );
}

export const AnswerSetDocView = ({
  children,
  docName,
  docStatus,
  isParticipant
}: {
  children: React.ReactNode;
  docName: string;
  docStatus: string;
  isParticipant: boolean;
}) => {
  const { t } = useTranslation("assessment_answerset");
  const docProperties = useMemo(() => {
    return [
      { icon: <AssessmentIcon />, label: t("assessment_answerset:title") },
      isParticipant
        ? { icon: <SelfAssessmentIcon />, label: t("add_participant:participant") }
        : { icon: <SelfAssessmentIcon />, label: t("add_participant:internal_contributor_user") }
    ];
  }, [t, isParticipant]);
  const statusIconMapper = useMemo(
    () => ({
      [ANSWERSET_STATUS.COMPLETED]: <CompletedIcon />,
      [ANSWERSET_STATUS.INPROGRESS]: <EditIcon />,
      [ANSWERSET_STATUS.PENDING]: <DraftIcon />
    }),
    []
  );
  const statuses = useMemo(() => {
    return Object.values(ANSWERSET_STATUS).map(status => ({
      id: status,
      label: t(`audit_status:${status.toLowerCase()}`),
      icon: statusIconMapper[status]
    }));
  }, [statusIconMapper, t]);
  return (
    <DocView
      docProperties={docProperties}
      header={docName}
      pagination={<AnswersetPageStepper />}
      statusId={docStatus}
      statuses={statuses}
      disabled={true}
    >
      {children}
    </DocView>
  );
};
