import { isString } from "lodash-es";
import { ContentState, convertFromRaw, convertToRaw, RawDraftContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import DOMPurify from "dompurify";

export const parseContentState = (state: any): any => {
  if (state === null) {
    return convertToRaw(ContentState.createFromText(""));
  }

  const isStateAString = isString(state);
  if (isStateAString && state.startsWith("{") && state.endsWith("}")) {
    return JSON.parse(state);
  }
  if (isStateAString) {
    return convertToRaw(ContentState.createFromText(state));
  }

  return state;
};
export const getTextFromTextEditorJsonString = (textEditorString: string): string => {
  const parsedEditorState = parseContentState(textEditorString);
  if (!Array.isArray(parsedEditorState?.blocks)) {
    return "";
  }
  return parsedEditorState.blocks.map((element: any) => element.text).join("\n\n");
};

export function checkIfTextEditorContainsText(contentState: unknown): boolean {
  if (!contentState) {
    return false;
  }

  if (typeof contentState !== "string") {
    return false;
  }

  if (contentState.startsWith("{") && contentState.endsWith("}")) {
    const text = JSON.parse(contentState).blocks?.[0]?.text;
    if (!text) {
      return false;
    }
    return text.trim()?.length > 0;
  }

  return contentState.trim().length > 0;
}

export function convertRawToHTMLContentWithStyling(rawContent: string) {
  const newRawContent = parseContentState(rawContent);
  const convertedState = convertContentStateFromRawObject(newRawContent);
  const htmlContent: string = stateToHTML(convertedState);

  // Sanitize the HTML to avoid XSS attacks
  const sanitizedContent: string = DOMPurify.sanitize(htmlContent);
  return sanitizedContent;
}

export function convertContentStateFromRawObject(contentState: object, textFallback?: string): ContentState {
  try {
    return convertFromRaw(contentState as RawDraftContentState);
  } catch (e: unknown) {
    if (textFallback) {
      return ContentState.createFromText(textFallback);
    }
    throw e;
  }
}
