import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useMetaView } from "app/contexts/meta-view-context";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import RadioEditorCombo from "../utils/RadioEditorCombo";
import { useProcessPage } from "../../../contexts/process-page-context";
import { useChange } from "../../../api/process/changePageApi";
import { UpdateChangePageDTO } from "../../../api/generated/process-service";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";

const ProcessChangePage = ({ readonly }: { readonly readonly?: boolean }) => {
  const { id } = useParams();
  const { t } = useTranslation("questionnaire_four_four_page");
  const { setInfoId } = useMetaView();
  const { data, actions, mutate } = useChange({ documentId: id || "" });

  useEnteringInfoCard({
    pathName: `/processes/${id}/change`,
    infoId: "infocard.dpia.page10"
  });

  const { onBeforeProcessUpdate, setProcessMeta, resetSignal } = useProcessPage();
  useEffect(() => {
    if (data?.processMeta) {
      setProcessMeta(data.processMeta);
    }
  }, [data?.processMeta, setProcessMeta]);

  useEffect(() => {
    if (resetSignal === 0) {
      return;
    }

    mutate();
  }, [resetSignal, mutate]);

  const triggerUpdate = useCallback(
    async (payload: UpdateChangePageDTO) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateChange(id || "", payload);
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );
  const mergeUpdates = useCallback((acc, curr) => {
    const fieldNameKey = curr.fieldName as keyof UpdateChangePageDTO;
    const existingField = acc[fieldNameKey];

    return {
      ...acc,
      [fieldNameKey]: {
        isDataUpToDate: curr.radioSelect === undefined ? existingField?.isDataUpToDate : curr.radioSelect,
        description: curr.text === undefined ? existingField?.description : curr.text
      }
    };
  }, []);

  const { queueUpdates } = useUpdateQueues({
    triggerUpdate,
    mergeUpdates,
    resetSignal
  });

  const onFocus = useCallback(
    name => {
      setInfoId(`infocard.dpia.page10.${name}`);
    },
    [setInfoId]
  );

  const radioOptions = useMemo(() => [t("yes"), t("no")], [t]);
  const processElements = !data?.processPage ? (
    <Box textAlign={"center"} mt={8}>
      <CircularProgress />
    </Box>
  ) : (
    <React.Fragment key={`body-${resetSignal}`}>
      {Object.entries(data?.processPage).map(([key, value], index) => {
        return (
          <RadioEditorCombo
            questionId={`testAndChange ${index}`}
            key={key}
            fieldName={key}
            radioSelect={value.isDataUpToDate}
            text={value.description}
            onSave={queueUpdates}
            tRadioOptions={radioOptions}
            radioTitle={t(key)}
            onFocus={onFocus}
            disabled={readonly}
          />
        );
      })}
    </React.Fragment>
  );

  return (
    <>
      <QuestionnaireSubHeader text={t("title")} />
      <Box pt={5} mb={5}>
        {processElements}
      </Box>
    </>
  );
};

export default ProcessChangePage;
