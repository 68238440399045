import { usePathName } from "../../../router/router-custom-hooks";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OverviewPageProps } from "../../../../components/Overview/controls/OverviewPagesMenu";
import { useNavigate } from "react-router-dom";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { paReadPermissions } from "../../../handlers/permissionHandler";
import { OVERVIEW_ADD_TYPE } from "../../../../components/Overview/constants/OverviewConstants";

export const usePAPages = () => {
  const { auth } = useAuthentication();
  const { t } = useTranslation("pagination");
  const isProcessorPAEnabled = useIsFeaturePresent(FEATURES.PROCESSOR_PA_FEATURE);
  const userHasPaReadPermission = auth?.permissions.some(permission => paReadPermissions.includes(permission));
  const shouldShowPages = useMemo(
    () => isProcessorPAEnabled && auth?.permissions.some(it => it === "processor_pa_read_org"),
    [isProcessorPAEnabled, auth?.permissions]
  );

  const navigate = useNavigate();
  const pathName = usePathName();
  const pages = useMemo<OverviewPageProps[]>(() => {
    return [
      ...(userHasPaReadPermission
        ? [{ title: t("processes"), route: "/processes", current: pathName.includes("processes") }]
        : []),
      { title: t("processor-pas"), route: "/processor-pas", current: pathName.includes("processor-pas") }
    ];
  }, [pathName, t, userHasPaReadPermission]);

  const onPageChange = useCallback(
    (page: OverviewPageProps) => {
      navigate(page.route);
    },
    [navigate]
  );

  return { pages, onPageChange, shouldShowPages };
};
