import { RESOURCE_TYPE, RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { ResourceMetaviewProps } from "../ResourceTypeOverviewDeps";
import { LegalBasisInResourceMetaView } from "./LegalBasisInResourceMetaView";
import MetaView from "components/MetaView/MetaView";
import AssetsInResourceMetaView from "./AssetsInResourceMetaView";
import DataBreachesInResourceMetaView from "./DataBreachesInResourceMetaView";
import ProcessesInResourceMetaView from "./ProcessesInResourceMetaView";
import RequestsInResourceMetaView from "./RequestsInResourceMetaView";
import ExternalRecipientsInResourceMetaView from "./ExternalRecipientsInResourceMetaView";

interface ResourceMetaViewProps {
  readonly resourceId: string | null;
  readonly resourceType: RESOURCE_TYPE;
}

const MetaViewByResourceType: {
  [key in RESOURCE_TYPE]?: React.FC<ResourceMetaviewProps>;
} = {
  [RESOURCE_TYPES.LEGAL_BASIS]: LegalBasisInResourceMetaView,
  [RESOURCE_TYPES.ASSET_TYPE]: AssetsInResourceMetaView,
  /* DATA BREACH */
  [RESOURCE_TYPES.DATABREACH_CAUSE]: DataBreachesInResourceMetaView,
  [RESOURCE_TYPES.DATABREACH_IMPACT]: DataBreachesInResourceMetaView,
  [RESOURCE_TYPES.DATABREACH_MEASURE]: DataBreachesInResourceMetaView,
  [RESOURCE_TYPES.DATABREACH_DETECTION]: DataBreachesInResourceMetaView,
  /* PROCESS */
  [RESOURCE_TYPES.DATA_DELETION]: ProcessesInResourceMetaView,
  [RESOURCE_TYPES.INTERNAL_RECIPIENT]: ProcessesInResourceMetaView,
  [RESOURCE_TYPES.LEGAL_RETENTION_PERIOD]: ProcessesInResourceMetaView,
  [RESOURCE_TYPES.DATA_CLASSIFICATION]: ProcessesInResourceMetaView,
  [RESOURCE_TYPES.AI_ROLE]: ProcessesInResourceMetaView,
  [RESOURCE_TYPES.PROCESSING_CATEGORY]: ProcessesInResourceMetaView,
  /* REQUEST */
  [RESOURCE_TYPES.DSR_REQUEST_TYPE]: RequestsInResourceMetaView,
  /* EXTERNAL RECIPIENT */
  [RESOURCE_TYPES.SAFEGUARD]: ExternalRecipientsInResourceMetaView,
  [RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE]: ExternalRecipientsInResourceMetaView,
  [RESOURCE_TYPES.DEROGATION]: ExternalRecipientsInResourceMetaView,
  [RESOURCE_TYPES.ENCRYPTION]: ExternalRecipientsInResourceMetaView,
  [RESOURCE_TYPES.PSEUDONYMIZATION]: ExternalRecipientsInResourceMetaView
};

const ResourceMetaViewMapper = ({ resourceType, resourceId }: ResourceMetaViewProps) => {
  const resourceOverviewTranslationContext = `resources_${resourceType}_overview`;
  const MetaViewByResourceTypeComponent = MetaViewByResourceType[resourceType];

  return MetaViewByResourceTypeComponent && resourceId ? (
    <MetaViewByResourceTypeComponent resourceId={resourceId} resourceType={resourceType} />
  ) : (
    <MetaView translationKey={resourceOverviewTranslationContext} />
  );
};

export default ResourceMetaViewMapper;
