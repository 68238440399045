import { useAutomaticallyToBeAddedErs } from "../../../api/assetApi";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import Question from "../../../../components/Question/Question";
import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextBody2 from "../../../../components/TextBody2/TextBody2";

export const AssetAutomaticallyAssetExternalRecipient = ({
  assetId,
  dataLocationIds
}: {
  readonly assetId: string;
  readonly dataLocationIds: string[];
}) => {
  const { data: suggestion } = useAutomaticallyToBeAddedErs({ documentId: assetId || "", dlIds: dataLocationIds });
  const { t } = useTranslation("asset_details");

  return (
    <Box>
      <TextBody2 text={t("automaticallyToBeAddedErs")} />
      <Question
        qType={QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS}
        value={suggestion?.additionalErIdsToAdd || []}
        disabled={true}
      />
    </Box>
  );
};
