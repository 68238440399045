import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import palette from "theme/palette/palette";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import colors from "theme/palette/colors";
import { Stack, Typography, Chip, Tooltip } from "@mui/material";
import { MultiPickerDialog } from "../Measures/MultiPickerDialog";

export interface SuggestionChipsProps {
  readonly modalTitle: string;
  readonly allowAdd?: boolean;
  readonly optionIds: string[];
  readonly onSuggestionsAdded: (ids: string[]) => void;
  readonly getOptionLabel: (id: string) => string;
}

const SuggestionChips = ({
  modalTitle,
  allowAdd,
  optionIds,
  onSuggestionsAdded,
  getOptionLabel
}: SuggestionChipsProps) => {
  const { t } = useTranslation("questionnaires");
  const [pickerOpen, setPickerOpen] = useState(false);
  const [maxVisibleChips, setMaxVisibleChips] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    switch (true) {
      case screenWidth > 1824:
        setMaxVisibleChips(4);
        break;
      case screenWidth > 1224:
        setMaxVisibleChips(3);
        break;
      case screenWidth > 1024:
        setMaxVisibleChips(2);
        break;
      default:
        setMaxVisibleChips(1);
        break;
    }
  }, [screenWidth]);

  const closePicker = useCallback(() => {
    setPickerOpen(false);
  }, []);
  const openPicker = useCallback(() => {
    setPickerOpen(true);
  }, []);

  const suggestionValueIdsEl = useMemo(() => {
    if (optionIds.length <= 0) return <></>;
    const chipMaxWidth = 78 / maxVisibleChips;
    return (
      <Stack direction="row" spacing={1} alignItems="center" sx={{ width: "100%" }} mt={1}>
        <Typography
          variant="caption"
          sx={{ fontSize: "14px", flexGrow: 0, flexShrink: 1, minWidth: "8%", maxWidth: "15%" }}
        >
          {t("multilingual:suggestion")}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            flexGrow: 0,
            flexShrink: 1,
            overflow: "hidden",
            display: "flex",
            width: "90%"
          }}
        >
          {optionIds.slice(0, maxVisibleChips).map(option => (
            <Tooltip title={getOptionLabel(option)} key={option}>
              <Chip
                key={option}
                sx={{
                  backgroundColor: palette.alert.infoBackground,
                  maxWidth: `${chipMaxWidth}%`,
                  flexShrink: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  alignSelf: "center",
                  "&:hover": {
                    backgroundColor: palette.alert.infoBackground
                  },
                  "&:focus": {
                    backgroundColor: palette.alert.infoBackground
                  }
                }}
                label={getOptionLabel(option)}
                clickable
                deleteIcon={<AddCircleIcon fontSize="small" style={{ color: colors.indigo.indigo500 }} />}
                // eslint-disable-next-line react/jsx-no-bind
                onDelete={() => onSuggestionsAdded([option])}
              />
            </Tooltip>
          ))}
          {/* Show "+N" if there are more chips than maxVisibleChips */}
          {optionIds.length > maxVisibleChips && (
            <Typography variant="body2" color="primary" onClick={openPicker} style={{ alignSelf: "center" }}>
              +{optionIds.length - maxVisibleChips}
            </Typography>
          )}
          {optionIds.length > 1 && (
            <Typography
              variant="body2"
              color="primary"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => onSuggestionsAdded(optionIds)}
              sx={{ flexGrow: 0, flexShrink: 1, marginLeft: "24px!important", alignSelf: "center" }}
            >
              {t("processor_pa_page:addAll")}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }, [optionIds, getOptionLabel, onSuggestionsAdded, t, openPicker, maxVisibleChips]);

  return (
    <>
      {suggestionValueIdsEl}
      <MultiPickerDialog
        title={modalTitle}
        open={pickerOpen}
        onClose={closePicker}
        onConfirm={onSuggestionsAdded}
        value={optionIds}
        options={optionIds}
        allowAdd={allowAdd}
        getOptionName={getOptionLabel}
      />
    </>
  );
};

export default SuggestionChips;
