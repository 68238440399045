import React from "react";
import { Box, Chip, makeStyles, SvgIcon, Theme, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";

import EditIcon from "../../../assets/images/icons/edit.svg";
import { ResourceBadge } from "./badge/ResourceBadge";
import { SeenBadge } from "./badge/SeenBadge";
import { ResourceDataAssets } from "./badge/ResourceDataAssets";

const useBadgeStyle = makeStyles((theme: Theme) => {
  const badgeLight = {
    // days
    "& .overdue": {
      color: theme.palette.red[500],
      backgroundColor: theme.palette.red[50]
    },
    "& .tomorrow": {
      color: theme.palette.orange[500],
      backgroundColor: theme.palette.yellow[50]
    },
    "& .today": {
      color: theme.palette.orange[500],
      backgroundColor: theme.palette.yellow[50]
    },
    "& .days": {
      color: theme.palette.orange[500],
      backgroundColor: theme.palette.yellow[50]
    },
    "& .regular": {
      color: theme.palette.blue[500],
      backgroundColor: theme.palette.blue[50]
    },
    "& .done": {
      color: theme.palette.green[500],
      backgroundColor: theme.palette.green[50]
    },
    // risks
    "& .notApplicable": {
      color: theme.palette.blue[500],
      backgroundColor: theme.palette.blue[50]
    },
    "& .medium": {
      color: theme.palette.orange[500],
      backgroundColor: theme.palette.yellow[50]
    },
    "& .low": {
      color: theme.palette.green[500],
      backgroundColor: theme.palette.green[50]
    },
    "& .high": {
      color: theme.palette.red[500],
      backgroundColor: theme.palette.red[50]
    },
    "& .veryHigh": {
      color: theme.palette.red[500],
      backgroundColor: theme.palette.red[50]
    },
    "& .blue": {
      color: theme.palette.blue[500],
      backgroundColor: theme.palette.blue[50]
    },
    "& .lila": {
      color: theme.palette.lila[500],
      backgroundColor: theme.palette.lila[50]
    },
    "& .orange": {
      color: theme.palette.orange[500],
      backgroundColor: theme.palette.yellow[50]
    },
    "& .red": {
      color: theme.palette.red[500],
      backgroundColor: theme.palette.red[50]
    },
    "& .green": {
      color: theme.palette.green[500],
      backgroundColor: theme.palette.green[50]
    }
  };

  const badgeDark = {
    "& .overdue": {
      color: theme.palette.red[200],
      backgroundColor: theme.palette.red[200]
    },
    "& .tomorrow": {
      color: theme.palette.orange[600],
      backgroundColor: theme.palette.yellow[200]
    },
    "& .today": {
      color: theme.palette.orange[600],
      backgroundColor: theme.palette.yellow[200]
    },
    "& .days": {
      color: theme.palette.orange[600],
      backgroundColor: theme.palette.yellow[200]
    },
    "& .regular": {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.green[200]
    },
    "& .done": {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.green[200]
    },
    "& .notApplicable": {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.blue[200]
    },
    "& .medium": {
      color: theme.palette.yellow[600],
      backgroundColor: theme.palette.yellow[200]
    },
    "& .low": {
      color: theme.palette.green[600],
      backgroundColor: theme.palette.green[200]
    },
    "& .high": {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.red[200]
    },
    "& .veryHigh": {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.red[200]
    },
    "& .blue": {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.blue[200]
    },
    "& .lila": {
      color: theme.palette.lila[700],
      backgroundColor: theme.palette.lila[200]
    },
    "& .orange": {
      color: theme.palette.orange[600],
      backgroundColor: theme.palette.yellow[200]
    },
    "& .red": {
      color: theme.palette.red[600],
      backgroundColor: theme.palette.red[200]
    },
    "& .green": {
      color: theme.palette.green[600],
      backgroundColor: theme.palette.green[200]
    }
  };

  return {
    "@global": {
      ".overview-row": {
        ...badgeLight,
        "&:hover": badgeDark,
        "&.selected": badgeDark
      },
      ".editOffIcon": {
        scale: 0.55
      }
    },
    wide: {
      minWidth: "100px",
      width: "90px",
      border: "none"
    },
    deletionConcept: {
      width: "36px",
      height: "36px",
      textAlign: "center",
      lineHeight: "36px",
      marginTop: "8px",
      marginBottom: "8px",
      borderRadius: "50%",
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[200]
    },
    pa: {
      width: "36px",
      height: "36px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center"
    },
    circle: {
      width: "36px",
      height: "36px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center"
    }
  };
});

const EditOffIcon = (props: any) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path
        className="editOffIcon"
        d="M24 42v-3.55l10.8-10.8 3.55 3.55L27.55 42ZM6 31.5v-3h15v3Zm34.5-2.45-3.55-3.55 1.45-1.45q.4-.4 1.05-.4t1.05.4l1.45 1.45q.4.4.4 1.05t-.4 1.05ZM6 23.25v-3h23.5v3ZM6 15v-3h23.5v3Z"
      />
    </SvgIcon>
  );
};

export interface Badge {
  readonly seen?: boolean;
  readonly date?: string;
  readonly days?: number;
  readonly number?: number;
  readonly kind: string;
}

const RiskBadge = ({ kind }: Badge) => {
  const { t } = useTranslation();
  const clsBadge = useBadgeStyle();
  const label = t(`risk_level_short:${kind}`);
  const tooltip = t(`risk_level_tooltip:${kind}`);
  return (
    <Box py={1} mr={3}>
      <Tooltip title={tooltip}>
        <Chip className={`${clsBadge.wide} ${kind}`} label={label} variant="outlined" />
      </Tooltip>
    </Box>
  );
};

const PrivacyCheckBadge = ({ kind }: Badge) => {
  const { t } = useTranslation();
  const clsBadge = useBadgeStyle();
  const label = t(`website_gdpr_risk:${kind}`);
  const tooltip = t(`website_gdpr_risk:${kind}`);
  return (
    <Box py={1} mr={3}>
      <Tooltip title={tooltip}>
        <Chip className={`${clsBadge.wide} ${kind}`} label={label} variant="outlined" />
      </Tooltip>
    </Box>
  );
};

const DueDataBadge = ({ days, date, kind }: Badge) => {
  const { t } = useTranslation();
  const clsBadge = useBadgeStyle();
  const getLabel = () => {
    if (days) {
      return t("tasks_page:days_left", { days: days });
    } else if (date) {
      return date;
    }
    return t(`tasks_page:${kind}`);
  };
  const getStyle = () => {
    if (kind === "noDue" || kind === "regular") {
      return "blue";
    } else if (kind === "tomorrow" || kind === "today" || kind === "days") {
      return "orange";
    } else if (kind === "overdue") {
      return "red";
    } else if (kind === "done") {
      return "green";
    }
  };
  const label = getLabel();
  return (
    <Box py={1} mr={3}>
      <Tooltip title={label}>
        <Chip className={`${clsBadge.wide} ${getStyle()}`} label={label} variant="outlined" />
      </Tooltip>
    </Box>
  );
};

const DeletionConceptBadge = ({ number }: { readonly number: number }) => {
  const cls = useBadgeStyle();
  return (
    <Box mr={3} className={`${cls.deletionConcept} ${number && "blue"}`}>
      {number}
    </Box>
  );
};

const TomProcessBadge = ({ number }: { readonly number: number }) => {
  const { t } = useTranslation();
  const cls = useBadgeStyle();
  return (
    <Tooltip title={t("tom_measures_overview:tooltip_number_connected_PAs")}>
      <Box mr={3} className={`${cls.deletionConcept} ${number && "blue"}`}>
        {number}
      </Box>
    </Tooltip>
  );
};

const ExternalRecipientBadge = ({ kind }: { readonly kind: string }) => {
  const clsBadge = useBadgeStyle();
  const { t } = useTranslation("service_providers_overview");
  const getStyle = () => {
    if (kind === "approved") {
      return "green";
    } else if (kind === "rejected") {
      return "red";
    } else {
      return "orange";
    }
  };
  const getIcon = () => {
    if (kind === "approved") {
      return <DoneIcon />;
    } else if (kind === "rejected") {
      return <ErrorIcon />;
    } else {
      return <EditIcon />;
    }
  };
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t(`${kind}`)}>
        <Box className={`${clsBadge.circle} ${getStyle()}`}>{getIcon()}</Box>
      </Tooltip>
    </Box>
  );
};

const AssetBadge = ({ kind }: { readonly kind: string }) => {
  const clsBadge = useBadgeStyle();
  const { t } = useTranslation("assets_overview");
  const getStyle = () => {
    if (kind === "planned") {
      return "blue";
    } else if (kind === "testing") {
      return "orange";
    } else if (kind === "active") {
      return "green";
    } else if (kind === "inactive") {
      return "red";
    }
  };
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t(`assets_overview:${kind}`)}>
        <Chip className={`${clsBadge.wide} ${getStyle()}`} label={t(`assets_overview:${kind}`)} variant="outlined" />
      </Tooltip>
    </Box>
  );
};

const ActiveBadge = ({ kind }: { readonly kind: string }) => {
  const clsBadge = useBadgeStyle();
  const { t } = useTranslation();
  const getStyle = () => {
    if (kind === "active") {
      return "blue";
    } else {
      return "red";
    }
  };
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t(`task_details:status_${kind}`)}>
        <Chip
          className={`${clsBadge.wide} ${getStyle()}`}
          label={t(`task_details:status_${kind}`)}
          variant="outlined"
        />
      </Tooltip>
    </Box>
  );
};

const DoneBadge = () => {
  const { t } = useTranslation();
  const clsBadge = useBadgeStyle();
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t("filter_criteria:done")}>
        <Chip className={`${clsBadge.wide} green`} label={t("filter_criteria:done")} variant="outlined" />
      </Tooltip>
    </Box>
  );
};

const ReadOnlyBadge = () => {
  const { t } = useTranslation();
  const clsBadge = useBadgeStyle();
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t("common:readOnly")}>
        <Chip className={`${clsBadge.wide} notApplicable`} label={t("common:readOnly")} variant="outlined" />
      </Tooltip>
    </Box>
  );
};

const AuditBadge = ({ kind }: { readonly kind: string }) => {
  const { t } = useTranslation("audit_status");
  const clsBadge = useBadgeStyle();
  const getStyle = () => {
    if (kind === "draft") {
      return "lila";
    } else if (kind === "inactive") {
      return "orange";
    } else if (kind === "edit") {
      return "orange";
    } else if (kind === "review") {
      return "blue";
    } else if (kind === "completed" || kind === "active") {
      return "green";
    }
  };

  const getIcon = () => {
    if (kind === "draft") {
      return <EditOffIcon />;
    } else if (kind === "edit" || kind === "inactive") {
      return <EditIcon />;
    } else if (kind === "review") {
      return <SearchIcon />;
    } else if (kind === "completed" || kind === "active") {
      return <DoneIcon />;
    }
  };
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t(`${kind}`)}>
        <Box className={`${clsBadge.pa} ${getStyle()}`}>{getIcon()}</Box>
      </Tooltip>
    </Box>
  );
};

const ProcessBadge = ({ kind }: { readonly kind: string }) => {
  const { t } = useTranslation("processes_overview");
  const clsBadge = useBadgeStyle();
  const getStyle = () => {
    if (kind === "edit") {
      return "orange";
    } else if (kind === "DPIAApproved") {
      return "red";
    } else if (kind === "review") {
      return "blue";
    } else if (kind === "approved") {
      return "green";
    }
  };

  const getIcon = () => {
    if (kind === "edit") {
      return <EditIcon />;
    } else if (kind === "review") {
      return <SearchIcon />;
    } else if (kind === "approved") {
      return <DoneIcon />;
    }
  };
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t(`status_${kind}`)}>
        <Box className={`${clsBadge.pa} ${getStyle()}`}>{getIcon()}</Box>
      </Tooltip>
    </Box>
  );
};

const TomBadge = ({ kind }: { readonly kind: string }) => {
  const { t } = useTranslation("");
  const clsBadge = useBadgeStyle();
  const getStyle = () => {
    if (kind === "IMPLEMENTED") {
      return "green";
    } else if (kind === "PLANNED") {
      return "blue";
    }
  };

  const getIcon = () => {
    if (kind === "IMPLEMENTED") {
      return <DoneIcon />;
    } else if (kind === "PLANNED") {
      return <SearchIcon />;
    } else return <EditIcon />;
  };
  return (
    <Box py={1} mr={3}>
      <Tooltip title={t(`tom_description_tab:status_${kind}`, "")}>
        <Box className={`${clsBadge.pa} ${getStyle()}`}>{getIcon()}</Box>
      </Tooltip>
    </Box>
  );
};

const getBadgeWeight = (kind: string) => {
  switch (kind) {
    case "readonly":
      return 100;
    case "seen":
      return 200;
    default:
      return 0;
  }
};
export const sortBadges = (a: Badge, b: Badge) => {
  return getBadgeWeight(a.kind) - getBadgeWeight(b.kind);
};

const OverviewBadge = ({
  collection,
  item,
  id
}: {
  readonly collection: COLLECTION_TYPES;
  readonly item: Badge;
  readonly id: string;
}) => {
  if (item.kind === "seen") {
    return <SeenBadge />;
  }
  if (item.kind === "readonly") {
    return <ReadOnlyBadge />;
  }

  switch (collection) {
    case COLLECTIONS.DATA_SUBJECT_REQUESTS:
      return <DueDataBadge days={item.days} date={item.date} kind={item.kind} />;
    case COLLECTIONS.DATA_BREACHES:
      return <DueDataBadge days={item.days} date={item.date} kind={item.kind} />;
    case COLLECTIONS.WEBSITES:
      return <PrivacyCheckBadge kind={item.kind} />;
    case COLLECTIONS.RISK:
    case COLLECTIONS.RISK_MATRIX:
    case COLLECTIONS.TOM_RISKS:
      return <RiskBadge kind={item.kind} />;
    case COLLECTIONS.DELETION_CONCEPT:
      return <DeletionConceptBadge number={item.number || 0} />;
    case COLLECTIONS.PERSON_GROUPS:
      return <ResourceDataAssets kind={item.kind} seen={item.seen} id={id} />;
    case COLLECTIONS.RESOURCES:
      return <ResourceBadge kind={item.kind} seen={item.seen} id={id} />;
    case COLLECTIONS.TASKS:
      if (item.kind === "done") {
        return <DoneBadge />;
      } else if (item.kind === "active" || item.kind === "inactive") {
        return <ActiveBadge kind={item.kind} />;
      } else {
        return <DueDataBadge days={item.days} date={item.date} kind={item.kind} />;
      }
    case COLLECTIONS.ASSETS:
      return <AssetBadge kind={item.kind} />;
    case COLLECTIONS.PROCESSES:
    case COLLECTIONS.PROCESSOR_PAS:
      return <ProcessBadge kind={item.kind} />;
    case COLLECTIONS.AUDITS:
      return <AuditBadge kind={item.kind} />;
    case COLLECTIONS.EXTERNAL_RECIPIENTS:
      return <ExternalRecipientBadge kind={item.kind} />;
    case COLLECTIONS.TOM:
      if (item.kind === "processes") {
        return <TomProcessBadge number={item.number || 0} />;
      } else return <TomBadge kind={item.kind} />;
    default:
      return <></>;
  }
};

export default OverviewBadge;
