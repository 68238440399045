import { useSnackbar } from "notistack";
import { useCallback } from "react";

export const useErrorSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const catchAsSnackbar = useCallback(
    message => {
      return error => {
        enqueueSnackbar(error.message || message, { variant: "error" });
        console.error(error, message || "please fill for better logs");
      };
    },
    [enqueueSnackbar]
  );

  return { catchAsSnackbar };
};
