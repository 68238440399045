import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";
import { isEmailInCorrectFormat } from "app/utils/emailFormatter";
import { useTranslation } from "react-i18next";

const TextAreaQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation();
  const [_value, _setValue] = useState<string | undefined>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  useEffect(() => {
    return _setValue((Array.isArray(value) ? value[0] : value) as string);
  }, [value]);

  const onChangeCallback = useCallback(e => {
    _setValue(e.target.value);
  }, []);
  const onBlurCallback = useCallback(() => {
    onChange?.(_value);
    onBlur?.();

    setIsEmailValid(isEmailInCorrectFormat(_value));
  }, [_value, onBlur, onChange]);

  return (
    <TextField
      fullWidth
      maxRows={1000}
      minRows={1}
      multiline
      value={_value}
      onChange={onChangeCallback}
      variant="outlined"
      onFocus={onFocus}
      onBlur={onBlurCallback}
      label={questionName}
      InputLabelProps={inputLabelProps}
      disabled={disabled}
      error={error || !isEmailValid}
      helperText={helperText ? helperText : isEmailValid ? "" : t("fields:invalid_email")}
    />
  );
};

const inputLabelProps = {};

export default React.memo(TextAreaQuestion);
