import React, { useCallback } from "react";
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useMetaView } from "app/contexts/meta-view-context";
import Question from "../../../../components/Question/Question";

export type AiRiskLevel = "unacceptable" | "high" | "limited" | "low";

interface AiRiskAssessmentProps {
  aiRiskLevel: AiRiskLevel | null;
  onChange: (riskLevel: AiRiskLevel) => void;
}

const riskLevels: AiRiskLevel[] = ["unacceptable", "high", "limited", "low"];

const StyledFormControlLabel = styled(FormControlLabel)<{
  readonly selected?: boolean;
}>(({ theme, selected }) => {
  return {
    width: "52%",
    border: "1px solid rgb(171, 171, 171)",
    borderColor: selected ? theme.palette.primary.main : undefined,
    padding: theme.spacing(1),
    borderRadius: "4px",
    backgroundColor: selected ? theme.palette.blue[50] : "transparent",
    marginBottom: "8px"
  };
});

const AiRiskAssessment = ({ aiRiskLevel, onChange }: AiRiskAssessmentProps) => {
  const { t } = useTranslation("pa_assessment");
  const { setInfo } = useMetaView();

  const handleRadioButtonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const riskLevel = event.target.value as AiRiskLevel;
      onChange(riskLevel);
    },
    [onChange]
  );

  const handleMouseEnter = useCallback(
    (level: AiRiskLevel) => () => {
      setInfo({
        title: `${t(`ai_risk_level_title`)}: ${t(level)}`,
        text: t(`ai_risk_level_description_${level}`)
      });
    },
    [setInfo, t]
  );

  return (
    <>
      <Typography variant="subtitle2">{t("ai_risk_level")}</Typography>
      <Box mt={1}>
        <Typography variant="body1">{t("ai_risk_level_desc")}</Typography>
      </Box>
      <Box mt={-1}>
        <Question questionId={"aiRiskLevel"} questionName={t("aiRiskLevel")}>
          <Box ml={1.3} mt={0.5}>
            <RadioGroup value={aiRiskLevel} onChange={handleRadioButtonChange} name="ai-risk-assessment">
              {riskLevels.map(level => (
                <StyledFormControlLabel
                  key={level}
                  value={level}
                  control={<Radio />}
                  label={t(level)}
                  onMouseEnter={handleMouseEnter(level)}
                  selected={aiRiskLevel === level}
                />
              ))}
            </RadioGroup>
          </Box>
        </Question>
      </Box>
    </>
  );
};

export default AiRiskAssessment;
