import React, { useCallback, useEffect, useState } from "react";
import { Box, DialogContent, Tooltip } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../../../../components/CustomAlert/CustomAlert";
import { TranslatableTextField } from "components/TranslatableTextField/TranslatableTextField";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { isEqual } from "lodash-es";
import { ResourceDialogProps } from "../../ResourceTypeOverviewDeps";
import { useResources } from "../../../../contexts/resource-context";
import { ResourceDTO, updateResourceApi, updateResourceMetadataApi } from "../../../../api/resourceApi";
import { RESOURCE_TYPES } from "../../../../handlers/resourceHandler";

const translationKeys = [
  "booksRecords",
  "inventoriesSec147",
  "annualFinancialStatementsSec147",
  "levelReports",
  "openingBalance",
  "bookingDocsInvoices",
  "bankDocsAccountStatements",
  "logbooks",
  "taxReturns",
  "tradeBusinessLetters",
  "offers",
  "orderConfirmations",
  "bookkeeping",
  "reminders",
  "insurancePolicy",
  "contracts",
  "tradingBooks",
  "inventoriesSec257",
  "openingBalanceSheets",
  "annualFinancialStatementsSec257",
  "indivFinancialStatements",
  "sectionReports",
  "groupFinancialStatements",
  "groupReports",
  "workInstructions",
  "bookingDocumentsSec257",
  "tradeLetters",
  "sec76"
];

export interface LegalRetentionPeriodMetadata {
  readonly storagePeriod: string | null;
  readonly storagePeriodUnit: string | null;
  readonly deletionTriggerId: string | null;
  readonly justificationOfStoragePeriod: string | null;
  readonly exampleDocTypeIds: string[];
}

interface LegalRetentionPeriodDialogProps {
  readonly open: boolean;
  readonly id: string;
  readonly name: string;
  readonly defaultResource: boolean;
  readonly metadata: LegalRetentionPeriodMetadata | null;
  readonly onChange: (metadata: LegalRetentionPeriodMetadata | null, id: string, nameKey: string) => void;
  readonly onClose: () => void;
}

const LegalRetentionPeriodMetadataDialog = ({
  open,
  id,
  name,
  defaultResource,
  metadata,
  onChange,
  onClose
}: LegalRetentionPeriodDialogProps) => {
  const { t } = useTranslation(`resources_legal-retention-period_overview`);
  const [currentNameKey, setCurrentNameKey] = useState(name || "");
  useEffect(() => {
    return setCurrentNameKey(name);
  }, [name]);
  const [currentMetadata, setCurrentMetadata] = useState<LegalRetentionPeriodMetadata | null>();
  useEffect(() => {
    return setCurrentMetadata(metadata);
  }, [metadata]);

  const onConfirm = useCallback(() => {
    if (currentMetadata && (!isEqual(currentMetadata, metadata) || currentNameKey !== name)) {
      onChange(currentMetadata, id, currentNameKey);
      onClose();
    } else {
      onClose();
    }
  }, [currentMetadata, metadata, onChange, currentNameKey, name, id, onClose]);

  const updateMetadata = useCallback((data: Partial<LegalRetentionPeriodMetadata>) => {
    setCurrentMetadata(period => {
      return { ...(period || emptyRetentionPeriodMetadata), ...data };
    });
  }, []);

  const onStoragePeriodChange = useCallback(
    (storagePeriod: number) =>
      updateMetadata({
        storagePeriod: (storagePeriod && storagePeriod.toString()) || ""
      } satisfies Partial<LegalRetentionPeriodMetadata>),
    [updateMetadata]
  );
  const onStoragePeriodUnitChange = useCallback(
    (storagePeriodUnit: string) =>
      updateMetadata({ storagePeriodUnit } satisfies Partial<LegalRetentionPeriodMetadata>),
    [updateMetadata]
  );
  const onDeletionTriggerIdsChange = useCallback(
    (deletionTriggerId: string) =>
      updateMetadata({ deletionTriggerId } satisfies Partial<LegalRetentionPeriodMetadata>),
    [updateMetadata]
  );
  const onJustificationOfStorageChange = useCallback(
    (justificationOfStoragePeriod: string) =>
      updateMetadata({ justificationOfStoragePeriod } satisfies Partial<LegalRetentionPeriodMetadata>),
    [updateMetadata]
  );
  const onExampleDocTypeIdsChanged = useCallback(
    (exampleDocTypeIds: string[]) =>
      updateMetadata({ exampleDocTypeIds } satisfies Partial<LegalRetentionPeriodMetadata>),
    [updateMetadata]
  );

  const titleTextField = (
    <TranslatableTextField
      label={t("resources_category:name")}
      value={currentNameKey || ""}
      translationKey={"resources_legal-retention-period"}
      translationKeys={translationKeys}
      onTextChange={setCurrentNameKey}
      aria-label="name"
      disabled={defaultResource}
    />
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogContent>
        <CustomAlert severity="info">{t("templateModalHeading")}</CustomAlert>
        <Box my={3}>
          {defaultResource && (
            <Tooltip title={t("defaultResourceRename")} placement="top-start">
              <Box>{titleTextField}</Box>
            </Tooltip>
          )}
          {!defaultResource && titleTextField}
          <Box mt={4} />
          <QuestionnaireSubHeader text={t("dpia_storage_table:heading")} />
          <Box display="flex" width="100%">
            <Box mr={3} flexGrow={1}>
              <Question
                qType={QUESTION_TYPE.NUMBER}
                questionName={t("businessRetentionPeriod:inputLabel")}
                value={currentMetadata?.storagePeriod || ""}
                onChange={onStoragePeriodChange}
              />
            </Box>
            <Box ml={3} flexGrow={1}>
              <Question
                qType={QUESTION_TYPE.UNIT_OF_TIME}
                value={currentMetadata?.storagePeriodUnit || ""}
                onChange={onStoragePeriodUnitChange}
              />
            </Box>
          </Box>
          <Question
            qType={QUESTION_TYPE.DELETION_TRIGGER}
            value={currentMetadata?.deletionTriggerId || ""}
            onChange={onDeletionTriggerIdsChange}
          />
          <Question
            qType={QUESTION_TYPE.TEXT_EDITOR}
            value={currentMetadata?.justificationOfStoragePeriod || ""}
            questionName={t("questionnaires:justificationOfStorage")}
            onChange={onJustificationOfStorageChange}
          />
          <Question
            qType={QUESTION_TYPE.DOCUMENT_TYPES}
            value={currentMetadata?.exampleDocTypeIds || emptyArray}
            onChange={onExampleDocTypeIdsChanged}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mr={1} mb={2}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {t("common:cancel")}
          </Button>
        </Box>
        <Box mr={2} mb={2}>
          <Button onClick={onConfirm} variant="contained" color="primary">
            {t("common:confirm")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const emptyArray: string[] = [];

export const LegalRetentionPeriodMetadataDialogWithSave = (props: ResourceDialogProps) => {
  const { resources } = useResources();
  const legalRetentionPeriods = resources[props.resourceType] || emptyResources;
  const [resource, setResource] = useState<ResourceDTO | null>(null);
  useEffect(() => {
    if (!props.resourceId) {
      setResource(null);
    }
    return setResource(legalRetentionPeriods.find(period => period.id === props.resourceId) || null);
  }, [legalRetentionPeriods, props.resourceId]);

  const onChange = useCallback(
    async (metadata: LegalRetentionPeriodMetadata | null, id: string, nameKey: string) => {
      if (resource?.nameKey !== nameKey) {
        await updateResourceApi(RESOURCE_TYPES.LEGAL_RETENTION_PERIOD, id, { nameKey, wait: true });
      }
      if (!isEqual(resource?.metadata, metadata)) {
        await updateResourceMetadataApi(RESOURCE_TYPES.LEGAL_RETENTION_PERIOD, id, metadata, true);
      }
      return props.onConfirm();
    },
    [props, resource?.metadata, resource?.nameKey]
  );

  return (
    <LegalRetentionPeriodMetadataDialog
      key={`lrpmd-${props.resourceId}`}
      open={!!resource}
      name={resource?.nameKey || ""}
      metadata={(resource?.metadata as LegalRetentionPeriodMetadata) || null}
      onClose={props.onClose}
      onChange={onChange}
      id={props.resourceId}
      defaultResource={!!resource?.defaultResource}
    />
  );
};

const emptyResources: ResourceDTO[] = [];

const emptyRetentionPeriodMetadata: LegalRetentionPeriodMetadata = {
  storagePeriod: null,
  storagePeriodUnit: null,
  deletionTriggerId: null,
  justificationOfStoragePeriod: null,
  exampleDocTypeIds: []
};
