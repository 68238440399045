import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { usePathName } from "../../router/router-custom-hooks";
import { FEATURES } from "../../features";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { usePAPermission } from "./usePAPermission";
import { useParams } from "react-router-dom";
import { PagePermissionDTO, PAPermissionsDTOPagesPermission } from "../../api/generated/process-service";
import { chunk } from "lodash-es";

export interface QuestionnaireStepModel {
  readonly label: string;
  readonly path: string;
  readonly icon: number;
  readonly permissionDTO: PagePermissionDTO;
}

export const paPageNameToPagePath = (input: keyof PAPermissionsDTOPagesPermission): string => {
  switch (input) {
    case "processing":
      return "description";
    case "data-flow":
      return "recipients";
    case "data-retention":
      return "periods";
    case "measure":
      return "measures";
    case "pre-assessment":
      return "pa-assessment";
    case "risk":
      return "risks";
    case "rights-of-data-subject":
      return "rights";
    case "change-management":
      return "change";
    case "final-assessment":
      return "dpia-assessment";
    default:
      return input;
  }
};

export function useQuestionnairePaginationSteps() {
  const { id: processId } = useParams();
  const { t } = useTranslation();
  const risksOnSubmissionEnabled = useIsFeaturePresent(FEATURES.RISKS_ON_PA_SUBMISSION);
  const dataFlowOnMeasuresEnabled = useIsFeaturePresent(FEATURES.DATAFLOW_UPLOAD);
  const [measuresPageTitle, setMeasuresPageTitle] = useState("");
  useEffect(() => {
    if (!risksOnSubmissionEnabled && !dataFlowOnMeasuresEnabled) {
      setMeasuresPageTitle(t("pagination:measures"));
      return;
    }
    if (risksOnSubmissionEnabled && !dataFlowOnMeasuresEnabled) {
      setMeasuresPageTitle(t("pagination:measuresRisks"));
      return;
    }
    if (!risksOnSubmissionEnabled && dataFlowOnMeasuresEnabled) {
      setMeasuresPageTitle(t("pagination:dataFlowMeasures"));
      return;
    }
    if (risksOnSubmissionEnabled && dataFlowOnMeasuresEnabled) {
      setMeasuresPageTitle(t("pagination:dataFlowMeasuresRisks"));
    }
  }, [t, risksOnSubmissionEnabled, dataFlowOnMeasuresEnabled]);

  const { paPermission, isLoading: paPermissionLoading } = usePAPermission(processId || "");
  const [stepsInitialized, setStepsInitialized] = useState<boolean>(false);
  const [availableSteps, setAvailableSteps] = useState<QuestionnaireStepModel[]>([]);
  useEffect(() => {
    if (paPermissionLoading) {
      return;
    }

    const allSteps = Object.entries(paPermission?.pagesPermission || {})
      .filter(([, pagePermission]) => pagePermission.permission === "read" || pagePermission.permission === "write")
      .sort(([, pagePermissionA], [, pagePermissionB]) => pagePermissionA.order - pagePermissionB.order)
      .map(([pageName, pagePermission], index) => ({
        label: pageName !== "measure" ? t(`pagination:${pageName}`) : measuresPageTitle,
        path: paPageNameToPagePath(pageName as keyof PAPermissionsDTOPagesPermission),
        icon: index + 1,
        permissionDTO: pagePermission
      }));
    setAvailableSteps(allSteps);
    setStepsInitialized(true);
  }, [t, measuresPageTitle, paPermissionLoading, paPermission?.pagesPermission]);

  const path = usePathName();
  const [stepsChunks, setStepsChunks] = useState<QuestionnaireStepModel[][]>([]);
  useEffect(() => {
    if (availableSteps.length <= 6) {
      // if only 10 pages, then no pagination is needed
      setStepsChunks([availableSteps]);
      return;
    }

    const chunks = chunk(availableSteps, 6);
    setStepsChunks(chunks);
  }, [availableSteps]);

  const [currentStep, setCurrentStep] = useState<QuestionnaireStepModel | null>(null);
  useEffect(() => {
    const lastPath = path.split("/").pop();
    const currentStep = availableSteps.find(step => step.path === lastPath);
    setCurrentStep(currentStep || availableSteps[0]);
  }, [availableSteps, path]);

  const [currentDisplayedSteps, setCurrentDisplayedSteps] = useState<QuestionnaireStepModel[]>([]);
  useEffect(() => {
    if (stepsChunks.length === 1) {
      setCurrentDisplayedSteps(stepsChunks[0] || []);
      return;
    }

    const currentChunk = stepsChunks.find(chunk => chunk.some(step => step.path === currentStep?.path));
    setCurrentDisplayedSteps(currentChunk || stepsChunks[0] || []);
  }, [stepsChunks, currentStep?.path]);

  return {
    availableSteps,
    currentDisplayedSteps,
    stepsChunks,
    currentStep,
    stepsInitialized
  };
}
