import React, { useCallback } from "react";
import { makeStyles, TextField } from "@material-ui/core";

let useStyles = makeStyles(theme => ({
  textarea: {
    width: "100%"
  }
}));
export default function TextArea({ value, onChange, onFocus, onBlur, disabled, rowsMin = 3, label, InputProps, id }) {
  const classes = useStyles();
  const onChangeCallback = useCallback(event => onChange(event.target.value), [onChange]);
  return (
    <TextField
      maxRows={1000} // infinity causes some height calculation issues, no one would need more than 1000 anyway
      multiline
      disabled={disabled}
      value={value}
      className={classes.textarea}
      onChange={onChangeCallback}
      variant="outlined"
      onFocus={onFocus}
      onBlur={onBlur}
      label={label}
      InputProps={InputProps}
      minRows={rowsMin}
      id={id}
    />
  );
}
