import { useParams } from "react-router-dom";
import { COLLECTIONS, COLLECTION_TYPES } from "app/collections";
import { SidebarUnseen, useSidebarUnseen } from "app/pages/shared/Sidebar/useSidebarUnseen";

export const useUnseenCount = (collection: COLLECTION_TYPES) => {
  const { resourceType } = useParams();
  const {
    sidebarNewItems: { [collection as keyof SidebarUnseen]: sidebarUnseenCount, resourcesByType }
  } = useSidebarUnseen();

  if (collection === COLLECTIONS.RESOURCES && resourceType) {
    return resourcesByType?.find(r => r.type === resourceType)?._count || 0;
  }
  return Number(sidebarUnseenCount) || 0;
};
