import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { getDeepLTranslation } from "../handlers/deepLHandler";
import { debounce } from "lodash-es";

export interface TranslationProps {
  readonly translationId?: string;
  readonly detectedSourceLanguage?: string;
  readonly translatedText?: string;
  readonly targetLanguage?: string;
  readonly textToTranslate: string;
  readonly selectedSourceLanguage?: string;
}

const emptyTranslationObject: TranslationProps = {
  translationId: "",
  detectedSourceLanguage: "",
  translatedText: "",
  targetLanguage: "",
  textToTranslate: "",
  selectedSourceLanguage: ""
};

export interface TranslatorContextValue {
  readonly loading: boolean;
  readonly translations: TranslationProps[];
  readonly translationError: boolean;
  readonly setTextToTranslateHook: (translationId: string, value: string) => void;
  readonly setSelectedSourceLanguageHook: (translationId: string, value: TranslationProps) => void;
  readonly setTargetLanguageHook: (translationId: string, value: TranslationProps) => void;
  readonly translateHook: (translationObject: TranslationProps) => void;
  readonly hideTranslationHook: (translationId: string) => void;
  readonly findTranslation: (translationId: string) => TranslationProps | null;
}

export const TranslatorContext = createContext<TranslatorContextValue>({
  loading: true,
  translations: [],
  translationError: false,
  setTextToTranslateHook: () => {
    // do
  },
  setSelectedSourceLanguageHook: () => {
    // do
  },
  setTargetLanguageHook: () => {
    // do
  },
  translateHook: () => {
    // do
  },
  hideTranslationHook: () => {
    // do
  },
  findTranslation: () => {
    return null;
  }
});

export const TranslatorProvider = function ({ children }: { children: React.ReactNode }) {
  const [translations, setTranslations] = useState<TranslationProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [translationError, setTranslationError] = useState<boolean>(false);

  const findTranslation = useCallback(
    (translationId: string) => {
      return translations.find(translation => translation.translationId === translationId) || null;
    },
    [translations]
  );

  const updateTranslationValue = useCallback((translations, translationId, value, fieldName) => {
    const translationExists = translations.some(
      (translation: TranslationProps) => translation.translationId === translationId
    );
    if (!translationExists) {
      return [...translations, { ...emptyTranslationObject, translationId, [fieldName]: value }];
    }

    return translations.map((translation: TranslationProps) => {
      if (translation.translationId === translationId) {
        return {
          ...translation,
          [fieldName]: value
        };
      }
      return translation;
    });
  }, []);

  const translateViaDeepL = useCallback(async translation => {
    const resultTranslation = await getDeepLTranslation({
      targetLanguage: translation.targetLanguage,
      textToBeTranslated: translation.textToTranslate,
      sourceLanguage: translation.selectedSourceLanguage
    });
    return {
      ...translation,
      translatedText: resultTranslation?.translations?.[0]?.text || "",
      detectedSourceLanguage: resultTranslation?.translations?.[0]?.detected_source_language
    };
  }, []);

  const translateHook = useCallback(
    async toBeUpdatedTranslation => {
      if (
        !toBeUpdatedTranslation?.translationId ||
        !toBeUpdatedTranslation?.targetLanguage ||
        !toBeUpdatedTranslation?.textToTranslate
      ) {
        return;
      }
      const translationId = toBeUpdatedTranslation.translationId;
      setLoading(true);
      try {
        const translated = await translateViaDeepL(toBeUpdatedTranslation);
        setTranslations(translations =>
          translations.map(translation => (translation.translationId === translationId ? translated : translation))
        );
        setTranslationError(false);
      } catch (error) {
        console.error("failed to translate: " + translationId, error);
        setTranslationError(true);
      }
      setLoading(false);
    },
    [setTranslations, translateViaDeepL]
  );

  const debouncedTranslateHook = useMemo(
    () => debounce(toBeUpdatedTranslation => translateHook(toBeUpdatedTranslation), 600),
    [translateHook]
  );

  const hideTranslationHook = useCallback(
    translationId => {
      setTranslations(translations =>
        translations.map(translation => {
          if (translation.translationId === translationId) {
            return {
              ...translation,
              translationId: translationId,
              detectedSourceLanguage: "",
              translatedText: "",
              targetLanguage: "",
              selectedSourceLanguage: ""
            };
          }
          return translation;
        })
      );
    },
    [setTranslations]
  );

  const setTextToTranslateHook = useCallback(
    (translationId, value) => {
      setTranslations(translations => updateTranslationValue(translations, translationId, value, "textToTranslate"));
    },
    [setTranslations, updateTranslationValue]
  );

  const setSelectedSourceLanguageHook = useCallback(
    (translationId, value) => {
      setTranslations(translations =>
        updateTranslationValue(translations, translationId, value, "selectedSourceLanguage")
      );
    },
    [setTranslations, updateTranslationValue]
  );

  const setTargetLanguageHook = useCallback(
    (translationId, value) => {
      setTranslations(translations => updateTranslationValue(translations, translationId, value, "targetLanguage"));
    },
    [setTranslations, updateTranslationValue]
  );

  return (
    <TranslatorContext.Provider
      value={{
        translations,
        setTextToTranslateHook,
        loading,
        setSelectedSourceLanguageHook,
        setTargetLanguageHook,
        translateHook: debouncedTranslateHook,
        hideTranslationHook,
        findTranslation,
        translationError
      }}
    >
      {children}
    </TranslatorContext.Provider>
  );
};

export const useTranslator = () => useContext(TranslatorContext);
