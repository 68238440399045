import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphContainer } from "./GraphContainer";
import { getDSRStatistic, STATS_TYPE } from "../../handlers/dashboardHandler";
import useTheme from "@material-ui/core/styles/useTheme";
import { useBizChartModel } from "./bizchart";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { TimeAndCountChart } from "./TimeAndCountChart";

export const DSRChart = ({ timePeriod, orgUnitId, labelId }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation("dashboardPage");
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const { catchAsSnackbar } = useErrorSnackbar();
  const { convertStatsToBizChartModel } = useBizChartModel();
  useEffect(() => {
    const init = async () => {
      const dsrStats = await getDSRStatistic(timePeriod?.id, orgUnitId, labelId);
      const chartData = convertStatsToBizChartModel(
        dsrStats,
        timePeriod?.id === STATS_TYPE.ALL_TIME && {
          month: "long",
          year: "numeric"
        },
        i18n.language
      );
      setChartData(chartData);
      setLoading(false);
    };

    init().catch(catchAsSnackbar("failed to load DSR statistic data"));
  }, [timePeriod?.id, convertStatsToBizChartModel, catchAsSnackbar, i18n.language, orgUnitId, labelId]);

  const chartScale = useMemo(
    () => ({
      count: { min: 0 },
      type: {
        formatter: type => {
          switch (type) {
            case "deleted":
              return t("deleted");
            case "done":
              return t("markedAsDone");
            case "created":
              return t("created");
            default:
              return type;
          }
        }
      }
    }),
    [t]
  );

  const color = useMemo(
    () => [
      "type",
      type => {
        switch (type) {
          case "deleted":
            return theme.palette.blueGrey[400];
          case "done":
            return theme.palette.green[400];
          case "created":
          default:
            return theme.palette.blue[600];
        }
      }
    ],
    [theme]
  );

  return (
    <GraphContainer title={t("dashboardCharts:dataSubject_title")} loading={loading}>
      <TimeAndCountChart timePeriodId={timePeriod?.id} chartScale={chartScale} chartData={chartData} color={color} />
    </GraphContainer>
  );
};
