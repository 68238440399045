import useSWR from "swr";
import { getEnforcementInfoApi } from "../../api/user/userMeApi";
import React, { CSSProperties, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, BoxProps, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";

export const AccountNotice = (props: Pick<BoxProps, "mt" | "mb" | "ml" | "mr">) => {
  const { data } = useSWR("/me/enforcements", () => getEnforcementInfoApi());
  const showMFANotice = useMemo(() => data?.mfaGracePeriodEndsAt || "", [data?.mfaGracePeriodEndsAt]);

  return (
    <>
      {showMFANotice && (
        <Box mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr}>
          <MFAEnforcementNotice gracePeriodEndsAt={showMFANotice} />
        </Box>
      )}
    </>
  );
};

const MFAEnforcementNotice = (props: { readonly gracePeriodEndsAt: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToMFASettings = useCallback(() => {
    navigate("/account-settings/mfa");
  }, [navigate]);
  return (
    <CustomAlert severity="warning" fullWidth>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <p>
          {t("authentication:warning_mfa_grace_period", {
            date: new Date(props.gracePeriodEndsAt)
              .toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric"
              })
              .replace(",", "")
          })}
        </p>
        <GoToMFASettingButton onClick={goToMFASettings} />
      </Box>
    </CustomAlert>
  );
};

const GoToMFASettingButton = (props: { readonly onClick: () => void }) => {
  const { t } = useTranslation();
  const style = useMemo<CSSProperties>(() => ({ minWidth: 150 }), []);
  return (
    <Box ml={2}>
      <Button variant="contained" color="primary" onClick={props.onClick} style={style}>
        {t("authentication:go_to_mfa")}
      </Button>
    </Box>
  );
};
