import React, { memo, useCallback } from "react";
import { Link, Box, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// memoizing to prevent unnecessary rerenders
const LogoSidebarCustom = memo(
  ({
    tenantName,
    customLogoUrl,
    open
  }: {
    readonly tenantName: string;
    readonly customLogoUrl: string | null;
    open: boolean;
  }) => {
    const { t } = useTranslation("sidebar");
    const navigate = useNavigate();
    const theme = useTheme();

    const onClickCallback = useCallback(() => {
      navigate("/");
    }, [navigate]);

    return (
      <Box
        display={"flex"}
        sx={{
          alignItems: "center",
          height: open ? "60px" : null,
          marginLeft: open ? theme.spacing(1.75) : null,
          transition: "height 0.1s, margin-left 0.1s",
          "& img": {
            borderRadius: "8px",
            height: open ? "42px" : "58px",
            marginLeft: !open ? "6px" : null,
            objectFit: "cover",
            transition: "width 0.1s, height 0.1s, margin-left 0.1s",
            userSelect: "none",
            width: open ? "42px" : "58px"
          }
        }}
      >
        {customLogoUrl && <img src={customLogoUrl} alt={"Logo"} onClick={onClickCallback} />}
        {customLogoUrl && open && (
          <Box
            sx={{
              marginLeft: theme.spacing(6),
              position: "absolute",
              width: "calc(100% - 80px)"
            }}
          >
            <Tooltip title={tenantName}>
              <Box
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: open ? "14.5px" : "18px",
                  fontWeight: open ? 600 : undefined,
                  letterSpacing: "0.25px",
                  marginLeft: "6px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {tenantName}
              </Box>
            </Tooltip>
            <Link
              underline="none"
              color="inherit"
              sx={{
                color: theme.palette.text.secondary,
                display: "block",
                fontSize: "12px",
                fontWeight: "450",
                letterSpacing: "0.3px",
                lineHeight: "20px",
                marginLeft: "6px",
                marginTop: open ? undefined : "18px",
                "&:hover": {
                  textDecoration: "none"
                }
              }}
              target="blank"
              href="https://caralegal.eu"
            >
              {`${t("powered_by")} Caralegal`}
            </Link>
          </Box>
        )}
      </Box>
    );
  }
);

LogoSidebarCustom.displayName = "LogoSidebarCustom";

export default LogoSidebarCustom;
