import React, { useCallback, useMemo, useState } from "react";
import { Box, IconButton, InputBase, makeStyles, Tooltip, Typography } from "@material-ui/core";
import CustomAlert from "components/CustomAlert/CustomAlert";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { COLLECTION_TYPES } from "app/collections";
import { OverviewNewItem } from "../controllers/overviewBaseController";
import stopEvent from "tool/stopEvent";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  addRow: {
    borderBottom: "1px solid #ccc",
    "& .newItemInput": {
      width: "100%"
    },
    "& .MuiInputBase-input": {
      padding: "14.5px 14px",
      width: "100%"
    }
  }
}));

export interface OverviewAddProps {
  readonly collection?: COLLECTION_TYPES;
  readonly placeholder?: string;
  readonly level?: number;
  readonly onAdd: (data: OverviewNewItem) => void;
  readonly onClose: () => void;
  readonly onValidate?: (input: OverviewNewItem) => string | null;
}

const OverviewAdd = ({ collection, placeholder, level, onAdd, onClose, onValidate }: OverviewAddProps) => {
  const { t } = useTranslation();
  const cls = useStyles();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState<string>("");
  const addButtonCallback = useCallback(() => {
    if (value.length) {
      setValue("");
      onAdd({ title: value });
    }
  }, [onAdd, value]);
  const closeButtonCallback = useCallback(() => {
    setValue("");
    onClose();
  }, [onClose]);
  const onChangeCallback = useCallback(event => setValue(event.target.value), []);
  const onKeyDownCallback = useCallback(
    event => {
      if (event.keyCode === 27) {
        setValue("");
        onClose();
        return;
      }
      if (value.length) {
        if (event.keyCode === 13 && value) {
          const errorMessage = onValidate?.({ title: value });
          if (errorMessage) {
            setErrorMessage(errorMessage);
            return;
          }

          setErrorMessage("");
          setValue("");
          onAdd({ title: value });
        }
      }
    },
    [onAdd, onClose, onValidate, value]
  );
  const inputPlaceholder = useMemo(
    () => placeholder || t(`${collection}_overview:new_item_placeholder`, t("overview:new_item_placeholder")),
    [collection, placeholder, t]
  );
  const plByLevel: Record<number, number> = {
    0: 17,
    1: 22
  };
  const addRowEl = (
    <Box onClick={stopEvent}>
      {errorMessage && (
        <Box px={4} py={1}>
          <CustomAlert severity="error">
            <Typography component="span">{errorMessage}</Typography>
          </CustomAlert>
        </Box>
      )}
      <Box
        className={cls.addRow}
        alignItems="center"
        display="flex"
        px={4}
        py={1}
        pl={level !== undefined ? plByLevel[level] : 1}
      >
        <InputBase
          autoFocus={true}
          onKeyDown={onKeyDownCallback}
          value={value}
          onChange={onChangeCallback}
          placeholder={inputPlaceholder}
          className={"newItemInput"}
          inputProps={nakedLabel}
        />
        <Box>
          <Tooltip title={t("overview:add_button")}>
            <IconButton color="primary" aria-label="add" onClick={addButtonCallback}>
              <AddIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title={t("overview:cancel")}>
            <IconButton
              style={{
                color: theme.palette.error.main
              }}
              aria-label="close"
              onClick={closeButtonCallback}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );

  return addRowEl;
};

const nakedLabel = { "aria-label": "naked" };

export default OverviewAdd;
