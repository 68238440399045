import { OverviewItem } from "../controllers/overviewBaseController";

export const getCheckableItemIds = (items: OverviewItem[]): string[] => {
  let ids: string[] = [];

  for (const item of items) {
    if (!item.disableActions?.find(({ action }) => action === "checkable")) {
      ids.push(item.id);
    }
    if (item.children) {
      ids = ids.concat(getCheckableItemIds(item.children));
    }
  }

  return ids;
};

export const findDescendants = (item: OverviewItem): OverviewItem[] => {
  let descendants: OverviewItem[] = [];

  if (item.children) {
    for (const child of item.children) {
      descendants.push(child);
      descendants = descendants.concat(findDescendants(child));
    }
  }

  return descendants;
};

// quite expensive, should think about having the ancestor ids in the OverviewItem
export const findAncestors = (tree: OverviewItem[], item: OverviewItem): string[] => {
  const ancestors: string[] = [];
  while (item.parentId) {
    const parentNode = findItemById(tree, item.parentId); // Search the entire tree for the parent
    if (parentNode) {
      ancestors.push(parentNode.id); // Add the ancestor's id
      item = parentNode; // Move up to the parent
    } else {
      break;
    }
  }
  return ancestors;
};

export const findItemById = (tree: OverviewItem[], id: string): OverviewItem | undefined => {
  for (const item of tree) {
    if (item.id === id) {
      return item;
    }
    const foundInChild = findItemById(item.children || [], id); // Recursively search in children
    if (foundInChild) {
      return foundInChild;
    }
  }
  return undefined;
};
