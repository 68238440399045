import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { apiEndpoints } from "./apiEndpoint";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.cubeJsHelperUrl}/api/cubejs`
});

export interface CubeJSAccessToken {
  readonly token: string;
  readonly url: string;
  readonly testUrl: string;
}

export interface CubeJSEvent {
  readonly url: string;
  readonly docType: string;
  readonly docTitle: string;
  readonly docId: string;
  readonly activityType: string;
  readonly tableId: "activities";
}

export async function postCubeJsToken() {
  const response = await axiosInstance.post<Partial<CubeJSAccessToken>>("/token");
  return response.data || {};
}

export async function postCubeJsEvent(payload: CubeJSEvent) {
  await axiosInstance.post<void, CubeJSEvent>("/event", payload);
}
