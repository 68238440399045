import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import GeneralPageStepper, { PageStep } from "../../../../../../components/Pagination/GeneralPageStepper";
import GeneralPageButtonPagination from "../../../../../../components/Pagination/GeneralPageButtonPagination";

function usePaginationSteps() {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<PageStep[]>([]);

  useEffect(() => {
    setSteps(
      [
        {
          label: t("audit_details:general"),
          path: "general"
        },
        {
          label: t("audit_details:questionnaire"),
          path: "questionnaire"
        },
        {
          label: t("audit_details:confirmation"),
          path: "confirmation"
        }
      ].flatMap(step => (step ? [step] : []))
    );
  }, [t]);

  return {
    steps
  };
}

export function AnswersetPageStepper() {
  const { steps } = usePaginationSteps();

  return <GeneralPageStepper steps={steps} />;
}

export function AnswersetPageButtons({ rightArea }: { readonly rightArea?: React.ReactNode }) {
  const { steps } = usePaginationSteps();

  return <GeneralPageButtonPagination steps={steps} rightArea={rightArea} />;
}
