import parsePhoneNumber from "libphonenumber-js";

export const validateAndConvertPhoneNumber = (phoneNumberText: any): string | null => {
  if (!(typeof phoneNumberText === "string" || phoneNumberText instanceof String)) {
    return null;
  }

  const phoneNumberObj = parsePhoneNumber(phoneNumberText as string, "DE");
  if (!phoneNumberObj?.isValid()) {
    return null;
  }

  return phoneNumberObj.format("E.164");
};
