import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography
} from "@material-ui/core";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles(() => ({
  pageMenu: {
    margin: "4px 0",
    "& .MuiTypography-root": {
      marginLeft: "8px",
      maxWidth: "150px"
    }
  }
}));

export interface OverviewPageProps {
  readonly title: string;
  readonly route: string;
  readonly current: boolean;
  readonly unseenCount?: number;
}

export interface OverviewPagesMenuProps {
  readonly pages: OverviewPageProps[];
  readonly onPageChange?: (page: OverviewPageProps) => void;
}

export interface OverviewPageMenuItemProps {
  readonly page: OverviewPageProps;
  readonly current: boolean;
  readonly onPageChange?: (page: OverviewPageProps) => void;
}

const PageMenuItem = ({ page, current, onPageChange }: OverviewPageMenuItemProps) => {
  const onPageChangeCallback = useCallback(() => {
    onPageChange?.(page);
  }, [onPageChange, page]);
  return (
    <MenuItem key={page.route} onClick={onPageChangeCallback} selected={current}>
      {page.title}
    </MenuItem>
  );
};

const OverviewPagesMenu = ({ pages, onPageChange }: OverviewPagesMenuProps) => {
  const cls = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const [current, setCurrent] = useState<OverviewPageProps | undefined>();

  useEffect(() => {
    setCurrent(pages.find(i => i.current));
  }, [pages]);

  /* ACTIONS */
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorRef(null);
  }, []);

  const handleToggle = useCallback(event => {
    setAnchorRef(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  }, []);

  const pageChange = useCallback(
    page => {
      if (page !== current) {
        handleClose();
        onPageChange?.(page);
        setCurrent(page);
      }
    },
    [current, handleClose, onPageChange]
  );
  /* ACTIONS */

  const viewEl = useMemo(
    () => (
      <Tooltip title={(current && current.title) || ""}>
        <Button variant="outlined" onClick={handleToggle} color="primary">
          <Box display="flex" alignItems="center" className={cls.pageMenu}>
            <ViewCarouselIcon />
            <Typography color="primary" noWrap>
              {current && current.title}
            </Typography>
            <ArrowDropDownIcon />
          </Box>
        </Button>
      </Tooltip>
    ),
    [cls.pageMenu, current, handleToggle]
  );

  const popperEl = useMemo(
    () => (
      <Popper open={open} anchorEl={anchorRef} placement={"bottom-end"} transition disablePortal>
        {() => (
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {pages.map(page => (
                  <PageMenuItem
                    key={page.route}
                    page={page}
                    current={page.route === current?.route}
                    onPageChange={pageChange}
                  />
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    ),
    [anchorRef, current?.route, handleClose, open, pageChange, pages]
  );

  return (
    <Box display="flex" justifyContent={"end"}>
      {viewEl}
      {popperEl}
    </Box>
  );
};

export default OverviewPagesMenu;
