import MetaView, { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";
import DocMetaView from "components/DocMetaView/DocMetaView";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuditDetailDTO, getAuditDetail, patchAuditDetail } from "app/api/auditApi";
import DocView from "components/DocView/DocView";
import { Box, Button, Step, StepButton, Stepper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import { AuditDetails } from "./pages/AuditDetails";
import { AuditQuestionnaire } from "./pages/AuditQuestionnaire";
import { AUDIT_PAGE } from "./AuditTypes";
import { AuditResult } from "./pages/AuditResult";
import { useMetaView } from "app/contexts/meta-view-context";
import { useTasks } from "app/contexts/tasks-context";

const Audit = () => {
  const { t } = useTranslation("audit_details");
  const id = useParams()?.id || "";
  const { page } = useParams();
  const [audit, setAudit] = useState<Partial<AuditDetailDTO> | null>(null);
  const [auditName, setAuditName] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();
  const [documentNotFound, setDocumentNotFound] = useState<boolean>(false);
  const { reloadDocRelatedTasks } = useTasks();

  const { setQuestionId, setSelectedTab } = useMetaView();
  const reloadAuditCallback = useCallback(async () => {
    if (!id) {
      setAudit(null);
      setDocumentNotFound(true);
      return;
    }

    const auditDetail = await getAuditDetail({ id: id });
    if (auditDetail) {
      setAudit(auditDetail);
      setAuditName(auditDetail?.title || "");
      setDocumentNotFound(false);
      reloadDocRelatedTasks();
    } else {
      setDocumentNotFound(true);
    }
  }, [id, reloadDocRelatedTasks]);

  const steps = useMemo(() => {
    return ["general", "questionnaire", "result"]
      .map((page, index) => ({
        label: page,
        number: index,
        disabled: !audit?.pages?.includes?.(page)
      }))
      .filter(step => !step.disabled);
  }, [audit?.pages]);

  useEffect(() => {
    const pageFromParam = steps.find(({ label }) => label.toLowerCase() === page?.toLowerCase());
    setActiveStep(pageFromParam?.number || 0);
  }, [page, steps]);

  useEffect(() => {
    reloadAuditCallback();
  }, [reloadAuditCallback]);

  const handleStep = useCallback(
    (step: number) => () => {
      setQuestionId(null);
      setSelectedTab(META_VIEW_TABS.ASSISTANT);
      const stepData = steps[step];
      if (stepData?.disabled) {
        return;
      }
      const pageName = stepData?.label || "general";
      navigate(`/audits/instances/${id}/${pageName}`);
    },
    [id, navigate, setQuestionId, setSelectedTab, steps]
  );

  const steppersEl = (
    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
      {steps.map(({ label, disabled }, index) => {
        return (
          <Step key={label}>
            <StepButton disabled={disabled} onClick={handleStep(index)}>
              {t(label)}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );

  /* TITLE */
  const onTitleChange = useCallback(
    async title => {
      await patchAuditDetail({ id: id, payload: { title } });
      await reloadAuditCallback();
    },
    [id, reloadAuditCallback]
  );

  const tabs = useMemo(() => {
    if (audit) {
      const allTabs: META_VIEW_TABS_TYPES[] = [META_VIEW_TABS.ASSISTANT];
      const step = steps[activeStep].label;
      if (step === AUDIT_PAGE.QUESTIONNAIRE) {
        allTabs.push(META_VIEW_TABS.TODOS);
        allTabs.push(META_VIEW_TABS.REMARKS);
      } else if (step === AUDIT_PAGE.GENERAL) {
        allTabs.push(META_VIEW_TABS.TODOS);
        allTabs.push(META_VIEW_TABS.COMMENTS);
      }
      return allTabs;
    } else return [];
  }, [activeStep, audit, steps]);

  const step = steps[activeStep]?.label;
  return (
    <DocMetaView
      loading={audit === null}
      notFound={documentNotFound}
      collection={COLLECTIONS.AUDITS}
      metaViewContent={
        <MetaView collection={COLLECTIONS.AUDITS} translationKey={"audit_details"} tabs={tabs} docId={id || ""} />
      }
    >
      <DocView pagination={steppersEl} header={auditName} onHeaderChange={onTitleChange}>
        {step === AUDIT_PAGE.GENERAL && <AuditDetails audit={audit} onUpdate={reloadAuditCallback} />}
        {step === AUDIT_PAGE.QUESTIONNAIRE && <AuditQuestionnaire auditId={id} />}
        {step === AUDIT_PAGE.RESULT && <AuditResult auditId={id} />}
        <Box mt={5} display="flex" justifyContent="flex-end">
          {activeStep !== steps.length - 1 && (
            <Button variant="contained" color="primary" onClick={handleStep(activeStep + 1)}>
              {t("common:next")}
            </Button>
          )}
        </Box>
      </DocView>
    </DocMetaView>
  );
};

export default Audit;
