import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.deeplUrl}/translate`
});

const langCodesMap: Record<string, string> = {
  cz: "cs",
  no: "nb",
  "de-CH": "de"
};

export const getTranslationAndSourceLanguage = async (
  targetLanguage: string,
  textToBeTranslated: string,
  sourceLanguage: string
) => {
  const response = await axiosInstance.get<{
    translations: Array<{
      detected_source_language: string;
      text: string;
    }>;
  }>("", {
    params: {
      text: textToBeTranslated,
      // eslint-disable-next-line camelcase
      target_lang: langCodesMap[targetLanguage] || targetLanguage,
      // eslint-disable-next-line camelcase
      source_lang: langCodesMap[sourceLanguage] || sourceLanguage,
      // eslint-disable-next-line camelcase
      preserve_formatting: 1
    }
  });
  return response ? response.data : null;
};
