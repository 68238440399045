import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import "date-fns";
import { useTranslation } from "react-i18next";
import CardTitle from "../../../../components/CardTitle/CardTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {
  AllProfileData,
  createExportProfile,
  deleteExportProfile,
  emptyTenantProfileInformation,
  getDefaultExportProfileData,
  getExportProfileById,
  getExportProfilesPerPage,
  Profile,
  PROFILE_TYPE,
  ProfileData,
  updateDefaultExportProfile,
  updateExportProfile
} from "app/handlers/tenantProfileInformationHandler";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { shouldShowEmailInputError } from "app/utils/emailFormatter";
import { FEATURES } from "app/features";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { Box, CircularProgress } from "@material-ui/core";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { v4 } from "uuid";
import useSWR from "swr";

interface ProfileMultiAutocompleteProps {
  readonly tenantId: string;
  readonly selectedProfileId?: string;
  readonly profileType: PROFILE_TYPE;
  readonly unsaved: boolean;
  readonly onChange: (id: string) => void;
  readonly onAdd: (id: string) => void;
  readonly onSave: () => void;
}

const ProfileSelector = ({
  tenantId,
  selectedProfileId,
  profileType,
  unsaved,
  onSave,
  onChange,
  onAdd
}: ProfileMultiAutocompleteProps) => {
  /* CONTEXT */
  const { t } = useTranslation("processing_activity_export_pdf");
  const { catchAsSnackbar } = useErrorSnackbar();
  const profilesFeatureEnabled = useIsFeaturePresent(FEATURES.CONTACT_INFO_PROFILES);

  /* STATE */
  const [openProfileDeletion, setOpenProfileDeletion] = useState<boolean>(false);
  const [render, setRender] = useState<number>(Date.now());
  const [selected, setSelected] = useState<string>("");
  const [optionIds, setOptionIds] = useState<string[]>([]);
  const [optionsMap, setOptionsMap] = useState<Record<string, Profile>>({});

  const fetch = useCallback(async () => {
    const profiles = await getExportProfilesPerPage({ tenantId, profileType });

    // we have to ignore default(first) profile in selectro
    // profile with no profileTitle will be ignored
    const validProfiles = profiles.filter(({ profileTitle }) => profileTitle !== "");
    setOptionIds(validProfiles.map(({ id }) => id));
    setOptionsMap(validProfiles.reduce<Record<string, Profile>>((acc, next) => ({ ...acc, [next.id]: next }), {}));
    setSelected(selectedProfileId || "");
    setRender(Date.now());
  }, [profileType, selectedProfileId, tenantId]);

  useEffect(() => {
    fetch();
  }, [fetch, selectedProfileId, profileType]);

  const handleClickOpenProfileDelete = useCallback(() => setOpenProfileDeletion(true), []);
  const handleCloseProfileDelete = useCallback(() => setOpenProfileDeletion(false), []);
  const getOptionLabelCallback = useCallback(id => (optionsMap[id] ? optionsMap[id]?.profileTitle : id), [optionsMap]);
  const updateSelectedCallback = useCallback(id => optionIds.includes(id) && onChange(id), [onChange, optionIds]);
  const updateOptionsCallback = useCallback(
    async (options: string[]) => {
      const profileTitle: string | undefined = options[options.length - 1];
      if (!profileTitle) {
        return;
      }

      const profileData = await getDefaultExportProfileData(tenantId);
      const profileId = v4();
      await createExportProfile({
        profileData: { ...profileData[profileType], profileTitle },
        profileType,
        profileId
      });
      await fetch();
      onAdd(profileId);
    },
    [fetch, onAdd, profileType, tenantId]
  );
  const onProfileSaveCallback = useCallback(async () => {
    if (selected) {
      const profileData = await getDefaultExportProfileData(tenantId);
      await updateExportProfile({
        tenantId,
        updateData: { ...profileData[profileType], profileTitle: optionsMap[selected].profileTitle },
        profileType,
        profileId: selected
      });
      await fetch();
      onSave();
    }
  }, [fetch, onSave, optionsMap, profileType, selected, tenantId]);
  const onProfileDeleteCallback = useCallback(async () => {
    if (selected) {
      try {
        await deleteExportProfile({
          tenantId,
          profileType,
          profileId: selected
        });
        await fetch();
      } catch (error) {
        catchAsSnackbar("Failed to delete profile");
      } finally {
        handleCloseProfileDelete();
        onChange("");
      }
    }
  }, [selected, tenantId, profileType, fetch, catchAsSnackbar, handleCloseProfileDelete, onChange]);

  if (!profilesFeatureEnabled) {
    return <></>;
  }

  return (
    <>
      <Box mb={3}>
        <CustomAlert severity="info">{t("export_info")}</CustomAlert>
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <Box flex={1}>
          <MultiAutocomplete
            id={render.toString()}
            label={t("profiles")}
            hasMultiSelect={false}
            options={optionIds}
            selected={selected}
            updateSelected={updateSelectedCallback}
            getOptionLabel={getOptionLabelCallback}
            addText={t("addProfile")}
            updateOptions={updateOptionsCallback}
            error={unsaved}
            helperText={unsaved ? t("unsavedChanges") : ""}
          />
        </Box>

        {unsaved && (
          <Box mx={2}>
            <Tooltip title={t("save")}>
              <Button
                data-testid="accordion_save_button"
                variant="contained"
                color="primary"
                onClick={onProfileSaveCallback}
              >
                {t("save")}
              </Button>
            </Tooltip>
          </Box>
        )}

        {selectedProfileId && (
          <Box ml={2}>
            <Tooltip title={t("common:delete")}>
              <IconButton onClick={handleClickOpenProfileDelete} color="primary">
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <ConfirmationModal
        variant="info"
        modalOpen={openProfileDeletion}
        onClose={handleCloseProfileDelete}
        modalTitle={t("common:information")}
        modalText={t("confirm_delete_profile")}
        buttons={[
          {
            confirmButton: false,
            title: t("cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: handleCloseProfileDelete
          },
          {
            confirmButton: true,
            title: t("common:delete"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: onProfileDeleteCallback
          }
        ]}
      />
    </>
  );
};

interface ProfileTextFieldProps {
  readonly valueName: keyof ProfileData;
  readonly value: string;
  readonly onChange: (key: keyof ProfileData, value: string) => void;
  readonly onBlur: () => void;
}
const ProfileTextField = ({ valueName, value, onChange, onBlur }: ProfileTextFieldProps) => {
  const { t } = useTranslation("processing_activity_export_pdf");
  const { t: tQuestionnaire } = useTranslation("serviceProviderGeneralTab");
  const onChangeCallback = useCallback(
    (event: { target: { value: string } }) => {
      onChange(valueName as keyof ProfileData, event.target.value);
    },
    [valueName, onChange]
  );

  const error = valueName === "email" ? shouldShowEmailInputError(value) : undefined;
  const helperText = valueName === "email" ? shouldShowEmailInputError(value) && t("emailBadFormat") : undefined;
  return (
    <TextField
      id={valueName}
      fullWidth={true}
      label={tQuestionnaire(valueName)}
      variant="outlined"
      value={value}
      onChange={onChangeCallback}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
    />
  );
};

interface GeneralInformationExportProps {
  readonly tenantId: string;
  readonly open: boolean;
  readonly setOpen: (value: boolean) => void;
  readonly exportProcesses: (data: AllProfileData) => void;
  readonly profiles: PROFILE_TYPE[];
}

const GeneralInformationExport = ({
  open,
  setOpen,
  exportProcesses,
  tenantId,
  profiles
}: GeneralInformationExportProps) => {
  /* CONTEXT */
  const { t } = useTranslation("processing_activity_export_pdf");
  const { t: tQuestionnaire } = useTranslation("serviceProviderGeneralTab");
  const { catchAsSnackbar } = useErrorSnackbar();

  /* STATE */
  const [exporting, setExporting] = useState(false);
  const [answers, setAnswers] = useState<AllProfileData>(emptyTenantProfileInformation);
  const [index, setIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<PROFILE_TYPE>(profiles[0] || "dataController");
  const [unsaved, setUnsaved] = useState<boolean>(false);
  const [selectedProfileIds, setSelectedProfileIds] = useState<{
    readonly dataController: string;
    readonly jointController: string;
    readonly controllerRepresentative: string;
    readonly dataProtectionOfficer: string;
    readonly dataProcessor: string;
  }>({
    dataController: "",
    jointController: "",
    controllerRepresentative: "",
    dataProtectionOfficer: "",
    dataProcessor: ""
  });

  /* get default profile data on init */
  const { data: defaultProfileData, isValidating } = useSWR("getDefaultExportProfileData", () =>
    getDefaultExportProfileData(tenantId)
  );
  useEffect(() => {
    if (defaultProfileData && !isValidating) {
      setAnswers(defaultProfileData);
    }
  }, [defaultProfileData, isValidating]);

  /* 
    we save all chnages user do 
    to "default" profile
    "default" - first profile in DB
  */
  const saveDefaultProfile = useCallback(async () => {
    await updateDefaultExportProfile({
      tenantId,
      profileType: currentPage,
      updateData: answers[currentPage]
    });
  }, [answers, currentPage, tenantId]);

  const getProfileTranslation = useCallback(
    (profileType: PROFILE_TYPE) => {
      switch (profileType) {
        case "dataController":
          return t("title_data_controller");
        case "jointController":
          return t("title_joint_controller_export");
        case "controllerRepresentative":
          return t("title_controller_representative");
        case "dataProtectionOfficer":
          return t("title_dpo");
        default:
          return t(`title_${profileType}`, profileType);
      }
    },
    [t]
  );

  const pages: { readonly title: string; readonly name: PROFILE_TYPE }[] = useMemo(
    () => profiles.map(it => ({ title: getProfileTranslation(it), name: it })),
    [getProfileTranslation, profiles]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const updateState = useCallback(
    (key: keyof ProfileData, value: string) => {
      if (answers[currentPage][key] !== value) {
        setAnswers(answers => ({
          ...answers,
          [currentPage]: {
            ...answers[currentPage],
            [key]: value || ""
          }
        }));

        if (selectedProfileIds[currentPage]) {
          setUnsaved(true);
        }
      }
    },
    [answers, currentPage, selectedProfileIds]
  );

  const exportProcessCallback = useCallback(async () => {
    setExporting(true);
    try {
      await exportProcesses(answers);
      setExporting(false);
    } catch (error) {
      setExporting(false);
      throw error;
    }
  }, [answers, exportProcesses]);

  const onPrimaryButtonClick = useCallback(() => {
    if (index < pages.length - 1) {
      const i = index + 1;
      setIndex(i);
      setCurrentPage(pages[i].name);
    }
    if (index === pages.length - 1) {
      exportProcessCallback().catch(catchAsSnackbar("Failed to export"));
    }
    setUnsaved(false);
  }, [index, pages, exportProcessCallback, catchAsSnackbar]);

  const onCancelButtonClick = useCallback(() => {
    if (index > 0) {
      const i = index - 1;
      setIndex(i);
      setCurrentPage(pages[i].name);
      setUnsaved(false);
    }
  }, [index, pages]);

  const onProfileSelectCallback = useCallback(
    async (id: string) => {
      setSelectedProfileIds(selectedProfileIds => ({
        ...selectedProfileIds,
        [currentPage]: id
      }));

      if (id) {
        const profileData = await getExportProfileById({ tenantId, profileType: currentPage, profileId: id });
        setAnswers(answers => ({
          ...answers,
          [currentPage]: profileData
        }));
      } else {
        const profileData = await getDefaultExportProfileData(tenantId);
        setAnswers(answers => ({
          ...answers,
          [currentPage]: profileData[currentPage]
        }));
      }
    },
    [currentPage, tenantId]
  );
  const onProfileSaveCallback = useCallback(() => {
    setUnsaved(false);
  }, []);

  return (
    <Dialog
      title="Loading"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <Box m={4}>
        <Box my={2} textAlign={"center"}>
          <CardTitle text={t("titleExportGeneralInformationModal")} />
        </Box>
        <Box mb={3}>
          <CardTitle text={pages[index].title} />
        </Box>
        <ProfileSelector
          tenantId={tenantId}
          selectedProfileId={selectedProfileIds[currentPage]}
          profileType={currentPage}
          unsaved={unsaved}
          onChange={onProfileSelectCallback}
          onAdd={onProfileSelectCallback}
          onSave={onProfileSaveCallback}
        />
        <Box my={2}>
          <ProfileTextField
            valueName={"name"}
            value={answers[currentPage]?.["name" as keyof ProfileData] || ""}
            onChange={updateState}
            onBlur={saveDefaultProfile}
          />
        </Box>
        <Box my={2}>
          <ProfileTextField
            valueName={"street"}
            value={answers[currentPage]?.["street" as keyof ProfileData] || ""}
            onChange={updateState}
            onBlur={saveDefaultProfile}
          />
        </Box>
        <Box my={2} display={"flex"} width={"100%"}>
          <Box flex={1} mr={1}>
            <ProfileTextField
              valueName={"ZIP"}
              value={answers[currentPage]?.["ZIP" as keyof ProfileData] || ""}
              onChange={updateState}
              onBlur={saveDefaultProfile}
            />
          </Box>
          <Box flex={1} ml={1}>
            <ProfileTextField
              valueName={"city"}
              value={answers[currentPage]?.["city" as keyof ProfileData] || ""}
              onChange={updateState}
              onBlur={saveDefaultProfile}
            />
          </Box>
        </Box>
        <Box my={2} display={"flex"} width={"100%"}>
          <Box flex={1} mr={1}>
            <ProfileTextField
              valueName={"phone"}
              value={answers[currentPage]?.["phone" as keyof ProfileData] || ""}
              onChange={updateState}
              onBlur={saveDefaultProfile}
            />
          </Box>
          <Box flex={1} ml={1}>
            <ProfileTextField
              valueName={"email"}
              value={answers[currentPage]?.["email" as keyof ProfileData] || ""}
              onChange={updateState}
              onBlur={saveDefaultProfile}
            />
          </Box>
        </Box>
        <Box my={2}>
          <ProfileTextField
            valueName={"contactPerson"}
            value={answers[currentPage]?.["contactPerson" as keyof ProfileData] || ""}
            onChange={updateState}
            onBlur={saveDefaultProfile}
          />
        </Box>
      </Box>
      <DialogActions>
        <Box mr={3} mb={1} display={"flex"}>
          {index > 0 && (
            <Button variant="outlined" color="primary" onClick={onCancelButtonClick}>
              {tQuestionnaire("back")}
            </Button>
          )}
          <Box mx={1} />
          <Button variant="contained" color="primary" disabled={exporting} onClick={onPrimaryButtonClick}>
            {exporting && (
              <Box mr={1} sx={{ display: "flex", alignItems: "center" }}>
                <CircularProgress color="inherit" size={14} />
              </Box>
            )}
            {index === pages.length - 1 && t("export")}
            {index < pages.length - 1 && tQuestionnaire("next")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralInformationExport;
