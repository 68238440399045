import OrgunitsPathsAutocomplete from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import React, { useMemo } from "react";
import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";
import { useUserDepartments } from "app/contexts/department-context";

const AffectedOrgUnitQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  textFieldIcon,
  orgUnitIds,
  onChange,
  onFocus
}: QuestionProps) => {
  const { t } = useTranslation();
  const { expandDepartmentIds } = useUserDepartments();
  const documentOrgUnitIdsSet = useMemo(
    () => expandDepartmentIds([...(orgUnitIds || [])]),
    [expandDepartmentIds, orgUnitIds]
  );
  const documentOrgUnitIds = useMemo(() => [...documentOrgUnitIdsSet], [documentOrgUnitIdsSet]);
  const _value = useMemo(() => (Array.isArray(value) ? value[0] : typeof value === "string" ? value : ""), [value]);

  return (
    <OrgunitsPathsAutocomplete
      value={_value}
      onChange={onChange}
      label={questionName || t("filter_criteria:affectedDepartment")}
      disabled={disabled}
      error={error}
      helperText={helperText}
      icon={textFieldIcon}
      onFocus={onFocus}
      documentOrgUnitIds={documentOrgUnitIds}
    />
  );
};

export default AffectedOrgUnitQuestion;
