import React, { useCallback } from "react";
import { Box, Fab, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

export interface AuditAddQuestionButtonProps {
  readonly onClick: () => void;
  readonly disabled: boolean;
}

export const AssessmentAddQuestionButton = ({ onClick, disabled }: AuditAddQuestionButtonProps) => {
  const { t } = useTranslation("assessment_details");
  const onClickCallback = useCallback(
    event => {
      event.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <Box textAlign="center" my={5}>
      <Typography>{t("add_new_question_message")}</Typography>
      <Typography>{t("start_from_existing")}</Typography>
      <Box mt={1} />
      <Tooltip title={t("audit_details:add_new_question_tooltip")} aria-label="add">
        <Fab color="primary" onClick={onClickCallback} disabled={disabled} style={{ width: "42px", height: "42px" }}>
          <AddIcon fontSize="large" />
        </Fab>
      </Tooltip>
    </Box>
  );
};
