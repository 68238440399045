import React from "react";
import { Box, Typography, SvgIconProps } from "@material-ui/core";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";

export type TileProps = {
  icon: React.ReactElement<SvgIconProps>;
  title: string;
  description: string;
  selected: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const TileWrapper = styled(Box)<{ selected: boolean; disabled?: boolean }>(({ theme, selected, disabled }) => ({
  opacity: disabled ? 0.6 : 1,
  border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[600]}`,
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: disabled ? "default" : "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  height: "100%",
  boxSizing: "border-box",
  alignItems: "center",
  transition: "border-color 0.3s",
  "&:hover": {
    borderColor: disabled ? theme.palette.grey[600] : theme.palette.primary.main
  }
}));

const Tile: React.FC<TileProps> = ({ icon, title, description, selected, disabled, onClick }) => {
  const theme = useTheme();
  return (
    <TileWrapper selected={selected} disabled={disabled} onClick={disabled ? undefined : onClick}>
      {React.cloneElement(icon, {
        style: { color: selected ? theme.palette.primary.main : theme.palette.grey[600], width: "24px", height: "24px" }
      })}
      <Typography
        variant="h6"
        gutterBottom
        style={{
          fontFamily: "SuisseIntl",
          fontSize: "14px",
          paddingTop: "8px",
          paddingBottom: "4px",
          color: theme.palette.text.secondary
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ fontFamily: "SuisseIntl", fontSize: "12px", color: theme.palette.text.secondary }}
      >
        {description}
      </Typography>
    </TileWrapper>
  );
};

export default Tile;
