/* tslint:disable */
/* eslint-disable */
/**
 * multilingual service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BatchLoadTranslations200ResponseInner
 */
export interface BatchLoadTranslations200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof BatchLoadTranslations200ResponseInner
     */
    'languageCode': string;
    /**
     * 
     * @type {string}
     * @memberof BatchLoadTranslations200ResponseInner
     */
    'translationKey': string;
    /**
     * 
     * @type {string}
     * @memberof BatchLoadTranslations200ResponseInner
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface BatchLoadTranslationsRequest
 */
export interface BatchLoadTranslationsRequest {
    /**
     * 
     * @type {Array<BatchLoadTranslationsRequestTranslationsInner>}
     * @memberof BatchLoadTranslationsRequest
     */
    'translations'?: Array<BatchLoadTranslationsRequestTranslationsInner>;
}
/**
 * 
 * @export
 * @interface BatchLoadTranslationsRequestTranslationsInner
 */
export interface BatchLoadTranslationsRequestTranslationsInner {
    /**
     * 
     * @type {string}
     * @memberof BatchLoadTranslationsRequestTranslationsInner
     */
    'languageCode': string;
    /**
     * 
     * @type {string}
     * @memberof BatchLoadTranslationsRequestTranslationsInner
     */
    'translationKey': string;
}
/**
 * 
 * @export
 * @interface CreateResourceTranslationRequest
 */
export interface CreateResourceTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateResourceTranslationRequest
     */
    'languageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateResourceTranslationRequest
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface CreateTenantLanguageSupportRequest
 */
export interface CreateTenantLanguageSupportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantLanguageSupportRequest
     */
    'languageCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTenantLanguageSupportRequest
     */
    'isMainLanguage': boolean;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExecutionMode = {
    ValidateOnly: 'validate-only',
    ExecuteOnly: 'execute-only',
    ValidateAndExecute: 'validate-and-execute'
} as const;

export type ExecutionMode = typeof ExecutionMode[keyof typeof ExecutionMode];


/**
 * 
 * @export
 * @interface FindDuplicatesInNamespaceRequest
 */
export interface FindDuplicatesInNamespaceRequest {
    /**
     * 
     * @type {string}
     * @memberof FindDuplicatesInNamespaceRequest
     */
    'excludeTranslationKey'?: string;
    /**
     * Map of language code and translation pairs
     * @type {{ [key: string]: string; }}
     * @memberof FindDuplicatesInNamespaceRequest
     */
    'translations'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface GetTenantLanguages200Response
 */
export interface GetTenantLanguages200Response {
    /**
     * 
     * @type {string}
     * @memberof GetTenantLanguages200Response
     */
    'mainLanguage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTenantLanguages200Response
     */
    'supportedLanguages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface HasuraEvent
 */
export interface HasuraEvent {
    /**
     * 
     * @type {HasuraEventEvent}
     * @memberof HasuraEvent
     */
    'event': HasuraEventEvent;
    /**
     * 
     * @type {string}
     * @memberof HasuraEvent
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof HasuraEvent
     */
    'id': string;
    /**
     * 
     * @type {HasuraEventDeliveryInfo}
     * @memberof HasuraEvent
     */
    'delivery_info': HasuraEventDeliveryInfo;
    /**
     * 
     * @type {HasuraEventTrigger}
     * @memberof HasuraEvent
     */
    'trigger': HasuraEventTrigger;
    /**
     * 
     * @type {HasuraEventTable}
     * @memberof HasuraEvent
     */
    'table': HasuraEventTable;
}
/**
 * 
 * @export
 * @interface HasuraEventDeliveryInfo
 */
export interface HasuraEventDeliveryInfo {
    /**
     * 
     * @type {number}
     * @memberof HasuraEventDeliveryInfo
     */
    'max_retries'?: number;
    /**
     * 
     * @type {number}
     * @memberof HasuraEventDeliveryInfo
     */
    'current_retry'?: number;
}
/**
 * 
 * @export
 * @interface HasuraEventEvent
 */
export interface HasuraEventEvent {
    /**
     * 
     * @type {string}
     * @memberof HasuraEventEvent
     */
    'op'?: string;
    /**
     * 
     * @type {HasuraEventEventData}
     * @memberof HasuraEventEvent
     */
    'data'?: HasuraEventEventData;
    /**
     * 
     * @type {HasuraEventEventTraceContext}
     * @memberof HasuraEventEvent
     */
    'trace_context'?: HasuraEventEventTraceContext;
}
/**
 * 
 * @export
 * @interface HasuraEventEventData
 */
export interface HasuraEventEventData {
    /**
     * 
     * @type {HasuraEventEventDataOld}
     * @memberof HasuraEventEventData
     */
    'old'?: HasuraEventEventDataOld | null;
    /**
     * 
     * @type {HasuraEventEventDataNew}
     * @memberof HasuraEventEventData
     */
    'new'?: HasuraEventEventDataNew;
}
/**
 * 
 * @export
 * @interface HasuraEventEventDataNew
 */
export interface HasuraEventEventDataNew {
    /**
     * 
     * @type {string}
     * @memberof HasuraEventEventDataNew
     */
    'tenant_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HasuraEventEventDataNew
     */
    'company_language'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HasuraEventEventDataNew
     */
    'features'?: Array<string>;
}
/**
 * 
 * @export
 * @interface HasuraEventEventDataOld
 */
export interface HasuraEventEventDataOld {
    /**
     * 
     * @type {string}
     * @memberof HasuraEventEventDataOld
     */
    'tenant_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HasuraEventEventDataOld
     */
    'company_language'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HasuraEventEventDataOld
     */
    'features'?: Array<string>;
}
/**
 * 
 * @export
 * @interface HasuraEventEventTraceContext
 */
export interface HasuraEventEventTraceContext {
    /**
     * 
     * @type {string}
     * @memberof HasuraEventEventTraceContext
     */
    'trace_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HasuraEventEventTraceContext
     */
    'span_id'?: string;
}
/**
 * 
 * @export
 * @interface HasuraEventTable
 */
export interface HasuraEventTable {
    /**
     * 
     * @type {string}
     * @memberof HasuraEventTable
     */
    'schema'?: string;
    /**
     * 
     * @type {string}
     * @memberof HasuraEventTable
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface HasuraEventTrigger
 */
export interface HasuraEventTrigger {
    /**
     * 
     * @type {string}
     * @memberof HasuraEventTrigger
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface InternalCreateTenantLanguageSupportRequest
 */
export interface InternalCreateTenantLanguageSupportRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalCreateTenantLanguageSupportRequest
     */
    'languageCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InternalCreateTenantLanguageSupportRequest
     */
    'isMainLanguage'?: boolean;
}
/**
 * 
 * @export
 * @interface InternalTranslateResourcesRequest
 */
export interface InternalTranslateResourcesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalTranslateResourcesRequest
     */
    'languageCodes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'creatorUID': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'updaterUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface OkResponse
 */
export interface OkResponse {
    /**
     * 
     * @type {string}
     * @memberof OkResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface Translation
 */
export interface Translation {
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    'languageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    'translationKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    'tenantId'?: string;
}
/**
 * 
 * @export
 * @interface TranslationAllOf
 */
export interface TranslationAllOf {
    /**
     * 
     * @type {string}
     * @memberof TranslationAllOf
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationAllOf
     */
    'tenantId'?: string;
}
/**
 * 
 * @export
 * @interface TranslationDTO
 */
export interface TranslationDTO {
    /**
     * 
     * @type {string}
     * @memberof TranslationDTO
     */
    'languageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationDTO
     */
    'translationKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationDTO
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTranslationRequest
 */
export interface UpdateTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTranslationRequest
     */
    'value'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new language support for a tenant
         * @param {CreateTenantLanguageSupportRequest} createTenantLanguageSupportRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantLanguageSupport: async (createTenantLanguageSupportRequest: CreateTenantLanguageSupportRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTenantLanguageSupportRequest' is not null or undefined
            assertParamExists('createTenantLanguageSupport', 'createTenantLanguageSupportRequest', createTenantLanguageSupportRequest)
            const localVarPath = `/api/v1/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTenantLanguageSupportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new language support for a tenant
         * @param {CreateTenantLanguageSupportRequest} createTenantLanguageSupportRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenantLanguageSupport(createTenantLanguageSupportRequest: CreateTenantLanguageSupportRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenantLanguageSupport(createTenantLanguageSupportRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create a new language support for a tenant
         * @param {CreateTenantLanguageSupportRequest} createTenantLanguageSupportRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantLanguageSupport(createTenantLanguageSupportRequest: CreateTenantLanguageSupportRequest, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.createTenantLanguageSupport(createTenantLanguageSupportRequest, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<OkResponse> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create a new language support for a tenant
     * @param {CreateTenantLanguageSupportRequest} createTenantLanguageSupportRequest 
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTenantLanguageSupport(createTenantLanguageSupportRequest: CreateTenantLanguageSupportRequest, wait?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTenantLanguageSupport(createTenantLanguageSupportRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Health check
     * @summary Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalMultilingualServiceApi - axios parameter creator
 * @export
 */
export const InternalMultilingualServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new language support for a tenant (internal)
         * @param {string} tenantId 
         * @param {InternalCreateTenantLanguageSupportRequest} internalCreateTenantLanguageSupportRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTenantLanguageSupport: async (tenantId: string, internalCreateTenantLanguageSupportRequest: InternalCreateTenantLanguageSupportRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('internalCreateTenantLanguageSupport', 'tenantId', tenantId)
            // verify required parameter 'internalCreateTenantLanguageSupportRequest' is not null or undefined
            assertParamExists('internalCreateTenantLanguageSupport', 'internalCreateTenantLanguageSupportRequest', internalCreateTenantLanguageSupportRequest)
            const localVarPath = `/api/v1/internal/tenants/{tenantId}/languages`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCreateTenantLanguageSupportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sync languages of a tenant (internal)
         * @param {HasuraEvent} hasuraEvent 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalSyncTenantLanguages: async (hasuraEvent: HasuraEvent, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hasuraEvent' is not null or undefined
            assertParamExists('internalSyncTenantLanguages', 'hasuraEvent', hasuraEvent)
            const localVarPath = `/api/v1/internal/tenants/sync-languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hasuraEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Translate resources for a tenant (internal)
         * @param {string} tenantId 
         * @param {InternalTranslateResourcesRequest} internalTranslateResourcesRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTranslateResources: async (tenantId: string, internalTranslateResourcesRequest: InternalTranslateResourcesRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('internalTranslateResources', 'tenantId', tenantId)
            // verify required parameter 'internalTranslateResourcesRequest' is not null or undefined
            assertParamExists('internalTranslateResources', 'internalTranslateResourcesRequest', internalTranslateResourcesRequest)
            const localVarPath = `/api/v1/internal/tenants/{tenantId}/translate-resources`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalTranslateResourcesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalMultilingualServiceApi - functional programming interface
 * @export
 */
export const InternalMultilingualServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalMultilingualServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new language support for a tenant (internal)
         * @param {string} tenantId 
         * @param {InternalCreateTenantLanguageSupportRequest} internalCreateTenantLanguageSupportRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateTenantLanguageSupport(tenantId: string, internalCreateTenantLanguageSupportRequest: InternalCreateTenantLanguageSupportRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateTenantLanguageSupport(tenantId, internalCreateTenantLanguageSupportRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sync languages of a tenant (internal)
         * @param {HasuraEvent} hasuraEvent 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalSyncTenantLanguages(hasuraEvent: HasuraEvent, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalSyncTenantLanguages(hasuraEvent, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Translate resources for a tenant (internal)
         * @param {string} tenantId 
         * @param {InternalTranslateResourcesRequest} internalTranslateResourcesRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalTranslateResources(tenantId: string, internalTranslateResourcesRequest: InternalTranslateResourcesRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalTranslateResources(tenantId, internalTranslateResourcesRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalMultilingualServiceApi - factory interface
 * @export
 */
export const InternalMultilingualServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalMultilingualServiceApiFp(configuration)
    return {
        /**
         * Create a new language support for a tenant (internal)
         * @param {string} tenantId 
         * @param {InternalCreateTenantLanguageSupportRequest} internalCreateTenantLanguageSupportRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTenantLanguageSupport(tenantId: string, internalCreateTenantLanguageSupportRequest: InternalCreateTenantLanguageSupportRequest, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.internalCreateTenantLanguageSupport(tenantId, internalCreateTenantLanguageSupportRequest, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Sync languages of a tenant (internal)
         * @param {HasuraEvent} hasuraEvent 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalSyncTenantLanguages(hasuraEvent: HasuraEvent, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.internalSyncTenantLanguages(hasuraEvent, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Translate resources for a tenant (internal)
         * @param {string} tenantId 
         * @param {InternalTranslateResourcesRequest} internalTranslateResourcesRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTranslateResources(tenantId: string, internalTranslateResourcesRequest: InternalTranslateResourcesRequest, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.internalTranslateResources(tenantId, internalTranslateResourcesRequest, wait, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalMultilingualServiceApi - object-oriented interface
 * @export
 * @class InternalMultilingualServiceApi
 * @extends {BaseAPI}
 */
export class InternalMultilingualServiceApi extends BaseAPI {
    /**
     * Create a new language support for a tenant (internal)
     * @param {string} tenantId 
     * @param {InternalCreateTenantLanguageSupportRequest} internalCreateTenantLanguageSupportRequest 
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalMultilingualServiceApi
     */
    public internalCreateTenantLanguageSupport(tenantId: string, internalCreateTenantLanguageSupportRequest: InternalCreateTenantLanguageSupportRequest, wait?: string, options?: AxiosRequestConfig) {
        return InternalMultilingualServiceApiFp(this.configuration).internalCreateTenantLanguageSupport(tenantId, internalCreateTenantLanguageSupportRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sync languages of a tenant (internal)
     * @param {HasuraEvent} hasuraEvent 
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalMultilingualServiceApi
     */
    public internalSyncTenantLanguages(hasuraEvent: HasuraEvent, wait?: string, options?: AxiosRequestConfig) {
        return InternalMultilingualServiceApiFp(this.configuration).internalSyncTenantLanguages(hasuraEvent, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Translate resources for a tenant (internal)
     * @param {string} tenantId 
     * @param {InternalTranslateResourcesRequest} internalTranslateResourcesRequest 
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalMultilingualServiceApi
     */
    public internalTranslateResources(tenantId: string, internalTranslateResourcesRequest: InternalTranslateResourcesRequest, wait?: string, options?: AxiosRequestConfig) {
        return InternalMultilingualServiceApiFp(this.configuration).internalTranslateResources(tenantId, internalTranslateResourcesRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MultilingualServiceApi - axios parameter creator
 * @export
 */
export const MultilingualServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load translations in bulk
         * @param {BatchLoadTranslationsRequest} [batchLoadTranslationsRequest] List of language code and translation key pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLoadTranslations: async (batchLoadTranslationsRequest?: BatchLoadTranslationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batch-load-translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchLoadTranslationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateResourceTranslationRequest} createResourceTranslationRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceTranslation: async (id: string, createResourceTranslationRequest: CreateResourceTranslationRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createResourceTranslation', 'id', id)
            // verify required parameter 'createResourceTranslationRequest' is not null or undefined
            assertParamExists('createResourceTranslation', 'createResourceTranslationRequest', createResourceTranslationRequest)
            const localVarPath = `/api/v1/translation-keys/{id}/translations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createResourceTranslationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all translation duplicates in a namespace
         * @param {string} namespace 
         * @param {FindDuplicatesInNamespaceRequest} [findDuplicatesInNamespaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDuplicatesInNamespace: async (namespace: string, findDuplicatesInNamespaceRequest?: FindDuplicatesInNamespaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('findDuplicatesInNamespace', 'namespace', namespace)
            const localVarPath = `/api/v1/namespaces/{namespace}/duplicates`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findDuplicatesInNamespaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all missing translations of a translation key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissingTranslations: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMissingTranslations', 'id', id)
            const localVarPath = `/api/v1/translation-keys/{id}/missing-translations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all supported languages of a tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantLanguages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all supported translations of a translation key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTranslation', 'id', id)
            const localVarPath = `/api/v1/translation-keys/{id}/translations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all translations for a language, optionally filtered by namespace
         * @param {string} languageCode 
         * @param {string} [namespace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations: async (languageCode: string, namespace?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageCode' is not null or undefined
            assertParamExists('getTranslations', 'languageCode', languageCode)
            const localVarPath = `/api/v1/translations/{languageCode}`
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Translate a translation key from the main language to all supported languages
         * @param {string} id 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateResource: async (id: string, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translateResource', 'id', id)
            const localVarPath = `/api/v1/translation-keys/{id}/translate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the translation of a translation key in a specific language
         * @param {string} id 
         * @param {string} languageCode 
         * @param {UpdateTranslationRequest} updateTranslationRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {ExecutionMode} [mode] The execution mode of the operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTranslation: async (id: string, languageCode: string, updateTranslationRequest: UpdateTranslationRequest, wait?: string, mode?: ExecutionMode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTranslation', 'id', id)
            // verify required parameter 'languageCode' is not null or undefined
            assertParamExists('updateTranslation', 'languageCode', languageCode)
            // verify required parameter 'updateTranslationRequest' is not null or undefined
            assertParamExists('updateTranslation', 'updateTranslationRequest', updateTranslationRequest)
            const localVarPath = `/api/v1/translation-keys/{id}/translations/{languageCode}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "x-caralegal-tenant-id", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTranslationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultilingualServiceApi - functional programming interface
 * @export
 */
export const MultilingualServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultilingualServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Load translations in bulk
         * @param {BatchLoadTranslationsRequest} [batchLoadTranslationsRequest] List of language code and translation key pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLoadTranslations(batchLoadTranslationsRequest?: BatchLoadTranslationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchLoadTranslations200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLoadTranslations(batchLoadTranslationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateResourceTranslationRequest} createResourceTranslationRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResourceTranslation(id: string, createResourceTranslationRequest: CreateResourceTranslationRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResourceTranslation(id, createResourceTranslationRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all translation duplicates in a namespace
         * @param {string} namespace 
         * @param {FindDuplicatesInNamespaceRequest} [findDuplicatesInNamespaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDuplicatesInNamespace(namespace: string, findDuplicatesInNamespaceRequest?: FindDuplicatesInNamespaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDuplicatesInNamespace(namespace, findDuplicatesInNamespaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all missing translations of a translation key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissingTranslations(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMissingTranslations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all supported languages of a tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantLanguages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTenantLanguages200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantLanguages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all supported translations of a translation key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all translations for a language, optionally filtered by namespace
         * @param {string} languageCode 
         * @param {string} [namespace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslations(languageCode: string, namespace?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Translation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslations(languageCode, namespace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Translate a translation key from the main language to all supported languages
         * @param {string} id 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translateResource(id: string, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translateResource(id, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the translation of a translation key in a specific language
         * @param {string} id 
         * @param {string} languageCode 
         * @param {UpdateTranslationRequest} updateTranslationRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {ExecutionMode} [mode] The execution mode of the operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTranslation(id: string, languageCode: string, updateTranslationRequest: UpdateTranslationRequest, wait?: string, mode?: ExecutionMode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTranslation(id, languageCode, updateTranslationRequest, wait, mode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MultilingualServiceApi - factory interface
 * @export
 */
export const MultilingualServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultilingualServiceApiFp(configuration)
    return {
        /**
         * Load translations in bulk
         * @param {BatchLoadTranslationsRequest} [batchLoadTranslationsRequest] List of language code and translation key pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLoadTranslations(batchLoadTranslationsRequest?: BatchLoadTranslationsRequest, options?: any): AxiosPromise<Array<BatchLoadTranslations200ResponseInner>> {
            return localVarFp.batchLoadTranslations(batchLoadTranslationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateResourceTranslationRequest} createResourceTranslationRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceTranslation(id: string, createResourceTranslationRequest: CreateResourceTranslationRequest, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.createResourceTranslation(id, createResourceTranslationRequest, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all translation duplicates in a namespace
         * @param {string} namespace 
         * @param {FindDuplicatesInNamespaceRequest} [findDuplicatesInNamespaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDuplicatesInNamespace(namespace: string, findDuplicatesInNamespaceRequest?: FindDuplicatesInNamespaceRequest, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.findDuplicatesInNamespace(namespace, findDuplicatesInNamespaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all missing translations of a translation key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissingTranslations(id: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getMissingTranslations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all supported languages of a tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantLanguages(options?: any): AxiosPromise<GetTenantLanguages200Response> {
            return localVarFp.getTenantLanguages(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all supported translations of a translation key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslation(id: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getTranslation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all translations for a language, optionally filtered by namespace
         * @param {string} languageCode 
         * @param {string} [namespace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(languageCode: string, namespace?: string, options?: any): AxiosPromise<Array<Translation>> {
            return localVarFp.getTranslations(languageCode, namespace, options).then((request) => request(axios, basePath));
        },
        /**
         * Translate a translation key from the main language to all supported languages
         * @param {string} id 
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateResource(id: string, wait?: string, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.translateResource(id, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the translation of a translation key in a specific language
         * @param {string} id 
         * @param {string} languageCode 
         * @param {UpdateTranslationRequest} updateTranslationRequest 
         * @param {string} [wait] Wait for the command to be projected
         * @param {ExecutionMode} [mode] The execution mode of the operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTranslation(id: string, languageCode: string, updateTranslationRequest: UpdateTranslationRequest, wait?: string, mode?: ExecutionMode, options?: any): AxiosPromise<OkResponse> {
            return localVarFp.updateTranslation(id, languageCode, updateTranslationRequest, wait, mode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultilingualServiceApi - object-oriented interface
 * @export
 * @class MultilingualServiceApi
 * @extends {BaseAPI}
 */
export class MultilingualServiceApi extends BaseAPI {
    /**
     * Load translations in bulk
     * @param {BatchLoadTranslationsRequest} [batchLoadTranslationsRequest] List of language code and translation key pairs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public batchLoadTranslations(batchLoadTranslationsRequest?: BatchLoadTranslationsRequest, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).batchLoadTranslations(batchLoadTranslationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateResourceTranslationRequest} createResourceTranslationRequest 
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public createResourceTranslation(id: string, createResourceTranslationRequest: CreateResourceTranslationRequest, wait?: string, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).createResourceTranslation(id, createResourceTranslationRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all translation duplicates in a namespace
     * @param {string} namespace 
     * @param {FindDuplicatesInNamespaceRequest} [findDuplicatesInNamespaceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public findDuplicatesInNamespace(namespace: string, findDuplicatesInNamespaceRequest?: FindDuplicatesInNamespaceRequest, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).findDuplicatesInNamespace(namespace, findDuplicatesInNamespaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all missing translations of a translation key
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public getMissingTranslations(id: string, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).getMissingTranslations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all supported languages of a tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public getTenantLanguages(options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).getTenantLanguages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all supported translations of a translation key
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public getTranslation(id: string, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).getTranslation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all translations for a language, optionally filtered by namespace
     * @param {string} languageCode 
     * @param {string} [namespace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public getTranslations(languageCode: string, namespace?: string, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).getTranslations(languageCode, namespace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Translate a translation key from the main language to all supported languages
     * @param {string} id 
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public translateResource(id: string, wait?: string, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).translateResource(id, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the translation of a translation key in a specific language
     * @param {string} id 
     * @param {string} languageCode 
     * @param {UpdateTranslationRequest} updateTranslationRequest 
     * @param {string} [wait] Wait for the command to be projected
     * @param {ExecutionMode} [mode] The execution mode of the operation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultilingualServiceApi
     */
    public updateTranslation(id: string, languageCode: string, updateTranslationRequest: UpdateTranslationRequest, wait?: string, mode?: ExecutionMode, options?: AxiosRequestConfig) {
        return MultilingualServiceApiFp(this.configuration).updateTranslation(id, languageCode, updateTranslationRequest, wait, mode, options).then((request) => request(this.axios, this.basePath));
    }
}


