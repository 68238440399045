import React from "react";
import { Typography, Box } from "@material-ui/core";

export default function ServiceProviderSectionHeader({ text }) {
  return (
    <Box mt={3}>
      <Typography variant="h2" component="span">
        <b>{text}</b>
      </Typography>
    </Box>
  );
}
