import ConfirmationModal, {
  ConfirmationModalButtonProps
} from "../../../components/ConfirmationModal/ConfirmationModal";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import {
  deleteDSRPublicLinkApi,
  getDSRPublicLinksApi,
  patchDSRPublicLinkApi,
  postDSRPublicLinkApi,
  PublicLinkDTO
} from "../../api/publicLinkApi";
import { DSRPublicLink } from "./DSRPublicLink";
import useSWR from "swr";
import { SWR_KEYS } from "../../swrKeys";
import { AuthenticatedUserInTenant, useAuthentication } from "../../handlers/authentication/authentication-context";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import useSWRMutation from "swr/mutation";
import { noUppercaseOpenSansButton } from "../../utils/standardStyles";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../features";

export const DSRPublicLinks = () => {
  const { t } = useTranslation();
  const { auth } = useAuthentication();
  const isMultiLinksEnabled = useIsFeaturePresent(FEATURES.PUBLIC_REQUESTS_MULTI_LINKS);

  const { data: dsrPublicLinks, isLoading, isValidating } = useSWR(SWR_KEYS.DSRPublicLinks, getDSRPublicLinksApi);
  const isMaxLinksReached = !isMultiLinksEnabled && (dsrPublicLinks?.links || []).length >= 1;

  const { trigger: createPublicLink, isMutating: isCreating } = useSWRMutation<
    void,
    void,
    string,
    Pick<AuthenticatedUserInTenant, "orgUnitId" | "furtherOrgUnitIds">
  >(SWR_KEYS.DSRPublicLinks, async (_, { arg }) => {
    await postDSRPublicLinkApi({
      orgUnitId: arg.orgUnitId,
      furtherOrgUnitIds: arg.furtherOrgUnitIds
    });
  });

  const { trigger: revokePublicLink } = useSWRMutation<void, void, string, { readonly id: string }>(
    SWR_KEYS.DSRPublicLinks,
    async (_, { arg }) => {
      await deleteDSRPublicLinkApi(arg.id);
    }
  );

  const { trigger: updatePublicLink } = useSWRMutation<
    void,
    void,
    string,
    Partial<Omit<PublicLinkDTO, "id">> & Pick<PublicLinkDTO, "id">
  >(SWR_KEYS.DSRPublicLinks, async (_, { arg }) => {
    await patchDSRPublicLinkApi(arg.id, {
      description: arg.description,
      email: arg.email,
      orgUnitId: arg.orgUnitId
    });
  });

  const onCreate = useCallback(async () => {
    if (!auth?.orgUnitId) {
      return;
    }

    return createPublicLink({
      orgUnitId: auth.orgUnitId,
      furtherOrgUnitIds: auth.furtherOrgUnitIds
    });
  }, [auth?.orgUnitId, auth?.furtherOrgUnitIds, createPublicLink]);

  return (
    <Box>
      <Box mb={1} display="flex" alignItems="center" gridGap={8}>
        <Typography variant="subtitle2" style={{ fontWeight: 600 }} component="span">
          {t(`data_subject_requests_overview:public_link`)}
        </Typography>
        {(isLoading || isValidating) && <CircularProgress size={14} />}
      </Box>
      <Box>
        {dsrPublicLinks &&
          dsrPublicLinks.links.map(dsrPublicLink => (
            <Box mb={1} key={dsrPublicLink.id}>
              <DSRPublicLink dsrPublicLink={dsrPublicLink} onRevoke={revokePublicLink} onUpdate={updatePublicLink} />
            </Box>
          ))}
      </Box>
      <Box display="flex" alignItems="center" gridGap={8}>
        <Tooltip
          title={
            isMaxLinksReached
              ? t("data_subject_requests_overview:publicLink_add_new_tooltip_maxplan")
              : t("data_subject_requests_overview:publicLink_add_new_tooltip")
          }
        >
          <Box>
            <Button
              onClick={onCreate}
              color="primary"
              startIcon={<AddIcon />}
              style={noUppercaseOpenSansButton}
              disabled={isCreating || isMaxLinksReached}
            >
              {t("data_subject_requests_overview:publicLink_add_new")}
            </Button>
          </Box>
        </Tooltip>
        {isCreating && <CircularProgress size={14} />}
      </Box>
    </Box>
  );
};

export const DSRPublicLinksModal = ({ open, onClose }: { readonly open: boolean; readonly onClose: () => void }) => {
  const { t } = useTranslation();
  const { data: dsrPublicLinks } = useSWR(SWR_KEYS.DSRPublicLinks, getDSRPublicLinksApi);
  const doesAllLinksHaveEmail = useMemo(() => {
    return dsrPublicLinks?.links.every(link => !!link.email);
  }, [dsrPublicLinks]);
  const buttons = useMemo<ConfirmationModalButtonProps[]>(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: onClose
      } satisfies ConfirmationModalButtonProps,
      {
        confirmButton: true,
        title: t("filter_criteria:done"),
        variant: "contained",
        color: "primary",
        size: "medium",
        disabled: !doesAllLinksHaveEmail,
        onClick: onClose
      } satisfies ConfirmationModalButtonProps
    ],
    [t, onClose, doesAllLinksHaveEmail]
  );
  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onClose}
      variant={"info"}
      modalTitle={t("data_subject_requests_overview:publicLink_modal_title")}
      modalText={t("data_subject_requests_overview:publicLink_modal_text")}
      modalNote={t("data_subject_requests_overview:publicLink_modal_note")}
      modalBody={<DSRPublicLinks />}
      buttons={buttons}
    />
  );
};
