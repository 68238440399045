import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Box, CardContent, Checkbox, Fade, Popper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

export interface FilterItem {
  readonly id: string;
  readonly title: string;
  readonly count: number;
  readonly childFilters: FilterItem[];
}

export interface FilterTree {
  readonly items: FilterItem[];
}

export const FilterSelector = ({
  filterTree,
  selectedFilterIds,
  onSelectedFilterIdsChanged
}: {
  filterTree: FilterTree;
  selectedFilterIds: string[];
  onSelectedFilterIdsChanged: (setter: (input: string[]) => string[]) => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(
    event => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );
  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    setOpen(!!anchorEl);
  }, [anchorEl]);

  const onCheckboxChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filterId = event.target.name;
      const checked = event.target.checked;
      if (!checked) {
        onSelectedFilterIdsChanged(selectedFilterIds => selectedFilterIds.filter(id => id !== filterId));
      } else {
        onSelectedFilterIdsChanged(selectedFilterIds => [...selectedFilterIds, filterId]);
      }
    },
    [onSelectedFilterIdsChanged]
  );

  const onReset = useCallback(() => {
    onSelectedFilterIdsChanged(() => []);
    handleClickAway();
  }, [onSelectedFilterIdsChanged, handleClickAway]);

  const filterActive = !!selectedFilterIds.length;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <IconButton onClick={handleClick} color={filterActive ? "primary" : "inherit"}>
          <FilterListIcon />
        </IconButton>
        <Popper
          id={open ? "transitions-popper" : undefined}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box sx={{ width: 300 }}>
                <Card>
                  <CardContent>
                    {filterActive && (
                      <Box mb={2}>
                        <Box
                          sx={{
                            fontWeight: "bold"
                          }}
                        >
                          <Button fullWidth={true} onClick={onReset} color="primary">
                            {t("filter_criteria:undoFilter")}
                          </Button>
                        </Box>
                        <Divider />
                      </Box>
                    )}
                    <Box mb={1}>
                      <Typography variant="h5" component="p">
                        {t("filter_criteria:filter")}
                      </Typography>
                    </Box>
                    {filterTree.items.map(item => (
                      <FilterItemComponent
                        item={item}
                        key={item.title}
                        checkedIds={selectedFilterIds}
                        onChange={onCheckboxChanged}
                      />
                    ))}
                  </CardContent>
                </Card>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

const FilterItemComponent = ({
  item,
  checkedIds,
  onChange
}: {
  item: FilterItem;
  checkedIds: string[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const haveChildren = !!item.childFilters.length;
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = useCallback(() => setExpanded(expanded => !expanded), []);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        {!haveChildren && (
          <Checkbox color="primary" checked={checkedIds.includes(item.id)} onChange={onChange} name={item.id} />
        )}
        {haveChildren && (
          <IconButton onClick={toggleExpand}>{expanded ? <NavigateNextIcon /> : <ExpandMore />}</IconButton>
        )}
        <Typography component="p">
          {item.title}
          {item.count ? ` (${item.count})` : ""}
        </Typography>
      </Box>
      {haveChildren && expanded && (
        <Fade timeout={350} in={true}>
          <Box ml={4}>
            {item.childFilters.map(childFilter => (
              <FilterItemComponent
                key={`${item.id}::${childFilter.id}`}
                item={childFilter}
                checkedIds={checkedIds}
                onChange={onChange}
              />
            ))}
          </Box>
        </Fade>
      )}
    </>
  );
};
