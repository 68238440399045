export type AUDIT_STATUS_TYPES = "draft" | "edit" | "review" | "completed";
export const AUDIT_STATUS = {
  DRAFT: "draft",
  EDIT: "edit",
  REVIEW: "review",
  COMPLETED: "completed"
} as const satisfies Record<string, AUDIT_STATUS_TYPES>;

export type AUDIT_METHODOLOGY_TYPES = "interview" | "self-assessment";
export const AUDIT_METHODOLOGY = {
  INTERVIEW: "interview",
  SELF_ASSESSMENT: "self-assessment"
} as const satisfies Record<string, AUDIT_METHODOLOGY_TYPES>;

export type AUDIT_PAGE_TYPES =
  | "general"
  | "questionnaire"
  | "result"
  | "responses"
  | "summary"
  | "evaluation"
  | "report";
export const AUDIT_PAGE = {
  GENERAL: "general",
  QUESTIONNAIRE: "questionnaire",
  RESULT: "result",
  RESPONSES: "responses",
  SUMMARY: "summary",
  EVALUATION: "evaluation",
  REPORT: "report"
} as const satisfies Record<string, AUDIT_PAGE_TYPES>;

export type AUDIT_TEMPLATE_STATUS_TYPES = "active" | "inactive";
export const AUDIT_TEMPLATE_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive"
} as const satisfies Record<string, AUDIT_TEMPLATE_STATUS_TYPES>;

export type AUDIT_REPORT_PERMISSION_TYPES = "read" | "write";
export const AUDIT_REPORT_PERMISSION = {
  READ: "read",
  WRITE: "write"
} as const satisfies Record<string, AUDIT_REPORT_PERMISSION_TYPES>;

export type ANSWERSET_STATUS_TYPES = "pending" | "inprogress" | "completed";
export const ANSWERSET_STATUS = {
  PENDING: "pending",
  INPROGRESS: "inprogress",
  COMPLETED: "completed"
} as const satisfies Record<string, ANSWERSET_STATUS_TYPES>;
