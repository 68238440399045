import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { withAbortController } from "../api/axios/axiosErrorHandler";
import { createRole, deleteRole, getRoles, updateRole } from "../handlers/newRoleHandler";
import { RoleDTO, SingleNewRole } from "app/api/roleApi";
import { NotLoggedInError } from "../handlers/authentication/authenticationError";
import { COLLECTION_TYPES } from "app/collections";

export interface RoleContextValue {
  readonly rolesLoaded: boolean;
  readonly roles: RoleDTO[];
  createRoleHook: (input: SingleNewRole) => Promise<string>;
  updateRoleHook: (id: string, input: SingleNewRole) => Promise<void>;
  deleteRoleHook: (id: string) => Promise<void>;
  getRoleHook: (id: string) => Promise<RoleDTO | null>;
  getRoleNameHook: (id: string) => string;
  getAllRolesWithReadPermission: (collection: COLLECTION_TYPES) => RoleDTO[];
  readonly reloadRoles: () => void;
}

export const RoleContext = createContext<RoleContextValue>({
  roles: [],
  rolesLoaded: false,
  getRoleNameHook: id => "",
  createRoleHook: async () => {
    throw new Error("Not implemented");
  },
  updateRoleHook: async () => {
    throw new Error("Not implemented");
  },
  deleteRoleHook: async () => {
    throw new Error("Not implemented");
  },
  getRoleHook: async () => {
    throw new Error("Not implemented");
  },
  reloadRoles: () => {
    throw new Error("Not implemented");
  },
  getAllRolesWithReadPermission: () => {
    throw new Error("Not implemented");
  }
});

export const defaultRolesWithoutGlobal = [
  "readOnly",
  "basicReduced",
  "basic",
  "basicNoPADelete",
  "basicPlus",
  "orgUnitExpert",
  "orgUnitAdmin",
  "orgUnitDPO"
];

export const defaultExpertRolesWithoutGlobal = ["orgUnitExpert", "orgUnitAdmin", "orgUnitDPO"];

export const RoleProvider = ({ children }: { children: React.ReactNode }) => {
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [roles, setRoles] = useState<RoleDTO[]>([]);
  const { auth } = useAuthentication();
  const [abortReloadRoleFn, setAbortReloadRoleFn] = useState<() => void>(() => () => {
    /* empty */
  });
  const reloadRoles = useCallback(() => {
    setAbortReloadRoleFn(lastAbortReloadRole => {
      lastAbortReloadRole();
      return withAbortController({
        executeFn: abortController => getRoles({ abortController }).then(setRoles)
      });
    });
    setRolesLoaded(true);
  }, []);

  const withReloadRoles = useCallback(
    async (changerFn: () => Promise<any>) => {
      setRolesLoaded(false);
      const result = await changerFn().catch((error: any) => {
        setRolesLoaded(true);
        throw error;
      });
      reloadRoles();
      return result;
    },
    [setRolesLoaded, reloadRoles]
  );

  const getRoleNameHook = useCallback(
    (id: string) => {
      const roleData = roles.find(role => role.id === id) || null;
      return roleData?.name ?? "";
    },
    [roles]
  );

  useEffect(() => {
    if (auth?.uid) reloadRoles();
  }, [auth?.uid, reloadRoles]);

  const getRoleHook = useCallback(
    async (id: string) => {
      return roles.find(role => role.id === id) || null;
    },
    [roles]
  );

  const createRoleHook = useCallback(
    async (input: SingleNewRole) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadRoles(() => createRole(auth.tenantId, input));
    },
    [withReloadRoles, auth?.tenantId]
  );

  const updateRoleHook = useCallback(
    (id: string, input: SingleNewRole) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadRoles(() => updateRole(auth.tenantId, id, input));
    },
    [withReloadRoles, auth?.tenantId]
  );
  const deleteRoleHook = useCallback(
    (id: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadRoles(() => deleteRole(auth.tenantId, id));
    },
    [withReloadRoles, auth?.tenantId]
  );

  const getAllRolesWithReadPermission = useCallback(
    (collection: COLLECTION_TYPES) => {
      switch (collection) {
        case "audits":
          return roles.filter(role => role.permissions?.includes("audit_read_org"));
        default:
          return roles;
      }
    },
    [roles]
  );

  const [contextValue, setContextValue] = useState<RoleContextValue>({
    rolesLoaded,
    roles,
    createRoleHook,
    updateRoleHook,
    deleteRoleHook,
    getRoleHook,
    getRoleNameHook,
    getAllRolesWithReadPermission,
    reloadRoles
  });
  useEffect(() => {
    setContextValue({
      rolesLoaded,
      roles,
      createRoleHook,
      updateRoleHook,
      deleteRoleHook,
      getRoleHook,
      getRoleNameHook,
      getAllRolesWithReadPermission,
      reloadRoles
    });
  }, [
    rolesLoaded,
    roles,
    createRoleHook,
    updateRoleHook,
    deleteRoleHook,
    getRoleHook,
    getRoleNameHook,
    reloadRoles,
    getAllRolesWithReadPermission
  ]);

  return <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>;
};

export const useUserRoles = () => useContext(RoleContext);
