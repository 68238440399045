import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HasFeatureToggleOn, HasPageAccess, HasUserPermission, ShowPageIf } from "app/router/router-filters";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { FEATURES, USER_FEATURE_IDS } from "app/features";
import { useNavigate } from "react-router-dom";
import { usePathName } from "app/router/router-custom-hooks";
import { Badge, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import FolderIcon from "@mui/icons-material/Folder";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RiskIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ViewListIcon from "@mui/icons-material/ViewList";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import WebIcon from "@mui/icons-material/Web";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";

import Administration from "./Administration";
import Help from "./Help";
import Language from "./Language";
import LogoSidebar from "./LogoSidebar";
import LogoSidebarCustom from "./LogoSidebarCustom";
import TenantSidebar from "./TenantSidebar";
import UserAvatarSidebar from "./UserAvatarSidebar";

import { COLLECTIONS } from "app/collections";
import { useAvailableLanguages } from "hook/useAvailableLanguages";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { useSidebarUnseen } from "./useSidebarUnseen";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";

import PrivacyTipIconBlue from "assets/images/icons/privacyTipBlue.svg";
import PrivacyTipIconGrey from "assets/images/icons/privacyTipGrey.svg";
import TaskIconBlue from "assets/images/icons/taskBlue.svg";
import TaskIconGrey from "assets/images/icons/taskGrey.svg";

import { sidebarZIndex } from "./sidebarZIndex";
import { getFileAsBlob } from "app/api/file-storage/attachmentsApi";
import { paReadPermissions } from "../../../handlers/permissionHandler";

const drawerWidthClosed = 70;
const drawerWidth = 300;
const BoxShadow = "0px 0px 40px 0px rgb(204 204 204 / 50%)";
const BorderRadius = "5px";

export default function SidebarWrapper() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const transition = useMemo(
    () =>
      theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
      }),
    [open, theme.transitions]
  );

  return (
    <Drawer
      open={true}
      sx={{
        border: 0,
        borderBottomLeftRadius: 0,
        borderRadius: BorderRadius,
        borderTopLeftRadius: 0,
        boxShadow: BoxShadow,
        flexShrink: 0,
        height: "calc(100% - 48px)",
        overflow: "unset",
        position: "fixed",
        top: "24px",
        whiteSpace: "nowrap",
        zIndex: sidebarZIndex,
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          maxWidth: drawerWidth,
          overflowX: "hidden",
          overflowY: open ? "auto" : "hidden",
          transition: transition,
          width: open ? drawerWidth : drawerWidthClosed
        }
      }}
      variant="permanent"
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
    >
      <Sidebar sidebarOpen={open} onSidebarClose={handleDrawerClose} />
    </Drawer>
  );
}

const Sidebar = function ({
  sidebarOpen,
  onSidebarClose
}: {
  readonly sidebarOpen: boolean;
  readonly onSidebarClose: () => void;
}) {
  const { auth, user } = useAuthentication();
  const theme = useTheme();
  const { tenantData, isExternalUser } = useUserAndTenantData();
  const { t, i18n } = useTranslation("sidebar");
  const { availableLanguages, isLoadedFromTenantData: availableLanguagesLoadedFromTenant } = useAvailableLanguages();
  const [lng, setLng] = useState("");

  const { sidebarNewItems } = useSidebarUnseen();

  const path = usePathName();

  // set language to previously selected or default value
  useEffect(() => {
    if (!tenantData?.features) {
      return;
    }
    if (!availableLanguagesLoadedFromTenant) {
      return;
    }

    const currentLanguage = i18n.language;
    if (!availableLanguages.includes(currentLanguage)) {
      const fallbackLanguage = tenantData.companyLanguage || "en";
      setLng(fallbackLanguage);
      i18n.changeLanguage(fallbackLanguage);
      return;
    }

    setLng(currentLanguage);
  }, [availableLanguagesLoadedFromTenant, tenantData?.features, availableLanguages, i18n, tenantData?.companyLanguage]);

  const onSubSideBarClicked = useCallback(() => {
    onSidebarClose?.();
  }, [onSidebarClose]);

  const allowDataSubjectRequestsSubmission = useCallback(
    pageAccess => {
      return !pageAccess.includes("data_subject_requests") && !isExternalUser(user?.id || "");
    },
    [isExternalUser, user?.id]
  );

  const onShowTrainingClick = useCallback(() => {
    window.open("https://caralegal.lawpilots.com/de/KQZ6-Z86A/");
  }, []);

  const { unseenCount } = useDataTypeTree();

  const tenantId = useMemo(() => auth?.tenantId || "", [auth]);
  const tenantName = useMemo(() => tenantData?.name || "", [tenantData]);

  const [hasCustomLogo, setHasCustomLogo] = useState(false);
  const [customLogoUrl, setCustomLogoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!tenantId) return;
    getFileAsBlob({ folderName: "brand", fileName: "logo.png" }).then(logo => {
      if (logo) {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(logo);
        setCustomLogoUrl(imageUrl);
        setHasCustomLogo(true);
      } else {
        setHasCustomLogo(false);
      }
    });
  }, [tenantId]);

  const userHasProcessorPermissionButNoPA =
    auth?.permissions.includes("processor_pa_read_org") && !auth?.permissions.some(p => paReadPermissions.includes(p));

  return (
    <List>
      {hasCustomLogo ? (
        <LogoSidebarCustom tenantName={tenantName} customLogoUrl={customLogoUrl} open={sidebarOpen} />
      ) : (
        <LogoSidebar open={sidebarOpen} />
      )}
      <TenantSidebar open={sidebarOpen} />
      <UserAvatarSidebar userData={user} />
      <HasPageAccess requiredPageAccess={["dashboard"]} returnNothing={true}>
        <NavigateItem targetPath={"/dashboard"} label={t("dashboard")}>
          <DashboardIcon />
        </NavigateItem>
      </HasPageAccess>
      <HasPageAccess requiredPageAccess={["tasks"]} returnNothing={true}>
        <NavigateItem targetPath={"/tasks/my"} label={t("tasks")} badgeContent={sidebarNewItems[COLLECTIONS.TASKS]}>
          {path.startsWith("/task") ? <TaskIconBlue /> : <TaskIconGrey />}
        </NavigateItem>
      </HasPageAccess>

      <NavigateItem
        targetPath={"/notifications"}
        label={t("notifications")}
        badgeContent={sidebarNewItems[COLLECTIONS.NOTIFICATIONS]}
      >
        <NotificationsIcon />
      </NavigateItem>
      <HasPageAccess requiredPageAccess={["processes"]} returnNothing={true}>
        <NavigateItem
          targetPath={userHasProcessorPermissionButNoPA ? "/processor-pas" : "/processes"}
          label={t("process_overview")}
        >
          <InsertDriveFileIcon />
        </NavigateItem>
      </HasPageAccess>

      <HasPageAccess requiredPageAccess={["impact_assessment"]} returnNothing={true}>
        <NavigateItem targetPath={"/dpias"} label={t("risk_assessment")}>
          <VerifiedUserIcon />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.MEASURES_FEATURE} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["tom"]} returnNothing={true}>
          <NavigateItem targetPath={"/toms/general"} label={t("data_security")}>
            <SecurityIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.RISKS} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["risks"]} returnNothing={true}>
          <NavigateItem targetPath={"/risks/general"} label={t("risks")}>
            <RiskIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.AUDIT} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["audits"]} returnNothing={true}>
          <NavigateItem targetPath={"/audits/instances"} label={t("audits")}>
            <AssignmentTurnedInIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DATA_BREACHES} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["data_breaches"]} returnNothing={true}>
          <NavigateItem targetPath={"/data-breaches"} label={t("data_breaches")}>
            {path.startsWith("/data-breaches") ? <PrivacyTipIconBlue /> : <PrivacyTipIconGrey />}
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DATA_SUBJECT_REQUEST} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["data_subject_requests"]} returnNothing={true}>
          <NavigateItem targetPath={"/data-subject-requests"} label={t("requests")}>
            <EmojiPeopleIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DSR_BASIC_SUBMISSION} navigateAway={false}>
        <ShowPageIf pageAccessCondition={allowDataSubjectRequestsSubmission}>
          <NavigateItem targetPath={"/data-subject-requests-submission"} label={t("requests_submission")}>
            <EmojiPeopleIcon />
          </NavigateItem>
        </ShowPageIf>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["resources"]} returnNothing={true}>
        <NavigateItem
          targetPath={"/resources"}
          label={t("resources")}
          badgeContent={
            [COLLECTIONS.RESOURCES, COLLECTIONS.DATA_LOCATIONS].reduce(
              (count, sidebarItemName) => count + sidebarNewItems[sidebarItemName] || 0,
              0
            ) + unseenCount
          }
        >
          <ViewListIcon />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.DELETION_CONCEPT} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["deletion_concept"]} returnNothing={true}>
          <NavigateItem targetPath={"/deletion-concept"} label={t("deletion_concept")}>
            <DeleteSweepIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>

      <HasFeatureToggleOn feature={FEATURES.ASSETS} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["assets"]} returnNothing={true}>
          <NavigateItem
            targetPath={"/asset-management"}
            label={t("assets_overview:header")}
            badgeContent={sidebarNewItems[COLLECTIONS.ASSETS]}
          >
            <WebAssetIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["service_providers"]} returnNothing={true}>
        <NavigateItem
          targetPath={"/external-recipients"}
          label={t("service_providers")}
          badgeContent={sidebarNewItems[COLLECTIONS.EXTERNAL_RECIPIENTS]}
        >
          <HomeWorkIcon />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.AI_TECH_DOCS} navigateAway={false}>
        <HasUserPermission
          requiredPermission={"aitechdoc_read_org"}
          returnNothing={true}
          alwaysShow={!!sidebarNewItems[COLLECTIONS.AI_TECH_DOCS]?.totalCount}
        >
          <NavigateItem
            targetPath={"/ai-tech-docs"}
            label={t("ai-tech-docs")}
            badgeContent={sidebarNewItems[COLLECTIONS.AI_TECH_DOCS]?.unseenCount}
          >
            <BuildCircleOutlinedIcon />
          </NavigateItem>
        </HasUserPermission>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={USER_FEATURE_IDS.WEBSITES} navigateAway={false}>
        <NavigateItem targetPath={"/websites"} label={t("websites")}>
          <WebIcon />
        </NavigateItem>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.LAWPILOT_TRAINING} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["training"]} returnNothing={true}>
          <ListItemButton
            onClick={onShowTrainingClick}
            sx={{
              marginLeft: "6px",
              "& .MuiBadge-colorError": {
                border: "2px solid",
                borderRadius: "16px",
                color: "#ffffff",
                fontSize: "11px",
                height: "auto",
                padding: "4px 6px",
                textAlign: "center"
              }
            }}
          >
            <ListItemIcon
              sx={{
                color: theme.palette.grey[500]
              }}
            >
              <CastForEducationIcon />
            </ListItemIcon>
            <ListItemText primary={t("training")} />
          </ListItemButton>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["document_center"]} returnNothing={true}>
        <NavigateItem targetPath={"/document-center"} label={t("documents")}>
          <FolderIcon />
        </NavigateItem>
      </HasPageAccess>
      <Divider />
      <Administration sidebarState={sidebarOpen} onClicked={onSubSideBarClicked} />
      <Help sidebarOpen={sidebarOpen} language={lng} onClicked={onSubSideBarClicked} />
      <Divider />
      <Language language={lng} sidebarState={sidebarOpen} onClicked={onSubSideBarClicked} />
    </List>
  );
};

const NavigateItem = ({
  targetPath,
  label,
  badgeContent,
  children
}: {
  readonly targetPath: string;
  readonly label: string;
  readonly badgeContent?: number;
  readonly children: React.ReactNode;
}) => {
  const theme = useTheme();
  const path = usePathName();
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate(targetPath);
  }, [navigate, targetPath]);

  const isOnPage = useMemo(() => {
    const getFirstPath = (input: string) => input.split("/").filter(it => it)[0];
    return getFirstPath(path) === getFirstPath(targetPath);
  }, [path, targetPath]);

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        backgroundColor: isOnPage ? theme.palette.blue[50] : "transparent"
      }}
    >
      <ListItemIcon
        sx={{
          color: isOnPage ? theme.palette.primary.main : theme.palette.grey[500],
          marginLeft: "6px",
          "& .MuiBadge-colorError": {
            color: "#ffffff",
            border: "2px solid",
            fontSize: "11px",
            padding: "4px 6px",
            textAlign: "center",
            height: "auto",
            borderRadius: "16px"
          }
        }}
      >
        <Badge badgeContent={badgeContent} color="error" overlap="rectangular">
          {children}
        </Badge>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};
