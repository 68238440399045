import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { Box, Button, Typography } from "@material-ui/core";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { useSnackbar } from "notistack";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { useMetaView } from "app/contexts/meta-view-context";

export default function AccountSettingsProfile() {
  const { t } = useTranslation("accountSettingsProfile");
  const { user, auth, loading: authenticationLoading, reloadUserHook } = useAuthentication();
  const { setInfo } = useMetaView();
  const { updateMyNameHook } = useUserAndTenantData();
  const { enqueueSnackbar } = useSnackbar();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [changesExist, setChangesExist] = useState(false);

  const infoCard = useMemo(() => {
    return {
      entering: {
        title: t("accountSettingsProfile:infoCardEnterTitle"),
        text: t("accountSettingsProfile:infoCardEnterBody")
      },
      firstName: {
        title: t("accountSettingsProfile:infoCardFirstNameTitle"),
        text: t("accountSettingsProfile:infoCardFirstNameBody")
      },
      lastName: {
        title: t("accountSettingsProfile:infoCardLastNameTitle"),
        text: t("accountSettingsProfile:infoCardLastNameBody")
      }
    };
  }, [t]);

  const showEnteringInfoCard = useCallback(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  useEffect(() => {
    if (!user?.firstName) {
      return;
    }

    setFirstName(user.firstName);
    setLastName(user.lastName);
  }, [user?.firstName, user?.lastName]);

  useEffect(() => {
    setChangesExist(firstName !== user?.firstName || lastName !== user?.lastName);
  }, [firstName, lastName, user?.firstName, user?.lastName]);

  useEffect(() => {
    return showEnteringInfoCard();
  }, [showEnteringInfoCard]);

  const onFirstNameChangeCallback = useCallback(value => setFirstName(value || ""), [setFirstName]);
  const onLastNameChangeCallback = useCallback(value => setLastName(value || ""), []);
  const onUpdateNameCallback = useCallback(async () => {
    setLoading(true);
    try {
      await updateMyNameHook(firstName, lastName);
      await reloadUserHook();
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
    } catch (error) {
      setLoading(false);
      catchAsSnackbar("failed to update names")(error);
    }
    setLoading(false);
  }, [updateMyNameHook, firstName, lastName, reloadUserHook, enqueueSnackbar, t, catchAsSnackbar]);

  return (
    <Box width={"50%"}>
      <Typography variant="h2">{t("title")}</Typography>
      <Question
        qType={QUESTION_TYPE.TEXT_AREA}
        value={firstName}
        questionName={t("firstName")}
        onChange={onFirstNameChangeCallback}
        onBlur={showEnteringInfoCard}
        info={infoCard.firstName}
      />
      <Question
        qType={QUESTION_TYPE.TEXT_AREA}
        value={lastName}
        questionName={t("lastName")}
        onChange={onLastNameChangeCallback}
        onBlur={showEnteringInfoCard}
        info={infoCard.lastName}
      />
      <Question qType={QUESTION_TYPE.TEXT_AREA} disabled={true} value={user?.email || ""} questionName={t("email")} />
      <Question
        qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
        disabled={true}
        value={auth?.orgUnitId || ""}
        questionName={t("department")}
      />
      <Question
        qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
        disabled={true}
        value={auth?.furtherOrgUnitIds || []}
        questionName={t("furtherDepartments")}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!changesExist || loading || authenticationLoading}
        onClick={onUpdateNameCallback}
      >
        {t("save")}
      </Button>
    </Box>
  );
}
