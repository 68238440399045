import { Box } from "@material-ui/core";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetProcessorApi, useUpdateProcessorApi } from "../../../api/processorPAApi";
import { useProcessorPAEditConfirmation } from "../ProcessorPAEditConfirmation";
import { useParams } from "react-router-dom";
import { useMetaView } from "../../../contexts/meta-view-context";
import { PatchProcessorPADTO } from "../../../api/generated/process-service";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import Question from "../../../../components/Question/Question";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { TOMOptionDTO } from "../../../api/riskApi";
import { useSnackbar } from "notistack";
import { MeasuresQuestionAdditionalData } from "../../../../components/Question/types/resources/MeasuresQuestion";
import { FollowUpTask } from "app/pages/tasks/FollowUpTask";
import { COLLECTIONS } from "app/collections";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

export const ProcessorMeasure = ({ readonly }: { readonly?: boolean }) => {
  const { id } = useParams();
  const { t } = useTranslation("processor_pa_page");
  const { data } = useGetProcessorApi({ documentId: id || "" });
  const { updateProcessor } = useUpdateProcessorApi({ documentId: id || "" });
  const { enqueueSnackbar } = useSnackbar();
  const { queueUpdates } = useUpdateQueues<PatchProcessorPADTO, PatchProcessorPADTO>({
    triggerUpdate: updateProcessor
  });
  const { beforeProcessUpdate } = useProcessorPAEditConfirmation();
  const { auth } = useAuthentication();

  const [measureIds, setMeasureIds] = useState<string[]>([]);

  useEffect(() => {
    if (data?.processorPA) {
      setMeasureIds(data.processorPA.measureIds || []);
    }
  }, [data?.processorPA]);

  const onMeasureIdsChanged = useCallback(
    async (measureIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setMeasureIds(measureIds);
        return await queueUpdates({ measureIds });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const infoCard = useMemo(
    () => ({
      enteringPA: {
        title: t("enteringInfoCardTitle", ""),
        text: t("enteringInfoCardText", "")
      },
      measureIds: {
        title: t("measureIds-title", ""),
        text: t("measureIds-info", "")
      }
    }),
    [t]
  );

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo(infoCard.enteringPA);
  }, [setInfo, infoCard.enteringPA]);

  const mesOptions = useMemo<TOMOptionDTO[]>(() => {
    return (data?.mes || []).map(mes => {
      return {
        id: mes.id,
        name: mes.name,
        description: mes.description || "",
        processSpecific: !!mes.processSpecific,
        protectionObjectiveIds: mes.protectionObjectiveIds || []
      } satisfies TOMOptionDTO;
    });
  }, [data?.mes]);

  /* IMPORT MEASURES FROM EXISTING PA */
  const onMeasureIdsImported = useCallback(
    async (importedMeasureIds: string[]) => {
      try {
        await onMeasureIdsChanged([...measureIds, ...importedMeasureIds]);
        enqueueSnackbar(t("importMeasuresMessageSuccess"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar(t("importMeasuresMessageError"), { variant: "error" });
      }
    },
    [enqueueSnackbar, measureIds, onMeasureIdsChanged, t]
  );
  const suggestionMeasureIds = useMemo(() => {
    return (data?.processorPA?.suggestions?.measureIds || []).filter(id => {
      return !measureIds.includes(id);
    });
  }, [data?.processorPA?.suggestions?.measureIds, measureIds]);

  const additionalData = useMemo(() => {
    return {
      mainOrgUnitIdOnCopy: data?.processorPA?.processorOrgUnitId || undefined
    } satisfies MeasuresQuestionAdditionalData;
  }, [data?.processorPA?.processorOrgUnitId]);

  const followUpTaskCheckboxVisible = useMemo<boolean>(() => {
    return (
      auth?.permissions.includes("processor_pa_approve_org") ||
      auth?.uid === data?.processorPA?.privacyExpertUserId ||
      auth?.uid === data?.processorPA?.dpoUserId
    );
  }, [auth?.permissions, auth?.uid, data?.processorPA?.dpoUserId, data?.processorPA?.privacyExpertUserId]);

  const followUpTaskCheckboxDisabled = useMemo<boolean>(() => {
    return !data?.processorPA?.privacyExpertUserId;
  }, [data?.processorPA?.privacyExpertUserId]);

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("measure")} />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.MEASURE}
          questionId={"measureIds"}
          questionName={t("measureIds")}
          options={mesOptions}
          value={measureIds}
          suggestionValueIds={suggestionMeasureIds}
          info={infoCard.measureIds}
          onChange={onMeasureIdsChanged}
          disabled={readonly}
          onSuggestionsConfirm={onMeasureIdsImported}
          nonStandardAdditionalData={additionalData}
        />
        {followUpTaskCheckboxVisible && (
          <FollowUpTask
            docId={id || ""}
            creatorUID={data?.processorPA?.privacyExpertUserId || ""}
            assigneeUID={data?.processorPA?.assignToUserId || ""}
            taskName={`${t("followUpTask:paTaskName")}  ${data?.processorPA?.name}`}
            taskDescription={t("followUpTask:paTaskDescription")}
            disableCheckbox={followUpTaskCheckboxDisabled || readonly}
            collection={COLLECTIONS.PROCESSOR_PAS}
            tooltipWhenDisabled={t("followUpTask:privacyExpertTooltip")}
          />
        )}
      </Box>
    </Box>
  );
};
