export interface TraversableItem {
  readonly id: string;
  readonly mergedIntoId?: string | null;
  readonly mergedInto?: string | null;
}

export const traverseMergedInto = <T extends TraversableItem>(itemId: string, allItems: T[]): T | null => {
  const foundItem = allItems.find(item => item.id === itemId);
  const mergedIntoKey = foundItem?.mergedIntoId || foundItem?.mergedInto;
  if (mergedIntoKey) {
    return traverseMergedInto(mergedIntoKey, allItems);
  }

  return foundItem || null;
};
