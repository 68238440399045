import React, { useCallback } from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const TranslatableTextField = ({ label, onTextChange, value, translationKey, translationKeys, disabled }) => {
  const { t } = useTranslation();

  const onChange = useCallback(
    event => {
      const changedValue = event?.target?.value;

      const returnNameKey = translationKeys.find(key => t(translationKey + ":" + key) === changedValue);

      if (returnNameKey) {
        return onTextChange(returnNameKey);
      } else {
        onTextChange(changedValue || "");
      }
    },
    [t, translationKey, translationKeys, onTextChange]
  );

  return (
    <TextField
      label={label}
      value={t(translationKey + ":" + value, value)}
      onChange={onChange}
      variant="outlined"
      aria-label="name"
      fullWidth={true}
      disabled={disabled}
    />
  );
};

TranslatableTextField.propTypes = {
  label: PropTypes.string,
  onTextChange: PropTypes.func,
  value: PropTypes.string,
  translationKey: PropTypes.string,
  translationKeys: PropTypes.array
};
