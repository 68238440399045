import { useQueryParamByKey } from "../../router/router-custom-hooks";
import AuthenticationPageTemplate from "./AuthenticationPageTemplate";
import React, { useEffect } from "react";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { useSSOConfigs } from "../../../hook/useSSOConfigs";
import { getSSOAuthUrl } from "../../handlers/authentication/authenticationHandler";
import { useNavigate } from "react-router-dom";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";

export const SSOLink = () => {
  const navigate = useNavigate();
  const ssoDomain = useQueryParamByKey("domain");
  useEffect(() => {
    if (!ssoDomain || !ssoDomain.includes(".")) {
      navigate("/login");
      return;
    }
  }, [ssoDomain, navigate]);

  const { catchAsSnackbar } = useErrorSnackbar();

  const { ssoConfig, loading: ssoLoading } = useSSOConfigs({ typedEmail: ssoDomain || "" });
  const { loading: userLoading, user: loggedInUser } = useAuthentication();
  useEffect(() => {
    if (userLoading || ssoLoading) {
      return;
    }

    if (loggedInUser?.id) {
      navigate("/login");
      return;
    }

    if (!ssoConfig) {
      navigate("/login");
      return;
    }

    const goToSSOAuthUrl = async () => {
      const ssoAuthUrl = await getSSOAuthUrl({
        callbackIdentifier: ssoConfig.callbackIdentifier
      });
      window.location.href = ssoAuthUrl;
    };

    goToSSOAuthUrl().catch(catchAsSnackbar("Failed to redirect to SSO"));
  }, [ssoConfig, userLoading, loggedInUser?.id, catchAsSnackbar, ssoLoading, navigate]);

  return <AuthenticationPageTemplate />;
};
