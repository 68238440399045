import { SSOConfigDTO } from "app/api/user/userSSOApi";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { getSSOAuthUrl } from "app/handlers/authentication/authenticationHandler";
import { useLocationFullPath } from "../hook/useLocationFullPath";
import { storeRedirectFullPathInSession } from "../app/handlers/authentication/redirectStorage";
import { Box, Button, CircularProgress } from "@mui/material";

export const SSOLoginButton = ({
  ssoConfig,
  enterKeyPressTriggerHack = false // basically so that login page can trigger this one via state... no better idea for now
}: {
  readonly ssoConfig: Required<SSOConfigDTO>;
  readonly enterKeyPressTriggerHack?: boolean;
}) => {
  const { t } = useTranslation("authentication");
  const [loading, setLoading] = useState(false);
  const locationFullPath = useLocationFullPath();
  const { catchAsSnackbar } = useErrorSnackbar();

  const onClickCallback = useCallback(async () => {
    setLoading(true);
    let ssoAuthUrl: string;
    try {
      ssoAuthUrl = await getSSOAuthUrl({
        callbackIdentifier: ssoConfig.callbackIdentifier
      });
    } catch (error) {
      setLoading(false);
      catchAsSnackbar("Unknown SSO error")(error);
      throw error;
    }

    if (!locationFullPath.includes("/login")) {
      storeRedirectFullPathInSession(locationFullPath);
    }
    window.location.href = ssoAuthUrl;
  }, [locationFullPath, ssoConfig.callbackIdentifier, catchAsSnackbar]);

  useEffect(() => {
    if (enterKeyPressTriggerHack) {
      onClickCallback();
    }
  }, [onClickCallback, enterKeyPressTriggerHack]);

  return (
    <Button
      data-qa="sso-login"
      variant="contained"
      type="submit"
      color="primary"
      disabled={loading}
      onClick={onClickCallback}
      fullWidth={true}
    >
      {loading && (
        <Box mr={1} display="flex" alignItems="center">
          <CircularProgress color="inherit" size={16} />
        </Box>
      )}
      {t("sso-login", { name: ssoConfig.name })}
    </Button>
  );
};
