import { getHasuraClientSDK } from "../api/hasuraApi";

export interface WhereIAm {
  readonly userId: string;
  readonly path: string;
  readonly heartbeat: Date;
  readonly questionId: string;
  readonly hslColor: string;
}

export type UnsubscribeWhereIAmListener = () => void;

export const getWhereAmIOnPath = async (input: {
  readonly tenantId: string;
  readonly currentPath: string;
}): Promise<Record<string, WhereIAm>> => {
  const client = await getHasuraClientSDK();
  const response = await client.whereIAmByPath({
    tenant_id: input.tenantId,
    path: input.currentPath
  });
  const whereIAms = response.frontend_access_where_i_am.map(
    it =>
      ({
        userId: it.user_id,
        path: it.path,
        heartbeat: new Date(it.last_heartbeat_at),
        questionId: it.question_id,
        hslColor: it.hsl_color
      }) satisfies WhereIAm
  );

  return whereIAms.reduce<Record<string, WhereIAm>>((acc, next) => ({ ...acc, [next.userId]: next }), {});
};

export const listenOnWherePeopleAre = (input: {
  readonly tenantId: string;
  readonly currentPath: string;
  readonly callback: (datas: Record<string, WhereIAm>) => void;
  readonly interval?: number;
}): UnsubscribeWhereIAmListener => {
  let subscribed = true;
  const update = async () => {
    try {
      const result = await getWhereAmIOnPath(input);
      if (subscribed) {
        input.callback(result);
      }
    } catch (error: unknown) {
      console.warn("Unable to get which field user is on", error);
    }
  };

  update();
  const intervalId = setInterval(update, input.interval || 5000);

  return () => {
    subscribed = false;
    clearInterval(intervalId);
  };
};

export const updateWhereIAm = async (input: {
  readonly path: string;
  readonly userId: string;
  readonly questionId: string;
}): Promise<void> => {
  const client = await getHasuraClientSDK();
  await client.whereIAmUpsert({
    path: input.path,
    question_id: input.questionId,
    hsl_color: stringToHSLCSSColor(input.userId),
    last_heartbeat_at: new Date()
  });
};

export const updateWhereIAmContinuously = (input: {
  readonly path: string;
  readonly userId: string;
  readonly questionId: string;
  readonly interval?: number;
}): UnsubscribeWhereIAmListener => {
  const update = () =>
    updateWhereIAm(input).catch(error => console.warn("Unable to set which field user is on", error));

  update();
  const intervalId = setInterval(update, input.interval || 25000);

  return () => clearInterval(intervalId);
};

const stringToHSLCSSColor = (stringInput: string): string => {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

export const getUserIdColor = stringToHSLCSSColor;
