import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../theme/theme";
import { usePathName } from "../../../app/router/router-custom-hooks";
import { Box, Link, Typography } from "@mui/material";

interface OverviewSelectAllProps {
  readonly numberTotalItems: number;
  readonly numberCheckedItems: number;
  readonly numberSelectableItems: number;
  readonly numberTotalSelectableItems: number;

  readonly onSelectAll: () => void;
  readonly onClearAll: () => void;
}
export const OverviewSelectAll = ({
  numberTotalItems,
  numberSelectableItems,
  numberCheckedItems,
  numberTotalSelectableItems,

  onSelectAll,
  onClearAll
}: OverviewSelectAllProps) => {
  const sx = {
    root: {
      p: 2,
      margin: "0 -32px",
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.grey[100]
    },
    link: {
      cursor: "pointer"
    },
    linkText: {
      fontSize: "14px",
      fontWeight: "bold",
      marginLeft: "16px"
    }
  };
  const path = usePathName();
  const onResourcesOverview = path.includes("resources") && !path.includes("data-assets");
  const { t } = useTranslation("overviewSelectAll");

  const currentlySelectedText = t("currentlySelected", {
    numberSelected: numberCheckedItems
  });
  const selectAllText = t("selectAll", {
    numberTotal: onResourcesOverview ? numberTotalSelectableItems : numberTotalItems
  });
  const uncheckAllText = t("clearSelection");

  // for resources only custom items are selectable, so we have different translations
  const currentlySelectedResources = t("currentlySelectedResources", {
    numberSelected: numberCheckedItems,
    numberPage: numberTotalSelectableItems
  });
  const resourcesSelectAllText = selectAllText + t("customEntriesOnly");

  const showComponent =
    (numberCheckedItems === numberSelectableItems || numberCheckedItems === numberTotalSelectableItems) &&
    numberCheckedItems > 0 &&
    numberCheckedItems >= numberSelectableItems;

  const showCheckAllText = numberCheckedItems != numberTotalSelectableItems; // don't show select all text when all are already checked

  const checkAllTextEl = (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Typography>{onResourcesOverview ? currentlySelectedResources : currentlySelectedText}</Typography>
      <Link underline={"none"} sx={sx.link} onClick={showCheckAllText ? onSelectAll : onClearAll}>
        <Typography noWrap sx={sx.linkText} color={"primary"}>
          {showCheckAllText ? (onResourcesOverview ? resourcesSelectAllText : selectAllText) : uncheckAllText}
        </Typography>
      </Link>
    </Box>
  );

  if (showComponent) {
    return <Box sx={sx.root}>{checkAllTextEl}</Box>;
  } else {
    return <></>;
  }
};
