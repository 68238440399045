import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import RenderIcon from "components/RenderIcon/RenderIcon";

const useStyles = makeStyles(theme => ({
  documentViewContent: {
    padding: "30px"
  },
  staticDocViewWrapperBody: {
    display: "flex",
    justifyContent: "center"
  },
  staticDocViewInnerBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  staticDocViewIconContainer: {
    display: "flex",
    justifyContent: "center"
  },
  successIcon: {
    fontSize: "200px",
    marginBottom: "50px",
    color: theme.palette.success.main
  },
  warningIcon: {
    fontSize: "200px",
    marginBottom: "50px",
    color: theme.palette.warning.main
  },
  errorIcon: {
    fontSize: "200px",
    marginBottom: "50px",
    color: theme.palette.error.main
  },
  regularIcon: {
    fontSize: "200px",
    marginBottom: "50px"
  }
}));

export default function StaticDocView({ icon, type = "success", children }) {
  const classes = useStyles();

  return (
    <Grid container spacing={0} direction="column" justifyContent="center" className={classes.documentViewContent}>
      <Grid item xs={12}>
        <div className={classes.staticDocViewWrapperBody}>
          <div className={classes.staticDocViewInnerBody}>
            <div className={classes.staticDocViewIconContainer}>
              <RenderIcon icon={icon} iconType={type} classes={classes} />
            </div>
            {children}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
