import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import GeneralPageStepper, { PageStep } from "../../../components/Pagination/GeneralPageStepper";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";

function usePaginationSteps() {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<PageStep[]>([]);

  useEffect(() => {
    setSteps(
      [
        {
          label: t("tom:descriptionTabTitle"),
          path: "description"
        },
        {
          label: t("pagination:risk"),
          path: "risks"
        },
        {
          label: t("tom:processTabTitle"),
          path: "processes"
        }
      ].flatMap(step => (step ? [step] : []))
    );
  }, [t]);

  return {
    steps
  };
}

export function TOMPageStepper() {
  const { steps } = usePaginationSteps();

  return <GeneralPageStepper steps={steps} />;
}

export function TOMPageButtons({ rightArea }: { readonly rightArea?: React.ReactNode }) {
  const { steps } = usePaginationSteps();

  return <GeneralPageButtonPagination steps={steps} rightArea={rightArea} />;
}
