import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetProcessorApi, useUpdateProcessorApi } from "../../../api/processorPAApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { PatchProcessorPADTO } from "../../../api/generated/process-service";
import { useProcessorPAEditConfirmation } from "../ProcessorPAEditConfirmation";
import { useMetaView } from "../../../contexts/meta-view-context";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { paReadPermissions } from "../../../handlers/permissionHandler";
import { Tooltip } from "@material-ui/core";
import { useUserDepartments } from "app/contexts/department-context";

export const ProcessorProcessorController = ({ readonly }: { readonly?: boolean }) => {
  const { id } = useParams();
  const { t } = useTranslation("processor_pa_page");
  const { getDepartmentWithParentName } = useUserDepartments();
  const { data } = useGetProcessorApi({ documentId: id || "" });
  const { updateProcessor } = useUpdateProcessorApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<PatchProcessorPADTO, PatchProcessorPADTO>({
    triggerUpdate: updateProcessor
  });
  const { beforeProcessUpdate, hardResetTrigger } = useProcessorPAEditConfirmation();
  const { auth } = useAuthentication();
  const userHasControllerPAReadPermission = auth?.permissions.some(permission =>
    paReadPermissions.includes(permission)
  );

  const [processorOrgUnitId, setProcessorOrgUnitId] = useState<string>("");
  const [assignToUserId, setAssignToUserId] = useState<string>("");
  const [privacyExpertUserId, setPrivacyExpertUserId] = useState<string>("");
  const [dpoUserId, setDpoUserId] = useState<string>("");
  const [paIds, setPAIds] = useState<string[]>([]);
  const [controllerOrgUnitIds, setControllerOrgUnitIds] = useState<string[]>([]);
  const [suggestionOrgUnitIds, setSuggestionOrgUnitIds] = useState<string[]>([]);
  const [controllerErIds, setControllerErIds] = useState<string[]>([]);
  const [labelIds, setLabelIds] = useState<string[]>([]);

  useEffect(() => {
    if (data?.processorPA) {
      setProcessorOrgUnitId(data.processorPA.processorOrgUnitId || "");
      setAssignToUserId(data.processorPA.assignToUserId || "");
      setPrivacyExpertUserId(data.processorPA.privacyExpertUserId || "");
      setDpoUserId(data.processorPA.dpoUserId || "");
      setPAIds(data.processorPA.paIds || []);
      setControllerOrgUnitIds(data.processorPA.controllerOrgUnitIds || []);
      setControllerErIds(data.processorPA.controllerExternalRecipientIds || []);
      setLabelIds(data.processorPA.labelIds || []);
      const nonDeletedOrgUnit: string[] = (data.processorPA.suggestions?.controllerOrgUnitIds || []).filter(
        orgunit => getDepartmentWithParentName(orgunit) !== `${t("questionnaires:deletedEntry")}`
      );
      setSuggestionOrgUnitIds(nonDeletedOrgUnit);
    }
  }, [data?.processorPA, t, getDepartmentWithParentName]);

  const onProcessorOrgUnitIdChange = useCallback(
    (processorOrgUnitId: string) => {
      return beforeProcessUpdate(async () => {
        setProcessorOrgUnitId(processorOrgUnitId);
        return queueUpdates({ processorOrgUnitId });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onAssignToUserIdChange = useCallback(
    (assignToUserIds: string[]) => {
      const assignToUserId = assignToUserIds[0] || "";
      return beforeProcessUpdate(async () => {
        setAssignToUserId(assignToUserId);
        return queueUpdates({ assignToUserId });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onPrivacyExpertUserIdChange = useCallback(
    (ids: string[]) => {
      const privacyExpertUserId = ids[0] || "";
      return beforeProcessUpdate(async () => {
        setPrivacyExpertUserId(privacyExpertUserId);
        return queueUpdates({ privacyExpertUserId });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onDpoUserIdChange = useCallback(
    (ids: string[]) => {
      const dpoUserId = ids[0] || "";
      return beforeProcessUpdate(async () => {
        setPrivacyExpertUserId(dpoUserId);
        return queueUpdates({ dpoUserId });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onPAIdsChange = useCallback(
    (paIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setPAIds(paIds);
        return queueUpdates({ paIds });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onControllerOrgUnitIdsChange = useCallback(
    (controllerOrgUnitIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setControllerOrgUnitIds(controllerOrgUnitIds);
        return queueUpdates({ controllerOrgUnitIds });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onControllerErIdsChange = useCallback(
    (controllerErIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setControllerErIds(controllerErIds);
        return queueUpdates({ controllerExternalRecipientIds: controllerErIds });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onLabelIdsChange = useCallback(
    (labelIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setLabelIds(labelIds);
        return queueUpdates({ labelIds });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const infoCard = useMemo(
    () => ({
      enteringPA: {
        title: t("enteringInfoCardTitle", ""),
        text: t("enteringInfoCardText", "")
      },
      processorOrgUnitId: {
        title: t("processorOrgUnitId-title", ""),
        text: t("processorOrgUnitId-info", "")
      },
      assignToUserId: {
        title: t("pa_general:dpo_info_title"),
        text: t("pa_general:assigned_user_info_text")
      },
      privacyExpertUserId: {
        title: t("pa_general:dpo_info_title"),
        text: t("pa_general:responsible_expert_info_text")
      },
      dpoUserId: {
        title: t("pa_general:dpo_info_title"),
        text: t("pa_general:dpo_info_text")
      },
      paIds: {
        title: t("paIds-title", ""),
        text: t("paIds-info", "")
      },
      controllerOrgUnitIds: {
        title: t("controllerOrgUnitIds-title", ""),
        text: t("controllerOrgUnitIds-info", "")
      },
      controllerExternalRecipientIds: {
        title: t("controllerExternalRecipientIds-title", ""),
        text: t("controllerExternalRecipientIds-info", "")
      },
      labelIds: {
        title: t("labelIds-title", ""),
        text: t("labelIds-info", "")
      }
    }),
    [t]
  );

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo(infoCard.enteringPA);
  }, [setInfo, infoCard.enteringPA]);

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("contact-detail")} />
      </Box>
      <Box>
        <Question
          // this component have problem with controlled state, so force re-render when dialog is changed
          key={`responsible-org-unit-manual-key-for-force-rerender-${hardResetTrigger}`}
          qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
          questionId={"processorOrgUnitId"}
          questionName={t("processorOrgUnitId")}
          info={infoCard.processorOrgUnitId}
          value={processorOrgUnitId}
          onChange={onProcessorOrgUnitIdChange}
          disabled={readonly}
        />
      </Box>
      <Question
        qType={QUESTION_TYPE.ASSIGNED_TO_USER}
        questionName={t("pa_general:assigned_to")}
        info={infoCard.assignToUserId}
        value={assignToUserId}
        onChange={onAssignToUserIdChange}
        assignType={"privacyExpert"}
        disabled={readonly}
      />
      <Question
        orgUnitIds={[processorOrgUnitId]}
        onlyIntersectingOrgUnitIds={true}
        qType={QUESTION_TYPE.PRIVACY_EXPERT_USER}
        questionId={"responsibleExpert"}
        info={infoCard.privacyExpertUserId}
        value={privacyExpertUserId}
        onChange={onPrivacyExpertUserIdChange}
        disabled={readonly}
      />
      <Question
        orgUnitIds={[processorOrgUnitId]}
        onlyIntersectingOrgUnitIds={true}
        qType={QUESTION_TYPE.DPO_USER}
        questionId={"dpo"}
        info={infoCard.dpoUserId}
        value={dpoUserId}
        onChange={onDpoUserIdChange}
        disabled={readonly}
      />
      <Tooltip title={userHasControllerPAReadPermission ? "" : t("common:noPermissionToEditField")}>
        <Box>
          <Question
            qType={QUESTION_TYPE.PAS}
            questionId={"paIds"}
            questionName={t("collection:processes_cap")}
            info={infoCard.paIds}
            value={paIds}
            onChange={onPAIdsChange}
            disabled={readonly || !userHasControllerPAReadPermission}
            options={data?.pas || emptyArray}
          />
        </Box>
      </Tooltip>

      <Box>
        <Question
          qType={QUESTION_TYPE.ORG_UNITS_PICKER}
          questionId={"controllerOrgUnitIds"}
          questionName={t("controllerOrgUnitIds")}
          info={infoCard.controllerOrgUnitIds}
          value={controllerOrgUnitIds}
          onChange={onControllerOrgUnitIdsChange}
          disabled={readonly}
          suggestionValueIds={suggestionOrgUnitIds}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS}
          questionId={"controllerExternalRecipientIds"}
          questionName={t("controllerExternalRecipientIds")}
          info={infoCard.controllerExternalRecipientIds}
          value={controllerErIds}
          onChange={onControllerErIdsChange}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.LABELS}
          questionId={"label"}
          questionName={t("labels:label")}
          info={infoCard.labelIds}
          value={labelIds}
          onChange={onLabelIdsChange}
          disabled={readonly}
        />
      </Box>
    </Box>
  );
};

const emptyArray: any[] = [];
