import {
  createGroupApi,
  deleteGroupApi,
  getSingleGroupApi,
  getGroupsApi,
  SingleNewGroup,
  updateGroupApi,
  GroupDTO
} from "app/api/groupApi";
import { retryUntil } from "app/handlers/utility/retry";
import { WithAbortController } from "../api/axios/axiosErrorHandler";
import { isEqual } from "lodash-es";

export const getGroups = (options: WithAbortController = {}) => getGroupsApi(options);

export const getGroupData = (id: string) => getSingleGroupApi(id);

export const deleteGroup = async (tenantId: string, id: string) => {
  await deleteGroupApi(id);
  await retryUntil(async () => {
    const createdGroup = await getGroupData(id);
    if (createdGroup) {
      throw new Error("Timeout on delete Group");
    }
  });
};

export const createGroup = async (tenantId: string, payload: SingleNewGroup): Promise<GroupDTO> => {
  const createdGroupId = await createGroupApi(payload);
  return await retryUntil(async () => {
    const createdGroup = await getGroupData(createdGroupId);
    if (!createdGroup) {
      throw new Error("Timeout on create Group");
    }
    return createdGroup;
  });
};

export const updateGroup = async (tenantId: string, id: string, payload: SingleNewGroup): Promise<GroupDTO> => {
  const group = await getGroupData(id);
  if (!group) {
    throw new Error("Trying to update non existing group");
  }

  await updateGroupApi(id, payload);

  return await retryUntil(async () => {
    const updatedGroup = await getGroupData(id);
    const [changedKey, changedValue] = Object.entries(payload)[0];
    if (!updatedGroup || !isEqual((updatedGroup as any)[changedKey], changedValue)) {
      throw new Error("Timeout on update group");
    }
    return updatedGroup;
  });
};
