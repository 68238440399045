import React from "react";
import DpiaRadioButtonQuestion from "./DpiaRadioButtonQuestion";
import { GetDpia200Response } from "../../../../app/api/generated/process-service";

interface RadioButtonQuestionListProps {
  dpiaDecisionKeys: string[];
  dpiaData: GetDpia200Response | undefined;
  readonly: boolean;
  preSelectVulnerablePersons: boolean;
  setMetaForQuestion: (key: string) => void;
  onRadioButtonQuestionChange: (key: string, value: string | number | boolean | undefined) => void;
}

const RadioButtonQuestionList: React.FC<RadioButtonQuestionListProps> = ({
  dpiaDecisionKeys,
  dpiaData,
  readonly,
  preSelectVulnerablePersons,
  setMetaForQuestion,
  onRadioButtonQuestionChange
}) => {
  return (
    <>
      {dpiaDecisionKeys.map((key, index) => (
        <DpiaRadioButtonQuestion
          key={key}
          keyName={key}
          index={index}
          value={dpiaData?.processPage?.[key as keyof typeof dpiaData.processPage]}
          readonly={readonly}
          vulnerablePersonIsYes={key === "vulnerablePersons" && preSelectVulnerablePersons}
          onFocus={setMetaForQuestion}
          onChange={onRadioButtonQuestionChange}
        />
      ))}
    </>
  );
};

export default RadioButtonQuestionList;
