import { OverviewResult, OverviewResultDecorator } from "../overviewBaseController";
import { DataLocationDTO, getDataLocationsApi } from "../../../../app/api/externalRecipientApi";

export const dataLocationsDecorator: OverviewResultDecorator<{ readonly _dataLocations: DataLocationDTO[] }> = {
  async decorate(overviewResult: OverviewResult): Promise<{ readonly _dataLocations: DataLocationDTO[] }> {
    if (overviewResult._dataLocations) {
      return { _dataLocations: overviewResult._dataLocations };
    }

    const response = await getDataLocationsApi();
    return {
      _dataLocations: response?.dataLocations || []
    };
  },
  type: "prefetch"
};
