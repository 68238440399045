import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { useTranslation } from "react-i18next";

const AuditRemarkEdit = ({
  value,
  onSave,
  onCancel
}: {
  readonly value: string;
  readonly onSave: (value: string) => void;
  readonly onCancel: () => void;
}) => {
  const { auth } = useAuthentication();
  const { t } = useTranslation();
  const { getUserNameHook } = useUserAndTenantData();
  const [currentValue, setCurrentValue] = useState<string>();

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onSaveCallback = useCallback(() => {
    onSave(currentValue || "");
  }, [currentValue, onSave]);
  const onChangeCallback = useCallback(event => {
    setCurrentValue(event.target.value);
  }, []);

  return (
    <Paper>
      <Box p={2}>
        <Box mb={1} display="flex" alignItems="center">
          <UserAvatar userId={auth?.uid} size="large" />
          <Box mr={2} />
          <Typography>{getUserNameHook(auth?.uid || "")}</Typography>
        </Box>
        <TextField
          fullWidth
          multiline
          rows={4}
          onBlur={onChangeCallback}
          defaultValue={currentValue}
          variant="outlined"
        />
        <Box display="flex" my={2}>
          <Box width="50%" mr={1}>
            <Button fullWidth variant="contained" color="primary" onClick={onSaveCallback}>
              {t("common:save")}
            </Button>
          </Box>
          <Box width="50%" ml={1}>
            <Button fullWidth variant="outlined" color="primary" onClick={onCancel}>
              {t("common:cancel")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default AuditRemarkEdit;
