import { getHasuraClientSDK } from "../api/hasuraApi";

export interface Tenant {
  readonly id: string;
  readonly name: string;
  readonly companyLanguage: string;
  readonly features: string[];
  readonly showDev: boolean;
  readonly showTraining: boolean;
}

export const getTenantInformation = async (tenantId: string): Promise<Tenant | null> => {
  const client = await getHasuraClientSDK();
  const tenant = await client.tenantGetOne({ tenant_id: tenantId });
  if (!tenant.frontend_access_tenant_by_pk) {
    return null;
  }

  return {
    id: tenant.frontend_access_tenant_by_pk.tenant_id,
    name: tenant.frontend_access_tenant_by_pk.name,
    companyLanguage: tenant.frontend_access_tenant_by_pk.company_language,
    features: tenant.frontend_access_tenant_by_pk.features,
    showDev: false,
    showTraining: false
  };
};
