import React, { useCallback, useMemo, useState } from "react";
import { detailType, TaskStatus } from "app/api/taskApi";
import ToDoIcon from "../../../../assets/images/tasks/status/to-do.svg";
import InProgressIcon from "../../../../assets/images/tasks/status/in-progress.svg";
import CompletedIcon from "../../../../assets/images/tasks/status/completed.svg";
import CanceledIcon from "../../../../assets/images/tasks/status/canceled.svg";
import ActiveIcon from "../../../../assets/images/tasks/status/active.svg";
import InActiveIcon from "../../../../assets/images/tasks/status/inactive.svg";
import { Box, ClickAwayListener, Typography, makeStyles, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";

const defaultStatusIcons: Record<TaskStatus, React.ReactNode> = {
  TODO: <ToDoIcon />,
  OPEN: <InProgressIcon />,
  DONE: <CompletedIcon />,
  DELETED: <CanceledIcon />
};

const recurringStatusIcons: Record<"ACTIVE" | "INACTIVE", React.ReactNode> = {
  ACTIVE: <ActiveIcon />,
  INACTIVE: <InActiveIcon />
};

const useStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  }
}));

interface TaskOverviewRowStatusProps {
  readonly detailType: detailType;
  readonly status: TaskStatus | "ACTIVE" | "INACTIVE";
  readonly onChange: (status: TaskStatus) => void;
  readonly showStatusText?: boolean;
}
const TaskOverviewRowStatus = ({ detailType, status, onChange, showStatusText }: TaskOverviewRowStatusProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const openMenuCallback = useCallback(event => {
    setRef(event?.currentTarget);
    setOpen(true);
  }, []);

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const onChangeCallback = useCallback(
    status => {
      closeMenuCallback();
      onChange(status);
    },
    [closeMenuCallback, onChange]
  );

  const statusText = useMemo(() => {
    if (!showStatusText) {
      return "";
    }
    return status ? t("status_" + status) : t("status");
  }, [showStatusText, status, t]);

  const statusMenuItems = useMemo(() => {
    return Object.keys(detailType === "RECURRING" ? recurringStatusIcons : defaultStatusIcons)
      .filter(key => key !== "DELETED")
      .map(key => ({
        id: key,
        label: t("status_" + key),
        icon:
          detailType === "RECURRING"
            ? recurringStatusIcons[key as "ACTIVE" | "INACTIVE"]
            : defaultStatusIcons[key as TaskStatus],
        selected: key === status
      }));
  }, [detailType, status, t]);

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        <Tooltip title={t("changeStatus")}>
          <Box className={cls.root} onClick={openMenuCallback}>
            {detailType === "RECURRING"
              ? recurringStatusIcons[status as "ACTIVE" | "INACTIVE"]
              : defaultStatusIcons[status as TaskStatus]}
            {showStatusText && (
              <Box ml={1}>
                <Typography>{statusText}</Typography>
              </Box>
            )}
          </Box>
        </Tooltip>
        <DropDownMenu
          open={open}
          anchorEl={ref}
          items={statusMenuItems}
          onChange={onChangeCallback}
          onClose={closeMenuCallback}
        />
      </>
    </ClickAwayListener>
  );
};

export default TaskOverviewRowStatus;
