import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import MultiAutocomplete from "../../../components/MultiAutocomplete/MultiAutocomplete";
import { getDataLocationWithExternalRecipient } from "../../utils/getDataLocationWithExternalRecipient";
import OverviewList from "../../../components/LegacyOverview/OverviewList";
import Divider from "@material-ui/core/Divider";
import Row from "../../../components/Row/Row";
import OverviewListItemPagination from "../../../components/LegacyOverview/OverviewListItemPagination";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import WarningIcon from "@material-ui/icons/Warning";
import DoneIcon from "@material-ui/icons/Done";
import Chip from "@material-ui/core/Chip";
import DateDisplay from "../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../components/UserDataDisplay";
import { DataSubjectRequestsPageButtons, DataSubjectRequestsPageStepper } from "./DataSubjectRequestsPagination";
import MetaView from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import AttachmentsOverviewOBS from "../shared/Attachments/AttachmentsOverviewOBS";
import { DSRPersonGroupField } from "./DataSubjectRequestsFields";
import { useExternalRecipients } from "../../contexts/external-recipient-context";
import { useDataLocations } from "../../../hook/useDataLocations";
import { useUserDepartments } from "../../contexts/department-context";
import { getPersonGroupAndDataCategoryKeysFromDataTypeIds } from "../data-breaches/dataBreachesUtils";
import Question from "components/Question/Question";
import { COLLECTIONS } from "app/collections";
import { useDataSubjectRequest } from "../../contexts/dsr-context";
import { ResourceField } from "../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../handlers/resourceHandler";
import { useResources } from "../../contexts/resource-context";
import { useUserProcesses } from "../../../hook/useUserProcesses";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { useAuthentication } from "../../handlers/authentication/authentication-context";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "20px"
  },
  rowIconError: {
    color: theme.palette.error.main,
    padding: "4px"
  },
  rowIconWarning: {
    color: theme.palette.warning.main,
    padding: "4px"
  },
  rowIconSuccess: {
    color: theme.palette.success.main,
    padding: "4px"
  },
  noProduct: {
    marginTop: "30px",
    marginLeft: "5%"
  }
}));

const getDataTypesIdsOfPersonGroup = (personGroupNameKey, dataTypeCategoryPersonGroupList) => {
  if (dataTypeCategoryPersonGroupList?.length) {
    const dataTypeIds = [];
    dataTypeCategoryPersonGroupList.forEach(personGroup => {
      if (personGroup.personGroupKey === personGroupNameKey) {
        personGroup.dataCategories.forEach(category => {
          category.dataTypes
            .filter(type => !type.mergedInto)
            .forEach(type => {
              dataTypeIds.push(type.id);
            });
        });
      }
    });
    return dataTypeIds;
  }
};

export default function DataSubjectRequestsDataPage({ documentId }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("data_subject_requests_data_page");
  const { resourcesLoaded, resources } = useResources();
  const { dataLocations, dataLocationsLoaded } = useDataLocations();
  const dataLocationsMap = useMemo(() => {
    return new Map(dataLocations.map(location => [location.id, location]));
  }, [dataLocations]);
  const { departmentsLoaded, getDepartmentName, isPartOfUserDepartments } = useUserDepartments();
  const [listOfProcesses, setListOfProcesses] = useState([]);
  const [displayableProcesses, setDisplayableProcesses] = useState([]);
  const [personGroups, setPersonGroups] = useState([]);
  const [dataStorageIDs, setDataStorageIDs] = useState([]);
  const [internalRecipientIDs, setInternalRecipientIDs] = useState([]);
  const [dsrExternalRecipientsIDs, setDsrExternalRecipientsIDs] = useState([]);
  const [title, setTitle] = useState("");
  const [pageInitialized, setPageInitialized] = useState(false);
  const [dataSourceIDs, setDataSourceIDs] = useState([]);
  const { auth } = useAuthentication();

  const dataTypeTree = useDataTypeTree();
  const dataTypeCategoryPersonGroupList = useMemo(() => {
    return dataTypeTree.data || [];
  }, [dataTypeTree.data]);

  const { setMeta, setInfo } = useMetaView();

  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { processes, processesLoaded: paLoaded } = useUserProcesses({
    personGroupIDs: personGroups
  });

  const { externalRecipients, initialized: erInitialized } = useExternalRecipients();

  const infoCardNoPersonGroup = useMemo(
    () => ({
      title: t("info_title"),
      text: t("info_no_person_group")
    }),
    [t]
  );
  const infoCardPersonGroup = useMemo(
    () => ({
      title: t("info_title"),
      text: t("info_person_group")
    }),
    [t]
  );

  //set Info depending on if person group selected
  useEffect(() => {
    if (personGroups?.length > 0) {
      setInfo(infoCardPersonGroup);
    } else {
      setInfo(infoCardNoPersonGroup);
    }
  }, [personGroups?.length, setInfo, infoCardPersonGroup, infoCardNoPersonGroup]);

  const {
    initialized: dataSubjectRequestInitialized,
    dataSubjectRequest,
    updateBasicDataHook
  } = useDataSubjectRequest();

  const noWritePermission = useMemo(() => {
    if (!dataSubjectRequest.inputData.orgUnitId) return false;
    if (dataSubjectRequest.inputData.assignedTo === auth.uid || auth?.permissions.includes("dsr_write_all"))
      return false;
    if (isPartOfUserDepartments(...[dataSubjectRequest.inputData.orgUnitId])) return false;
    return true;
  }, [dataSubjectRequest, isPartOfUserDepartments, auth]);

  const addMetaDataToProcesses = useCallback(
    data => {
      if (!Array.isArray(data)) {
        setListOfProcesses([]);
      }

      /**
       * @param processDetails {ProcessingActivityOverviewDTO}
       */
      let toMetaView = processDetails => {
        const metaObject = {
          [t("processes_overview:version")]: processDetails.version,
          [t("processes_overview:created")]: <DateDisplay timestamp={new Date(processDetails.createdAt)}></DateDisplay>,
          [t("processes_overview:createdBy")]: <AutomaticUserDataDisplay uid={processDetails.createdBy} />,
          [t("processes_overview:department")]: getDepartmentName(processDetails.orgUnitIds[0]),
          [t("processes_overview:dueDate")]: processDetails.dueAt ? (
            <DateDisplay timestamp={new Date(processDetails.dueAt)}></DateDisplay>
          ) : (
            "-"
          ),
          [t("processes_overview:status")]: t(
            `processes_overview:status_${processDetails.status}`,
            processDetails.status
          )
        };
        return {
          id: processDetails.id,
          name: processDetails.title,
          meta: metaObject
        };
      };
      const input = data.map(toMetaView);
      setListOfProcesses(input);
    },
    [getDepartmentName, t]
  );

  useEffect(() => {
    if (
      !(paLoaded && externalRecipients && departmentsLoaded && dataLocationsLoaded && resourcesLoaded && erInitialized)
    ) {
      return;
    }

    const allDataLocationIDs = dataLocations.map(location => location.id);
    const allInternalRecipientIDs = resources[RESOURCE_TYPES.INTERNAL_RECIPIENT].map(
      internalRecipient => internalRecipient.id
    );
    const allExternalRecipientIDs = externalRecipients.map(externalRecipient => externalRecipient.id);
    const relevantDataStorageIDs = new Set();
    const relevantDataSourceIDs = new Set();
    const relevantInternalRecipientIDs = new Set();
    const relevantExternalRecipientIDs = new Set();
    processes.forEach(processData => {
      processData.allInternalRecipientIds
        .filter(recipientId => allInternalRecipientIDs.includes(recipientId))
        .forEach(id => relevantInternalRecipientIDs.add(id));
      processData.allExternalRecipientIds
        .filter(recipientId => allExternalRecipientIDs.includes(recipientId))
        .forEach(id => relevantExternalRecipientIDs.add(id));
      processData.allDataSourceIds
        .filter(dataSourceId => allDataLocationIDs.includes(dataSourceId))
        .forEach(id => relevantDataSourceIDs.add(id));
      processData.allDataStorageIds
        .filter(dataStorageId => allDataLocationIDs.includes(dataStorageId))
        .forEach(id => relevantDataStorageIDs.add(id));
    });
    setDataStorageIDs([...relevantDataStorageIDs]);
    setDataSourceIDs([...relevantDataSourceIDs]);
    setInternalRecipientIDs([...relevantInternalRecipientIDs]);
    setDsrExternalRecipientsIDs([...relevantExternalRecipientIDs]);
    addMetaDataToProcesses(processes);
  }, [
    i18n.language,
    externalRecipients,
    departmentsLoaded,
    addMetaDataToProcesses,
    dataLocationsLoaded,
    dataLocations,
    resourcesLoaded,
    resources,
    paLoaded,
    erInitialized,
    processes
  ]);

  //translate lists keys
  const getExternalDataRecipientName = function (id) {
    const filterOptionListsById = externalRecipients.find(element => element.id === id);
    if (filterOptionListsById?.name) {
      return filterOptionListsById.name;
    }
  };

  useEffect(() => {
    if (dataSubjectRequestInitialized && !pageInitialized && dataTypeCategoryPersonGroupList?.length > 0) {
      setTitle(dataSubjectRequest.inputData.title);
      if (dataSubjectRequest.inputData.dataTypeIds?.length > 0) {
        const { personGroupKeys } = getPersonGroupAndDataCategoryKeysFromDataTypeIds(
          dataSubjectRequest.inputData.dataTypeIds,
          dataTypeCategoryPersonGroupList
        );
        setPersonGroups(personGroupKeys);
      } else {
        setPersonGroups(dataSubjectRequest.inputData.personGroups);
      }
      setPageInitialized(true);
    }
  }, [
    dataSubjectRequest.inputData?.dataTypeIds,
    dataSubjectRequest.inputData?.dataTypeIds?.length,
    dataSubjectRequest.inputData.personGroups,
    dataSubjectRequest.inputData.title,
    dataSubjectRequestInitialized,
    dataTypeCategoryPersonGroupList,
    pageInitialized
  ]);

  const determineStatusIcon = process => {
    const processStatus = process ? process.meta.status : "";

    switch (processStatus) {
      case "rejected":
        return <ErrorIcon className={classes.rowIconError} />;
      case "edit":
        return <WarningIcon className={classes.rowIconWarning} />;
      case "review":
        return <WarningIcon className={classes.rowIconWarning} />;
      case "approved":
        return <DoneIcon className={classes.rowIconSuccess} />;
      default:
        return <WarningIcon className={classes.rowIconWarning} />;
    }
  };

  const resetMeta = function () {
    // if anything needs to be done with the previously selected meta then here, or ignore the returned process
    setMeta(null);
  };

  // update the new meta to be displayed in the meta view content
  const updateMeta = function (process) {
    setMeta(process.meta);
  };

  // content for doc view
  const docViewContent = (
    <DocView header={title} pagination={<DataSubjectRequestsPageStepper />}>
      <QuestionnaireSubHeader text={t("relevant_data")} />
      {
        <div>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Question questionId={"createdBy"} questionName={t("createdBy")} disabled={noWritePermission}>
                <DSRPersonGroupField
                  disabled={noWritePermission}
                  onBlur={() => {}}
                  values={personGroups || []}
                  onChanges={selectedPersonGroupKeys => {
                    setPersonGroups(selectedPersonGroupKeys);
                    updateBasicDataHook({
                      personGroups: selectedPersonGroupKeys,
                      dataTypeIds: selectedPersonGroupKeys
                        .flatMap(personGroupKey =>
                          getDataTypesIdsOfPersonGroup(personGroupKey, dataTypeCategoryPersonGroupList)
                        )
                        .filter(nonNull => nonNull)
                    });
                  }}
                />
              </Question>
            </Grid>
            {personGroups && personGroups.length > 0 && processes && (
              <>
                <Grid item xs={12}>
                  <Question
                    questionId={"relevant_datasources"}
                    questionName={t("datasource")}
                    disabled={noWritePermission}
                  >
                    <MultiAutocomplete
                      id="data_sources"
                      selected={dataSourceIDs || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            deleteIcon={<> </>}
                            key={index}
                            label={getDataLocationWithExternalRecipient(option, dataLocationsMap, t)}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      disabled={true}
                      options={dataLocations.map(dl => dl.id)}
                      getOptionLabel={option => {
                        return getDataLocationWithExternalRecipient(option, dataLocationsMap, t);
                      }}
                      hasMultiSelect={true}
                      label={t("data_subject_requests_data_page:datasource")}
                    />
                  </Question>
                </Grid>

                <Grid item xs={12}>
                  <Question
                    questionId={"relevant_locations"}
                    questionName={t("data_subject_requests_data_page:datalocation")}
                    disabled={noWritePermission}
                  >
                    <MultiAutocomplete
                      id="data_storages"
                      selected={dataStorageIDs || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            deleteIcon={<> </>}
                            key={index}
                            label={getDataLocationWithExternalRecipient(option, dataLocationsMap, t)}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      disabled={true}
                      options={dataLocations.map(dl => dl.id)}
                      getOptionLabel={option => {
                        return getDataLocationWithExternalRecipient(option, dataLocationsMap, t);
                      }}
                      hasMultiSelect={true}
                      label={t("data_subject_requests_data_page:datalocation")}
                    />
                  </Question>
                </Grid>

                <Grid item xs={12}>
                  <Question
                    questionId={"dataRecipients:internal"}
                    questionName={t("dataRecipients:internal")}
                    disabled={noWritePermission}
                  >
                    <ResourceField
                      disabled={true}
                      id="internal_recipients"
                      value={internalRecipientIDs || []}
                      resourceType={RESOURCE_TYPES.INTERNAL_RECIPIENT}
                      label={t("dataRecipients:internal")}
                      isNotDeletable={() => true}
                      onChange={() => {
                        /* do nothing */
                      }}
                    />
                  </Question>
                </Grid>
                <Grid item xs={12}>
                  <Question
                    questionId={"dataRecipients:external"}
                    questionName={t("dataRecipients:external")}
                    disabled={noWritePermission}
                  >
                    <MultiAutocomplete
                      id="external_data_recipients"
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            deleteIcon={<> </>}
                            key={index}
                            label={getExternalDataRecipientName(option)}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      disabled={true}
                      options={externalRecipients.map(element => element["id"])}
                      selected={dsrExternalRecipientsIDs || []}
                      getOptionLabel={option => {
                        return getExternalDataRecipientName(option);
                      }}
                      hasMultiSelect={true}
                      label={t("dataRecipients:external")}
                      elementId={"external_recipients"}
                    />
                  </Question>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "16px" }}>
                  <QuestionnaireSubHeader text={t("processing_activities")} />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "24px" }}>
                  <OverviewList>
                    {displayableProcesses.map((process, index) => {
                      return (
                        <React.Fragment key={process.id}>
                          <Divider />
                          <Row
                            index={index}
                            rowDataItemText={process.name}
                            key={index}
                            determineStatusIcon={() => determineStatusIcon(process)}
                            callOnMouseLeave={resetMeta}
                            callOnMouseOver={() => updateMeta(process)}
                            onClick={() => {
                              if (process.id) {
                                window.open("/processes/" + process.id + "/recipients");
                              }
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </OverviewList>

                  <OverviewListItemPagination
                    toolTipNext={t("processes_tab:next")}
                    toolTipPrevious={t("processes_tab:previous")}
                    list={listOfProcesses}
                    setDisplayableChunk={setDisplayableProcesses}
                    numberDescriptionText={t("processes_tab:outOf")}
                    numberOfPagesDescriptionText={t("processes_tab:processesPerPage")}
                    onPageChange={setPageIndex}
                    onItemsPerPageChange={setItemsPerPage}
                    page={pageIndex}
                    itemsPerPage={itemsPerPage}
                  />
                  {displayableProcesses.length === 0 && <div className={classes.noProduct}>{t("no_processes")}</div>}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <QuestionnaireSubHeader text={t("attachments")} />
            </Grid>
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                <div>
                  <AttachmentsOverviewOBS
                    disabled={noWritePermission}
                    docId={documentId}
                    setInfo={setInfo}
                    setMeta={setMeta}
                    category={"datasubjectrequests"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataSubjectRequestsPageButtons />
            </Grid>
          </Grid>
        </div>
      }
    </DocView>
  );

  return (
    <DocMetaView
      docViewContent={docViewContent}
      metaViewContent={
        <MetaView
          docId={documentId}
          tabs={["assistant", "comments", "todos"]}
          collection={COLLECTIONS.DATA_SUBJECT_REQUESTS}
          translationKey={"data_subject_requests_overview_general_page"}
        />
      }
    />
  );
}
