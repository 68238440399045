import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { useMetaView } from "../../../contexts/meta-view-context";
import { GetApp } from "@material-ui/icons";
import { exportDeletionConceptExcel } from "./utils/createDeletionConceptExcelExportData";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { DeletionConceptOverviewMetaView } from "./DeletionConceptOverviewMetaView";
import { useLocation } from "react-router-dom";
import { useDataTypeTree } from "app/api/dataAssetApi";

const DeletionConceptOverview = () => {
  const { setInfo } = useMetaView();
  const { t } = useTranslation("deletion_concept_overview");
  const isExportEnabled = useIsFeaturePresent(FEATURES.DELETION_CONCEPT);
  const overviewState = useOverviewState()[COLLECTIONS.DELETION_CONCEPT];
  const { auth } = useAuthentication();
  const location = useLocation();
  const dispatch = useOverviewDispatch();

  const dataTypeTree = useDataTypeTree();

  const [selectedPersonGroupAndDataCategory, setSelectedPersonGroupAndDataCategory] = useState<{
    readonly personGroupId: string;
    readonly dataCategoryId: string;
  } | null>(null);

  useEffect(() => {
    dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.DELETION_CONCEPT, selectedId: null });
  }, [dispatch, location]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("deletion_concept_overview:enteringInfoCardTitle"),
        text: t("deletion_concept_overview:enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const onRowClick = useCallback(item => {
    setSelectedPersonGroupAndDataCategory({
      personGroupId: item.personGroupId,
      dataCategoryId: item.dataCategoryId
    });
  }, []);

  const onExportAll = useCallback(() => {
    if (!auth?.tenantId) {
      return;
    }

    return exportDeletionConceptExcel({
      tenantId: auth?.tenantId,
      t,
      orgUnitIds: Array.isArray(overviewState.filter.orgUnitIds) ? overviewState.filter.orgUnitIds : undefined,
      personGroupIds: Array.isArray(overviewState.filter.personGroupId)
        ? overviewState.filter.personGroupId
        : undefined,
      dataStorageIds: Array.isArray(overviewState.filter.dataStorageIds)
        ? overviewState.filter.dataStorageIds
        : undefined
    });
  }, [auth?.tenantId, t, overviewState.filter]);

  const toolbarActions = useMemo(
    () =>
      [
        { action: "sort" },
        { action: "filter" },
        isExportEnabled
          ? [{ action: "export", tooltip: t("overview:download"), onHandle: onExportAll, icon: <GetApp /> }]
          : []
      ].flat(2),
    [onExportAll, isExportEnabled, t]
  );

  const defaultMetaView = useMemo(() => <MetaView translationKey={"deletion_concept_overview"} />, []);

  const metaViewContent = selectedPersonGroupAndDataCategory ? (
    <DeletionConceptOverviewMetaView
      personGroupId={selectedPersonGroupAndDataCategory.personGroupId}
      dataCategoryId={selectedPersonGroupAndDataCategory.dataCategoryId}
    />
  ) : (
    defaultMetaView
  );

  if (dataTypeTree.isLoading) {
    // fix for timing-issue, causing missing personGroup filters with many personGroups
    // when getOverview called before dataTypeTree.data is populated
    return null;
  }

  return (
    <DocMetaView metaViewContent={metaViewContent}>
      <Overview
        header={t("header")}
        collection={COLLECTIONS.DELETION_CONCEPT}
        onRowClick={onRowClick}
        toolbarActions={toolbarActions}
        selectable={true}
        toolbarMode="tabs"
        showSelectAll={false}
      />
    </DocMetaView>
  );
};

export default React.memo(DeletionConceptOverview);
