import React, { useEffect, useRef } from "react";

export const Canvas = props => {
  const { draw, width, height, options, contextType = "2d", ...rest } = props;

  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext(contextType);

    draw(context);
  }, [draw]);

  return <canvas width={width} height={height} ref={canvasRef} {...rest} />;
};
