import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { RESOURCE_TYPE } from "app/handlers/resourceHandler";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type MergeType = "mergeIntoExisting" | "createNew";
export const MERGE_TYPES = {
  mergeIntoExisting: "mergeIntoExisting",
  createNew: "createNew"
} as const satisfies Record<string, MergeType>;

const MergeTypeSelector = ({
  mergeDecision,
  onMergeDecisionChanged,
  resourceType
}: {
  readonly resourceType: RESOURCE_TYPE;
  readonly mergeDecision: MergeType;
  readonly onMergeDecisionChanged: (value: MergeType) => void;
}) => {
  const { t } = useTranslation(`resources_${resourceType}_overview`);

  const onMergeDecisionChangedCallback = useCallback(
    (event, value) => {
      onMergeDecisionChanged(value);
    },
    [onMergeDecisionChanged]
  );

  return (
    <RadioGroup
      row
      name={t(`mergeModal:merge_name_input_title`, t("common:name"))}
      value={mergeDecision}
      onChange={onMergeDecisionChangedCallback}
    >
      <FormControlLabel
        control={
          <Radio checked={mergeDecision === MERGE_TYPES.createNew} color="primary" name={t("common:createNew")} />
        }
        label={t("common:createNew")}
        value={MERGE_TYPES.createNew}
      />
      <FormControlLabel
        control={
          <Radio
            checked={mergeDecision === MERGE_TYPES.mergeIntoExisting}
            color="primary"
            name={t("common:mergeExisting")}
          />
        }
        label={t("common:mergeExisting")}
        value={MERGE_TYPES.mergeIntoExisting}
      />
    </RadioGroup>
  );
};

export default MergeTypeSelector;
