interface Storage {
  save(key: string, input: string): Promise<void>;
  get(key: string): Promise<string>;
  clearAll(): Promise<void>;
}

const redirectStorageNamespace = "caralegal::path::";

const sessionStorageEngine: Storage = {
  async get(key: string): Promise<string> {
    const item = window.sessionStorage.getItem(`${redirectStorageNamespace}${key}`);
    return item || "";
  },
  async save(key: string, input: string): Promise<void> {
    window.sessionStorage.setItem(`${redirectStorageNamespace}${key}`, input);
  },
  async clearAll(): Promise<void> {
    window.sessionStorage.removeItem(`${redirectStorageNamespace}redirect`);
  }
};

export const getRedirectFullPath = async (): Promise<string> => {
  const redirectPath = await sessionStorageEngine.get("redirect");
  return redirectPath;
};

export const storeRedirectFullPathInSession = async (fullPath: string) => {
  await sessionStorageEngine.save("redirect", fullPath);
};

export const clearRedirectFullPathSession = sessionStorageEngine.clearAll;
