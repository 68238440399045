import axios, { AxiosError } from "axios";

export const isAxiosErrorWithCode = (
  error: any,
  status: number,
  errorCode?: string,
  errorBodyPart?: string
): error is AxiosError => {
  if (!axios.isAxiosError(error)) {
    return false;
  }
  if (!error.response) {
    return false;
  }
  if (error.response.status !== status) {
    return false;
  }
  if (errorCode && !((error.response.data as any).error?.toLowerCase?.() === errorCode.toLowerCase())) {
    return false;
  }
  if (errorBodyPart && !(error.response.data as any).message?.toLowerCase?.().includes?.(errorBodyPart.toLowerCase())) {
    return false;
  }

  return true;
};

export interface WithAbortController {
  readonly abortController?: AbortController;
}

export const withAbortController = (config: {
  executeFn: (abort: AbortController) => Promise<void>;
  onAbort?: () => Promise<void>;
  onError?: (error: unknown) => Promise<void>;
}): (() => void) => {
  const abortController = new AbortController();
  const init = async () => {
    try {
      await config.executeFn(abortController);
    } catch (error) {
      if (axios.isCancel(error)) {
        await config.onAbort?.();
        return;
      }
      if (config.onError) {
        await config.onError(error);
      } else {
        throw error;
      }
    }
  };
  init();
  return () => abortController.abort(); // returns a function to trigger http abort
};
