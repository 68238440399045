import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, TextField } from "@material-ui/core";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import AuthenticationPageTemplate, { AuthenticationAlert } from "./AuthenticationPageTemplate";
import { useNavigate } from "react-router-dom";
import { useAvailabilityOfServices } from "hook/servicesAvailability";
import { useSSOConfigs } from "hook/useSSOConfigs";
import { SSOLoginButton } from "components/SSOLoginButton";
import Divider from "@material-ui/core/Divider";
import { useTenantsAutoSelect } from "hook/useTenants";
import { useErrorSnackbar } from "hook/errorSnackbar";

import {
  MFAEnforced,
  MFALoginRequired,
  TooManyWrongPasswordAttempts,
  WrongCredentials
} from "../../handlers/authentication/authenticationError";
import { paReadPermissionExists } from "../../defaultRolePageAccess";

export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation("authentication");
  const { auth, loading: userLoading, signInUserHook, user: loggedInUser } = useAuthentication();
  const [email, setEmail] = useState("");
  const { ssoConfig, usedSSOs } = useSSOConfigs({ typedEmail: email });

  const [password, setPassword] = useState("");
  const [mfaRequired, setMFARequired] = useState(false);
  const [mfaToken, setMFAToken] = useState("");

  const [alertNotification, setAlertNotification] = useState<AuthenticationAlert | null>(null);
  const [currentError, setCurrentError] = useState<any>(null);
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);
  const servicesStatuses = useAvailabilityOfServices();
  const { catchAsSnackbar } = useErrorSnackbar();
  const { autoSelectTenantOrPickOne } = useTenantsAutoSelect();
  useEffect(() => {
    autoSelectTenantOrPickOne().catch(catchAsSnackbar("Failed to select tenant"));
  }, [autoSelectTenantOrPickOne, catchAsSnackbar]);

  useEffect(() => {
    if (servicesStatuses?.statusAll?.error) {
      setCurrentError({ code: servicesStatuses?.statusAll?.error });
    }
  }, [servicesStatuses]);

  useEffect(() => {
    setLoginButtonDisabled(!(email && password));
  }, [email, password]);

  const handleKnownError = useCallback(
    (error: unknown) => {
      if (error instanceof WrongCredentials) {
        setAlertNotification({
          title: t(mfaRequired ? "error_incorrect_mfa_code" : "error_incorrect_email_password"),
          severity: "error"
        });
        return;
      }

      if (error instanceof TooManyWrongPasswordAttempts) {
        setAlertNotification({
          title: t("too_many_wrong_attempts"),
          severity: "error"
        });
        return;
      }

      if (error instanceof MFAEnforced) {
        setAlertNotification({
          title: t("mfa_enforced"),
          severity: "error"
        });
        return;
      }

      if (typeof error === "object" && error && "code" in error && error.code && typeof error.code === "string") {
        switch (error.code) {
          case "unknown_response":
            setAlertNotification({
              header: t("header"),
              title: t("unknown_response"),
              severity: "error"
            });
            return;
          case "no_connection":
            setAlertNotification({
              title: t("no_connection"),
              severity: "error"
            });
            return;
          default:
            setAlertNotification({
              title: `${t("error_unable_to_login")} ${error.code}`,
              severity: "error"
            });
            return error;
        }
      }
    },
    [t, mfaRequired]
  );

  const loginCallback = useCallback(
    async event => {
      setAlertNotification(null); // reset alert
      setCurrentError(null);
      event.preventDefault();
      try {
        await signInUserHook(email, password, mfaToken);
      } catch (error) {
        if (error instanceof MFALoginRequired) {
          setMFARequired(true);
          setAlertNotification(null);
          return;
        }
        setCurrentError(error);
        return;
      }
      setAlertNotification({
        title: t("login_successful"),
        severity: "success"
      });
    },
    [signInUserHook, t, email, password, mfaToken]
  );

  useEffect(() => {
    if (currentError) {
      const unknownError = handleKnownError(currentError);
      if (unknownError) {
        throw unknownError;
      }
    }
  }, [currentError, handleKnownError]);

  useEffect(() => {
    if (auth?.uid) {
      if (!paReadPermissionExists(auth.permissions)) {
        navigate("/tasks/my");
      } else {
        navigate("/processes");
      }
    }
  }, [auth?.permissions, auth?.uid, navigate]);

  const setEmailCallback = useCallback(event => {
    setEmail(event.target.value);
  }, []);

  const setPasswordCallback = useCallback(event => {
    setPassword(event.target.value);
  }, []);

  const setMFATokenCallback = useCallback(event => {
    setMFAToken((event.target.value || "").trim());
  }, []);

  const onEnterKeyPressAttemptLogin = useCallback(
    async event => {
      if (event.key === "Enter") {
        await loginCallback(event);
      }
    },
    [loginCallback]
  );

  const onResetPasswordClickedCallback = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  const usedSSOComponents = (
    <>
      <Box
        mt={2}
        mb={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gridGap: 16
        }}
      >
        {usedSSOs.map(usedSSO => (
          <SSOLoginButton key={`used-sso-${usedSSO.callbackIdentifier}`} ssoConfig={usedSSO} />
        ))}
      </Box>
      <Box mt={5} mb={3}>
        <Divider />
      </Box>
    </>
  );

  const passwordField = (
    <Box mt={2} mb={1}>
      <TextField
        fullWidth
        type="password"
        value={password}
        onChange={setPasswordCallback}
        variant="outlined"
        label={t("password")}
        onKeyDown={onEnterKeyPressAttemptLogin}
      />
    </Box>
  );

  const mfaTokenField = (
    <Box mt={2} mb={1}>
      <TextField
        fullWidth
        autoComplete="off"
        label={t("code")}
        type="number"
        placeholder="123456"
        value={mfaToken}
        onChange={setMFATokenCallback}
        variant="outlined"
        onKeyDown={onEnterKeyPressAttemptLogin}
      />
    </Box>
  );

  const [enterKeyPressTriggerHack, setEnterKeyPressTriggerHack] = useState(false);
  const onEnterKeyPressAttemptSSOLogin = useCallback(
    async event => {
      if (event.key === "Enter" && ssoConfig) {
        setEnterKeyPressTriggerHack(true);
      }
    },
    [ssoConfig]
  );
  const mainSSOLoginButton = ssoConfig && (
    <Box mt={2} mb={ssoConfig?.ssoOnly ? 5 : 1} sx={{ display: "flex", justifyContent: "center" }}>
      <SSOLoginButton ssoConfig={ssoConfig} enterKeyPressTriggerHack={enterKeyPressTriggerHack} />
    </Box>
  );

  const normalLoginButton = (
    <Box mt={2} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        data-qa="login"
        variant="contained"
        type="submit"
        color="primary"
        disabled={loginButtonDisabled}
        onClick={loginCallback}
      >
        {userLoading && (
          <Box mr={1} sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress color="inherit" size={16} />
          </Box>
        )}
        {t("login")}
      </Button>
    </Box>
  );

  const forgetPasswordButton = (
    <Box mt={2} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
      <Button data-qa="forgot_password" color="primary" onClick={onResetPasswordClickedCallback}>
        {t("forgot_password")}
      </Button>
    </Box>
  );

  return (
    <AuthenticationPageTemplate alertNotification={alertNotification}>
      {!loggedInUser && (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
          {!ssoConfig && usedSSOs.length > 0 && usedSSOComponents}
          <Box mt={2} mb={1}>
            <TextField
              fullWidth
              type="email"
              value={email}
              onChange={setEmailCallback}
              onKeyDown={onEnterKeyPressAttemptSSOLogin}
              variant="outlined"
              label={t("email")}
            />
          </Box>
          {ssoConfig && mainSSOLoginButton}
          {!ssoConfig?.ssoOnly && passwordField}
          {!ssoConfig?.ssoOnly && mfaRequired && mfaTokenField}
          {!ssoConfig?.ssoOnly && normalLoginButton}
          {!ssoConfig?.ssoOnly && forgetPasswordButton}
        </Box>
      )}
    </AuthenticationPageTemplate>
  );
}
