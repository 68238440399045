import React, { useCallback, useEffect, useMemo, useState } from "react";
import { changeLastUrlPath } from "./url-handler";
import GeneralPageButtons from "./GeneralPageButtons";
import { useNavigate } from "react-router-dom";
import { usePathName } from "../../app/router/router-custom-hooks";

const GeneralPageButtonPagination = ({
  steps,
  leftArea,
  rightArea
}: {
  readonly steps: {
    readonly label: string;
    readonly path: string;
  }[];
  readonly leftArea?: React.ReactNode;
  readonly rightArea?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const path = usePathName();
  const [currentLastPath, setCurrentLastPath] = useState("");

  useEffect(() => {
    const lastPath = path.split("/").pop();
    setCurrentLastPath(lastPath || "");
  }, [path]);

  const primaryButtonClicked = useCallback(() => {
    const currentStepIndex = steps.findIndex(step => currentLastPath === step.path);
    const nextStep = steps[currentStepIndex + 1];
    if (nextStep?.path) {
      navigate(changeLastUrlPath(path, nextStep.path));
    }
  }, [currentLastPath, steps, navigate, path]);

  const secondaryButtonClicked = useCallback(() => {
    const currentStepIndex = steps.findIndex(step => currentLastPath === step.path);
    const prevStep = steps[currentStepIndex - 1];
    if (prevStep?.path) {
      navigate(changeLastUrlPath(path, prevStep.path));
    }
  }, [currentLastPath, steps, navigate, path]);

  const isLastStep = currentLastPath === steps[steps.length - 1]?.path;
  const isFirstStep = currentLastPath === steps[0]?.path;

  const primaryButton = useMemo(() => {
    if (isLastStep) {
      return undefined;
    }
    return {
      onClick: primaryButtonClicked
    };
  }, [isLastStep, primaryButtonClicked]);

  const secondaryButton = useMemo(() => {
    if (isFirstStep) {
      return undefined;
    }
    return {
      onClick: secondaryButtonClicked
    };
  }, [isFirstStep, secondaryButtonClicked]);

  return (
    <GeneralPageButtons
      leftArea={leftArea}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      rightArea={rightArea}
    />
  );
};

export default GeneralPageButtonPagination;
