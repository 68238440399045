import { defaultAxios } from "app/api/axios/axiosProvider";
import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { isAxiosErrorWithCode, WithAbortController } from "../axios/axiosErrorHandler";

export const axiosInstance: AxiosInstance = defaultAxios({
  baseURL: `${apiEndpoints.userUrl}/api/public/sso`
});

export interface SSOConfigDTO {
  readonly name?: string;
  readonly ssoOnly?: boolean;
  readonly callbackIdentifier?: string;
}

export interface SSOConfigGETParam extends WithAbortController {
  readonly emailDomain?: string;
  readonly callbackIdentifier?: string;
}
export const getOAuth2SSOConfigApi = async (param: SSOConfigGETParam): Promise<SSOConfigDTO | null> => {
  try {
    const response = await axiosInstance.get<SSOConfigDTO>("/oidc", {
      params: {
        domain: param.emailDomain,
        callbackIdentifier: param.callbackIdentifier
      },
      signal: param.abortController?.signal
    });
    return response.data || {};
  } catch (error) {
    if (isAxiosErrorWithCode(error, 400, "Invalid SSO")) {
      return null;
    }
    throw error;
  }
};

export const getOAuth2SSOAuthUrlApi = async (
  payload: Pick<SSOConfigDTO, "callbackIdentifier">
): Promise<string | null> => {
  try {
    const response = await axiosInstance.get<{ readonly authUrl?: string }>(`/oidc/${payload.callbackIdentifier}/auth`);
    return response.data?.authUrl || null;
  } catch (error) {
    if (isAxiosErrorWithCode(error, 400, "Invalid SSO")) {
      return null;
    }
    throw error;
  }
};
