import { useState, useCallback } from "react";

export function useDocumentUpload(reloadOverview: () => void) {
  const [loading, setLoading] = useState<boolean>(false);

  const onUploadStart = useCallback(() => setLoading(true), []);

  const onUploadEnd = useCallback(() => {
    setLoading(false);
    reloadOverview();
  }, [reloadOverview]);

  return {
    loading,
    onUploadStart,
    onUploadEnd
  };
}
