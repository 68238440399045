import { useTranslation } from "react-i18next";
import React from "react";
import { Button, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import TextBody2 from "../../../../../components/TextBody2/TextBody2";

const ChipsMergingConfirmationModal = ({
  chipTitleModalOpen,
  handleChipTitleModalClose,
  currentSelectedChipTitle,
  dispatch
}) => {
  const { t } = useTranslation("questionnaires");

  return (
    <Dialog
      open={chipTitleModalOpen}
      onClose={handleChipTitleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <TextBody2 text={t("chipsMergingConfirmation")} />
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={e => {
            e.preventDefault();
            handleChipTitleModalClose();
          }}
          variant="outlined"
          color="primary"
        >
          {t("fields:no")}
        </Button>
        <Button
          onClick={e => {
            e.preventDefault();
            handleChipTitleModalClose();
            dispatch(currentSelectedChipTitle);
          }}
          variant="contained"
          color="primary"
        >
          {t("fields:yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChipsMergingConfirmationModal;
