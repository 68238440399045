const SERVICE_TYPES_KEYS = [
  "SERVICEPROVIDER",
  "PARENTCOMPANY",
  "SUBSIDIARY",
  "AFFILIATE",
  "PARTNER",
  "CUSTOMER",
  "OTHER"
];

export default SERVICE_TYPES_KEYS;
