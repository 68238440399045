import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { isAxiosErrorWithCode } from "../api/axios/axiosErrorHandler";
import { DefaultApi } from "../api/generated/process-service";
import { apiEndpoints } from "../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../api/axios/loggedInAxiosProvider";

export interface ProcessMetaProps {
  readonly id: string;
  readonly title: string;
  readonly status: string;
  readonly relativeOrgUnitIds: string[];
  readonly assignUserIds: string[];
}

export interface ProcessPageContextProps {
  readonly processMeta: ProcessMetaProps | null;
  readonly setProcessMeta: (processMeta: ProcessMetaProps) => void;
  readonly onBeforeProcessUpdate: <T>(
    updateFunction: () => Promise<T>,
    onCancel?: () => Promise<void> | void
  ) => Promise<T>;

  /**
   * Tells the page to reset the page if the value is bigger than 0
   */
  readonly resetSignal: number;
}

export const PROCESS_STATUSES = {
  edit: "edit",
  dpiaApproved: "DPIAApproved",
  review: "review",
  approved: "approved"
};

const ProcessPageContext = createContext<ProcessPageContextProps>({
  processMeta: null,
  setProcessMeta: (processMeta: ProcessMetaProps) => {
    // do
  },
  onBeforeProcessUpdate: async updateFunction => {
    return updateFunction();
  },
  resetSignal: 0
});

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());
export const ProcessPageProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [processMeta, setProcessMeta] = useState<ProcessMetaProps | null>(null);
  const [afterEditConfirmFunction, setAfterEditConfirmFunction] = useState<(() => Promise<void>) | null>(null);
  const [afterEditCancelFunction, setAfterEditCancelFunction] = useState<(() => Promise<void> | void) | null>(null);
  const [resetSignal, setResetSignal] = useState(0);

  const cancelEditConfirmation = useCallback(() => {
    setAfterEditConfirmFunction(null);
    afterEditCancelFunction?.();
    setResetSignal(Date.now());
  }, [afterEditCancelFunction]);

  const confirmEditConfirmation = useCallback(async () => {
    await afterEditConfirmFunction?.();
    setAfterEditConfirmFunction(null);
  }, [afterEditConfirmFunction]);

  const isApproved = useMemo(
    () => processMeta?.status === PROCESS_STATUSES.approved || processMeta?.status === PROCESS_STATUSES.dpiaApproved,
    [processMeta?.status]
  );
  const isReview = useMemo(() => processMeta?.status === PROCESS_STATUSES.review, [processMeta?.status]);

  const editConfirmationModalEl = useMemo(
    () => (
      <ConfirmationModal
        modalOpen={Boolean(afterEditConfirmFunction)}
        onClose={cancelEditConfirmation}
        modalTitle={t("questionnaires:edit_modal_title")}
        modalText={isReview ? t("questionnaires:edit_in_review_modal_text") : t("questionnaires:edit_modal_text")}
        buttons={[
          {
            confirmButton: false,
            title: t("common:no"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: cancelEditConfirmation
          },
          {
            confirmButton: true,
            title: t("common:yes"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: confirmEditConfirmation
          }
        ]}
      />
    ),
    [afterEditConfirmFunction, cancelEditConfirmation, t, isReview, confirmEditConfirmation]
  );

  const onBeforeProcessUpdate: ProcessPageContextProps["onBeforeProcessUpdate"] = useCallback(
    (updateFunction, onCancel) => {
      const executeChangerFn = async (): ReturnType<typeof updateFunction> => {
        const executeUpdate = async (): ReturnType<typeof updateFunction> => {
          try {
            return await updateFunction();
          } catch (error: unknown) {
            const message = isAxiosErrorWithCode(error, 403)
              ? t("metaview_tabs:readOnlyAlert")
              : (error && typeof error === "object" && "message" in error && error.message) || "Error";
            enqueueSnackbar(message, { variant: "error" });
            throw error;
          }
        };

        if (processMeta && isApproved) {
          await processClient.editProcess(processMeta.id);
          const result = await executeUpdate();
          // trigger update for the processingActivity-context.jsx
          setProcessMeta({ ...processMeta, status: PROCESS_STATUSES.edit });
          return result;
        }
        if (processMeta && isReview) {
          await processClient.editProcess(processMeta.id);
          const result = await executeUpdate();
          setProcessMeta({ ...processMeta, status: PROCESS_STATUSES.edit });
          return result;
        }
        return executeUpdate();
      };

      return new Promise((resolve, reject) => {
        if (isApproved) {
          if (onCancel) {
            setAfterEditCancelFunction(() => () => onCancel());
          }
          setAfterEditConfirmFunction(() => () => executeChangerFn().then(resolve).catch(reject));
        } else {
          executeChangerFn().then(resolve).catch(reject);
        }
      });
    },
    [enqueueSnackbar, isApproved, isReview, processMeta, t]
  );

  const [contextValue, setContextValue] = useState<ProcessPageContextProps>({
    processMeta,
    setProcessMeta,
    onBeforeProcessUpdate,
    resetSignal
  });
  useEffect(() => {
    setContextValue({
      processMeta,
      setProcessMeta,
      onBeforeProcessUpdate,
      resetSignal
    });
  }, [processMeta, setProcessMeta, onBeforeProcessUpdate, resetSignal]);

  return (
    <ProcessPageContext.Provider value={contextValue}>
      {children}
      {editConfirmationModalEl}
    </ProcessPageContext.Provider>
  );
};

export const useProcessPage = () => useContext(ProcessPageContext);
