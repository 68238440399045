import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import { MeDTO, UpdateMePayload, UpdateMFAPayload, UpdatePasswordPayload } from "./userMeApiInterfaces";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api/users`
});

export const getMeApi = async (): Promise<MeDTO> => {
  const response = await axiosInstance.get<MeDTO>("/me");
  return response.data || {};
};

export const updateMeApi = async (payload: UpdateMePayload): Promise<void> => {
  await axiosInstance.patch("/me", payload);
};

export const updateMyPasswordApi = async (payload: UpdatePasswordPayload): Promise<void> => {
  await axiosInstance.put("/me/passwords", payload);
};

export const updateMyMFAApi = async (payload: UpdateMFAPayload): Promise<void> => {
  await axiosInstance.put("/me/mfas", payload);
};

export const deleteMyMFAApi = async (): Promise<void> => {
  await axiosInstance.delete("/me/mfas");
};

export interface MeEnforcementInfoDTO {
  readonly mfaGracePeriodEndsAt: string | null;
  readonly mfaEnforced: boolean;
  readonly ssoOnly: boolean;
}

export const getEnforcementInfoApi = async (): Promise<Partial<MeEnforcementInfoDTO>> => {
  const response = await axiosInstance.get<MeEnforcementInfoDTO>("/me/enforcements");
  return response.data || {};
};
