import React, { useCallback, useState } from "react";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import DataLocationOverview from "./DataLocationOverview";
import DataLocationDetailsInMetaview from "./DataLocationDetailsInMetaview";

export const DataLocationOverviewPage = () => {
  const [previewDataLocationId, setPreviewDataLocationId] = useState<string | null>(null);
  const onPreviewDataLocationSelected = useCallback(
    (dataLocation: { readonly id: string }) => setPreviewDataLocationId(dataLocation.id),
    []
  );
  const [reloadKey, setReloadKey] = useState<string>("");

  const onReloadMetaview = useCallback(
    (reloadKey: { readonly reloadKey: string }) => setReloadKey(reloadKey.reloadKey),
    []
  );

  const metaViewContent = previewDataLocationId ? (
    <DataLocationDetailsInMetaview dataLocationId={previewDataLocationId} key={reloadKey} />
  ) : (
    <MetaView translationKey={"service_providers_overview"} />
  );

  return (
    <DocMetaView metaViewContent={metaViewContent}>
      <DataLocationOverview onDataLocationClicked={onPreviewDataLocationSelected} onReloadMetaview={onReloadMetaview} />
    </DocMetaView>
  );
};
