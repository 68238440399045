import React from "react";
import { SeenBadge } from "./SeenBadge";
import { UntranslatedCountBadge } from "./UntranslatedCountBadge";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";

const ResourceBadgeComponent = ({
  id,
  seen
}: {
  readonly kind: string;
  readonly seen?: boolean;
  readonly id: string;
}) => {
  const isMultilingualEnabled = !!useIsFeaturePresent(FEATURES.MULTILINGUAL);

  return (
    <>
      {seen === false && <SeenBadge />}
      {isMultilingualEnabled && <UntranslatedCountBadge id={id} />}
    </>
  );
};

export const ResourceBadge = React.memo(ResourceBadgeComponent);
