import { useGetAITechDocApi, useUpdateAITechDocApi } from "../../../api/aiTechDocApi";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { UpdateAITechDocDTO } from "../../../api/generated/aitechdoc-service";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";

export const AITechDocTechnicalDetail = ({ readonly, id }: { readonly readonly?: boolean; readonly id: string }) => {
  const { data: aiTechDoc } = useGetAITechDocApi({ documentId: id || "" });
  const { updateAITechDoc } = useUpdateAITechDocApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAITechDocDTO, UpdateAITechDocDTO>({
    triggerUpdate: updateAITechDoc
  });

  const { auth } = useAuthentication();

  const { t } = useTranslation("aitechdoc_details");
  const { setInfo } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const [hardwareRequirements, setHardwareRequirements] = useState<string>("");
  const [hasMultipleConfigs, setHasMultipleConfigs] = useState<boolean | null>(null);
  const [hasMultipleConfigsExplanation, setHasMultipleConfigsExplanation] = useState<string>("");
  const [hasAdminSettingHyperParameters, setHasAdminSettingHyperParameters] = useState<boolean | null>(null);
  const [hasAdminSettingHyperParametersExplanation, setHasAdminSettingHyperParametersExplanation] =
    useState<string>("");
  const [energyConsumptionExplanation, setEnergyConsumptionExplanation] = useState<string>("");

  useEffect(() => {
    if (aiTechDoc) {
      setHardwareRequirements(aiTechDoc.hardwareRequirements || "");
      setHasMultipleConfigs(aiTechDoc.hasMultipleConfigs === undefined ? null : aiTechDoc.hasMultipleConfigs);
      setHasMultipleConfigsExplanation(aiTechDoc.hasMultipleConfigsExplanation || "");
      setHasAdminSettingHyperParameters(
        aiTechDoc.hasAdminSettingHyperParameters === undefined ? null : aiTechDoc.hasAdminSettingHyperParameters
      );
      setHasAdminSettingHyperParametersExplanation(aiTechDoc.hasAdminSettingHyperParametersExplanation || "");
      setEnergyConsumptionExplanation(aiTechDoc.energyConsumptionExplanation || "");
    }
  }, [aiTechDoc]);

  const onHardwareRequirementsChange = useCallback(
    (value: string) => {
      setHardwareRequirements(value);
      queueUpdates({ hardwareRequirements: value });
    },
    [queueUpdates]
  );

  const onHasMultipleConfigsChange = useCallback(
    (value: boolean) => {
      setHasMultipleConfigs(value);
      queueUpdates({ hasMultipleConfigs: value });
    },
    [queueUpdates]
  );

  const onHasMultipleConfigsExplanationChange = useCallback(
    (value: string) => {
      setHasMultipleConfigsExplanation(value);
      queueUpdates({ hasMultipleConfigsExplanation: value });
    },
    [queueUpdates]
  );

  const onHasAdminSettingHyperParametersChange = useCallback(
    (value: boolean) => {
      setHasAdminSettingHyperParameters(value);
      queueUpdates({ hasAdminSettingHyperParameters: value });
    },
    [queueUpdates]
  );

  const onHasAdminSettingHyperParametersExplanationChange = useCallback(
    (value: string) => {
      setHasAdminSettingHyperParametersExplanation(value);
      queueUpdates({ hasAdminSettingHyperParametersExplanation: value });
    },
    [queueUpdates]
  );

  const onEnergyConsumptionExplanationChange = useCallback(
    (value: string) => {
      setEnergyConsumptionExplanation(value);
      queueUpdates({ energyConsumptionExplanation: value });
    },
    [queueUpdates]
  );

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("technicalDetails")} />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.TEXT_EDITOR}
          questionId={"hardwareRequirements"}
          questionName={t("hardwareRequirements")}
          value={hardwareRequirements}
          onChange={onHardwareRequirementsChange}
          disabled={readonly}
        />
      </Box>
      <Box mt={4}>
        <Question
          qType={QUESTION_TYPE.YES_NO}
          questionId={"hasMultipleConfigs"}
          questionName={t("hasMultipleConfigs")}
          title={t("hasMultipleConfigs")}
          value={hasMultipleConfigs}
          onChange={onHasMultipleConfigsChange}
          disabled={readonly}
        />
      </Box>
      {hasMultipleConfigs ? (
        <Box mt={-4}>
          <Question
            qType={QUESTION_TYPE.TEXT_EDITOR}
            questionId={"hasMultipleConfigsExplanation"}
            questionName={t("hasMultipleConfigsExplanation")}
            value={hasMultipleConfigsExplanation}
            onChange={onHasMultipleConfigsExplanationChange}
            disabled={readonly}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box mt={4}>
        <Question
          qType={QUESTION_TYPE.YES_NO}
          questionId={"hasAdminSettingHyperParameters"}
          questionName={t("hasAdminSettingHyperParameters")}
          title={t("hasAdminSettingHyperParameters")}
          value={hasAdminSettingHyperParameters}
          onChange={onHasAdminSettingHyperParametersChange}
          disabled={readonly}
        />
      </Box>
      {hasAdminSettingHyperParameters ? (
        <Box mt={-4}>
          <Question
            qType={QUESTION_TYPE.TEXT_EDITOR}
            questionId={"hasAdminSettingHyperParametersExplanation"}
            questionName={t("hasAdminSettingHyperParametersExplanation")}
            value={hasAdminSettingHyperParametersExplanation}
            onChange={onHasAdminSettingHyperParametersExplanationChange}
            disabled={readonly}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box>
        <Question
          qType={QUESTION_TYPE.TEXT_EDITOR}
          questionId={"energyConsumptionExplanation"}
          questionName={t("energyConsumptionExplanation")}
          value={energyConsumptionExplanation}
          onChange={onEnergyConsumptionExplanationChange}
          disabled={readonly}
        />
      </Box>
    </Box>
  );
};
