export const tryToParseJWTToken = (jwtToken: string): any | null => {
  const tokenParts = jwtToken.split(".");
  if (tokenParts.length !== 3) {
    return null;
  }
  try {
    const bodyPart = window.atob(tokenParts[1]);
    return JSON.parse(bodyPart);
  } catch (e) {
    // not a jwt token
    return null;
  }
};

export const softCheckJWTTokenExpired = (jwtToken: string): boolean => {
  const jwtTokenExpiry = softParseJWTTokenExpiry(jwtToken);
  if (!jwtTokenExpiry) {
    return true;
  }

  if (new Date().getTime() > jwtTokenExpiry.getTime()) {
    return true;
  }

  return false;
};

export const softParseJWTTokenExpiry = (jwtToken: string): Date | null => {
  if (!jwtToken) {
    return null;
  }

  const jwt = tryToParseJWTToken(jwtToken);
  if (!jwt?.exp) {
    return null;
  }

  const jwtTokenExpiryMs = jwt.exp * 1000;
  return new Date(jwtTokenExpiryMs);
};
