import { safeGetMs, toJSDateObjectIfFirebaseDate } from "app/handlers/utility/date-helper";

export const createDueDateLabel = function (inputDueDate, t) {
  const dueDate = toJSDateObjectIfFirebaseDate(inputDueDate);
  let dateVariant, dateLabel;
  if (dueDate) {
    const numberOfMsInADay = 1000 * 3600 * 24;
    const dueDateDaysSinceEpoch = Math.floor(safeGetMs(dueDate) / numberOfMsInADay);
    const currentDaysSinceEpoch = Math.floor(new Date().getTime() / numberOfMsInADay);
    const differenceDays = dueDateDaysSinceEpoch - currentDaysSinceEpoch;
    if (differenceDays < 0) {
      dateVariant = "overdue";
      dateLabel = t("tasks_page:overdue");
    } else if (differenceDays === 0) {
      dateVariant = "today";
      dateLabel = t("tasks_page:due_today");
    } else if (differenceDays === 1) {
      dateVariant = "tomorrow";
      dateLabel = t("tasks_page:day_left");
    } else if (differenceDays <= 5) {
      dateVariant = "five_days";
      dateLabel = t("tasks_page:days_left", { days: differenceDays });
    } else if (differenceDays > 5) {
      dateVariant = "regular";
      const dateStringOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      };
      dateLabel = dueDate.toLocaleDateString("de-de", dateStringOptions);
    }
  } else {
    dateVariant = "regular";
    dateLabel = t("tasks_page:no_date");
  }
  return {
    dateLabel: dateLabel,
    dateVariant: dateVariant
  };
};

export const createRegularDateLabel = (inputDueDate, t) => {
  const dueDate = toJSDateObjectIfFirebaseDate(inputDueDate);
  let dateVariant, dateLabel;
  if (dueDate) {
    dateVariant = "regular";
    const dateStringOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };
    dateLabel = dueDate.toLocaleDateString("de-de", dateStringOptions);
  } else {
    dateVariant = "regular";
    dateLabel = t("tasks_page:no_date");
  }
  return {
    dateLabel: dateLabel,
    dateVariant: dateVariant
  };
};
