import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { DataBreachesPageButtons, DataBreachesPageStepper } from "./DataBreachesPagination";
import InlineTaskDetails from "../tasks/details/InlineTaskDetails";
import { createTask, deleteTask, getAllTasksByDocumentId, TYPES } from "../../handlers/tasksHandler";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useMetaView } from "../../contexts/meta-view-context";
import { getDataBreachById } from "../../handlers/dataBreachHandler";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { COLLECTIONS } from "../../collections";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import { DataBreachesMetaView } from "./DataBreachesMetaView";
import TaskDetails from "../tasks/details/TaskDetails";
import { useUserDepartments } from "../../contexts/department-context";

const DataBreachesTasksPage = ({ documentId }) => {
  const { t, i18n } = useTranslation("data_breaches_tasks_page");
  const { setInfo } = useMetaView();
  const [databreach, setDatabreach] = useState(null);
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { isPartOfUserDepartments } = useUserDepartments();

  useEffect(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("enteringInfoCardText")
    });
  }, [i18n.language, setInfo, t]);

  const {
    auth: { tenantId, uid: userUID, permissions }
  } = useAuthentication();

  const noWritePermission = useMemo(() => {
    if (!databreach?.orgUnitId) return false;
    if (databreach?.assigneeUID === userUID || databreach?.dpoUID === userUID || permissions.includes("db_write_all"))
      return false;
    if (isPartOfUserDepartments(...[databreach.orgUnitId])) return false;
    return true;
  }, [databreach, isPartOfUserDepartments, userUID, permissions]);

  const [taskIDs, setTaskIDs] = useState([]);

  const [tasksLoading, setTasksLoading] = useState(false);

  const { catchAsSnackbar } = useErrorSnackbar();

  const defaultMetaViewEl = useMemo(() => <DataBreachesMetaView docName={databreach?.title} />, [databreach?.title]);
  const [metaViewEl, setMetaViewEl] = useState(defaultMetaViewEl);
  const [taskDetailsId, setTaskDetailsId] = useState(null);

  const loadTasks = useCallback(async () => {
    setTaskIDs([]);
    const tasks = await getAllTasksByDocumentId(documentId);
    setTaskIDs(tasks.map(task => task.id));
    return tasks;
  }, [documentId]);

  useEffect(() => {
    const fetchDataBreach = async () => {
      const db = await getDataBreachById(documentId);
      setDatabreach(db);
    };
    fetchDataBreach();
  }, [documentId]);

  useEffect(() => {
    loadTasks().catch(catchAsSnackbar("Failed to load tasks"));
  }, [catchAsSnackbar, databreach, loadTasks]);

  const createCustomEmptyTask = useCallback(async () => {
    const newTaskId = await createTask({
      tenantId,
      task: {
        type: TYPES.check_databreach,
        documentId,
        collection: COLLECTIONS.DATA_BREACHES,
        creatorUID: userUID,
        pageId: "tasks",
        title: ""
      }
    });
    await addToSeenItemsOfUserHook(COLLECTIONS.TASKS, newTaskId);
  }, [tenantId, documentId, userUID, addToSeenItemsOfUserHook]);

  const newTaskButtonClicked = useCallback(async () => {
    setTasksLoading(true);
    await createCustomEmptyTask();
    await loadTasks();
    setTasksLoading(false);
  }, [setTasksLoading, createCustomEmptyTask, loadTasks]);

  const onTaskDeleted = useCallback(
    deletedTaskId => {
      setTaskIDs(latestTaskIDs => {
        return latestTaskIDs.filter(taskId => taskId !== deletedTaskId);
      });
    },
    [setTaskIDs]
  );

  const onCloseTaskDetails = useCallback(() => {
    setTaskDetailsId(null);
    setMetaViewEl(defaultMetaViewEl);
  }, [defaultMetaViewEl]);
  const onTaskDelete = useCallback(
    async taskId => {
      await deleteTask(taskId);
      onCloseTaskDetails();
      setTaskIDs(latestTaskIDs => {
        return latestTaskIDs.filter(id => id !== taskId);
      });
    },
    [onCloseTaskDetails]
  );

  const buildTaskDetails = useCallback(
    (taskId, reloadId) => {
      return (
        <TaskDetails
          reloadId={reloadId}
          taskId={taskId}
          onTaskLoad={null}
          onCloseTaskDetails={onCloseTaskDetails}
          onTaskDelete={onTaskDelete}
          onTaskEdit={loadTasks}
          disabled={true}
        />
      );
    },
    [loadTasks, onCloseTaskDetails, onTaskDelete]
  );

  const onShowTaskDetails = useCallback(
    taskId => {
      setTaskDetailsId(taskId);
      setMetaViewEl(buildTaskDetails(taskId));
    },
    [buildTaskDetails]
  );

  const onTaskEdit = useCallback(
    taskId => {
      if (taskId === taskDetailsId) {
        setMetaViewEl(buildTaskDetails(taskId, Date.now()));
      }
    },
    [buildTaskDetails, taskDetailsId]
  );

  return (
    <DocMetaView metaViewContent={metaViewEl}>
      <DocView header={databreach?.title || ""} pagination={<DataBreachesPageStepper />}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <QuestionnaireSubHeader text={t("title")} />
          </Grid>
          {tasksLoading && taskIDs.length === 0 && (
            <Grid container justifyContent="center">
              <CircularProgress color="inherit" />
            </Grid>
          )}
          <Grid item container spacing={3}>
            {taskIDs.map(taskID => {
              return (
                <Grid key={taskID} item xs={12}>
                  <InlineTaskDetails
                    existingTaskId={taskID}
                    onDelete={onTaskDeleted}
                    onShowTaskDetails={onShowTaskDetails}
                    onTaskEdit={onTaskEdit}
                    disabled={noWritePermission}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                disabled={noWritePermission || tasksLoading}
                onClick={newTaskButtonClicked}
              >
                {t("add_task")}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <DataBreachesPageButtons />
            </Grid>
          </Grid>
        </Grid>
      </DocView>
    </DocMetaView>
  );
};

DataBreachesTasksPage.propTypes = {
  documentId: PropTypes.string.isRequired
};

export default DataBreachesTasksPage;
