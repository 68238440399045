import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Paper } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 26,
    marginTop: 6
  },
  paper: {
    radius: 16,
    paddingTop: 8,
    boxShadow: "0px 0px 40px 0px rgba(204,204,204,0.50)",
    padding: "5px"
  }
}));
export const GraphContainer = ({ title, height, children, loading }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} style={{ minHeight: height }}>
      <Grid container alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
        <div className={classes.title}>{title}</div>
      </Grid>
      {loading && <GraphContainerLoader />}
      {!loading && children}
    </Paper>
  );
};

GraphContainer.propTypes = {
  title: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element,
  loading: PropTypes.bool
};

GraphContainer.defaultProps = {
  height: 550,
  loading: false
};

export const GraphContainerLoader = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
