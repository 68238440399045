import { tPersonGroupDataCategoryClassification } from "../../../handlers/dataTypeTranslatorHandler";

export function removeAddText(key, addText) {
  if (key.toString().includes(addText)) {
    return key.replace(addText, "").trim();
  } else {
    return key;
  }
}

/**
 * Replace reference IDs with the object name, this function **only** for rendering purposes
 */
export const toLegacyCategoryTypeTuple = function (categoryTuple, dataById = {}) {
  const personGroup = dataById.personGroups?.[categoryTuple.personGroup];
  const category = dataById.dataCategories?.[categoryTuple.category];
  const dataType = dataById.dataTypes?.[categoryTuple.id];

  return {
    ...categoryTuple,
    personGroup: personGroup?.personGroupKey || categoryTuple.personGroup,
    personGroupDeleted: !personGroup,
    category: category?.dataCategoryKey || categoryTuple.category,
    categoryDeleted: !category,
    id: dataType?.id || categoryTuple.id,
    type: dataType?.dataTypeKey || categoryTuple.type,
    typeDeleted: !dataType,
    dataClassificationId: dataType?.dataClassificationId || categoryTuple.dataClassificationId || null
  };
};

export const getPersonGroupCategoryTupleString = function (
  categoryTuple,
  classifications,
  dataTypeTree,
  translateFunction
) {
  return tPersonGroupDataCategoryClassification({
    t: translateFunction,
    dataType: categoryTuple,
    classifications,
    dataTypeTree
  });
};

// Get the translated label for questionnaire field that has category type tuples
export const getPersonGroupCategoryTupleStrings = function (
  questionnaireTupleField,
  dataClassifications,
  dataTypeTree,
  t,
  dataById
) {
  // Map all translated keys
  const tupleStrings = questionnaireTupleField.categoryTypeTuples
    ?.map(categoryTuple =>
      getPersonGroupCategoryTupleString(
        toLegacyCategoryTypeTuple(categoryTuple, dataById),
        dataClassifications,
        dataTypeTree,
        t,
        dataById
      )
    )
    .reduce((acc, x) => acc.add(x), new Set()); // convert the array to a set to eliminate duplicates
  return tupleStrings.size > 0 ? [...tupleStrings] : [];
};
