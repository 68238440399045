import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimeLineElement from "../TimeLineElement/TimeLineElement";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  // increase width, necessary for small screens
  timeline: {}
}));

export interface VersionHistoryItem {
  readonly key?: string | null;
  readonly description: string;
  readonly userName: string;
  readonly date: Date;
  readonly dotColor: string;
  readonly additionalDescription?: string;
  readonly additionalUserName?: string;
  readonly repeated: number;
}

export interface VersionHistoryProps {
  readonly historyData: VersionHistoryItem[];
  readonly lastPage: boolean;
  readonly previousEventId?: string;
}

export const VersionHistoryItems = ({ historyData, lastPage }: VersionHistoryProps) => {
  return (
    <>
      {historyData.map((element, index, array) => {
        const lastItemInArray = index === array.length - 1;
        return (
          <TimeLineElement
            key={element.key || index}
            title={element.description}
            subtitle={element.userName}
            date={element.date}
            dotColor={element.dotColor}
            bottomConnector={lastPage && lastItemInArray}
            moreInfoTitle={element.additionalDescription}
            moreInfoSubtitle={element.additionalUserName}
            repeated={element.repeated}
          />
        );
      })}
    </>
  );
};

export const VersionHistoryWrapper = ({ children }: { readonly children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box>
      <Timeline align="alternate" className={classes.timeline}>
        {children}
      </Timeline>
    </Box>
  );
};
