import { Box, Typography } from "@material-ui/core";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { t } from "i18next";
import { useMemo } from "react";

interface MoveItemConformationProps {
  readonly openModal: boolean;
  readonly onCLose: () => void;
  readonly onConfirm: () => void;
}

const MoveItemConformation = ({ openModal, onCLose, onConfirm }: MoveItemConformationProps) => {
  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCLose
        },
        {
          confirmButton: true,
          title: t("common:confirm"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: onConfirm
        }
      ] as ConfirmationModalButtonProps[],
    [onCLose, onConfirm]
  );

  return (
    <ConfirmationModal
      variant={"info"}
      modalBody={
        <Box>
          <Typography>{t("documentCenter:moveConfirmation")}</Typography>
        </Box>
      }
      modalOpen={openModal}
      modalTitle={t("documentCenter:enterInfoHeader")}
      onClose={onCLose}
      buttons={buttons}
      modalText={""}
    />
  );
};

export default MoveItemConformation;
