import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, makeStyles, TextField, Theme, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RedoIcon from "@material-ui/icons/Redo";
import ColorizeIcon from "@material-ui/icons/Colorize";
import { uuid4 } from "@sentry/utils";
import {
  AuditQuestionPayloadActionDTO,
  AuditQuestionPayloadOptionDTO,
  AuditQuestionWithAnswerAndRemarkDTO,
  QUESTION_ACTIONS
} from "app/api/auditApi";
import { AUDIT_QUESTION } from "../designer/AuditQuestionType";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "@material-ui/icons";
import CallSplitIcon from "@material-ui/icons/CallSplit";

const styles = makeStyles((theme: Theme) => ({
  header: {
    textAligh: "center"
  },
  label: {
    color: theme.palette.grey[600],
    fontSize: "12px"
  },
  icon: {
    color: theme.palette.grey[500],
    height: "40px",
    width: "40px"
  },
  smallMultySelect: {
    "& .MuiInputBase-root": {
      padding: "0px 8px !important"
    }
  },
  conditionBlock: {
    background: theme.palette.grey[50],
    borderRadius: "8px"
  },
  actionTitle: {
    cursor: "pointer",
    "&:hover": {
      "& .MuiTypography-root": {
        textDecoration: "underline",
        color: theme.palette.primary.main
      }
    }
  }
}));

const getQuestionEditFields = (questionType: string): string[] => {
  switch (questionType) {
    case AUDIT_QUESTION.HEADING:
    case AUDIT_QUESTION.SUB_HEADING:
    case AUDIT_QUESTION.PARAGRAPH: {
      return ["type", "inGroup", "title", "showCondition"];
    }

    case AUDIT_QUESTION.DATE:
    case AUDIT_QUESTION.TEXT_INPUT_SINGLE:
    case AUDIT_QUESTION.TEXT_INPUT_MULTIPLE: {
      return ["type", "inGroup", "title", "placeholder", "showCondition"];
    }
    case AUDIT_QUESTION.CHECK_BOX:
    case AUDIT_QUESTION.RADIO_BOX:
      return ["type", "inGroup", "title", "options", "showCondition", "showAction"];

    case AUDIT_QUESTION.SELECT_BOX_SINGLE:
    case AUDIT_QUESTION.SELECT_BOX_MULTIPLE: {
      return ["type", "inGroup", "title", "placeholder", "options", "showCondition", "showAction"];
    }
    case AUDIT_QUESTION.DIVIDER: {
      return ["type", "inGroup", "showCondition"];
    }
    case AUDIT_QUESTION.GROUP: {
      return ["type", "groupName", "showCondition"];
    }
    case AUDIT_QUESTION.ORG_PROCESSING_CONSENT: {
      return ["type", "inGroup", "title"];
    }
    default:
      throw new Error(`Unknown audit block: ${questionType}`);
  }
};

const AuditQuestionTypeField = ({
  value,
  disabled,
  onChange
}: {
  readonly value: string;
  readonly disabled?: boolean;
  readonly onChange: (data: { [key: string]: string }) => void;
}) => {
  const cls = styles();
  const { t } = useTranslation("audit_details");
  const getLabels = useCallback(
    typeVal => {
      return t(`question_types_${typeVal.toLowerCase()}`);
    },
    [t]
  );

  const onChangeCallback = useCallback(
    (type: string | null) => {
      if (type) {
        onChange({ type });
      }
    },
    [onChange]
  );

  const options = useMemo(() => Object.values(AUDIT_QUESTION).filter(t => t !== AUDIT_QUESTION.GROUP), []);

  return (
    <Box mt={4} className={cls.smallMultySelect}>
      <MultiAutocomplete
        id={"questionType"}
        label={t("question_type")}
        options={options}
        selected={value}
        updateSelected={onChangeCallback}
        getOptionLabel={getLabels}
        size={"small"}
        disabled={value === AUDIT_QUESTION.GROUP || disabled}
      />
    </Box>
  );
};

const AuditQuestionTextField = ({
  label,
  value,
  field,
  disabled,
  multiline,
  onChange
}: {
  readonly label: string;
  readonly value: string;
  readonly field: string;
  readonly disabled?: boolean;
  readonly multiline?: boolean;
  readonly onChange?: (data: { [key: string]: string }) => void;
}) => {
  const [currentValue, setCurrentValue] = useState<string>("");
  const onBlurCallback = useCallback(
    event => {
      const value = event.target.value.trim();
      if (value) {
        onChange?.({ [field]: value });
      }
    },
    [field, onChange]
  );
  const onChangeCallback = useCallback(event => setCurrentValue(event.target.value), []);

  useEffect(() => setCurrentValue(value), [value]);

  return (
    <Box mt={4}>
      <TextField
        label={label}
        fullWidth
        disabled={disabled}
        value={currentValue}
        onChange={onChangeCallback}
        variant="outlined"
        margin={"none"}
        size="small"
        InputLabelProps={{
          shrink: true
        }}
        onBlur={onBlurCallback}
        multiline={multiline}
      />
    </Box>
  );
};

const AuditQuestionOptionItem = ({
  option,
  index,
  disabled,
  onRemove,
  onRename
}: {
  readonly option: {
    readonly id: string;
    readonly title: string;
    readonly tooltip?: string | null;
  };
  readonly disabled?: boolean;
  readonly index: number;
  readonly onRemove: (id: string) => void;
  readonly onRename: (id: string, title: string) => void;
}) => {
  const { t } = useTranslation("audit_details");
  const cls = styles();

  const [title, setTitle] = useState<string>("");

  useEffect(() => setTitle(option.title), [option.title]);

  const onRemoveCallback = useCallback(() => onRemove(option.id), [option.id, onRemove]);
  const onRenameCallback = useCallback(
    event => {
      const newName = event.target.value.trim();
      if (newName && newName !== option.title) {
        onRename(option.id, event.target.value);
      }
    },
    [option.title, option.id, onRename]
  );
  const onChangeCallback = useCallback(event => setTitle(event.target.value), []);
  return (
    <Box key={`${option.id}-${index}`} display="flex" alignItems={"center"} mt={1} ml={1}>
      <Typography className={cls.label}>{index + 1}</Typography>
      <Box mr={3} />
      <TextField
        size="small"
        fullWidth
        margin="none"
        value={title}
        variant="outlined"
        disabled={disabled}
        onBlur={onRenameCallback}
        onChange={onChangeCallback}
      />
      {!disabled && (
        <>
          <Box mr={1} />
          <Tooltip title={t("question_options_delete")}>
            <IconButton
              aria-label="delete"
              size="small"
              className={cls.icon}
              onClick={onRemoveCallback}
              disabled={disabled}
            >
              <DeleteIcon color="secondary" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

const AuditQuestionOptionsEditor = ({
  label,
  disabled,
  options,
  onChange
}: {
  readonly label: string;
  readonly disabled?: boolean;
  readonly options: AuditQuestionPayloadOptionDTO[];
  readonly onChange: ({ options }: { options: AuditQuestionPayloadOptionDTO[] }) => void;
}) => {
  const { t } = useTranslation("audit_details");
  const cls = styles();
  const [optionName, setOptionName] = useState<string>("");

  const onChangeCallback = useCallback(
    title => {
      if (title !== "") {
        const newOption = { title, id: uuid4(), tooltip: "" };
        const newOptions = [...options, newOption];
        onChange({ options: newOptions });
        setOptionName("");
      }
    },
    [onChange, options]
  );

  const onKeyDownCallback = useCallback(
    event => {
      if (event.keyCode === 13) {
        onChangeCallback(event.target.value.trim());
      }
    },
    [onChangeCallback]
  );

  const onBlurCallback = useCallback(event => onChangeCallback(event.target.value.trim()), [onChangeCallback]);

  const onChangeOptionName = useCallback(event => setOptionName(event.target.value), []);
  const onRemoveOption = useCallback(
    optionId => onChange({ options: options.filter(({ id }) => id !== optionId) }),
    [onChange, options]
  );
  const onRenameOption = useCallback(
    (optionId, newName) =>
      onChange({ options: options.map(option => (option.id === optionId ? { ...option, title: newName } : option)) }),
    [onChange, options]
  );

  const addEl = useMemo(
    () => (
      <Box ml={5} mr={6}>
        <TextField
          size="small"
          fullWidth
          margin="normal"
          placeholder={t("question_options_add")}
          variant="outlined"
          onKeyDown={onKeyDownCallback}
          onChange={onChangeOptionName}
          onBlur={onBlurCallback}
          value={optionName}
        />
      </Box>
    ),
    [onBlurCallback, onChangeOptionName, onKeyDownCallback, optionName, t]
  );
  const existOptionsEl = useMemo(
    () =>
      options.map((option, index) => (
        <AuditQuestionOptionItem
          key={`${option.id}-${index}`}
          option={option}
          disabled={disabled}
          onRemove={onRemoveOption}
          onRename={onRenameOption}
          index={index}
        />
      )),
    [disabled, onRemoveOption, onRenameOption, options]
  );
  return (
    <Box mt={4}>
      <Typography className={cls.label}>{label}</Typography>
      <Box mt={1} />
      {existOptionsEl}
      {!disabled && addEl}
    </Box>
  );
};

const AuditQuestionCondition = ({
  index,
  dependencyId,
  dependencyValueId,
  questions,
  question,
  disabled,
  onChangeConditionValue,
  onChangeConditionId,
  onDelete,
  onSelectQuestion,
  onSetConditionPickerQuestion
}: {
  readonly index: number;
  readonly dependencyId: string;
  readonly dependencyValueId: string;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly disabled?: boolean;
  readonly onChangeConditionValue: (data: { [key: string]: string }) => void;
  readonly onChangeConditionId: (oldId: string, newId: string) => void;
  readonly onDelete: (key: string) => void;
  readonly onSelectQuestion: (questionId: string) => void;
  readonly onSetConditionPickerQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO | null) => void;
}) => {
  const cls = styles();
  const { t } = useTranslation("audit_details");
  const [conditionQuestionId, setConditionQuestionId] = useState<string>("");
  const [conditionValueId, setConditionValueId] = useState<string>("");
  const [conditionValues, setConditionValues] = useState<AuditQuestionPayloadOptionDTO[] | null>(null);

  const onRemoveConditionCallback = useCallback(() => {
    onDelete(dependencyId);
    onSetConditionPickerQuestion?.(null);
  }, [onDelete, dependencyId, onSetConditionPickerQuestion]);
  const onGoToQuestionCallBack = useCallback(() => onSelectQuestion(dependencyId), [dependencyId, onSelectQuestion]);
  const onPickConditionQuestionCallBack = useCallback(
    () => onSetConditionPickerQuestion?.(question),
    [onSetConditionPickerQuestion, question]
  );

  /* SELECT DEPENDENCY QUESTION */
  const dependencyQuestionOptions = useMemo(
    () =>
      questions
        .filter(({ id, type, groupId, options }) => {
          // group is not allowed
          if (type === AUDIT_QUESTION.GROUP) {
            return false;
          }
          // question from same group is not allowed
          else if (question.id === groupId) {
            return false;
          }
          // empty option os not allowed
          else if (!options || !options.length) {
            return false;
          }
          // the same question is not allowed
          else if (id === question.id) {
            return false;
          }
          return true;
        })
        .map(({ id }) => id),
    [question.id, questions]
  );
  const getDependencyQuestionLabels = useCallback(
    questionId => questions.find(({ id }) => id === questionId)?.title || "",
    [questions]
  );
  const setDpendencyValues = useCallback(
    (questionId: string) => {
      const dependencyQuestion = questions.find(({ id }) => questionId === id);
      if (dependencyQuestion) {
        setConditionValues(dependencyQuestion.options);
      }
    },
    [questions]
  );
  const onSelectDependencyQuestion = useCallback(
    questionId => {
      if (questionId) {
        setDpendencyValues(questionId);
        onChangeConditionId(dependencyId, questionId);
      }
    },
    [dependencyId, onChangeConditionId, setDpendencyValues]
  );
  const isOptionDisabledCallback = useCallback(
    optionId => {
      return optionId !== dependencyId && question?.conditions?.[optionId] !== undefined;
    },
    [dependencyId, question?.conditions]
  );
  const questionPickerEl = useMemo(
    () => (
      <Box className={cls.smallMultySelect}>
        <MultiAutocomplete
          id={"questionPicker"}
          label={t("question_picker")}
          options={dependencyQuestionOptions}
          selected={conditionQuestionId}
          updateSelected={onSelectDependencyQuestion}
          getOptionLabel={getDependencyQuestionLabels}
          size={"small"}
          isOptionDisabled={isOptionDisabledCallback}
          disabled={disabled}
        />
      </Box>
    ),
    [
      cls.smallMultySelect,
      conditionQuestionId,
      dependencyQuestionOptions,
      disabled,
      getDependencyQuestionLabels,
      isOptionDisabledCallback,
      onSelectDependencyQuestion,
      t
    ]
  );

  /* SELECT DEPENDENCY QUESTION VALUE */
  const dependencyValueOptions = useMemo(() => (conditionValues || []).map(({ id }) => id), [conditionValues]);
  const getDependencyValueLabels = useCallback(
    valueId => (conditionValues || []).find(({ id }) => id === valueId)?.title || "",
    [conditionValues]
  );
  const onSelectDependencyValue = useCallback(
    valueId => {
      setConditionValueId(valueId);
      onChangeConditionValue({ [dependencyId]: valueId });
    },
    [dependencyId, onChangeConditionValue]
  );
  const valuePickerEl = useMemo(
    () => (
      <Box mt={4} className={cls.smallMultySelect}>
        <MultiAutocomplete
          id={"questionPicker"}
          label={t("value_picker")}
          options={dependencyValueOptions}
          selected={conditionValueId}
          updateSelected={onSelectDependencyValue}
          getOptionLabel={getDependencyValueLabels}
          size={"small"}
          disabled={disabled}
        />
      </Box>
    ),
    [
      cls.smallMultySelect,
      conditionValueId,
      dependencyValueOptions,
      disabled,
      getDependencyValueLabels,
      onSelectDependencyValue,
      t
    ]
  );

  useEffect(() => {
    setConditionQuestionId(dependencyId);
    setConditionValueId(dependencyValueId);

    if (dependencyId) {
      setDpendencyValues(dependencyId);
    }
  }, [dependencyId, setDpendencyValues, dependencyValueId]);

  return (
    <Box mt={4} px={2} pt={1} pb={4} className={cls.conditionBlock}>
      <Box display="flex" alignItems={"center"} mb={3}>
        <Box flex={1}>
          <Typography>{`${t("group_condition_block_title")} ${index + 1}`}</Typography>
        </Box>
        {!disabled && dependencyId !== "none" && (
          <Tooltip title={t("group_condition_block_goto_tooltip")}>
            <IconButton aria-label="delete" size="small" className={cls.icon} onClick={onGoToQuestionCallBack}>
              <RedoIcon color="primary" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {/* dependencyId === "none" && (
          <Tooltip title={t("group_condition_block_pick_tooltip")}>
            <IconButton aria-label="delete" size="small" className={cls.icon} onClick={onPickConditionQuestionCallBack}>
              <ColorizeIcon color="primary" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )*/}
        {!disabled && (
          <Tooltip title={t("group_condition_block_delete_tooltip")}>
            <IconButton aria-label="delete" size="small" className={cls.icon} onClick={onRemoveConditionCallback}>
              <DeleteIcon color="secondary" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {questionPickerEl}
      {valuePickerEl}
    </Box>
  );
};

const AuditQuestionConditions = ({
  conditions,
  questions,
  question,
  disabled,
  onChange,
  onSelectQuestion,
  onSetConditionPickerQuestion
}: {
  readonly conditions: Record<string, string>;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly disabled?: boolean;
  readonly onChange: (data: { conditions: Record<string, string> }) => void;
  readonly onSelectQuestion: (questionId: string) => void;
  readonly onSetConditionPickerQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO | null) => void;
}) => {
  const { t } = useTranslation("audit_details");
  const [groupCondition, setGroupCondition] = useState<Record<string, string> | null>(null);
  useEffect(() => {
    setGroupCondition(conditions || null);
  }, [conditions]);

  const onChangeConditionValue = useCallback(
    data => {
      onChange({ conditions: { ...conditions, ...data } });
    },
    [conditions, onChange]
  );

  const onChangeConditionId = useCallback(
    (oldId: string, newId: string) => {
      const data = { ...conditions };
      delete data[oldId];
      data[newId] = "";
      onChange({ conditions: data });
    },
    [conditions, onChange]
  );

  const onDeleteCondition = useCallback(
    conditionProperty => {
      const data = { ...conditions };
      delete data[conditionProperty];
      onChange({ conditions: data });
    },
    [conditions, onChange]
  );

  const addConditionCallback = useCallback(() => {
    setGroupCondition(data => ({ ...data, none: "none" }));
  }, []);

  const conditionsEl = useMemo(() => {
    return groupCondition ? (
      Object.keys(groupCondition || {}).map((conditionKey, index) => (
        <AuditQuestionCondition
          index={index}
          key={`${conditionKey}-${index}`}
          question={question}
          questions={questions}
          dependencyId={conditionKey}
          dependencyValueId={groupCondition[conditionKey]}
          disabled={disabled}
          onChangeConditionValue={onChangeConditionValue}
          onChangeConditionId={onChangeConditionId}
          onDelete={onDeleteCondition}
          onSelectQuestion={onSelectQuestion}
          onSetConditionPickerQuestion={onSetConditionPickerQuestion}
        />
      ))
    ) : (
      <></>
    );
  }, [
    disabled,
    groupCondition,
    onChangeConditionId,
    onChangeConditionValue,
    onDeleteCondition,
    onSelectQuestion,
    onSetConditionPickerQuestion,
    question,
    questions
  ]);

  const addConditionButtonEl = (
    <Box mt={6} textAlign="center">
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={addConditionCallback}
        startIcon={<CallSplitIcon fontSize="inherit" />}
      >
        {t("question_group_add_condition")}
      </Button>
    </Box>
  );

  return (
    <>
      {conditionsEl}
      {!disabled && addConditionButtonEl}
    </>
  );
};

const AuditQuestionAction = ({
  question,
  action,
  index,
  onShowQuestionAction,
  onChange,
  onRemove
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly action: AuditQuestionPayloadActionDTO;
  readonly index: number;
  readonly onShowQuestionAction: (action: AuditQuestionPayloadActionDTO) => void;
  readonly onChange: (action: AuditQuestionPayloadActionDTO) => void;
  readonly onRemove: (actionId: string) => void;
}) => {
  const { t } = useTranslation("audit_details");
  const cls = styles();

  const onRemoveActionCallback = useCallback(() => onRemove(action.id), [action.id, onRemove]);

  const dependencyValueOptions = useMemo(() => (question.options || []).map(({ id }) => id), [question.options]);
  const onSelectQuestionValue = useCallback(
    (id: string | null) => onChange({ ...action, answerValueId: id || "" }),
    [action, onChange]
  );
  const getQuestionValueLabels = useCallback(
    valueId => (question.options || []).find(({ id }) => id === valueId)?.title || "",
    [question.options]
  );
  const valuePickerEl = useMemo(
    () => (
      <Box mt={4} className={cls.smallMultySelect}>
        <MultiAutocomplete
          id={"questionPicker"}
          label={t("value_picker")}
          options={dependencyValueOptions}
          selected={action.answerValueId}
          updateSelected={onSelectQuestionValue}
          getOptionLabel={getQuestionValueLabels}
          size={"small"}
        />
      </Box>
    ),
    [
      action.answerValueId,
      cls.smallMultySelect,
      dependencyValueOptions,
      getQuestionValueLabels,
      onSelectQuestionValue,
      t
    ]
  );

  const onActionTitleClick = useCallback(() => onShowQuestionAction(action), [action, onShowQuestionAction]);

  const actionTitleEl = useMemo(
    () => (
      <Box onClick={onActionTitleClick} className={cls.actionTitle}>
        <Typography>{action.payload.title}</Typography>
      </Box>
    ),
    [action.payload.title, cls.actionTitle, onActionTitleClick]
  );

  return (
    <Box mt={4} px={2} pt={1} pb={4} className={cls.conditionBlock}>
      <Box display="flex" alignItems={"center"} mb={3}>
        <Box flex={1}>
          <Typography>{`${t("tasks_page:task")} # ${index + 1}`}</Typography>
        </Box>
        <Tooltip title={t("group_condition_block_delete_tooltip")}>
          <IconButton aria-label="delete" size="small" className={cls.icon} onClick={onRemoveActionCallback}>
            <DeleteIcon color="secondary" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      {actionTitleEl}
      {valuePickerEl}
    </Box>
  );
};

const AuditQuestionActions = ({
  question,
  disabled,
  onShowQuestionAction,
  onChange
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly disabled?: boolean;
  readonly onShowQuestionAction: (action: AuditQuestionPayloadActionDTO) => void;
  readonly onChange: (data: { actions: AuditQuestionPayloadActionDTO[] }) => void;
}) => {
  const { t } = useTranslation("audit_details");
  const [questionActions, setQuestionActions] = useState<AuditQuestionPayloadActionDTO[]>([]);

  const _disabled = useMemo(() => {
    return !question.options.length || disabled;
  }, [disabled, question.options.length]);

  useEffect(() => {
    if (question.actions) {
      setQuestionActions(question.actions);
    }
  }, [question.actions]);

  const defaultAnswerValueId = useMemo(
    () => (question.options.length ? question.options[0].id : ""),
    [question.options]
  );
  const addItemCallback = useCallback(() => {
    const newAction = {
      id: uuid4(),
      answerValueId: defaultAnswerValueId,
      actionType: QUESTION_ACTIONS.TASK,
      payload: {
        title: `${question.title} - ACTION`,
        description: "TODO",
        priority: `${question.title} - ACTION`,
        labelIds: [],
        attachmentIds: []
      }
    };

    const actions = [...(question.actions || []), newAction];
    setQuestionActions(actions);
    onShowQuestionAction(newAction);
    onChange({ actions });
  }, [defaultAnswerValueId, onChange, onShowQuestionAction, question.actions, question.title]);

  const onRemoveActionCallback = useCallback(
    (id: string) => {
      onChange({ actions: question.actions.filter(action => action.id !== id) });
    },
    [onChange, question]
  );

  const onChangeActionCallback = useCallback(
    (action: AuditQuestionPayloadActionDTO) => {
      onChange({ actions: question.actions.map(a => (a.id !== action.id ? a : action)) });
    },
    [onChange, question]
  );

  const addConditionButtonEl = (
    <Tooltip title={_disabled ? t("emptyOptionsActionsDisabledTooltip") : ""}>
      <Box mt={6} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<CheckCircle color={_disabled ? "disabled" : "primary"} />}
          onClick={addItemCallback}
          disabled={_disabled}
        >
          {t("question_add_action")}
        </Button>
      </Box>
    </Tooltip>
  );

  const actionsEl = useMemo(
    () =>
      questionActions.map((questionAction, index) => (
        <AuditQuestionAction
          key={`${questionAction.id}-${index}`}
          question={question}
          action={questionAction}
          index={index}
          onRemove={onRemoveActionCallback}
          onChange={onChangeActionCallback}
          onShowQuestionAction={onShowQuestionAction}
        />
      )),
    [onChangeActionCallback, onRemoveActionCallback, onShowQuestionAction, question, questionActions]
  );

  return (
    <>
      {actionsEl}
      {!disabled && addConditionButtonEl}
    </>
  );
};

const AuditQuestionInGroup = ({
  question,
  questions
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
}) => {
  const { t } = useTranslation("audit_details");
  const groupName: string =
    (question.groupId && questions.find(q => q.id === question.groupId && q.type === AUDIT_QUESTION.GROUP)?.title) ||
    "";
  return groupName ? (
    <Box mt={4}>
      <TextField
        disabled={true}
        label={t("question_in_group_title")}
        fullWidth
        value={groupName}
        variant="outlined"
        margin={"none"}
        size="small"
        InputLabelProps={{
          shrink: true
        }}
      />
    </Box>
  ) : (
    <></>
  );
};

export const AuditQuestionSettings = ({
  allQuestions,
  selectedQuestion,
  disabled,
  onChangeQuestion,
  onSelectQuestion,
  onSetConditionPickerQuestion,
  onShowQuestionAction
}: {
  readonly allQuestions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly selectedQuestion: AuditQuestionWithAnswerAndRemarkDTO;
  readonly disabled?: boolean;
  readonly onChangeQuestion: (question: AuditQuestionWithAnswerAndRemarkDTO) => void;
  readonly onSelectQuestion: (questionId: string) => void;
  readonly onSetConditionPickerQuestion: (question: AuditQuestionWithAnswerAndRemarkDTO | null) => void;
  readonly onShowQuestionAction: (action: AuditQuestionPayloadActionDTO) => void;
}) => {
  const { t } = useTranslation("audit_details");
  const onQuestionChangeCallback = useCallback(
    data => {
      onChangeQuestion({ ...selectedQuestion, ...data });
    },
    [selectedQuestion, onChangeQuestion]
  );

  const availableFieldsForQuestion: string[] = getQuestionEditFields(selectedQuestion.type);

  const allFieldsForQuestion: { [key: string]: JSX.Element } = useMemo(
    () => ({
      type: (
        <AuditQuestionTypeField
          key={"question_type"}
          value={selectedQuestion.type}
          disabled={disabled}
          onChange={onQuestionChangeCallback}
        />
      ),
      inGroup: <AuditQuestionInGroup question={selectedQuestion} questions={allQuestions} />,
      title: (
        <AuditQuestionTextField
          key={"question_title"}
          label={t("question_title")}
          field={"title"}
          value={selectedQuestion.title || ""}
          disabled={disabled}
          onChange={onQuestionChangeCallback}
          multiline={true}
        />
      ),
      placeholder: (
        <AuditQuestionTextField
          key={"question_placeholder"}
          label={t("question_placeholder")}
          field={"placeholder"}
          value={selectedQuestion.placeholder || ""}
          disabled={disabled}
          onChange={onQuestionChangeCallback}
        />
      ),
      options: (
        <AuditQuestionOptionsEditor
          key={"question_options"}
          label={t("question_options")}
          options={selectedQuestion.options || []}
          disabled={disabled}
          onChange={onQuestionChangeCallback}
        />
      ),
      groupName: (
        <AuditQuestionTextField
          key={"group_name"}
          label={t("group_name")}
          field={"title"}
          value={selectedQuestion.title || ""}
          disabled={disabled}
          onChange={onQuestionChangeCallback}
          multiline={true}
        />
      ),
      showCondition: (
        <AuditQuestionConditions
          onChange={onQuestionChangeCallback}
          question={selectedQuestion}
          conditions={selectedQuestion.conditions || {}}
          questions={allQuestions}
          disabled={disabled}
          onSelectQuestion={onSelectQuestion}
          onSetConditionPickerQuestion={onSetConditionPickerQuestion}
        />
      ),
      showAction: (
        <AuditQuestionActions
          question={selectedQuestion}
          disabled={disabled}
          onShowQuestionAction={onShowQuestionAction}
          onChange={onQuestionChangeCallback}
        />
      )
    }),
    [
      allQuestions,
      disabled,
      onQuestionChangeCallback,
      onSelectQuestion,
      onSetConditionPickerQuestion,
      onShowQuestionAction,
      selectedQuestion,
      t
    ]
  );
  return (
    <>
      <Typography align={"center"} variant="subtitle1">
        {t("meta_view_question_settings")}
      </Typography>
      {Object.keys(allFieldsForQuestion || {})
        .filter((key: string) => availableFieldsForQuestion.includes(key))
        .map((key: string, index: number) => (
          <Box key={`${key}-${index}`}>{allFieldsForQuestion[key]}</Box>
        ))}
    </>
  );
};
