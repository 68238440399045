import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useEffect, useState } from "react";
import { enabledUserFeaturesBasedOnTenantFeatures } from "app/features";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

export const useIsFeaturePresent = (feature: string): boolean | null => {
  const { auth } = useAuthentication();
  const { tenantData } = useUserAndTenantData();
  const [isFeaturePresent, setIsFeaturePresent] = useState<boolean | null>(null);
  useEffect(() => {
    if (tenantData === null || auth === null) {
      return;
    }

    const tenantFeatures = tenantData?.features || [];
    const featureIsInTenant = tenantFeatures.includes(feature);
    if (featureIsInTenant) {
      setIsFeaturePresent(featureIsInTenant);
      return;
    }

    const enabledUserFeatures = enabledUserFeaturesBasedOnTenantFeatures(tenantFeatures);
    const userFeature = enabledUserFeatures.find(userFeature => userFeature.id === feature);
    if (!userFeature) {
      setIsFeaturePresent(false);
      return;
    }

    const userHaveFeature = !!auth?.featureIds?.includes(feature);
    setIsFeaturePresent(userHaveFeature);
  }, [tenantData, feature, auth]);
  return isFeaturePresent;
};
