import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";
import { useResources } from "app/contexts/resource-context";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";
import { LegalRetentionPeriodMetadataDialogWithSave } from "app/pages/resources/modals/legal-retention-period/LegalRetentionPeriodMetadataDialog";
import { ResourceField } from "../../../ResourceField";
import { ResourceDTO } from "../../../../app/api/resourceApi";

const LegalRetentionPeriodsQuestion = ({
  questionName,
  value,
  orgUnitIds,
  disabled,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange,
  onChipClick,
  onlyIntersectingOrgUnitIds
}: QuestionProps) => {
  const { t } = useTranslation("questionnaires");
  const { translateById: translateResourceById, refreshResources, resources } = useResources();
  const [currentValue, setCurrentValue] = useState<string[]>([]);
  const [resourcePeriodToEdit, setResourcePeriodToEdit] = useState<ResourceDTO | null>(null);
  const legalRetentionPeriods = resources[RESOURCE_TYPES.LEGAL_RETENTION_PERIOD] || emptyResources;

  useEffect(() => {
    const initialValue: string[] = (Array.isArray(value) ? value : [value]) as string[];
    setCurrentValue(initialValue);
  }, [value]);

  const onChangeCallback = useCallback(
    (value: string[] | string | null) => {
      const arrayValue = !value ? [] : Array.isArray(value) ? value : [value];
      setCurrentValue(arrayValue);
      onChange?.(arrayValue);
    },
    [onChange]
  );

  const legalRetentionPeriodTooltip = useCallback(
    legalRetentionPeriodId => {
      const legalRetentionPeriod = legalRetentionPeriods.find(
        legalRetentionPeriod => legalRetentionPeriod.id === legalRetentionPeriodId
      );

      const metadata = legalRetentionPeriod?.metadata as any;
      return [
        `${t("businessRetentionPeriod:inputLabel")}: ${metadata?.storagePeriod || ""}`,
        `${t("time:unit")}: ${metadata?.storagePeriodUnit ? t(`time:${metadata?.storagePeriodUnit}`) : ""}`,
        `${t("resources_legal-retention-period_overview:deletionTrigger")}: ${translateResourceById(
          RESOURCE_TYPES.DELETION_TRIGGER,
          metadata?.deletionTriggerId || ""
        )}`,
        `${t("questionnaires:justificationOfStorage")}: ${
          getTextFromTextEditorJsonString(metadata?.justificationOfStoragePeriod) || ""
        }`
      ].join("\n");
    },
    [legalRetentionPeriods, translateResourceById, t]
  );

  const onChipClickCallback = useCallback(
    (periodId: string) => {
      const period = legalRetentionPeriods.find(({ id }: { id: string }) => id === periodId);
      if (period) {
        setResourcePeriodToEdit(period);
      }
    },
    [legalRetentionPeriods]
  );

  const onAddCallback = useCallback(newPeriod => onChipClick?.(newPeriod), [onChipClick]);

  const onLegalRetentionPeriodResourceChanged = useCallback(async () => {
    setResourcePeriodToEdit(null);
    await refreshResources();
    // re-trigger the onChange callback to update the value in the parent component
    setCurrentValue(currentValue => {
      onChange?.(currentValue);
      return currentValue;
    });
  }, [refreshResources, onChange]);

  const closeRetentionPeriodResourceEdit = useCallback(() => {
    setResourcePeriodToEdit(null);
  }, []);

  const legalRetentionPeriodDialogEl = (
    <LegalRetentionPeriodMetadataDialogWithSave
      resourceId={resourcePeriodToEdit?.id || ""}
      resourceType={RESOURCE_TYPES.LEGAL_RETENTION_PERIOD}
      onConfirm={onLegalRetentionPeriodResourceChanged}
      onClose={closeRetentionPeriodResourceEdit}
      open={Boolean(resourcePeriodToEdit)}
    />
  );

  return (
    <>
      <ResourceField
        onChange={onChangeCallback}
        label={questionName || ""}
        value={currentValue}
        resourceType={RESOURCE_TYPES.LEGAL_RETENTION_PERIOD}
        id={"legalRetentionPeriod"}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        multiSelect={true}
        allowAdd={true}
        tooltip={legalRetentionPeriodTooltip}
        onChipClick={onChipClickCallback}
        onAddCallback={onAddCallback}
        displayDeleted={true}
        error={error}
        helperText={helperText}
        docOrgUnitIds={orgUnitIds}
        onlyIntersectingOrgUnitIds={onlyIntersectingOrgUnitIds}
      />
      {legalRetentionPeriodDialogEl}
    </>
  );
};

const emptyResources: ResourceDTO[] = [];
export default LegalRetentionPeriodsQuestion;
