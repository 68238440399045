import { CircularProgress } from "@material-ui/core";
import {
  getProcessPageData,
  getProcessRisksOptions,
  patchProcessRisksPageData,
  useProcessGeneralPage
} from "app/api/paApi";
import { useProcessPage } from "app/contexts/process-page-context";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RiskIdsAccordion } from "./RiskIdsAccordion";
import { isEqual } from "lodash-es";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";
import useSWR from "swr";

export interface ProcessRisksPageModel {
  readonly riskIds: string[];
}

const ProcessRisksPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("dpia_four_four_page");
  const { id } = useParams();
  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  useEnteringInfoCard({
    pathName: `/processes/${id}/risks`,
    infoId: "infocard.dpia.page8"
  });

  const [pageModel, setPageModel] = useState<ProcessRisksPageModel | null>(null);

  useEffect(() => {
    const get = async () => {
      const { processPage, processMeta } = await getProcessPageData<ProcessRisksPageModel>({
        processId: id || "",
        page: "risks"
      });

      setPageModel(processPage);
      setProcessMeta(processMeta);
    };

    if (id) {
      get();
    }
  }, [id, setProcessMeta]);

  const updateRiskIdsCallback = useCallback(
    async (riskIds: string[]) => {
      if (id && pageModel?.riskIds && !isEqual(riskIds, pageModel?.riskIds)) {
        await onBeforeProcessUpdate(async () => {
          setPageModel({ riskIds });
          await patchProcessRisksPageData({ processId: id, payload: { riskIds } });
        });
      }
    },
    [id, onBeforeProcessUpdate, pageModel?.riskIds]
  );

  const { data: risksData, isLoading: risksDataLoading } = useSWR(id ? [id, "options", "risks"] : null, () =>
    getProcessRisksOptions({ processId: id || "" })
  );
  const riskOptions = useMemo(() => risksData || [], [risksData]);

  const loaded = pageModel && !risksDataLoading;

  const { data: paGeneral } = useProcessGeneralPage({
    paId: id || ""
  });

  return (
    <>
      <QuestionnaireSubHeader text={t("title")} />
      {loaded ? (
        <RiskIdsAccordion
          title={t("title")}
          riskOptions={riskOptions}
          onRiskIdsChange={updateRiskIdsCallback}
          riskIds={pageModel.riskIds}
          documentId={id || ""}
          type={"processing-activity"}
          disabled={props.readonly === true}
          newRiskFurtherOrgUnitIds={emptyFurtherOrgUnitIds}
          newRiskPrivacyRelevant={true}
          newRiskOrgUnitId={paGeneral?.processPage?.responsibleOrgUnitId || undefined}
        />
      ) : (
        <CircularProgress size={24} />
      )}
    </>
  );
};

const emptyFurtherOrgUnitIds: string[] = [];

export default ProcessRisksPage;
