import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  cardTitle: {
    fontWeight: "bolder"
  }
}));

export default function CardTitle({
  text,
  translationKey,
  className
}: {
  text: string;
  translationKey?: string;
  className?: string;
}) {
  const { t } = useTranslation(translationKey);
  const classes = useStyles();

  return (
    <Typography variant="h2" component="span" className={className ?? classes.cardTitle}>
      {translationKey ? t(text) : text}
    </Typography>
  );
}
