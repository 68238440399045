import { useTranslation } from "react-i18next";
import { useExternalRecipients } from "../../contexts/external-recipient-context";
import React, { useCallback, useEffect, useMemo } from "react";
import { CircularProgress } from "@material-ui/core";
import Question from "../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../components/Question/QuestionTypes";

export const ExternalRecipientPicker = ({
  onChange,
  externalRecipientId
}: {
  readonly onChange: (erId: string | null) => void;
  readonly externalRecipientId: string | null;
}) => {
  const { t } = useTranslation();
  const { initialized, loadExternalRecipientsHook } = useExternalRecipients();
  useEffect(() => {
    loadExternalRecipientsHook(); // to make sure we can always select latest ones
  }, [loadExternalRecipientsHook]);

  const selected = useMemo(() => (externalRecipientId ? [externalRecipientId] : []), [externalRecipientId]);
  const onChangeCallback = useCallback(
    (externalRecipientIDs: string[]) => {
      onChange(externalRecipientIDs.slice(-1)[0] || null);
    },
    [onChange]
  );

  if (!initialized) {
    return <CircularProgress />;
  }

  return (
    <Question
      qType={QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS}
      value={selected}
      onChange={onChangeCallback}
      questionName={t("service_providers_overview:externalRecipient")}
    />
  );
};
