import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { MetaViewMetaProps, useMetaView } from "app/contexts/meta-view-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useUserDepartments } from "app/contexts/department-context";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { processorPAWritePermissions } from "../../../handlers/permissionHandler";
import { usePAPages } from "../../processes/overview/processPages";
import { exportProcessorProcessingActivitiesExcel } from "../../../export/createdExcelExportData";
import { ProcessesOverviewCollectionParams } from "../../processes/overview/ProcessesOverviewController";
import { AllProfileData, PROFILE_TYPE } from "../../../handlers/tenantProfileInformationHandler";
import { exportProcessorPAsPDF } from "../../../export/createdPdfExportData";
import GeneralInformationExport from "../../processes/overview/GeneralInformationExport";
import { streamFilesToZip } from "../../../handlers/fileHandler";

const ProcessorPAOverview = () => {
  const { t } = useTranslation("processor_pa_overview");
  const { setInfoId, setMeta } = useMetaView();
  const { auth } = useAuthentication();
  const { departmentsLoaded } = useUserDepartments();

  const showEnterInfo = useCallback(() => {
    setInfoId("infocard.pa.processor.overview.entering");
  }, [setInfoId]);
  const showCreateInfo = useCallback(() => {
    setInfoId("infocard.pa.processor.overview.creating");
  }, [setInfoId]);

  const onRowOver = useCallback(
    item => {
      const meta: MetaViewMetaProps = {
        processorOrgUnitId: item.subTitle,
        created: <DateDisplay timestamp={item.createdAt} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.creatorUID} separator={undefined} />,
        updated: <DateDisplay timestamp={item.updatedAt} displaySeconds={undefined} />,
        updatedBy: <AutomaticUserDataDisplay uid={item.updaterUID} separator={undefined} />,
        approved: <DateDisplay timestamp={item.approvedAt} displaySeconds={undefined} />,
        approvedBy: <AutomaticUserDataDisplay uid={item.approverUID} separator={undefined} />
      };
      setMeta(meta);
    },
    [setMeta]
  );

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }, { action: "export-all" }], []);
  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }, { action: OVERVIEW_ADD_TYPE.MULTIPLE }], []);

  /* useEffects */
  useEffect(() => {
    showEnterInfo();
  }, [showEnterInfo]);

  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }], []);
  const selectionActions = useMemo(() => [{ action: "export-all" }, { action: "remove" }], []);

  const showAddActions = useMemo(
    () => auth?.permissions.find(permission => processorPAWritePermissions.includes(permission)),
    [auth?.permissions]
  );

  const exportToXLSX = useCallback(
    async ids => {
      if (!auth?.tenantId) {
        return;
      }

      return await exportProcessorProcessingActivitiesExcel(auth?.tenantId, auth?.uid, t, ids);
    },
    [auth?.tenantId, auth?.uid, t]
  );

  const [generalExportOpen, setGeneralExportOpen] = useState<boolean>(false);
  const [processorPAIdsForPdfExport, setProcessorPAIdsForPdfExport] = useState<string[] | null>(null);
  const exportToPdfDialog = useCallback((ids: string[]) => {
    setProcessorPAIdsForPdfExport(ids);
    setGeneralExportOpen(true);
  }, []);
  const exportToPDF = useCallback(
    async (exportInfo: AllProfileData) => {
      await exportProcessorPAsPDF({
        processorPAIds: processorPAIdsForPdfExport || [],
        exportInfo
      });
      setGeneralExportOpen(false);
    },
    [processorPAIdsForPdfExport]
  );
  const exportToAttachments = useCallback(
    async (ids, idToName) => {
      if (!auth?.tenantId) {
        return;
      }

      await streamFilesToZip({
        exportDefinitions: [
          {
            docIds: ids,
            category: `${COLLECTIONS.PROCESSOR_PAS}-processing`,
            docIdToFolderName: id => idToName.get(id) || id,
            categoryName: "General",
            withPrefixQuery: false
          }
        ]
      });
    },
    [auth?.tenantId]
  );

  const collectionParams = useMemo<ProcessesOverviewCollectionParams>(
    () => ({
      exportToPdfDialog,
      exportToXLSX,
      exportToAttachments
    }),
    [exportToPdfDialog, exportToXLSX, exportToAttachments]
  );

  const { pages, onPageChange, shouldShowPages } = usePAPages();

  return (
    <DocMetaView metaViewContent={<MetaView editable={true} translationKey={"processor_pa_overview"} />}>
      {departmentsLoaded && (
        <>
          <Overview
            collection={COLLECTIONS.PROCESSOR_PAS}
            collectionParams={collectionParams}
            pages={shouldShowPages ? pages : undefined}
            onPageChange={onPageChange}
            onAddOpen={showCreateInfo}
            onAddClose={showEnterInfo}
            onRowOver={onRowOver}
            onRowLeave={showEnterInfo}
            rowActions={rowActions}
            toolbarActions={toolbarActions}
            addActions={showAddActions ? addActions : undefined}
            selectionActions={selectionActions}
            checkable={true}
            toolbarMode={shouldShowPages ? "tabs" : undefined}
            header={t("processes_overview:list_processes")}
            showSelectAll={true}
          />
          <GeneralInformationExport
            tenantId={auth?.tenantId || ""}
            open={generalExportOpen}
            setOpen={setGeneralExportOpen}
            exportProcesses={exportToPDF}
            profiles={profilesForExport}
          />
        </>
      )}
    </DocMetaView>
  );
};

const profilesForExport: PROFILE_TYPE[] = ["dataProcessor", "controllerRepresentative", "dataProtectionOfficer"];

export default ProcessorPAOverview;
