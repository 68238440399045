import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { TaskInfoLabel } from "./TaskInfoLabel";
import { useTranslation } from "react-i18next";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import NotesIcon from "@mui/icons-material/Notes";
import stopEvent from "tool/stopEvent";

const useStyles = makeStyles(theme => ({
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .rdw-editor-wrapper": {
      border: "none"
    },
    "& .rdw-editor-main": {
      padding: theme.spacing(1),
      caretColor: theme.palette.primary.main
    },
    "& .public-DraftStyleDefault-block": {
      margin: 0
    }
  },
  descriptionEditor: {
    width: "100%"
  },
  descriptionText: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  edit: {
    "& .rdw-block-wrapper, .rdw-fontsize-wrapper, .rdw-colorpicker-wrapper, .rdw-emoji-wrapper, .rdw-option-disabled": {
      display: "none"
    },
    "& .rdw-editor-toolbar": {
      margin: 0,
      padding: 0
    },
    "& .rdw-option-wrapper": {
      padding: 0
    }
  },
  view: {
    "& .rdw-editor-toolbar": {
      display: "none"
    }
  },
  hoverCursorPointer: {
    "& .rdw-editor-main": {
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  dismissButton: {
    marginLeft: theme.spacing(1)
  },
  descriptionPlaceholder: {
    display: "flex",
    justifySelf: "center",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[400]
  },
  hoverGrayBg: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[100]
    }
  }
}));

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface TaskDescriptionProps {
  readonly docId: string;
  readonly onChangeDescription: (description: string) => void;
  readonly readOnly?: boolean;
  readonly text: string | null;
}
const TaskDescription = ({ docId, onChangeDescription, readOnly, text }: TaskDescriptionProps) => {
  const { t } = useTranslation("task_details");
  const cls = useStyles();

  const [description, setDescription] = useState(text || "");
  const [initialDescription, setInitialDescription] = useState(text || "");
  const [isEditing, setIsEditing] = useState(false);
  const previousDocId = usePrevious(docId);

  useEffect(() => {
    setDescription(text || "");
    if (docId !== previousDocId) {
      setIsEditing(false);
      setInitialDescription(text || "");
    }
  }, [docId, previousDocId, text]);

  const editorElQuery = useMemo(() => `.${cls.descriptionContainer} .rdw-editor-wrapper`, [cls.descriptionContainer]);
  const focusEditor = useCallback(() => {
    const editor = document.querySelector(editorElQuery);
    if (editor) {
      const contentEditableElement = editor.querySelector('[contenteditable="true"]') as HTMLElement;
      if (contentEditableElement) contentEditableElement.focus();
    }
  }, [editorElQuery]);
  const releaseEditorFocus = useCallback(() => {
    const editor = document.querySelector(editorElQuery);
    if (editor) {
      const contentEditableElement = editor.querySelector('[contenteditable="true"]') as HTMLElement;
      if (contentEditableElement) contentEditableElement.blur();
    }
  }, [editorElQuery]);

  const openEditor = useCallback(() => {
    if (readOnly) {
      return;
    }
    setIsEditing(true);
    setInitialDescription(description);
    setTimeout(() => focusEditor(), 0);
  }, [description, focusEditor, readOnly]);

  const onSave = useCallback(
    event => {
      stopEvent(event);
      setIsEditing(false);
      if (!description) {
        return;
      }
      onChangeDescription(description);
    },
    [description, onChangeDescription]
  );

  // save if cmd + enter is pressed
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
        stopEvent(event);
        releaseEditorFocus();
        onSave(event);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSave, releaseEditorFocus]);

  const onChange = useCallback(
    newDescription => {
      setDescription(newDescription);
      onChangeDescription(newDescription);
    },
    [onChangeDescription]
  );

  const onDismiss = useCallback(
    event => {
      stopEvent(event);
      setIsEditing(false);
      setDescription(initialDescription);
    },
    [initialDescription]
  );

  const isDescriptionEmpty = useMemo(() => {
    if (isEditing) {
      return false;
    }
    if (description && description[0] === "{") {
      const parsedDescription = JSON.parse(description);
      if (
        parsedDescription?.blocks &&
        parsedDescription.blocks
          .map((block: any) => block.text)
          .join("")
          .trim() === ""
      ) {
        return true;
      }
    } else {
      return description === "";
    }
  }, [description, isEditing]);

  return (
    <Box className={cls.descriptionContainer} mt={2} mb={2}>
      <TaskInfoLabel icon={NotesIcon} text={t("task_details:description")} />
      <Box ml={-1} onClick={openEditor} className={cls.descriptionEditor}>
        {(!isDescriptionEmpty || isEditing) && (
          <TextEditor
            inputValue={description}
            className={
              isEditing ? cls.edit : readOnly ? cls.view : `${cls.view} ${cls.hoverCursorPointer} ${cls.hoverGrayBg}`
            }
            onChange={onChange}
            disabled={!isEditing}
            testId={"description-text-editor"}
          />
        )}
        {!isDescriptionEmpty && isEditing && (
          <Box p={1}>
            <Button onClick={onSave} variant="outlined" color="primary">
              {t("common:save")}
            </Button>
            <Button onClick={onDismiss} variant="text" color="primary" className={cls.dismissButton}>
              {t("common:dismiss")}
            </Button>
          </Box>
        )}
        {isDescriptionEmpty && !isEditing && (
          <Box
            onClick={openEditor}
            p={1}
            ml={-0.5}
            pb={1}
            className={readOnly ? cls.descriptionPlaceholder : `${cls.descriptionPlaceholder} ${cls.hoverGrayBg}`}
          >
            {readOnly ? t("task_details:no_description_added") : `${t("task_details:add_description")}...`}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { TaskDescription };
