import { Box, Chip } from "@mui/material";
import React from "react";

export const OrgUnitTreeNodeLabel = ({
  name,
  searchText,
  chipText
}: {
  readonly name: string;
  readonly searchText?: string;
  readonly chipText?: string;
}) => {
  let text = <Box>{name}</Box>;
  if (searchText && searchText.length) {
    const index = name.toLowerCase().indexOf(searchText.toLowerCase());
    if (index !== -1) {
      text = (
        <Box>
          <span>{name.substring(0, index)}</span>
          <span style={{ fontWeight: 700 }}>{name.substring(index, index + searchText.length)}</span>
          <span>{name.substring(index + searchText.length)}</span>
        </Box>
      );
    }
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box flex={1}>{text}</Box>
      {chipText ? (
        <Box>
          <Chip label={chipText} size="small" />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
