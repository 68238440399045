import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import MetaView from "components/MetaView/MetaView";
import { useMetaView } from "app/contexts/meta-view-context";
import { usePathParamByKey } from "app/router/router-custom-hooks";
import { useNavigate } from "react-router-dom";
import { useUserGroups } from "app/contexts/group-context";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  buttonsWrapper: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between"
  },
  buttonsWrapperHiddenResetDelete: {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginRight: "10px"
  },
  alertButton: {
    backgroundColor: theme.palette.red[400],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.red[500],
      color: theme.palette.common.white
    }
  },
  buttonContainer: {
    marginRight: "8px",
    marginTop: "200px",
    "& div:nth-child(1)": {
      float: "right"
    },
    "& button:nth-child(1)": {
      marginRight: "11px"
    }
  }
}));

export default function CreateAndEditGroup() {
  const existingGroupId = usePathParamByKey("id");
  const { t } = useTranslation("manage-user-page");
  const classes = useStyles();
  const { auth } = useAuthentication();
  const { createGroupHook, updateGroupHook, getGroupHook } = useUserGroups();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [modalText, setModalText] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmationFunction, setConfirmationFunction] = useState<() => void>(() => {
    /* empty */
  });
  const [loadingInfo, setLoadingInfo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setInfo } = useMetaView();
  const navigateToOverview = useCallback(() => navigate("/groups"), [navigate]);

  const loadGroup = useCallback(async () => {
    if (!existingGroupId) {
      setName("");
      return;
    }

    if (existingGroupId) {
      const group = await getGroupHook(existingGroupId);
      if (!group) {
        return navigateToOverview();
      }
      setName(group.name || "");
      return;
    }
  }, [existingGroupId, getGroupHook, navigateToOverview]);

  useEffect(() => {
    loadGroup();
  }, [loadGroup]);

  const defaultInfo = useMemo(
    () =>
      existingGroupId
        ? {
            title: t("groups_overview:groupDetailsInfoTitle"),
            text: t("groups_overview:groupDetailsInfoText")
          }
        : {
            title: t("groups_overview:addNewGroupInfoTitle"),
            text: t("groups_overview:addNewGroupInfoText")
          },
    [existingGroupId, t]
  );

  useEffect(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);

  // register Group in Database
  const registerGroup = useCallback(async () => {
    setLoadingInfo(true);
    const trimmedName = name?.trim();

    try {
      await createGroupHook({
        name: trimmedName
      });

      setLoadingInfo(false);
      enqueueSnackbar(t("group_created_message"), { variant: "success" });
      navigateToOverview();
    } catch (error: any) {
      if (error.response.data.error === "ConflictError") {
        enqueueSnackbar(t("groups_overview:group_name_exist", { name: trimmedName }), { variant: "error" });
      } else {
        enqueueSnackbar(t(error?.message), { variant: "error" });
      }
      console.error(error);
      setLoadingInfo(false);
    }
  }, [name, enqueueSnackbar, t, navigateToOverview, createGroupHook]);

  // edit Group in Database
  const editGroup = useCallback(async () => {
    if (!existingGroupId) {
      throw new Error("Group is non existent!");
    }
    const trimmedName = name?.trim();
    setLoadingInfo(true);
    try {
      await updateGroupHook(existingGroupId, {
        name: trimmedName
      });
      enqueueSnackbar(t("changes_saved"), { variant: "success" });
      setLoadingInfo(false);
      navigateToOverview();
    } catch (error: any) {
      console.error(error);
      if (error.response.data.error === "ConflictError") {
        enqueueSnackbar(t("groups_overview:group_name_exist", { name: trimmedName }), { variant: "error" });
      } else {
        enqueueSnackbar(t(error?.message), { variant: "error" });
      }
      setLoadingInfo(false);
    }
  }, [existingGroupId, updateGroupHook, name, enqueueSnackbar, t, navigateToOverview]);

  const necessaryFieldsSubmitted = () => !loadingInfo && name;

  const onInfoTextReset = useCallback(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);
  const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const modalClose = useCallback(() => {
    setEditModalOpen(false);
    setModalText("");
  }, []);

  const [modalButtons, setModalButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(() => {
    setModalButtons([
      {
        confirmButton: false,
        title: t("common:no"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: () => {
          setEditModalOpen(false);
          setModalText("");
        }
      },
      {
        confirmButton: true,
        title: t("common:yes"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: async () => {
          confirmationFunction();
          setEditModalOpen(false);
          setModalText("");
        }
      }
    ]);
  }, [confirmationFunction, t]);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey="organisation" />}>
      <DocView header={existingGroupId ? t("edit_group_information") : t("add_group")}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth={true}
              label={t("fields:group_name")}
              value={name}
              onChange={onNameChanged}
              name="name_optional"
              id="name"
              type="text"
              variant="outlined"
              onBlur={onInfoTextReset}
            />
          </Grid>
        </Grid>

        <div className={classes.buttonsWrapper}>
          <div>
            <span className={classes.button}>
              <Button variant="outlined" color="primary" onClick={navigateToOverview}>
                {t("back")}
              </Button>
            </span>
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled={!necessaryFieldsSubmitted()}
                onClick={existingGroupId ? editGroup : registerGroup}
              >
                {loadingInfo && <CircularProgress color="inherit" size={14} className={classes.icon} />}
                {existingGroupId ? t("save_user") : t("add")}
              </Button>
            </span>
          </div>
          <ConfirmationModal
            modalBody={undefined}
            modalOpen={editModalOpen}
            modalTitle={t("questionnaires:edit_modal_title")}
            modalText={modalText}
            onClose={modalClose}
            buttons={modalButtons}
          />
        </div>
      </DocView>
    </DocMetaView>
  );
}
