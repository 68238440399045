import { Box, Menu, MenuItem } from "@mui/material";
import React, { forwardRef, useCallback } from "react";
import CheckMarkIcon from "assets/images/tasks/check-mark.svg";
import { Typography } from "@material-ui/core";

interface DropDownMenuItemButtonProps extends DropDownMenuItemProps {
  readonly onClick: (id: string) => void;
}

const DropDownMenuItem = forwardRef(({ id, label, icon, selected, onClick }: DropDownMenuItemButtonProps, ref) => {
  const sx = {
    root: {
      display: "flex"
    }
  };

  const onClickCallback = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <MenuItem onClick={onClickCallback} ref={ref as any} sx={sx.root}>
      {icon}
      <Box mx={1} flex={1}>
        <Typography>{label}</Typography>
      </Box>
      <Box minWidth={6} ml={1}>
        {selected ? <CheckMarkIcon /> : <></>}
      </Box>
    </MenuItem>
  );
});
DropDownMenuItem.displayName = "DropDownMenuItem";

export interface DropDownMenuItemProps {
  readonly id: string;
  readonly label: string;
  readonly icon: React.ReactNode;
  readonly selected?: boolean;
}

export interface DropDownMenuProps {
  readonly open: boolean;
  readonly anchorEl: Element | null;
  readonly anchorOrigin?: {
    readonly vertical: number;
    readonly horizontal: number;
  };
  readonly items: DropDownMenuItemProps[];
  readonly selectedId?: string;
  readonly onChange: (id: string) => void;
  readonly onClose?: () => void;
}

const DropDownMenu = ({ open, anchorEl, anchorOrigin, items, selectedId, onClose, onChange }: DropDownMenuProps) => {
  const sx = {
    paper: {
      "& .MuiPaper-root": {
        overflow: "visible",
        boxShadow: "0px 2px 5px 0px #00000033",
        "&::before": {
          display: "block",
          content: '""',
          position: "absolute",
          top: -8,
          left: "15%",
          width: "16px",
          height: "16px",
          backgroundColor: "#ffffff",
          transform: "translate(-50%, 0) rotate(45deg)",
          boxShadow: "0px -3px 5px -3px #00000033",
          borderRadius: "2px"
        }
      }
    }
  };
  const onCloseCallback = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const onChangeCallback = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Menu
      keepMounted={false}
      open={open}
      anchorEl={anchorEl}
      onClose={onCloseCallback}
      anchorOrigin={
        anchorOrigin || {
          vertical: 35,
          horizontal: -16
        }
      }
      sx={sx.paper}
    >
      {items.map((item, index) => (
        <DropDownMenuItem
          key={`${item.id}-${index}`}
          id={item.id}
          selected={item.selected || selectedId === item.id}
          icon={item.icon}
          label={item.label}
          onClick={onChangeCallback}
        />
      ))}
    </Menu>
  );
};
export default DropDownMenu;
