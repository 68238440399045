import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAllQueryParams, useNavigateWithQueryParams } from "../../router/router-custom-hooks";
import { pages } from "./paPages";

export const useRedirectOnLegacyPage = () => {
  const { id: processId, page: pageParam } = useParams();
  const allQueryParams = useAllQueryParams();
  const navigateWithParams = useNavigateWithQueryParams();

  const [shouldNavigateToPageName, setShouldNavigateToPageName] = useState<string>("");
  useEffect(() => {
    if (!processId) {
      return;
    }
    if (!pageParam) {
      return;
    }

    const parsedPageParam = parseInt(pageParam, 10);
    const isUsingNumberPageParam = isFinite(parsedPageParam) && parsedPageParam > 0;
    if (!isUsingNumberPageParam) {
      return;
    }

    const pageName = Object.values(pages).find(page => page.number === pageParam)?.name;
    if (!pageName) {
      return;
    }

    setShouldNavigateToPageName(pageName);
  }, [pageParam, processId, shouldNavigateToPageName]);

  useEffect(() => {
    if (!shouldNavigateToPageName) {
      return;
    }

    const targetPath = `/processes/${processId}/${shouldNavigateToPageName}`;
    navigateWithParams(targetPath, allQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldNavigateToPageName]);
  return null;
};
