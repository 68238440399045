import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Collapse, IconButton, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import { Add, Delete, Edit } from "@material-ui/icons";
import OverviewRenamer from "components/Overview/controls/OverviewRenamer";
import { OrgUnitTreeStructure } from "app/pages/organization/OrganizationOverview";
import { useTranslation } from "react-i18next";
import OverviewAdd from "components/Overview/controls/OverviewAdd";
import { OverviewNewItem } from "components/Overview/controllers/overviewBaseController";
import OverviewHighlight from "components/Overview/controls/OverviewHighlight";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

const useStyles = makeStyles((theme: Theme) => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  folder: {
    cursor: "pointer",
    padding: "4px 8px",
    alignItems: "center",
    marginBottom: "4px",
    "& .folderIcon": {
      fill: theme.palette.grey[500]
    },
    "&:hover": {
      background: theme.palette.grey[100],
      "& .actions": {
        display: "flex"
      }
    },
    "& .actions": {
      display: "none",
      background: "transparent",
      "& .MuiIconButton-root:hover": {
        backgroundColor: theme.palette.blue[50],
        color: theme.palette.primary.main
      }
    },
    "& .title": {
      padding: "16px 0 12px 0"
    },
    "&.edit": {
      background: theme.palette.blue[50]
    },
    "&.delete": {
      background: theme.palette.error.light
    },
    "&.addFolder": {
      background: theme.palette.grey[100]
    },
    "&.moveTo": {
      background: theme.palette.grey[100]
    }
  },
  children: {
    paddingLeft: "24px"
  },
  noItems: {
    backgroundColor: theme.palette.blue[50],
    borderRadius: "6px"
  },
  titleEditor: {
    minHeight: "48px",
    paddingTop: "4px",
    "& .MuiInputBase-input": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      borderRadius: "4px",
      "&:focus": {
        background: "transparent !important"
      },
      "&:hover": {
        background: "#f0f0f0"
      }
    }
  }
}));

/* INTERFACES */
interface TreeNodeActionProps {
  readonly name: string;
  readonly icon?: React.ReactNode;
  readonly item: OrgUnitTreeStructure;
  readonly onHandle?: (item?: OrgUnitTreeStructure) => void;
}

interface TreeNodeProps {
  readonly item: OrgUnitTreeStructure;
  readonly treeFirstItem: string | null;
  readonly searchText: string;
  readonly activeRow: string | null;
  readonly onRename: (newName: string, docId: string) => void;
  readonly onDelete: (id: string) => void;
  readonly onAddChild: (parent: OrgUnitTreeStructure, name: string) => void;
  readonly setActiveRow: Dispatch<SetStateAction<string | null>>;
}

export interface OverviewTreeViewParams {
  readonly items: OrgUnitTreeStructure[];
  readonly searchText: string;
  readonly onRename?: (id: string, newName: string) => void;
  readonly onDelete?: (id: string) => void;
  readonly onAddChild: (parent: OrgUnitTreeStructure, name: string) => void;
}

/* COMPONENTS */
const TreeNodeTitle = ({
  title,
  searchText,
  editMode,
  onChange,
  onClose
}: {
  readonly title: string;
  readonly searchText: string;
  readonly editMode: boolean;
  readonly onChange: (nameTrimmed: string) => void;
  readonly onClose: () => void;
}) => {
  const onBlurCallback = useCallback(() => {
    onClose();
  }, [onClose]);
  const onChangeTitle = useCallback(
    (nameTrimmed: string) => {
      onChange(nameTrimmed);
    },
    [onChange]
  );

  const renamerEl = <OverviewRenamer title={title} onCancel={onBlurCallback} onSave={onChangeTitle} />;

  const titleEl = useMemo(
    () => (
      <Box mx={2} minWidth={0} className="title" flex={1}>
        <Typography noWrap>
          {searchText ? <OverviewHighlight text={title} searchTerm={searchText} /> : title}
        </Typography>
      </Box>
    ),
    [searchText, title]
  );
  if (editMode) {
    return renamerEl;
  } else return titleEl;
};

const TreeNodeAction = ({ name, icon, item, onHandle }: TreeNodeActionProps) => {
  const onHandleCallback = useCallback(() => onHandle?.(item), [item, onHandle]);
  return (
    <Tooltip title={name}>
      <IconButton onClick={onHandleCallback} component="label" aria-label="split button">
        {icon}
      </IconButton>
    </Tooltip>
  );
};

const TreeNode = ({
  item,
  treeFirstItem,
  searchText,
  activeRow,
  onRename,
  onDelete,
  onAddChild,
  setActiveRow
}: TreeNodeProps) => {
  const cls = useStyles();
  const { t } = useTranslation("organisationManagement");
  const { auth } = useAuthentication();
  const [open, setOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [addChildMode, setAddChildMode] = useState<boolean>(false);
  const [childrenEl, setChildrenEl] = useState<React.ReactElement | null>(null);

  useEffect(() => {
    if (activeRow !== item.id) {
      setEditMode(false);
      setAddChildMode(false);
    }
  }, [activeRow, item.id]);

  useEffect(() => {
    setChildrenEl(null);
    setOpen(false);
  }, [searchText]);

  /* RENAME */
  const onRenameOpenCallback = useCallback(() => {
    setEditMode(true);
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  /* DELETE */
  const onDeleteConfirm = useCallback(() => setConfirmationOpen(true), []);
  const closeConfirmation = useCallback(() => setConfirmationOpen(false), []);

  const onDeleteChild = useCallback(() => {
    onDelete(item.id);
    setConfirmationOpen(false);
  }, [onDelete, item.id, setConfirmationOpen]);

  const onRenameCallback = useCallback(
    (nameTrimmed: string) => {
      onRename(nameTrimmed, `${item.id}`);
      setEditMode(false);
      setActiveRow(null);
    },
    [onRename, item.id, setActiveRow]
  );

  const onCloseCallback = useCallback(() => {
    setEditMode(false);
    setActiveRow(null);
  }, [setActiveRow]);

  /* CREATE CHILD */
  const onCreateChildCallback = useCallback(() => {
    setAddChildMode(true);
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  const onCreateChild = useCallback(
    (newOrgUnit: OverviewNewItem) => {
      if (!newOrgUnit?.title) {
        throw new Error("Title is required for new org unit.");
      }
      const name = newOrgUnit.title;
      onAddChild(item, name);
      setAddChildMode(false);
      setActiveRow(null);
    },
    [item, onAddChild, setActiveRow]
  );

  const onCloseNewChildEnter = useCallback(() => {
    setAddChildMode(false);
    setActiveRow(null);
  }, [setActiveRow]);

  const defaultActions = useMemo(
    () =>
      item.id === treeFirstItem
        ? [{ name: t("organisationManagement:create"), icon: <Add />, onHandle: onCreateChildCallback }]
        : auth?.permissions.some(it => it.startsWith("orgunit_write_"))
          ? [
              { name: t("organisationManagement:create"), icon: <Add />, onHandle: onCreateChildCallback },
              { name: t("overview:edit"), icon: <Edit />, onHandle: onRenameOpenCallback },
              { name: t("overview:remove"), icon: <Delete />, onHandle: onDeleteConfirm }
            ]
          : [
              { name: t("organisationManagement:create"), icon: <Add />, onHandle: onCreateChildCallback },
              { name: t("overview:edit"), icon: <Edit />, onHandle: onRenameOpenCallback }
            ],
    [item.id, treeFirstItem, t, onCreateChildCallback, auth?.permissions, onRenameOpenCallback, onDeleteConfirm]
  );

  const confirmationDialogEl = useMemo(
    () => (
      <ConfirmationModal
        variant="info"
        modalOpen={confirmationOpen}
        onClose={closeConfirmation}
        modalTitle={t("common:information")}
        modalText={t("organisation:dialog_title_org_delete")}
        buttons={[
          {
            title: t("common:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: closeConfirmation
          },
          {
            confirmButton: true,
            title: t("common:delete"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: onDeleteChild
          }
        ]}
      />
    ),
    [closeConfirmation, confirmationOpen, onDeleteChild, t]
  );

  const actionsEl = useMemo(
    () =>
      [...defaultActions].map(({ name, icon, onHandle }, index) => (
        <TreeNodeAction key={index} name={name} icon={icon} item={item} onHandle={onHandle} />
      )),
    [defaultActions, item]
  );

  const noChildrenEl = useMemo(
    () => (
      <Box p={2} textAlign={"center"} mb={1} className={cls.noItems}>
        {t("overview:no_entries_found")}
      </Box>
    ),
    [cls.noItems, t]
  );

  const setChildren = useCallback(() => {
    const el = item.children?.length
      ? (item.children || []).map((children, index) => (
          <TreeNode
            key={index}
            item={children}
            treeFirstItem={treeFirstItem}
            searchText={searchText}
            activeRow={activeRow}
            onRename={onRename}
            onDelete={onDelete}
            onAddChild={onAddChild}
            setActiveRow={setActiveRow}
          />
        ))
      : noChildrenEl;

    setChildrenEl(<>{el}</>);
  }, [activeRow, item.children, noChildrenEl, onAddChild, onDelete, onRename, searchText, setActiveRow, treeFirstItem]);

  useEffect(() => {
    setChildren();
  }, [addChildMode, setChildren]);

  const onToggleOpen = useCallback(() => {
    if (!editMode) setOpen(v => !v);
    if (childrenEl === null) {
      setChildren();
    }
  }, [childrenEl, editMode, setChildren]);

  const stopEvent = useCallback(event => event.stopPropagation(), []);

  const chevronEl = useMemo(() => {
    return open ? <KeyboardArrowDownIcon className="folderIcon" /> : <KeyboardArrowRightIcon className="folderIcon" />;
  }, [open]);

  const addChildEl = useMemo(
    () => (
      <Collapse in={addChildMode}>
        <OverviewAdd placeholder={t("treeTableAddNewSub")} onAdd={onCreateChild} onClose={onCloseNewChildEnter} />
      </Collapse>
    ),
    [addChildMode, onCloseNewChildEnter, onCreateChild, t]
  );

  return (
    <Box>
      <Box
        onClick={onToggleOpen}
        display="flex"
        className={`
          ${cls.folder} 
          ${editMode ? "edit" : ""} 
          ${addChildMode ? "addChild" : ""} `}
      >
        {chevronEl}
        <Box mr={1} />
        <TreeNodeTitle
          editMode={editMode}
          title={item.data.name}
          searchText={searchText}
          onClose={onCloseCallback}
          onChange={onRenameCallback}
        />
        {!editMode && !addChildMode && (
          <Box className="actions" onClick={stopEvent}>
            {actionsEl}
          </Box>
        )}
      </Box>
      {addChildEl}
      {confirmationDialogEl}
      {open && <Box className={cls.children}>{childrenEl}</Box>}
    </Box>
  );
};

const OrganizationTreeView = ({ items, searchText, onRename, onDelete, onAddChild }: OverviewTreeViewParams) => {
  const cls = useStyles();
  const { t } = useTranslation("organisationManagement");

  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [treeFirstItem, setTreeFirstItem] = useState<string | null>(null);

  useEffect(() => {
    if (items.length) {
      setTreeFirstItem(items[0].id || null);
    }
  }, [items]);

  const onRenameCallback = useCallback(
    (organisationalUnitId: string, nameTrimmed: string) => {
      onRename?.(organisationalUnitId, nameTrimmed);
    },
    [onRename]
  );

  const onDeleteCallback = useCallback(
    (organisationalUnitId: string) => {
      onDelete?.(organisationalUnitId);
    },
    [onDelete]
  );

  const noChildrenEl = useMemo(
    () => (
      <Box p={2} textAlign={"center"} mb={1} className={cls.noItems}>
        {t("overview:no_entries_found")}
      </Box>
    ),
    [cls.noItems, t]
  );

  const treeEl = useMemo(
    () => (
      <>
        {items.map((item, index) => (
          <TreeNode
            key={`${item.id}-${index}`}
            item={item}
            treeFirstItem={treeFirstItem}
            searchText={searchText}
            activeRow={activeRow}
            onRename={onRenameCallback}
            onDelete={onDeleteCallback}
            onAddChild={onAddChild}
            setActiveRow={setActiveRow}
          />
        ))}
      </>
    ),
    [activeRow, items, onAddChild, onDeleteCallback, onRenameCallback, searchText, treeFirstItem]
  );

  return <Box mx={6}>{items.length ? treeEl : noChildrenEl}</Box>;
};

export default React.memo(OrganizationTreeView);
