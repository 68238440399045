import React, { useCallback } from "react";
import { QuestionProps } from "../../Question";
import { ResourceField } from "components/ResourceField";

const ResourceQuestion = ({
  allowAdd,
  disabled = false,
  error,
  helperText,
  multiSelect,
  orgUnitIds,
  questionName,
  resourceType,
  value,
  onBlur,
  onChange,
  onFocus,
  onlyIntersectingOrgUnitIds
}: QuestionProps) => {
  const _value = multiSelect ? (value as string[]) : (value as string);
  const onChangeCallback = useCallback(
    (val: string | string[] | null) => {
      onChange?.(val);
    },
    [onChange]
  );
  if (resourceType) {
    return (
      <ResourceField
        allowAdd={allowAdd}
        disabled={disabled}
        docOrgUnitIds={orgUnitIds}
        onlyIntersectingOrgUnitIds={onlyIntersectingOrgUnitIds}
        error={error}
        helperText={helperText}
        label={questionName || resourceType}
        multiSelect={multiSelect}
        resourceType={resourceType}
        sortOptionsByName={false}
        value={_value}
        onBlur={onBlur}
        onChange={onChangeCallback}
        onFocus={onFocus}
      />
    );
  }
  return <>{resourceType}</>;
};

export default ResourceQuestion;
