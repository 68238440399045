import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api/hasura`
});

export interface HasuraTokenDTO {
  readonly hasuraToken?: string;
}

export const hasuraLoginTokenApi = async (tenantId: string): Promise<HasuraTokenDTO> => {
  const response = await axiosInstance.post<HasuraTokenDTO>("/token", { tenantId });
  return response.data || {};
};
