import React from "react";

/**
 * A hook that memoizes a value, but only updates it after a delay.
 *
 * @param factory A function that returns the value to memoize.
 * @param deps The dependencies to watch for changes.
 * @param delay The delay in milliseconds to wait before updating the memoized value.
 * @returns The memoized value.
 */
export default function useDebouncedMemo<T>(factory: () => T, deps: React.DependencyList, delay = 500): T {
  const [value, setValue] = React.useState(factory());
  const timer = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      setValue(factory());
    }, delay);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return value;
}
