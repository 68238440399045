import React, { useCallback, useEffect, useState } from "react";
import { BottomNavigation, BottomNavigationAction, Box, makeStyles } from "@material-ui/core";
import { MetaViewTabProps, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  tabs: {
    background: theme.palette.blue[50],
    height: "80px",
    width: "100%"
  },
  content: {
    flex: 1,
    overflow: "auto"
  },
  action: {
    "& .MuiBottomNavigationAction-label": {
      marginTop: "6px",
      fontSize: "12px"
    },
    minWidth: "80px"
  }
}));

interface MetaViewTabsProps {
  readonly tabs: MetaViewTabProps[];
  readonly selected: META_VIEW_TABS_TYPES;
  readonly children?: React.ReactNode;
  readonly onTabChange: (tabId: META_VIEW_TABS_TYPES) => void;
}

export default function MetaViewTabs({ tabs, selected, children, onTabChange }: MetaViewTabsProps) {
  const [tab, setTab] = useState<MetaViewTabProps | null>(null);
  const cls = useStyles();

  useEffect(() => {
    const currentTab = tabs.find(tab => tab.tabId === selected);
    if (currentTab) {
      setTab(currentTab);
    }
  }, [selected, tabs]);

  const onTabChangeCallback = useCallback(
    (event, value) => {
      setTab(value);
      onTabChange(value.tabId);
    },
    [onTabChange]
  );

  return (
    <Box display={"flex"} className={cls.root}>
      <Box display={"flex"} justifyContent="space-between" className={cls.tabs}>
        {
          <BottomNavigation value={tab} onChange={onTabChangeCallback} className={cls.tabs}>
            {tabs.map((tab, index) => (
              <BottomNavigationAction
                key={index}
                className={cls.action}
                label={tab.title}
                value={tab}
                icon={tab.icon}
              />
            ))}
          </BottomNavigation>
        }
      </Box>
      <Box p={3} className={cls.content}>
        {tab?.content}
        {children}
      </Box>
    </Box>
  );
}
