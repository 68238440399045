import externalRecipientsTemplatesPath from "assets/templates/external_recipients_templates.xlsx";
import { fetchTemplate } from "./xlsxDownloadHandler";

async function getExternalDataRecipientsTemplates() {
  const jsonResults = await fetchTemplate(externalRecipientsTemplatesPath);
  const convertProductsToArray = jsonResults.map(jsonResult => {
    return {
      ...jsonResult,
      // convert "- product1 \n- product2" to ["product1", "product2"]
      products: jsonResult.products
        ? jsonResult.products
            .replace(/- /g, "")
            .replace(/\n/g, ",")
            .split(",")
            .map(product => product.trim())
        : []
    };
  });
  return convertProductsToArray;
}

export { getExternalDataRecipientsTemplates };
