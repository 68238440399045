import { DateField } from "components/DateField";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { QuestionProps } from "../../Question";

const DateQuestion = ({
  questionName,
  value,
  disabled = false,
  disablePast,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation();
  const onChangeCallback = useCallback(
    (date: Date | null) => {
      onChange?.(date);
    },
    [onChange]
  );
  const _value = value ? new Date(Array.isArray(value) ? value[0] : (value as string)) || new Date() : null;
  return (
    <DateField
      disablePast={disablePast}
      onFocus={onFocus}
      date={_value || null}
      onDateChange={onChangeCallback}
      onBlur={onBlur}
      label={questionName || t("dueDate:fieldName")}
      minDateMessage={t("dueDate:pastDate")}
      disabled={disabled}
      margin={"none"}
    />
  );
};

export default DateQuestion;
