import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import GeneralPageStepper, { PageStep } from "../../../components/Pagination/GeneralPageStepper";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";

export function useAITechDocPagination() {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<PageStep[]>([]);

  useEffect(() => {
    setSteps(
      [
        {
          label: t("aitechdoc_details:pageGeneral"),
          path: "general"
        },
        {
          label: t("aitechdoc_details:pageTechnicalDetail"),
          path: "technical-detail"
        },
        {
          label: t("aitechdoc_details:pageTrainingData"),
          path: "training-data"
        }
      ].flatMap(step => (step ? [step] : []))
    );
  }, [t]);

  return {
    steps
  };
}

export function AITechDocPageStepper({
  currentPath,
  onStepChange,
  skipNavigate
}: {
  readonly currentPath?: string;
  readonly onStepChange?: (step: PageStep) => void;
  readonly skipNavigate?: boolean;
}) {
  const { steps } = useAITechDocPagination();

  return (
    <GeneralPageStepper
      steps={steps}
      onStepChange={onStepChange}
      skipNavigate={skipNavigate}
      currentPath={currentPath}
    />
  );
}

export function AITechDocPageButtons({ rightArea }: { readonly rightArea?: React.ReactNode }) {
  const { steps } = useAITechDocPagination();

  return <GeneralPageButtonPagination steps={steps} rightArea={rightArea} />;
}
