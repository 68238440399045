import { Add } from "@material-ui/icons";
import { Box, Button } from "@mui/material";
import { COLLECTIONS } from "app/collections";
import { useMetaView } from "app/contexts/meta-view-context";
import AssessmentResponseOverviewRow from "components/Overview/collections/assessment/AssessmentResponseOverviewRow";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import Overview from "components/Overview/Overview";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface AssessmentResponsesOverviewProps {
  readonly auditId: string;
  readonly enterInfo: { readonly title: string; readonly text: string };
  readonly writePermission: boolean;

  readonly onNext: () => void;
  readonly onAddAnswerSet: () => void;
  readonly onRowClick: (item: OverviewItem, allAssessmentResponseIds: string[]) => void;
}
const AssessmentResponsesOverview = ({
  auditId,
  enterInfo,
  writePermission,

  onNext,
  onAddAnswerSet,
  onRowClick
}: AssessmentResponsesOverviewProps) => {
  const { t } = useTranslation("audit_details");
  const { setInfo } = useMetaView();

  const [allAssessmentResponseIds, setAllAssessmentResponseIds] = useState<string[]>([]);

  const showEnterInfo = useCallback(() => {
    setInfo(enterInfo);
  }, [enterInfo, setInfo]);

  const toolbarActions = [{ action: "sort" }, { action: "filter" }];
  const rowActions = useMemo(() => [{ action: "remove" }], []);
  const selectionActions = useMemo(() => [{ action: "remove" }], []);

  const onRowClickCallback = useCallback(
    (item: OverviewItem) => {
      onRowClick(item, allAssessmentResponseIds);
    },
    [allAssessmentResponseIds, onRowClick]
  );

  const overviewToolbarAddControl = useMemo(() => {
    return (
      <Box display={"flex"} ml={2}>
        {writePermission && (
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Add fontSize="small" />}
            onClick={onAddAnswerSet}
            style={{ marginRight: "16px" }}
          >
            {t("common:create")}
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={onNext}>
          {t("common:nextContinue")}
        </Button>
      </Box>
    );
  }, [onAddAnswerSet, onNext, t, writePermission]);

  const collectionParams = useMemo(() => ({ auditId, setAllAssessmentResponseIds }), [auditId]);

  return (
    <Overview
      toolbarActions={toolbarActions}
      toolbarHeader={t("audit_details:responses")}
      toolbarMode={"tabs"}
      toolbarAddControl={overviewToolbarAddControl}
      collection={COLLECTIONS.ASSESSMENT_RESPONSE}
      rowActions={rowActions}
      selectionActions={selectionActions}
      addActions={[]}
      checkable={true}
      collectionParams={collectionParams}
      onRowClick={onRowClickCallback}
      CustomRowComponent={AssessmentResponseOverviewRow}
      onAddClose={showEnterInfo}
      onRowLeave={showEnterInfo}
    />
  );
};

export default AssessmentResponsesOverview;
