import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TomModelDTO } from "../../../../app/api/tomApi";
import { useAuthentication } from "../../../../app/handlers/authentication/authentication-context";
import { Box } from "@mui/material";
import MultiAutocomplete from "../../../MultiAutocomplete/MultiAutocomplete";
import { removeAddText } from "../../../../app/pages/questionnaires/utils/helper-functions";
import { AITechDocsApi } from "../../../../app/api/generated/aitechdoc-service";
import { apiEndpoints } from "../../../../app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../../../app/api/axios/loggedInAxiosProvider";
import { AITechDocModal } from "../../../../app/pages/ai-tech-docs/AITechDocModal";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete/Autocomplete";
import { Chip } from "@material-ui/core";

export const AITechDocsQuestion = ({
  value,
  disabled = false,
  onFocus,
  onBlur,
  onChange,
  options,
  orgUnitIds,
  allowAdd
}: QuestionProps) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string[]>([]);

  useEffect(() => {
    const initialValue: string[] = (Array.isArray(value) ? value : [value]) as string[];
    return setCurrentValue(initialValue);
  }, [value]);

  const onChangeCallback = useCallback(
    ids => {
      onChange?.(ids);
    },
    [onChange]
  );

  const [aiTechDocById, setAiTechDocById] = useState<Map<string, { id: string; name: string }>>(new Map());
  const [optionIds, setOptionIds] = useState<string[]>([]);
  useEffect(() => {
    const aiTechDocById = new Map<string, { id: string; name: string }>();
    const optionIds: string[] = [];
    for (const option of (options || []) as { id: string; name: string }[]) {
      const aiTechDoc = option as TomModelDTO;
      aiTechDocById.set(aiTechDoc.id, aiTechDoc);
      optionIds.push(aiTechDoc.id);
    }
    setAiTechDocById(aiTechDocById);
    setOptionIds(optionIds);
  }, [options]);

  const addNewText = useMemo(() => t("asset_details:add_text"), [t]);
  const getOptionName = useCallback(
    (aiTechDocId: string) => {
      if (aiTechDocId.startsWith(`${addNewText} `)) {
        return aiTechDocId;
      }
      return aiTechDocById.get(aiTechDocId)?.name || "";
    },
    [addNewText, aiTechDocById]
  );

  const { auth } = useAuthentication();
  const userHasAITechDocAddPermission = auth?.permissions.includes("aitechdoc_write_org");

  const [toEditTechDocId, setToEditTechDocId] = useState<string | null>(null);
  const onOptionAddCallback = useCallback(
    async (options: string[]) => {
      // the last option is the newly added option
      const newItemName = removeAddText(options[options.length - 1], addNewText).trim();
      const response = await aiTechDocClient.createAITechDoc({
        name: newItemName,
        furtherOrgUnitIds: orgUnitIds
      });
      const createdId = response?.headers?.["x-resource-id"] || "";
      setToEditTechDocId(createdId);
      setOptionIds(prev => [...prev, createdId]);
      setAiTechDocById(prev =>
        new Map(prev).set(createdId, {
          id: createdId,
          name: newItemName
        })
      );
      const updatedValue = [...currentValue, createdId];
      setCurrentValue(updatedValue);
      setTimeout(() => {
        onChangeCallback(updatedValue);
      }, 250);
    },
    [addNewText, orgUnitIds, currentValue, onChangeCallback]
  );
  const createAITechDocCancelCallback = useCallback(() => {
    setToEditTechDocId(null);
  }, [setToEditTechDocId]);

  const aiTechDocCreatedCallback = useCallback((id?: string, name?: string) => {
    setToEditTechDocId(null);
    if (id && name) {
      setAiTechDocById(prev =>
        new Map(prev).set(id, {
          id,
          name
        })
      );
    }
  }, []);

  const renderTags = useCallback(
    (value: string[], getTagProps: AutocompleteGetTagProps) => {
      return value.map((id, index) => {
        const tagProps = getTagProps({ index });
        return (
          <Chip
            key={`tags-${index}`}
            {...tagProps}
            label={getOptionName(id) /* eslint-disable-next-line react/jsx-no-bind */}
            onClick={() => {
              setToEditTechDocId(id);
            }}
          />
        );
      });
    },
    [getOptionName]
  );

  return (
    <Box onFocus={onFocus} onBlur={onBlur}>
      <MultiAutocomplete
        addText={userHasAITechDocAddPermission && allowAdd ? addNewText : undefined}
        disabled={disabled}
        getOptionLabel={getOptionName}
        hasMultiSelect={true}
        label={t("asset_details:aiTechDocIds")}
        options={optionIds}
        selected={currentValue}
        updateSelected={onChangeCallback}
        updateOptions={userHasAITechDocAddPermission && allowAdd ? onOptionAddCallback : undefined}
        renderTags={renderTags}
      />
      <AITechDocModal
        id={toEditTechDocId || ""}
        open={!!toEditTechDocId}
        onClose={createAITechDocCancelCallback}
        onConfirm={aiTechDocCreatedCallback}
      />
    </Box>
  );
};

const aiTechDocClient = new AITechDocsApi(undefined, apiEndpoints.aiTechDocUrl, defaultOTCAuthenticatedAxios());
