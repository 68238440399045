import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMetaView } from "../../../../contexts/meta-view-context";
import { useTranslation } from "react-i18next";
import { AutomaticUserDataDisplay } from "../../../../../components/UserDataDisplay";
import { AssetsOverviewCollectionParams } from "app/pages/assets/overview/AssetsOverviewController";
import { OverviewProvider } from "app/contexts/overview-context";
import Overview, { OverviewParams } from "components/Overview/Overview";
import AssetsOverviewRow from "components/Overview/collections/assets/AssetsOverviewRow";
import { COLLECTIONS } from "app/collections";
import { OverviewRowActionProps } from "components/Overview/controls/OverviewRowAction";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Box, CircularProgress } from "@mui/material";
import { unlink } from "app/api/assetApi";
import { useSnackbar } from "notistack";
import { RowAction } from "components/ListViewItem/ListViewItem";

interface AssetsOverviewProps extends Pick<OverviewParams, "onRowOver" | "onRowLeave" | "noEntriesText" | "header"> {
  readonly filter: { [key: string]: string | string[] };
  readonly rowActions?: OverviewRowActionProps[] | RowAction[];
}
const AssetsOverview = React.memo(
  (props: AssetsOverviewProps) => {
    const collectionParams = useMemo<AssetsOverviewCollectionParams>(
      () => ({
        openInNewTab: true,
        filter: props.filter
      }),
      [props.filter]
    );
    const rowActions = props.rowActions || [];

    return (
      // new overview provider to not share the same state as the main asset overview
      <OverviewProvider>
        <Overview
          CustomRowComponent={AssetsOverviewRow}
          checkable={false}
          collection={COLLECTIONS.ASSETS}
          collectionParams={collectionParams}
          deletable={false}
          header={props.header}
          noEntriesText={props.noEntriesText}
          rowActions={rowActions}
          showSelectAll={false}
          toolbarMode="tabs"
          onRowLeave={props.onRowLeave}
          onRowOver={props.onRowOver}
        />
      </OverviewProvider>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.filter === nextProps.filter &&
      prevProps.header === nextProps.header &&
      prevProps.noEntriesText === nextProps.noEntriesText &&
      prevProps.rowActions === nextProps.rowActions
    );
  }
);
AssetsOverview.displayName = "ExternalRecipientAssetsOverview";

const ExternalRecipientAssetsOverview = ({ externalRecipientId }: { readonly externalRecipientId: string }) => {
  const { t } = useTranslation("assets_overview");
  const { setInfo, setMeta } = useMetaView();
  const { enqueueSnackbar } = useSnackbar();
  const [isUnlinkingInProgress, setIsUnlinkingInProgress] = useState(false);

  const unlinkAsset = useCallback(
    async assetId => {
      setIsUnlinkingInProgress(true);
      try {
        await unlink(assetId, { externalRecipientId });
        enqueueSnackbar(t("external_recipients_assets_overview:unlinkSuccess"), { variant: "success" });
      } catch (error) {
        console.error("Failed to unlink asset:", error);
        enqueueSnackbar(t("external_recipients_assets_overview:unlinkError"), { variant: "error" });
      } finally {
        setIsUnlinkingInProgress(false);
      }
    },
    [enqueueSnackbar, externalRecipientId, t]
  );

  const rowActions: OverviewRowActionProps[] = useMemo(
    () => [
      {
        action: "unlink",
        title: t("common:unlink"),
        icon: <LinkOffIcon />,
        onHandle: item => {
          unlinkAsset(item);
        }
      }
    ],
    [t, unlinkAsset]
  );

  const filter = useMemo(() => ({ externalRecipientIds: [externalRecipientId] }), [externalRecipientId]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("external_recipients_assets_overview:enteringInfoCardTitle"),
        text: t("external_recipients_assets_overview:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    if (setInfo) {
      setInfo(infoCard.entering);
    }
  }, [setInfo, infoCard.entering]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const onRowOver = useCallback(
    item => {
      setMeta({
        [t("title")]: item.title,
        [t("type")]: t(item.resourceAssetTypeId),
        [t("status")]: t(item.status),
        [t("owner")]: <AutomaticUserDataDisplay uid={item.creatorUID} separator={undefined} />,
        [t("amountOfLinkedProcesses")]: item.processes?.length || 0,
        [t("amountOfLinkedRisks")]: item?.riskIds?.length || 0,
        [t("amountOfLinkedDataSourcesOrStarageLocations")]: item?.dataLocationIds?.length || 0
      });
    },
    [setMeta, t]
  );

  if (isUnlinkingInProgress) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return <AssetsOverview filter={filter} onRowOver={onRowOver} onRowLeave={showEnterInfo} rowActions={rowActions} />;
};
ExternalRecipientAssetsOverview.displayName = "ExternalRecipientAssetsOverview";

export default ExternalRecipientAssetsOverview;
