import CustomAlert from "components/CustomAlert/CustomAlert";
import React, { useEffect, useState } from "react";
import { useExternalRecipients } from "../app/contexts/external-recipient-context";
import { useResources } from "../app/contexts/resource-context";
import { RESOURCE_TYPES } from "../app/handlers/resourceHandler";

export const ExpiringSCCNotice = ({
  toCheckExternalRecipientIds,
  expiringSCCKey,
  warningText
}: {
  readonly toCheckExternalRecipientIds?: string[];
  readonly expiringSCCKey: string;
  readonly warningText: React.ReactNode;
}) => {
  const { externalRecipients } = useExternalRecipients();
  const { resources } = useResources();
  const [expiringSCCID, setExpiringSCCID] = useState("");
  useEffect(() => {
    const safeguardLists = resources[RESOURCE_TYPES.SAFEGUARD] || [];
    setExpiringSCCID(safeguardLists.find(safeguard => safeguard.nameKey === expiringSCCKey)?.id || "");
  }, [expiringSCCKey, resources]);

  const [hasExpiringSCC, setHasExpiringSCC] = useState<boolean>(false);
  useEffect(() => {
    const toCheckEDRs = toCheckExternalRecipientIds?.length
      ? externalRecipients.filter(
          edr => edr.id && Array.isArray(edr?.safeguards) && toCheckExternalRecipientIds.includes(edr.id)
        )
      : externalRecipients;

    const hasExpiringSCC = toCheckEDRs.some(edr => edr?.safeguards?.includes(expiringSCCID));
    setHasExpiringSCC(hasExpiringSCC);
  }, [externalRecipients, toCheckExternalRecipientIds, expiringSCCID]);

  if (!hasExpiringSCC) {
    return <></>;
  }

  return <CustomAlert severity="error">{warningText}</CustomAlert>;
};

ExpiringSCCNotice.defaultProps = {
  externalRecipientIds: []
};
