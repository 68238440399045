import { Box, Button, CircularProgress, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import BasicPagination from "components/BasicPagination/BasicPagination";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/system/styleFunctionSx";

export interface MetaViewListItemActionProps {
  readonly action: string;
  readonly icon: ReactNode;
  readonly tooltip: string;
}
export interface MetaViewListItemProps {
  readonly id: string;
  readonly title: string;
  readonly subTitle: string;
  readonly icon?: ReactNode;
  readonly iconTooltip?: string;
  readonly actions?: MetaViewListItemActionProps[];
}

interface MetaViewListProps {
  readonly header: string;
  readonly headerIcon?: React.ReactNode;
  readonly headerTooltip?: React.ReactNode;
  readonly loading?: boolean;
  readonly items: MetaViewListItemProps[];
  readonly pagination?: boolean;
  readonly itemsPerPage?: number;

  readonly onItemClick?: (id: string) => void;
  readonly onItemActionClick?: (id: string, action: string) => void;
}

const MetaViewListItemAction = ({
  action,
  onClick
}: {
  readonly action: MetaViewListItemActionProps;
  readonly onClick: (action: MetaViewListItemActionProps) => void;
}) => {
  const onClickCallback = useCallback(() => {
    onClick(action);
  }, [action, onClick]);

  return (
    <Tooltip title={action.tooltip}>
      <IconButton onClick={onClickCallback}>{action.icon}</IconButton>
    </Tooltip>
  );
};

const MetaViewListItem = ({
  item,
  isLast,
  onClick,
  onActionClick
}: {
  readonly item: MetaViewListItemProps;
  readonly isLast: boolean;
  readonly onClick?: (id: string) => void;
  readonly onActionClick?: (id: string, action: string) => void;
}) => {
  const theme = useTheme();
  const sx = useMemo<Record<string, SxProps>>(
    () => ({
      button: {
        borderRadius: 0,
        width: "100%",
        display: "block",
        padding: "16px 16px 16px 8px",
        borderBottom: `1px solid ${theme.palette.grey[400]}`
      },
      item: {
        width: "100%",
        display: "flex",
        alignItems: "center"
      },
      itemLast: {
        border: "none"
      },
      text: {
        ml: 2,
        flex: 1,
        textAlign: "left",
        overflow: "hidden"
      },
      title: {
        whiteSpace: "nowrap",
        minWidth: 0,
        overflow: "hidden",
        textOverflow: "ellipsis"
      },
      caption: {
        color: `${theme.palette.grey[400]} !important`
      },
      actions: {
        px: 1,
        display: "flex",
        alignItems: "center",
        gap: 0.5
      }
    }),
    [theme]
  );

  const { id, title, subTitle, icon, iconTooltip, actions } = item;
  const onCLickCallback = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  const onActionClickCallback = useCallback(
    action => {
      onActionClick?.(id, action.action);
    },
    [id, onActionClick]
  );

  const stopClick = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const actionsEl = (() => {
    if (!actions || !actions.length) {
      return <></>;
    }
    return (
      <Box sx={sx.actions} onClick={stopClick}>
        {actions.map(action => (
          <MetaViewListItemAction key={action.action} action={action} onClick={onActionClickCallback} />
        ))}
      </Box>
    );
  })();

  const buttonSx = useMemo<SxProps>(() => {
    return { ...sx.button, ...(isLast ? sx.itemLast : {}) };
  }, [isLast, sx.button, sx.itemLast]);

  const typoSx = useMemo<SxProps>(() => {
    return { ...sx.title, ...sx.caption };
  }, [sx.title, sx.caption]);

  return (
    <Button sx={buttonSx} onClick={onCLickCallback}>
      <Box sx={sx.item}>
        <Tooltip title={iconTooltip}>
          <Box>{icon || <></>}</Box>
        </Tooltip>
        <Box sx={sx.text}>
          <Typography sx={sx.title}>{title}</Typography>
          <Typography sx={typoSx} variant="caption">
            {subTitle}
          </Typography>
        </Box>
        {actionsEl}
      </Box>
    </Button>
  );
};

const MetaViewList = ({
  header,
  headerIcon,
  headerTooltip,
  loading,
  items,
  pagination,
  itemsPerPage = 5,
  onItemClick,
  onItemActionClick
}: MetaViewListProps) => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);

  /* PAGINATION */
  const currentPageItems = useCallback(
    (element, index) => {
      if (pagination && items.length > itemsPerPage) {
        return index < page * itemsPerPage && index >= (page - 1) * itemsPerPage;
      }
      return true;
    },
    [pagination, items.length, itemsPerPage, page]
  );
  const paginationEl = useMemo(() => {
    if (pagination && items.length > itemsPerPage) {
      return (
        <BasicPagination elementsPerPage={itemsPerPage} page={page} setPage={setPage} numberElements={items.length} />
      );
    }
  }, [items.length, itemsPerPage, page, pagination]);

  /* LIST */
  const listEl = !loading && (
    <Box>
      {items.filter(currentPageItems).map((item, index) => (
        <MetaViewListItem
          key={item.id}
          item={item}
          onClick={onItemClick}
          isLast={index === items.length - 1}
          onActionClick={onItemActionClick}
        />
      ))}
    </Box>
  );

  /* LOADING */
  const loadingEl = loading && (
    <Box textAlign={"center"} mt={1}>
      <CircularProgress />
    </Box>
  );

  /* NO ENTRIES */
  const noEntriesFoundEl = (() => {
    if (!(!loading && items.length === 0)) {
      return <></>;
    }

    return (
      <Typography textAlign="center" color="text.secondary" pb={1} pt={4}>
        {t("overview:no_entries_found")}
      </Typography>
    );
  })();

  return (
    <Box bgcolor="blue.50" borderRadius={1.5} p={3}>
      <Box>
        <Tooltip title={headerTooltip}>
          <>
            {headerIcon || <></>}
            <Typography variant="h4">{header}</Typography>
          </>
        </Tooltip>
      </Box>
      {listEl}
      {loadingEl}
      {noEntriesFoundEl}
      {paginationEl}
    </Box>
  );
};

export default MetaViewList;
