import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import MetaView from "components/MetaView/MetaView";
import { useMetaView } from "app/contexts/meta-view-context";
import { usePathParamByKey } from "app/router/router-custom-hooks";
import { useNavigate } from "react-router-dom";
import { useUserRoles } from "app/contexts/role-context";
import { allDisplayablePermissions, allPermissions, ServicePermission } from "app/handlers/permissionHandler";
import { PermissionCheckboxes } from "./PermissionCheckboxes";
import { AssignableFieldsCheckBoxes } from "./AssignableFieldsCheckboxes";
import { AllAssignableUserFields } from "../../api/user/userApi";
import { Box, Button, CircularProgress, Divider, TextField } from "@mui/material";
import TextBody2 from "../../../components/TextBody2/TextBody2";

export default function CreateAndEditRole() {
  const existingRoleId = usePathParamByKey("id");
  const { t } = useTranslation("manage-user-page");
  const { createRoleHook, updateRoleHook, getRoleHook } = useUserRoles();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);
  const [checkedAssignableFields, setCheckedAssignableFields] = useState<string[]>([]);
  const [groupedServicePermissions, setGroupedServicePermissions] = useState<ServicePermission[][]>([]);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setInfo } = useMetaView();
  const navigateToOverview = useCallback(() => navigate("/roles"), [navigate]);

  const [notDisplayedPermissions, setNotDisplayedPermissions] = useState<string[]>([]);

  const loadRole = useCallback(async () => {
    // array of two permission sets for later rendering
    const groupedPermissions = allPermissions.reduce((acc, nextValue, index) => {
      return ((index % 2 === 0 ? acc.push([nextValue]) : acc[acc.length - 1].push(nextValue)) &&
        acc) as ServicePermission[][];
    }, [] as ServicePermission[][]);
    setGroupedServicePermissions(groupedPermissions);
    if (!existingRoleId) {
      setName("");
      setCheckedPermissions([]);
      setCheckedAssignableFields([]);
      setNotDisplayedPermissions([]);
      return;
    }

    const role = await getRoleHook(existingRoleId);
    if (!role) {
      return navigateToOverview();
    }
    setName(role.name || "");
    setCheckedAssignableFields(role.assignableFields || []);

    const checkedPermissions = [...(role.permissions || [])];
    setCheckedPermissions(checkedPermissions);
    setNotDisplayedPermissions(checkedPermissions.filter(permission => !allDisplayablePermissions.has(permission)));
  }, [existingRoleId, getRoleHook, navigateToOverview]);

  useEffect(() => {
    loadRole();
  }, [loadRole]);

  const defaultInfo = useMemo(
    () =>
      existingRoleId
        ? {
            title: t("roles_overview:roleDetailsInfoTitle"),
            text: t("roles_overview:roleDetailsInfoText")
          }
        : {
            title: t("roles_overview:addNewRoleInfoTitle"),
            text: t("roles_overview:addNewRoleInfoText")
          },
    [existingRoleId, t]
  );

  useEffect(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);

  // register Role in Database
  const registerRole = useCallback(async () => {
    setLoadingInfo(true);
    const trimmedName = name?.trim();

    try {
      await createRoleHook({
        name: trimmedName,
        permissions: checkedPermissions,
        assignableFields: checkedAssignableFields
      });

      setLoadingInfo(false);
      enqueueSnackbar(t("role_created_message"), { variant: "success" });
      navigateToOverview();
    } catch (error: any) {
      if (error.response.data.error === "ConflictError") {
        enqueueSnackbar(t("roles_overview:role_name_exist", { name: trimmedName }), { variant: "error" });
      } else {
        enqueueSnackbar(t(error?.message), { variant: "error" });
      }
      setLoadingInfo(false);
    }
  }, [name, createRoleHook, checkedPermissions, checkedAssignableFields, enqueueSnackbar, t, navigateToOverview]);

  // edit Role in Database
  const editRole = useCallback(async () => {
    if (!existingRoleId) {
      throw new Error("Role is non existent!");
    }

    const trimmedName = name?.trim();
    setLoadingInfo(true);
    try {
      await updateRoleHook(existingRoleId, {
        name: trimmedName,
        permissions: checkedPermissions,
        assignableFields: checkedAssignableFields
      });
      enqueueSnackbar(t("changes_saved"), { variant: "success" });
      setLoadingInfo(false);
      navigateToOverview();
    } catch (error: any) {
      if (error.response.data.error === "ConflictError") {
        enqueueSnackbar(t("roles_overview:role_name_exist", { name: trimmedName }), { variant: "error" });
      } else {
        enqueueSnackbar(t(error?.message), { variant: "error" });
      }
      setLoadingInfo(false);
    }
  }, [
    existingRoleId,
    name,
    updateRoleHook,
    checkedPermissions,
    checkedAssignableFields,
    enqueueSnackbar,
    t,
    navigateToOverview
  ]);

  const necessaryFieldsSubmitted = () => !loadingInfo && name && checkedPermissions.length > 0;

  const onInfoTextReset = useCallback(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);
  const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const updateSelectedPermission = useCallback((permissions: string[]) => {
    setCheckedPermissions([...permissions]);
  }, []);

  const updateSelectedAssignableField = useCallback((assignableFields: string[]) => {
    setCheckedAssignableFields([...assignableFields]);
  }, []);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey="organisation" />}>
      <DocView header={existingRoleId ? t("edit_role_information") : t("add_role")}>
        <Box mt={2}>
          <TextField
            required
            fullWidth={true}
            label={t("fields:role_name")}
            value={name}
            onChange={onNameChanged}
            name="name_optional"
            id="name"
            type="text"
            variant="outlined"
            onBlur={onInfoTextReset}
          />
        </Box>
        <Box mt={3}>
          <TextBody2 text="* This is an old “all organizational units” permission, which will no longer exist in the future. Please only use the other permissions." />
        </Box>
        {groupedServicePermissions.map((permissionsOfTwo, index) => (
          <Box display={"flex"} key={"column " + index}>
            {permissionsOfTwo.map(permissions => (
              <Box flex={1} key={permissions.name}>
                <PermissionCheckboxes
                  key={permissions.name}
                  permissions={permissions.permissions}
                  dependencies={permissions.dependencies}
                  name={permissions.name}
                  selectedPermissions={checkedPermissions}
                  updateSelected={updateSelectedPermission}
                />
              </Box>
            ))}
          </Box>
        ))}
        {notDisplayedPermissions.length > 0 ? (
          <>
            <Divider />
            <PermissionCheckboxes
              permissions={notDisplayedPermissions}
              dependencies={{}}
              name={"other_permissions"}
              selectedPermissions={checkedPermissions}
              updateSelected={updateSelectedPermission}
            />
          </>
        ) : (
          <></>
        )}

        <Divider />

        <Box>
          <AssignableFieldsCheckBoxes
            name={"Expert assignable Fields"}
            assignableFields={AllAssignableUserFields}
            selectedAssignableFields={checkedAssignableFields}
            updateSelected={updateSelectedAssignableField}
          />
        </Box>

        <Box display={"flex"} mt={4} justifyContent="space-between">
          <Box>
            <Button variant="outlined" color="primary" onClick={navigateToOverview}>
              {t("back")}
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={!necessaryFieldsSubmitted()}
              onClick={existingRoleId ? editRole : registerRole}
            >
              {loadingInfo && (
                <Box mr={1}>
                  <CircularProgress color="inherit" size={14} />
                </Box>
              )}
              {existingRoleId ? t("save_user") : t("add")}
            </Button>
          </Box>
        </Box>
      </DocView>
    </DocMetaView>
  );
}
