import {
  executeInSessionStorageLock,
  getAccessTokenInSession,
  getPersistentlyStoredRefreshToken,
  storeAccessTokenInSession,
  storePersistentRefreshToken
} from "./authenticationStorage";
import { OAuth2ResponseDTO } from "../../api/user/userOAuth2ApiInterfaces";
import { signInWithRefreshToken } from "./authenticationSignInHandler";
import { NotLoggedInError } from "./authenticationError";

export const assertGetUserAccessToken = async (): Promise<string> => {
  const isExpired = (expiresAtISODate: string): boolean => {
    return new Date().getTime() >= new Date(expiresAtISODate).getTime();
  };

  const currentAccessTokenIfNotExpired = async (): Promise<string | null> => {
    const accessToken = await getAccessTokenInSession();
    const validAccessToken = accessToken && accessToken.expiresAt && !isExpired(accessToken.expiresAt);
    return validAccessToken ? accessToken.token : null;
  };

  const refreshAccessTokenAndSave = async (): Promise<string> => {
    return await executeInSessionStorageLock("accessTokenRefreshLock", async () => {
      const existingAccessToken = await currentAccessTokenIfNotExpired();
      if (existingAccessToken) {
        return existingAccessToken;
      }

      const refreshToken = await getPersistentlyStoredRefreshToken();
      if (!refreshToken?.token || isExpired(refreshToken.expiresAt)) {
        throw new NotLoggedInError();
      }

      const accessToken = await signInWithRefreshToken({ refreshToken: refreshToken.token });
      return accessToken.accessToken;
    });
  };

  const accessToken = await currentAccessTokenIfNotExpired();
  if (accessToken) {
    return accessToken;
  }

  return refreshAccessTokenAndSave();
};

export const assertAccessToken = (
  response: Pick<OAuth2ResponseDTO, "access_token" | "expires_in">
): { readonly accessToken: string; readonly expiresInS: number } => {
  const accessToken = response?.access_token;
  const expiresInS = response?.expires_in;
  if (!accessToken) {
    throw new Error("access_token is not found in response");
  }
  if (!expiresInS) {
    throw new Error("expires_in is not found in response");
  }
  return {
    accessToken,
    expiresInS
  };
};

const assertAccessAndRefreshToken = (
  response: Pick<OAuth2ResponseDTO, "access_token" | "expires_in" | "refresh_token">
): { readonly accessToken: string; readonly refreshToken: string; readonly expiresInS: number } => {
  const { accessToken, expiresInS } = assertAccessToken(response);
  const refreshToken = response.refresh_token;
  if (!refreshToken) {
    throw new Error("refresh_token is not found in response");
  }
  return {
    accessToken,
    expiresInS,
    refreshToken
  };
};

export const assertAndSaveToken = async (
  response: Pick<OAuth2ResponseDTO, "access_token" | "expires_in" | "refresh_token">
): Promise<{ readonly accessToken: string; readonly refreshToken: string; readonly expiresInS: number }> => {
  const token = assertAccessAndRefreshToken(response);
  await storeAccessTokenInSession(token);
  await storePersistentRefreshToken(token);
  return token;
};
export const isRefreshTokenAboutToExpire = async (): Promise<boolean> => {
  const refreshToken = await getPersistentlyStoredRefreshToken();
  if (!refreshToken?.token || !refreshToken?.expiresAt) {
    return false; // no refresh token means not about to expire
  }

  const fiveMinutesInMs = 1000 * 60 * 5;
  const fiveMinutesBeforeExpiration = new Date(refreshToken.expiresAt).getTime() - fiveMinutesInMs;
  return new Date().getTime() >= fiveMinutesBeforeExpiration;
};
