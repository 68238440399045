import i18n from "app/i18n";
import { OrgUnitTreeStructure } from "./OrganizationOverview";
import { getOrganizationFilterBoundOrgUnit } from "app/pages/shared/Filters/filters";

import { Department } from "../../handlers/departmentHandler";
import { createNaturalSorter } from "../../utils/naturalSort";
import { FilterItemProps } from "../../../components/Overview/controllers/overviewBaseController";

export const sortings = [
  {
    field: "name",
    type: "asc",
    label: i18n.t("filter_criteria:aToZ")
  },
  {
    field: "name",
    type: "desc",
    label: i18n.t("filter_criteria:zToA")
  }
];

export const getFilter = (departments: Department[], filterOrgUnit: string[]): FilterItemProps[] => {
  const filter: FilterItemProps = getOrganizationFilterBoundOrgUnit("id", departments, i18n.t, filterOrgUnit) || {
    filterTree: [],
    filterField: ""
  };
  return [filter];
};

const sortOneNode = (level: OrgUnitTreeStructure[], order: string) => {
  const naturalSorter = createNaturalSorter({
    desc: order === "desc"
  });
  return level.sort((a, b) => naturalSorter(a.data.name, b.data.name));
};

export const sortTreeByName = (tree: OrgUnitTreeStructure[], order: string): OrgUnitTreeStructure[] => {
  const sortChildren = (node: OrgUnitTreeStructure): OrgUnitTreeStructure => {
    if (node.children && node.children.length > 0) {
      const sortedChildren = sortOneNode(node.children, order).map(sortChildren);
      return { ...node, children: sortedChildren };
    }
    return node;
  };

  const sortedTree = [...tree];
  sortOneNode(sortedTree, order);
  sortedTree.map(sortChildren);

  return sortedTree;
};
