import { Box, Chip, Tooltip, useTheme } from "@mui/material";
import { useMissingTranslations } from "app/api/multilingualApi";
import { useTLng } from "components/Multilingual/MultilingualModal";
import { useTranslation } from "react-i18next";

export function UntranslatedCountBadge({ id }: { id: string }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { untranslatedLngs } = useMissingTranslations(id);
  const tLng = useTLng();

  if (untranslatedLngs.length === 0) {
    return null;
  }

  return (
    <Box py={1} mr={3}>
      <Tooltip
        title={t("multilingual:untranslatedLanguages", {
          languages: untranslatedLngs.map(lng => tLng(lng)).join(", ")
        })}
      >
        <Chip
          style={{
            textTransform: "uppercase",
            backgroundColor: "#F17A79",
            color: theme.colors.white,
            paddingTop: 2
          }}
          label={t("multilingual:nTranslationsMissing", { count: untranslatedLngs.length })}
        />
      </Tooltip>
    </Box>
  );
}
