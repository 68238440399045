import {
  ControllerUpdateDTO,
  DataBreachUpdateDTO,
  downloadDataBreachNotificationWordDocumentFromAPI,
  DPAUpdateDTO,
  DPOUpdateDTO,
  getControllerByDataBreachIdFromApi,
  getDataBreachByIdFromApi,
  getDPAByDataBreachIdFromApi,
  getDPOByDataBreachIdFromApi,
  updateControllerApi,
  updateDataBreachApi,
  updateDPAApi,
  updateDPOApi
} from "../api/dataBreachApi";
import { COLLECTIONS } from "../collections";
import { sendNotificationApi } from "../api/userNotificationApi";

export const STATUSES = ["OPEN", "IN_PROGRESS", "PENDING_FEEDBACK", "CLOSED"];

export async function getDataBreachById(id: string) {
  return await getDataBreachByIdFromApi(id);
}

export async function getControllerByDataBreachId(id: string) {
  return await getControllerByDataBreachIdFromApi(id);
}

export async function getDPAByDataBreachId(id: string) {
  return await getDPAByDataBreachIdFromApi(id);
}

export async function getDPOByDataBreachId(id: string) {
  return await getDPOByDataBreachIdFromApi(id);
}

export async function updateDataBreach(id: string, dataBreachData: DataBreachUpdateDTO, user: NotificationUser) {
  if (dataBreachData.assigneeUID && dataBreachData.assigneeUID !== user.uid) {
    await sendAssignNotification(id, dataBreachData.assigneeUID);
  }
  if (dataBreachData.dueAt) {
    await sendDueAtChangedNotification(id, user);
  }
  return updateDataBreachApi(id, dataBreachData);
}

export async function updateController(
  dataBreachId: string,
  controllerId: string,
  controllerData: ControllerUpdateDTO
) {
  return updateControllerApi(dataBreachId, controllerId, controllerData);
}

export async function updateDPO(dataBreachId: string, dpoId: string, dpoData: DPOUpdateDTO) {
  return updateDPOApi(dataBreachId, dpoId, dpoData);
}

export async function updateDPA(dataBreachId: string, dpaId: string, dpaData: DPAUpdateDTO) {
  return updateDPAApi(dataBreachId, dpaId, dpaData);
}

async function sendAssignNotification(dataBreachId: string, assigneeUID: string) {
  const dataBreach = await getDataBreachById(dataBreachId);

  if (!dataBreach) {
    return;
  }

  await sendNotificationApi({
    title: "dbreach_assigned",
    receivers: [assigneeUID],
    pageId: "general",
    collection: COLLECTIONS.DATA_BREACHES,
    docId: dataBreachId,
    docName: dataBreach.title || ""
  });
}

type NotificationUser = {
  readonly uid: string;
  readonly tenantId: string;
};

async function sendDueAtChangedNotification(dataBreachId: string, user: NotificationUser) {
  const dataBreach = await getDataBreachById(dataBreachId);

  if (!dataBreach) {
    return;
  }

  if (!dataBreach.assigneeUID) {
    return;
  }

  if (user.uid === dataBreach.assigneeUID) {
    return;
  }

  await sendNotificationApi({
    title: "dbreach_due_date_changed",
    receivers: [dataBreach.assigneeUID],
    pageId: "general",
    collection: "dataBreaches",
    docId: dataBreachId,
    docName: dataBreach.title || ""
  });
}

export async function downloadDataBreachNotificationWordDocument(
  dataBreachId: string,
  resourceTree: object[],
  detectionTypeLabel: string,
  language: string
) {
  const { data, fileName } = await downloadDataBreachNotificationWordDocumentFromAPI(
    dataBreachId,
    resourceTree,
    detectionTypeLabel,
    language
  );

  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}
