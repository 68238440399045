import React from "react";
import PDFIcon from "./../../../../assets/images/icons/pdf.svg";
import DOCXIcon from "./../../../../assets/images/icons/docx.svg";
import RTFIcon from "./../../../../assets/images/icons/rtf.svg";
import JPGIcon from "./../../../../assets/images/icons/jpg.svg";
import PNGIcon from "./../../../../assets/images/icons/png.svg";
import DOCIcon from "./../../../../assets/images/icons/doc.svg";
import ODTIcon from "./../../../../assets/images/icons/odt.svg";
import XLSIcon from "./../../../../assets/images/icons/xls.svg";
import PPTIcon from "./../../../../assets/images/icons/ppt.svg";
import ZIPIcon from "./../../../../assets/images/icons/zip.svg";

export function normaliseFileNameExtension(fileName) {
  const fileNameArray = fileName.split(".");
  let fileNameWithoutExtension;
  let extension;
  // necessary because file can have "." in the name as well
  fileNameArray.forEach((nameString, index) => {
    if (index === 0) {
      fileNameWithoutExtension = nameString;
    } else if (index < fileNameArray.length - 1) {
      fileNameWithoutExtension = fileNameWithoutExtension + "." + nameString;
    } else {
      extension = nameString;
    }
  });
  const extensionLowerCase = extension.toLowerCase();
  return fileNameWithoutExtension + "." + extensionLowerCase;
}

export const ALLOWED_FILE_EXTENSIONS = {
  PDF: ".pdf",
  DOC: ".doc",
  DOCX: ".docx",
  TXT: ".txt",
  XLS: ".xls",
  XLSX: ".xlsx",
  CSV: ".csv",
  JPG: ".jpg",
  JPEG: ".jpeg",
  PNG: ".png",
  TIF: ".tif",
  PPT: ".ppt",
  PPTX: ".pptx",
  ODT: ".odt",
  RTF: ".rtf",
  KEY: ".key",
  ODP: ".odp",
  TIFF: ".tiff",
  ZIP: ".zip",
  RAR: ".rar",
  MSG: ".msg",
  HTML: ".html",
  SEVEN_Z: ".7z",
  DOCM: ".docm",
  DOTX: ".dotx",
  GIF: ".gif",
  HTM: ".htm",
  ICS: ".ics",
  VSD: ".vsd",
  XLSM: ".xlsm",
  XLTX: ".xltx",
  XLTM: ".xltm",
  POTX: ".potx",
  WAV: ".wav"
};

export const getFileIcon = (filename, classes) => {
  const extension = `.${filename.split(".").pop()}`;
  switch (extension) {
    case ALLOWED_FILE_EXTENSIONS.PDF:
      return <PDFIcon className={classes.smallAvatar} alt={"pdf"} />;
    case ALLOWED_FILE_EXTENSIONS.DOCX:
      return <DOCXIcon className={classes.smallAvatar} alt={"docx"} />;
    case ALLOWED_FILE_EXTENSIONS.RTF:
      return <RTFIcon className={classes.smallAvatar} alt={"rtf"} />;
    case ALLOWED_FILE_EXTENSIONS.JPG:
      return <JPGIcon className={classes.smallAvatar} alt={"jpg"} />;
    case ALLOWED_FILE_EXTENSIONS.JPEG:
      return <JPGIcon className={classes.smallAvatar} alt={"jpeg"} />;
    case ALLOWED_FILE_EXTENSIONS.PNG:
      return <PNGIcon className={classes.smallAvatar} alt={"png"} />;
    case ALLOWED_FILE_EXTENSIONS.ODT:
      return <ODTIcon className={classes.smallAvatar} alt={"odt"} />;
    case ALLOWED_FILE_EXTENSIONS.XLS:
      return <XLSIcon className={classes.smallAvatar} alt={"xls"} />;
    case ALLOWED_FILE_EXTENSIONS.XLSX:
      return <XLSIcon className={classes.smallAvatar} alt={"xlsx"} />;
    case ALLOWED_FILE_EXTENSIONS.PPT:
      return <PPTIcon className={classes.smallAvatar} alt={"ppt"} />;
    case ALLOWED_FILE_EXTENSIONS.PPTX:
      return <PPTIcon className={classes.smallAvatar} alt={"pptx"} />;
    case ALLOWED_FILE_EXTENSIONS.ZIP:
      return <ZIPIcon className={classes.smallAvatar} alt={"zip"} />;
    default:
      return <DOCIcon className={classes.smallAvatar} alt={"default"} />;
  }
};
