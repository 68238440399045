import React, { useCallback, useMemo, useRef, useState } from "react";
import createMentionPlugin from "@draft-js-plugins/mention";
import Editor from "@draft-js-plugins/editor";
import "@draft-js-plugins/mention/lib/plugin.css";
import "./mentionEditorStyles.css";
import { useTheme } from "@material-ui/core/styles";
import { UserDTO } from "../../app/api/user/userApi";
import { EditorState } from "draft-js";
import { EntryComponentProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry";
import { Box } from "@material-ui/core";

export const EditorWithMention = ({
  text,
  setText,
  readOnly,
  onSearchChange,
  suggestions,
  placeholder,
  onAddMention
}: {
  readonly text: EditorState;
  readonly setText: (input: EditorState) => void;
  readonly readOnly: boolean;
  readonly onSearchChange: (input: { readonly value: string }) => void;
  readonly suggestions: (UserDTO & { readonly name: string })[];
  readonly onAddMention: (input: UserDTO & { readonly name: string }) => void;
  readonly placeholder?: string;
}) => {
  const editor = useRef<Editor>(null);
  const primaryColour = useTheme().palette.primary.main;
  const [open, setOpen] = useState(false);
  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionComponent: mentionProps => {
        return <span className="mentionUser">{mentionProps.children}</span>;
      },
      popperOptions: {
        placement: "bottom-end"
      }
    });
    const { MentionSuggestions } = mentionPlugin;
    return { MentionSuggestions, plugins: [mentionPlugin] };
  }, []);

  const entryComponent = useCallback(
    (props: EntryComponentProps) => {
      const { mention, theme, isFocused, searchValue, ...parentProps } = props;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...parentProps} className={"mentionWrap" + (isFocused ? " active" : "")}>
          <Box className="mentionWrap__name">{boldString(mention.name, searchValue, primaryColour)}</Box>
          {mention.email !== mention.name && (
            <Box className="mentionWrap__email">{boldString(mention.email, searchValue, primaryColour)}</Box>
          )}
        </div>
      );
    },
    [primaryColour]
  );

  return (
    <>
      <Editor
        placeholder={placeholder}
        editorState={text}
        onChange={setText}
        plugins={plugins}
        readOnly={readOnly}
        ref={editor}
      />
      <MentionSuggestions
        onSearchChange={onSearchChange}
        suggestions={suggestions}
        onAddMention={onAddMention}
        entryComponent={entryComponent}
        open={open}
        onOpenChange={onOpenChange}
      />
    </>
  );
};

export const boldString = (str: string, find?: string, color?: string) => {
  if (!str?.split || !find) {
    return str;
  }
  const parts = str.split(find);
  return (
    <>
      {parts.map((part, index) => {
        const last = index === parts.length - 1;
        if (last) {
          return <React.Fragment key={`${str}-${index}`}>{part}</React.Fragment>;
        }
        return (
          <React.Fragment key={`${str}-${index}`}>
            {part}
            <b style={{ color: color }}>{find}</b>
          </React.Fragment>
        );
      })}
    </>
  );
};

export const arrayBoldString = (str: string, splitters: string[], color: string, displayAtSign?: boolean) => {
  if (!str || splitters.length === 0) {
    return str;
  }
  splitters.sort((a, b) => b.length - a.length);
  const pattern = new RegExp(`(${splitters.map(s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")).join("|")})`, "g");
  const parts = str.split(pattern);
  return (
    <>
      {parts.map((part, index) => {
        const isSplitter = splitters.includes(part);
        return isSplitter ? (
          <b key={`${part}-${index}`} style={{ color: color, fontWeight: 400, letterSpacing: 0.25 }}>
            {displayAtSign && "@"}
            {part}
          </b>
        ) : (
          <React.Fragment key={`${part}-${index}`}>{part}</React.Fragment>
        );
      })}
    </>
  );
};
