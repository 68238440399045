import { retryUntil } from "../utility/retry";
import { uuid4 } from "@sentry/utils";

interface Storage {
  save<T extends object>(key: string, input: T): Promise<void>;

  get<T extends object>(key: string): Promise<T | null>;

  clearAll(): Promise<void>;
}

const storageNamespace = "caralegal::auth::";

const persistentStorageEngine: Storage = {
  async get<T>(key: string): Promise<T | null> {
    const item = window.localStorage.getItem(`${storageNamespace}${key}`);
    return item ? (JSON.parse(item) as T) : null;
  },
  async save<T>(key: string, input: T): Promise<void> {
    window.localStorage.setItem(`${storageNamespace}${key}`, JSON.stringify(input));
  },
  async clearAll(): Promise<void> {
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (key?.startsWith(storageNamespace)) {
        window.localStorage.removeItem(key);
      }
    }
  }
};

const sessionStorageEngine: Storage = {
  async get<T>(key: string): Promise<T | null> {
    const item = window.sessionStorage.getItem(`${storageNamespace}${key}`);
    return item ? (JSON.parse(item) as T) : null;
  },
  async save<T>(key: string, input: T): Promise<void> {
    window.sessionStorage.setItem(`${storageNamespace}${key}`, JSON.stringify(input));
  },
  async clearAll(): Promise<void> {
    window.sessionStorage.clear();
  }
};

export interface StoredToken {
  readonly token: string;
  readonly expiresAt: string;
}

export const storePersistentRefreshToken = async ({ refreshToken }: { refreshToken: string }) => {
  const gracePeriodOf15Minutes = 1000 * 60 * 15;
  const refreshTokenExpiresInMs = 1000 * 60 * 60 * 24 - gracePeriodOf15Minutes; // 24 hours - 15 minutes grace period
  await persistentStorageEngine.save<StoredToken>("refreshToken", {
    token: refreshToken,
    expiresAt: new Date(new Date().getTime() + refreshTokenExpiresInMs).toISOString()
  });
};

export const getPersistentlyStoredRefreshToken = async (): Promise<StoredToken | null> => {
  const token = await persistentStorageEngine.get<StoredToken>("refreshToken");
  if (!token?.token || !token.expiresAt) {
    return null;
  }
  return token || null;
};

export const storeAccessTokenInSession = async ({
  accessToken,
  expiresInS
}: {
  accessToken: string;
  expiresInS: number;
}) => {
  const gracePeriodInMs = 1000 * 60; // 1 minute before we should already try to refresh
  const accessTokenExpiresInMs = expiresInS * 1000;
  await sessionStorageEngine.save<StoredToken>("accessToken", {
    token: accessToken,
    expiresAt: new Date(new Date().getTime() + accessTokenExpiresInMs - gracePeriodInMs).toISOString()
  });
};

export const getAccessTokenInSession = async (): Promise<StoredToken | null> => {
  const token = await sessionStorageEngine.get<StoredToken>("accessToken");
  if (!token?.token || !token.expiresAt) {
    return null;
  }
  return token || null;
};

export type StoredTenantToken = StoredToken & { readonly tenantId: string };

export const storeHasuraTokenInSession = async ({ token, tenantId, expiresAt }: StoredTenantToken) => {
  await sessionStorageEngine.save<StoredTenantToken>("hasuraToken", {
    token: token,
    expiresAt: expiresAt,
    tenantId: tenantId
  });
};

export const getHasuraTokenInSession = async (): Promise<StoredTenantToken | null> => {
  const token = await sessionStorageEngine.get<StoredTenantToken>("hasuraToken");
  if (!token?.token || !token.expiresAt || !token.tenantId) {
    return null;
  }
  return token || null;
};

export interface SessionStorageLock {
  readonly lockedAt: string;
  readonly lockId: string;
}

export const executeInSessionStorageLock = async <T>(lockName: string, run: () => Promise<T>): Promise<T> => {
  const accessTokenExpireInMs = 5 * 1000;

  const lockId = uuid4();
  const insertLock = () =>
    sessionStorageEngine.save(lockName, {
      lockedAt: new Date().toISOString(),
      lockId
    } satisfies SessionStorageLock);

  const removeLock = async (lockId: string): Promise<void> => {
    const lock = await sessionStorageEngine.get<SessionStorageLock>(lockName);
    if (lock?.lockId !== lockId) {
      return;
    }

    await sessionStorageEngine.save(lockName, {});
  };

  const errorOnNoLock = async () => {
    const lock = await sessionStorageEngine.get<SessionStorageLock>(lockName);
    if (lock?.lockedAt) {
      const lockedAtDate = new Date(lock.lockedAt);
      if (new Date().getTime() - lockedAtDate.getTime() < accessTokenExpireInMs) {
        throw new Error(`Can't get a lock for: ${lockName}`);
      }
    }
  };

  // retry until it gets the lock
  await retryUntil(
    async () => {
      await errorOnNoLock();
      await insertLock();
      return;
    },
    10000,
    250
  );

  try {
    const result = await run();
    await removeLock(lockId);
    return result;
  } catch (e: unknown) {
    await removeLock(lockId);
    throw e;
  }
};

export const clearAccessTokenInSession = sessionStorageEngine.clearAll;
export const clearPersistentRefreshToken = persistentStorageEngine.clearAll;

export interface StoredLoginPreference {
  readonly tenantId: string | null;
  readonly ssoIds: string[];
}

export const getLoginPreference = async (): Promise<StoredLoginPreference> => {
  const loginPreference = await sessionStorageEngine.get<StoredLoginPreference>("loginPreference");
  return {
    tenantId: loginPreference?.tenantId || null,
    ssoIds: loginPreference?.ssoIds || []
  };
};

export const storeLoginPreferenceInSession = async (
  modifierFn: (lastPref: StoredLoginPreference) => StoredLoginPreference
) => {
  const loginPreference = await getLoginPreference();
  const modifiedLoginPreference = await modifierFn(loginPreference);
  await sessionStorageEngine.save<Partial<StoredLoginPreference>>("loginPreference", modifiedLoginPreference);
};
