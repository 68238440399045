import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useErrorSnackbar } from "hook/errorSnackbar";

import { MFALoginRequired, WrongCredentials } from "../../handlers/authentication/authenticationError";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "8px 0",
    lineHeight: "72px"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  input: {
    width: "350px",
    margin: "20px 0"
  },
  mfaResolver: {
    width: "350px"
  }
}));

export default function AccountSettingsReauthenticate({
  onSuccess,
  title,
  buttonText
}: {
  onSuccess?: (password: string) => Promise<void>;
  title: string;
  buttonText: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation("accountSettingsReauthenticate");
  const { signInUserHook, user } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [enterMFARequired, setEnterMFARequired] = useState(false);
  const [mfaToken, setMFAToken] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const { catchAsSnackbar } = useErrorSnackbar();

  const resetFields = useCallback(() => {
    setLoading(false);
    setCurrentPassword("");
    setShowCurrentPassword(false);
  }, []);

  const reauthenticateCallback = useCallback(async () => {
    if (!user?.email) {
      throw new Error("User not actually logged in!");
    }

    setLoading(true);
    try {
      await signInUserHook(user.email, currentPassword, mfaToken);
    } catch (error: any) {
      setLoading(false);
      if (error instanceof WrongCredentials) {
        enqueueSnackbar(t("password_entered_wrong"), { variant: "error" });
        return;
      }

      if (error instanceof MFALoginRequired) {
        setEnterMFARequired(true);
        return;
      }

      catchAsSnackbar("reauthenticate failed due to unknown reason")(error);
      throw error;
    }

    await resetFields();
    await onSuccess?.(currentPassword);
  }, [
    user?.email,
    setLoading,
    signInUserHook,
    currentPassword,
    resetFields,
    onSuccess,
    enqueueSnackbar,
    catchAsSnackbar,
    mfaToken,
    t
  ]);

  const onCurrentPasswordChangeCallback = useCallback(event => {
    setCurrentPassword(event?.target?.value || "");
  }, []);

  const onMFATokenChangeCallback = useCallback(event => {
    setMFAToken(event?.target?.value || "");
  }, []);

  const onEnterReauthenticate = useCallback(
    event => {
      if (event.key === "Enter") {
        return reauthenticateCallback();
      }
    },
    [reauthenticateCallback]
  );

  const onToggleCurrentPasswordVisibilityCallback = useCallback(() => {
    setShowCurrentPassword(lastState => !lastState);
  }, []);

  const preventSubmitCallback = useCallback(event => {
    event.preventDefault();
  }, []);

  const passwordFieldInputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            tabIndex={-1}
            aria-label="toggle password visibility"
            onClick={onToggleCurrentPasswordVisibilityCallback}
            onMouseDown={preventSubmitCallback}
            edge="end"
          >
            {showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      )
    }),
    [preventSubmitCallback, showCurrentPassword, onToggleCurrentPasswordVisibilityCallback]
  );

  // content for doc view
  return (
    <div>
      <h2 className={classes.title}>{title}</h2>
      <div>
        <TextField
          id="currentPassword"
          tabIndex={0}
          placeholder={t("current_password")}
          variant="outlined"
          className={classes.input}
          type={showCurrentPassword ? "text" : "password"}
          value={currentPassword}
          onChange={onCurrentPasswordChangeCallback}
          onKeyDown={onEnterReauthenticate}
          InputProps={passwordFieldInputProps}
          label={t("current_password")}
        />
      </div>
      {enterMFARequired && (
        <div>
          <TextField
            tabIndex={1}
            fullWidth
            autoComplete="off"
            className={classes.input}
            label={t("authentication:code")}
            type="number"
            placeholder="123456"
            value={mfaToken}
            onChange={onMFATokenChangeCallback}
            variant="outlined"
            onKeyDown={onEnterReauthenticate}
          />
        </div>
      )}
      <div>
        <Button variant="contained" color="primary" disabled={loading} onClick={reauthenticateCallback}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
