import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { getTomApi, updateTomApi } from "../../../api/tomApi";
import { Box, Dialog, DialogContent } from "@mui/material";
import { CircularProgress, Typography } from "@material-ui/core";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import Button from "@material-ui/core/Button";
import { TomContent, TomModalData } from "./TomModal";
import useSWR from "swr";
import { isAxiosErrorWithCode } from "../../../api/axios/axiosErrorHandler";
import { DocumentNotFound } from "../../shared/DocumentNotFound/DocumentNotFound";

export interface UpdateTOMModalProps {
  readonly docOrgUnitIds?: string[];
  readonly tomId: string;
  readonly onUpdated: (id: string, modelData: TomModalData) => void;
  readonly onCancel: () => void;
  readonly open: boolean;
}

export const UpdateTOMModal = ({ open, onCancel, onUpdated, docOrgUnitIds, tomId }: UpdateTOMModalProps) => {
  const { t } = useTranslation("tom_description_tab");

  const { data, error } = useSWR(tomId ? [tomId] : null, () => getTomApi(tomId));
  const readonly = data?.permission !== "write";

  const [tomModalData, setTomModalData] = useState<TomModalData | null>(null);
  useEffect(() => {
    if (data) {
      setTomModalData({
        name: data.name,
        status: data.status,
        statusDate: data.statusDate,
        description: data.description,
        protectionObjectiveIds: data.protectionObjectiveIds,
        labelIds: data.labelIds
      });
    } else {
      setTomModalData(null);
      setDocumentNotFound(false);
    }
  }, [data]);

  const [documentNotFound, setDocumentNotFound] = useState(false);
  useEffect(() => {
    if (isAxiosErrorWithCode(error, 404) || isAxiosErrorWithCode(error, 403)) {
      setDocumentNotFound(true);
    } else {
      setDocumentNotFound(false);
    }
  }, [error]);

  const [saving, setSaving] = useState(false);
  const updateTOMCallback = useCallback(async () => {
    if (!tomModalData) {
      return;
    }

    setSaving(true);
    try {
      await updateTomApi(tomId, {
        name: tomModalData.name || undefined,
        status: tomModalData.status,
        statusDate: tomModalData.statusDate,
        description: tomModalData.description,
        protectionObjectiveIds: tomModalData.protectionObjectiveIds,
        labelIds: tomModalData.labelIds
      });
      onUpdated(tomId, tomModalData);
    } catch (e: unknown) {
      setSaving(false);
      throw e;
    }
    setSaving(false);
  }, [tomModalData, tomId, onUpdated]);

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
      {documentNotFound ? (
        <DialogContent>
          <DocumentNotFound onContinue={onCancel} />
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="h5" component="div">
            {t("dpia_four_four_page:edit_measure")}
          </Typography>
          <CustomAlert severity="warning">
            {readonly
              ? t("dpia_four_four_page:read_only")
              : data?.processSpecific
                ? t("dpia_four_four_page:alert_editing_process_specific_measure")
                : t("dpia_four_four_page:alert_editing_general_measure")}
          </CustomAlert>
          {tomModalData ? (
            <TomContent
              data={tomModalData}
              onDataChanged={setTomModalData}
              docOrgUnitIds={docOrgUnitIds}
              disabled={readonly}
            />
          ) : (
            <></>
          )}
          <Box display="flex" justifyContent="space-between">
            <Button disabled={saving} variant="outlined" color="primary" onClick={onCancel}>
              {t("dpia_four_four_page:cancel")}
            </Button>
            <Button disabled={saving || readonly} variant="contained" color="primary" onClick={updateTOMCallback}>
              {saving && (
                <Box mr={1}>
                  <CircularProgress color="inherit" size={14} />
                </Box>
              )}
              {t("dpia_four_four_page:save_measure_button")}
            </Button>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};
