import { apiEndpoints } from "./apiEndpoint";
import { GraphQLClient, RequestMiddleware } from "graphql-request";
import { getSdk, Sdk } from "./generated/graphql/hasura";
import {
  executeInSessionStorageLock,
  getHasuraTokenInSession,
  getLoginPreference,
  StoredTenantToken,
  storeHasuraTokenInSession
} from "../handlers/authentication/authenticationStorage";
import { hasuraLoginTokenApi } from "./user/userHasuraBridgeApi";
import { softParseJWTTokenExpiry } from "../handlers/authentication/jwtTokenSoftParse";

export interface HasuraClientProps {
  readonly endpoint?: string;
  readonly path?: string;
  readonly headers?: () => Record<string, string>;
  readonly requestMiddleware?: RequestMiddleware;
}

export type HasuraClientSDK = Sdk;

const createHasuraClient = (input: HasuraClientProps): HasuraClientSDK => {
  const endpoint = input.endpoint;
  const path = input.path;
  const _client = new GraphQLClient(`${endpoint}${path}`, {
    headers: input.headers,
    requestMiddleware: input.requestMiddleware
  });
  return getSdk(_client);
};

let _hasuraClientSDK: HasuraClientSDK | null = null;
export const getHasuraClientSDK = async (): Promise<HasuraClientSDK> => {
  if (!_hasuraClientSDK) {
    const tokenMiddleware = createRequestMiddlewareForRefreshingToken();
    _hasuraClientSDK = createHasuraClient({
      endpoint: apiEndpoints.hasuraUrl,
      path: "/v1/graphql",
      requestMiddleware: tokenMiddleware
    });
  }
  return _hasuraClientSDK;
};

const returnIfValidToken = async (input: Partial<StoredTenantToken>): Promise<StoredTenantToken | null> => {
  if (!input.token) {
    return null;
  }
  if (!input.expiresAt) {
    return null;
  }
  if (!input.tenantId) {
    return null;
  }

  const { tenantId } = await getLoginPreference();
  if (!tenantId) {
    return null;
  }

  const sameTenantId = tenantId === input.tenantId;
  if (!sameTenantId) {
    return null;
  }
  const gracePeriodOneMinute = 60 * 1000;
  const tokenExpireAt = new Date(input.expiresAt).getTime() - gracePeriodOneMinute;
  const tokenStillValid = tokenExpireAt > new Date().getTime();
  if (!tokenStillValid) {
    return null;
  }

  return {
    token: input.token,
    expiresAt: input.expiresAt,
    tenantId: input.tenantId
  };
};

const fetchHasuraToken = async (input: { readonly tenantId: string }): Promise<StoredTenantToken | null> => {
  const { hasuraToken } = await hasuraLoginTokenApi(input.tenantId);
  if (!hasuraToken) {
    throw new Error("Empty hasura token on refreshing hasura token");
  }
  const expireAt = softParseJWTTokenExpiry(hasuraToken);
  if (!expireAt) {
    throw new Error("Empty expiry on refreshing hasura token");
  }
  return {
    token: hasuraToken,
    expiresAt: expireAt.toISOString(),
    tenantId: input.tenantId
  };
};

const createRequestMiddlewareForRefreshingToken: () => RequestMiddleware = () => {
  let memoryCachedHasuraToken: StoredTenantToken | null = null;

  const fetchTokenFromSessionOrRemote = (): Promise<StoredTenantToken> => {
    return executeInSessionStorageLock("hasuraTokenRefreshLock", async () => {
      const sessionStorageHasuraToken = await getHasuraTokenInSession();
      const validSessionStorageToken = await returnIfValidToken({
        token: sessionStorageHasuraToken?.token,
        expiresAt: sessionStorageHasuraToken?.expiresAt,
        tenantId: sessionStorageHasuraToken?.tenantId
      });
      if (validSessionStorageToken) {
        return validSessionStorageToken;
      }

      const { tenantId } = await getLoginPreference();
      if (!tenantId) {
        throw new Error("Tenant id is not found when refreshing hasura token");
      }

      const fetchedHasuraToken = await fetchHasuraToken({ tenantId });
      if (!fetchedHasuraToken) {
        throw new Error("Empty hasura token on refreshing hasura token");
      }
      await storeHasuraTokenInSession(fetchedHasuraToken);
      return fetchedHasuraToken;
    });
  };

  return async request => {
    let validSessionStorageToken = await returnIfValidToken(memoryCachedHasuraToken || {});
    if (!validSessionStorageToken) {
      validSessionStorageToken = await fetchTokenFromSessionOrRemote();
      memoryCachedHasuraToken = validSessionStorageToken;
    }

    return {
      ...request,
      headers: { ...request.headers, Authorization: `Bearer ${validSessionStorageToken.token}` }
    };
  };
};
