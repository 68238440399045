import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import { RiskPageStepper } from "./RiskPagination";
import { useRisk } from "../../contexts/risk-context";
import { COLLECTIONS } from "../../collections";
import RiskAssetsOverview from "app/pages/service-providers/overview/assets/RiskAssetsOverview";

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const RiskAssetsOverviewPage = () => {
  const { t } = useTranslation("risk_second_assessment_page");
  const { risk } = useRisk();
  const { title: riskTitle, id } = risk;
  const { setInfo } = useMetaView();

  const infoCardEntering = useMemo(
    () => ({
      title: t("risk_assets_page:enteringInfoCardTitle"),
      text: t("risk_assets_page:enteringInfoCardText")
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCardEntering);
  }, [setInfo, infoCardEntering]);

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          translationKey={"risk_asset_overview_page"}
          docName={riskTitle}
          docId={id}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={riskTitle} pagination={<RiskPageStepper />}>
        <RiskAssetsOverview riskId={risk.id} />
      </DocView>
    </DocMetaView>
  );
};
