import React, { useCallback, useEffect, useMemo } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "../../../contexts/meta-view-context";
import DateDisplay from "../../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../../components/UserDataDisplay";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { exportDataBreachesExcel } from "app/export/createdExcelExportData";
import { dataBreachWritePermissions } from "../../../handlers/permissionHandler";

const toolbarActions = [{ action: "sort" }, { action: "filter" }, { action: "export-xlsx" }];
const addActions = [{ action: OVERVIEW_ADD_TYPE.SINGLE }, { action: OVERVIEW_ADD_TYPE.MULTIPLE }];

const DataBreachesOverview = () => {
  const { t } = useTranslation();
  const { setInfo, setMeta } = useMetaView();
  const { auth } = useAuthentication();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("data_breaches_overview:enteringInfoCardTitle"),
        text: t("data_breaches_overview:enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);
  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.creatorUID} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updaterUID ? <AutomaticUserDataDisplay uid={item.updaterUID} separator={undefined} /> : "-",
        status: t("data_breaches_general_page:status_" + item.status.toUpperCase())
      });
    },
    [setMeta, t]
  );

  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }], []);

  const exportToXLSX = useCallback(
    async ids => {
      if (auth?.tenantId) {
        return await exportDataBreachesExcel(auth?.tenantId, auth?.uid, t, ids);
      }
    },
    [auth?.tenantId, auth?.uid, t]
  );

  const collectionParams = useMemo(
    () => ({
      exportToXLSX
    }),
    [exportToXLSX]
  );

  const selectionActions = useMemo(() => [{ action: "export-xlsx" }, { action: "remove" }], []);

  const showAddActions = auth?.permissions.find(permission => dataBreachWritePermissions.includes(permission));

  const docViewContent = (
    <Overview
      onAddClose={showEnterInfo}
      onRowOver={onRowOver}
      onRowLeave={showEnterInfo}
      toolbarActions={toolbarActions}
      header={t("data_breaches_overview:header")}
      collection={COLLECTIONS.DATA_BREACHES}
      rowActions={rowActions}
      selectionActions={selectionActions}
      addActions={showAddActions ? addActions : undefined}
      checkable={true}
      collectionParams={collectionParams}
      toolbarMode={"tabs"}
    />
  );
  return (
    <DocMetaView
      docViewContent={docViewContent}
      metaViewContent={<MetaView translationKey={"data_breaches_overview"} collection={COLLECTIONS.DATA_BREACHES} />}
    />
  );
};

export default React.memo(DataBreachesOverview);
