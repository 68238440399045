import { Box, Card, CardHeader, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import { createPAProcessorApi, useGetPAProcessorsApi } from "../../../api/processorPAApi";
import { useTranslation } from "react-i18next";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useUserDepartments } from "../../../contexts/department-context";
import { noUppercaseOpenSansButton } from "../../../utils/standardStyles";
import AddIcon from "@material-ui/icons/Add";
import { getProcessPageData } from "../../../api/paApi";
import Collapse from "@material-ui/core/Collapse";
import { useProcessPage } from "../../../contexts/process-page-context";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { ProcessGeneralPageModel } from "../../questionnaires/general-page/ProcessGeneralPage";

export const ProcessorPAsOfPA = ({ paId, readonly }: { readonly paId: string; readonly readonly: boolean }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPAProcessorsApi({ paId });

  const [enteredIds, setEnteredIds] = useState<Set<string> | undefined>();
  useEffect(() => {
    if (isLoading) {
      return;
    }

    setEnteredIds(lastValue => {
      const currentSet = lastValue || new Set();
      for (const processorPA of data?.processPage?.processorPAs || []) {
        currentSet.add(processorPA.id);
      }
      return currentSet;
    });
  }, [isLoading, data?.processPage?.processorPAs]);

  const { getDepartmentWithParentName, rootDepartment } = useUserDepartments();
  const processorPAsItems = useMemo(() => {
    return (data?.processPage?.processorPAs || [])
      .map(processorPA => {
        const departmentWithParentName = getDepartmentWithParentName(
          processorPA.processorOrgUnitId || rootDepartment?.id || ""
        );
        return {
          id: processorPA.id,
          name: processorPA.name || "-",
          procesorOrgUnitId: processorPA.processorOrgUnitId || rootDepartment?.id || "",
          processorOrgUnitName: departmentWithParentName,
          sortName: `${departmentWithParentName} | ${processorPA.name}`
        };
      })
      .sort((a, b) => a.sortName.localeCompare(b.sortName));
  }, [data?.processPage?.processorPAs, getDepartmentWithParentName, rootDepartment?.id]);

  return (
    <Box>
      <QuestionnaireSubHeader text={t("processor_pa_page:title")} />
      <Box mt={2}>
        {processorPAsItems.map(proPa => (
          <Collapse key={proPa.id} in={enteredIds?.has(proPa.id) || false}>
            <Box mb={1}>
              <ProcessorPAAccordion id={proPa.id} name={proPa.name} orgUnitName={proPa.processorOrgUnitName} />
            </Box>
          </Collapse>
        ))}
      </Box>
      <Box pt={1}>
        <AddProcessorPA paId={paId} readonly={readonly} />
      </Box>
    </Box>
  );
};

const ProcessorPAAccordion = ({
  id,
  name,
  orgUnitName
}: {
  readonly id: string;
  readonly name: string;
  readonly orgUnitName: string;
}) => {
  const { t } = useTranslation();
  const onOpenClick = useCallback(() => {
    window.open(`/processor-pas/${id}/general`, "_blank");
  }, [id]);
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box flex={1} display="flex" alignItems="center">
                <Box>
                  <Typography style={orgUnitNameStyle}>{orgUnitName}</Typography>
                </Box>
                <Box ml="44px">
                  <Typography style={nameStyle}>{name}</Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={onOpenClick}
                  style={editInNewTabStyle}
                  endIcon={<OpenInNewIcon />}
                >
                  {t("common:edit")}
                </Button>
              </Box>
            </Box>
          }
        />
      </Card>
    </Box>
  );
};

const orgUnitNameStyle: CSSProperties = {
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "24px"
};

const nameStyle: CSSProperties = {
  color: "text.secondary",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "24px"
};

const editInNewTabStyle: CSSProperties = {
  ...noUppercaseOpenSansButton,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "24px"
};

const AddProcessorPA = ({ paId, readonly }: { readonly paId: string; readonly readonly: boolean }) => {
  const { t } = useTranslation();
  const { data, mutate } = useGetPAProcessorsApi({ paId });
  const { auth } = useAuthentication();
  const userHasProcessorCreatePermission = auth?.permissions.includes("processor_pa_write_org");

  const { onBeforeProcessUpdate } = useProcessPage();

  const [isCreating, setIsCreating] = useState(false);
  const onCreateProcessorPA = useCallback(async () => {
    await onBeforeProcessUpdate(async () => {
      setIsCreating(true);
      try {
        await mutate(async oldData => {
          const generalPage = await getProcessPageData<ProcessGeneralPageModel>({
            processId: paId || "",
            page: "general"
          });
          const createdProcessorPAId = await createPAProcessorApi({
            payload: {
              paIds: [paId],
              name: data?.processMeta.title || "",
              processorOrgUnitId: generalPage?.processPage?.responsibleOrgUnitId || "",
              privacyExpertUserId: generalPage?.processPage?.responsibleExpertUserIds[0] || "",
              dpoUserId: generalPage?.processPage?.dpoUID || ""
            }
          });
          window.open(`/processor-pas/${createdProcessorPAId}/general`, "_blank");
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            processPage: {
              ...oldData?.processPage,
              processorPAs: [
                ...(oldData?.processPage?.processorPAs || []),
                {
                  id: createdProcessorPAId,
                  name: data?.processMeta.title || ""
                } as any
              ]
            }
          };
        });
      } catch (e) {
        setIsCreating(false);
        throw e;
      }
      setIsCreating(false);
    });
  }, [onBeforeProcessUpdate, mutate, paId, data?.processMeta.title]);

  return (
    <Tooltip title={!userHasProcessorCreatePermission ? t("common:noPermission") : ""}>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCreateProcessorPA}
          style={noUppercaseOpenSansButton}
          startIcon={<AddIcon />}
          disabled={readonly || isCreating || !userHasProcessorCreatePermission}
          endIcon={isCreating ? <CircularProgress size={14} /> : undefined}
        >
          {t("processor_pa_page:add_linked")}
        </Button>
      </Box>
    </Tooltip>
  );
};
