import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StaticDocView from "../../../components/StaticDocView/StaticDocView";
import StaticDocViewHeader from "../../../components/StaticDocViewHeader/StaticDocViewHeader";
import StaticDocViewTextContent from "../../../components/StaticDocViewTextContent/StaticDocViewTextContent";
import StaticDocViewSubmitButton from "../../../components/StaticDocViewSubmitButton/StaticDocViewSubmitButton";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import React, { useCallback } from "react";

export const QuestionnaireStaticPages = ({ status }: { readonly status: string }) => {
  const { t } = useTranslation("pagination");
  const navigate = useNavigate();

  const toProcessOverview = useCallback(() => {
    navigate("/processes");
  }, [navigate]);

  const staticReview = (
    <StaticDocView icon={"CheckCircleOutline"} type={"success"}>
      <StaticDocViewHeader text={t("questionnaires:inReviewInfoHeader")} />
      <StaticDocViewTextContent text={t("questionnaires:inReviewInfoText")} />
      <StaticDocViewSubmitButton text={t("questionnaires:inReviewAcknowledgeButtonText")} onClick={toProcessOverview} />
    </StaticDocView>
  );

  const staticApproved = (
    <StaticDocView icon={"CheckCircleOutline"} type={"success"}>
      <StaticDocViewHeader text={t("questionnaires:approvedInfoHeader")} />
      <StaticDocViewTextContent text={t("questionnaires:approvedInfoText")} />
      <StaticDocViewSubmitButton text={t("questionnaires:approvedAcknowledgeButtonText")} onClick={toProcessOverview} />
    </StaticDocView>
  );

  const staticDPIAApproved = (
    <StaticDocView icon={"CheckCircleOutline"} type={"success"}>
      <StaticDocViewHeader text={t("questionnaires:approvedInfoHeader")} />
      <StaticDocViewTextContent text={t("questionnaires:DPIAApprovedInfoText")} />
      <StaticDocViewSubmitButton text={t("questionnaires:approvedAcknowledgeButtonText")} onClick={toProcessOverview} />
    </StaticDocView>
  );

  const staticError = (
    <StaticDocView icon={"CheckCircleOutline"} type={"success"}>
      <StaticDocViewHeader text={t("questionnaires:approvedInfoHeader")} />
      <StaticDocViewTextContent text={t("questionnaires:approvedInfoText")} />
      <StaticDocViewSubmitButton text={t("questionnaires:approvedAcknowledgeButtonText")} onClick={toProcessOverview} />
    </StaticDocView>
  );

  const staticDocViewContent =
    status === "review"
      ? staticReview
      : status === "approved"
        ? staticApproved
        : status === "DPIAApproved"
          ? staticDPIAApproved
          : staticError;
  return <DocMetaView metaViewContent={<MetaView />}>{staticDocViewContent}</DocMetaView>;
};
