import React, { Dispatch, SetStateAction, useCallback } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { alpha, withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { appendParentNodeForMove } from "./overview/DocumentOverviewHelper";
import { OverviewItem, OverviewNewItem } from "components/Overview/controllers/overviewBaseController";

const StyledTreeItemWithCheckbox = withStyles(theme => ({
  group: {
    marginLeft: -22,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.15)}`
  }
}))(TreeItem);

function TreeNodeLabel({ currentNode }: { readonly currentNode: OverviewNewItem }) {
  return <span>{currentNode.title}</span>;
}

function TreeNode({
  currentNode,
  destinationItem,
  disabled,
  itemToMove,
  setDestinationItem
}: {
  readonly currentNode: OverviewNewItem;
  readonly destinationItem: OverviewNewItem | null;
  readonly disabled?: boolean;
  readonly itemToMove: OverviewNewItem;
  readonly setDestinationItem: Dispatch<SetStateAction<OverviewNewItem | null>>;
}) {
  const children = currentNode.children;

  const handleCheckboxChange = useCallback(() => {
    if (destinationItem && destinationItem.path === currentNode.path) {
      setDestinationItem(null);
    } else {
      setDestinationItem(currentNode);
    }
  }, [currentNode, destinationItem, setDestinationItem]);

  const isCheckboxDisabled = destinationItem !== null && currentNode.path !== destinationItem.path;
  const parentNodeOfItemToMove = itemToMove.path.replace(`/${itemToMove.title}`, "");
  const disableParentNode = currentNode.path === parentNodeOfItemToMove;

  return currentNode.fileType || currentNode.path.startsWith(itemToMove.path) ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Box>
        <Checkbox
          color="primary"
          disabled={isCheckboxDisabled || disabled || disableParentNode}
          onChange={handleCheckboxChange}
        />
      </Box>
      <Box pt={1.125}>
        <StyledTreeItemWithCheckbox nodeId={currentNode.path} label={<TreeNodeLabel currentNode={currentNode} />}>
          {children?.map((child: OverviewNewItem) => (
            <TreeNode
              currentNode={child}
              destinationItem={destinationItem}
              itemToMove={itemToMove}
              key={child.path}
              setDestinationItem={setDestinationItem}
            />
          ))}
        </StyledTreeItemWithCheckbox>
      </Box>
    </Box>
  );
}

export default function TreeViewDocuments({
  destinationItem,
  itemToMove,
  items,
  rootFolderTitle,
  setDestinationItem
}: {
  readonly destinationItem: OverviewNewItem | null;
  readonly itemToMove: OverviewNewItem;
  readonly items: OverviewItem[];
  readonly rootFolderTitle: string;
  readonly setDestinationItem: Dispatch<SetStateAction<OverviewNewItem | null>>;
}) {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={["moveToRoot"]}
    >
      {appendParentNodeForMove(items, rootFolderTitle).map((currentNode: OverviewNewItem, i: number) => (
        <TreeNode
          currentNode={currentNode}
          destinationItem={destinationItem}
          disabled={i === 0 && !itemToMove.path.includes("/")}
          itemToMove={itemToMove}
          key={currentNode.path}
          setDestinationItem={setDestinationItem}
        />
      ))}
    </TreeView>
  );
}
