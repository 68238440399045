import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { debounce } from "lodash-es";
import { useSnackbar } from "notistack";
import { useMetaView } from "../../contexts/meta-view-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { EmailNotificationConfig } from "app/handlers/userAndTenant/userEmailNotificationHandler";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";

export default function AccountSettingsNotifications() {
  const { t } = useTranslation("accountSettingsNotification");
  const { auth } = useAuthentication();
  const { getEmailNotificationConfigHook, updateEmailNotificationConfigHook } = useUserAndTenantData();
  const isPublicAssessmentActivated = useIsFeaturePresent("publicAssessment");

  const [pageLoaded, setPageLoaded] = useState(false);
  const [notificationConfig, setNotificationConfig] = useState<EmailNotificationConfig | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { catchAsSnackbar } = useErrorSnackbar();

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo({
      title: t("accountSettingsNotification:infoCardEnterTitle"),
      text: t("accountSettingsNotification:infoCardEnterBody")
    });
  }, [setInfo, t]);

  const loadUserNotificationConfig = useCallback(async () => {
    const fetchedNotificationConfig = await getEmailNotificationConfigHook();
    setNotificationConfig(fetchedNotificationConfig);
  }, [getEmailNotificationConfigHook]);

  const updateEmailNotificationConfigDebounced = useMemo(
    () =>
      debounce(
        notificationConfig =>
          updateEmailNotificationConfigHook(notificationConfig)
            .then(() => enqueueSnackbar(t("success"), { variant: "success" }))
            .catch(catchAsSnackbar("failed to update notification")),
        500
      ),
    [catchAsSnackbar, enqueueSnackbar, t, updateEmailNotificationConfigHook]
  );

  useEffect(() => {
    loadUserNotificationConfig().then(() => setPageLoaded(true));
  }, [auth?.tenantId, loadUserNotificationConfig]);

  const modifiableNotificationConfigKeys = useMemo(() => {
    return [
      "onMention",
      "onReplyToComment",
      "onTaskAssignment",
      "onProcessAssignment",
      "onProcessApproved",
      "requestAllowEdit",
      "expiredDueDate",
      "onDSRSubmission",
      "onAuditAssignment"
    ].filter(it => {
      if (it === "onAuditAssignment") {
        return isPublicAssessmentActivated;
      }
      return true;
    });
  }, [isPublicAssessmentActivated]);

  const handleRadioChange = useCallback(
    event => {
      if (!notificationConfig) {
        return;
      }
      const eventValue = event.target.value;
      const newConfig = { ...notificationConfig, receiveNotification: eventValue === "yes" ? true : false };
      setNotificationConfig(newConfig);
      updateEmailNotificationConfigDebounced(newConfig);
    },
    [notificationConfig, updateEmailNotificationConfigDebounced]
  );

  const handleConfigChange = useCallback(
    event => {
      if (!notificationConfig) {
        return;
      }
      const newConfig = { ...notificationConfig, [event.target.name]: event.target.checked };
      setNotificationConfig(newConfig);
      updateEmailNotificationConfigDebounced(newConfig);
    },
    [notificationConfig, updateEmailNotificationConfigDebounced]
  );

  if (!pageLoaded) {
    return <></>;
  }

  return (
    <Box>
      <Typography variant="h2">{t("email_notification")}</Typography>
      <Box mt={4} />
      <Typography variant="subtitle1" component="div">
        {t("receiveEmailNotifications")}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="notification"
          name="notificationSettings"
          value={notificationConfig?.receiveNotification ? "yes" : "no"}
          onChange={handleRadioChange}
          row
        >
          <FormControlLabel value="yes" control={<Radio color="primary" />} label={t("yes")} />
          <FormControlLabel value="no" control={<Radio color="primary" />} label={t("no")} />
        </RadioGroup>
      </FormControl>
      <Box mt={2} />
      {notificationConfig && notificationConfig?.receiveNotification && (
        <Box>
          <Typography variant="subtitle1" component="div">
            {t("whichEventsNotifications")}
          </Typography>
          <FormGroup>
            {modifiableNotificationConfigKeys.map(key => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    color="primary"
                    name={key}
                    checked={!!notificationConfig[key as keyof EmailNotificationConfig]}
                    onChange={handleConfigChange}
                  />
                }
                label={t(key)}
              />
            ))}
          </FormGroup>
        </Box>
      )}
    </Box>
  );
}
