import React, { useCallback } from "react";

export const FileReaderComponent = ({
  id,
  accept,
  onFileRead,
  onReadFinished
}: {
  readonly id: string;
  readonly accept: string;
  readonly onFileRead: (readFile: string) => Promise<void>;
  readonly onReadFinished?: () => void;
}) => {
  const processFile = useCallback(async (file: File) => {
    if (!file) {
      throw new Error("file is empty");
    }
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      // triggers on reading
      fileReader.onloadend = () => {
        if (typeof fileReader.result !== "string") {
          reject("No fileReader result!");
          return;
        }
        resolve(fileReader.result);
      };
      fileReader.readAsText(file);
    });
  }, []);

  const onUpload = useCallback(
    async e => {
      const files = [...e.target.files];
      const filesData = await Promise.all(files.map(processFile));
      for (const fileData of filesData) {
        await onFileRead(fileData);
      }
      if (onReadFinished) onReadFinished();
      e.target.value = ""; // resetting the value so <input onChange is triggered when uploading the same file twice
    },
    [onFileRead, onReadFinished, processFile]
  );

  return (
    <div hidden={true}>
      <input id={id} type="file" accept={accept} onChange={onUpload} multiple={true} />
    </div>
  );
};
