import React, { useCallback, useEffect, useMemo } from "react";
import { ProcessesOverviewReadOnly } from "../../../processes/overview/ProcessesOverviewReadOnly";
import { useMetaView } from "../../../../contexts/meta-view-context";
import { useTranslation } from "react-i18next";
import DateDisplay from "../../../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../../../components/UserDataDisplay";

const ExternalRecipientProcessOverview = ({ externalRecipientId }: { readonly externalRecipientId: string }) => {
  const { t } = useTranslation("processes_overview");
  const filter = useMemo(() => ({ externalRecipientIDs: [externalRecipientId] }), [externalRecipientId]);

  const { setInfo, setMeta } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("processes_tab:enteringInfoCardTitle"),
        text: t("processes_tab:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const onRowOver = useCallback(
    item => {
      setMeta({
        [t("version")]: item.version,
        [t("created")]: (
          <DateDisplay timestamp={item.createdAt ? new Date(item.createdAt) : null} displaySeconds={undefined} />
        ),
        [t("createdBy")]: <AutomaticUserDataDisplay uid={item.creatorUID} separator={undefined} />,
        [t("department")]: item.subTitle,
        [t("status")]: t(`filter_criteria:status_${item.status}`),
        [t("dueDate")]: <DateDisplay timestamp={item.dueAt ? new Date(item.dueAt) : null} displaySeconds={undefined} />
      });
    },
    [setMeta, t]
  );

  if (!externalRecipientId) {
    return <></>;
  }

  return <ProcessesOverviewReadOnly filter={filter} onRowOver={onRowOver} onRowLeave={showEnterInfo} />;
};

export default ExternalRecipientProcessOverview;
