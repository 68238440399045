import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { RISK_LEVEL, riskRatingToLevel } from "../../../handlers/risksHandler";
import { CircularProgress, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const useRiskRatingAlertStyles = makeStyles(theme => ({
  assessmentBox: {
    height: 56,
    borderRadius: 6,
    minWidth: 200
  },
  ratingWidth: {
    width: 146
  },
  explanationWidth: {},
  notApplicable: {
    color: theme.palette.alert.info,
    backgroundColor: theme.palette.alert.infoBackground,
    borderColor: theme.palette.alert.infoBackground
  },
  medium: {
    color: theme.palette.alert.warning,
    backgroundColor: theme.palette.alert.warningBackground,
    borderColor: theme.palette.alert.warningBackground
  },
  low: {
    color: theme.palette.alert.success,
    backgroundColor: theme.palette.alert.successBackground,
    borderColor: theme.palette.alert.successBackground
  },
  high: {
    color: theme.palette.alert.error,
    backgroundColor: theme.palette.alert.errorBackground,
    borderColor: theme.palette.alert.errorBackground
  },
  veryHigh: {
    color: theme.palette.alert.error,
    backgroundColor: theme.palette.alert.errorBackground,
    borderColor: theme.palette.alert.errorBackground
  },
  alertText: {
    padding: 10
  }
}));

export const RiskRatingAlert = ({ riskRating, riskRatingIsLoading }) => {
  const { t } = useTranslation();
  const classes = useRiskRatingAlertStyles();
  const riskRatingLevel = riskRatingToLevel(riskRating);
  const showExplanationText = riskRatingLevel === RISK_LEVEL.notApplicable;
  const className = [
    classes.assessmentBox,
    classes[riskRatingLevel],
    showExplanationText ? classes.explanationWidth : classes.ratingWidth
  ].join(" ");

  return (
    <Grid className={className} container justifyContent="center" alignItems="center">
      {riskRatingIsLoading && <CircularProgress />}
      {!riskRatingIsLoading && !showExplanationText && <Grid item>{t(`risk_level:${riskRatingLevel}`)}</Grid>}
      {!riskRatingIsLoading && showExplanationText && (
        <Grid item className={classes.alertText}>
          {t(`risk_level_tooltip:notApplicableFurtherExplanation`)}
        </Grid>
      )}
    </Grid>
  );
};

RiskRatingAlert.propTypes = {
  riskRating: PropTypes.number,
  riskRatingIsLoading: PropTypes.bool
};

RiskRatingAlert.defaultProps = {
  riskRatingIsLoading: false
};
