import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAITechDocApi } from "../../api/aiTechDocApi";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import { DocumentNotFound } from "../shared/DocumentNotFound/DocumentNotFound";
import { AITechDocPageStepper, useAITechDocPagination } from "./AITechDocPagination";
import { AITechDocGeneral } from "./pages/AITechDocGeneral";
import { AITechDocTechnicalDetail } from "./pages/AITechDocTechnicalDetail";
import { AITechDocTrainingData } from "./pages/AITechDocTrainingData";
import { PageStep } from "../../../components/Pagination/GeneralPageStepper";
import { OpenInNew } from "@mui/icons-material";

export const AITechDocModal = ({
  id,
  open,
  onClose,
  onConfirm
}: {
  readonly id: string;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onConfirm: (id: string, name: string) => void;
}) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState("general");
  const { data, error, isLoading } = useGetAITechDocApi({ documentId: id || "" });

  const [title, setTitle] = useState<string>("");
  useEffect(() => {
    setTitle(data?.name || "");
  }, [data?.name]);

  const [documentNotFound, setDocumentNotFound] = useState(false);
  useEffect(() => {
    if (isAxiosErrorWithCode(error, 404) || isAxiosErrorWithCode(error, 403)) {
      setDocumentNotFound(true);
    } else {
      setDocumentNotFound(false);
    }
  }, [error]);

  const onConfirmCallback = useCallback(() => {
    onConfirm(id, title);
  }, [id, title, onConfirm]);

  const { steps } = useAITechDocPagination();
  const nextStep = useMemo(() => {
    const currentIndex = steps.findIndex(step => step.path === page);
    return steps[currentIndex + 1] || null;
  }, [steps, page]);
  const previousStep = useMemo(() => {
    const currentIndex = steps.findIndex(step => step.path === page);
    return steps[currentIndex - 1] || null;
  }, [steps, page]);
  const toNextPage = useCallback(() => {
    if (nextStep) {
      setPage(nextStep.path);
    }
  }, [nextStep]);
  const toPreviousPage = useCallback(() => {
    if (previousStep) {
      setPage(previousStep.path);
    }
  }, [previousStep]);

  const onStepChange = useCallback((step: PageStep) => {
    setPage(step.path);
  }, []);

  const onOpenInNewClicked = useCallback(() => {
    window.open(`/ai-tech-docs/${id}/general`);
  }, [id]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      {documentNotFound ? (
        <DialogContent>
          <DocumentNotFound onContinue={onClose} />
        </DialogContent>
      ) : isLoading ? (
        <>
          <Box display="flex" height={1000} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <DialogTitle>
            <Box display="flex" alignItems="center" gap={0.5}>
              {title}
              <IconButton size="small">
                <OpenInNew onClick={onOpenInNewClicked} />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <AITechDocPageStepper onStepChange={onStepChange} skipNavigate={true} currentPath={page} />
            {page === "general" && <AITechDocGeneral readonly={data?.permission !== "write"} id={id || ""} />}
            {page === "technical-detail" && (
              <AITechDocTechnicalDetail readonly={data?.permission !== "write"} id={id || ""} />
            )}
            {page === "training-data" && (
              <AITechDocTrainingData readonly={data?.permission !== "write"} id={id || ""} />
            )}
          </DialogContent>
          <DialogActions>
            {previousStep ? (
              <Button onClick={toPreviousPage} variant="outlined">
                {t("back")}
              </Button>
            ) : (
              <Button onClick={onClose} variant="outlined">
                {t("cancel")}
              </Button>
            )}
            {nextStep ? (
              <Button onClick={toNextPage} variant="contained">
                {t("next")}
              </Button>
            ) : (
              <Button onClick={onConfirmCallback} variant="contained">
                {t("confirm")}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
