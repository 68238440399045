import { useMetaView } from "app/contexts/meta-view-context";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useEnteringInfoCard(input: { readonly pathName: string; readonly infoId: string }) {
  const location = useLocation();
  const { setInfoId } = useMetaView();

  const { pathName, infoId } = input;

  useEffect(() => {
    if (location.pathname === pathName) {
      setInfoId(infoId);
    }
  }, [infoId, location, pathName, setInfoId]);
}
