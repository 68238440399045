import { Autocomplete, Box, FormControl, Stack, TextField, Typography, createFilterOptions } from "@mui/material";
import { useSupportedLanguages, useTranslatedKeysInLng } from "app/api/multilingualApi";
import { useTLng } from "components/Multilingual/MultilingualModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PersonGroupsMergeTranslations(props: {
  translationKeys: string[];
  originalNames: string[];
  onChange: (translations: Record<string, string>) => void;
}) {
  const [state, setState] = useState({ translations: {} as Record<string, string> });
  const { onChange } = props;
  useEffect(() => {
    onChange(state.translations);
  }, [onChange, state.translations]);
  const supportedLanguages = useSupportedLanguages();
  useEffect(() => {
    if (supportedLanguages.data?.mainLanguage) {
      setState(state => ({
        ...state,
        translations: {
          [supportedLanguages.data?.mainLanguage ?? ""]: props.originalNames[0] ?? ""
        }
      }));
    }
  }, [supportedLanguages.data?.mainLanguage, props.originalNames]);
  const { translationKeys } = props;
  const handleTranslationChange = (language: string) => (value: string) => {
    setState(state => ({
      ...state,
      translations: {
        ...state.translations,
        [language]: value
      }
    }));
  };
  return (
    <Stack direction="column" spacing={2}>
      {supportedLanguages.data?.mainLanguage && (
        <TranslationPicker
          key={supportedLanguages.data.mainLanguage}
          lng={supportedLanguages.data.mainLanguage}
          tanslationKeys={translationKeys}
          originalNames={props.originalNames}
          isMainLanguage
          value={state.translations[supportedLanguages.data.mainLanguage] || ""}
          onChange={handleTranslationChange(supportedLanguages.data.mainLanguage)}
        />
      )}
      {supportedLanguages.data?.supportedLanguages?.map(language => (
        <TranslationPicker
          key={language}
          lng={language}
          tanslationKeys={translationKeys}
          originalNames={props.originalNames}
          value={state.translations[language] || ""}
          onChange={handleTranslationChange(language)}
        />
      ))}
    </Stack>
  );
}

const filter = createFilterOptions<string>();

function TranslationPicker(props: {
  lng: string;
  value: string;
  tanslationKeys: string[];
  originalNames: string[];
  isMainLanguage?: boolean;
  onChange: (value: string) => void;
}) {
  const { onChange } = props;
  const [t] = useTranslation();
  const translations = useTranslatedKeysInLng({
    languageCode: props.lng,
    translationKeys: props.tanslationKeys
  });
  const handleTextChange = useCallback(
    (event: React.SyntheticEvent, value: string | null) => {
      onChange(value ?? "");
    },
    [onChange]
  );
  const inputLabelId = `${props.lng}-translation-picker-label`;
  const tLng = useTLng();
  const uniqTranslationOptions = useMemo(() => {
    const options = new Set<string>();
    props.originalNames.forEach(name => options.add(name));
    translations.data?.forEach(translation => {
      if (translation instanceof Error || !translation) return;
      options.add(translation.value);
    });
    return Array.from(options);
  }, [props.originalNames, translations.data]);
  const renderAutocompleteInput = useCallback(
    (params: any) => <TextField {...params} label={t("multilingual:translation")} />,
    [t]
  );
  const handleFilterOptions = useCallback((options: string[], params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(option => inputValue === option);
    if (inputValue !== "" && !isExisting) {
      filtered.push(inputValue);
    }

    return filtered;
  }, []);
  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Typography>
          {tLng(props.lng)}
          {props.isMainLanguage ? ` (${t("common:default")})` : ""}
        </Typography>
        <FormControl fullWidth>
          <Autocomplete
            id={inputLabelId}
            options={uniqTranslationOptions}
            freeSolo
            renderInput={renderAutocompleteInput}
            value={props.value}
            onChange={handleTextChange}
            filterOptions={handleFilterOptions}
          />
        </FormControl>
      </Stack>
    </Box>
  );
}
