import { useCallback, useMemo } from "react";
import ListViewItem from "components/ListViewItem/ListViewItem";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useOverviewState } from "app/contexts/overview-context";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";

export default function GroupOverviewRow({
  checkable,
  checked,
  item,
  onChecked,
  onDelete
}: CustomRowComponentProps<OverviewItem>) {
  const navigate = useNavigate();
  const overviewSetup = useOverviewState()[COLLECTIONS.GROUP];

  const deleteItem = useCallback(async () => {
    await onDelete(item.id);
  }, [item.id, onDelete]);

  const deleteDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "remove"),
    [item.disableActions]
  );
  const handleClick = useCallback(() => {
    navigate(`/groups/${item.id}`);
  }, [item.id, navigate]);

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: "pointer"
      }}
    >
      <ListViewItem
        deletable={!deleteDisabled}
        onDelete={deleteItem}
        onSelect={checkable ? onChecked : undefined}
        searchText={overviewSetup.search}
        selected={checked}
        subTitle={item.subTitle}
        title={item.title}
      />
    </Box>
  );
}
