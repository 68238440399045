// function taken from https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-using-html-canvas
// adjusted to take path directly as parameter instead of context
/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {path} path
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = false] Whether to stroke the rectangle.
 */
export function rectangleWithRoundedCorners(path, x, y, width, height, radius = 8, fill, stroke = false) {
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    let defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (let side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  path.moveTo(x + radius.tl, y);
  path.lineTo(x + width - radius.tr, y);
  path.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  path.lineTo(x + width, y + height - radius.br);
  path.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  path.lineTo(x + radius.bl, y + height);
  path.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  path.lineTo(x, y + radius.tl);
  path.quadraticCurveTo(x, y, x + radius.tl, y);
  if (fill) {
    path.fill();
  }
  if (stroke) {
    path.stroke();
  }
}
