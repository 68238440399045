import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { createTomApi, TomModelDTO } from "../../../../app/api/tomApi";
import { Accordion, AccordionSummary, Box, Button, Chip, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MultiPickerDialog } from "../../../Measures/MultiPickerDialog";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextEditor from "../../../../app/pages/questionnaires/utils/TextEditor";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UpdateTOMModal } from "../../../../app/pages/toms/modal/UpdateTOMModal";
import { CreateTOMModal } from "../../../../app/pages/toms/modal/CreateTOMModal";
import { checkIfTextEditorContainsText } from "../../../../app/pages/questionnaires/utils/textEditorConverter";
import { useSnackbar } from "notistack";
import { TomModalData } from "../../../../app/pages/toms/modal/TomModal";
import { useAuthentication } from "../../../../app/handlers/authentication/authentication-context";
import LogoutIcon from "@mui/icons-material/Logout";

const MeasuresQuestion = ({
  value,
  suggestionValueIds,
  disabled = false,
  onFocus,
  onBlur,
  onChange,
  options,
  orgUnitIds,
  allowAdd,
  nonStandardAdditionalData,
  onSuggestionsConfirm
}: QuestionProps) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string[]>([]);

  useEffect(() => {
    const initialValue: string[] = (Array.isArray(value) ? value : [value]) as string[];
    return setCurrentValue(initialValue);
  }, [value]);

  const onChangeCallback = useCallback(
    ids => {
      onChange?.(ids);
    },
    [onChange]
  );

  const protectionObjectiveIds = useMemo<string[] | undefined>(() => {
    return toMeasuresQuestionsAdditionalData(nonStandardAdditionalData)?.protectionObjectiveIds;
  }, [nonStandardAdditionalData]);

  const implementedTOMIds = useMemo<Set<string> | undefined>(() => {
    const implementedTOMIds = toMeasuresQuestionsAdditionalData(nonStandardAdditionalData)?.implementedTOMIds;
    return implementedTOMIds ? new Set(implementedTOMIds) : undefined;
  }, [nonStandardAdditionalData]);

  const mainOrgUnitIdOnCopy = useMemo<string | undefined>(() => {
    return toMeasuresQuestionsAdditionalData(nonStandardAdditionalData)?.mainOrgUnitIdOnCopy;
  }, [nonStandardAdditionalData]);

  const [tomsById, setTomsById] = useState<Map<string, TomModelDTO>>(new Map());
  const [optionIds, setOptionIds] = useState<string[]>([]);
  const [disabledOptionIds, setDisabledOptionIds] = useState<Set<string> | undefined>(undefined);
  useEffect(() => {
    const tomsById = new Map<string, TomModelDTO>();
    const optionIds: string[] = [];
    const disabledOptionIds = new Set<string>();
    const allowedProtectionObjectiveIds = protectionObjectiveIds ? new Set(protectionObjectiveIds) : undefined;
    for (const option of (options || []) as TomModelDTO[]) {
      const tom = option as TomModelDTO;
      tomsById.set(tom.id, tom);
      optionIds.push(tom.id);

      if (allowedProtectionObjectiveIds) {
        const haveMatchingProtectionObjectiveIds = tom.protectionObjectiveIds?.some(id =>
          allowedProtectionObjectiveIds.has(id)
        );
        if (!haveMatchingProtectionObjectiveIds) {
          disabledOptionIds.add(tom.id);
        }
      }
      if (implementedTOMIds && implementedTOMIds.has(tom.id)) {
        disabledOptionIds.add(tom.id);
      }
    }
    setTomsById(tomsById);
    setOptionIds(optionIds);
    setDisabledOptionIds(disabledOptionIds);
  }, [options, protectionObjectiveIds, implementedTOMIds]);

  const getOptionName = useCallback(
    (tomId: string) => {
      return tomsById.get(tomId)?.name || "";
    },
    [tomsById]
  );

  const groupsOrder = useMemo(() => {
    return [
      t("dpia_four_four_page:general_measure"),
      t("dpia_four_four_page:process_specific_measure"),
      t("risk_first_assessment_page:alreadyImplementedMeasures"),
      t("risk_first_assessment_page:noCommonProtectionObjectives")
    ];
  }, [t]);

  const getOptionGroup = useCallback(
    (tomId: string): string[] => {
      const tom = tomsById.get(tomId);
      if (!tom) {
        return [];
      }

      const alreadyImplemented = implementedTOMIds && implementedTOMIds.has(tomId);
      if (alreadyImplemented) {
        return [t("risk_first_assessment_page:alreadyImplementedMeasures")];
      }

      const disabledTomDuaToMissingProtectionObjective = disabledOptionIds && disabledOptionIds.has(tomId);
      if (disabledTomDuaToMissingProtectionObjective) {
        return [t("risk_first_assessment_page:noCommonProtectionObjectives")];
      }

      if (tom.processSpecific) {
        return [t("dpia_four_four_page:process_specific_measure")];
      }

      return [t("dpia_four_four_page:general_measure")];
    },
    [disabledOptionIds, implementedTOMIds, t, tomsById]
  );

  const getIsOptionDisabled = useCallback(
    (tomId: string): boolean => {
      return !!disabledOptionIds?.has(tomId);
    },
    [disabledOptionIds]
  );

  const [pickerOpen, setPickerOpen] = useState(false);
  const closePicker = useCallback(() => {
    setPickerOpen(false);
  }, []);
  const openPicker = useCallback(() => {
    setPickerOpen(true);
  }, []);

  const onDeleteCallback = useCallback(
    (tom: TomModelDTO) => {
      const updatedValue = currentValue.filter(id => id !== tom.id);
      setCurrentValue(updatedValue);
      onChangeCallback(updatedValue);
    },
    [currentValue, onChangeCallback]
  );

  const { auth } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const onCopyAsProcessSpecificCallback = useCallback(
    async (tom: TomModelDTO) => {
      const createdTomId = await createTomApi(tom.name, {
        processSpecific: true,
        protectionObjectiveIds: tom.protectionObjectiveIds,
        description: tom.description,
        riskIds: tom.riskIds,
        labelIds: tom.labelIds,
        furtherAffectedOrgUnitIds: [],
        status: tom.status,
        statusDate: tom.statusDate,
        ownerUID: auth?.uid || tom.ownerUID,
        orgUnitId: mainOrgUnitIdOnCopy || tom.orgUnitId
      });

      setOptionIds(prev => [...prev, createdTomId]);
      setTomsById(prev =>
        new Map(prev).set(createdTomId, {
          ...tom,
          id: createdTomId,
          processSpecific: true
        })
      );
      const newValue = currentValue.map(id => (id === tom.id ? createdTomId : id));
      setCurrentValue(newValue);

      onChangeCallback(newValue);
      enqueueSnackbar(t("dpia_four_four_page:copiedAsProcessSpecific"), { variant: "success" });
    },
    [auth?.uid, mainOrgUnitIdOnCopy, currentValue, onChangeCallback, enqueueSnackbar, t]
  );

  const [toEditTOMId, setToEditTOMId] = useState<string | null>(null);
  const onEditCallback = useCallback(
    (tom: TomModelDTO) => {
      setToEditTOMId(tom.id);
    },
    [setToEditTOMId]
  );
  const cancelEditCallback = useCallback(() => {
    setToEditTOMId(null);
  }, [setToEditTOMId]);
  const onTOMUpdatedCallback = useCallback(
    (id: string, modelData: TomModalData) => {
      setToEditTOMId(null);
      onChangeCallback(currentValue);
      setTomsById(prev => {
        const tom = prev.get(id);
        if (!tom) {
          return prev;
        }
        return new Map(prev).set(id, {
          ...tom,
          name: modelData.name === undefined ? tom.name : modelData.name || "",
          description: modelData.description === undefined ? tom.description : modelData.description || "",
          protectionObjectiveIds:
            modelData.protectionObjectiveIds === undefined
              ? tom.protectionObjectiveIds
              : modelData.protectionObjectiveIds || [],
          status: modelData.status === undefined ? tom.status : modelData.status || null,
          statusDate: modelData.statusDate === undefined ? tom.statusDate : modelData.statusDate || null
        });
      });
    },
    [currentValue, onChangeCallback]
  );

  const [toCreateTOMOptionName, setToCreateTOMOptionName] = useState<string | null>(null);
  const onOptionAddCallback = useCallback(
    (optionName: string) => {
      setToCreateTOMOptionName(optionName);
    },
    [setToCreateTOMOptionName]
  );
  const createTomCancelCallback = useCallback(() => {
    setToCreateTOMOptionName(null);
  }, [setToCreateTOMOptionName]);

  const tomCreatedCallback = useCallback(
    (tom: TomModelDTO) => {
      setToCreateTOMOptionName(null);
      setOptionIds(prev => [...prev, tom.id]);
      setTomsById(prev => new Map(prev).set(tom.id, tom));
      const updatedValue = [...currentValue, tom.id];
      setCurrentValue(updatedValue);
    },
    [currentValue]
  );

  const userHasTomAddPermission = auth?.permissions.includes("tom_write_org");

  const createTomAdditionalData = useMemo<Partial<TomModelDTO>>(() => {
    if (!protectionObjectiveIds) {
      return {};
    }
    return {
      protectionObjectiveIds
    };
  }, [protectionObjectiveIds]);

  /* IMPORT MEASURES FROM EXISTING PA */
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
  const openImportDialog = useCallback(() => {
    setShowImportDialog(true);
  }, []);
  const closeImportDialog = useCallback(() => {
    setShowImportDialog(false);
  }, []);
  const importButtonEl = useMemo(() => {
    if (suggestionValueIds && suggestionValueIds.length > 0) {
      return (
        <Button variant="outlined" startIcon={<LogoutIcon />} onClick={openImportDialog}>
          {t("processor_pa_page:importMeasuresButton")}
        </Button>
      );
    }
    return <></>;
  }, [openImportDialog, suggestionValueIds, t]);

  return (
    <Box onFocus={onFocus} onBlur={onBlur}>
      <Box mb={currentValue.length ? 2 : undefined}>
        {currentValue
          .map(id => tomsById.get(id))
          .filter((tom): tom is TomModelDTO => !!tom)
          .map((tom, index) => (
            <MeasureAccordion
              key={`${tom.id}-${index}`}
              tom={tom as TomModelDTO}
              onDelete={onDeleteCallback}
              onEdit={onEditCallback}
              onCopyAsProcessSpecific={onCopyAsProcessSpecificCallback}
              disabled={disabled}
            />
          ))}
      </Box>
      <Box display={"flex"} gap={2}>
        <Button variant="contained" color="primary" onClick={openPicker} disabled={disabled}>
          {t("pa_data_flow_measures_risks:add_measure")}
        </Button>
        {importButtonEl}
      </Box>
      <MultiPickerDialog
        title={t("pa_data_flow_measures_risks:add_measure")}
        open={pickerOpen}
        onClose={closePicker}
        onConfirm={onChangeCallback}
        value={currentValue}
        options={optionIds}
        groupsOrder={groupsOrder}
        onOptionAdd={onOptionAddCallback}
        getOptionName={getOptionName}
        getOptionGroup={getOptionGroup}
        getIsOptionDisabled={getIsOptionDisabled}
        allowAdd={userHasTomAddPermission && allowAdd}
      />
      <UpdateTOMModal
        open={!!toEditTOMId}
        onCancel={cancelEditCallback}
        onUpdated={onTOMUpdatedCallback}
        tomId={toEditTOMId || ""}
        docOrgUnitIds={orgUnitIds}
      />
      <CreateTOMModal
        initialName={toCreateTOMOptionName || ""}
        open={!!toCreateTOMOptionName}
        onCancel={createTomCancelCallback}
        onCreated={tomCreatedCallback}
        docOrgUnitIds={orgUnitIds}
        additionalData={createTomAdditionalData}
      />
      <MultiPickerDialog
        title={t("processor_pa_page:importMeasuresDialogTitle", { number: suggestionValueIds?.length })}
        open={showImportDialog}
        onClose={closeImportDialog}
        onConfirm={onSuggestionsConfirm}
        value={suggestionValueIds || []}
        options={suggestionValueIds || []}
        getOptionName={getOptionName}
      />
    </Box>
  );
};

const MeasureAccordion = ({
  tom,
  disabled,
  onDelete,
  onEdit,
  onCopyAsProcessSpecific
}: {
  tom: TomModelDTO;
  disabled?: boolean;
  onDelete?: (tom: TomModelDTO) => void;
  onEdit?: (tom: TomModelDTO) => void;
  onCopyAsProcessSpecific?: (tom: TomModelDTO) => void;
}) => {
  const { t } = useTranslation();

  const onDeleteCallback = useCallback(() => {
    onDelete?.(tom);
  }, [onDelete, tom]);

  const onEditCallback = useCallback(() => {
    onEdit?.(tom);
  }, [onEdit, tom]);

  const [isCopying, setIsCopying] = useState(false);
  const onCopyAsProcessSpecificCallback = useCallback(() => {
    setIsCopying(true);
    onCopyAsProcessSpecific?.(tom);
  }, [onCopyAsProcessSpecific, tom]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {tom.name}
        {tom.processSpecific ? (
          ""
        ) : (
          <Box ml={1}>
            <Chip label={t("pagination:general")} size="small" />
          </Box>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box mb={2}>
          <TextEditor
            testId={"paMeasureDescription"}
            title={t("dpia_four_four_page:measure_description")}
            inputValue={
              checkIfTextEditorContainsText(tom.description)
                ? tom.description
                : t("dpia_four_four_page:emptyDescription")
            }
            disabled={true}
          />
        </Box>
        <Box mb={2}>
          <ResourceField
            disabled={true}
            id="protectionObjective"
            value={tom.protectionObjectiveIds ?? []}
            resourceType={RESOURCE_TYPES.PROTECTION_OBJECTIVE}
            label={t("dpia_four_four_page:protection_objective")}
            isNotDeletable={alwaysNotDeletable}
            onChange={doNothing}
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" mt={4}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DeleteIcon />}
            onClick={onDeleteCallback}
            disabled={disabled}
          >
            {t("questionnaires:delete")}
          </Button>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            {!tom.processSpecific && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={isCopying ? <CircularProgress size={16} /> : <ContentCopyIcon />}
                onClick={onCopyAsProcessSpecificCallback}
                disabled={isCopying || disabled}
              >
                {t("dpia_four_four_page:copy_as_process_specific")}
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={onEditCallback}
              disabled={disabled}
            >
              {tom.processSpecific
                ? t("dpia_four_four_page:edit_process_specific_measure")
                : t("dpia_four_four_page:edit_general_measure")}
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const doNothing = () => {};
const alwaysNotDeletable = () => true;

export interface MeasuresQuestionAdditionalData {
  protectionObjectiveIds?: string[];
  implementedTOMIds?: string[];
  mainOrgUnitIdOnCopy?: string;
}

const toMeasuresQuestionsAdditionalData = (data: unknown): MeasuresQuestionAdditionalData | null => {
  if (!data) {
    return null;
  }
  if (typeof data !== "object") {
    return null;
  }

  const protectionObjectiveIds =
    "protectionObjectiveIds" in data && data.protectionObjectiveIds && Array.isArray(data.protectionObjectiveIds)
      ? data.protectionObjectiveIds
      : undefined;
  const implementedTOMIds =
    "implementedTOMIds" in data && data.implementedTOMIds && Array.isArray(data.implementedTOMIds)
      ? data.implementedTOMIds
      : undefined;
  const mainOrgUnitIdOnCopy =
    "mainOrgUnitIdOnCopy" in data && typeof data.mainOrgUnitIdOnCopy === "string"
      ? data.mainOrgUnitIdOnCopy
      : undefined;
  return {
    protectionObjectiveIds,
    implementedTOMIds,
    mainOrgUnitIdOnCopy
  };
};

export default MeasuresQuestion;
