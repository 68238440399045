import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useNavigate, useParams } from "react-router-dom";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { TomOverviewCollectionParams } from "./TomOverviewController";
import { exportTomsExcel } from "app/export/createdExcelExportData";
import { exportTomsPdf } from "app/export/createdPdfExportData";
import { tomWritePermissions } from "../../../handlers/permissionHandler";
import { streamFilesToZip } from "../../../handlers/fileHandler";

export const TomOverview = () => {
  const { t } = useTranslation("tom_measures_overview");
  const dispatch = useOverviewDispatch();
  const { setInfo, setMeta } = useMetaView();
  const { auth } = useAuthentication();
  const currentPage = useParams()?.page || "";
  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard?.entering, setInfo]);

  useEffect(() => {
    showEnterInfo();
  }, [showEnterInfo]);

  const pages = useMemo(
    () => [
      { title: t("tom_measures_overview:tab_general"), route: "general", current: currentPage === "general" },
      {
        title: t("tom_measures_overview:tab_process_specific"),
        route: "processes",
        current: currentPage === "processes"
      }
    ],
    [currentPage, t]
  );

  const navigate = useNavigate();
  const onPageChange = useCallback(
    ({ route }) => {
      navigate(`/toms/${route}`);
      dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.TOM, selectedId: null });
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW, collection: COLLECTIONS.TOM, reloadOverview: Date.now() });
    },
    [dispatch, navigate]
  );

  const exportAllToPdf = useCallback(async ids => {
    await exportTomsPdf(ids);
  }, []);

  const tomTemplatesEnabled = useIsFeaturePresent(FEATURES.TOM_TEMPLATES);
  const addActions = useMemo(
    () => [
      { action: OVERVIEW_ADD_TYPE.SINGLE },
      { action: OVERVIEW_ADD_TYPE.MULTIPLE },
      ...(tomTemplatesEnabled ? [{ action: OVERVIEW_ADD_TYPE.TEMPLATE }] : [])
    ],
    [tomTemplatesEnabled]
  );

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }, { action: "export-all" }], []);

  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }], []);

  const selectionActions = useMemo(() => [{ action: "export-all" }, { action: "remove" }], []);

  const onRowOver = useCallback(
    tom => {
      setMeta({
        department: tom.subTitle,
        createdOn: <DateDisplay timestamp={new Date(tom.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={tom.createdBy} />,
        updated: tom.updatedAt ? <DateDisplay timestamp={new Date(tom.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: tom.updatedBy ? <AutomaticUserDataDisplay uid={tom.updatedBy} separator={undefined} /> : "-",
        status: t(`tom_description_tab:status_${tom.status?.toUpperCase()}`, tom.status || "-")
      });
    },
    [setMeta, t]
  );

  const exportToXLSX = useCallback(
    async ids => {
      if (auth?.tenantId) {
        return await exportTomsExcel(auth?.tenantId, auth?.uid, t, ids);
      }
    },
    [auth?.tenantId, auth?.uid, t]
  );

  const exportToPdf = useCallback(async (ids: string[]) => {
    await exportTomsPdf(ids);
  }, []);

  const exportToAttachments = useCallback(
    async (ids, idToName) => {
      if (!auth?.tenantId) {
        return;
      }

      await streamFilesToZip({
        exportDefinitions: [
          {
            docIds: ids,
            category: `${COLLECTIONS.TOM}`,
            categoryName: "General",
            withPrefixQuery: false,
            docIdToFolderName: id => idToName.get(id) || id
          }
        ]
      });
    },
    [auth?.tenantId]
  );

  const collectionParams = useMemo<TomOverviewCollectionParams>(
    () => ({
      exportToXLSX,
      exportAllToPdf,
      exportToPdf,
      exportToAttachments
    }),
    [exportToXLSX, exportAllToPdf, exportToPdf, exportToAttachments]
  );

  const showAddActions = auth?.permissions.find(permission => tomWritePermissions.includes(permission));

  const docViewContent = (
    <>
      <Overview
        pages={pages}
        onPageChange={onPageChange}
        collection={COLLECTIONS.TOM}
        onRowOver={onRowOver}
        onRowLeave={showEnterInfo}
        header={t("tom_overview:title")}
        checkable={true}
        toolbarActions={toolbarActions}
        addActions={showAddActions ? addActions : undefined}
        rowActions={rowActions}
        collectionParams={collectionParams}
        selectionActions={selectionActions}
        toolbarMode={"tabs"}
      />
    </>
  );
  return (
    <DocMetaView
      docViewContent={docViewContent}
      metaViewContent={<MetaView translationKey={"tom_measures_overview"} />}
    />
  );
};
