import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { ResourceMetaviewProps } from "../ResourceTypeOverviewDeps";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import MetaViewList, { MetaViewListItemProps } from "components/MetaViewList/MetaViewList";
import { useUserDepartments } from "app/contexts/department-context";
import { DataSubjectRequestShortDTO, getDataSubjectRequestsApi } from "app/api/dataSubjectRequestApi";

const SWR_KEY = "requests-in-metaview";

const RequestsInResourceMetaView = ({ resourceType, resourceId }: ResourceMetaviewProps) => {
  const { t } = useTranslation();
  const { departments } = useUserDepartments();
  const { data, isValidating, mutate } = useSWR(SWR_KEY, () => filter && getDataSubjectRequestsApi(filter), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const [filter, setFIlter] = useState<Record<string, string | string[]> | null>(null);
  const [items, setItems] = useState<MetaViewListItemProps[]>([]);

  useEffect(() => {
    if (resourceType && resourceId) {
      switch (resourceType) {
        case RESOURCE_TYPES.DSR_REQUEST_TYPE:
          setFIlter({
            typeIds: [resourceId]
          });
          break;
      }
    } else {
      setFIlter(null);
    }
  }, [resourceId, resourceType]);

  useEffect(() => {
    if (data && !isValidating) {
      const _items = data.datasubjectrequests?.map((request: DataSubjectRequestShortDTO) => {
        const { id, title, subTitle } = request;
        return {
          id: id,
          title: title || "",
          subTitle,
          icon: <></>,
          iconTooltip: ""
        } satisfies MetaViewListItemProps;
      });

      setItems(_items);
    } else {
      setItems([]);
    }
  }, [data, departments, isValidating, t]);

  useEffect(() => {
    mutate();
  }, [filter, mutate]);

  const onItemClickCallback = useCallback((id: string) => {
    window.open(`/data-subject-requests/${id}/general`);
  }, []);

  return (
    <Box p={3}>
      <MetaViewList
        header={t("collection:dataSubjectRequests_cap")}
        items={items}
        loading={isValidating}
        onItemClick={onItemClickCallback}
        pagination={true}
      />
    </Box>
  );
};

export default RequestsInResourceMetaView;
