import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  ClickAwayListener,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close, Delete, FileCopy, GetApp } from "@material-ui/icons";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { COLLECTION_TYPES } from "app/collections";
import { MultiOrgUnitsSelector } from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import { OverviewItem } from "../controllers/overviewBaseController";
import { OverviewToolbarActionProps } from "./OverviewPagesToolbar";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { ExportMenuItem } from "./OverviewExportMenu";
import { useOverviewState } from "app/contexts/overview-context";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(theme => ({
  iconButtons: {
    backgroundColor: theme.palette.blue[50],
    borderRadius: "12px",
    padding: "4px"
  },
  iconButton: {
    backgroundColor: theme.palette.blue[50],
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.blue[50],
      color: theme.palette.primary.dark
    },
    margin: "0 4px",
    width: "32px",
    height: "32px"
  }
}));

export interface OverviewSelectActionProps {
  readonly actions?: OverviewToolbarActionProps[];
  readonly collection: COLLECTION_TYPES;
  readonly checkedItems: OverviewItem[];
  readonly onCancel?: () => void;
  readonly onDuplicate?: (val?: string[] | undefined) => void;
  readonly onDelete?: () => void;
  readonly onExport?: (val: string) => void;
}

const ActionMenuItem = ({
  title,
  checkedItems,
  onHandle,
  onClose
}: {
  readonly title: string;
  readonly checkedItems: OverviewItem[];
  readonly onHandle: (items: OverviewItem[]) => void;
  readonly onClose: () => void;
}) => {
  const onClick = useCallback(() => {
    onClose();
    onHandle(checkedItems);
  }, [checkedItems, onClose, onHandle]);
  return <MenuItem onClick={onClick}>{title}</MenuItem>;
};

const OverviewSelectActionItem = ({
  title,
  icon,
  disabled,
  dropdownActions,
  checkedItems,
  onHandle
}: {
  readonly title: string;
  readonly icon: React.ReactNode;
  readonly disabled?: boolean;
  readonly dropdownActions?: {
    readonly title: string;
    readonly onHandle: (items: OverviewItem[]) => void;
  }[];
  readonly checkedItems: OverviewItem[];
  readonly onHandle?: (val?: any) => void;
}) => {
  const cls = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onClick = useCallback(
    event => {
      if (dropdownActions) {
        setAnchorEl(event.currentTarget);
      } else {
        onHandle?.(event);
      }
    },
    [dropdownActions, onHandle]
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const menuEl = useMemo(
    () =>
      dropdownActions && (
        <Menu id="overview-row-action-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {dropdownActions.map(({ title, onHandle }, index) => (
            <ActionMenuItem
              key={index}
              title={title}
              checkedItems={checkedItems}
              onHandle={onHandle}
              onClose={handleClose}
            />
          ))}
        </Menu>
      ),
    [anchorEl, checkedItems, dropdownActions, handleClose]
  );

  return (
    <>
      <Tooltip title={title}>
        <span>
          <IconButton className={cls.iconButton} onClick={onClick} disabled={disabled}>
            {icon}
          </IconButton>
        </span>
      </Tooltip>
      {menuEl}
    </>
  );
};

export const OverviewSelectAction = ({
  actions,
  collection,
  checkedItems,
  onCancel,
  onDuplicate,
  onDelete,
  onExport
}: OverviewSelectActionProps) => {
  const { t } = useTranslation("overview");
  const cls = useStyles();
  const [openExport, setOpenExport] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [openCopyToOrg, setOpenCopyToOrg] = useState(false);

  const [anchorExportRef, setAnchorExportRef] = useState(null);
  const [anchorCopyRef, setAnchorCopyRef] = useState(null);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [orgIdsToCopyTo, setOrgIdsToCopyTo] = useState<string[]>([]);

  const [disableDeleteAction, setDisableDeleteAction] = useState<boolean>(false);

  const overviewState = useOverviewState()[collection];
  const isActionLoading = overviewState?.action === "started";

  const defaultActions: OverviewToolbarActionProps[] = useMemo(() => [{ action: "remove" }], []);
  const allActions: OverviewToolbarActionProps[] = useMemo(() => actions || defaultActions, [actions, defaultActions]);

  useEffect(() => {
    const deleteActionExistInDisableActions = checkedItems.some(({ disableActions }) =>
      disableActions?.some((disableAction: { action: string }) => disableAction.action === "remove")
    );
    setDisableDeleteAction(deleteActionExistInDisableActions);
  }, [checkedItems]);

  /* EXPORT */
  const handleToggleExport = useCallback(event => {
    setAnchorExportRef(event.currentTarget);
    setOpenExport(prevOpen => !prevOpen);
  }, []);
  const handleCloseExport = useCallback(() => {
    setAnchorExportRef(null);
    setOpenExport(false);
  }, []);

  /* DELETE */
  const onDeleteConfirm = useCallback(() => setConfirmationOpen(true), []);
  const closeConfirmation = useCallback(() => setConfirmationOpen(false), []);

  /* COPY TO */
  const handleToggleCopyToOrg = useCallback(event => {
    setOpenCopyToOrg(prevOpen => !prevOpen);
  }, []);
  const handleCloseCopyToOrg = useCallback(() => {
    setOpenCopyToOrg(false);
  }, []);
  const handleToggleCopy = useCallback(event => {
    setAnchorCopyRef(event.currentTarget);
    setOpenCopy(prevOpen => !prevOpen);
  }, []);
  const handleCloseCopy = useCallback(() => {
    setAnchorCopyRef(null);
    setOpenCopy(false);
  }, []);
  const onCopyToCallback = useCallback(async () => {
    await onDuplicate?.(orgIdsToCopyTo);
    await handleCloseCopyToOrg();
  }, [onDuplicate, orgIdsToCopyTo, handleCloseCopyToOrg]);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const onDuplicateCallback = useCallback(async () => {
    setIsDuplicating(true);
    try {
      await onDuplicate?.();
    } catch (e) {
      setIsDuplicating(false);
      throw e;
    }
    setIsDuplicating(false);
  }, [onDuplicate]);

  const exportEl = onExport && (
    <>
      <Box py={4} px={2} pb={0}>
        <Typography align="left" style={{ fontWeight: 700 }} variant="h4">
          {t("download")}
        </Typography>
      </Box>
      <Box>
        <MenuList id="split-button-menu" autoFocusItem>
          <ExportMenuItem text={t("export_pdf")} onExport={onExport} format="pdf" />
          <ExportMenuItem text={t("export_xls")} onExport={onExport} format="xlsx" />
          {allActions.some(it => it.action === "export-all") && (
            <ExportMenuItem text={t("export_attachments")} onExport={onExport} format="attachments" />
          )}
        </MenuList>
      </Box>
    </>
  );

  const exportMenuEl = (
    <Popper open={openExport} anchorEl={anchorExportRef} placement={"bottom-start"} transition disablePortal>
      {() => (
        <Paper>
          <ClickAwayListener onClickAway={handleCloseExport}>
            <Box>{exportEl}</Box>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  );

  const copyMenuEl = (
    <Popper open={openCopy} anchorEl={anchorCopyRef} placement={"bottom-start"} transition disablePortal>
      {() => (
        <Paper>
          <ClickAwayListener onClickAway={handleCloseCopy}>
            <Box py={2}>
              <MenuList id="split-button-menu" autoFocusItem>
                <MenuItem disabled={isActionLoading} onClick={onDuplicateCallback}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>{t("duplicate")}</Box>
                    {isDuplicating && (
                      <Box ml={1}>
                        <CircularProgress size={14} />
                      </Box>
                    )}
                  </Box>
                </MenuItem>
                <MenuItem disabled={isActionLoading} onClick={handleToggleCopyToOrg}>
                  {t("copyTo")}
                </MenuItem>
              </MenuList>
            </Box>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  );

  const _actions: OverviewToolbarActionProps[] = useMemo(
    () =>
      [
        {
          action: "cancel",
          title: t("cancel"),
          onHandle: onCancel,
          icon: <Close />,
          disabled: false
        },
        ...allActions
          .filter(
            ({ action }) =>
              ![
                "duplicate-copy-to",
                "duplicate",
                "export-all",
                "export-pdf",
                "export-xlsx",
                "export-pdf-xlsx",
                "remove"
              ].includes(action)
          )
          .map(action => ({
            action: action.action,
            title: action.title || action.action,
            icon: action.icon || <PlayArrow />,
            disabled: action.disabled,
            dropdownActions: action.dropdownActions,
            onHandle: () => (action.onHandle ? action.onHandle(checkedItems) : Promise.resolve)
          })),
        allActions.some(({ action }) => action === "duplicate-copy-to") && {
          action: "duplicate-copy-to",
          title: t("duplicate"),
          onHandle: handleToggleCopy,
          icon: <FileCopy />,
          disabled: false
        },
        allActions.some(({ action }) => action === "duplicate") && {
          action: "duplicate",
          title: t("duplicate"),
          onHandle: onDuplicateCallback,
          icon: <FileCopy />,
          disabled: false
        },
        allActions.some(({ action }) => action === "export-all") && {
          action: "export-all",
          title: t("downloadSelected"),
          onHandle: handleToggleExport,
          icon: <GetApp />,
          disabled: false
        },
        allActions.some(({ action }) => action === "export-pdf-xlsx") && {
          action: "export-pdf-xlsx",
          title: t("downloadSelected"),
          onHandle: handleToggleExport,
          icon: <GetApp />,
          disabled: false
        },
        allActions.some(({ action }) => action === "export-pdf") && {
          action: "export-pdf",
          title: t("downloadSelected"),
          onHandle: () => onExport?.("pdf"),
          icon: <GetApp />,
          disabled: false
        },
        allActions.some(({ action }) => action === "export-xlsx") && {
          action: "export-xlsx",
          title: t("downloadSelected"),
          onHandle: () => onExport?.("xlsx"),
          icon: <GetApp />,
          disabled: false
        },
        allActions.some(({ action }) => action === "remove") && {
          action: "remove",
          title: t("remove"),
          onHandle: onDeleteConfirm,
          icon: <Delete />,
          disabled: disableDeleteAction
        }
      ].flatMap(nonNull => (nonNull ? [nonNull] : [])),
    [
      allActions,
      checkedItems,
      disableDeleteAction,
      handleToggleCopy,
      handleToggleExport,
      onCancel,
      onDeleteConfirm,
      onDuplicateCallback,
      onExport,
      t
    ]
  );

  const buttonsEl = useMemo(
    () =>
      _actions.map((item, index) => {
        const { title, onHandle, icon, disabled } = item;
        return (
          <OverviewSelectActionItem
            key={index}
            title={title || ""}
            icon={icon}
            onHandle={onHandle}
            disabled={disabled}
            dropdownActions={item.dropdownActions}
            checkedItems={checkedItems}
          />
        );
      }),
    [_actions, checkedItems]
  );

  const confirmationDialogEl = useMemo(
    () => (
      <ConfirmationModal
        variant="info"
        modalOpen={confirmationOpen}
        onClose={closeConfirmation}
        modalTitle={t("common:information")}
        modalText={t(`confirm_delete_collection`, { collection: t(`collection:${collection}`) })}
        buttons={[
          {
            title: t("common:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: closeConfirmation
          },
          {
            confirmButton: true,
            title: t("common:delete"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: onDelete
          }
        ]}
      />
    ),
    [closeConfirmation, collection, confirmationOpen, onDelete, t]
  );

  const copyToModalEl = useMemo(
    () => (
      <ConfirmationModal
        modalOpen={openCopyToOrg}
        onClose={handleCloseCopyToOrg}
        modalTitle={t("processes_overview:information")}
        modalText={t("processes_overview:copy_modal_text")}
        modalBody={
          <Box mt={2}>
            <MultiOrgUnitsSelector
              fullWidth={true}
              value={orgIdsToCopyTo}
              label={t("processes_overview:organizational_units")}
              onChange={setOrgIdsToCopyTo}
            />
          </Box>
        }
        buttons={[
          {
            confirmButton: false,
            title: t("questionnaires:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: handleCloseCopyToOrg
          },
          {
            confirmButton: true,
            title: t("common:copy"),
            variant: "contained",
            color: "primary",
            size: "medium",
            disabled: !orgIdsToCopyTo?.length,
            onClick: onCopyToCallback
          }
        ]}
      />
    ),
    [handleCloseCopyToOrg, onCopyToCallback, openCopyToOrg, orgIdsToCopyTo, t]
  );

  return (
    <Box className={cls.iconButtons} display="flex" justifyContent={"end"}>
      {buttonsEl}
      {exportMenuEl}
      {copyMenuEl}
      {confirmationDialogEl}
      {copyToModalEl}
    </Box>
  );
};
