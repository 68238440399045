import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import { createTasksBasedOnRequestType, verificationQuestionId } from "../../handlers/dataSubjectRequestHandler";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import TextBody2 from "../../../components/TextBody2/TextBody2";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { DataSubjectRequestsPageButtons, DataSubjectRequestsPageStepper } from "./DataSubjectRequestsPagination";
import InlineTaskDetails from "../tasks/details/InlineTaskDetails";
import { createTask, deleteTask, getAllTasksByDocumentId, getTask, TYPES } from "../../handlers/tasksHandler";
import Button from "@material-ui/core/Button";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { sortTasks } from "./dsrTaskOrder";
import MetaView from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import { COLLECTIONS } from "../../collections";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import { useResources } from "../../contexts/resource-context";
import { RESOURCE_TYPES } from "../../handlers/resourceHandler";
import { downloadAttachmentApi, getAllAttachmentsApi } from "../../api/file-storage/attachmentsApi";
import { isEqual } from "lodash-es";
import { getFileIcon } from "../shared/Attachments/fileAttachmentUtils";
import Row from "components/Row/Row";
import { useDataSubjectRequest } from "../../contexts/dsr-context";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import TaskDetails from "../tasks/details/TaskDetails";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { useUserDepartments } from "app/contexts/department-context";

const useStyles = makeStyles(theme => ({
  radioChecked: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {
    color: theme.palette.primary.main
  },
  centerCircularProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));

export default function DataSubjectRequestsTasksPage({ documentId }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("data_subject_requests_tasks_page");
  const { setInfo } = useMetaView();
  const { isPartOfUserDepartments } = useUserDepartments();

  useEffect(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("enteringInfoCardText")
    });
  }, [i18n.language, setInfo, t]);

  const [userFocusOnField, setUserFocusOnField] = useState(null);
  const {
    auth: { tenantId, uid: userUID, permissions }
  } = useAuthentication();

  const {
    initialized: dataSubjectRequestInitialized,
    dataSubjectRequest,
    updateBasicDataHook
  } = useDataSubjectRequest();
  const { translateById } = useResources();
  const { addToSeenItemsOfUserHook, isNoAllowOrgUnitWritePermissionHook } = useUserAndTenantData();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [pageLoaded, setPageLoaded] = useState(false);
  const [isDataSubjectVerified, setIsDataSubjectVerified] = useState("");
  const [taskIDs, setTaskIDs] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [taskLoaded, setTaskLoaded] = useState(false);
  const [uploadedFilesLoaded, setUploadedFilesLoaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [taskDetailsId, setTaskDetailsId] = useState(null);

  const defaultMetaViewEl = useMemo(
    () => <MetaView translationKey={"data_subject_requests_overview_general_page"} />,
    []
  );

  const [metaViewEl, setMetaViewEl] = useState(defaultMetaViewEl);

  const loadUploadedFiles = useCallback(async () => {
    if (!dataSubjectRequestInitialized) {
      return;
    }

    const requestTypes = dataSubjectRequest.inputData?.type || [];
    let allDataSorted = [];

    for (const requestType of requestTypes) {
      const fileUploadSubFolderName = COLLECTIONS.RESOURCES + "-" + RESOURCE_TYPES.DSR_REQUEST_TYPE + "-" + requestType;

      const data = await getAllAttachmentsApi({ folderName: fileUploadSubFolderName });

      const dataSorted = data
        .sort((a, b) => b.timeCreated.localeCompare(a.timeCreated))
        .map(v => ({ ...v, requestType: requestType })); // ISO8601 strings
      allDataSorted.push(dataSorted);
    }

    if (!isEqual(allDataSorted, uploadedFiles)) {
      setUploadedFiles(allDataSorted.flat(1));
    }

    setUploadedFilesLoaded(true);
  }, [dataSubjectRequestInitialized, dataSubjectRequest.inputData?.type, setUploadedFiles, uploadedFiles]);

  const loadDSRTasks = useCallback(async () => {
    setTaskIDs([]);
    const requestTypes = dataSubjectRequest.inputData?.type || [];
    setTasksLoading(true);
    const tasks = await getAllTasksByDocumentId(dataSubjectRequest.id);
    const sortedTasks = sortTasks(requestTypes, tasks);

    setTaskIDs(sortedTasks.map(task => task.id));
    setTasksLoading(false);
    setTaskLoaded(true);
    return sortedTasks;
  }, [dataSubjectRequest.id, dataSubjectRequest.inputData?.type]);

  const createAutomaticTasks = useCallback(async () => {
    setTasksLoading(true);
    await createTasksBasedOnRequestType(dataSubjectRequest.id, i18n.language);
    await loadDSRTasks();
  }, [dataSubjectRequest.id, i18n.language, loadDSRTasks]);

  useEffect(() => {
    if (!dataSubjectRequestInitialized) {
      setPageLoaded(false);
      setUserFocusOnField(null);
      return;
    }

    if (!dataSubjectRequest) {
      return;
    }

    if (userFocusOnField !== "isDataSubjectVerified") {
      setIsDataSubjectVerified(dataSubjectRequest.inputData?.isDataSubjectVerified || "");
    }
    setPageLoaded(true);
  }, [dataSubjectRequestInitialized, dataSubjectRequest]);

  useEffect(() => {
    if (pageLoaded && !taskLoaded) {
      loadDSRTasks().catch(catchAsSnackbar("Failed to load tasks"));
    }
  }, [catchAsSnackbar, loadDSRTasks, pageLoaded, taskLoaded]);

  useEffect(() => {
    if (!pageLoaded) {
      return;
    }

    const requestTypes = dataSubjectRequest.inputData?.type || [];
    setPageTitle(requestTypes.map(type => translateById(RESOURCE_TYPES.DSR_REQUEST_TYPE, type)).join(", "));
  }, [pageLoaded, dataSubjectRequest, translateById]);

  useEffect(() => {
    if (userUID && dataSubjectRequest.id) {
      loadUploadedFiles();
    }
  }, [userUID, dataSubjectRequest.id]);

  const noWritePermission = useMemo(() => {
    if (!dataSubjectRequest.inputData.orgUnitId) return false;
    if (dataSubjectRequest.inputData.assignedTo === userUID || permissions.includes("dsr_write_all")) return false;
    if (isPartOfUserDepartments(...[dataSubjectRequest.inputData.orgUnitId])) return false;
    return true;
  }, [dataSubjectRequest, isPartOfUserDepartments, userUID]);

  const isDataSubjectVerifiedChanged = useCallback(answer => {
    setIsDataSubjectVerified(answer);
    if ((dataSubjectRequest.inputData?.isDataSubjectVerified || "") !== answer) {
      updateBasicDataHook({ isDataSubjectVerified: answer })
        .catch(catchAsSnackbar("Failed to update verified status"))
        .then(() => createAutomaticTasks().catch(catchAsSnackbar("Failed to create automatic tasks")));
    }
  }, []);

  const createCustomEmptyTask = useCallback(async () => {
    const createdTaskId = await createTask({
      tenantId,
      task: {
        title: "",
        type: TYPES.check_dsr,
        documentId: dataSubjectRequest.id,
        collection: COLLECTIONS.DATA_SUBJECT_REQUESTS,
        pageId: "tasks"
      }
    });
    await addToSeenItemsOfUserHook(COLLECTIONS.TASKS, createdTaskId);
  }, [tenantId, dataSubjectRequest.id, addToSeenItemsOfUserHook]);

  const newTaskButtonClicked = useCallback(async () => {
    setTasksLoading(true);
    try {
      await createCustomEmptyTask();
    } finally {
      setTasksLoading(false);
    }
    await loadDSRTasks();
  }, [setTasksLoading, createCustomEmptyTask, loadDSRTasks]);

  const onTaskDeleted = useCallback(
    deletedTaskId => {
      setTaskIDs(latestTaskIDs => {
        return latestTaskIDs.filter(taskId => taskId !== deletedTaskId);
      });
    },
    [setTaskIDs]
  );
  const onTaskDone = useCallback(
    async doneTaskId => {
      const task = await getTask(doneTaskId);
      if (task.questionId === verificationQuestionId) {
        setIsDataSubjectVerified("yes");
      }
    },
    [setIsDataSubjectVerified]
  );
  const onCloseTaskDetails = useCallback(() => {
    setMetaViewEl(defaultMetaViewEl);
    setTaskDetailsId(null);
  }, [defaultMetaViewEl]);
  const onTaskDelete = useCallback(
    async taskId => {
      await deleteTask(taskId);
      onCloseTaskDetails();
      setTaskIDs(latestTaskIDs => {
        return latestTaskIDs.filter(id => id !== taskId);
      });
    },
    [onCloseTaskDetails]
  );
  const buildTaskDetails = useCallback(
    (taskId, reloadId) => {
      return (
        <TaskDetails
          key={`dsr-task-details-${taskId}`}
          reloadId={reloadId}
          taskId={taskId}
          onTaskLoad={null}
          onCloseTaskDetails={onCloseTaskDetails}
          onTaskDelete={onTaskDelete}
          onTaskEdit={loadDSRTasks}
        />
      );
    },
    [loadDSRTasks, onCloseTaskDetails, onTaskDelete]
  );
  const onShowTaskDetails = useCallback(
    taskId => {
      setTaskDetailsId(taskId);
      setMetaViewEl(buildTaskDetails(taskId));
    },
    [buildTaskDetails]
  );
  const onTaskEdit = useCallback(
    taskId => {
      if (taskId === taskDetailsId) {
        setMetaViewEl(buildTaskDetails(taskId, Date.now()));
      }
    },
    [buildTaskDetails, taskDetailsId]
  );

  // content for doc view
  const docViewContent = () => (
    <DocView header={dataSubjectRequest?.inputData?.title || ""} pagination={<DataSubjectRequestsPageStepper />}>
      <QuestionnaireSubHeader
        text={t("title_data_subject_request_task_page") + (pageTitle ? ": " + pageTitle : "")}
        disabled={noWritePermission}
      />
      <>
        <Box>
          <TextBody2 text={t("hasVerified")} />
        </Box>
        <Box mb={3} mt={1}>
          <RadioGroup row name="isDataSubjectVerified" value={isDataSubjectVerified || ""}>
            <FormControlLabel
              label={t("yes")}
              value={"yes"}
              disabled={noWritePermission}
              control={
                <Radio
                  onClick={() => isDataSubjectVerifiedChanged("yes")}
                  classes={{ root: classes.radioChecked, checked: classes.checked }}
                />
              }
            />
            <FormControlLabel
              label={t("no")}
              value={"no"}
              disabled={noWritePermission}
              control={
                <Radio
                  onClick={() => isDataSubjectVerifiedChanged("no")}
                  classes={{ root: classes.radioChecked, checked: classes.checked }}
                />
              }
            />
          </RadioGroup>
        </Box>
        <Grid container spacing={3}>
          {taskIDs &&
            taskIDs.length > 0 &&
            taskIDs.map(taskID => {
              return (
                <Grid key={taskID} item xs={12}>
                  <InlineTaskDetails
                    existingTaskId={taskID}
                    onDelete={() => onTaskDeleted(taskID)}
                    onDone={() => onTaskDone(taskID)}
                    onShowTaskDetails={onShowTaskDetails}
                    onTaskEdit={onTaskEdit}
                    disabled={noWritePermission}
                  />
                </Grid>
              );
            })}
          {tasksLoading && (
            <Grid item xs={12}>
              <div className={classes.centerCircularProgress}>
                <CircularProgress color="inherit" />
              </div>
            </Grid>
          )}
        </Grid>
      </>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {(dataSubjectRequest.inputData?.isDataSubjectVerified === "yes" ||
            dataSubjectRequest.inputData?.isDataSubjectVerified === "no") && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                noWritePermission ||
                tasksLoading ||
                !(isDataSubjectVerified === "yes" || isDataSubjectVerified === "no")
              }
              onClick={newTaskButtonClicked}
            >
              {t("data_subject_requests_tasks_page:add_task")}
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <QuestionnaireSubHeader text={t("common:templates")} />
          {!!uploadedFiles.length &&
            uploadedFiles.map((files, index) => {
              return (
                <React.Fragment key={`rowfragment-${index}-${files.name}`}>
                  <Row
                    index={index}
                    determineStatusIcon={() => getFileIcon(files.name, classes)}
                    rowDataItemText={files.name}
                    key={`row-${index}-${files.name}`}
                    onClick={() => {
                      downloadAttachmentApi({
                        folderName:
                          COLLECTIONS.RESOURCES + "-" + RESOURCE_TYPES.DSR_REQUEST_TYPE + "-" + files.requestType,
                        fileName: files.name
                      });
                    }}
                  />
                  <Divider key={`Divider-${index}-${files.name}`} />
                </React.Fragment>
              );
            })}
          {uploadedFilesLoaded && !uploadedFiles.length && (
            <CustomAlert severity="info">
              <Typography variant="body2" component="span">
                {parse(DOMPurify.sanitize(t("no_templates")))}
              </Typography>
            </CustomAlert>
          )}
        </Grid>
        <Grid item xs={12}>
          <DataSubjectRequestsPageButtons />
        </Grid>
      </Grid>
    </DocView>
  );

  const circularProgress = <CircularProgress color="inherit" />;
  return <DocMetaView docViewContent={pageLoaded ? docViewContent() : circularProgress} metaViewContent={metaViewEl} />;
}
