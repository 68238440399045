import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";

export default function GeneralPageButtons({
  leftArea,
  primaryButton,
  secondaryButton,
  rightArea
}: {
  readonly leftArea?: React.ReactNode;
  readonly primaryButton?: {
    readonly label?: string;
    readonly disabled?: boolean;
    readonly onClick: () => void;
  };
  readonly secondaryButton?: {
    readonly label?: string;
    readonly disabled?: boolean;
    readonly onClick: () => void;
  };
  readonly rightArea?: React.ReactNode;
}) {
  const { t } = useTranslation();

  return (
    <Box display="flex" width="100%" alignItems="center" justifyContent={leftArea ? "space-between" : "flex-end"}>
      {leftArea && <Box>{leftArea}</Box>}
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
          {secondaryButton && (
            <Box>
              <Button variant="outlined" disabled={secondaryButton.disabled} onClick={secondaryButton.onClick}>
                {secondaryButton.label || t("pagination:back")}
              </Button>
            </Box>
          )}
          {primaryButton && (
            <Box>
              <Button
                disabled={primaryButton.disabled}
                variant="contained"
                color="primary"
                onClick={primaryButton.onClick}
              >
                {primaryButton.label || t("pagination:next")}
              </Button>
            </Box>
          )}
          {rightArea && <Box>{rightArea}</Box>}
        </Box>
      </Box>
    </Box>
  );
}
