import React from "react";
import HeatMap from "../../../../components/HeatMap/HeatMap";
import { useAssessmentResources } from "./RiskAssessment";
import { useTranslation } from "react-i18next";

export const RiskHeatMap = ({ damageExtendId, occurrenceId, headerToRender }) => {
  const { t } = useTranslation("risk_assessment");
  const { occurrenceIdToValue, damageExtendIdToValue } = useAssessmentResources();
  const occurrenceValue = occurrenceIdToValue(occurrenceId);
  const damageExtendValue = damageExtendIdToValue(damageExtendId);
  const mapSize = 200;
  const marker = (
    <svg height={50} width={50}>
      <circle cx={25} cy={25} r={10} fill={"#FCFCFC"} stroke="#2450BF" strokeWidth="2" />
    </svg>
  );
  return (
    <HeatMap
      numberSquaresPerRow={4}
      mapSize={mapSize}
      markersToDisplay={[
        {
          xPosition: damageExtendValue - 1,
          yPosition: occurrenceValue - 1,
          objectToRender: marker
        }
      ]}
      labelXAxis={t("damageExtend")}
      labelYAxis={t("occurrence")}
    />
  );
};
