import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { getAuditsStatusesTypeFilter } from "app/pages/shared/Filters/filters";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { AxiosInstance } from "axios";
import i18n from "app/i18n";
import { departmentsDecorator } from "components/Overview/controllers/decorator/departmentsDecorator";
import { getOrganizationFilter } from "../../../shared/Filters/OrganizationFilter";

const AssessmentResponseOverviewController = (
  axiosInstance: AxiosInstance,
  options: {
    readonly auditId: string;
    readonly setAllAssessmentResponseIds: (ids: string[]) => void;
  }
): OverviewController => {
  // the controller is a pure TS file, if it uses state, the value will not be updated
  const t = i18n.t;

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.ASSESSMENT_RESPONSE, undefined, [
    departmentsDecorator
  ]);

  const getFilters = (overviewResult: OverviewResult) => {
    return [
      getOrganizationFilter("orgUnitId", overviewResult._departments, t, undefined),
      getAuditsStatusesTypeFilter("status", overviewResult.filters?.statuses || [], t)
    ].filter(nonNull => nonNull?.filterTree?.length);
  };

  const getSortings = () => [
    {
      field: "title",
      type: "asc",
      label: t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: t("filter_criteria:zToA")
    },
    {
      field: "createdAt",
      type: "desc",
      label: t("filter_criteria:newFirst")
    },
    {
      field: "createdAt",
      type: "asc",
      label: t("filter_criteria:oldFirst")
    }
  ];

  const getCurrentPage = () => window.location.pathname.split("/").pop();

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup, `/overviews/audit/${options.auditId}/answers`);
    if (!data) {
      options.setAllAssessmentResponseIds([]);
      return null;
    }

    options.setAllAssessmentResponseIds((data?.items || []).map(({ id }) => id));
    return {
      ...data,
      items: data?.items || [],
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    if (getCurrentPage() === "instances") {
      baseController.goToItem(`/audits/${getCurrentPage()}/${id}/general`);
    } else {
      baseController.goToItem(`/audits/${getCurrentPage()}/${id}`);
    }
  };

  const baseApiUrlForPage = () => {
    const page = getCurrentPage();
    switch (page) {
      case "instances":
        return "/audits";
      case "templates":
        return "/audits/templates";
      default:
        throw new Error(`Unknown audit pages: ${page}`);
    }
  };

  const addItem = async (data: OverviewNewItem) => {
    return await baseController.addItem(
      {
        title: data?.title || createOverviewItemDefaultName()
      },
      baseApiUrlForPage()
    );
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, data, `${baseApiUrlForPage()}/${id}`);
  };

  const deleteItem = async (id: string) => {
    const item = baseController.getById(id);
    return await baseController.deleteItem(id, `/assessments/${item?.auditId}/answerset/${id}`);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem({
      title: data?.title || createOverviewItemDefaultName(`${COLLECTIONS.AUDITS}_${getCurrentPage()}`)
    });
    goToItem(response.headers["x-resource-id"] || "");
  };

  const addItemsFromTemplates = async (data: { templateIds: string[] }) => {
    return await baseController.addItemsFromTemplates(data, `overviews/templates`);
  };

  const copyItems = async ({ ids }: { ids: string[] }) => {
    await axiosInstance.post(`/audits/${getCurrentPage() === "templates" ? "templates" : "audits"}/copies`, {
      ids
    });
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo,
    addItemsFromTemplates,
    copyItems
  };
};

export default AssessmentResponseOverviewController;
