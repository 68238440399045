import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { isEUPlusAdequacyDecisionCountryOrArea } from "../../../handlers/countryHandler";
import { makeStyles } from "@material-ui/core";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: "10px"
  },
  alertContent: {
    marginBottom: "0px"
  }
}));

export default function ComplianceCheck({ externalDataRecipient }) {
  const { t } = useTranslation("compliance_check");
  const classes = useStyles();
  const safeguardsContractualClauses = "696f66849c6ae624e271";
  const noSafeguards = "5ba0de6450fc10429397";
  const contractTypeNotExistingId = "d79e40bdbc2a0bec4b00";
  const contractNotRequired = "c85722ab047be8a031b0";
  const subProcessorsUsed = externalDataRecipient.spUsage === true;

  const isBoolean = value => {
    if (typeof value === "boolean") {
      return true;
    }
    return false;
  };

  const checkLocationDataProcessing = function () {
    if (
      !externalDataRecipient ||
      !externalDataRecipient.dpLocations ||
      !externalDataRecipient.dpLocations?.length > 0
    ) {
      return "";
    }
    if (externalDataRecipient.dpLocations.find(location => !isEUPlusAdequacyDecisionCountryOrArea(location))) {
      return "non-eu";
    } else {
      return "eu/adequacy";
    }
  };

  const locationDataProcessing = checkLocationDataProcessing();
  //determine whether any data processing happens through non compliant sp/sub processor
  const anyDataProcessingOutsideEu =
    externalDataRecipient.dpNotExcludableLocations?.length > 0 || locationDataProcessing === "non-eu";
  const checkComplianceGeneralFields = function () {
    return (
      !externalDataRecipient.contractTypes.includes(contractTypeNotExistingId) &&
      externalDataRecipient.tomsProvided === true &&
      checkComplianceSubProcessorsFields()
    );
  };

  const checkComplianceSubProcessorsFields = function () {
    if (!isBoolean(externalDataRecipient.spUsage) || !isBoolean(externalDataRecipient.spRequirementApproval)) {
      return false;
      //only if subprocessors are used, subsequent fields are required
    } else if (externalDataRecipient.spUsage === true) {
      return (
        externalDataRecipient.spRequirementApproval === true &&
        externalDataRecipient.spListedInContract === true &&
        externalDataRecipient.spPAgreementsConcluded === true &&
        (externalDataRecipient.spExcludedDPOutsideEU === true ||
          (externalDataRecipient.spExcludedDPOutsideEU === false &&
            externalDataRecipient.spSscComplianceObligation === true))
      );
    } else if (externalDataRecipient.spUsage === false) {
      return externalDataRecipient.spRequirementApproval === true;
    } else {
      return false;
    }
  };

  const checkComplianceIfNonEUCountrySelected = function () {
    return (
      externalDataRecipient.transferAssessment === true &&
      externalDataRecipient.safeguards?.length > 0 &&
      !externalDataRecipient.safeguards.includes(noSafeguards) &&
      externalDataRecipient.derogations?.length > 0
    );
  };

  const createAlertContentMissingexternalDataRecipient = function () {
    if (
      externalDataRecipient.contractTypes?.includes(contractNotRequired) &&
      !externalDataRecipient.noContractRequiredReason
    ) {
      return (
        <ul className={classes.alertContent}>
          <li>{t("contract_reason_missing")}</li>
        </ul>
      );
    }

    return (
      <ul className={classes.alertContent}>
        {!externalDataRecipient.dpLocations?.length > 0 && (
          <li id="cc_locationMissing">{t("dataProcessing_location_missing")}</li>
        )}
        {!isBoolean(externalDataRecipient.tomsProvided) && <li id="tomMissing">{t("tomsProvided_missing")}</li>}
        {createAlertContentSubProcessorsMissingexternalDataRecipient()}
        {locationDataProcessing === "eu/adequacy" && !isBoolean(externalDataRecipient.dpExcludedDPOutsideEU) && (
          <li id="cc_excludedDataProcessingOutsideEUMissing">
            {t("dataProcessing_excludedDataProcessingOutsideEU_missing")}
          </li>
        )}
        {externalDataRecipient.dpExcludedDPOutsideEU === true &&
          !isBoolean(externalDataRecipient.dpEnsuredAllServicesProvided) && (
            <li id="cc_ensuredContractuallyAllServicesProvidedInEUMissing">
              {t("dataProcessing_ensuredContractuallyAllServicesProvidedInEU_missing")}
            </li>
          )}
        {externalDataRecipient.dpExcludedDPOutsideEU === false &&
          externalDataRecipient.dpNotExcludableLocations?.length > 0 && (
            <li id="cc_dataProcessingLocation2Missing">{t("dataProcessing_location_2_missing")}</li>
          )}
        {externalDataRecipient.dpEnsuredAllServicesProvided === false &&
          !externalDataRecipient.dpNotExcludableLocations?.length > 0 && (
            <li id="cc_dataProcessingLocation2Missing">{t("dataProcessing_location_2_missing")}</li>
          )}
        {anyDataProcessingOutsideEu && !externalDataRecipient.safeguards?.length > 0 && (
          <li id="cc_safeguardsMissing">{t("dataProcessing_safeguards_missing")}</li>
        )}
        {anyDataProcessingOutsideEu && !externalDataRecipient.derogations?.length > 0 && (
          <li id="cc_derogationsMissing">{t("derogations_missing")}</li>
        )}
        {anyDataProcessingOutsideEu && !isBoolean(externalDataRecipient.transferAssessment) && (
          <li id="cc_transferImpactMissing">{t("transfer_impact_missing")}</li>
        )}
      </ul>
    );
  };

  const createAlertContentSubProcessorsMissingexternalDataRecipient = function () {
    return (
      <>
        {!isBoolean(externalDataRecipient.spUsage) && (
          <li id="cc_subProcessorsUsageMissing">{t("subProcessor_usage_missing")}</li>
        )}
        {!isBoolean(externalDataRecipient.spRequirementApproval) && <li>{t("requirementApproval_missing")}</li>}
        {subProcessorsUsed && !isBoolean(externalDataRecipient.spListedInContract) && (
          <li id="cc_subProcessorsListedInContractMissing">{t("listedInContract_missing")}</li>
        )}
        {subProcessorsUsed && !isBoolean(externalDataRecipient.spPAgreementsConcluded) && (
          <li id="cc_subProcessorsProcessingAgreementsConcludedMissing">
            {t("processingAgreementsConcluded_missing")}
          </li>
        )}
        {subProcessorsUsed && !isBoolean(externalDataRecipient.spExcludedDPOutsideEU) && (
          <li id="cc_subProcessorsExcludedDataProcessingOutsideEUMissing">
            {t("excludedDataProcessingOutsideEU_missing")}
          </li>
        )}
        {subProcessorsUsed &&
          externalDataRecipient.spExcludedDPOutsideEU === false &&
          !isBoolean(externalDataRecipient.spSscComplianceObligation) && (
            <li id="cc_subProcessorsSscComplianceObligationMissing">
              {t("dataProcessing_sscComplianceObligation_missing")}
            </li>
          )}
      </>
    );
  };

  const createAlertContentexternalDataRecipientSetToNoNone = function () {
    return (
      <ul className={classes.alertContent}>
        {anyDataProcessingOutsideEu && externalDataRecipient.safeguards.includes(noSafeguards) && (
          <li id="cc_safeguardsNone">{t("safeguards_none")}</li>
        )}
        {anyDataProcessingOutsideEu && externalDataRecipient?.transferAssessment === false && (
          <li id="cc_transferImpactAssessmentNo">{t("transfer_impact_assessment_no")}</li>
        )}
        {externalDataRecipient.tomsProvided === false && <li id="cc_tomsNo">{t("toms_no")}</li>}
        {createAlertContentSubProcessorsexternalDataRecipientSetToNo()}
      </ul>
    );
  };

  const createAlertContentSubProcessorsexternalDataRecipientSetToNo = function () {
    return (
      <>
        {externalDataRecipient.spRequirementApproval === false && (
          <li id="cc_subProcessorsRequirementApprovalNo">{t("subProcessor_requirementApproval_no")}</li>
        )}
        {subProcessorsUsed && externalDataRecipient.spListedInContract === false && (
          <li id="cc_subProcessorsListedInContractNo">{t("subProcessor_listedInContract_no")}</li>
        )}
        {subProcessorsUsed && externalDataRecipient.spPAgreementsConcluded === false && (
          <li id="cc_subProcessorsProcessingAgreementsConcludedNo">
            {t("subProcessor_processingAgreementsConcluded_no")}
          </li>
        )}
        {subProcessorsUsed && externalDataRecipient.spSscComplianceObligation === false && (
          <li id="cc_subProcessorsExcludedDataProcessingOutsideEUNo">
            {t("subProcessor_excludedDataProcessingOutsideEU_no")}
          </li>
        )}
      </>
    );
  };

  const getAlert = function (id, severity, text, children) {
    return (
      <div id={id}>
        <CustomAlert className={classes.alert} severity={severity}>
          <Typography variant="inherit" component="span">
            {parse(DOMPurify.sanitize(text))}
          </Typography>
          {children}
        </CustomAlert>
      </div>
    );
  };

  //check GM-1994 for logic
  //Case: no-contract-type-selected
  if (!externalDataRecipient.contractTypes?.length > 0) {
    return getAlert("cc_missingContractType", "warning", t("compliance_check_no_contract_selected"));
    //Case: contract-type-is-“no contract required”
  } else if (externalDataRecipient.contractTypes?.includes(contractNotRequired)) {
    if (externalDataRecipient.noContractRequiredReason) {
      return getAlert("cc_compliant", "success", t("compliance_check_compliant"));
    } else {
      const alertMessage = createAlertContentMissingexternalDataRecipient();
      return getAlert("cc_missingEDTFields", "warning", t("compliance_check_incomplete_info"), alertMessage);
    }
    //Case: contract-type-is-“no contract existing”
  } else if (externalDataRecipient.contractTypes?.includes(contractTypeNotExistingId)) {
    return getAlert("cc_contractDoesNotExist", "error", t("compliance_check_no_contract"));
    //Case: safeguard-is-“standard-contractual-clauses-(old set)”
  } else if (
    externalDataRecipient.safeguards &&
    externalDataRecipient.safeguards?.includes(safeguardsContractualClauses)
  ) {
    return getAlert("cc_OldSafeguard", "warning", t("compliance_check_safeguard_old_set"));
    //Case: safeguards-derogations-toms-subprocessors-or-and-risk-assessment-set-to-none
  } else if (
    externalDataRecipient?.tomsProvided === false ||
    (anyDataProcessingOutsideEu &&
      (externalDataRecipient.safeguards?.includes(noSafeguards) ||
        externalDataRecipient.transferAssessment === false)) ||
    externalDataRecipient.spRequirementApproval === false ||
    (subProcessorsUsed &&
      (externalDataRecipient.spListedInContract === false ||
        externalDataRecipient.spPAgreementsConcluded === false ||
        externalDataRecipient.spSscComplianceObligation === false))
  ) {
    const alertMessage = createAlertContentexternalDataRecipientSetToNoNone();
    return getAlert("cc_answerNoNone", "error", t("compliance_check_answer_no_none"), alertMessage);
    //Case: country-is-eu-country-or-non-eu-country-with-ade-decision-no-external-data-processing
  } else if (
    locationDataProcessing === "eu/adequacy" &&
    checkComplianceGeneralFields() &&
    externalDataRecipient.dpExcludedDPOutsideEU === true &&
    externalDataRecipient.dpEnsuredAllServicesProvided === true
  ) {
    return getAlert("cc_compliant", "success", t("compliance_check_compliant"));
    //Case: country-is-eU-country-or-non-eu-country-with-ade-decision-with-external-data-processing
  } else if (
    locationDataProcessing === "eu/adequacy" &&
    checkComplianceGeneralFields() &&
    checkComplianceIfNonEUCountrySelected() &&
    ((externalDataRecipient.dpExcludedDPOutsideEU === false &&
      externalDataRecipient.dpNotExcludableLocations?.length > 0) ||
      (externalDataRecipient.dpExcludedDPOutsideEU === true &&
        externalDataRecipient.dpEnsuredAllServicesProvided === false &&
        externalDataRecipient.dpNotExcludableLocations?.length > 0))
  ) {
    return getAlert("cc_compliant", "success", t("compliance_check_compliant"));
    //Case: country-is-non-eu-country-without-ade-decision
  } else if (
    locationDataProcessing === "non-eu" &&
    checkComplianceGeneralFields() &&
    checkComplianceIfNonEUCountrySelected()
  ) {
    return getAlert("cc_compliant", "success", t("compliance_check_compliant"));
    //Case: required-externalDataRecipient-missing
  } else {
    const alertMessage = createAlertContentMissingexternalDataRecipient();
    return getAlert("cc_missingEDTFields", "warning", t("compliance_check_incomplete_info"), alertMessage);
  }
}
