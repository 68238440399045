import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { DateField } from "../../../../../../components/DateField";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  repetition: {
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: 0
    },
    "& .days .MuiIconButton-root": {
      fontSize: "14px",
      width: "40px",
      height: "40px",
      background: "#eee",
      color: "#666",
      margin: "0 4px",
      textAlign: "center",
      "&:hover": {
        color: theme.palette.primary.dark,
        background: theme.palette.blue[50]
      },
      "&.selected": {
        background: theme.palette.primary.dark,
        color: theme.palette.blue[50]
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 14px"
    },
    "& .disabled": {
      background: theme.palette.grey[100]
    }
  }
}));

interface DayOfWeekButtonProps {
  readonly day: string;
  readonly selected: boolean;
  readonly onClick: (day: string) => void;
}
const DayOfWeekButton = ({ day, selected, onClick }: DayOfWeekButtonProps) => {
  const { t } = useTranslation("task_details");
  const opnClickCallback = useCallback(() => {
    onClick(day);
  }, [day, onClick]);
  return (
    <Tooltip title={t("task_details:" + day)} key={day}>
      <IconButton size="medium" onClick={opnClickCallback} className={selected ? "selected" : ""}>
        {t(day.toLowerCase())}
      </IconButton>
    </Tooltip>
  );
};

interface TaskRepetitionModalProps {
  readonly endTime: Date | null;
  readonly startTime: Date | null;
  readonly dayOfWeek: string | null;
  readonly type: string | null;
  readonly onChangeRepetition: (data: {
    recurrenceType: string;
    dayOfWeek: string | null;
    startTime: Date | null;
    endTime: Date | null;
  }) => void;
  readonly active: boolean;
  readonly setActive: (active: boolean) => void;
}
const TaskRepetitionModal = ({
  endTime,
  startTime,
  dayOfWeek,
  type,
  onChangeRepetition,
  active,
  setActive
}: TaskRepetitionModalProps) => {
  const { t } = useTranslation("task_details");
  const cls = useStyles();

  const [recurrenceType, setRecurrenceType] = useState(type || "MONTHLY");
  const [recurrenceDayOfWeek, setRecurrenceDayOfWeek] = useState(dayOfWeek || "MONDAY");
  const [startType, setStartType] = useState(!startTime ? "now" : "date");
  const [endType, setEndType] = useState(!endTime ? "never" : "date");
  const [startRecurrenceTime, setStartRecurrenceTime] = useState(startTime);
  const [endRecurrenceTime, setEndRecurrenceTime] = useState(endTime);

  useEffect(() => {
    if (startType === "date") {
      setStartRecurrenceTime(startTime || new Date());
    } else {
      setStartRecurrenceTime(null);
    }
  }, [startTime, startType]);

  useEffect(() => {
    if (endType === "date") {
      setEndRecurrenceTime(
        endTime ||
          (startRecurrenceTime
            ? new Date(new Date(startRecurrenceTime).setDate(new Date(startRecurrenceTime).getDate() + 1))
            : new Date())
      );
    } else {
      setEndRecurrenceTime(null);
    }
  }, [endType, endTime, startRecurrenceTime]);

  const validator = useMemo(() => {
    if (
      endRecurrenceTime &&
      startRecurrenceTime &&
      new Date(endRecurrenceTime).getTime() < new Date(startRecurrenceTime).getTime()
    ) {
      return false;
    }
    if (
      recurrenceType !== type ||
      recurrenceDayOfWeek !== dayOfWeek ||
      startRecurrenceTime !== startTime ||
      endRecurrenceTime !== endTime
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    endRecurrenceTime,
    startRecurrenceTime,
    recurrenceType,
    type,
    recurrenceDayOfWeek,
    dayOfWeek,
    startTime,
    endTime
  ]);

  const onChangeRecurrenceType = useCallback(event => {
    setRecurrenceType(event.target.value);
  }, []);

  const onClickRecurrenceDayOfWeek = useCallback(day => {
    setRecurrenceDayOfWeek(day);
  }, []);

  const onChangeStartType = useCallback(event => {
    setStartType(event.target.value);
  }, []);

  const onChangeEndType = useCallback(event => {
    setEndType(event.target.value);
  }, []);

  const onCloseConfirmationModal = useCallback(() => {
    setActive(false);
  }, [setActive]);

  const days = useMemo(() => ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"], []);
  const bodyEl = useMemo(
    () => (
      <Box mt={4} mb={4} px={2} className={cls.repetition}>
        <Box alignItems={"center"} display="flex">
          <Box width={1 / 4}>{t("recurrence_type")}</Box>
          <Box width={150}>
            <FormControl variant="outlined">
              <Select value={recurrenceType} onChange={onChangeRecurrenceType}>
                {["DAILY", "WEEKLY", "MONTHLY", "QUARTERLY", "HALFYEARLY", "YEARLY", "BIYEARLY"].map(i => (
                  <MenuItem key={i} value={i}>
                    {t(i.toLowerCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box flex={1} />
        </Box>
        {recurrenceType === "WEEKLY" && (
          <Box alignItems={"center"} display="flex" mt={5}>
            <Box width={1 / 4}>
              <Typography>{t("day_of_week")}</Typography>
            </Box>
            <Box className="days">
              {days.map(day => (
                <DayOfWeekButton
                  key={day}
                  day={day}
                  onClick={onClickRecurrenceDayOfWeek}
                  selected={day === recurrenceDayOfWeek}
                />
              ))}
            </Box>
            <Box flex={1} />
          </Box>
        )}
        <Box mt={5} display="flex">
          <Box mt={1} width={1 / 2}>
            <Typography>{t("startHeader")}</Typography>
            <Box mt={1} />
            <FormControl component="fieldset">
              <RadioGroup aria-label="start" name="start" value={startType} onChange={onChangeStartType}>
                <Box display="flex" alignItems={"center"}>
                  <Box>
                    <FormControlLabel value="now" control={<Radio color="primary" />} label={t("radio_none_start")} />
                  </Box>
                </Box>
                <Box display="flex" alignItems={"center"}>
                  <Box>
                    <FormControlLabel value="date" control={<Radio color="primary" />} label={t("radio_date_start")} />
                  </Box>
                  <Box width={160}>
                    <DateField
                      disabled={startType !== "date"}
                      disablePast={true}
                      date={startRecurrenceTime}
                      onDateChange={setStartRecurrenceTime}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mt={1} width={1 / 2}>
            <Typography>{t("endHeader")}</Typography>
            <Box mt={1} />
            <FormControl component="fieldset">
              <RadioGroup color="primary" aria-label="end" name="end" value={endType} onChange={onChangeEndType}>
                <Box display="flex" alignItems={"center"}>
                  <Box width={1 / 4}>
                    <FormControlLabel value="never" control={<Radio color="primary" />} label={t("radio_none_end")} />
                  </Box>
                </Box>
                <Box display="flex" alignItems={"center"}>
                  <Box>
                    <FormControlLabel value="date" control={<Radio color="primary" />} label={t("radio_date_end")} />
                  </Box>
                  <Box width={160}>
                    <DateField
                      disabled={endType !== "date"}
                      disablePast={true}
                      // id="date-picker-inline"
                      date={endRecurrenceTime}
                      onDateChange={setEndRecurrenceTime}
                      minDate={startRecurrenceTime || new Date()}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
    ),
    [
      cls.repetition,
      t,
      recurrenceType,
      onChangeRecurrenceType,
      days,
      startType,
      onChangeStartType,
      startRecurrenceTime,
      endType,
      onChangeEndType,
      endRecurrenceTime,
      onClickRecurrenceDayOfWeek,
      recurrenceDayOfWeek
    ]
  );
  const actionsEl = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("cancel"),
        variant: "outlined" as "text" | "outlined" | "contained" | undefined,
        color: "primary" as "primary" | "inherit" | "secondary" | "default",
        size: "medium" as "medium" | "small" | "large" | undefined,
        onClick: () => {
          if (type) {
            setRecurrenceType(type);
          }
          if (dayOfWeek) {
            setRecurrenceDayOfWeek(dayOfWeek);
          }
          setStartRecurrenceTime(startTime);
          setEndRecurrenceTime(endTime);
          setStartType(!startTime ? "now" : "date");
          setEndType(!endTime ? "never" : "date");
          setActive(false);
        }
      },
      {
        confirmButton: true,
        title: t("ok"),
        variant: "contained" as "text" | "outlined" | "contained" | undefined,
        color: "primary" as "primary" | "inherit" | "secondary" | "default",
        size: "medium" as "medium" | "small" | "large" | undefined,
        disabled: validator === false,
        onClick: () => {
          onChangeRepetition({
            recurrenceType,
            dayOfWeek: recurrenceDayOfWeek,
            startTime: startType === "now" ? null : startRecurrenceTime,
            endTime: endType === "never" ? null : endRecurrenceTime
          });
          setActive(false);
        }
      }
    ],
    [
      t,
      validator,
      type,
      dayOfWeek,
      startTime,
      endTime,
      onChangeRepetition,
      recurrenceType,
      recurrenceDayOfWeek,
      startType,
      startRecurrenceTime,
      endType,
      endRecurrenceTime,
      setActive
    ]
  );

  return (
    <ConfirmationModal
      modalText=""
      modalOpen={active}
      onClose={onCloseConfirmationModal}
      modalTitle={t("repetition")}
      modalBody={bodyEl}
      buttons={actionsEl}
      variant="info"
    />
  );
};

export { TaskRepetitionModal };
