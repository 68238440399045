import i18n from "../../../i18n";

export const getProcessStatusIconProps = function (processStatus: string) {
  switch (processStatus) {
    case "rejected":
      return {
        title: i18n.t("processes_overview:status_rejected"),
        icon: "ErrorOutline"
      };
    case "review":
      return {
        title: i18n.t("processes_overview:status_review"),
        icon: "ProcessInReviewIcon"
      };
    case "approved":
      return {
        title: i18n.t("processes_overview:status_approved"),
        icon: "ApprovedIcon"
      };
    default:
      return {
        title: i18n.t("processes_overview:status_edit"),
        icon: "ProcessEditIcon"
      };
  }
};
