import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import { usePathName } from "../router/router-custom-hooks";
import { getUserIdColor, listenOnWherePeopleAre, WhereIAm } from "../handlers/whereIAmHandler";
import { useIsFeaturePresent } from "../../hook/useIsFeaturePresent";
import { FEATURES } from "../features";

export interface WhereIAmContextType {
  readonly whereIAmData: Record<string, WhereIAm>;
  readonly updateSelf: (whereIAm: Pick<WhereIAm, "path" | "questionId">) => void;
}

export const WhereIAmContext = createContext<WhereIAmContextType>({
  whereIAmData: {},
  updateSelf: () => {
    // nothing
  }
});

export const WhereIAmContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useAuthentication();
  const path = usePathName();
  const whereIAmEnabled = useIsFeaturePresent(FEATURES.WHERE_I_AM);

  const [whereIAmData, setWhereIAmData] = useState<Record<string, WhereIAm>>({});
  useEffect(() => {
    if (!auth?.tenantId) {
      return;
    }
    if (!whereIAmEnabled) {
      return;
    }

    return listenOnWherePeopleAre({
      tenantId: auth.tenantId,
      currentPath: path,
      callback: setWhereIAmData
    });
  }, [auth?.tenantId, path, whereIAmEnabled]);

  const updateSelf = useCallback(
    (whereIAm: Pick<WhereIAm, "path" | "questionId">) => {
      if (!auth?.uid) {
        return;
      }
      setWhereIAmData(prev => ({
        ...prev,
        [auth.uid]: {
          userId: auth.uid,
          path: whereIAm.path,
          heartbeat: new Date(),
          questionId: whereIAm.questionId,
          hslColor: getUserIdColor(auth.uid)
        }
      }));
    },
    [auth?.uid]
  );

  return (
    <WhereIAmContext.Provider
      value={{
        whereIAmData,
        updateSelf
      }}
    >
      {children}
    </WhereIAmContext.Provider>
  );
};

export const useWhereIAmContext = () => useContext(WhereIAmContext);
