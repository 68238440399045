import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { Autocomplete } from "@material-ui/lab";
import { Box, Checkbox, TextField } from "@material-ui/core";
import { COLLECTION_TYPES } from "app/collections";
import { TemplateItem } from "../controllers/overviewBaseController";
import useSWR from "swr";

type OverviewTemplateDialogProps = {
  collection: COLLECTION_TYPES;
  passedTemplates: TemplateItem[] | undefined;
  getTemplates: () => Promise<TemplateItem[]>;
  onClose: () => void;
  onConfirm: (templateIds: string[]) => void;
};

export const OverviewTemplateDialog = ({
  collection,
  passedTemplates,
  getTemplates,
  onClose,
  onConfirm
}: OverviewTemplateDialogProps) => {
  const { t } = useTranslation("overview");
  const { data } = useSWR(passedTemplates ? null : [collection, "templates"], () => getTemplates());
  const templates = useMemo(() => passedTemplates || data || [], [data, passedTemplates]);

  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const onConfirmCallback = useCallback(() => {
    onConfirm(selectedTemplates.map(({ id }) => id));
  }, [onConfirm, selectedTemplates]);

  const createBlankButtonEl: ConfirmationModalButtonProps = useMemo(
    () => ({
      confirmButton: true,
      title: t("createBlank"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: onConfirmCallback
    }),
    [onConfirmCallback, t]
  );

  const createFromTemplateButtonEl: ConfirmationModalButtonProps = useMemo(
    () => ({
      confirmButton: true,
      title: t("createFromTemplate"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: onConfirmCallback
    }),
    [onConfirmCallback, t]
  );

  const cancelButtonEl: ConfirmationModalButtonProps = useMemo(
    () => ({
      confirmButton: false,
      title: t("cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: onClose
    }),
    [onClose, t]
  );

  const buttons = useMemo(
    () => [cancelButtonEl, selectedTemplates.length === 0 ? createBlankButtonEl : createFromTemplateButtonEl],
    [cancelButtonEl, createBlankButtonEl, createFromTemplateButtonEl, selectedTemplates.length]
  );

  const onChangeAutocomplete = useCallback((event, newValues) => setSelectedTemplates(newValues), []);

  const singleCategory = useMemo(() => {
    const categories = new Set(templates.map(({ category }) => category));
    return categories.size === 1;
  }, [templates]);
  const groupBy = useCallback(option => t("overviewTemplateCategory:" + option.category, option.category), [t]);
  const getOptionLabel = useCallback(option => option.name, []);
  const renderInput = useCallback(params => <TextField {...params} label={t("search")} variant="outlined" />, [t]);

  const renderOptionEl = useCallback(
    (option, { selected }) => (
      <React.Fragment>
        <Checkbox color={"primary"} style={{ marginRight: 8 }} checked={selected} />
        {getOptionLabel(option)}
      </React.Fragment>
    ),
    [getOptionLabel]
  );

  const autocompleteEl = (
    <Box my={3}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id="overview-template-dialog"
        options={templates}
        groupBy={singleCategory ? undefined : groupBy}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        renderOption={renderOptionEl}
        onChange={onChangeAutocomplete}
        noOptionsText={t("no_options_templates")}
      />
    </Box>
  );

  return (
    <ConfirmationModal
      variant="info"
      modalOpen={true}
      onClose={onClose}
      modalTitle={t(`${collection}_template_dialog_title`)}
      modalText={t(`${collection}_template_dialog_text`)}
      buttons={buttons}
      modalBody={autocompleteEl}
    />
  );
};
