export const SWR_KEYS = {
  ResourceProvider: "ResourceProvider",
  auditActionTasks: "auditActionTasks",
  user: "user",
  comments: "comments",
  ProcessPeriodsPage: "ProcessPeriodsPage",
  ProcessRecipientsPage: "ProcessRecipientsPage",
  DSRPublicLinks: "DSRPublicLinks",
  multilingual: "multilingual"
};
