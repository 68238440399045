import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { detailType } from "./taskApi";

export type RecurrenceType = "DAILY" | "WEEKLY" | "MONTHLY" | "QUARTERLY" | "HALFYEARLY" | "YEARLY" | "BIYEARLY";
export type DayOfWeek = "SUNDAY" | "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY";

export interface Time {
  readonly hours: number;
  readonly minutes: number;
  readonly seconds: number;
}

export interface DayMonthYear {
  readonly day: number;
  readonly month: number;
  readonly year: number;
}

export interface RecurringTask {
  readonly id: string;
  readonly tenantId: string;
  readonly creatorUID: string;
  readonly recurrenceType: RecurrenceType;
  readonly timeOfDay: Time | null;
  readonly dayOfWeek: DayOfWeek;
  readonly dayOfYear: DayMonthYear | null;
  readonly startTime: Date | null;
  readonly endTime: Date | null;
  readonly title: string | null;
  readonly description: string | null;
  readonly documentId: string | null;
  readonly dueAt: Date | null;
  readonly assigneeUID: string | null;
  readonly commentId: string | null;
  readonly pageId: string | null;
  readonly priority: string | null;
  readonly collection: string | null;
  readonly questionId: string | null;
  readonly type: string | null;
  readonly message: string | null;
  readonly participants: string[];
  readonly labels: string[];
  readonly notes: string | null;
  readonly section: string | null;
  readonly active: boolean | null;
  readonly createdAt: Date | null;
  readonly updatedAt: Date | null;
  readonly detailType?: detailType;
}

export interface NewRecurringTask {
  readonly recurrenceType?: string;
  readonly timeOfDay?: Time | null;
  readonly dayOfWeek?: string | null;
  readonly dayOfYear?: DayMonthYear | null;
  readonly startTime?: string | null;
  readonly endTime?: string | null;
  readonly title?: string;
  readonly type?: string;
  readonly description?: string | null;
  readonly documentId?: string | null;
  readonly dueAt?: string | null;
  readonly creatorUID?: string | null;
  readonly assigneeUID?: string | null;
  readonly commentId?: string | null;
  readonly pageId?: string | null;
  readonly priority?: string | null;
  readonly collection?: string | null;
  readonly questionId?: string | null;
  readonly message?: string | null;
  readonly notes?: string | null;
  readonly participants?: string[];
  readonly labels?: string[];
  readonly section?: string | null;
  readonly seen?: boolean | null;
}

export interface UpdateRecurringTask {
  readonly ownerUID?: string | null;
  readonly recurrenceType?: string | null;
  readonly timeOfDay?: Time | null;
  readonly dayOfWeek?: string | null;
  readonly dayOfYear?: DayMonthYear | null;
  readonly startTime?: string | null;
  readonly endTime?: string | null;
  readonly title?: string | null;
  readonly description?: string | null;
  readonly documentId?: string | null;
  readonly dueAt?: string | null;
  readonly updatedAt?: string | null;
  readonly assigneeUID?: string | null;
  readonly commentId?: string | null;
  readonly pageId?: string | null;
  readonly priority?: string | null;
  readonly collection?: string | null;
  readonly questionId?: string | null;
  readonly message?: string | null;
  readonly notes?: string | null;
  readonly participants?: string[];
  readonly labels?: string[];
  readonly type?: string | null;
  readonly section?: string | null;
}

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.taskUrl}/api/v1/recurringTasks`
});

export async function createRecurringTaskApi(data: NewRecurringTask) {
  const response = await axiosInstance.post(`/`, data);
  return response.headers["x-resource-id"] || "";
}

export async function getRecurringTaskApi(id: string): Promise<RecurringTask> {
  const response = await axiosInstance.get(`/${id}`);
  return response?.data;
}

export async function getRecurringTasksApi(filterParams: {
  readonly collection?: string;
  readonly documentId?: string;
  readonly type?: string;
  readonly questionId?: string;
}): Promise<RecurringTask[]> {
  const response = await axiosInstance.get(`/`, {
    params: filterParams
  });
  return response?.data?.recurringTasks;
}

export async function updateRecurringTaskApi(taskId: string, payload: UpdateRecurringTask) {
  const response = await axiosInstance.patch(`/${taskId}`, payload);
  return response?.data;
}

export async function deleteRecurringTaskApi(id: string) {
  return axiosInstance.delete("/" + id);
}

export async function activateRecurringTaskApi(id: string) {
  return axiosInstance.post(`/${id}/activate`);
}

export async function deactivateRecurringTaskApi(id: string) {
  return axiosInstance.post(`/${id}/deactivate`);
}
