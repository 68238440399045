import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.owlitUrl}/api`
});

export interface SearchResultDTO {
  readonly documentId?: string;
  readonly title?: string;
  readonly imageData?: string | null;
  readonly snippets?: string[];
  readonly authors?: string[];
  readonly breadcrumbs?: string[];
  readonly decidedAt?: string | null;
  readonly publishedAt?: string | null;
}

export interface AvailableSearchFilterDTO {
  readonly title?: string;
  readonly id?: string;
  readonly count?: number;
}

export interface SearchResultsDTO {
  readonly count?: number;
  readonly hits?: SearchResultDTO[];
  readonly filter?: {
    readonly lawTaxonomy?: AvailableSearchFilterDTO[];
    readonly publicationType?: AvailableSearchFilterDTO[];
  };
}

export async function searchOwlit(
  searchTerm: string,
  pageNumber: number | null,
  filters: string[],
  abortController: AbortController
): Promise<SearchResultsDTO> {
  const params = new URLSearchParams();
  params.append("term", searchTerm);
  params.append("pageNumber", pageNumber ? String(pageNumber) : "1");
  filters.forEach(filter => params.append("filters", filter));

  const response = await axiosInstance.get("/search", {
    params: params,
    signal: abortController.signal
  });
  return response.data || {};
}

export const getRedirectToDocument = async (documentId: string): Promise<string> => {
  const response = await axiosInstance.get(`/redirects/documents/${documentId}`);
  return response.data?.redirectLink || "";
};
