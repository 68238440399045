import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../../../contexts/meta-view-context";
import { Box } from "@mui/material";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

const AnswersetConfirmation = ({
  readOnly,
  onCheckConfirmation
}: {
  readonly readOnly: boolean;
  readonly onCheckConfirmation: () => void;
}) => {
  const { t } = useTranslation("assessment_answerset");
  const { setInfo } = useMetaView();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitleConfirmation"),
        text: t("enteringInfoCardTextConfirmation")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const onCheckedChange = useCallback(() => {
    setIsConfirm(!isConfirm);
    onCheckConfirmation();
  }, [isConfirm, onCheckConfirmation]);

  return (
    <Box>
      <Box>
        <Typography align="left" variant="h5" style={{ lineHeight: "32px", fontSize: "20px" }}>
          {t("audit_details:confirmation")}
        </Typography>
      </Box>
      <Box>
        <Typography align="left" variant="inherit">
          {t("audit_details:confirmationText")}
        </Typography>
      </Box>

      <Box mt={4} mb={4}>
        <FormControlLabel
          disabled={readOnly}
          control={
            <Checkbox
              checked={readOnly || isConfirm}
              onChange={onCheckedChange}
              name="confirmationPledge"
              color="primary"
            />
          }
          label={t("audit_details:confirmationPledge")}
        />
      </Box>
    </Box>
  );
};

export default AnswersetConfirmation;
