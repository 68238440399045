import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Typography from "@material-ui/core/Typography";
import { calculateBygoneTime } from "../BygoneTime";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box, CardActions, Collapse, IconButton, Tooltip } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) => ({
  userName: {
    fontSize: 11,
    color: theme.palette.grey[400]
  },
  fieldName: {
    fontSize: 12,
    wordBreak: "break-word",
    whiteSpace: "pre-line"
  },
  date: {
    fontSize: 12
  },
  dotColorBlue: {
    backgroundColor: theme.palette.primary.main
  },
  dotColorOrange: {
    backgroundColor: theme.palette.warning.dark
  },
  dotColorGreen: {
    backgroundColor: theme.palette.secondary.main
  },
  dotColorGray: {
    backgroundColor: theme.palette.grey[400]
  },
  badgeColor: {
    "& .MuiBadge-colorPrimary": {
      backgroundColor: theme.palette.grey[600]
    }
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  fixFlex: {
    flex: "1 0 calc((100% - 12px) / 2)"
  }
}));

export interface TimeLineElementProps {
  readonly date: Date;
  readonly title: string;
  readonly subtitle: string;
  readonly dotColor: string;
  readonly bottomConnector: boolean;
  readonly repeated: number;
  readonly moreInfoTitle?: string;
  readonly moreInfoSubtitle?: string;
}

function TimeLineElement({
  date,
  title,
  subtitle,
  dotColor,
  bottomConnector,
  repeated,
  moreInfoTitle,
  moreInfoSubtitle
}: TimeLineElementProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const timelineClasses = useMemo(() => ({ root: getDotColorClass(dotColor, classes) }), [dotColor, classes]);

  const [expanded, setExpanded] = useState(false);
  const onExpandClicked = useCallback(() => setExpanded(expanded => !expanded), []);

  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.fixFlex}>
        <Typography className={classes.date}>{calculateTime(date, t)}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot classes={timelineClasses} />
        {!bottomConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className={classes.fixFlex}>
        <Badge
          badgeContent={
            repeated && (
              <Tooltip title={t("questionnaireHistory:repeated_info", { times: repeated })}>
                <span>{repeated}</span>
              </Tooltip>
            )
          }
          color="primary"
          className={classes.badgeColor}
          overlap="rectangular"
        >
          <Box>
            <Card>
              <CardContent>
                <Typography className={classes.fieldName}>{title}</Typography>
                <Typography className={classes.userName}>{subtitle}</Typography>
              </CardContent>
              {moreInfoTitle && (
                <>
                  <CardActions>
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <Tooltip title={t("questionnaireHistory:more_info")}>
                        <Box>
                          <IconButton
                            aria-label="show more"
                            aria-expanded={expanded}
                            size="small"
                            onClick={onExpandClicked}
                            className={clsx(classes.expand, {
                              [classes.expandOpen]: expanded
                            })}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography className={classes.fieldName}>{moreInfoTitle}</Typography>
                      <Typography className={classes.userName}>{moreInfoSubtitle}</Typography>
                    </CardContent>
                  </Collapse>
                </>
              )}
            </Card>
          </Box>
        </Badge>
      </TimelineContent>
    </TimelineItem>
  );
}

export default React.memo(TimeLineElement);

const calculateTime = (date: Date, t: TFunction) =>
  calculateBygoneTime(date, (key: string, param: string) => t("date:" + key, param));

const getDotColorClass = (dotColor: string, classes: ReturnType<typeof useStyles>) => {
  switch (dotColor) {
    case "orange":
      return classes.dotColorOrange;
    case "blue":
      return classes.dotColorBlue;
    case "green":
      return classes.dotColorGreen;
    default:
      return classes.dotColorGray;
  }
};
