import { getTranslationAndSourceLanguage } from "../api/deepLApi";

export function getDeepLTranslation({
  targetLanguage,
  textToBeTranslated,
  sourceLanguage
}: {
  readonly targetLanguage: string;
  readonly textToBeTranslated: string;
  readonly sourceLanguage: string;
}) {
  return getTranslationAndSourceLanguage(targetLanguage, textToBeTranslated, sourceLanguage);
}
