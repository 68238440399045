const getDepartmentName = (departments, departmentIdOrIds) => {
  if (!Array.isArray(departments)) {
    return "";
  }

  const departmentId = Array.isArray(departmentIdOrIds) ? departmentIdOrIds[0] : departmentIdOrIds;

  const foundDepartment = departments.find(orgUnit => orgUnit.id === departmentId);
  return foundDepartment?.name || foundDepartment?.data?.name || "";
};

export { getDepartmentName };
