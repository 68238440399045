import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, To, useLocation, useNavigate, useParams } from "react-router-dom";
import { HasFeatureToggleOn, HasPageAccess, HasResourcesToggledOn, ShowPageIf } from "./router-filters";
import ProcessOverview from "../pages/processes/overview/ProcessesOverview";
import DataBreachesOverview from "../pages/data-breaches/overview/DataBreachesOverview";
import DataSubjectRequestOverview from "../pages/data-subject-requests/overview/DataSubjectRequestOverview";
import UsersOverview from "../pages/users/overview/UsersOverview";
import Login from "../pages/authentication/Login";
import ResetPassword from "../pages/authentication/ResetPassword";
import OrganisationOverview from "../pages/organization/OrganizationOverview";
import Questionnaire from "../pages/questionnaires/Questionnaire";
import NotificationsOverview from "../pages/notifications/NotificationsOverview";
import ServiceProvider from "../pages/service-providers/ExternalRecipient";
import Dashboard from "../pages/dashboard/Dashboard";
import DocumentCenter from "../pages/documents/overview/DocumentOverview";
import PageTemplate from "../pages/shared/PageTemplate/PageTemplate";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import ResourcesSelectionPage from "../pages/resources/ResourcesSelectionPage";
import DataSubjectRequestsGeneralPage from "../pages/data-subject-requests/DataSubjectRequestsGeneralPage";
import DataSubjectRequestsTasksPage from "../pages/data-subject-requests/DataSubjectRequestsTasksPage";
import DataSubjectRequestsDataPage from "../pages/data-subject-requests/DataSubjectRequestsDataPage";
import { AccountSettings } from "../pages/account-settings/AccountSettings";
import { AccountSettingsMFAFlow } from "../pages/account-settings/mfa-flow/AccountSettingsMFAFlow";
import { AccountSettingsChangePasswordFlow } from "../pages/account-settings/change-password-flow/AccountSettingsChangePasswordFlow";
import DataBreachesAuthorityNotificationPage from "../pages/data-breaches/DataBreachesAuthorityNotificationPage";
import DataBreachesGeneralPage from "../pages/data-breaches/DataBreachesGeneralPage";
import { EntityMetaViewProvider } from "app/contexts/meta-view-context";
import DataBreachesTasksPage from "../pages/data-breaches/DataBreachesTasksPage";
import { FEATURES, USER_FEATURE_IDS } from "../features";
import DataSubjectRequestsSubmission from "../pages/data-subject-requests/DataSubjectRequestSubmissionPage";
import { RiskGeneralPage } from "../pages/risks/RiskGeneralPage";
import { RiskFirstAssessmentPage } from "../pages/risks/RiskFirstAssessmentPage";
import { RiskSecondAssessmentPage } from "../pages/risks/RiskSecondAssessmentPage";
import { RiskTreatmentPage } from "../pages/risks/RiskTreatmentPage";
import { RiskProvider, useRisk } from "../contexts/risk-context";
import TasksOverview from "../pages/tasks/overview/TaskOverview";
import { TasksProvider } from "../contexts/tasks-context";
import { Importer } from "../pages/importer/Importer";
import RiskOverview from "../pages/risks/overview/RiskOverview";
import CreateAndEditUser from "../pages/users/CreateAndEditUser";
import CreateAndEditGroup from "../pages/groups/CreateAndEditGroup";
import CreateAndEditRole from "../pages/roles/CreateAndEditRole";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import SSOLoginCallback from "../pages/authentication/SSOLoginCallback";
import Tenants from "../pages/authentication/Tenants";
import DeletionConceptOverview from "../pages/deletion-concept/overview/DeletionConceptOverview";
import WebsiteOverview from "../pages/website/WebsiteOverview";
import { TenantDirectLink } from "../pages/authentication/TenantDirectLink";
import { ResourceTypeOverviewPage } from "../pages/resources/ResourceTypeOverviewPage";
import AssetsOverview from "../pages/assets/overview/AssetsOverview";
import { AuditsOverview } from "../pages/audits/overview/AuditsOverview";
import { DataLocationOverviewPage } from "../pages/datalocations/DataLocationOverviewPage";
import { AuditRemarksProvider } from "app/contexts/remarks-context";
import { DataSubjectRequestProvider } from "../contexts/dsr-context";
import { PAGE_ACCESS } from "../defaultRolePageAccess";
import { TomOverview } from "app/pages/toms/overview/TomOverview";
import { importPAJob } from "../api/paApi";
import { importERJob } from "../api/externalRecipientApi";
import { SSOLink } from "../pages/authentication/SSOLink";
import { ProcessPageProvider } from "app/contexts/process-page-context";
import { importTomsJob } from "app/api/tomApi";
import { GroupOverview } from "app/pages/groups/overview/GroupOverview";
import { RoleOverview } from "app/pages/roles/overview/RoleOverview";
import DataBreachesEvaluationPage from "app/pages/data-breaches/DataBreachesEvaluationPage";
import { ExternalRecipientOverviewPage } from "../pages/service-providers/overview/ExternalRecipientOverview";
import TokensOverview from "app/pages/tokens/overview/TokenOverview";
import TokenDetail from "app/pages/tokens/TokenDetail";
import { DebugSettings } from "../pages/debug/DebugSettings";
import PersonGroupsOverview from "app/pages/resources/overview/person-groups/PersonGroupsOverview";
import { ProcessorPAPage } from "../pages/processor-pas/ProcessorPAPage";
import ProcessorPAOverview from "../pages/processor-pas/overview/ProcessorPAOverview";
import { AssessmentLegacyWrapper } from "app/pages/audits/AssessmentLegacyWrapper";
import { AssetPage } from "../pages/assets/AssetPage";
import { useTranslation } from "react-i18next";
import { AuditTemplateDesigner } from "app/pages/audits/designer/AuditTemplateDesigner";
import AnswersetPage from "app/pages/audits/assessment/answerSet/pages/AnswersetPage";
import AITechDocsOverview from "../pages/ai-tech-docs/overview/AITechDocsOverview";
import { AITechDocPage } from "../pages/ai-tech-docs/AITechDocPage";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { RiskAssetsOverviewPage } from "app/pages/risks/RiskAssetsOverviewPage";
import { TOMDocPage } from "../pages/toms/TOMDocPage";
import DocumentNotFoundPage from "../pages/shared/DocumentNotFound/DocumentNotFound";
import { COLLECTIONS } from "../collections";

const SecuredRoute = ({ children }: { readonly children: React.ReactNode }) => {
  const { auth } = useAuthentication();
  if (!auth) {
    return <Login />;
  }
  // abc
  return <PageTemplate>{children}</PageTemplate>;
};
// logout has to be page so that we can stop navigation on unsaved changes
const Logout = () => {
  const navigate = useNavigate();
  const { auth, signOutUserHook } = useAuthentication();

  useEffect(() => {
    signOutUserHook()
      .then(function () {
        // tell sentry to forget all user data
        Sentry.setUser(null);
      })
      .catch(function () {
        // An error happened.
      });
  }, [signOutUserHook]);

  // navigates to the login only after a clean state change
  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth, navigate]);

  return <></>;
};

export const AppRouter = () => {
  const showBasicSubmissionIf = useCallback((pageAccess: PAGE_ACCESS[]) => {
    return !pageAccess.includes("data_subject_requests");
  }, []);
  const { i18n } = useTranslation();
  const isPublicAssessmentActivated = useIsFeaturePresent("publicAssessment");
  const isSelfAssessmentMethodActivated = useIsFeaturePresent("publicSelfAssessmentMethod");
  return (
    <Routes key={i18n.language}>
      <Route path="/" element={<Login />} />
      <Route path="/sso" element={<SSOLink />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/tenants" element={<Tenants />} />
      <Route path="/tenants/:tenantId" element={<TenantDirectLink />} />
      <Route
        path="/org_management"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["org_management"]}>
              <EntityMetaViewProvider>
                <OrganisationOverview />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/processes"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["processes"]}>
              <TasksProvider>
                <EntityMetaViewProvider>
                  <ProcessOverview />
                </EntityMetaViewProvider>
              </TasksProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/processes/importer"
        element={
          <SecuredRoute>
            {/** the page access importer does not exist, so only tenant admin can access it now */}
            <HasPageAccess requiredPageAccess={["importer"]}>
              <EntityMetaViewProvider>
                <Importer importAPIFn={importPAJob} />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      {generateComponentRouterForMultiplePaths(
        "/processes/:id/:page",
        ["/process/:id/:page"],
        <SecuredRoute>
          <HasPageAccess requiredPageAccess={["processes"]}>
            <TasksProvider>
              <ProcessPageProvider>
                <EntityMetaViewProvider>
                  <Questionnaire />
                </EntityMetaViewProvider>
              </ProcessPageProvider>
            </TasksProvider>
          </HasPageAccess>
        </SecuredRoute>
      )}
      <Route
        path="/processor-pas"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["processor-pas"]}>
              <TasksProvider>
                <EntityMetaViewProvider>
                  <ProcessorPAOverview />
                </EntityMetaViewProvider>
              </TasksProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/processor-pas/:id/:page"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["processor-pas"]}>
              <TasksProvider>
                <EntityMetaViewProvider>
                  <ProcessorPAPage />
                </EntityMetaViewProvider>
              </TasksProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/tasks/:page"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["tasks"]}>
              <TasksProvider>
                <EntityMetaViewProvider>
                  <TasksOverview />
                </EntityMetaViewProvider>
              </TasksProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/tasks/:page/:id"
        element={
          <SecuredRoute>
            <TasksProvider>
              <EntityMetaViewProvider>
                <TasksOverview />
              </EntityMetaViewProvider>
            </TasksProvider>
          </SecuredRoute>
        }
      />
      <Route path="/task-details/:id" element={<RedirectWithAllProps to="/tasks/:id" />} />
      <Route
        path="/tokens"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.TOKEN}>
              <HasPageAccess requiredPageAccess={["tokens"]}>
                <EntityMetaViewProvider>
                  <TokensOverview />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/tokens/:tokenId"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.TOKEN}>
              <HasPageAccess requiredPageAccess={["tokens"]}>
                <EntityMetaViewProvider>
                  <TokenDetail />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/tokens/new"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.TOKEN}>
              <HasPageAccess requiredPageAccess={["tokens"]}>
                <EntityMetaViewProvider>
                  <TokenDetail />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/users"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["user_management"]}>
              <EntityMetaViewProvider>
                <UsersOverview />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/users/new"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["user_management"]}>
              <EntityMetaViewProvider>
                <CreateAndEditUser />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/users/:userId"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["user_management"]}>
              <EntityMetaViewProvider>
                <CreateAndEditUser />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      {generateComponentRouterForMultiplePaths(
        "/dpias",
        ["/dpia"],
        <SecuredRoute>
          <HasPageAccess requiredPageAccess={["impact_assessment"]}>
            <EntityMetaViewProvider>
              <ProcessOverview dpiaOnly={true} />
            </EntityMetaViewProvider>
          </HasPageAccess>
        </SecuredRoute>
      )}
      <Route
        path="/toms/importer"
        element={
          <SecuredRoute>
            {/** the page access importer does not exist, so only tenant admin can access it now */}
            <HasPageAccess requiredPageAccess={["importer"]}>
              <EntityMetaViewProvider>
                <Importer importAPIFn={importTomsJob} />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      {generateComponentRouterForMultiplePaths(
        "/toms/:page",
        ["/tom/:page"],
        <SecuredRoute>
          <HasFeatureToggleOn feature={FEATURES.MEASURES_FEATURE}>
            <HasPageAccess requiredPageAccess={["tom"]}>
              <EntityMetaViewProvider>
                <TomOverview />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </HasFeatureToggleOn>
        </SecuredRoute>
      )}
      {generateComponentRouterForMultiplePaths(
        "/toms/:id/:page",
        ["/tom/:id/:page"],
        <SecuredRoute>
          <HasFeatureToggleOn feature={FEATURES.MEASURES_FEATURE}>
            <HasPageAccess requiredPageAccess={["tom"]}>
              <TasksProvider>
                <EntityMetaViewProvider>
                  <TOMDocPage />
                </EntityMetaViewProvider>
              </TasksProvider>
            </HasPageAccess>
          </HasFeatureToggleOn>
        </SecuredRoute>
      )}
      <Route
        path="/data-subject-requests"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.DATA_SUBJECT_REQUEST}>
              <HasPageAccess requiredPageAccess={["data_subject_requests"]}>
                <EntityMetaViewProvider>
                  <DataSubjectRequestOverview />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/data-subject-requests/:id/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.DATA_SUBJECT_REQUEST}>
              <HasPageAccess requiredPageAccess={["data_subject_requests"]}>
                <TasksProvider>
                  <DSRProviderWithDocumentId>
                    <EntityMetaViewProvider>
                      <ComponentSwitch componentMap={getDSRComponentMap()} />
                    </EntityMetaViewProvider>
                  </DSRProviderWithDocumentId>
                </TasksProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/data-subject-requests-submission"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.DSR_BASIC_SUBMISSION}>
              <ShowPageIf pageAccessCondition={showBasicSubmissionIf}>
                <EntityMetaViewProvider>
                  <DataSubjectRequestsSubmission />
                </EntityMetaViewProvider>
              </ShowPageIf>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/data-breaches"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.DATA_BREACHES}>
              <HasPageAccess requiredPageAccess={["data_breaches"]}>
                <EntityMetaViewProvider>
                  <DataBreachesOverview />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/data-breaches/:id/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.DATA_BREACHES}>
              <EntityMetaViewProvider>
                <HasPageAccess requiredPageAccess={["data_breaches"]}>
                  <TasksProvider>
                    <ComponentSwitch componentMap={getDataBreachComponentMap()} />
                  </TasksProvider>
                </HasPageAccess>
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/account-settings"
        element={
          <SecuredRoute>
            <EntityMetaViewProvider>
              <AccountSettings />
            </EntityMetaViewProvider>
          </SecuredRoute>
        }
      />
      <Route
        path="/account-settings/mfa"
        element={
          <SecuredRoute>
            <EntityMetaViewProvider>
              <AccountSettingsMFAFlow />
            </EntityMetaViewProvider>
          </SecuredRoute>
        }
      />
      <Route
        path="/account-settings/password"
        element={
          <SecuredRoute>
            <EntityMetaViewProvider>
              <AccountSettingsChangePasswordFlow />
            </EntityMetaViewProvider>
          </SecuredRoute>
        }
      />
      <Route
        path="/account-settings/password"
        element={
          <SecuredRoute>
            <EntityMetaViewProvider>
              <AccountSettingsChangePasswordFlow />
            </EntityMetaViewProvider>
          </SecuredRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["dashboard"]}>
              <Dashboard />
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/resources/data-locations"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["service_providers"]}>
              <EntityMetaViewProvider>
                <DataLocationOverviewPage />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      {
        <Route
          path="/resources/data-assets/overview"
          element={
            <SecuredRoute>
              <HasPageAccess requiredPageAccess={["resources"]}>
                <EntityMetaViewProvider>
                  <PersonGroupsOverview />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </SecuredRoute>
          }
        />
      }

      <Route
        path="/resources/:resourceType/overview"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["resources"]}>
              <EntityMetaViewProvider>
                <HasResourcesToggledOn>
                  <ResourceTypeOverviewPage />
                </HasResourcesToggledOn>
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/resources"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["resources"]}>
              <EntityMetaViewProvider>
                <ResourcesSelectionPage />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/deletion-concept"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.DELETION_CONCEPT}>
              <EntityMetaViewProvider>
                <DeletionConceptOverview />
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/asset-management"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.ASSETS}>
              <EntityMetaViewProvider>
                <AssetsOverview />
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/ai-tech-docs"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.AI_TECH_DOCS}>
              <EntityMetaViewProvider>
                <AITechDocsOverview />
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />

      <Route
        path="/ai-tech-docs/:id/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.AI_TECH_DOCS}>
              <EntityMetaViewProvider>
                <TasksProvider>
                  <AITechDocPage />
                </TasksProvider>
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/asset-management/:id/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.ASSETS}>
              <HasPageAccess requiredPageAccess={["assets"]}>
                <EntityMetaViewProvider>
                  <TasksProvider>
                    <AssetPage />
                  </TasksProvider>
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      {generateComponentRouterForMultiplePaths(
        "/external-recipients",
        ["/service-providers"],
        <SecuredRoute>
          <HasPageAccess requiredPageAccess={["service_providers"]}>
            <EntityMetaViewProvider>
              <ExternalRecipientOverviewPage />
            </EntityMetaViewProvider>
          </HasPageAccess>
        </SecuredRoute>
      )}
      <Route
        path="/external-recipients/importer"
        element={
          <SecuredRoute>
            {/** the page access importer does not exist, so only tenant admin can access it now */}
            <HasPageAccess requiredPageAccess={["importer"]}>
              <EntityMetaViewProvider>
                <Importer importAPIFn={importERJob} />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      {generateComponentRouterForMultiplePaths(
        "/external-recipients/:page/:id",
        [
          /* nto make sure old email Notifications still work*/
          "/service-provider/:id/:page",
          "/service-providers/:id/:page",
          "/service-providers/:id",
          "/external-recipient/:page/:id"
        ],
        <SecuredRoute>
          <HasPageAccess requiredPageAccess={["service_providers"]}>
            <EntityMetaViewProvider>
              <TasksProvider>
                <ServiceProviderWithId />
              </TasksProvider>
            </EntityMetaViewProvider>
          </HasPageAccess>
        </SecuredRoute>
      )}

      <Route
        path="/document-center"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["document_center"]} returnNothing={true}>
              <EntityMetaViewProvider>
                <DocumentCenter />
              </EntityMetaViewProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/actions/reset-password" element={<ResetPassword initialPassword={false} />} />
      <Route path="/actions/initial-password" element={<ResetPassword initialPassword={true} />} />
      <Route path="/actions/sso-login" element={<SSOLoginCallback />} />
      <Route
        path="/notifications"
        element={
          <SecuredRoute>
            <EntityMetaViewProvider>
              <NotificationsOverview />
            </EntityMetaViewProvider>
          </SecuredRoute>
        }
      />
      <Route
        path="/risks/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.RISKS}>
              <HasPageAccess requiredPageAccess={["risks"]}>
                <EntityMetaViewProvider>
                  <RiskOverview />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/risks/:id/:page"
        element={
          <SecuredRoute>
            <HasPageAccess requiredPageAccess={["risks"]}>
              <TasksProvider>
                <RiskWithDocumentId>
                  <EntityMetaViewProvider>
                    <ComponentSwitch componentMap={getRiskComponentMap()} />
                  </EntityMetaViewProvider>
                </RiskWithDocumentId>
              </TasksProvider>
            </HasPageAccess>
          </SecuredRoute>
        }
      />
      <Route
        path="/audits/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.AUDIT}>
              <HasPageAccess requiredPageAccess={["audits"]}>
                <TasksProvider>
                  <EntityMetaViewProvider>
                    <AuditsOverview
                      isPublicAssessmentActivated={!!isPublicAssessmentActivated}
                      isSelfAssessmentMethodActivated={!!isSelfAssessmentMethodActivated}
                    />
                  </EntityMetaViewProvider>
                </TasksProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/audits/templates/:id"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.AUDIT}>
              <EntityMetaViewProvider>
                <AuditTemplateDesigner />
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/audits/:auditid/answerset/:id/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.PUBLIC_ASSESSMENT}>
              <TasksProvider docPrefix={"/answerset/"}>
                <EntityMetaViewProvider>
                  <AnswersetPage />
                </EntityMetaViewProvider>
              </TasksProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/audits/instances/:id/:page"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.AUDIT}>
              <EntityMetaViewProvider>
                <AuditRemarksProvider>
                  <TasksProvider>
                    <AssessmentLegacyWrapper />
                  </TasksProvider>
                </AuditRemarksProvider>
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/groups"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.USERGROUPS_FEATURES}>
              <HasPageAccess requiredPageAccess={["groups"]}>
                <EntityMetaViewProvider>
                  <GroupOverview />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/groups/new"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.USERGROUPS_FEATURES}>
              <HasPageAccess requiredPageAccess={["groups"]}>
                <EntityMetaViewProvider>
                  <CreateAndEditGroup />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/groups/:id"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.USERGROUPS_FEATURES}>
              <HasPageAccess requiredPageAccess={["groups"]}>
                <EntityMetaViewProvider>
                  <CreateAndEditGroup />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/roles"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.USERROLES_FEATURES}>
              <HasPageAccess requiredPageAccess={["roles"]}>
                <EntityMetaViewProvider>
                  <RoleOverview />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/roles/new"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.USERROLES_FEATURES}>
              <HasPageAccess requiredPageAccess={["roles"]}>
                <EntityMetaViewProvider>
                  <CreateAndEditRole />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/roles/:id"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={FEATURES.USERROLES_FEATURES}>
              <HasPageAccess requiredPageAccess={["roles"]}>
                <EntityMetaViewProvider>
                  <CreateAndEditRole />
                </EntityMetaViewProvider>
              </HasPageAccess>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/websites"
        element={
          <SecuredRoute>
            <HasFeatureToggleOn feature={USER_FEATURE_IDS.WEBSITES}>
              <EntityMetaViewProvider>
                <WebsiteOverview />
              </EntityMetaViewProvider>
            </HasFeatureToggleOn>
          </SecuredRoute>
        }
      />
      <Route
        path="/debug"
        element={
          <SecuredRoute>
            <DebugSettings />
          </SecuredRoute>
        }
      />

      {/*  The default route if nothing matches */}
      <Route path="*" element={<NavigateToProcesses />} />
    </Routes>
  );
};

const NavigateToProcesses = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/processes");
  }, [navigate]);

  return null;
};

// unfortunately, regex or array paths are not longer supported with react router v6
// -> we need to specify it manually
const generateComponentRouterForMultiplePaths = (
  mainPath: string,
  paths: string[],
  componentNode: React.ReactNode
): React.ReactNode => {
  return (
    <>
      {paths.map(path => (
        <Route key={path} path={path} element={<RedirectWithAllProps to={mainPath} />} />
      ))}
      {<Route key={mainPath} path={mainPath} element={componentNode} />}
    </>
  );
};

const RedirectWithAllProps = ({ to }: { readonly to: string }) => {
  const location = useLocation();
  const params = useParams();
  const navigateTo = useMemo<To>(() => {
    const replacedTo = Object.entries(params || {}).reduce((acc, [key, value]) => {
      return acc.replaceAll(`/:${key}`, `/${value}`);
    }, to);
    return {
      pathname: replacedTo,
      search: location?.search,
      hash: location?.hash
    };
  }, [params, to, location?.search, location?.hash]);

  return <Navigate to={navigateTo} replace={true} />;
};

const ComponentSwitch = ({
  componentMap,
  additionalProps
}: {
  readonly componentMap: Record<string, (input: { readonly documentId: string }) => React.JSX.Element>;
  readonly additionalProps?: object;
}): React.JSX.Element => {
  const { page, id } = useParams();
  if (!id) {
    return <></>;
  }

  // general page always as fallback
  const ComponentConstructor = (componentMap[page || ""] || componentMap["general"]) as any;
  if (!ComponentConstructor) {
    return <></>;
  }
  return <ComponentConstructor {...(additionalProps || {})} documentId={id} />;
};

const getDataBreachComponentMap = () => ({
  general: DataBreachesGeneralPage,
  authorityNotification: DataBreachesAuthorityNotificationPage,
  evaluation: DataBreachesEvaluationPage,
  tasks: DataBreachesTasksPage
});

const getDSRComponentMap = () => ({
  general: DataSubjectRequestsGeneralPage,
  tasks: DataSubjectRequestsTasksPage,
  data: DataSubjectRequestsDataPage
});

const DSRProviderWithDocumentId = ({ children }: { readonly children: React.ReactNode }) => {
  const { id } = useParams();
  if (!id) {
    return <></>;
  }
  return <DataSubjectRequestProvider dataSubjectRequestId={id}>{children} </DataSubjectRequestProvider>;
};

const RiskWithDocumentId = ({ children }: { readonly children: React.ReactNode }) => {
  const { id } = useParams();
  return (
    <RiskProvider riskId={id || ""} customLoadScreen={undefined}>
      <RiskNotFoundHandler>{children}</RiskNotFoundHandler>
    </RiskProvider>
  );
};

const RiskNotFoundHandler = ({ children }: { readonly children: React.ReactNode }) => {
  const { isMissing } = useRisk();
  if (isMissing) {
    return <DocumentNotFoundPage collection={COLLECTIONS.RISK} />;
  } else {
    return <>{children}</>;
  }
};

const ServiceProviderWithId = () => {
  const params = useParams();
  const id = params.id;
  if (!id) {
    return <></>;
  }
  return <ServiceProvider documentId={id} />;
};

const getRiskComponentMap = () => ({
  general: RiskGeneralPage,
  "initial-assessment": RiskFirstAssessmentPage,
  treatment: RiskTreatmentPage,
  "treatment-assessment": RiskSecondAssessmentPage,
  assets: RiskAssetsOverviewPage
});

export default AppRouter;
