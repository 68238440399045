import { useMemo } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { getAuditsStatusesTypeFilter, getProcessTaskFilter } from "app/pages/shared/Filters/filters";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { AxiosInstance } from "axios";
import i18n from "app/i18n";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { CollectionParams } from "hook/useOverviewData";
import { getOrganizationFilter } from "../../shared/Filters/OrganizationFilter";

const AuditsOverviewController = (axiosInstance: AxiosInstance, options: CollectionParams): OverviewController => {
  // the controller is a pure TS file, if it uses state, the value will not be updated

  const t = i18n.t;
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.AUDITS, undefined, [departmentsDecorator]);

  const getFilters = (overviewResult: OverviewResult) => {
    return [
      getOrganizationFilter("mainOrgUnitId", overviewResult._departments, t, undefined),
      getAuditsStatusesTypeFilter("status", overviewResult.filters?.statuses || [], t),
      getProcessTaskFilter("openTasks", t)
    ].filter(nonNull => nonNull?.filterTree?.length);
  };

  const getSortings = () => [
    {
      field: "title",
      type: "asc",
      label: t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: t("filter_criteria:zToA")
    },
    {
      field: "createdAt",
      type: "desc",
      label: t("filter_criteria:newFirst")
    },
    {
      field: "createdAt",
      type: "asc",
      label: t("filter_criteria:oldFirst")
    }
  ];

  const pageToApiMapper: Record<"instances" | "answersets" | "templates", string> = useMemo(() => {
    if (options.isPublicAssessmentActivated) {
      return {
        instances: options.writePermission ? "audits" : "answersets",
        answersets: "answersets",
        templates: "templates"
      };
    } else {
      return {
        instances: "audits",
        answersets: "audits",
        templates: "templates"
      };
    }
  }, [options.writePermission, options.isPublicAssessmentActivated]);

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(
      setup,
      `/overviews/${pageToApiMapper[options.currentPage as "instances" | "answersets" | "templates"] || ""}`
    );
    if (!data) {
      return null;
    }
    return {
      ...data,
      items: data?.items || [],
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    if (options.currentPage === "instances") {
      baseController.goToItem(`/audits/${options.currentPage}/${id}/general`);
    } else {
      baseController.goToItem(`/audits/${options.currentPage}/${id}`);
    }
  };

  const baseApiUrlForPage = () => {
    switch (options.currentPage) {
      case "instances":
        return "/audits";
      case "templates":
        return "/audits/templates";
      default:
        throw new Error(`Unknown audit pages: ${options.currentPage}`);
    }
  };

  const addItem = async (data: OverviewNewItem) => {
    return await baseController.addItem(
      {
        title: data?.title || createOverviewItemDefaultName()
      },
      baseApiUrlForPage()
    );
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, data, `${baseApiUrlForPage()}/${id}`);
  };

  const deleteItem = async (id: string) => {
    return await baseController.deleteItem(id, `${baseApiUrlForPage()}/${id}`);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem({
      title: data?.title || createOverviewItemDefaultName(`${COLLECTIONS.AUDITS}_${options.currentPage}`)
    });
    goToItem(response.headers["x-resource-id"] || "");
  };

  const addItemsFromTemplates = async (data: { templateIds: string[] }) => {
    return await baseController.addItemsFromTemplates(data, `overviews/templates`);
  };

  const copyItems = async ({ ids }: { ids: string[] }) => {
    await axiosInstance.post(`/audits/${options.currentPage === "templates" ? "templates" : "audits"}/copies`, {
      ids
    });
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo,
    addItemsFromTemplates,
    copyItems
  };
};

export default AuditsOverviewController;
