import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { getDataBreachById, STATUSES as BREACH_STATUS_OPTIONS, updateDataBreach } from "app/handlers/dataBreachHandler";
import { DataBreachesPageButtons, DataBreachesPageStepper } from "./DataBreachesPagination";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { Box } from "@material-ui/core";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import Question from "components/Question/Question";
import { useMetaView } from "app/contexts/meta-view-context";
import { useUserDepartments } from "../../contexts/department-context";
import { DataBreachesMetaView } from "./DataBreachesMetaView";
import { RESOURCE_TYPES } from "../../handlers/resourceHandler";
import { DataBreachDTO } from "app/api/dataBreachApi";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { COLLECTIONS } from "app/collections";

const DataBreachesGeneralPage = ({ documentId }: { readonly documentId: string }) => {
  const { t } = useTranslation("data_breaches_general_page");
  const { auth } = useAuthentication();
  const { getUserNameHook } = useUserAndTenantData();
  const { rootDepartment, departmentsLoaded, isPartOfUserDepartments } = useUserDepartments();

  const [dataBreach, setDataBreach] = useState<DataBreachDTO | null>(null);

  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const { setInfo } = useMetaView();

  const patch = useCallback(
    async (data: Partial<DataBreachDTO>) => {
      setDataBreach(current => (current ? { ...current, ...data } : null));
      if (auth) {
        const updatedAnswers = await updateDataBreach(documentId, data, auth);
        setDataBreach(updatedAnswers);
      }
    },
    [auth, documentId]
  );

  const noWritePermission = useMemo(() => {
    if (!auth) return true;

    if (!dataBreach?.orgUnitId) return false;
    if (
      dataBreach?.assigneeUID === auth.uid ||
      dataBreach?.dpoUID === auth.uid ||
      auth?.permissions.includes("db_write_all")
    )
      return false;
    if (isPartOfUserDepartments(...[dataBreach.orgUnitId])) return false;
    return true;
  }, [dataBreach, auth, isPartOfUserDepartments]);

  const onBlur = useCallback(
    () =>
      setInfo({
        title: t("enteringInfoCardTitle"),
        text: t("breach_enteringInfoCardText")
      }),
    [setInfo, t]
  );

  useEffect(() => {
    const init = async () => {
      if (!departmentsLoaded) {
        return;
      }

      const data: DataBreachDTO | null = await getDataBreachById(documentId);
      if (!data) {
        setDocumentNotFound(true);
        return;
      }
      setDocumentNotFound(false);
      setDataBreach({
        ...data,
        orgUnitId: data.orgUnitId || rootDepartment?.id || null
      });
      setAnswersLoaded(true);
    };
    init();
  }, [departmentsLoaded, rootDepartment?.id, documentId]);

  useEffect(() => {
    onBlur();
  }, [onBlur]);

  /* TITLE */
  const titleInfo = useMemo(
    () => ({
      title: t("enteringInfoCardTitle"),
      text: t("breach_enteringInfoCardText")
    }),
    [t]
  );
  const onChangeTitle = useCallback((title: string) => patch({ title }), [patch]);
  const titleEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_AREA}
      translatable={dataBreach?.title || ""}
      translationId={"title"}
      questionId={"title"}
      questionName={t("title")}
      value={dataBreach?.title || ""}
      info={titleInfo}
      onChange={onChangeTitle}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* INCEDENT */
  const incedentEl = (
    <Question qType={QUESTION_TYPE.TEXT_AREA} value={dataBreach?.incId || ""} disabled={true} questionName={t("id")} />
  );

  /* AFFECTED ORG UNIT */
  const affectedOrgOnitInfo = useMemo(
    () => ({
      title: t("enteringInfoCardTitle"),
      text: t("orgUnitFocus")
    }),
    [t]
  );
  const onAffectedOrgOnitChange = useCallback((orgUnitId: string) => patch({ orgUnitId }), [patch]);
  const affectedOrgUnitEl = (
    <Question
      qType={QUESTION_TYPE.AFFECTED_ORG_UNITS}
      value={dataBreach?.orgUnitId || ""}
      questionId={"orgUnitId"}
      info={affectedOrgOnitInfo}
      onChange={onAffectedOrgOnitChange}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* FURTHER ORG UNITS */
  const infoFurtherOrgUnits = useMemo(
    () => ({
      title: t("enteringInfoCardTitle"),
      text: t("furtherOrgUnitFocus")
    }),
    [t]
  );
  const furtherOrgUnitsChange = useCallback((furtherOrgUnitIds: string[]) => patch({ furtherOrgUnitIds }), [patch]);
  const furtherOrgUnitsEl = (
    <Question
      qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
      questionId={"furtherOrgUnitIds"}
      value={dataBreach?.furtherOrgUnitIds || ""}
      info={infoFurtherOrgUnits}
      onChange={furtherOrgUnitsChange}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* INCIDENT SUMMARY */
  const incidentSummaryInfo = useMemo(
    () => ({
      title: t("enteringInfoCardTitle"),
      text: t("breach_summary_info_card_text")
    }),
    [t]
  );
  const onIncidentSummaryChange = useCallback((summary: string) => patch({ summary }), [patch]);
  const incidentSummaryEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_EDITOR}
      value={dataBreach?.summary || ""}
      translatable={dataBreach?.summary || ""}
      translationId={"summary"}
      questionId="summary"
      questionName={t("summary")}
      onChange={onIncidentSummaryChange}
      info={incidentSummaryInfo}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* INCIDENT CAUSE IDS */
  const incidentCauseIdsInfo = useMemo(
    () => ({
      title: t("enteringInfoCardTitle"),
      text: t("breach_cause_info_card_text")
    }),
    [t]
  );
  const onIncidentCauseIdsChange = useCallback((incidentCauseIds: string[]) => patch({ incidentCauseIds }), [patch]);
  const incidentCauseIdsEl = (
    <Question
      qType={QUESTION_TYPE.RESOURCE}
      resourceType={RESOURCE_TYPES.DATABREACH_CAUSE}
      value={dataBreach?.incidentCauseIds || []}
      translationId={"causeIds"}
      questionId="causeIds"
      questionName={t("causeIds")}
      onChange={onIncidentCauseIdsChange}
      info={incidentCauseIdsInfo}
      onBlur={onBlur}
      multiSelect={true}
      allowAdd={true}
      hidden={!useIsFeaturePresent(FEATURES.DATABREACH_CAUSE)}
      orgUnitIds={dataBreach ? [dataBreach.orgUnitId || "", ...(dataBreach.furtherOrgUnitIds || [])] : []}
      disabled={noWritePermission}
    />
  );

  /* INCIDENT CAUSE */
  const incidentCauseInfo = useMemo(
    () => ({
      title: t("enteringInfoCardTitle"),
      text: t("breach_cause_info_card_text")
    }),
    [t]
  );
  const onIncidentCauseChange = useCallback((cause: string) => patch({ cause }), [patch]);
  const incidentCauseEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_EDITOR}
      value={dataBreach?.cause || ""}
      translatable={dataBreach?.cause || ""}
      translationId={"cause"}
      questionId="cause"
      questionName={t("cause")}
      onChange={onIncidentCauseChange}
      info={incidentCauseInfo}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* DETECTION TYPE */
  const detectionTypeInfo = useMemo(
    () => ({
      title: t("enteringInfoCardTitle"),
      text: t("breach_detectionType_info_card_text")
    }),
    [t]
  );
  const onDetectionTypeChange = useCallback((detectionType: string) => patch({ detectionType }), [patch]);
  const detectionTypeEl = (
    <Question
      qType={QUESTION_TYPE.RESOURCE}
      resourceType={RESOURCE_TYPES.DATABREACH_DETECTION}
      multiSelect={false}
      value={dataBreach?.detectionType || ""}
      info={detectionTypeInfo}
      questionId="detectionType"
      questionName={t("detectionType")}
      onChange={onDetectionTypeChange}
      onBlur={onBlur}
      orgUnitIds={dataBreach ? [dataBreach.orgUnitId || "", ...(dataBreach.furtherOrgUnitIds || [])] : []}
      disabled={noWritePermission}
    />
  );

  /* OCCURED AT */
  const onOccuredAtChange = useCallback(occurredAt => patch({ occurredAt }), [patch]);
  const occurredAtEl = (
    <Question
      qType={QUESTION_TYPE.DATE_TIME}
      questionId="occurredAt"
      questionName={t("occurredAt")}
      value={dataBreach?.occurredAt?.toString() || ""}
      onChange={onOccuredAtChange}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* DETECTED AT */
  const onDetectedAtChange = useCallback(
    detectedAt => {
      const dueDate = new Date(detectedAt).setHours(detectedAt.getHours() + 72);
      patch({ detectedAt, dueAt: new Date(dueDate) });
    },
    [patch]
  );
  const detectedAtEl = (
    <Question
      qType={QUESTION_TYPE.DATE_TIME}
      questionId="detectedAt"
      questionName={t("detectedAt")}
      value={dataBreach?.detectedAt?.toString() || ""}
      onChange={onDetectedAtChange}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* STATUS */
  const onStatusChanged = useCallback(status => patch({ status }), [patch]);
  const getOptionSelected = useCallback((option, value) => option === value, []);
  const getOptionLabel = useCallback(option => t("status_" + option), [t]);
  const statusEl = (
    <Question questionId="status" questionName={t("status")}>
      <MultiAutocomplete
        id="status"
        label={t("status")}
        hasMultiSelect={false}
        selected={dataBreach?.status || ""}
        getOptionSelected={getOptionSelected}
        updateSelected={onStatusChanged}
        options={BREACH_STATUS_OPTIONS}
        getOptionLabel={getOptionLabel}
        onBlur={onBlur}
        disabled={noWritePermission}
      />
    </Question>
  );

  /* DUE AT */
  const onDueAtChange = useCallback(dueAt => patch({ dueAt }), [patch]);
  const dueAtEl = (
    <Question
      qType={QUESTION_TYPE.DATE_TIME}
      questionId="dueAt"
      questionName={t("dueAt")}
      value={dataBreach?.dueAt?.toString() || ""}
      onChange={onDueAtChange}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* PRIVACY EXPERT */
  const onAssigneeIdChange = useCallback(
    (userIds: string[]) => {
      const assigneeUID = userIds[0] || "";
      patch({ assigneeUID });
    },
    [patch]
  );
  const privacyExpertEl = (
    <Question
      qType={QUESTION_TYPE.PRIVACY_EXPERT_USER}
      questionId="assigneeUID"
      questionName={t("assigneeUID")}
      value={[dataBreach?.assigneeUID || ""]}
      onChange={onAssigneeIdChange}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* Data Protection Officer */
  const onDpoUIDChange = useCallback(
    (userIds: string[]) => {
      const dpoUID = userIds[0] || "";
      patch({ dpoUID });
    },
    [patch]
  );

  const dpoEl = (
    <Question
      qType={QUESTION_TYPE.DPO_USER}
      questionId="dpo"
      value={[dataBreach?.dpoUID || ""]}
      onChange={onDpoUIDChange}
      onBlur={onBlur}
      disabled={noWritePermission}
    />
  );

  /* CREATOR ID */
  const creatorIdEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_AREA}
      disabled={true}
      questionName={t("creatorUID")}
      value={getUserNameHook(dataBreach?.creatorUID || "")}
      onBlur={onBlur}
    />
  );

  /* LABELS */
  const onLabelsChange = useCallback(labelIds => patch({ labelIds }), [patch]);

  const labelsEl = (
    <Question
      qType={QUESTION_TYPE.LABELS}
      value={dataBreach?.labelIds || []}
      onChange={onLabelsChange}
      onBlur={onBlur}
      orgUnitIds={dataBreach ? [dataBreach.orgUnitId || "", ...(dataBreach.furtherOrgUnitIds || [])] : []}
      disabled={noWritePermission}
    />
  );

  const dpoFieldFeature = useIsFeaturePresent(FEATURES.DATA_PROTECTION_OFFICER_FIELD);

  // content for doc view
  const docViewContent = answersLoaded === true && (
    <DocView header={dataBreach?.title || ""} pagination={<DataBreachesPageStepper />}>
      <QuestionnaireSubHeader text={t("generalInformation")} />
      {answersLoaded && (
        <>
          <Box display="flex" justifyContent="space-between" gridGap={32}>
            <Box flex="1">{titleEl}</Box>
            <Box flex="1">{incedentEl}</Box>
          </Box>
          {affectedOrgUnitEl}
          {furtherOrgUnitsEl}
          {incidentSummaryEl}
          {incidentCauseIdsEl}
          {incidentCauseEl}
          {detectionTypeEl}
          <Box display="flex" justifyContent="space-between" gridGap={32}>
            <Box flex="1">{occurredAtEl}</Box>
            <Box flex="1">{detectedAtEl}</Box>
          </Box>
          <Box display="flex" justifyContent="space-between" gridGap={32}>
            <Box flex="1">{statusEl}</Box>
            <Box flex="1">{dueAtEl}</Box>
          </Box>
          {dpoFieldFeature ? (
            <>
              <Box display="flex" justifyContent="space-between" gridGap={32}>
                <Box flex="1">{privacyExpertEl}</Box>
                <Box flex="1">{dpoEl}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between" gridGap={32}>
                <Box flex="1">{creatorIdEl}</Box>
                <Box flex="1">{labelsEl}</Box>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" gridGap={32}>
                <Box flex="1">{privacyExpertEl}</Box>
                <Box flex="1">{creatorIdEl}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between" gridGap={32}>
                <Box flex="1">{labelsEl}</Box>
              </Box>
            </>
          )}
          <Box mt={3}>
            <DataBreachesPageButtons />
          </Box>
        </>
      )}
    </DocView>
  );

  return (
    <DocMetaView
      notFound={documentNotFound}
      collection={COLLECTIONS.DATA_BREACHES}
      docViewContent={docViewContent}
      metaViewContent={<DataBreachesMetaView docName={dataBreach?.title || ""} />}
    />
  );
};
export default DataBreachesGeneralPage;
