import isUserRoleAdminOrExpertOrRoot from "../../handlers/permissionHandler";
import React, { useEffect, useMemo, useState } from "react";
import { FollowUpTask } from "../tasks/FollowUpTask";
import { useTranslation } from "react-i18next";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "../../collections";
import { getProcessPageData } from "../../api/paApi";
import { useParams } from "react-router-dom";
import { ProcessGeneralPageModel } from "./general-page/ProcessGeneralPage";

export const PAFollowUpTask = (props: { readonly disabled?: boolean }) => {
  const { id } = useParams();
  const [model, setModel] = useState<ProcessGeneralPageModel | null>(null);
  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchProcessPageData = async () => {
      const { processPage } = await getProcessPageData<ProcessGeneralPageModel>({
        processId: id || "",
        page: "general"
      });
      setModel(processPage);
    };

    fetchProcessPageData();
  }, [id]);
  const { t } = useTranslation("followUpTask");
  const { nonRootAdminTenantUsers } = useUserAndTenantData();

  const disableCheckbox = useMemo(() => {
    const expertUser = nonRootAdminTenantUsers.find(user => user.id === model?.responsibleExpertUserIds[0]);
    return (
      !expertUser ||
      !isUserRoleAdminOrExpertOrRoot({
        role: expertUser.userRole || ""
      })
    );
  }, [nonRootAdminTenantUsers, model?.responsibleExpertUserIds]);

  return (
    <FollowUpTask
      docId={id || ""}
      creatorUID={model?.responsibleExpertUserIds[0] || ""}
      assigneeUID={model?.assignedToUserIds[0] || ""}
      taskName={`${t("paTaskName")}  ${model?.title}`}
      taskDescription={t("paTaskDescription")}
      disableCheckbox={disableCheckbox || props.disabled}
      collection={COLLECTIONS.PROCESSES}
      tooltipWhenDisabled={t("privacyExpertTooltip")}
    />
  );
};
