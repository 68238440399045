import { Box } from "@material-ui/core";
import TextBody2 from "components/TextBody2/TextBody2";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";

import { mergeExternalRecipientApi } from "../../../api/externalRecipientApi";
import ExternalDataRecipientsQuestion from "../../../../components/Question/types/resources/ExternalDataRecipientsQuestion";

export interface ExternalRecipientModalProps {
  readonly selectedExternalRecipients: { id: string }[];
  readonly onClose: () => void;
  readonly onCancel: () => void;
}
export const ExternalRecipientMergeModal = ({
  selectedExternalRecipients,
  onClose,
  onCancel
}: ExternalRecipientModalProps) => {
  const { t } = useTranslation("service_providers_overview");

  const [selectedERIds, setSelectedERIds] = useState<string[]>([]);
  const [mergeIntoERId, setMergeIntoERId] = useState<string | null>(null);
  useEffect(() => {
    setSelectedERIds(selectedExternalRecipients.map(er => er.id));
    setMergeIntoERId(null);
  }, [selectedExternalRecipients]);

  const mergeExternalRecipients = useCallback(async () => {
    await mergeExternalRecipientApi(
      {
        toMergeERIDs: selectedERIds,
        mergeIntoERId: mergeIntoERId || null
      },
      {
        wait: true
      }
    );
    onClose();
  }, [onClose, selectedERIds, mergeIntoERId]);

  const [buttons, setButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(
    () =>
      setButtons([
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancel
        },
        {
          disabled: !mergeIntoERId,
          confirmButton: true,
          title: t("common:merge"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: mergeExternalRecipients
        }
      ]),
    [mergeExternalRecipients, mergeIntoERId, onCancel, onClose, t]
  );

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box>
        <Box mt={2}>
          <ExternalDataRecipientsQuestion value={selectedERIds} disabled={true} />
        </Box>
      </Box>
      <Box mt={3}>
        <ExternalDataRecipientsQuestion
          value={mergeIntoERId ? [mergeIntoERId] : []}
          multiSelect={false}
          allowAdd={false}
          onChange={setMergeIntoERId}
          blackListedIds={selectedERIds}
        />
      </Box>
    </Box>
  );

  return (
    <ConfirmationModal
      modalOpen={!!selectedExternalRecipients.length}
      onClose={onCancel}
      modalTitle={t("datalocations_merge_title")}
      modalText={t("resources_data-deletion_overview:merge_text")}
      buttons={buttons}
      modalBody={modalBody}
    />
  );
};
