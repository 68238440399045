import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Typography } from "@mui/material";

interface TextBody2Props {
  readonly text: string;
  readonly translationKey?: string;
  readonly parseHTML?: boolean;
}

const TextBody2 = ({ text, translationKey, parseHTML }: TextBody2Props) => {
  const { t } = useTranslation(translationKey);

  if (parseHTML) {
    return <span className="MuiTypography-root MuiTypography-body2">{parse(DOMPurify.sanitize(text))}</span>;
  }
  return <Typography variant="body2">{translationKey ? t(text) : text}</Typography>;
};

export default TextBody2;
