export const getCustomDualMode = () => {
  return window.sessionStorage.getItem("caralegal::debug::paServiceDualMode") || null;
};

export const saveCustomDualMode = (mode: string | null) => {
  if (!mode) {
    window.sessionStorage.removeItem("caralegal::debug::paServiceDualMode");
    return;
  }
  window.sessionStorage.setItem("caralegal::debug::paServiceDualMode", mode);
};
