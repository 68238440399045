import React from "react";
import MetaView, { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "../../../components/MetaView/MetaView";
import { COLLECTIONS } from "../../collections";
import { usePathParamByKey } from "../../router/router-custom-hooks";

export const DataBreachesMetaView = ({ docName }: { readonly docName: string }) => {
  const documentId = usePathParamByKey("id");
  const pageName = usePathParamByKey("page");

  return (
    <MetaView
      tabs={metaViewTabIds}
      translationKey={"data_breaches_overview"}
      docName={docName}
      docId={documentId}
      pageId={pageName}
      collection={COLLECTIONS.DATA_BREACHES}
    />
  );
};

const metaViewTabIds: META_VIEW_TABS_TYPES[] = [
  META_VIEW_TABS.ASSISTANT,
  META_VIEW_TABS.TODOS,
  META_VIEW_TABS.COMMENTS
];
