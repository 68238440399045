import { DefaultApi, GetChange200Response, UpdateChangePageDTO } from "../generated/process-service";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import useSWR from "swr";
import { useCallback, useMemo } from "react";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const useChange = (args: { documentId: string }) => {
  const responseChange = useSWR(args.documentId ? "change_" + args.documentId : null, async () => {
    return processClient.getChange(args.documentId).then(resp => resp.data);
  });

  const { mutate } = responseChange;

  const emptyProcessData: GetChange200Response = useMemo(
    () => ({
      processMeta: { id: args.documentId, status: "", relativeOrgUnitIds: [], assignUserIds: [], title: "" },
      processPage: {}
    }),
    [args.documentId]
  );

  const updateChange = useCallback(
    async (processID: string, payload: UpdateChangePageDTO) => {
      await mutate(
        async data => {
          await processClient.updateChange(args.documentId, payload);
          return data;
        },
        {
          populateCache: false,
          revalidate: false,
          optimisticData(currentData) {
            if (!currentData) {
              return emptyProcessData;
            }

            return {
              ...currentData,
              processPage: { ...currentData.processPage, ...payload }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate]
  );

  const actions = useMemo(
    () => ({
      updateChange: updateChange
    }),
    [updateChange]
  );

  return {
    ...responseChange,
    actions
  };
};
