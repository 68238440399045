import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import DocView from "../../../components/DocView/DocView";
import { Box, Button } from "@material-ui/core";
import React, { useCallback } from "react";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { exportOrgUnits } from "../../handlers/departmentHandler";
import { exportDataTypes } from "../../api/dataAssetApi";

export const DebugSettings = () => {
  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"debug_settings"} />}>
      <DocView header="Debug Mode">
        <Box>
          <CustomExportForCS />
        </Box>
      </DocView>
    </DocMetaView>
  );
};

export const CustomExportForCS = () => {
  const { auth } = useAuthentication();
  const exportOrgUnit = useCallback(async () => {
    exportOrgUnits(auth?.tenantId + "-org-units.tsv");
  }, [auth]);
  const exportDataType = useCallback(async () => {
    exportDataTypes(auth?.tenantId + "-data_types.tsv");
  }, [auth]);

  if (!auth?.permissions?.includes("super_admin")) {
    return <></>;
  }

  return (
    <Box>
      <Box mb={4}>
        <Button onClick={exportOrgUnit} variant="contained" color="primary">
          Export Org Unit
        </Button>
      </Box>
      <Box mb={4}>
        <Button onClick={exportDataType} variant="contained" color="primary">
          Export Data Type
        </Button>
      </Box>
    </Box>
  );
};
