import DataLocationOverview from "../../datalocations/DataLocationOverview";
import React, { useCallback, useEffect, useState } from "react";
import { useGetAssetApi, useUpdateAssetApi } from "../../../api/assetApi";
import DataLocationsPickerModal from "../../datalocations/DataLocationsPickerModal";
import { useTranslation } from "react-i18next";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { UpdateAssetDTO } from "../../../api/generated/asset-service";
import { Box, Button } from "@mui/material";
import { AssetAutomaticallyAssetExternalRecipient } from "./AssetAutomaticallyAssetExternalRecipient";

export const AssetDataLocationsOverview = ({
  assetId,
  readonly
}: {
  readonly assetId: string;
  readonly readonly: boolean;
}) => {
  const { data: asset } = useGetAssetApi({ documentId: assetId || "" });
  const { updateAsset } = useUpdateAssetApi({ documentId: assetId || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAssetDTO, UpdateAssetDTO>({
    triggerUpdate: updateAsset
  });
  const { t } = useTranslation("assets_overview");

  const [dataLocationsPickerOpen, setDataLocationsPickerOpen] = useState(false);
  const openDataLocationsPicker = useCallback(() => setDataLocationsPickerOpen(true), []);
  const closeDataLocationsPicker = useCallback(() => setDataLocationsPickerOpen(false), []);

  const [dataLocationIds, setDataLocationIds] = useState<string[]>(asset?.dataLocationIds || []);
  const [realTimeDataLocationIds, setRealTimeDataLocationIds] = useState<string[]>([]);

  useEffect(() => {
    setDataLocationIds(asset?.dataLocationIds || []);
    setRealTimeDataLocationIds(asset?.dataLocationIds || []);
  }, [asset?.dataLocationIds]);

  const onDataLocationChange = useCallback(
    (dataLocationIds: string[]) => {
      setDataLocationIds(dataLocationIds);
      queueUpdates({ dataLocationIds });
      setDataLocationsPickerOpen(false);
    },
    [queueUpdates]
  );

  return (
    <>
      <Box display="flex" px={8}>
        <Button variant="contained" color="primary" onClick={openDataLocationsPicker} disabled={readonly}>
          {t("asset_details:changeDataLocations")}
        </Button>
      </Box>
      <DataLocationOverview
        key={`dls-${dataLocationIds.join("-")}`}
        dataLocationIds={dataLocationIds}
        hideTitle={true}
        hideActions={true}
      />
      <DataLocationsPickerModal
        label={t("assets_overview:please_select_data_locations")}
        open={dataLocationsPickerOpen}
        selectedIDs={dataLocationIds}
        onChange={onDataLocationChange}
        onSelectionChanged={setRealTimeDataLocationIds}
        additionalInfo={
          <Box mt={4}>
            <AssetAutomaticallyAssetExternalRecipient assetId={assetId} dataLocationIds={realTimeDataLocationIds} />
          </Box>
        }
        onClose={closeDataLocationsPicker}
      />
    </>
  );
};
