import PropTypes from "prop-types";
import { RiskAssessment } from "./RiskAssessment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { debounce, isEmpty } from "lodash-es";
import { useRisk } from "../../../contexts/risk-context";

export const RiskAssessmentAutoSave = ({ phaseId, protectionObjectiveId, disabled }) => {
  const { initialized, risk, updateAssessmentHook } = useRisk();

  const [assessment, setAssessment] = useState(null);
  useEffect(() => {
    const phaseAssessment = risk.assessments[phaseId];
    if (!phaseAssessment.individualAssessmentEnabled && protectionObjectiveId !== "main") {
      setAssessment(null);
      return;
    }
    if (!phaseAssessment.individualAssessmentEnabled && protectionObjectiveId === "main") {
      setAssessment(phaseAssessment.combinedAssessment || emptyAssessment("main"));
      return;
    }

    if (!risk.protectionObjectiveIds.includes(protectionObjectiveId)) {
      setAssessment(null);
      return;
    }

    setAssessment(
      phaseAssessment.individualAssessments.find(
        assessment => assessment.protectionObjectiveId === protectionObjectiveId
      ) || emptyAssessment(protectionObjectiveId)
    );
  }, [phaseId, protectionObjectiveId, risk]);

  const [updatePayload, setUpdatePayload] = useState({});

  const onOccurrenceIdChange = useCallback(occurrenceId => {
    setUpdatePayload(updatePayload => ({ ...updatePayload, occurrenceId }));
  }, []);

  const onDamageExtendIdChange = useCallback(damageExtendId => {
    setUpdatePayload(updatePayload => ({ ...updatePayload, damageExtendId }));
  }, []);

  const onImpactDescriptionChange = useCallback(impactDescription => {
    setUpdatePayload(updatePayload => ({ ...updatePayload, impactDescription }));
  }, []);

  const onReasonDescriptionChange = useCallback(reasonDescription => {
    setUpdatePayload(updatePayload => ({ ...updatePayload, reasonDescription }));
  }, []);

  const [riskRatingIsLoading, setRiskRatingIsLoading] = useState(false);

  const debouncedUpdateAssessmentHook = useMemo(
    () =>
      debounce(async (phaseId, protectionObjectiveId, updatePayload) => {
        try {
          const result = await updateAssessmentHook(phaseId, protectionObjectiveId, updatePayload);
          setUpdatePayload({});
          setRiskRatingIsLoading(false);
          return result;
        } catch (error) {
          setRiskRatingIsLoading(false);
          throw error;
        }
      }, 600),
    [updateAssessmentHook]
  );

  useEffect(() => {
    if (isEmpty(updatePayload)) {
      return;
    }

    if (updatePayload.occurrenceId || updatePayload.damageExtendId) {
      setRiskRatingIsLoading(true);
    }
    debouncedUpdateAssessmentHook(phaseId, protectionObjectiveId, updatePayload);
  }, [phaseId, protectionObjectiveId, updatePayload, debouncedUpdateAssessmentHook]);

  if (!initialized) {
    return <CircularProgress />;
  }

  if (!assessment) {
    return <></>;
  }

  return (
    <RiskAssessment
      data-testid={`assessment-${phaseId}-${protectionObjectiveId}`}
      key={protectionObjectiveId}
      id={protectionObjectiveId}
      hideOccurrence={protectionObjectiveId !== "main"}
      occurrenceId={assessment.occurrenceId}
      damageExtendId={assessment.damageExtendId}
      reasonDescription={assessment.reasonDescription}
      impactDescription={assessment.impactDescription}
      riskRating={assessment.rating}
      riskRatingIsLoading={riskRatingIsLoading}
      onOccurrenceIdChange={onOccurrenceIdChange}
      onDamageExtendIdChange={onDamageExtendIdChange}
      onImpactDescriptionChange={onImpactDescriptionChange}
      onReasonDescriptionChange={onReasonDescriptionChange}
      disabled={disabled}
    />
  );
};

RiskAssessmentAutoSave.propTypes = {
  phaseId: PropTypes.string.isRequired,
  protectionObjectiveId: PropTypes.string.isRequired
};

const emptyAssessment = protectionObjectiveId => ({
  protectionObjectiveId,
  occurrenceId: null,
  damageExtendId: null,
  impactDescription: null,
  reasonDescription: null,
  rating: null
});
