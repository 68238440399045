import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import {
  ListItemButton,
  MenuItem,
  MenuList,
  Paper,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  useTheme
} from "@mui/material";
// when using the Popper from @mui/material, anchorEl tequnique does not work (so popper is misplaced)
import { Popper } from "@material-ui/core";
import { usePathName } from "../../../router/router-custom-hooks";
import { sidebarZIndex } from "./sidebarZIndex";

export const HelpSidebar = ({
  sidebarOpen,
  language,
  onClicked
}: {
  readonly sidebarOpen: boolean;
  readonly language: string;
  readonly onClicked?: () => void;
}) => {
  const { t } = useTranslation("sidebar");
  const theme = useTheme();
  const path = usePathName();
  const faqPath = path.includes("/help/faq");
  const onPrivacyClick = useCallback(() => {
    window.open(
      language === "de" ? "https://caralegal.eu/app/datenschutzerklaerung" : "https://caralegal.eu/app/privacynotice"
    );
    setAnchorEl(null);
    onClicked?.();
  }, [language, onClicked]);
  const onSupportClick = useCallback(() => {
    window.open("https://intercom-help.eu/caralegal-help-center/en/");
    setAnchorEl(null);
    onClicked?.();
  }, [onClicked]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(event => {
    // seems like event object is re-used by browser, so we need to extract it before we pass it to set state
    // since we use function setter in set state, it can run later, and by then the browser probably have re-used
    // the event object, and changed the current target to something else it wanted
    const currentTarget = event.currentTarget;
    setAnchorEl(anchorEl => (anchorEl ? null : currentTarget));
  }, []);
  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);
  useEffect(() => {
    setAnchorEl(null);
  }, [sidebarOpen]);

  return (
    <>
      <ListItemButton
        sx={{
          backgroundColor: faqPath ? theme.palette.blue[50] : "transparent"
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            color: faqPath ? theme.palette.primary.main : theme.palette.grey[500],
            marginLeft: "6px",
            "& .MuiBadge-colorError": {
              border: "2px solid",
              borderRadius: "16px",
              color: "#ffffff",
              fontSize: "11px",
              height: "auto",
              padding: "4px 6px",
              textAlign: "center"
            }
          }}
        >
          <ContactSupportIcon />
        </ListItemIcon>
        <ListItemText primary={t("help")} />
        <ArrowRightIcon />
      </ListItemButton>
      <Popper open={anchorEl !== null} anchorEl={anchorEl} placement="right-start" transition style={popperZIndex}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper>
            <MenuList id="composition-menu" aria-labelledby="composition-button">
              {[
                { name: t("support"), onClick: onSupportClick },
                { name: t("private_notice"), onClick: onPrivacyClick }
              ]
                .flatMap(it => (it ? [it] : []))
                .map(i => (
                  <MenuItem key={i.name} onClick={i.onClick}>
                    {t(i.name)}
                  </MenuItem>
                ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const popperZIndex: CSSProperties = { zIndex: sidebarZIndex + 1 };

export default HelpSidebar;
