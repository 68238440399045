import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ListItemText, Menu, MenuItem } from "@material-ui/core";
import { ListViewItemProps, Assignee, StyledListItemIcon } from "./ListViewItem";
import { AssigneeAvatar } from "./AssigneeAvatar";
import { LazySvgIcon } from "../LazySvgIcon/LazySvgIcon";

export const AssigneDropdown = (props: Pick<ListViewItemProps, "assignables" | "assignee" | "onAssigneeChange">) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { assignables, assignee, onAssigneeChange } = props;
  const [selectedAssignee, setSelectedAssignee] = useState<Assignee | undefined>(assignee);
  useEffect(() => {
    setSelectedAssignee(assignee);
  }, [assignee]);
  useEffect(() => {
    onAssigneeChange?.(selectedAssignee);
  }, [selectedAssignee, onAssigneeChange]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const isOpen = Boolean(anchorEl);
  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          height: 24
        }}
      >
        <AssigneeAvatar assignee={selectedAssignee} />
      </Box>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            setSelectedAssignee(undefined);
            handleClose();
          }}
        >
          <StyledListItemIcon>
            <LazySvgIcon name="Unassigned" />
          </StyledListItemIcon>
          <ListItemText>Unassigned</ListItemText>
        </MenuItem>
        {assignables?.map(assignable => (
          <MenuItem
            key={assignable.id}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              setSelectedAssignee(assignable);
              handleClose();
            }}
          >
            <StyledListItemIcon>
              <AssigneeAvatar assignee={assignable} />
            </StyledListItemIcon>
            <ListItemText>{assignable.is_deleted ? "Deleted" : assignable.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
