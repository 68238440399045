import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TaskDescription, TaskLabels, TaskTitle } from "./Details";
import { Box } from "@material-ui/core";
import TaskPriority from "../../controls/TaskPriority";

export interface ActionTaskPayloadDTO {
  readonly title: string;
  readonly description: string;
  readonly priority: string;
  readonly labelIds: string[];
  readonly fileIds: string[];
}

const ActionTaskDetails = ({
  actionId,
  actionPayload,
  onActionTaskEdit,
  onCloseActionTaskDetails
}: {
  readonly actionId: string;
  readonly actionPayload: ActionTaskPayloadDTO;
  readonly onActionTaskEdit: (actionId: string, actionPayload: ActionTaskPayloadDTO) => void;
  readonly onCloseActionTaskDetails: () => void;
}) => {
  const [actionModel, setActionModel] = useState<ActionTaskPayloadDTO | null>(null);

  useEffect(() => {
    setActionModel(actionPayload);
  }, [actionPayload]);

  const updateActionTask = useCallback(
    data => {
      const updatedAction = { ...actionModel, ...data };
      setActionModel(updatedAction);
      onActionTaskEdit(actionId, updatedAction);
    },
    [actionId, actionModel, onActionTaskEdit]
  );

  /* ACTION */
  const onChangeTitle = useCallback(title => updateActionTask({ title }), [updateActionTask]);
  const onChangePriority = useCallback(priority => updateActionTask({ priority }), [updateActionTask]);
  const onChangeDescription = useCallback(description => updateActionTask({ description }), [updateActionTask]);
  const onChangeLabels = useCallback(labelIds => updateActionTask({ labelIds }), [updateActionTask]);

  const metaViewContent = useMemo(
    () =>
      actionModel && (
        <Box p={3}>
          <TaskTitle
            title={actionModel?.title || ""}
            onChangeTitle={onChangeTitle}
            onCloseDetails={onCloseActionTaskDetails}
          />
          <TaskPriority priority={actionModel?.priority || ""} onChangePriority={onChangePriority} />
          <TaskDescription description={actionModel?.description || ""} onChageDescription={onChangeDescription} />
          <TaskLabels
            task={{ labels: actionModel?.labelIds || [] }}
            onChangeLabels={onChangeLabels}
            saveOperationInProgress={undefined}
          />
        </Box>
      ),
    [actionModel, onChangeDescription, onChangeLabels, onChangePriority, onChangeTitle, onCloseActionTaskDetails]
  );

  return <>{metaViewContent}</>;
};

export default ActionTaskDetails;
