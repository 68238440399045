import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { Box, Button, TextField } from "@material-ui/core";
import { validateAndConvertPhoneNumber } from "../mfa/phoneNumberValidator";
import { useSnackbar } from "notistack";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { useMetaView } from "app/contexts/meta-view-context";
import { generateMFASecret, generateMFASecretURI } from "app/handlers/authentication/authenticationHandler";
import QRCode from "react-qr-code";

import { MFACheckSMS, MFATokenError } from "../../../handlers/authentication/authenticationError";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "8px 0",
    lineHeight: "72px"
  },
  desc: {
    whiteSpace: "pre-wrap"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  fields: {
    width: "304px",
    margin: "10px 0"
  },
  numberFields: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none"
    }
  }
}));

export default function AccountSettingsMFASetup({ onSuccess }: { onSuccess?: () => Promise<void> }) {
  const classes = useStyles();
  const { t } = useTranslation("accountSettingsMFASetup");
  const { setupMFAHook, user } = useAuthentication();

  const [loading, setLoading] = useState(false);
  const [mfaSecret, setMFASecret] = useState("");
  useEffect(() => {
    generateMFASecret().then(it => setMFASecret(it));
  }, []);
  const [mfaSecretURI, setMFASecretURI] = useState("");
  useEffect(() => {
    generateMFASecretURI({ mfaSecret, email: user?.email || "" }).then(it => setMFASecretURI(it));
  }, [user?.email, mfaSecret]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [mfaToken, setMFAToken] = useState("");
  const [phoneNumberEnabled, setPhoneNumberEnabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { catchAsSnackbar } = useErrorSnackbar();

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo(null);
  }, [setInfo]);

  useEffect(() => {
    setIsValidPhoneNumber(!!validateAndConvertPhoneNumber(phoneNumber));
  }, [phoneNumber]);

  const setupMFACallback = useCallback(async () => {
    setLoading(true);

    try {
      const normalizedPhoneNumber = validateAndConvertPhoneNumber(phoneNumber);
      if (phoneNumber && !normalizedPhoneNumber) {
        enqueueSnackbar(t("invalidPhoneNumber"), { variant: "error" });
        return;
      }

      await setupMFAHook({
        secret: mfaSecret,
        token: mfaToken,
        phoneNumber: phoneNumber
      });
      setLoading(false);
      onSuccess?.();
    } catch (error) {
      setLoading(false);
      if (error instanceof MFACheckSMS) {
        return;
      }
      if (error instanceof MFATokenError) {
        enqueueSnackbar(t("invalidSMS"), { variant: "error" });
        return;
      }

      catchAsSnackbar("unknown error when activating MFA")(error);
    }
  }, [enqueueSnackbar, t, catchAsSnackbar, mfaSecret, mfaToken, phoneNumber, setupMFAHook, onSuccess]);

  const onPhoneNumberChangedCallback = useCallback(event => {
    setPhoneNumber(event.target.value || "");
  }, []);

  const onMFATokenChanged = useCallback(event => {
    setMFAToken(event.target.value || "");
  }, []);

  const onEnterSetupMFACallback = useCallback(
    event => {
      if (event.key === "Enter") {
        return setupMFACallback();
      }
    },
    [setupMFACallback]
  );

  const toggleAuthenticator = useCallback(() => {
    setPhoneNumberEnabled(phoneNumberEnabled => !phoneNumberEnabled);
  }, []);

  return (
    <div>
      {!phoneNumberEnabled && (
        <div>
          <h2 className={classes.title}>{t("authenticator_enter_title")}</h2>
          <p className={classes.desc}>{t("authenticator_enter_desc")}</p>
          <p className={classes.desc}>1. {t("authenticator_enter_step_1")}</p>
          <p className={classes.desc}>2. {t("authenticator_enter_step_2")}</p>
          <QRCode value={mfaSecretURI} />
          <Box sx={{ display: "flex", gridGap: 8 }} mt={2}>
            <Button variant="outlined" color="primary" disabled={loading} onClick={toggleAuthenticator}>
              {t("switch_to_phone_mfa")}
            </Button>
          </Box>
        </div>
      )}
      {phoneNumberEnabled && (
        <div>
          <h2 className={classes.title}>{t("phone_number_enter_title")}</h2>
          <p className={classes.desc}>{t("phone_number_enter_desc")}</p>
          <TextField
            className={classes.fields}
            label={t("phone_number_field")}
            value={phoneNumber}
            onChange={onPhoneNumberChangedCallback}
            variant="outlined"
            aria-label={"phone number"}
          />
          <Box sx={{ display: "flex", gridGap: 8 }} mt={2}>
            <Button variant="outlined" color="primary" disabled={loading} onClick={toggleAuthenticator}>
              {t("switch_to_authenticator_mfa")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || !isValidPhoneNumber}
              onClick={setupMFACallback}
            >
              {t("phone_number_button")}
            </Button>
          </Box>
        </div>
      )}
      <div>
        <h2 className={classes.title}>{t("sms_code_enter_title")}</h2>
        <p className={classes.desc}>{t("sms_code_enter_desc")}</p>
        <div>
          <TextField
            className={classes.fields + " " + classes.numberFields}
            label={t("sms_code_field")}
            value={mfaToken}
            onChange={onMFATokenChanged}
            variant="outlined"
            aria-label={"sms code"}
            onKeyDown={onEnterSetupMFACallback}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || mfaToken?.length !== 6}
              onClick={setupMFACallback}
            >
              {t("sms_code_button")}
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}
