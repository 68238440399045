import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";

const TextEditorQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  textEditorToolbarOptions,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const [_value, _setValue] = useState<string | undefined>("");
  useEffect(() => {
    return _setValue((Array.isArray(value) ? value[0] : value) as string);
  }, [value]);
  const onChangeCallback = useCallback((val: string) => _setValue(val), []);
  const onFocusCallback = useCallback(
    event => {
      onFocus?.(event);
    },
    [onFocus]
  );
  const onBlurCallback = useCallback(
    event => {
      onChange?.(_value);
      onBlur?.(event);
    },
    [_value, onBlur, onChange]
  );
  return (
    <TextEditor
      disabled={disabled}
      title={questionName}
      inputValue={_value}
      error={error}
      helperText={helperText}
      onChange={onChangeCallback}
      onFocus={onFocusCallback}
      onBlur={onBlurCallback}
      toolbarOptions={textEditorToolbarOptions}
    />
  );
};

export default React.memo(TextEditorQuestion);
