import React from "react";
import { Box, Typography } from "@mui/material";

interface QuestionnaireSubHeaderProps {
  readonly text: string;
}
const QuestionnaireSubHeader = ({ text }: QuestionnaireSubHeaderProps) => {
  const sx = {
    root: {
      marginBottom: "16px" // theme.spacing
    },
    typograpy: {
      wordBreak: "break-all"
    }
  };
  return (
    <Box sx={sx.root}>
      <Typography variant="subtitle1" sx={sx.typograpy}>
        {text}
      </Typography>
    </Box>
  );
};

export default QuestionnaireSubHeader;
