import React, { useEffect } from "react";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import AuthenticationPageTemplate from "./AuthenticationPageTemplate";
import { useNavigate, useParams } from "react-router-dom";

export function TenantDirectLink() {
  const { tenantId } = useParams();
  const { user: loggedInUser, changeTenantHook } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedInUser || !tenantId) {
      return;
    }

    const loggedInUserHaveAccessToTenant = loggedInUser.tenants?.some(tenant => tenant.tenantId === tenantId);
    if (!loggedInUserHaveAccessToTenant) {
      navigate("/tenants");
      return;
    }

    changeTenantHook(tenantId).then(() => navigate("/processes"));
  }, [loggedInUser, tenantId, changeTenantHook, navigate]);

  return <AuthenticationPageTemplate />;
}
