import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { isAxiosErrorWithCode, WithAbortController } from "./axios/axiosErrorHandler";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api/groups`
});

export interface GroupDTO {
  readonly tenantId: string;
  readonly id: string;
  readonly name: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly creatorUID: string;
  readonly updaterUID: string;
  readonly userIds: string[];
}

export interface GroupsDTO {
  readonly groups: GroupDTO[];
}

export interface SingleNewGroup {
  readonly name: string;
}

export const getGroupsApi = async (options: WithAbortController = {}): Promise<GroupDTO[]> => {
  const response = await axiosInstance.get<GroupsDTO>("/", {
    signal: options.abortController?.signal
  });
  return response.data?.groups || [];
};

export const getSingleGroupApi = async (id: string): Promise<GroupDTO | null> => {
  try {
    const response = await axiosInstance.get<GroupDTO | null>(`/${id}`);
    return response.data || null;
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404, "NotFound")) {
      return null;
    }
    throw error;
  }
};

export const createGroupApi = async (payload: SingleNewGroup): Promise<string> => {
  const response = await axiosInstance.post("/", payload);
  return response.headers["x-resource-id"] || "";
};

export const updateGroupApi = async (id: string, payload: SingleNewGroup): Promise<void> => {
  await axiosInstance.patch(`/${id}`, payload);
};

export const deleteGroupApi = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/${id}`);
};
