import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

export default function CardTitleComment({
  text,
  translationKey,
  subTitle
}: {
  readonly text: string | React.ReactNode;
  readonly translationKey?: string;
  readonly subTitle?: React.ReactNode;
}) {
  const { t } = useTranslation(translationKey);

  return (
    <div>
      <Typography variant="h4" component="span">
        {translationKey && typeof text === "string" ? t(text) : text}
      </Typography>
      <div>{subTitle}</div>
    </div>
  );
}
