import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DueDate } from "components/DueDate";
import {
  changeTaskAssignee,
  changeTaskTitle,
  deleteTask,
  getTask,
  markTaskAsDone,
  STATUSES,
  updateTaskDeadline
} from "../../../handlers/tasksHandler";
import { Box, CircularProgress, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { Loupe } from "@material-ui/icons";
import DoneIcon from "@material-ui/icons/Done";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import { debounce } from "lodash-es";
import { useSnackbar } from "notistack";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { toJSDateObjectIfFirebaseDate } from "../../../handlers/utility/date-helper";
import AssignUsersMultiAutocomplete from "../../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";

interface InlineTaskDetailsProps {
  readonly existingTaskId: string;
  readonly onDelete: (taskId: string) => void;
  readonly onDone: (taskId: string) => void;
  readonly onShowTaskDetails: (taskId: string) => void;
  readonly onTaskEdit: (taskId: string) => void;
  readonly disabled?: boolean;
}

const InlineTaskDetails = ({
  existingTaskId,
  onDelete,
  onDone,
  onShowTaskDetails,
  onTaskEdit,
  disabled
}: InlineTaskDetailsProps) => {
  const { t } = useTranslation("task_details");
  const { auth } = useAuthentication();

  const [title, setTitle] = useState("");
  const [assignedToUID, setAssignedToUID] = useState("");
  const [assignedToType, setAssignedToType] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [status, setStatus] = useState("");
  const [markingDeleteOrDone, setMarkingDeleteOrDone] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [markAsDoneEnabled, setMarkAsDoneEnabled] = useState(false);
  const { catchAsSnackbar } = useErrorSnackbar();
  const { enqueueSnackbar } = useSnackbar();

  const loadTask = useCallback(async () => {
    try {
      const task = await getTask(existingTaskId);
      if (!task) {
        throw new Error("Task does not exists!");
      }
      return task;
    } catch (e) {
      catchAsSnackbar("Error fetching task");
    }
  }, [catchAsSnackbar, existingTaskId]);

  useEffect(() => {
    const initialLoad = async () => {
      const task = await loadTask();

      if (task) {
        setTitle(task.title || "");
        setAssignedToType(task.assignedToType || "");
        setDeadline(toJSDateObjectIfFirebaseDate(task.dueAt) || null);
        setAssignedToUID(task.assigneeUID || "");
        setStatus(task.status || STATUSES.open);
      }
    };
    initialLoad();
  }, [loadTask]);

  const onAssignTask = useCallback(
    async newAssigneeId => {
      try {
        setAssignedToUID(newAssigneeId);
        await changeTaskAssignee(auth?.tenantId || "", auth?.uid || "", existingTaskId, newAssigneeId);
        onTaskEdit(existingTaskId);
        enqueueSnackbar(t("assigned_message"), { variant: "success" });
      } catch (e) {
        catchAsSnackbar("failed to update task assigneeUID");
      }
    },
    [auth?.tenantId, auth?.uid, catchAsSnackbar, enqueueSnackbar, existingTaskId, onTaskEdit, t]
  );

  const onDeadLineChange = useCallback(
    async deadline => {
      try {
        setDeadline(deadline);
        await updateTaskDeadline(auth?.tenantId || "", auth?.uid || "", existingTaskId, deadline);
        onTaskEdit(existingTaskId);
      } catch (e) {
        catchAsSnackbar("failed to update task deadline");
      }
    },
    [auth?.tenantId, auth?.uid, catchAsSnackbar, existingTaskId, onTaskEdit]
  );

  const updateTitleDebounced = useMemo(
    () =>
      debounce(async (title: string) => {
        try {
          await changeTaskTitle(auth?.tenantId || "", auth?.uid || "", existingTaskId, title);
          onTaskEdit(existingTaskId);
        } catch (e) {
          catchAsSnackbar("failed to update task title");
        }
      }, 500),
    [auth?.tenantId, auth?.uid, catchAsSnackbar, existingTaskId, onTaskEdit]
  );

  const onTitleChange = useCallback(
    async event => {
      const title = event.target.value;
      setTitle(title);
      await updateTitleDebounced(title);
    },
    [updateTitleDebounced]
  );

  const markAsDoneClicked = useCallback(async () => {
    if (!markAsDoneEnabled) {
      return;
    }
    setModalText(t("confirm_task_modal_text"));
    setConfirmModalOpen(true);
  }, [markAsDoneEnabled, t]);

  const setTaskToDone = async function () {
    setMarkingDeleteOrDone(true);

    try {
      await markTaskAsDone(auth?.tenantId || "", auth?.uid || "", existingTaskId);
      setStatus(STATUSES.done);
      enqueueSnackbar(t("done_message"), { variant: "success" });
    } catch (error) {
      catchAsSnackbar("failed to mark task as done")(error);
    } finally {
      setMarkingDeleteOrDone(false);
      setConfirmModalOpen(false);
    }

    if (onDone) {
      onDone(existingTaskId);
    }
  };

  const deleteClicked = useCallback(async () => {
    setMarkingDeleteOrDone(true);
    try {
      await deleteTask(existingTaskId);
      setStatus(STATUSES.deleted);
    } catch (error) {
      catchAsSnackbar("failed to delete task")(error);
    } finally {
      setMarkingDeleteOrDone(false);
    }

    if (onDelete) {
      onDelete(existingTaskId);
    }
  }, [catchAsSnackbar, existingTaskId, onDelete]);

  const showDetailsClicked = useCallback(() => {
    onShowTaskDetails(existingTaskId);
  }, [existingTaskId, onShowTaskDetails]);

  const onCloseConfirmationModal = useCallback(() => {
    setConfirmModalOpen(false);
    setModalText("");
  }, []);

  const onAssignTaskCallback = useCallback((ids: string[]) => onAssignTask(ids[0] || ""), [onAssignTask]);

  useEffect(() => {
    setMarkAsDoneEnabled(Boolean(title && deadline && assignedToUID));
  }, [title, deadline, assignedToUID, status]);

  const shouldDisableInputFields = isAssignmentDisabled(title, assignedToUID, deadline, status);
  const markAsDoneButtonEl = status !== STATUSES.done && (
    <Tooltip title={markAsDoneEnabled ? t("mark_done") : t("disabled_mark_done")}>
      <span>
        <IconButton disabled={!markAsDoneEnabled || disabled} onClick={markAsDoneClicked}>
          <DoneIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
  const removeButtonEl = (status === STATUSES.open || status === STATUSES.todo) && (
    <Tooltip title={t("delete")}>
      <span>
        <IconButton onClick={deleteClicked} disabled={disabled}>
          <DeleteOutlineIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
  const detailsButtonEl = (
    <Tooltip title={t("tasks_page:more_details")}>
      <span>
        <IconButton onClick={showDetailsClicked} disabled={disabled}>
          <Loupe />
        </IconButton>
      </span>
    </Tooltip>
  );
  const allreadyDoneEl = status === STATUSES.done && (
    <Tooltip title={t("done")}>
      <span>
        <IconButton disabled={true}>
          <DoneAllIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
  const loadingEl = <CircularProgress color="inherit" />;

  if (status === STATUSES.deleted) {
    return <></>;
  } else
    return (
      <>
        <ConfirmationModal
          modalOpen={confirmModalOpen}
          modalTitle={t("questionnaires:edit_modal_title")}
          modalText={modalText}
          onClose={onCloseConfirmationModal}
          buttons={[
            {
              confirmButton: false,
              title: t("common:no"),
              variant: "outlined",
              color: "primary",
              size: "medium",
              onClick: () => {
                setConfirmModalOpen(false);
                setModalText("");
              }
            },
            {
              confirmButton: true,
              title: t("common:yes"),
              variant: "contained",
              color: "primary",
              size: "medium",
              onClick: async () => {
                setTaskToDone();
              }
            }
          ]}
        />

        <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={4}>
            <TextField
              label={t("title")}
              value={title}
              onChange={onTitleChange}
              variant="outlined"
              fullWidth={true}
              disabled={status === STATUSES.done || disabled}
              multiline={true}
              helperText={noAssigneeDeadlineOrTitlePresent(title, assignedToUID, deadline) && t("enter_task_title")}
            />
          </Grid>
          <Grid item xs>
            <AssignUsersMultiAutocomplete
              id={"assignee"}
              onDocAssignedUserIdsChanged={onAssignTaskCallback}
              docAssignedUserIds={[assignedToUID]}
              freeSolo={true}
              disableClearable={true}
              label={t("assigned_to")}
              disabled={shouldDisableInputFields || assignedToType === "GROUP" || disabled}
              excludedUserIds={[]}
              hasMultiSelect={false}
            />
          </Grid>
          <Grid item xs>
            <DueDate
              margin="none"
              date={deadline}
              onDateChange={onDeadLineChange}
              disabled={shouldDisableInputFields || disabled}
            />
          </Grid>

          <Grid item xs>
            {!markingDeleteOrDone && (
              <Box textAlign={"right"} style={{ minWidth: "150px", width: "100%" }}>
                {allreadyDoneEl}
                {markAsDoneButtonEl}
                {removeButtonEl}
                {detailsButtonEl}
              </Box>
            )}
            {markingDeleteOrDone && loadingEl}
          </Grid>
        </Grid>
      </>
    );
};

const isAssignmentDisabled = (title: string, assignedToUID: string, deadline: string | null, status: string) => {
  return !!(status === STATUSES.done || noAssigneeDeadlineOrTitlePresent(title, assignedToUID, deadline));
};

const noAssigneeDeadlineOrTitlePresent = (title: string, assignedToUID: string, deadline: string | null) =>
  !title && !assignedToUID && !deadline;

export default InlineTaskDetails;
