import { useTranslation } from "react-i18next";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import React, { useEffect, useMemo, useState } from "react";
import { useBizChartModel } from "./bizchart";
import { getExternalRecipientStatistic, STATS_TYPE } from "../../handlers/dashboardHandler";
import { GraphContainer } from "./GraphContainer";
import { TimeAndCountChart } from "./TimeAndCountChart";
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  mainPieChart: {
    margin: 0
  }
}));

export const ExternalRecipientPieChart = ({ timePeriod, label, orgUnitId }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("dashboardPage");
  const { catchAsSnackbar } = useErrorSnackbar();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const theme = useTheme();

  const { convertStatsToBizChartModel } = useBizChartModel();
  useEffect(() => {
    const init = async () => {
      const externalRecipientStats = await getExternalRecipientStatistic(timePeriod?.id, label, orgUnitId);
      const chartData = convertStatsToBizChartModel(
        externalRecipientStats,
        timePeriod?.id === STATS_TYPE.ALL_TIME && {
          month: "long",
          year: "numeric"
        },
        i18n.language
      );
      setChartData(chartData);
      setLoading(false);
    };

    if (Object.values(STATS_TYPE).includes(timePeriod?.id)) {
      init().catch(catchAsSnackbar("failed to load Data Breach statistic data"));
    }
  }, [timePeriod?.id, convertStatsToBizChartModel, catchAsSnackbar, i18n.language, label, orgUnitId]);

  const chartScale = useMemo(
    () => ({
      count: { min: 0 },
      type: {
        formatter: type => {
          switch (type) {
            case "numberApproved":
              return t("dashboardPage:approved");
            case "numberNotApproved":
              return t("dashboardPage:not_approved");
            default:
              return type;
          }
        }
      }
    }),
    [t]
  );

  const color = useMemo(
    () => [
      "type",
      type => {
        switch (type) {
          case "numberNotApproved":
            return theme.palette.orange[300];
          case "numberApproved":
          default:
            return theme.palette.green[400];
        }
      }
    ],
    [theme]
  );

  return (
    <GraphContainer height={470} title={t("dashboardPage:serviceproviders")} loading={loading}>
      <TimeAndCountChart
        className={classes.mainPieChart}
        timePeriodId={timePeriod?.id}
        chartScale={chartScale}
        chartData={chartData}
        color={color}
        explicitChartType={"pie"}
        chartHeight={390}
      />
    </GraphContainer>
  );
};
