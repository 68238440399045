import { UserDTO } from "app/api/user/userApi";
import { getI18n } from "../i18n";

export const getUserName = (
  tenantUsers: Pick<UserDTO, "id" | "email" | "firstName" | "lastName">[],
  userId: string
) => {
  if (userId === "system") {
    return getI18n().t("common:system");
  }

  const userData = tenantUsers.find(user => user.id === userId);
  if (!(userData && userData.email)) {
    return "";
  }

  if (userData.firstName && userData.lastName) {
    return `${userData.firstName} ${userData.lastName}`;
  } else {
    return userData.email;
  }
};

export const getUserNameEmail = (
  tenantUsers: Pick<UserDTO, "id" | "email" | "firstName" | "lastName">[],
  userId: string
) => {
  const userData = tenantUsers.find(user => user.id === userId);
  if (!(userData && userData.email)) {
    return "";
  }

  if (userData.firstName && userData.lastName) {
    return `${userData.firstName} ${userData.lastName} - ${userData.email}`;
  } else {
    return userData.email;
  }
};
