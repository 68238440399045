import { CSSProperties } from "react";

// all custom styles shiran (current designer) asked to apply
// this will probably become standard in the future, and then we should then play with theme.js instead of doing this

export const noUppercaseOpenSansButton: CSSProperties = {
  textTransform: "none",
  fontFamily: "SuisseIntl",
  fontWeight: 600
};
