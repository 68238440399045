import { useTranslation } from "react-i18next";
import GeneralPageStepper, { PageStep } from "../../../components/Pagination/GeneralPageStepper";
import React, { useEffect, useState } from "react";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";

function usePaginationSteps() {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<PageStep[]>([]);

  useEffect(() => {
    setSteps([
      {
        label: t("pa_general:page_title"),
        path: "general"
      },
      {
        label: t("pagination:processing"),
        path: "processing"
      },
      {
        label: t("pagination:data-flow"),
        path: "third-country-transfer"
      },
      {
        label: t("processor_pa_page:measure"),
        path: "measure"
      }
    ]);
  }, [t]);

  return {
    steps
  };
}

export function ProcessorPAPageStepper() {
  const { steps } = usePaginationSteps();

  return <GeneralPageStepper steps={steps} />;
}

export function ProcessorPAPageButtons({ rightArea }: { readonly rightArea?: React.ReactNode }) {
  const { steps } = usePaginationSteps();

  return <GeneralPageButtonPagination steps={steps} rightArea={rightArea} />;
}
