import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { ClickAwayListener } from "@material-ui/core";
import RenderIcon from "components/RenderIcon/RenderIcon";

const docMetaViewBoxShadow = "0px 0px 40px 0px rgba(204,204,204,0.50)";

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1)
  },
  moreHorizEllipsisPaper: {
    boxShadow: docMetaViewBoxShadow,
    padding: "5px 40px 5px 5px"
  },
  moreHorizEllipsisMenuIcon: {
    cursor: "pointer"
  },
  moreHorizEllipsisMenuIconLists: {
    display: "flex",
    padding: "5px",
    fontSize: "13px",
    cursor: "pointer",
    "& svg": {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1)
    }
  }
}));

const RenderEllipsisItem = function ({ icon, iconSize, title, callBackFunction, elementName, elementId, setIsOpen }) {
  const classes = useStyles();

  const listClickCallback = useCallback(
    event => {
      event?.stopPropagation?.();
      setIsOpen(false);
      callBackFunction(elementId, elementName);
    },
    [setIsOpen, callBackFunction, elementId, elementName]
  );

  return (
    <div className={classes.moreHorizEllipsisMenuIconLists} onClick={listClickCallback}>
      <RenderIcon iconSize={iconSize} icon={icon} elementId={elementId} />
      <span>{title}</span>
    </div>
  );
};

/**
 *This component displays an ellipsis menu
 */
export default function EllipsisMenu({ iconSize, data, elementName, elementId, orientationIcons, passedClasses }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const clickAwayHandler = () => setIsOpen(false);
  const [placement, setPlacement] = React.useState();
  const classes = useStyles();

  const handleClick = newPlacement => event => {
    event.stopPropagation();
    if (isOpen === false) {
      setAnchorEl(event.currentTarget);
      setIsOpen(true);
      setPlacement(newPlacement);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div className={passedClasses}>
        <Popper open={isOpen} anchorEl={anchorEl} placement={placement} transition style={{ zIndex: 5000 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={10}>
              <Paper className={classes.moreHorizEllipsisPaper}>
                {data.map((item, key) => {
                  return (
                    <RenderEllipsisItem
                      iconSize={iconSize}
                      {...item}
                      key={key}
                      elementName={elementName}
                      elementId={elementId}
                      setIsOpen={setIsOpen}
                    />
                  );
                })}
              </Paper>
            </Fade>
          )}
        </Popper>
        {!orientationIcons && (
          <MoreHorizIcon
            data-testid={"horizontal-ellipsis-menu"}
            onClick={handleClick("bottom-end")}
            className={classes.moreHorizEllipsisMenuIcon}
          />
        )}
        {orientationIcons && orientationIcons === "vertical" && (
          <MoreVertIcon onClick={handleClick("bottom-end")} className={classes.moreHorizEllipsisMenuIcon} />
        )}
      </div>
    </ClickAwayListener>
  );
}
