import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  changeTenant,
  getLoggedInUser,
  removeMFA,
  setupMFA,
  signOut
} from "app/handlers/authentication/authenticationHandler";
import { LoggedInUser } from "./authenticationHandlerInterfaces";
import { isRefreshTokenAboutToExpire } from "./authenticationTokenHandler";
import { UpdateMFAPayload } from "../../api/user/userMeApiInterfaces";
import { signInWithMFA, signInWithPassword, signInWithSSOToken } from "./authenticationSignInHandler";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isUserDepartmentBound } from "../permissionHandler";
import { SWR_KEYS } from "app/swrKeys";
import { LoggedOutInactiveError } from "./authenticationError";
import { getLoginPreference } from "./authenticationStorage";

export interface AuthenticationContextType {
  readonly loading: boolean;
  readonly user: LoggedInUser | null;
  readonly auth: AuthenticatedUserInTenant | null;
  readonly reloadUserHook: () => Promise<LoggedInUser>;
  readonly signOutUserHook: () => Promise<void>;
  readonly signInUserHook: (emailOrUserId: string, password: string, mfaToken?: string) => Promise<void>;
  readonly ssoCallbackHook: (ssoToken: string) => Promise<void>;
  readonly changeTenantHook: (tenantId: string) => Promise<void>;
  readonly setupMFAHook: (updateMFA: UpdateMFAPayload) => Promise<void>;
  readonly removeAllMFAsHook: () => Promise<void>;
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  // initialize initial values, for those who needs explicit return types, throw an error
  loading: true,
  user: null,
  auth: null,
  signOutUserHook: async () => {
    throw new Error("Yet not initialized");
  },
  signInUserHook: async () => {
    throw new Error("Yet not initialized");
  },
  ssoCallbackHook: async () => {
    throw new Error("Yet not initialized");
  },
  changeTenantHook: async () => {
    throw new Error("Yet not initialized");
  },
  reloadUserHook: async () => {
    throw new Error("Yet not initialized");
  },
  setupMFAHook: async () => {
    throw new Error("Yet not initialized");
  },
  removeAllMFAsHook: async () => {
    throw new Error("Yet not initialized");
  }
});

export interface AuthenticatedUserInTenant {
  readonly uid: string;
  readonly tenantId: string;
  readonly role: string;
  readonly isUserDepartmentBound: boolean;
  readonly featureIds: string[];
  readonly furtherOrgUnitIds?: string[];
  readonly orgUnitId: string;
  readonly permissions: string[];
}

export class UnauthorizedTenantAccess extends Error {}

export const AuthenticationProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation("authentication");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<LoggedInUser | null>(null);
  const [authenticatedTenantUser, setAuthenticatedTenantUser] = useState<AuthenticatedUserInTenant | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const logoutUser = useCallback(async () => {
    setAuthenticatedTenantUser(null);
    setUser(null);
    await signOut();
    setLoading(false);
  }, []);

  const navigate = useNavigate();

  useSWR(
    [SWR_KEYS.user],
    async () => {
      const loggedInUser = await getLoggedInUser();
      const loginPreference = await getLoginPreference();
      return { loggedInUser, loginPreference };
    },
    {
      onError: async error => {
        // refresh token is not valid anymore
        if (error instanceof LoggedOutInactiveError) {
          enqueueSnackbar(t("error_logged_out"), { variant: "error" });
          setLoading(true);
          navigate("/logout");
        }
      },
      async onSuccess(userInfo) {
        setUser(userInfo?.loggedInUser);
        setLoading(false);

        const availableTenants = userInfo?.loggedInUser?.tenants;
        if (!availableTenants) {
          return;
        }
        const selectedTenantId = userInfo?.loginPreference?.tenantId;
        if (!selectedTenantId) {
          // no selected tenant, then just exit
          return;
        }
        const selectedTenantIsAvailable = availableTenants.find(tenant => tenant.tenantId === selectedTenantId);
        if (selectedTenantIsAvailable) {
          // if selected tenant exists and it's in the list of tenants, then just exit
          return;
        }

        // otherwise, the user might have been deactivated from the selected tenant, so ask user to pick another one
        if (availableTenants.length !== 0) {
          await reloadUserHook();
          navigate("/tenants");
          return;
        }

        // or log out if there are no more tenants left to pick
        enqueueSnackbar(t("error_logged_out"), { variant: "error" });
        setLoading(true);
        navigate("/logout");
      },
      // refresh every minute to check in case the user has been deactivated recently
      refreshInterval: 1000 * 60
    }
  );

  const genericChangeState = useCallback(
    async changerFn => {
      setLoading(true);
      const result = await changerFn().catch((error: unknown) => {
        setLoading(false);
        throw error;
      });
      setLoading(false);
      return result;
    },
    [setLoading]
  );

  const reloadUser = useCallback(
    async () =>
      genericChangeState(async () => {
        const loggedInUser = await getLoggedInUser();
        setUser(loggedInUser);
      }),
    [genericChangeState]
  );

  const reloadUserHook = useCallback(
    async () =>
      genericChangeState(async () => {
        const loggedInUser = await getLoggedInUser();
        setUser(loggedInUser);
        return loggedInUser;
      }),
    [genericChangeState]
  );

  const signInUserHook = useCallback(
    (emailOrUserId, password, mfaToken) => {
      return genericChangeState(async () => {
        if (mfaToken) {
          await signInWithMFA({ emailOrUserId, password, mfaToken });
        } else {
          await signInWithPassword({ emailOrUserId, password });
        }
        await reloadUser();
      });
    },
    [genericChangeState, reloadUser]
  );

  const ssoCallbackHook = useCallback(
    ssoToken => {
      return genericChangeState(async () => {
        await signInWithSSOToken({ ssoToken });
        await reloadUser();
      });
    },
    [genericChangeState, reloadUser]
  );

  const changeTenantHook = useCallback(
    async (tenantId: string) => {
      await genericChangeState(async () => {
        if (!user?.tenants) {
          throw new UnauthorizedTenantAccess();
        }
        const tenantInfo = await changeTenant({ tenants: user.tenants, id: user.id }, tenantId);
        const selectedTenantId = tenantInfo.tenantId;
        const userRoleInSelectedTenant = tenantInfo.userRole;
        if (!selectedTenantId || !userRoleInSelectedTenant) {
          throw new UnauthorizedTenantAccess();
        }
        setAuthenticatedTenantUser({
          uid: user.id,
          tenantId: selectedTenantId,
          role: userRoleInSelectedTenant,
          isUserDepartmentBound: isUserDepartmentBound({ role: userRoleInSelectedTenant }),
          featureIds: tenantInfo.featureIds || [],
          orgUnitId: tenantInfo.orgUnitId || "",
          furtherOrgUnitIds: tenantInfo.furtherOrgUnitIds || [],
          permissions: tenantInfo.permissions || []
        });
      });
    },
    [genericChangeState, user?.id, user?.tenants]
  );

  const setupMFAHook = useCallback(
    (updateMFA: UpdateMFAPayload) => {
      return genericChangeState(async () => {
        await setupMFA(updateMFA);
        await reloadUser();
      });
    },
    [genericChangeState, reloadUser]
  );

  const removeAllMFAsHook = useCallback(async () => {
    return genericChangeState(async () => {
      await removeMFA();
      await reloadUser();
    });
  }, [genericChangeState, reloadUser]);

  const signOutUserHook = useCallback(() => {
    return genericChangeState(() => logoutUser());
  }, [genericChangeState, logoutUser]);

  useEffect(() => {
    const autoLogoutId = setInterval(
      () =>
        isRefreshTokenAboutToExpire().then(isAboutToExpire => {
          if (isAboutToExpire) {
            return signOutUserHook();
          }
        }),
      1000 * 60 * 5
    );
    return () => clearInterval(autoLogoutId);
  }, [signOutUserHook]);

  const value: AuthenticationContextType = {
    loading,
    user: user,
    auth: authenticatedTenantUser,
    reloadUserHook,
    signOutUserHook,
    signInUserHook,
    ssoCallbackHook,
    changeTenantHook,
    setupMFAHook,
    removeAllMFAsHook
  };
  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export const useAuthentication = (): AuthenticationContextType => useContext(AuthenticationContext);
