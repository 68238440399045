import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HTTPBackend, { HttpBackendOptions } from "i18next-http-backend";
import LocalStorageBackend, { LocalStorageBackendOptions } from "i18next-localstorage-backend"; // primary use cache
import ChainedBackend, { ChainedBackendOptions } from "i18next-chained-backend";

import en from "../assets/i18n/en.json";
import de from "../assets/i18n/de.json";
import { allAvailableLanguages } from "./handlers/languageHandler";

const translationVersion = import.meta.env.VITE_RELEASE_ID || `local-${new Date().toISOString()}`;
i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      // we cache the translation files in local storage, if not, we fetch from the server
      backends: [LocalStorageBackend, HTTPBackend],
      backendOptions: [
        {
          // invalidate cache in local storage if we redeploy
          defaultVersion: translationVersion
        } satisfies LocalStorageBackendOptions,
        {
          // this path is generated by our vite translation plugin, check vite config
          // the version is to not cache translation when we redeploy
          loadPath: `/i18n/{{lng}}/{{ns}}.json?v=${translationVersion}`
        } satisfies HttpBackendOptions
      ]
    } satisfies ChainedBackendOptions,
    partialBundledLanguages: true, // we bundle en and de since it's used 90% of the time still
    fallbackLng: code => {
      if (!code) {
        return ["en"];
      }

      if (!code.includes("-")) {
        return [code, "en"];
      }

      const languageCode = code.split("-")[0];
      return [code, languageCode, "en"];
    },
    // define explicitly supported language to avoid trying to fetch unsupported languages,
    // e.g. instead of trying to fetch for en-US, just fetch directly en
    supportedLngs: allAvailableLanguages,
    debug: false,

    ns: [], // no default namespace to not make empty translations fetch
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

const bundledTranslationFiles = [
  {
    lang: "de",
    file: de
  },
  {
    lang: "en",
    file: en
  }
];

// the process where we bundle en and json as it's used 90% of the time
// the increase in index.js size is minimal with 2 languages, and it does make things faster by reducing api calls
for (const { lang, file } of bundledTranslationFiles) {
  for (const [namespace, translation] of Object.entries(file)) {
    i18n.addResourceBundle(lang, namespace, translation);
  }
}

export default i18n;

export const getI18n = () => i18n;
