import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useRights } from "../../../api/process/rightsPageApi";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import RadioEditorCombo from "../utils/RadioEditorCombo";
import { useProcessPage } from "../../../contexts/process-page-context";
import { UpdateRightsPageDTO } from "../../../api/generated/process-service";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";

const ProcessRightsPage = ({ readonly }: { readonly readonly?: boolean }) => {
  const { id } = useParams();
  const { t } = useTranslation("questionnaire_four_three_page");
  const { data, actions, mutate } = useRights({ documentId: id || "" });
  useEnteringInfoCard({
    pathName: `/processes/${id}/rights`,
    infoId: "infocard.dpia.page9"
  });

  const { onBeforeProcessUpdate, setProcessMeta, resetSignal } = useProcessPage();
  useEffect(() => {
    if (data?.processMeta) {
      setProcessMeta(data.processMeta);
    }
  }, [data?.processMeta, setProcessMeta]);

  useEffect(() => {
    if (resetSignal === 0) {
      return;
    }

    mutate();
  }, [resetSignal, mutate]);

  const triggerUpdate = useCallback(
    async (payload: UpdateRightsPageDTO) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateRights(id || "", payload);
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );
  const mergeUpdates = useCallback((acc, curr) => {
    const fieldNameKey = curr.fieldName as keyof UpdateRightsPageDTO;
    const existingField = acc[fieldNameKey];

    return {
      ...acc,
      [fieldNameKey]: {
        response: curr.radioSelect === undefined ? existingField?.response : curr.radioSelect,
        process: curr.text === undefined ? existingField?.process : curr.text
      }
    };
  }, []);

  const { queueUpdates } = useUpdateQueues({
    triggerUpdate,
    mergeUpdates,
    resetSignal
  });

  const radioOptions = useMemo(() => [t("yes"), t("no")], [t]);

  const processElements = !data?.processPage ? (
    <Box textAlign={"center"} mt={8}>
      <CircularProgress />
    </Box>
  ) : (
    <React.Fragment key={`body-${resetSignal}`}>
      {Object.entries(data?.processPage).map(([key, value], index) => {
        return (
          <RadioEditorCombo
            questionId={"rights " + index}
            key={key}
            fieldName={key}
            radioSelect={value.response}
            text={value.process}
            onSave={queueUpdates}
            tRadioOptions={radioOptions}
            radioTitle={t(key)}
            editorTitle={t(`measures_${key}`)}
            disabled={readonly}
          />
        );
      })}
    </React.Fragment>
  );

  return (
    <>
      <QuestionnaireSubHeader text={t("title")} />
      {[t("titleBody1")]}
      <Box pt={5} mb={5}>
        {processElements}
      </Box>
    </>
  );
};
export default ProcessRightsPage;
