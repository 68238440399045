import { useGetAITechDocApi, useUpdateAITechDocApi } from "../../../api/aiTechDocApi";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { UpdateAITechDocDTO } from "../../../api/generated/aitechdoc-service";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";

export const AITechDocGeneral = ({ readonly, id }: { readonly readonly?: boolean; readonly id: string }) => {
  const { data: aiTechDoc } = useGetAITechDocApi({ documentId: id || "" });
  const { updateAITechDoc } = useUpdateAITechDocApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAITechDocDTO, UpdateAITechDocDTO>({
    triggerUpdate: updateAITechDoc
  });

  const { auth } = useAuthentication();

  const { t } = useTranslation("aitechdoc_details");
  const { setInfo } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const [description, setDescription] = useState<string>("");
  const [mainOrgUnitId, setMainOrgUnitId] = useState<string>("");
  const [furtherOrgUnitIds, setFurtherOrgUnitIds] = useState<string[]>([]);
  const [assigneeUID, setAssigneeUID] = useState<string>("");
  const [labelIds, setLabelIds] = useState<string[]>([]);
  useEffect(() => {
    if (aiTechDoc) {
      setDescription(aiTechDoc.description || "");
      setMainOrgUnitId(aiTechDoc.mainOrgUnitId || auth?.orgUnitId || "");
      setFurtherOrgUnitIds(aiTechDoc.furtherOrgUnitIds || []);
      setAssigneeUID(aiTechDoc.assigneeUID || "");
      setLabelIds(aiTechDoc.labelIds || []);
    }
  }, [aiTechDoc, auth?.orgUnitId]);

  const onDescriptionChange = useCallback(
    (value: string) => {
      setDescription(value);
      queueUpdates({ description: value });
    },
    [queueUpdates]
  );

  const onMainOrgUnitIdChange = useCallback(
    (value: string) => {
      setMainOrgUnitId(value);
      queueUpdates({ mainOrgUnitId: value });
    },
    [queueUpdates]
  );

  const onFurtherOrgUnitIdsChange = useCallback(
    (value: string[]) => {
      setFurtherOrgUnitIds(value);
      queueUpdates({ furtherOrgUnitIds: value });
    },
    [queueUpdates]
  );

  const onAssigneeUIDChange = useCallback(
    (value: string | string[]) => {
      const singleValue = Array.isArray(value) ? value[0] : value;
      setAssigneeUID(singleValue || "");
      queueUpdates({ assigneeUID: singleValue || "" });
    },
    [queueUpdates]
  );

  const onLabelIdsChange = useCallback(
    (value: string[]) => {
      setLabelIds(value);
      queueUpdates({ labelIds: value });
    },
    [queueUpdates]
  );

  const docOrgUnitIds = useMemo(() => [mainOrgUnitId, ...furtherOrgUnitIds], [mainOrgUnitId, furtherOrgUnitIds]);

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("generalInformation")} />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.TEXT_EDITOR}
          questionId={"aitechdoc-description"}
          questionName={t("description")}
          value={description}
          onChange={onDescriptionChange}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
          questionId={"aitechdoc-mainOrgUnitId"}
          questionName={t("mainOrgUnitId")}
          value={mainOrgUnitId}
          onChange={onMainOrgUnitIdChange}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.ORG_UNITS_PICKER}
          questionId={"aitechdoc-furtherOrgUnitIds"}
          questionName={t("furtherOrgUnitIds")}
          value={furtherOrgUnitIds}
          onChange={onFurtherOrgUnitIdsChange}
          disabled={readonly}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" gap={6}>
        <Box flex={1}>
          <Question
            qType={QUESTION_TYPE.ASSIGNED_TO_USER}
            questionId={"aitechdoc-assigneeUID"}
            questionName={t("assigneeUID")}
            value={assigneeUID}
            onChange={onAssigneeUIDChange}
            disabled={readonly}
            orgUnitIds={docOrgUnitIds}
          />
        </Box>
        <Box flex={1}>
          <Question
            qType={QUESTION_TYPE.LABELS}
            questionId={"aitechdoc-labelIds"}
            questionName={t("labelIds")}
            value={labelIds}
            onChange={onLabelIdsChange}
            disabled={readonly}
            orgUnitIds={docOrgUnitIds}
          />
        </Box>
      </Box>
    </Box>
  );
};
