import {
  VersionHistoryItem,
  VersionHistoryItems,
  VersionHistoryProps,
  VersionHistoryWrapper
} from "../../../../../components/VersionHistory/VersionHistory";
import React, { useCallback, useEffect, useState } from "react";
import { usePAVersionHistory } from "../../../../api/process/paVersionHistoryApi";
import { VersionHistoriesDTO, VersionHistoryDTO } from "../../../../api/generated/process-service";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { useTranslation } from "react-i18next";

export default function QuestionnaireVersionHistory() {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data, isLoading, setSize } = usePAVersionHistory({
    documentId: id || "",
    refreshInterval: 5000
  });

  const isMoreAvailable = !!(data && data.length > 0 && data[data.length - 1].nextCursor);
  const onLoadMoreClicked = useCallback(() => {
    return setSize(size => size + 1);
  }, [setSize]);

  return (
    <Box>
      {isLoading && (
        <Box display="flex" alignItems="center" justifyContent="center" m={2}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Box>
          <VersionHistoryWrapper>
            {(data || []).map(versionHistoryResponse => {
              const lastPage = !versionHistoryResponse.nextCursor;
              return (
                <VersionHistoryConverter
                  key={versionHistoryResponse.nextCursor || "last-page"}
                  versionHistoryResponse={versionHistoryResponse}
                  lastPage={lastPage}
                />
              );
            })}
          </VersionHistoryWrapper>
        </Box>
      )}
      {isMoreAvailable && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button onClick={onLoadMoreClicked} variant="outlined" color="primary">
            {t("common:loadMore")}
          </Button>
        </Box>
      )}
    </Box>
  );
}

const VersionHistoryConverter = ({
  versionHistoryResponse,
  lastPage
}: {
  readonly versionHistoryResponse: VersionHistoriesDTO;
  readonly lastPage: boolean;
}) => {
  const { getUserNameHook } = useUserAndTenantData();

  const [historyData, setHistoryData] = useState<VersionHistoryProps["historyData"]>([]);
  useEffect(() => {
    const historyData = (versionHistoryResponse.timelineEvents || [])
      .map(toVersionHistoryItem)
      .reduce(groupRepeatingItems, [])
      .map(it => ({
        ...it,
        userName: getUserNameHook(it.userName),
        additionalUserName: it.additionalUserName ? getUserNameHook(it.additionalUserName) : it.additionalUserName
      }));
    setHistoryData(historyData);
  }, [versionHistoryResponse, getUserNameHook]);
  return <VersionHistoryItems historyData={historyData} lastPage={lastPage} />;
};

const toVersionHistoryItem = (event: VersionHistoryDTO): VersionHistoryItem => {
  return {
    key: event.eventId,
    description: event.eventDescription || "",
    userName: event.changerUID || "",
    date: event.timestamp ? new Date(event.timestamp) : new Date(),
    dotColor: selectDotColor(event.eventType || ""),
    repeated: 0,
    additionalDescription: event.triggerEvent?.eventDescription,
    additionalUserName: event.triggerEvent?.changerUID
  };
};

const groupRepeatingItems = (acc: VersionHistoryItem[], next: VersionHistoryItem): VersionHistoryItem[] => {
  if (acc.length === 0) {
    return [next];
  }
  const last = acc[acc.length - 1];
  const sameDescriptionAndUser = last.description === next.description && last.userName === next.userName;
  const inTenMinutesDifference = Math.abs(last.date.getTime() - next.date.getTime()) < 10 * 60 * 1000;
  if (sameDescriptionAndUser && inTenMinutesDifference) {
    return [...acc.slice(0, acc.length - 1), { ...next, repeated: (last.repeated || 1) + 1 }];
  }
  return [...acc, next];
};

const selectDotColor = (eventType: string): string => {
  switch (eventType) {
    case "pa/submittedForReview":
      return "blue";
    case "pa/approved":
      return "green";
    case "pa/setToEdit":
    case "pa/created":
    case "pa/editRequested":
      return "orange";
    default:
      return "grey";
  }
};
