import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { COLLECTIONS } from "../../collections";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import Box from "@material-ui/core/Box";
import { useUpdateQueues } from "../../../hook/useUpdateQueues";
import DocView from "../../../components/DocView/DocView";
import { UpdateAITechDocDTO, UpdateAITechDocDTOStatusEnum } from "../../api/generated/aitechdoc-service";
import { AITechDocPageButtons, AITechDocPageStepper } from "./AITechDocPagination";
import { useTranslation } from "react-i18next";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { useGetAITechDocApi, useUpdateAITechDocApi } from "../../api/aiTechDocApi";
import { AITechDocGeneral } from "./pages/AITechDocGeneral";
import { AITechDocTechnicalDetail } from "./pages/AITechDocTechnicalDetail";
import { AITechDocTrainingData } from "./pages/AITechDocTrainingData";

export const AITechDocPage = () => {
  const { id, page } = useParams();
  const { isLoading, data, error } = useGetAITechDocApi({ documentId: id || "" });
  const docName = data?.name || "-";
  const [documentNotFound, setDocumentNotFound] = useState(false);

  useEffect(() => {
    if (isAxiosErrorWithCode(error, 404) || isAxiosErrorWithCode(error, 403)) {
      setDocumentNotFound(true);
    } else {
      setDocumentNotFound(false);
    }
  }, [error]);

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.AI_TECH_DOCS} />;
  }

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          collection={COLLECTIONS.AI_TECH_DOCS}
          docId={id}
          docName={docName}
          pageId={page || "general"}
          tabs={metaViewTabIds}
          translationKey={"aitechdoc_details"}
        />
      }
      loading={isLoading}
    >
      <AITechDocDocView key={page} readonly={data?.permission !== "write"}>
        <Box>
          <Box mt={4}>
            {page === "general" && <AITechDocGeneral readonly={data?.permission !== "write"} id={id || ""} />}
            {page === "technical-detail" && (
              <AITechDocTechnicalDetail readonly={data?.permission !== "write"} id={id || ""} />
            )}
            {page === "training-data" && (
              <AITechDocTrainingData readonly={data?.permission !== "write"} id={id || ""} />
            )}
          </Box>
          <Box>
            <AITechDocPageButtons />
          </Box>
        </Box>
      </AITechDocDocView>
    </DocMetaView>
  );
};

export const AITechDocDocView = ({
  children,
  readonly
}: {
  readonly children: React.ReactNode;
  readonly readonly: boolean;
}) => {
  const { t } = useTranslation("aitechdoc_details");
  const { id } = useParams();
  const { data } = useGetAITechDocApi({ documentId: id || "" });
  const docName = data?.name || "-";
  const { updateAITechDoc } = useUpdateAITechDocApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAITechDocDTO, UpdateAITechDocDTO>({
    triggerUpdate: updateAITechDoc
  });

  const aiTechDocsStatusIconMap: Record<UpdateAITechDocDTOStatusEnum, React.ReactNode> = useMemo(
    () => ({
      [UpdateAITechDocDTOStatusEnum.Edit]: <LazySvgIcon name="In_Progress" />,
      [UpdateAITechDocDTOStatusEnum.Review]: <LazySvgIcon name="In_Review" />,
      [UpdateAITechDocDTOStatusEnum.Approved]: <LazySvgIcon name="Completed" />
    }),
    []
  );

  const statuses = useMemo(() => {
    return Object.values(UpdateAITechDocDTOStatusEnum).map(status => ({
      id: status,
      label: t(`aitechdoc_overview:status_${status.toLowerCase()}`),
      icon: aiTechDocsStatusIconMap[status]
    }));
  }, [aiTechDocsStatusIconMap, t]);

  const onHeaderChange = useCallback(
    async (name: string) => {
      await queueUpdates({ name });
    },
    [queueUpdates]
  );
  const onStatusChange = useCallback(
    async (status: string) => {
      await queueUpdates({ status: status as UpdateAITechDocDTOStatusEnum });
    },
    [queueUpdates]
  );

  return (
    <DocView
      header={docName}
      onHeaderChange={onHeaderChange}
      pagination={<AITechDocPageStepper />}
      statusId={data?.status}
      statuses={statuses}
      disabled={readonly}
      onStatusChange={onStatusChange}
    >
      {children}
    </DocView>
  );
};

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.COMMENTS, META_VIEW_TABS.TODOS];
