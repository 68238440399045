import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { uniqueId } from "lodash-es";
import React from "react";
import useSWR from "swr";
import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { CommentApi, CommentModelDB, CommentModelDTO } from "./generated/comment-service";
import { SWR_KEYS } from "app/swrKeys";

export const commentClient = new CommentApi(undefined, apiEndpoints.commentUrl, defaultOTCAuthenticatedAxios());

export const useComments = (
  args: { documentId: string; namespace?: string | null; namespacePrefix?: string } | { commentId: string }
) => {
  const { user } = useAuthentication();
  const comments = useSWR(
    [SWR_KEYS.comments, "commentId" in args ? args.commentId : [args.documentId, args.namespace]].flat(),
    async () => {
      return "commentId" in args
        ? commentClient.getCommentReplies(args.commentId).then(resp => resp.data)
        : commentClient
            .getComments(args.namespace ?? undefined, args.documentId ?? undefined, args.namespacePrefix ?? undefined)
            .then(resp => resp.data);
    },
    {
      isPaused() {
        return "commentId" in args ? !args.commentId : !args.documentId;
      }
    }
  );
  const { mutate } = comments;
  return {
    ...comments,
    addComment: React.useCallback(
      async (comment: NonNullable<CommentModelDTO>) => {
        const optimisticComment: CommentModelDB = {
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          contentState: comment.contentState || { entityMap: {}, blocks: [] },
          documentId: comment.documentId,
          namespace: comment.namespace,
          id: uniqueId(),
          creatorUID: user?.id || "fakeUID",
          updaterUID: user?.id || "fakeUID",
          mentionedUsers: comment.mentionedUsers || [],
          value: comment.value || ""
        };
        let commentId = "";
        await mutate(
          commentClient.createComment(comment).then(resp => {
            commentId = resp.headers["x-resource-id"] || "";
            return { commentModels: [] };
          }),
          {
            populateCache: false,
            optimisticData(currentData) {
              return {
                ...currentData,
                commentModels: currentData?.commentModels
                  ? [...currentData.commentModels, optimisticComment]
                  : [optimisticComment]
              };
            }
          }
        );
        return commentId;
      },
      [mutate, user?.id]
    ),
    deleteComment: React.useCallback(
      async (commentId: string) => {
        await commentClient.deleteComment(commentId);
        comments.mutate({
          commentModels: comments.data?.commentModels
            ? comments.data.commentModels.filter(comment => comment.id !== commentId)
            : []
        });
      },
      [comments]
    ),
    updateComment: React.useCallback(
      async (commentId: string, comment: CommentModelDTO) => {
        await commentClient.updateComment(commentId, comment);
        comments.mutate({
          commentModels: comments.data?.commentModels
            ? comments.data.commentModels.map(comment => {
                if (comment.id === commentId) {
                  return {
                    ...comment,
                    contentState: comment.contentState,
                    documentId: comment.documentId,
                    namespace: comment.namespace,
                    id: comment.id,
                    creatorUID: comment.creatorUID,
                    updaterUID: comment.updaterUID,
                    mentionedUsers: comment.mentionedUsers,
                    value: comment.value
                  };
                }
                return comment;
              })
            : []
        });
      },
      [comments]
    )
  };
};
