import React from "react";
import { Typography, useTheme } from "@mui/material";

interface QuestionTitleProps {
  readonly disabled?: boolean;
  readonly children?: React.ReactNode;
}

export default function QuestionTitle({ disabled, children }: QuestionTitleProps) {
  const theme = useTheme();
  const sx = {
    root: {
      color: disabled ? theme.palette.text.disabled : theme.palette.text.primary
    }
  };
  return (
    <Typography sx={sx.root} variant="body1">
      {children}
    </Typography>
  );
}
