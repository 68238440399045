import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import TreeViewdocuments from "./TreeViewDocuments";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { OverviewItem, OverviewNewItem } from "components/Overview/controllers/overviewBaseController";

interface MoveItemFolderSelectorProps {
  readonly destinationItem: OverviewNewItem | null;
  readonly itemToMove: OverviewNewItem;
  readonly items: OverviewItem[];
  readonly setDestinationItem: Dispatch<SetStateAction<OverviewNewItem | null>>;
  readonly setItemToMove: Dispatch<SetStateAction<OverviewItem | null>>;
  readonly onMoveEnd: () => void;
}
export default function MoveItemFolderSelector({
  destinationItem,
  itemToMove,
  items,
  setDestinationItem,
  setItemToMove,
  onMoveEnd
}: MoveItemFolderSelectorProps) {
  const { t } = useTranslation("documentCenter");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (itemToMove) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [itemToMove]);

  const onClose = useCallback(() => {
    setItemToMove(null);
    setDestinationItem(null);
  }, [setDestinationItem, setItemToMove]);

  const onConfirm = useCallback(() => onMoveEnd(), [onMoveEnd]);

  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onClose
        },
        {
          confirmButton: true,
          title: t("common:confirm"),
          variant: "contained",
          color: "primary",
          size: "medium",
          disabled: destinationItem ? false : true,
          onClick: onConfirm
        }
      ] as ConfirmationModalButtonProps[],
    [destinationItem, onClose, onConfirm, t]
  );

  const modalBody = useMemo(
    () => (
      <Box p={2} height={400} overflow={"auto"}>
        <Box display="flex">
          <Typography>{t("currentLocation")}</Typography>
          <Box ml={0.5} />
          <Typography>
            <b>{itemToMove.path}</b>
          </Typography>
        </Box>
        <Box mt={2}>
          <TreeViewdocuments
            items={items}
            itemToMove={itemToMove}
            setDestinationItem={setDestinationItem}
            destinationItem={destinationItem}
            rootFolderTitle={t("moveToRoot")}
          />
        </Box>
      </Box>
    ),
    [destinationItem, itemToMove, items, setDestinationItem, t]
  );

  return (
    <ConfirmationModal
      variant={"info"}
      modalBody={modalBody}
      modalOpen={openModal}
      modalTitle={t("moveMessage")}
      onClose={onClose}
      buttons={buttons}
      modalText={""}
    />
  );
}
