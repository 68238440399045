import {
  AssessmentMeasureDTO,
  DefaultApi,
  GetFinalAssessment200Response,
  UpdateAssessmentMeasureRequest,
  UpdateConsultationDataRequest
} from "../generated/process-service";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import useSWR from "swr";
import { useCallback, useEffect, useMemo } from "react";
import { useProcessPage } from "app/contexts/process-page-context";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const useFinalAssessmentPageApi = (args: { documentId: string }) => {
  const responseFinalAssessment = useSWR(args.documentId ? "final_" + args.documentId : null, async () => {
    return processClient.getFinalAssessment(args.documentId).then(resp => resp.data);
  });

  const emptyProcessData: GetFinalAssessment200Response = useMemo(
    () => ({
      processMeta: { id: args.documentId, status: "", relativeOrgUnitIds: [], assignUserIds: [], title: "" },
      processPage: {
        assessment: "",
        assessmentMeasures: [],
        consultationDPADescription: "",
        consultationDPARequired: ""
      }
    }),
    [args.documentId]
  );

  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  useEffect(() => {
    if (responseFinalAssessment.data?.processMeta) {
      setProcessMeta(responseFinalAssessment.data.processMeta);
    }
  }, [responseFinalAssessment.data?.processMeta, setProcessMeta]);
  const { mutate } = responseFinalAssessment;

  const addAssesmentMeasure = useCallback(
    async (payload: AssessmentMeasureDTO) => {
      await mutate(
        async () => {
          await onBeforeProcessUpdate(
            async () => {
              await processClient.addAssessmentMeasure(args.documentId, payload);
            },
            async () => {
              // reset data
              await mutate(responseFinalAssessment.data);
            }
          );
          return undefined;
        },
        {
          populateCache: false,
          revalidate: true
        }
      );
    },
    [args.documentId, mutate, onBeforeProcessUpdate, responseFinalAssessment.data]
  );

  const updateAssessmentMeasure = useCallback(
    async (assessmentMeasureId: string, updates: UpdateAssessmentMeasureRequest, onAborted?: () => void) => {
      await mutate(
        async () => {
          await onBeforeProcessUpdate(
            async () => {
              await processClient.updateAssessmentMeasure(args.documentId, assessmentMeasureId, updates);
            },
            async () => {
              // reset data
              await mutate(responseFinalAssessment.data);
              onAborted?.();
            }
          );
          return undefined;
        },
        {
          populateCache: false,
          revalidate: true,
          optimisticData(currentData, displayedData) {
            if (!displayedData?.processPage) {
              return emptyProcessData;
            }

            return {
              ...displayedData,
              processPage: {
                ...displayedData.processPage,
                assessmentMeasures: displayedData.processPage?.assessmentMeasures?.map(item => {
                  if (item.id === assessmentMeasureId) {
                    return { ...item, ...updates };
                  }
                  return item;
                })
              }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate, onBeforeProcessUpdate, responseFinalAssessment.data]
  );

  const deleteAssessmentMeasure = useCallback(
    async (assessmentMeasureId: string) => {
      await mutate(
        async () => {
          await onBeforeProcessUpdate(
            async () => {
              await processClient.deleteAssessmentMeasure(args.documentId, assessmentMeasureId);
            },
            async () => {
              // reset data
              await mutate(responseFinalAssessment.data);
            }
          );
          return undefined;
        },
        {
          populateCache: false,
          revalidate: true,
          optimisticData(currentData, displayedData) {
            if (!displayedData?.processPage) {
              return emptyProcessData;
            }

            return {
              ...displayedData,
              processPage: {
                ...displayedData.processPage,
                assessmentMeasures: displayedData.processPage?.assessmentMeasures?.filter(
                  item => item.id !== assessmentMeasureId
                )
              }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate, onBeforeProcessUpdate, responseFinalAssessment.data]
  );

  const updateFinalAssessment = useCallback(
    async (assessment: string) => {
      await mutate(
        async () => {
          await onBeforeProcessUpdate(
            async () => {
              await processClient.updateFinalAssessment(args.documentId, { assessment });
            },
            async () => {
              // reset data
              await mutate(responseFinalAssessment.data);
            }
          );
          return undefined;
        },
        {
          populateCache: false,
          revalidate: true,
          optimisticData(currentData, displayedData) {
            if (!displayedData?.processPage) {
              return emptyProcessData;
            }

            return {
              ...displayedData,
              processPage: {
                ...displayedData.processPage,
                assessment
              }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate, onBeforeProcessUpdate, responseFinalAssessment.data]
  );

  const updateConsultation = useCallback(
    async (consultationData: UpdateConsultationDataRequest) => {
      await mutate(
        async () => {
          await onBeforeProcessUpdate(
            async () => {
              await processClient.updateConsultationData(args.documentId, consultationData);
            },
            async () => {
              // reset data
              await mutate(responseFinalAssessment.data);
            }
          );
          return undefined;
        },
        {
          populateCache: false,
          revalidate: true,
          optimisticData(currentData, displayedData) {
            if (!displayedData?.processPage) {
              return emptyProcessData;
            }

            return {
              ...displayedData,
              processPage: {
                ...displayedData.processPage,
                ...consultationData
              }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate, onBeforeProcessUpdate, responseFinalAssessment.data]
  );

  const actions = useMemo(
    () => ({
      addAssesmentMeasure,
      updateAssessmentMeasure,
      deleteAssessmentMeasure,
      updateFinalAssessment,
      updateConsultation
    }),
    [addAssesmentMeasure, updateAssessmentMeasure, deleteAssessmentMeasure, updateFinalAssessment, updateConsultation]
  );

  return {
    ...responseFinalAssessment,
    actions
  };
};
