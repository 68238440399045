import { useCallback } from "react";
import { getLoginPreference } from "app/handlers/authentication/authenticationStorage";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { useNavigate } from "react-router-dom";
import { storeRedirectFullPathInSession } from "app/handlers/authentication/redirectStorage";
import { useLocationFullPath } from "./useLocationFullPath";

export const useTenantsAutoSelect = () => {
  const { loading, user, changeTenantHook } = useAuthentication();
  const { catchAsSnackbar } = useErrorSnackbar();
  const navigate = useNavigate();
  const locationFullPath = useLocationFullPath();

  const autoSelectTenantOrPickOne = useCallback(async () => {
    if (loading || !user?.tenants) {
      return;
    }

    if (user.tenants.length === 0) {
      catchAsSnackbar("No tenant available for this user")();
      return;
    }

    if (user.tenants.length === 1) {
      const tenantId = user.tenants[0].tenantId;
      changeTenantHook(tenantId).catch(catchAsSnackbar("Failed to switch tenant"));
      return;
    }

    const loginPref = await getLoginPreference();
    const preferredTenant = user.tenants?.find(tenant => tenant.tenantId === loginPref.tenantId);
    if (preferredTenant) {
      return changeTenantHook(preferredTenant.tenantId).catch(catchAsSnackbar("Failed to switch tenant"));
    }

    if (user.tenants.length > 1) {
      if (locationFullPath && !locationFullPath.includes("/actions")) {
        storeRedirectFullPathInSession(locationFullPath);
      }
    }
    navigate("/tenants");
  }, [loading, user?.tenants, navigate, catchAsSnackbar, changeTenantHook, locationFullPath]);

  return { autoSelectTenantOrPickOne, hasMultiTenants: (user?.tenants?.length || 0) > 1 };
};
