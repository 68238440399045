import { useCallback } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { LazySvgIcon } from "../LazySvgIcon/LazySvgIcon";
import { usePromisedCallback } from "./usePromisedCallback";
import { useTranslation } from "react-i18next";
import stopEvent from "tool/stopEvent";

export const RowDeletePrompt = (props: { onDelete: () => Promise<void>; onCancel: () => void }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onDelete, onCancel } = props;
  const deleteTrigger = usePromisedCallback(useCallback(() => onDelete().then(onCancel), [onDelete, onCancel]));

  return (
    <>
      <Box
        onClick={stopEvent}
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          gap: theme.spacing(2)
        }}
      >
        <Typography variant="body1" sx={{ paddingLeft: "100px" }}>
          {t("overview:delete_confirmation")}
        </Typography>
      </Box>
      <Box
        onClick={stopEvent}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: theme.spacing(1)
        }}
      >
        <IconButton color="primary" disabled={deleteTrigger.pending} onClick={deleteTrigger.trigger}>
          <LazySvgIcon name="Check" />
        </IconButton>
        <IconButton color="error" disabled={deleteTrigger.pending} onClick={onCancel}>
          <LazySvgIcon name="Close" />
        </IconButton>
      </Box>
    </>
  );
};
