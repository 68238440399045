import { useMemo } from "react";
import { riskWritePermissions } from "app/handlers/permissionHandler";
import { useUserPermissions } from "./useUserPermissions";

export const useRiskPermissions = () => {
  const { readOnlyUser, hasPermission } = useUserPermissions();

  const hasRiskWriteAllPermission = useMemo(() => riskWritePermissions.includes("risk_write_all"), []);

  const hasRiskWriteOrgPermission = useMemo(() => riskWritePermissions.includes("risk_write_org"), []);

  const isActionDisabled = useMemo(
    () => readOnlyUser || (!hasRiskWriteAllPermission && !hasRiskWriteOrgPermission),
    [readOnlyUser, hasRiskWriteAllPermission, hasRiskWriteOrgPermission]
  );

  return {
    readOnlyUser,
    hasRiskWriteAllPermission,
    hasRiskWriteOrgPermission,
    isActionDisabled,
    hasPermission
  };
};
