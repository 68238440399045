import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useProcessPage } from "../../../contexts/process-page-context";
import React, { useCallback, useEffect, useState } from "react";
import { useProportionality } from "../../../api/process/proportionalityPageApi";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import TextEditor from "../utils/TextEditor";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

const ProcessProportionalityPage = ({ readonly }: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("questionnaire_four_one_page");
  const { id } = useParams();
  useEnteringInfoCard({
    pathName: `/processes/${id}/proportionality`,
    infoId: "infocard.dpia.page7"
  });

  const {
    data,
    actions: { updateProportionality: updateProportionalityAction }
  } = useProportionality({ documentId: id || "" });

  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  useEffect(() => {
    if (data?.processMeta) {
      setProcessMeta(data.processMeta);
    }
  }, [data?.processMeta, setProcessMeta]);

  const [proportionality, setProportionality] = useState<string>("");

  useEffect(() => {
    if (data?.processPage?.proportionality) {
      setProportionality(data?.processPage.proportionality);
    }
  }, [data?.processPage.proportionality]);

  const onBlur = useCallback(() => {
    return onBeforeProcessUpdate(async () => {
      await updateProportionalityAction(id || "", { proportionality });
    });
  }, [id, onBeforeProcessUpdate, proportionality, updateProportionalityAction]);

  return (
    <>
      <QuestionnaireSubHeader text={t("title")} />
      <Question
        questionId={"proportionality"}
        title={t("subtitle")}
        questionName={t("subtitle")}
        translatable={proportionality}
        disabled={readonly}
      >
        <TextEditor onChange={setProportionality} inputValue={proportionality} disabled={readonly} onBlur={onBlur} />
      </Question>
    </>
  );
};

export default ProcessProportionalityPage;
