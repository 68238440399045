import {
  approveExternalRecipientApi,
  createDataLocationApi,
  createExternalRecipientApi,
  deleteExternalRecipientApi,
  ExternalRecipientDocumentDTO,
  ExternalRecipientDTO,
  ExternalRecipientPayload,
  getExternalRecipientApi,
  getExternalRecipientsApi,
  unApproveExternalRecipientApi,
  updateExternalRecipientApi
} from "../api/externalRecipientApi";
import { generateVersionedTitle } from "../utils/name-with-number";
import { getExternalDataRecipientsTemplates } from "./templatesHandler";
import { addToSeenItemsOfUser } from "./userAndTenant/userSeenItemsHandler";
import { COLLECTIONS } from "../collections";
import { AxiosResponse } from "axios";
import { isAxiosErrorWithCode } from "../api/axios/axiosErrorHandler";

export const getExternalRecipients = async (): Promise<ExternalRecipientDTO[]> => {
  const externalRecipientsDTO = await getExternalRecipientsApi();
  return externalRecipientsDTO.externalRecipients;
};

export const deleteExternalRecipient = async (externalRecipientId: string): Promise<void> => {
  return await deleteExternalRecipientApi(externalRecipientId);
};

export const getExternalRecipient = async (externalRecipientId: string): Promise<ExternalRecipientDocumentDTO> => {
  return await getExternalRecipientApi(externalRecipientId);
};

export const createExternalRecipient = async (
  createPayload: ExternalRecipientPayload
): Promise<{ id: string; response: AxiosResponse }> => {
  return await createExternalRecipientApi(createPayload);
};

export const updateExternalRecipient = async (
  externalRecipientId: string,
  updatePayload: ExternalRecipientPayload
): Promise<void> => {
  return await updateExternalRecipientApi(externalRecipientId, updatePayload);
};

export const approveExternalRecipient = async (externalRecipientId: string): Promise<void> => {
  return await approveExternalRecipientApi(externalRecipientId);
};

export const unApproveExternalRecipient = async (externalRecipientId: string): Promise<void> => {
  return await unApproveExternalRecipientApi(externalRecipientId);
};

export const createExternalDataRecipientWithDataLocations = async (
  payloadER: ExternalRecipientPayload,
  newDataLocationNames: string[]
): Promise<string> => {
  const externalRecipientBasicPayload = {
    name: ""
  };

  const newExternalRecipientId = (await createExternalRecipient({ ...externalRecipientBasicPayload, ...payloadER })).id;

  if (newDataLocationNames?.length) {
    for (const newDataLocationName of newDataLocationNames) {
      try {
        await createDataLocationApi(
          { nameKey: newDataLocationName, externalRecipientId: newExternalRecipientId },
          { params: { wait: true } }
        );
      } catch (error: any) {
        if (!isAxiosErrorWithCode(error, 409)) {
          throw error;
        }
      }
    }
  }

  return newExternalRecipientId;
};

export async function createExternalRecipientsFromTemplate(
  templateIds: string[],
  creatorUID: string,
  tenantId: string
) {
  const allTemplates = await getExternalDataRecipientsTemplates();
  const templates = allTemplates.filter(template => templateIds.includes(template.id));

  // we have to call it one by one since each template modify the product list
  // and they will overwrite each other on multiple template creation
  // e.g. create A and B from template, but when B saves product, it does not see product from A
  // and might overwrite product from A, when saving product from B
  // we have transactions to prevent this, but the transaction will repeat anyway when we call
  // create from template in parallel, so we just call it in serial
  for (const template of templates) {
    const createdExternalRecipientId = await createFromTemplate(template);
    if (createdExternalRecipientId) {
      await addToSeenItemsOfUser(tenantId, creatorUID, COLLECTIONS.EXTERNAL_RECIPIENTS, createdExternalRecipientId);
    }
  }
}

const createFromTemplate = async (template: any) => {
  const allExternalDataRecipients = await getExternalRecipients();
  const allExternalDataRecipientsNames = allExternalDataRecipients.map((data: ExternalRecipientDTO) => data.name);
  const generatedName = generateVersionedTitle(template.title, [], allExternalDataRecipientsNames);

  if (!allExternalDataRecipientsNames.includes(template.title)) {
    return createExternalDataRecipientWithDataLocations(
      {
        name: generatedName,
        serviceType: template.serviceType,
        street: template.street,
        ZIP: template.zip.toString(), // zip is parsed as number if the excel field only contains number, we want string
        city: template.city,
        country: template.country
      },
      template.products || []
    );
  } else {
    return false;
  }
};
