import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useResources } from "app/contexts/resource-context";
import { TaskInfoLabel } from "./TaskInfoLabel";
import LabelIcon from "@mui/icons-material/Label";
import { isEqual } from "lodash-es";
import { LabelField } from "../../../../../components/LabelField";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import ChipsStack from "components/ChipsStack/ChipsStack";
import { RESOURCE_TYPES } from "../../../../handlers/resourceHandler";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

interface TaskLabelsProps {
  readonly taskId: string;
  readonly labels: string[];
  readonly saveOperationInProgress: boolean;
  readonly onChangeLabels: (labels: string[]) => void;
}
export const TaskLabels = ({ taskId, labels, saveOperationInProgress, onChangeLabels }: TaskLabelsProps) => {
  const { t } = useTranslation("task_details");

  const [addLabelsActive, setAddLabelsActive] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const { translateById } = useResources();

  useEffect(() => {
    setSelectedLabels(labels);
  }, [labels, taskId]);

  const onLabelSelectionChanged = useCallback(
    value => {
      return setSelectedLabels(value);
    },
    [setSelectedLabels]
  );
  const addLabelsModalBody = <LabelField selectedIDs={selectedLabels} onSelectionChanged={onLabelSelectionChanged} />;
  const labelsAreUnchanged = isEqual(new Set(selectedLabels || []), new Set(labels));

  const resetTaskLabels = useCallback(() => {
    const taskLabels = labels || [];
    setSelectedLabels(taskLabels);
  }, [labels]);

  const addLabelsModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: () => {
          resetTaskLabels();
          setAddLabelsActive(false);
        }
      },
      {
        confirmButton: true,
        disabled: labelsAreUnchanged || saveOperationInProgress,
        title: t("save"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: () => {
          onChangeLabels(selectedLabels);
          setAddLabelsActive(false);
        }
      }
    ],
    [t, labelsAreUnchanged, saveOperationInProgress, resetTaskLabels, selectedLabels, onChangeLabels]
  );

  const getLabelName = useCallback(
    labelId => {
      return translateById(RESOURCE_TYPES.LABEL, labelId);
    },
    [translateById]
  );

  const closeModal = useCallback(() => {
    resetTaskLabels();
    setAddLabelsActive(false);
  }, [resetTaskLabels]);

  return (
    <Box mt={1.5} mb={2}>
      <TaskInfoLabel icon={LabelIcon} text={t("Labels")} />
      <ConfirmationModal
        modalOpen={addLabelsActive}
        onClose={closeModal}
        modalTitle={t("add_labels_modal_title")}
        modalText={t("add_labels_modal_text")}
        modalBody={addLabelsModalBody}
        buttons={addLabelsModalButtons}
        variant="info"
      />
      <Box mt={1}>
        <ChipsStack
          chips={[
            ...(labels && labels.length
              ? labels.map(labelId => ({
                  label: getLabelName(labelId),
                  type: "label",
                  variant: "contained",
                  rightIcon: CancelIcon,
                  fontWeight: 600,
                  onRightIconClick: async () => {
                    const temp = selectedLabels.filter(selectedLabel => selectedLabel !== labelId);
                    setSelectedLabels(temp);
                    onChangeLabels(temp);
                  }
                }))
              : []),
            {
              label: t("add_label"),
              variant: "dashed",
              leftIcon: AddIcon,
              onClick: () => setAddLabelsActive(true)
            }
          ]}
        />
      </Box>
    </Box>
  );
};
