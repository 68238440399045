import natsort from "natsort";

export interface NaturalSortOption {
  readonly desc?: boolean;
  readonly caseSensitive?: boolean;
}
export const createNaturalSorter = (
  options: NaturalSortOption = {
    desc: false,
    caseSensitive: false
  }
): ((a: string | number, b: string | number) => number) => {
  return natsort({ insensitive: !options.caseSensitive, desc: !!options.desc });
};

export const naturalSortBy = <T>(
  items: T[],
  keyFetchers: ((input: T) => string | number)[],
  sortOption?: NaturalSortOption
): T[] => {
  const naturalSort = createNaturalSorter(sortOption);
  if (!items || items.length === 0) {
    return items;
  }
  if (!keyFetchers || keyFetchers.length === 0) {
    return items;
  }
  return items
    .map(it => ({
      it,
      sortKeys: keyFetchers.map(keyFetcher => keyFetcher(it))
    }))
    .sort((a, b) => {
      for (let keyFetcherIndex = 0; keyFetcherIndex < keyFetchers.length; keyFetcherIndex++) {
        const result = naturalSort(a.sortKeys[keyFetcherIndex], b.sortKeys[keyFetcherIndex]);
        if (result === 0) {
          continue;
        }
        return result;
      }

      return 0;
    })
    .map(it => it.it);
};
