import { useCallback, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { OverviewItem } from "../../controllers/overviewBaseController";
import TaskOverviewRowStatus from "./TaskOverviewRowStatus";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import {
  activateRecurringTask,
  changeTaskAssignee,
  changeTaskGroups,
  deactivateRecurringTask,
  updateTaskDeadline,
  updateTaskInformation
} from "app/handlers/tasksHandler";
import { useSnackbar } from "notistack";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import TaskOverviewRowPriority from "./TaskOverviewRowPriority";
import { IconButton, makeStyles } from "@material-ui/core";
import TaskOverviewRowDueDate from "./TaskOverviewRowDueDate";
import TaskOverviewRowAssignUser from "./TaskOverviewRowAssignUser";
import stopEvent from "tool/stopEvent";
import Delete from "@material-ui/icons/Delete";
import RecurringIcon from "../../../../assets/images/tasks/recurring-icon.svg";
import GroupIcon from "../../../../assets/images/tasks/group-icon.svg";
import OverviewRowTitle from "components/Overview/controls/OverviewRowTitle";
import TaskOverviewRowAssignGroups from "./TaskOverviewRowAssignGroups";
import { TaskStatus } from "app/api/taskApi";
import { useTranslation } from "react-i18next";
import { updateGroupTaskApi } from "app/api/groupTaskApi";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 48,
    display: "flex",
    width: "100%",
    minWidth: "0px",
    alignItems: "center"
  }
}));

interface TaskOverviewRowProps {
  readonly item: OverviewItem;
  readonly hovered: boolean;
  readonly searchTerm: string;
  readonly onDelete: () => void;
  readonly onBlur: () => void;
}

const TaskOverviewRow = ({ item, hovered, searchTerm, onDelete, onBlur }: TaskOverviewRowProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { auth } = useAuthentication();
  const { tenantId, uid } = auth || { tenantId: "", uid: "" };
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useOverviewDispatch();

  const getCurrentPage = () => {
    if (window.location.pathname.indexOf("other") > -1) return "other";
    else return "my";
  };

  const reload = useCallback(
    (taskId?: string | null) => {
      const reload = {
        selectedId: taskId,
        shadowLoading: true,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
    },
    [dispatch]
  );

  /* TITLE */
  const titleEl = <OverviewRowTitle title={item.title} searchTerm={searchTerm} seen={item.seen} />;

  /* STATUS */
  const onChangeStatusCallback = useCallback(
    async (status: TaskStatus | "ACTIVE" | "INACTIVE") => {
      const endsWith = window.location.pathname.endsWith(item.id);
      if (item.detailType === "RECURRING") {
        if (status === "ACTIVE") {
          await activateRecurringTask(item.id);
        } else {
          await deactivateRecurringTask(item.id);
        }

        reload();
      } else if (item.detailType === "GROUP") {
        await updateGroupTaskApi(item.id, { status }).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });

        if (status === "DONE") {
          enqueueSnackbar(t("taskSuccessfullyCompleted"), { variant: "success" });
          reload(endsWith ? null : undefined);
          if (endsWith) {
            window.history.pushState("", "", `/tasks/${getCurrentPage()}`);
          }
        } else {
          reload();
        }
      } else {
        await updateTaskInformation(tenantId, uid, item.id, { status }).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });

        if (status === "DONE") {
          enqueueSnackbar(t("taskSuccessfullyCompleted"), { variant: "success" });
          reload(endsWith ? null : undefined);
          if (endsWith) {
            window.history.pushState("", "", `/tasks/${getCurrentPage()}`);
          }
        } else {
          reload();
        }
      }
    },
    [enqueueSnackbar, item.detailType, item.id, reload, t, tenantId, uid]
  );

  /* PRIORIRY */
  const onChangePriorityCallback = useCallback(
    async (priority: string) => {
      await updateTaskInformation(tenantId, uid, item.id, { priority }).catch(error => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
      reload();
    },
    [enqueueSnackbar, item.id, reload, tenantId, uid]
  );

  /* DUE DATE */
  const onChangeDueDateCallback = useCallback(
    async (dueAt: Date | null) => {
      if (!dueAt) {
        await updateTaskDeadline(tenantId, uid, item.id, null).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });
      } else {
        const now = new Date();
        const endOfDayDateDue = new Date(new Date(dueAt).setHours(23, 59, 59, 999));
        // when click on "clear"
        const finalDueDate = now.getTime() > endOfDayDateDue.getTime() ? null : endOfDayDateDue;
        await updateTaskDeadline(tenantId, uid, item.id, finalDueDate).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });
      }

      reload();
    },
    [enqueueSnackbar, item.id, reload, tenantId, uid]
  );

  /* USER ID */
  const onChangeUserIdCallback = useCallback(
    async (userId: string) => {
      const endsWith = window.location.pathname.endsWith(item.id);
      await changeTaskAssignee(tenantId, uid, item.id, userId).catch(error => {
        enqueueSnackbar(error.message, { variant: "error" });
      });

      if (userId !== auth?.uid) {
        reload(endsWith ? null : undefined);
        if (endsWith) {
          window.history.pushState("", "", `/tasks/${getCurrentPage()}`);
        }
      }
    },
    [auth?.uid, enqueueSnackbar, item.id, reload, tenantId, uid]
  );
  const assignToUserEl = useMemo(() => {
    return item.assignToType === "USER" ? (
      <TaskOverviewRowAssignUser
        userId={item.assignUserId}
        collection={item.collection}
        onChange={onChangeUserIdCallback}
      />
    ) : (
      <></>
    );
  }, [item.assignToType, item.assignUserId, item.collection, onChangeUserIdCallback]);

  /* GROUP IDS */
  const onChangeGroupIdsCallback = useCallback(
    async (ids: string[]) => {
      await changeTaskGroups(item.id, ids, tenantId).catch(error => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
      reload();
    },
    [enqueueSnackbar, item.id, reload, tenantId]
  );
  const assignToGroupEl = useMemo(() => {
    return item.assignToType === "GROUP" ? (
      <TaskOverviewRowAssignGroups taskId={item.id} groupIds={item.groupIds} onChange={onChangeGroupIdsCallback} />
    ) : (
      <></>
    );
  }, [item.assignToType, item.groupIds, item.id, onChangeGroupIdsCallback]);

  /* DETAIL ICON */
  const taskDetailEl = useMemo(() => {
    if (item.detailType === "RECURRING") {
      return (
        <Box ml={2} mt={1}>
          <RecurringIcon />
        </Box>
      );
    } else if (item.assignToType === "GROUP") {
      return (
        <Box ml={2} mt={1}>
          <GroupIcon />
        </Box>
      );
    }
    return <></>;
  }, [item.assignToType, item.detailType]);

  const isGroupSubTask = useMemo(
    () => item.detailType === "GROUP" && item.assignToType === "USER",
    [item.assignToType, item.detailType]
  );

  const stopEventCallback = useCallback(
    e => {
      stopEvent(e);
      onBlur();
    },
    [onBlur]
  );

  return (
    <Box className={cls.root} ml={1.5}>
      <Box onClick={stopEventCallback}>
        <TaskOverviewRowStatus detailType={item.detailType} status={item.status} onChange={onChangeStatusCallback} />
      </Box>
      <Box mx={2} flex={1} display="flex" minWidth={"0"} alignItems={"center"}>
        {titleEl}
        {taskDetailEl}
        <Box ml={2} onClick={stopEventCallback} display="flex" alignItems={"center"}>
          <TaskOverviewRowPriority priority={item.priority} status={item.status} onChange={onChangePriorityCallback} />
          <TaskOverviewRowDueDate
            status={item.status}
            dueDate={item.dueAt ? new Date(item.dueAt) : null}
            onChange={onChangeDueDateCallback}
          />
        </Box>
      </Box>
      {hovered && !isGroupSubTask && (
        <Box onClick={stopEventCallback} mr={1}>
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </Box>
      )}

      <Box onClick={stopEventCallback}>
        {assignToUserEl}
        {assignToGroupEl}
      </Box>
    </Box>
  );
};

export default TaskOverviewRow;
