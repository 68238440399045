import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ResourceMetaviewProps } from "../ResourceTypeOverviewDeps";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import MetaViewList, { MetaViewListItemProps } from "components/MetaViewList/MetaViewList";
import { useUserDepartments } from "app/contexts/department-context";
import { useUserProcesses } from "hook/useUserProcesses";
import EditIcon from "assets/images/icons/edit.svg";
import SearchIcon from "@material-ui/icons/Search";
import DoneIcon from "@material-ui/icons/Done";
import { flattenPersonGroupsData, useDataTypeTree } from "app/api/dataAssetApi";
import { useGetProcessorsApi } from "app/api/processorPAApi";

const paStatusIcons: Record<string, React.ReactNode> = {
  edit: <EditIcon />,
  review: <SearchIcon />,
  approved: <DoneIcon />
};

const ProcessesInResourceMetaView = ({ resourceType, resourceId }: ResourceMetaviewProps) => {
  const { t } = useTranslation();
  const { departments } = useUserDepartments();
  const { processes, isLoading } = useUserProcesses();
  const dataTypeTree = useDataTypeTree();
  const { data } = useGetProcessorsApi();

  const [items, setItems] = useState<MetaViewListItemProps[]>([]);

  const filter = useCallback(async () => {
    const _processes = processes
      .filter(
        ({
          id,
          allDeletionTypeIds,
          allInternalRecipientIds,
          allLegalRetentionPeriodIds,
          allDataTypeIds,
          allAIRoleIds
        }) => {
          switch (resourceType) {
            case RESOURCE_TYPES.DATA_DELETION: {
              return allDeletionTypeIds.includes(resourceId);
            }
            case RESOURCE_TYPES.INTERNAL_RECIPIENT: {
              return allInternalRecipientIds.includes(resourceId);
            }
            case RESOURCE_TYPES.LEGAL_RETENTION_PERIOD: {
              return allLegalRetentionPeriodIds.includes(resourceId);
            }
            case RESOURCE_TYPES.DATA_CLASSIFICATION: {
              const dataTypeIds = Object.values(flattenPersonGroupsData(dataTypeTree.data).dataTypes)
                .filter(dataType => dataType.dataClassificationId === resourceId)
                .map(({ id }) => id);
              return allDataTypeIds.some(id => dataTypeIds.includes(id));
            }
            case RESOURCE_TYPES.AI_ROLE: {
              return allAIRoleIds.includes(resourceId);
            }
            case RESOURCE_TYPES.PROCESSING_CATEGORY: {
              return ((data && data.items) || []).some(
                ({ processingCategoryIds, paIds }) => processingCategoryIds.includes(resourceId) && paIds.includes(id)
              );
            }
          }
          return false;
        }
      )
      .map(({ id, title, subTitle, status }) => {
        return {
          id,
          title,
          subTitle,
          icon: paStatusIcons[status] || <></>
        } satisfies MetaViewListItemProps;
      });
    setItems(_processes);
  }, [data, dataTypeTree.data, processes, resourceId, resourceType]);

  useEffect(() => {
    if (processes) {
      filter();
    } else {
      setItems([]);
    }
  }, [dataTypeTree.data, departments, filter, processes, resourceId, resourceType, t]);

  const onItemClickCallback = useCallback((id: string) => {
    window.open(`/processes/${id}/general`);
  }, []);

  return (
    <Box p={3}>
      <MetaViewList
        header={t("processes_overview:list_processes")}
        items={items}
        loading={isLoading}
        onItemClick={onItemClickCallback}
        pagination={true}
      />
    </Box>
  );
};

export default ProcessesInResourceMetaView;
