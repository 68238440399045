import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ResourceMetaviewProps } from "../ResourceTypeOverviewDeps";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import MetaViewList, { MetaViewListItemProps } from "components/MetaViewList/MetaViewList";
import { getDepartmentName } from "../../../utils/getFirstDepartmentName";
import { useUserDepartments } from "app/contexts/department-context";
import { useExternalRecipients } from "app/contexts/external-recipient-context";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "assets/images/icons/edit.svg";
import { useTheme } from "@mui/material";

const ExternalRecipientsInResourceMetaView = ({ resourceType, resourceId }: ResourceMetaviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { departments } = useUserDepartments();
  const { externalRecipients, initialized } = useExternalRecipients();

  const sx = {
    circle: {
      width: "36px",
      height: "36px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: theme.palette.green[500],
      backgroundColor: theme.palette.green[50]
    }
  };

  const [items, setItems] = useState<MetaViewListItemProps[]>([]);

  useEffect(() => {
    if (externalRecipients) {
      const _externalRecipients = externalRecipients
        .filter(({ safeguards, contractTypes, derogations, encryptions, pseudonymizations }) => {
          switch (resourceType) {
            case RESOURCE_TYPES.SAFEGUARD: {
              return safeguards.includes(resourceId);
            }
            case RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE: {
              return contractTypes.includes(resourceId);
            }
            case RESOURCE_TYPES.DEROGATION: {
              return derogations.includes(resourceId);
            }
            case RESOURCE_TYPES.ENCRYPTION: {
              return encryptions.includes(resourceId);
            }
            case RESOURCE_TYPES.PSEUDONYMIZATION: {
              return pseudonymizations.includes(resourceId);
            }
          }
          return false;
        })
        .map(({ id, name, responsibleOrgUnitId, approvedAt }) => {
          return {
            id,
            title: name,
            subTitle: getDepartmentName(departments, responsibleOrgUnitId),
            icon: approvedAt ? (
              <Box sx={sx.circle}>
                <DoneIcon />
              </Box>
            ) : (
              <EditIcon />
            )
          } satisfies MetaViewListItemProps;
        });
      setItems(_externalRecipients);
    } else {
      setItems([]);
    }
  }, [departments, externalRecipients, resourceId, resourceType, sx.circle, t]);

  const onItemClickCallback = useCallback((id: string) => {
    window.open(`/external-recipients/general/${id}`);
  }, []);

  return (
    <Box p={3}>
      <MetaViewList
        header={t("service_providers_overview:page_title")}
        items={items}
        loading={!initialized}
        onItemClick={onItemClickCallback}
        pagination={true}
      />
    </Box>
  );
};

export default ExternalRecipientsInResourceMetaView;
