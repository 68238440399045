import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AuditDetailDTO, AuditQuestionWithAnswerAndRemarkDTO, getAuditDetail, postAuditAnswer } from "app/api/auditApi";
import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import { AuditQuestionsRender } from "../../designer/AuditQuestionsRender";
import {
  AUDIT_METHODOLOGY,
  AUDIT_REPORT_PERMISSION,
  AUDIT_REPORT_PERMISSION_TYPES,
  AUDIT_TEMPLATE_STATUS
} from "../AuditTypes";
import Question from "components/Question/Question";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { CircularProgress } from "@material-ui/core";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";
import { COLLECTIONS } from "../../../../collections";
import { useAuditActionTasks } from "app/contexts/audit-action-tasks-context";

export const AuditQuestionnaire = ({ auditId }: { readonly auditId: string }) => {
  const { t } = useTranslation("audit_details");
  const { setInfo, setMeta } = useMetaView();
  const { auditActionTasksReload } = useAuditActionTasks();

  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [questionnaire, setQuestionnaire] = useState<AuditQuestionWithAnswerAndRemarkDTO[] | undefined>(undefined);
  const [reportPermission, setReportPermission] = useState<AUDIT_REPORT_PERMISSION_TYPES | null>(null);

  useEffect(() => {
    getAuditDetail({ id: auditId }).then(data => {
      setAuditData(data);
      if (data?.pagesWithWrite?.includes("report")) {
        setReportPermission(AUDIT_REPORT_PERMISSION.WRITE);
      } else if (data?.pages?.includes("report")) {
        setReportPermission(AUDIT_REPORT_PERMISSION.WRITE);
      }
    });
  }, [auditId]);

  const noWritePermission = useMemo(
    () => !auditData?.pagesWithWrite?.includes?.("questionnaire"),
    [auditData?.pagesWithWrite]
  );

  const infoCard: Record<string, { readonly title: string; readonly text: string }> = useMemo(
    () => ({
      default: {
        title: t("enteringInfoCardTitle"),
        text: t(`auditScopeInfoCardContent`)
      },
      [AUDIT_METHODOLOGY.INTERVIEW]: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringQuestionnareInterviewInfoCardContent")
      },
      [AUDIT_METHODOLOGY.SELF_ASSESSMENT]: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringQuestionnareSelfAssessmentInfoCardContent")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard[auditData?.methodology || "default"]);
  }, [auditData?.methodology, infoCard, setInfo]);

  useEffect(() => {
    if (auditData?.questions) {
      return setQuestionnaire(auditData?.questions);
    }
  }, [auditData?.questions]);

  const onAnswerCallback = useCallback(
    async (questionId, payload) => {
      if (auditData?.id) {
        await postAuditAnswer({ auditId: auditData.id, questionId, payload });
        const data = await getAuditDetail({ id: auditData.id });
        setAuditData(data);
        auditActionTasksReload();
      }
    },
    [auditActionTasksReload, auditData?.id]
  );

  return (
    <>
      {!auditData && <CircularProgress />}
      {auditData && (
        <>
          <AuditQuestionsRender
            reportPermission={reportPermission}
            allowAnswer={!noWritePermission}
            questions={questionnaire || []}
            selectedId={null}
            onAnswer={onAnswerCallback}
            templateStatus={AUDIT_TEMPLATE_STATUS.ACTIVE}
            auditStatus={auditData.status}
          />
          <Question>
            <QuestionnaireSubHeader text={t("common:attachments")} />
            <AttachmentsOverviewOBS
              docId={auditId}
              category={`${COLLECTIONS.AUDITS}-questionnaire`}
              setMeta={setMeta}
              disabled={noWritePermission}
            />
          </Question>
        </>
      )}
    </>
  );
};
