import React, { useCallback, useEffect, useState } from "react";
import { Box, Icon, TextField, Tooltip, makeStyles } from "@material-ui/core";
import { Group } from "@material-ui/icons";
import RepeatIcon from "@mui/icons-material/Repeat";

const useStyles = makeStyles(theme => ({
  titleEditorContainer: {
    width: "100%",
    "& .MuiInputBase-root.Mui-disabled": {
      color: "unset"
    }
  },
  titleEditor: {
    minHeight: "27px",
    paddingTop: "4px",
    "& .MuiInputBase-input": {
      fontSize: "20px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      marginLeft: "-8px",
      paddingLeft: "8px",
      borderRadius: "4px",
      "&:focus": {
        background: "transparent !important"
      }
    }
  },
  icon: {
    "& svg": {
      fill: "rgba(129, 129, 129, 1)"
    }
  },
  hoverGrayBg: {
    "& .MuiInputBase-input": {
      "&:hover": {
        background: "#f0f0f0"
      }
    }
  }
}));

interface TaskTitleProps {
  readonly detailType?: string | null;
  readonly assignedToType?: string | null;
  readonly title: string;
  readonly onChangeTitle: (title: string) => void;
  readonly disabled?: boolean;
  readonly groupTask?: boolean;
}
const TaskTitle = ({ detailType, assignedToType, title, onChangeTitle, disabled = false }: TaskTitleProps) => {
  const cls = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [titleValue, setTitleValue] = useState("");

  useEffect(() => {
    setTitleValue(title);
  }, [title]);

  const onBlurCallback = useCallback(() => {
    setEditMode(false);
    onChangeTitle(titleValue);
  }, [onChangeTitle, titleValue]);

  const onFocusCallback = useCallback(() => {
    setEditMode(true);
  }, []);

  const onKeyDown = useCallback(event => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  }, []);

  const onTitleTextChange = useCallback(event => {
    setTitleValue(event.target.value);
  }, []);

  const GroupTaskIconEl = (
    <Box mr={1} display="flex" alignItems={"center"} className={cls.icon}>
      <Icon>{<Group />}</Icon>
    </Box>
  );
  const RepeatIconEl = (
    <Box mr={1} display="flex" alignItems={"center"} className={cls.icon}>
      <Icon>{<RepeatIcon />}</Icon>
    </Box>
  );

  const editTitleEl = (
    <Box display="flex" alignItems={"center"} mb={1} mt={1} className={cls.titleEditorContainer}>
      {(detailType === "GROUP" || assignedToType === "GROUP") && GroupTaskIconEl}
      {detailType === "RECURRING" && RepeatIconEl}
      <Tooltip title={titleValue} disableHoverListener={disabled}>
        <TextField
          data-testid="edit-text-field"
          fullWidth
          type="string"
          className={disabled ? cls.titleEditor : `${cls.titleEditor} ${cls.hoverGrayBg}`}
          disabled={disabled}
          value={titleValue}
          onKeyDown={onKeyDown}
          onFocus={onFocusCallback}
          onBlur={onBlurCallback}
          onChange={onTitleTextChange}
          InputProps={{
            disableUnderline: !editMode
          }}
        />
      </Tooltip>
    </Box>
  );

  return (
    <Box display="flex" alignItems={"center"}>
      {editTitleEl}
    </Box>
  );
};

export { TaskTitle };
