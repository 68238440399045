import { useTranslation } from "react-i18next";
import GeneralPageStepper from "../../../components/Pagination/GeneralPageStepper";
import React, { useEffect, useState } from "react";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";

function usePaginationSteps() {
  const { t, i18n } = useTranslation();
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        label: t("pagination:general"),
        path: "general"
      },
      {
        label: t("pagination:tasks"),
        path: "tasks"
      },
      {
        label: t("pagination:data"),
        path: "data"
      }
    ]);
  }, [i18n.language]);

  return {
    steps
  };
}

export function DataSubjectRequestsPageStepper() {
  const { steps } = usePaginationSteps();

  return <GeneralPageStepper steps={steps} />;
}

export function DataSubjectRequestsPageButtons({ leftArea }) {
  const { steps } = usePaginationSteps();

  return <GeneralPageButtonPagination steps={steps} leftArea={leftArea} />;
}
