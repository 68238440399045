import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AiAssetSelect from "./AiAssetSelect";
import { useCallback, useEffect, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DefaultApi, GetAiAssets200Response, ImpactedAssetDTO } from "app/api/generated/process-service";
import Question from "components/Question/Question";
import { useTranslation } from "react-i18next";
import { Button, LinearProgress } from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import { createAsset, getAssetsWithAdditionalIDs } from "../handler/assetHandler";
import { AssetShortDTO } from "../types/AssetTypes";
import AiRoleResourcePicker from "./AiRoleResourcePicker";
import { apiEndpoints } from "../../../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../../api/axios/loggedInAxiosProvider";
import useSWR from "swr";
import { useResources } from "../../../contexts/resource-context";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";

type AssetMultiselectProps = {
  readonly paId: string;
  readonly expandedAccordion?: string | false;
  readonly disabled?: boolean;
  readonly impactedAssets: ImpactedAssetDTO[];
  readonly orgUnitIds?: string[] | undefined;
  readonly onChange?: (accordionId: string, data: ImpactedAssetDTO) => Promise<void>;
  readonly onDelete?: (accordionId: string) => Promise<void>;
};

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

/**
 * AI asset accordion component for managing impacted assets.
 * It allows the user to create, update and delete impacted assets.
 * One "impacted asset" consists of an asset and a resource of type AI role.
 * @param paId The id of the process assessment.
 * @param expandedAccordion The id of the expanded accordion (impacted asset).
 * @param disabled Whether the component is disabled.
 * @param impactedAssets The impacted assets.
 * @param orgUnitIds The organization unit ids.
 * @param onChange The function to call when the value changes.
 * @param onDelete The function to call when an impacted asset is deleted.
 * @returns The AI asset accordion component.
 */
const AiAssetAccordion = ({
  paId,
  disabled,
  expandedAccordion,
  impactedAssets,
  orgUnitIds,
  onChange,
  onDelete
}: AssetMultiselectProps) => {
  const { t } = useTranslation("questionnaires");

  const [localExpandedAccordion, setLocalExpandedAccordion] = useState<string | false>(expandedAccordion || false);

  const { loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { data: aiAssetResponse, isLoading: aiAssetIdsLoading } = useSWR<GetAiAssets200Response>(
    `ai-asset-ids-${paId}`,
    async () => {
      const response = await processClient.getAiAssets(paId);
      return response.data || {};
    }
  );
  const aiAssetIds = useMemo<Set<string>>(() => {
    return new Set<string>(aiAssetResponse?.aiSystemAssetIds || []);
  }, [aiAssetResponse]);

  const existingAssetIds = useMemo(
    () => impactedAssets.map(impactedAsset => impactedAsset.assetId).filter((it): it is string => !!it),
    [impactedAssets]
  );

  const {
    data: availableAssetsResponse,
    isLoading: availableAssetsLoading,
    mutate
  } = useSWR<{ items: AssetShortDTO[] }>(
    orgUnitIds ? `available-assets-${paId}-${orgUnitIds}-${existingAssetIds}` : null,
    () => getAssetsWithAdditionalIDs({ anyOrgUnitIds: orgUnitIds }, existingAssetIds)
  );
  const availableAssets = useMemo(() => availableAssetsResponse?.items || [], [availableAssetsResponse]);
  const availableAssetsById = useMemo<Map<string, AssetShortDTO>>(() => {
    return availableAssets.reduce<Map<string, AssetShortDTO>>((acc, asset) => {
      acc.set(asset.id, asset);
      return acc;
    }, new Map<string, AssetShortDTO>());
  }, [availableAssets]);

  // set local expanded accordion state when expandedAccordion changes
  useEffect(() => {
    if (expandedAccordion) {
      setLocalExpandedAccordion(expandedAccordion);
    }
  }, [expandedAccordion]);

  const handleAssetChange = useCallback(
    (accordionId, assetId) => {
      onChange?.(accordionId, { assetId: assetId });
    },
    [onChange]
  );

  const handleNewAssetAdded = useCallback(
    async (accordionId: string, newAssetName: string) => {
      const createdAssetId = await createAsset({ name: newAssetName });
      await mutate();
      loadSeenItemsOfUserHook();
      await onChange?.(accordionId, { assetId: createdAssetId });
    },
    [loadSeenItemsOfUserHook, mutate, onChange]
  );

  const handleAiRoleChange = useCallback(
    (accordionId, aiRoleId) => {
      onChange?.(accordionId, { aiRoleId: aiRoleId });
    },
    [onChange]
  );

  const handleAccordionClicked = useCallback((accordionId: string) => {
    setLocalExpandedAccordion(prev => (prev === accordionId ? false : accordionId));
  }, []);

  if (aiAssetIdsLoading || availableAssetsLoading) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={accordionDetailsSx}>
      {impactedAssets.length > 0 &&
        impactedAssets.map((impactedAsset, index) => (
          <Question
            disabled={disabled}
            key={`impacted-asset-${index}`}
            pb={0}
            pl={0}
            pr={0}
            pt={0}
            questionId={`impactedAsset-${impactedAsset.id}`}
            questionName={t("asset_details:affected_assets")}
          >
            <ImpactedAssetAccordion
              index={index}
              impactedAsset={impactedAsset}
              aiAssetIds={aiAssetIds}
              availableAssetsById={availableAssetsById}
              disabled={disabled}
              expanded={localExpandedAccordion === impactedAsset.id}
              onClick={handleAccordionClicked}
              onAssetChange={handleAssetChange}
              onNewAssetAdded={handleNewAssetAdded}
              onAiRoleChange={handleAiRoleChange}
              onDelete={onDelete}
            />
          </Question>
        ))}
    </Box>
  );
};

const accordionDetailsSx: SxProps = {
  "& .MuiAccordionDetails-root": {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0
  }
};

/**
 * Component for selecting one asset.
 * @param accordionId The id of the accordion (impacted asset).
 * @param disabled Whether the component is disabled.
 * @param selectedAssetId The id of the selected asset.
 * @returns The asset picker component.
 */
const AssetPicker = ({
  accordionId,
  disabled,
  selectedAssetId,
  availableAssetsById,
  onChange,
  onAddNewOption
}: {
  accordionId: string;
  disabled?: boolean;
  selectedAssetId?: string;
  onChange?: (accordionId: string, assetId: string) => void;
  availableAssetsById: Map<string, AssetShortDTO>;
  onAddNewOption?: (accordionId: string, newOption: string) => void;
}) => {
  const handleChange = useCallback((assetId: string) => onChange?.(accordionId, assetId), [accordionId, onChange]);
  const handleNewAsset = useCallback(
    (newOption: string) => onAddNewOption?.(accordionId, newOption),
    [accordionId, onAddNewOption]
  );
  return (
    <Box width="100%" mr={1}>
      <Question>
        <AiAssetSelect
          availableAssetsById={availableAssetsById}
          disabled={disabled}
          selectedAssetId={selectedAssetId}
          onChange={handleChange}
          onAddNewOption={handleNewAsset}
        />
      </Question>
    </Box>
  );
};

interface ImpactedAssetAccordionProps {
  index: number;
  impactedAsset: ImpactedAssetDTO;
  availableAssetsById: Map<string, AssetShortDTO>;
  aiAssetIds: Set<string>;
  disabled?: boolean;
  expanded?: boolean;
  onClick?: (accordionId: string) => void;
  onAssetChange?: (accordionId: string, assetId: string | null) => void;
  onNewAssetAdded?: (accordionId: string, newAssetName: string) => void;
  onAiRoleChange?: (accordionId: string, aiRoleId: string | null) => void;
  onDelete?: (accordionId: string) => void;
}

/**
 * Component for managing an impacted asset.
 * @param index The index of the impacted asset.
 * @param impactedAsset The impacted asset.
 * @param availableAssetsById The available assets by id.
 * @param aiAssetIds The asset ids which uses ai system
 * @param disabled Whether the component is disabled.
 * @param expanded Whether the component is expanded.
 * @param onClick The function to call when the accordion is clicked.
 * @param onAssetChange The function to call when the asset changes.
 * @param onNewAssetAdded The function to call when a new asset is added.
 * @param onAiRoleChange The function to call when the AI role changes.
 * @param onDelete The function to call when the component is deleted.
 * @returns The impacted asset component.
 */
const ImpactedAssetAccordion = ({
  index,
  impactedAsset,
  availableAssetsById,
  aiAssetIds,
  disabled,
  expanded,
  onClick,
  onAssetChange,
  onNewAssetAdded,
  onAiRoleChange,
  onDelete
}: ImpactedAssetAccordionProps) => {
  const { t } = useTranslation("questionnaires");

  const isAiAssetSelected = useMemo(() => {
    if (!impactedAsset.assetId) {
      return false;
    }

    return aiAssetIds.has(impactedAsset.assetId);
  }, [impactedAsset.assetId, aiAssetIds]);

  const selectedAsset = useMemo(
    () => availableAssetsById.get(impactedAsset.assetId || "") || null,
    [availableAssetsById, impactedAsset.assetId]
  );

  const { translateById } = useResources();
  const aiRoleTranslation = useMemo(
    () => (impactedAsset.aiRoleId ? translateById(RESOURCE_TYPES.AI_ROLE, impactedAsset.aiRoleId) : ""),
    [translateById, impactedAsset.aiRoleId]
  );

  const accordionSx = useMemo<SxProps>(
    () => ({
      borderRadius: "6px",
      "&:before": {
        display: "none"
      }
    }),
    []
  );

  const onChangeCallback = useCallback(() => {
    onClick?.(impactedAsset.id as string);
  }, [impactedAsset.id, onClick]);

  const onDeleteCallback = useCallback(() => {
    onDelete?.(impactedAsset.id as string);
  }, [impactedAsset.id, onDelete]);

  return (
    <Box key={index} mt={1}>
      <Accordion expanded={expanded} onChange={onChangeCallback} sx={accordionSx}>
        <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}>
          {selectedAsset
            ? `${selectedAsset?.name}${aiRoleTranslation ? ` - ${aiRoleTranslation}` : ""}`
            : t("questionnaires:selectAssetAndAiRole")}{" "}
        </AccordionSummary>
        <AccordionDetails>
          <AssetPicker
            accordionId={impactedAsset.id as string}
            disabled={disabled}
            selectedAssetId={impactedAsset.assetId}
            availableAssetsById={availableAssetsById}
            onChange={onAssetChange}
            onAddNewOption={onNewAssetAdded}
          />
          <AiRoleResourcePicker
            accordionId={impactedAsset.id as string}
            aiRoleId={impactedAsset.aiRoleId}
            disabled={disabled}
            isAiAssetSelected={isAiAssetSelected}
            onChange={onAiRoleChange}
          />
        </AccordionDetails>
        {!disabled && (
          <Box ml={2} mb={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DeleteIcon />}
              onClick={onDeleteCallback}
              disabled={disabled}
            >
              {t("questionnaires:delete")}
            </Button>
          </Box>
        )}
      </Accordion>
    </Box>
  );
};

export default AiAssetAccordion;
