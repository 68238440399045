import { Box } from "@material-ui/core";
import { useDataTypeTreeManager } from "app/api/dataAssetApi";
import { DataAssetType } from "app/api/generated/asset-service";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { ResourceField } from "components/ResourceField";
import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";

export interface ClassificationDataProps {
  readonly open: boolean;
  readonly item: OverviewItem;
}
interface PersonGroupsClassificationModalProps {
  readonly classificationData: ClassificationDataProps | null;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
}
const PersonGroupsClassificationModal = ({
  classificationData,
  onCancel,
  onConfirm
}: PersonGroupsClassificationModalProps) => {
  const { data, dataById, actions } = useDataTypeTreeManager(true);

  const [dataClassificationId, setDataClassificationId] = useState<string>("");

  const onChangeSelected = useCallback(id => {
    setDataClassificationId(id || "");
  }, []);

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box mt={3}>
        <ResourceField
          value={dataClassificationId || classificationData?.item.dataClassificationId}
          onChange={onChangeSelected}
          resourceType={RESOURCE_TYPES.DATA_CLASSIFICATION}
          label={t(`resources_lists_data_types_categories_person_groups:modal_classification_field`)}
          multiSelect={false}
        />
      </Box>
    </Box>
  );

  const onConfirmCallback = useCallback(async () => {
    if (dataClassificationId) {
      if (classificationData?.item.assetType === DataAssetType.DataCategory) {
        const dataCategory = dataById?.dataCategories[classificationData?.item.id];
        const personGroup = dataById?.personGroups[classificationData?.item.parentId];

        if (dataCategory && personGroup) {
          await Promise.all(
            dataCategory.dataTypes.map(dataType =>
              actions.updateDataType({
                personGroupId: personGroup.id || "",
                dataCategoryId: dataCategory.id || "",
                dataTypeId: dataType.id,
                updates: { dataClassificationId }
              })
            )
          );
        }
      } else if (classificationData?.item.assetType === DataAssetType.DataType) {
        const dataCategory = dataById?.dataCategories[classificationData?.item.parentId];
        const personGroup = data?.find(({ dataCategories }) =>
          dataCategories.some(({ id }) => dataCategory?.id === id)
        );

        if (dataCategory && personGroup) {
          await actions.updateDataType({
            personGroupId: personGroup.id || "",
            dataCategoryId: dataCategory.id || "",
            dataTypeId: classificationData?.item.id,
            updates: { dataClassificationId }
          });
        }
      }

      onConfirm();
    }
    setDataClassificationId("");
  }, [
    actions,
    classificationData?.item.assetType,
    classificationData?.item.id,
    classificationData?.item.parentId,
    data,
    dataById?.dataCategories,
    dataById?.personGroups,
    dataClassificationId,
    onConfirm
  ]);

  const onCancelCallback = useCallback(() => {
    setDataClassificationId("");
    onCancel();
  }, [onCancel]);

  const buttons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: onCancelCallback
      },
      {
        confirmButton: true,
        title: t("common:confirm"),
        variant: "contained",
        color: "primary",
        size: "medium",
        disabled: !dataClassificationId,
        onClick: onConfirmCallback
      }
    ],
    [dataClassificationId, onCancelCallback, onConfirmCallback]
  );

  return (
    <ConfirmationModal
      modalOpen={Boolean(classificationData?.open)}
      onClose={onCancel}
      modalTitle={t(`resources_lists_data_types_categories_person_groups:modal_classification_field`)}
      buttons={buttons}
      modalBody={modalBody}
      modalText={t(`resources_lists_data_types_categories_person_groups:modal_select_classification`)}
    />
  );
};
export default PersonGroupsClassificationModal;
