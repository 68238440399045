import { getTextFromTextEditorJsonString } from "../../questionnaires/utils/textEditorConverter";

export const displayArrayTextInDeletionConcept = (
  prevValue: string,
  currentValue: string,
  index: number,
  array: string[]
) => {
  const cleanedCurrentValue = getTextFromTextEditorJsonString(currentValue);
  if (!cleanedCurrentValue) {
    return prevValue;
  }

  if (array.length === 1) {
    return cleanedCurrentValue;
  }

  if (!prevValue) {
    return `- ${cleanedCurrentValue}`;
  }

  return `${prevValue}\n- ${cleanedCurrentValue}`;
};
