import { useCallback } from "react";
import { useResources } from "../contexts/resource-context";
import { RESOURCE_TYPES } from "./resourceHandler";
import { ResourceDTO } from "../api/resourceApi";
import { postSuggestLegalBasis } from "../api/mlApi";

export const suggestLegalBasisId = async (purposeDescription: string): Promise<string> => {
  if (purposeDescription) {
    const suggestedLegalBasisResponse = await postSuggestLegalBasis({
      message: purposeDescription
    });
    return suggestedLegalBasisResponse?.categoryKey || "";
  }

  return "";
};

export const useLegalBasisSuggestion = () => {
  const { resources, resourcesLoaded } = useResources();
  const allLegalBasis = resources[RESOURCE_TYPES.LEGAL_BASIS];

  const suggestLegalBasis = useCallback(
    async (purposeDescription: string): Promise<ResourceDTO | null> => {
      if (!resourcesLoaded) {
        return null;
      }

      const suggestedLegalBasisId = await suggestLegalBasisId(purposeDescription);
      if (!suggestedLegalBasisId) {
        return null;
      }
      return allLegalBasis.find(legalBasis => legalBasis.id === suggestedLegalBasisId) || null;
    },
    [allLegalBasis, resourcesLoaded]
  );

  return {
    suggestLegalBasis
  };
};
