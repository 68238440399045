import "app/shim"; // needs to be first
import ReactDOM from "react-dom";
import App from "./app/app";
import "app/i18n";
import { setupSentryIfNecessary } from "./tool/sentry";
import { redirectIfExternalDomainUsed } from "./tool/redirect";

setupSentryIfNecessary();
redirectIfExternalDomainUsed();

ReactDOM.render(<App />, document.getElementById("root"));
