import { COLLECTIONS, COLLECTION_TYPES } from "app/collections";
import { useSidebarSWR } from "app/pages/shared/Sidebar/useSidebarUnseen";
import RenderIcon from "components/RenderIcon/RenderIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { useUnseenCount } from "hook/useUnseenCount";
import { useDataTypeTree } from "app/api/dataAssetApi";
import OverviewIconButton from "./OverviewIconButton";

export default function OverviewMarkAllAsReadButton(props: {
  onMarkAllAsRead: () => Promise<void>;
  collection: COLLECTION_TYPES;
}) {
  const { t } = useTranslation("overview");
  const userSeen = useSidebarSWR();
  const unseenCount = useUnseenCount(props.collection);
  const { unseenCount: dataAssetUnseenCount } = useDataTypeTree();
  const handleMarkAllAsRead = useSWRMutation([props.onMarkAllAsRead, userSeen.unseenERsMutate], async () => {
    await props.onMarkAllAsRead();
    await userSeen.sidebarNewItemsMutate();
  });
  const handleClick = React.useCallback(() => {
    handleMarkAllAsRead.trigger();
  }, [handleMarkAllAsRead]);
  const hasUnseen = React.useMemo(
    () => (props.collection === COLLECTIONS.PERSON_GROUPS ? dataAssetUnseenCount : unseenCount) > 0,
    [dataAssetUnseenCount, props.collection, unseenCount]
  );
  return (
    <OverviewIconButton>
      <RenderIcon
        toolTipTitle={hasUnseen ? t("mark_all_as_read") : t("mark_all_as_read_inactive")}
        disabled={!hasUnseen}
        callBackFunction={handleClick}
        icon="MarkAllAsReadIcon"
      />
    </OverviewIconButton>
  );
}
