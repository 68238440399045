import { DataTypeTreeData } from "../api/dataAssetApi";

export const SPECIAL_CLASSIFICATIONS = {
  MIXED: "mixed"
};

export const getDataClassificationIdFromDataCategory = ({
  personGroupKey,
  dataCategoryKey,
  dataTypeTree
}: {
  readonly personGroupKey: string;
  readonly dataCategoryKey: string;
  readonly dataTypeTree: DataTypeTreeData;
}) => {
  const dataTypes =
    (dataTypeTree || [])
      ?.find(personGroup => personGroup.personGroupKey === personGroupKey)
      ?.dataCategories?.find(dataCategory => dataCategory.dataCategoryKey === dataCategoryKey)?.dataTypes || [];

  if (dataTypes.length === 0) {
    return null;
  }

  const allDataTypesHaveNoClassification = dataTypes.every(dataType => !dataType.dataClassificationId);
  if (allDataTypesHaveNoClassification) {
    return null;
  }

  const anyDataClassificationIdFromList = dataTypes.find(
    dataType => dataType.dataClassificationId
  )?.dataClassificationId;
  if (!anyDataClassificationIdFromList) {
    // this case in theory can't happen since we checked at least 1 data type have a valid classification id before
    return null;
  }

  const allDataClassificationIdTheSame = dataTypes.every(
    dataType => dataType.dataClassificationId === anyDataClassificationIdFromList
  );

  if (!allDataClassificationIdTheSame) {
    return SPECIAL_CLASSIFICATIONS.MIXED;
  }

  return anyDataClassificationIdFromList;
};
