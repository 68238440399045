import { useEffect, useState, useCallback } from "react";
import { detailType, TaskDetailsDTO } from "app/api/taskApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { COLLECTIONS } from "app/collections";
import {
  getGroupTask,
  getRecurringTask,
  getTask,
  updateRecurringTask,
  updateTaskInformation,
  activateRecurringTask,
  deactivateRecurringTask,
  updateTaskDeadline,
  changeRecurringTaskAssignee,
  changeTaskAssignee,
  changeRecurringTaskParticipants,
  changeTaskParticipants,
  changeTaskGroups,
  updateGroupTaskInformation
} from "app/handlers/tasksHandler";
import { RecurringTask } from "app/api/recurringTaskApi";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { GroupTask } from "app/api/groupTaskApi";

type TaskTypes = TaskDetailsDTO & RecurringTask & GroupTask;

interface TaskDataProps {
  taskId: string;
  detailType?: detailType;
  onCloseTaskDetails?: () => void;
  onTaskLoad?: (task: TaskTypes | null) => void;
  onTaskEdit: (taskId: string, prop: keyof TaskTypes, value: TaskTypes) => void;
  enqueueSnackbar: (message: string, options?: any) => void;
  setIsSlidedIn?: (isSlidedIn: boolean) => void;
  reload?: number;
}
export function useTaskData({
  taskId,
  detailType,
  onCloseTaskDetails,
  onTaskLoad,
  onTaskEdit,
  enqueueSnackbar,
  setIsSlidedIn,
  reload
}: TaskDataProps) {
  const { auth } = useAuthentication();
  const { removeFromSeenItemsOfUserHook } = useUserAndTenantData();
  const { tenantId, uid } = auth || { tenantId: "", uid: "" };
  const [task, setTask] = useState<TaskTypes | null>(null);
  const [documentNotFound, setDocumentNotFound] = useState(false);

  const updateTask = useCallback(
    async (taskId, detailType, data, currentTask) => {
      try {
        const updatedTask = { ...currentTask, ...data };
        const updatedProps = Object.keys(data).filter(key => data[key] !== currentTask[key]);
        const updatedProp = updatedProps[0] as keyof TaskDetailsDTO | keyof RecurringTask;
        if (!updatedProp) {
          return;
        }
        updatedTask.updated = new Date();
        setTask(updatedTask);
        switch (detailType) {
          case "RECURRING":
            switch (updatedProp) {
              case "active":
                if (data.active) {
                  await activateRecurringTask(taskId);
                } else {
                  await deactivateRecurringTask(taskId);
                }
                break;
              case "assigneeUID":
                await changeRecurringTaskAssignee(tenantId, uid, taskId, data.assigneeUID);
                break;
              case "participants":
                await changeRecurringTaskParticipants(tenantId, uid, taskId, data.participants);
                break;
              default:
                await updateRecurringTask(taskId, data);
                break;
            }
            break;
          case "GROUP":
            switch (updatedProp) {
              default:
                await updateGroupTaskInformation(tenantId, uid, taskId, data);
                break;
            }
            break;
          default:
            switch (updatedProp) {
              case "dueAt":
                await updateTaskDeadline(tenantId, uid, taskId, data.dueAt);
                break;
              case "assigneeUID":
                await changeTaskAssignee(tenantId, uid, taskId, data.assigneeUID);
                break;
              case "participants":
                await changeTaskParticipants(tenantId, uid, taskId, data.participants);
                data.participants.forEach(async (userId: string) => {
                  if (userId !== uid) {
                    await removeFromSeenItemsOfUserHook(userId, COLLECTIONS.TASKS, taskId);
                  }
                });
                break;
              case "groupIds":
                await changeTaskGroups(taskId, data.groupIds, tenantId);
                break;
              default:
                await updateTaskInformation(tenantId, uid, taskId, data);
                break;
            }
            break;
        }
        onTaskEdit(taskId, updatedProp, data);
      } catch (error) {
        enqueueSnackbar("Failed to update task information", { variant: "error" });
        console.error("Failed to update task information", error);
      }
    },
    [onTaskEdit, tenantId, uid, removeFromSeenItemsOfUserHook, enqueueSnackbar]
  );

  useEffect(() => {
    let isMounted = true;
    async function fetchTask() {
      let taskData;
      try {
        switch (detailType) {
          case "RECURRING":
            taskData = await getRecurringTask(taskId);
            break;
          case "GROUP":
            taskData = await getGroupTask(taskId);
            break;
          default:
            taskData = await getTask(taskId);
        }
      } catch (error) {
        enqueueSnackbar("Error in fetching task", { variant: "error" });
        console.error("Error in fetching task", error);
        if (isMounted) setDocumentNotFound(true);
      }
      if (!taskData) {
        if (isMounted) {
          setDocumentNotFound(true);
          onCloseTaskDetails?.();
          onTaskLoad?.(null);
        }
        return;
      }
      if (isMounted) {
        setTask(taskData);
        onTaskLoad?.(taskData);
      }
      if (taskId) {
        setIsSlidedIn?.(true);
      }
    }
    fetchTask();
    return () => {
      isMounted = false;
    };
  }, [taskId, detailType, enqueueSnackbar, onCloseTaskDetails, onTaskLoad, setIsSlidedIn, reload]);

  return { task, setTask, updateTask, documentNotFound };
}
