import { ProcessesOverviewReadOnly } from "../processes/overview/ProcessesOverviewReadOnly";
import React, { useMemo } from "react";
import { ProcessFilterOptions } from "../processes/overview/ProcessesOverviewController";

export const DataLocationAffectedProcesses = ({
  selectedDataLocationIds
}: {
  readonly selectedDataLocationIds: string[];
}) => {
  const processFilter = useMemo<Partial<ProcessFilterOptions>>(
    () => ({
      dataLocationIDs: selectedDataLocationIds
    }),
    [selectedDataLocationIds]
  );

  return <ProcessesOverviewReadOnly filter={processFilter} key={selectedDataLocationIds.join("-")} />;
};
