import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import OverviewUnseeCount from "components/Overview/controls/OverviewUnseeCount";
import { useCallback, useMemo } from "react";

interface TabButtonProps {
  readonly index: number;
  readonly label: string;
  readonly tooltip?: string;
  readonly selected?: boolean;
  readonly unseenCount?: number;
  readonly onClick: (index: number) => void;
}

const Tab = ({ index, label, tooltip, selected, unseenCount, onClick }: TabButtonProps) => {
  const theme = useTheme();
  const sx = {
    root: {
      padding: "14px 16px",
      borderBottom: "2px solid",
      borderColor: selected ? theme.palette.primary.main : "transparent",
      borderRadius: "0px",
      minWidth: 0,
      flex: "inhereted"
    },
    content: {
      minWidth: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
      alignItems: "center"
    },
    text: {
      color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
      fontWeight: "600"
    }
  };

  const onClickCallback = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return (
    <Tooltip title={tooltip}>
      <Button sx={sx.root} onClick={onClickCallback}>
        <Box sx={sx.content}>
          <Typography variant="body2" sx={sx.text} noWrap minWidth={0}>
            {label}
          </Typography>
          {unseenCount && unseenCount > 0 ? <OverviewUnseeCount count={unseenCount || 0} /> : <></>}
        </Box>
      </Button>
    </Tooltip>
  );
};

export interface TabProps {
  readonly label: string;
  readonly tooltip?: string;
  readonly selected?: boolean;
  readonly unseenCount?: number;
  readonly content?: React.ReactNode;
}
export interface TabsProps {
  readonly tabs: TabProps[];
  readonly selected?: number;
  readonly onTabChange: (index: number) => void;
}
const Tabs = ({ tabs, selected, onTabChange }: TabsProps) => {
  const sx = {
    root: {
      flex: 1,
      overflow: "hidden"
    },
    header: {
      overflow: "hidden",
      display: "flex"
    },
    content: {
      mt: 3
    }
  };

  const contentEl = useMemo(() => tabs.find((t, index) => t.selected || index === selected)?.content, [selected, tabs]);

  return (
    <Box sx={sx.root}>
      <Box sx={sx.header}>
        {tabs.map((tab, index) => (
          <Tab
            key={`tab-button-${index}`}
            index={index}
            label={tab.label}
            selected={tab.selected || selected === index}
            unseenCount={tab.unseenCount}
            tooltip={tab.tooltip || tab.label}
            onClick={onTabChange}
          />
        ))}
      </Box>
      {contentEl && <Box sx={sx.content}>{contentEl}</Box>}
    </Box>
  );
};

export default Tabs;
