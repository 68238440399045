import React, { useMemo } from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMetaView } from "app/contexts/meta-view-context";
import ArrayDisplay from "components/ArrayDisplay";
import InfoCard from "components/InfoCard/InfoCard";
import InsightCard from "components/InsightCard/InsightCard";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: "12px",
    marginBottom: "4px",
    color: theme.palette.text.secondary
  },
  value: {
    fontSize: "14px"
  }
}));

interface MetaViewAssistantTabProps {
  readonly translationKey?: string;
  readonly editable?: boolean;
}

const MetaViewAssistantTab = ({ translationKey, editable }: MetaViewAssistantTabProps) => {
  const cls = useStyles();
  const { info, meta, insight } = useMetaView();
  const { t, i18n } = useTranslation(translationKey);

  const infoEl = useMemo(() => {
    if (!meta && info) {
      return <InfoCard editable={editable} infoCardHeader={info?.title} infoCardContent={info?.text} />;
    } else return <></>;
  }, [info, meta, editable]);

  const insightEl = useMemo(() => {
    if (insight && !meta) {
      return <InsightCard infoCardHeader={insight.title} infoCardContent={insight.text} />;
    } else return <></>;
  }, [insight, meta]);

  const metaEl = useMemo(() => {
    if (meta) {
      const header = i18n.exists("metadata_headline")
        ? t("metadata_headline")
        : t("processes_overview:metadata_headline");
      if (meta.title && meta.text) {
        return (
          <InfoCard
            infoCardHeader={t(meta.title)}
            infoCardContent={
              <Box>{typeof meta.text === "string" ? parse(DOMPurify.sanitize(meta.text)) : meta.text}</Box>
            }
          />
        );
      } else {
        return (
          <InfoCard
            infoCardHeader={header}
            infoCardContent={
              <Box>
                {Object.entries(meta).map(([key, value]) => (
                  <Box key={key} my={2}>
                    <Typography className={cls.label}>{t(key)}</Typography>
                    <Typography className={cls.value}>
                      {Array.isArray(value) ? (
                        <ArrayDisplay elements={value} />
                      ) : React.isValidElement(value) ? (
                        value
                      ) : (
                        <span>{parse(DOMPurify.sanitize(String(value)))}</span>
                      )}
                    </Typography>
                  </Box>
                ))}
              </Box>
            }
          />
        );
      }
    } else return <></>;
  }, [meta, i18n, t, cls.label, cls.value]);

  return (
    <>
      {infoEl}
      {insightEl}
      {metaEl}
    </>
  );
};

export default MetaViewAssistantTab;
