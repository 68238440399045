export async function parseXlsxDataToJson(excelDataBlob) {
  return readXLSX(await excelDataBlob.arrayBuffer(), false);
}

export async function readXLSX(binary, binaryType) {
  const { read, utils } = await import("xlsx");
  const readBinary = read(binary, { type: binaryType, bookVBA: true });
  /* Get first worksheet */
  const workSheetName = readBinary.SheetNames[0];
  const workSheet = readBinary.Sheets[workSheetName];
  /* Convert array of arrays */
  return utils.sheet_to_json(workSheet);
}
