import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import { Box, Button, ClickAwayListener, makeStyles, Menu, MenuItem, TextField, Tooltip } from "@material-ui/core";
import MetaView from "components/MetaView/MetaView";
import DocView from "components/DocView/DocView";
import { useParams } from "react-router-dom";
import {
  AuditQuestionDTO,
  AuditQuestionPayloadActionDTO,
  AuditQuestionPayloadDTO,
  AuditQuestionWithAnswerAndRemarkDTO,
  AuditTemplateDetailDTO,
  copyAuditTemplateQuestion,
  deleteAuditTemplateQuestion,
  getAuditTemplate,
  patchAuditTemplate,
  patchAuditTemplateQuestion,
  postAuditTemplateQuestion
} from "app/api/auditApi";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { ExpandMore } from "@material-ui/icons";
import { TFunction } from "i18next";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import DocumentNotFoundPage from "app/pages/shared/DocumentNotFound/DocumentNotFound";
import { ActionTaskDetails } from "app/pages/tasks/details/components";
import { ActionTaskPayloadDTO } from "app/pages/tasks/details/components/ActionTaskDetails";
import { AUDIT_TEMPLATE_STATUS_TYPES } from "../audit/AuditTypes";
import { AuditQuestionSettings } from "../metaview/AuditQuestionSettings";
import { AuditAddQuestionButton } from "./AuditAddQuestionButton";
import AuditQuestionSelector from "./AuditQuestionSelector";
import { AuditQuestionsRender } from "./AuditQuestionsRender";
import { AUDIT_QUESTION } from "./AuditQuestionType";

const useStyles = makeStyles(theme => ({
  statusMenu: {
    marginTop: "56px"
  },
  currentStatus: {
    background: theme.palette.grey[100],
    cursor: "default"
  },
  titleEditor: {
    minHeight: "48px",
    paddingTop: "4px",
    "& .MuiInputBase-input": {
      fontSize: "24px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      marginLeft: "-8px",
      paddingLeft: "8px",
      borderRadius: "4px",
      "&:focus": {
        background: "transparent !important"
      },
      "&:hover": {
        background: "#f0f0f0"
      }
    }
  }
}));

const buildDefaultAuditBlock = (type: string, title?: string): AuditQuestionPayloadDTO => {
  return {
    type,
    order: { afterQuestionId: "" },
    title: title || type,
    description: "",
    placeholder: "",
    options: [],
    actions: [],
    contentIds: [],
    htmlContent: null
  };
};

export const buildNewAuditQuestionPayloadDTO = (type: string, t: TFunction): AuditQuestionPayloadDTO => {
  const placeholder: string | undefined =
    type === AUDIT_QUESTION.DATE ? t("question_types_date_placeholder") : undefined;
  const title = t(`question_types_${type.toLowerCase()}`);
  switch (type) {
    case AUDIT_QUESTION.HEADING:
    case AUDIT_QUESTION.SUB_HEADING:
    case AUDIT_QUESTION.PARAGRAPH: {
      return buildDefaultAuditBlock(type, title);
    }

    case AUDIT_QUESTION.TEXT_INPUT_SINGLE:
    case AUDIT_QUESTION.TEXT_INPUT_MULTIPLE: {
      return { ...buildDefaultAuditBlock(type, title), placeholder: "" };
    }
    case AUDIT_QUESTION.DATE: {
      return { ...buildDefaultAuditBlock(type, title), placeholder: placeholder || "" };
    }
    case AUDIT_QUESTION.CHECK_BOX:
    case AUDIT_QUESTION.RADIO_BOX:
    case AUDIT_QUESTION.SELECT_BOX_SINGLE:
    case AUDIT_QUESTION.SELECT_BOX_MULTIPLE: {
      return { ...buildDefaultAuditBlock(type, title), placeholder: "", options: [] };
    }
    case AUDIT_QUESTION.DIVIDER: {
      return buildDefaultAuditBlock(type);
    }
    case AUDIT_QUESTION.GROUP: {
      return buildDefaultAuditBlock(type, title);
    }
    case AUDIT_QUESTION.ORG_PROCESSING_CONSENT: {
      return buildDefaultAuditBlock(type, title);
    }
    default:
      throw new Error(`Unknown audit block: ${type}`);
  }
};

export const buildAuditQuestionPayloadDTO = (q: AuditQuestionDTO): AuditQuestionPayloadDTO => {
  return {
    type: q.type,
    title: q.title,
    description: q.description,
    placeholder: q.placeholder,
    contentIds: q.contentIds,
    htmlContent: q.htmlContent,
    order: q.order,
    options: q.options,
    conditions: q.conditions,
    actions: q.actions,
    groupId: q.groupId
  };
};

const AuditTemplateDesignerHeader = ({
  template,
  onUpdate
}: {
  readonly template: Partial<AuditTemplateDetailDTO> | null;
  readonly onUpdate: () => void;
}) => {
  const cls = useStyles();
  const { t } = useTranslation("audit_details");
  const [editTitleMode, setEditTitleMode] = useState<boolean>(false);
  const [templateTitle, setTemplateTitle] = useState<string>("");
  const [templateStatus, setTemplateStatus] = useState<string>("");
  const [statusMenuRef, setStatusMenuRef] = useState<HTMLElement | null>(null);
  const setEditTitleModeOn = useCallback(() => setEditTitleMode(true), []);

  useEffect(() => {
    if (template) {
      setTemplateTitle(template.title || "");
      setTemplateStatus(template.status || "");
    }
  }, [template]);

  const onChangeTemplateTitleCallback = useCallback(event => setTemplateTitle(event.target.value), []);
  const onUpdateTemplateTitleCallback = useCallback(
    async event => {
      setEditTitleMode(false);
      const title = event.target.value.trim();
      if (title && template?.id) {
        await patchAuditTemplate({
          id: template?.id || "",
          payload: { title }
        });
        setTemplateTitle(title);
      }
    },
    [template?.id]
  );

  const openStatusMenu = useCallback(event => setStatusMenuRef(event?.currentTarget), []);
  const closeStatusMenu = useCallback(() => setStatusMenuRef(null), []);

  const updateStatus = useCallback(
    async status => {
      closeStatusMenu();
      if (status !== template?.status) {
        await patchAuditTemplate({
          id: template?.id || "",
          payload: { status }
        });
        onUpdate();
      }
    },
    [closeStatusMenu, onUpdate, template?.id, template?.status]
  );

  const changeStatusToActive = useCallback(() => updateStatus("active"), [updateStatus]);
  const changeStatusToInActive = useCallback(() => updateStatus("inactive"), [updateStatus]);

  const exportTemplateAsJsonFile = useCallback(() => {
    const templateAsJson = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(template))}`;
    const link = document.createElement("a");
    link.href = templateAsJson;
    link.download = template?.title + ".json";

    link.click();
  }, [template]);

  const { auth } = useAuthentication();
  const statusButtonEl = useMemo(
    () => (
      <>
        <Box alignItems={"center"} display={"flex"}>
          <Box mr={1} hidden={!auth?.permissions.includes("super_admin")}>
            <Tooltip title={"root-admin tool"}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportTemplateAsJsonFile}
              >
                {t("audit_details:exportAsTemplate")}
              </Button>
            </Tooltip>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              endIcon={<ExpandMore />}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={openStatusMenu}
            >
              {t("audit_status:" + templateStatus)}
            </Button>
            <Menu
              className={cls.statusMenu}
              id="simple-menu"
              anchorEl={statusMenuRef}
              open={Boolean(statusMenuRef)}
              onClose={closeStatusMenu}
            >
              <MenuItem className={templateStatus === "active" ? cls.currentStatus : ""} onClick={changeStatusToActive}>
                {t("audit_status:active")}
              </MenuItem>
              <MenuItem
                className={templateStatus === "inactive" ? cls.currentStatus : ""}
                onClick={changeStatusToInActive}
              >
                {t("audit_status:inactive")}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </>
    ),
    [
      auth?.permissions,
      changeStatusToActive,
      changeStatusToInActive,
      closeStatusMenu,
      cls.currentStatus,
      cls.statusMenu,
      exportTemplateAsJsonFile,
      openStatusMenu,
      statusMenuRef,
      t,
      templateStatus
    ]
  );

  return (
    <Box my={4} display="flex" alignItems={"center"}>
      <Box flex={1} mr={1}>
        <TextField
          className={cls.titleEditor}
          fullWidth
          value={templateTitle}
          onBlur={onUpdateTemplateTitleCallback}
          onChange={onChangeTemplateTitleCallback}
          onFocus={setEditTitleModeOn}
          InputProps={{
            disableUnderline: !editTitleMode
          }}
        />
      </Box>
      <Box>{statusButtonEl}</Box>
    </Box>
  );
};

export const AuditTemplateDesigner = () => {
  const id = useParams()?.id || "";
  const { t } = useTranslation("audit_details");

  const [template, setTemplate] = useState<Partial<AuditTemplateDetailDTO> | null>(null);
  const [blockSelector, setBlockSelector] = useState<boolean>(false);
  const [questions, setQuestions] = useState<AuditQuestionWithAnswerAndRemarkDTO[]>([]);
  const [selectedAuditQuestionId, setSelectedAuditQuestionId] = useState<string | null>(null);
  const [selectedAuditQuestion, setSelectedAuditQuestion] = useState<AuditQuestionWithAnswerAndRemarkDTO | null>(null);
  const [selectedAuditQuestionAction, setSelectedAuditQuestionActiion] = useState<AuditQuestionPayloadActionDTO | null>(
    null
  );
  const [notFound, setNotFound] = useState(false);
  useState<AuditQuestionWithAnswerAndRemarkDTO | null>(null);

  const [published, setPublished] = useState<boolean>(false);
  const [conditionPickerQuestion, setConditionPickerQuestion] = useState<AuditQuestionWithAnswerAndRemarkDTO | null>(
    null
  );

  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const closeConfirmation = useCallback(() => setConfirmationOpen(false), []);

  const reloadTemplateCallback = useCallback(async () => {
    if (!id) {
      setTemplate(null);
      return;
    }

    const auditTemplate = await getAuditTemplate({ id: id });
    if (!auditTemplate) {
      setNotFound(true);
      return;
    }

    setTemplate(auditTemplate);
    setQuestions(auditTemplate?.questions || []);
    setPublished(auditTemplate !== null && auditTemplate.status === "active");
  }, [id]);

  /* get template */
  useEffect(() => {
    reloadTemplateCallback();
  }, [reloadTemplateCallback]);

  useEffect(() => {
    if (selectedAuditQuestionId) {
      setSelectedAuditQuestion(questions.find(({ id }) => id === selectedAuditQuestionId) || null);
    } else setSelectedAuditQuestion(null);
  }, [questions, selectedAuditQuestionId]);

  const onUnPublish = useCallback(async () => {
    if (template && template.id) {
      await patchAuditTemplate({
        id: template.id,
        payload: { status: "draft" }
      });
      await reloadTemplateCallback();
      closeConfirmation();
    }
  }, [template, reloadTemplateCallback, closeConfirmation]);

  const showQuestionSelectorCallback = useCallback(() => setBlockSelector(true), []);
  const hideQuestionSelectorCallback = useCallback(() => {
    setBlockSelector(false);
    // setSelectedAuditBlock(null);
  }, []);

  const onAddAuditQuestion = useCallback(
    async (type: string) => {
      hideQuestionSelectorCallback();

      const lastQuestion: AuditQuestionDTO | null = questions.length ? questions[questions.length - 1] : null;
      const newQuestion: AuditQuestionPayloadDTO = {
        ...buildNewAuditQuestionPayloadDTO(type, t),
        order: { afterQuestionId: lastQuestion ? lastQuestion.id : null }
      };
      const newQuestionId = await postAuditTemplateQuestion({ templateId: id, payload: newQuestion });

      await reloadTemplateCallback();
      setSelectedAuditQuestionId(newQuestionId || null);
    },
    [hideQuestionSelectorCallback, questions, t, id, reloadTemplateCallback]
  );

  const onAddQuestionToGroup = useCallback(
    async (questionType, groupId) => {
      const questionsInGroup = questions.filter(question => question.groupId === groupId);

      const newQuestion: AuditQuestionPayloadDTO = {
        ...buildNewAuditQuestionPayloadDTO(questionType, t),
        order: { afterQuestionId: questionsInGroup.length ? questionsInGroup[questionsInGroup.length - 1].id : null },
        groupId
      };

      const newQuestionId = await postAuditTemplateQuestion({ templateId: id, payload: newQuestion });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(newQuestionId || null);
    },
    [id, questions, reloadTemplateCallback, t]
  );

  const onDropQuestionToGroup = useCallback(
    async (questionId, payload) => {
      await patchAuditTemplateQuestion({
        templateId: id,
        id: questionId,
        payload
      });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(questionId || null);
    },
    [id, reloadTemplateCallback]
  );

  const onDropQuestionToRoot = useCallback(
    async (questionId, payload) => {
      await patchAuditTemplateQuestion({
        templateId: id,
        id: questionId,
        payload
      });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(questionId || null);
    },
    [id, reloadTemplateCallback]
  );

  const onChangeAuditQuestion = useCallback(
    async (q: AuditQuestionWithAnswerAndRemarkDTO) => {
      await patchAuditTemplateQuestion({
        templateId: id,
        id: q.id,
        payload: buildAuditQuestionPayloadDTO(q)
      });

      setQuestions(currentQuestions =>
        currentQuestions.map(currentQuestion => (currentQuestion.id === q.id ? q : currentQuestion))
      );
    },
    [id]
  );

  const onChangeAuditQuestionActionTask = useCallback(
    async (actionId: string, questionActionTask: ActionTaskPayloadDTO) => {
      if (selectedAuditQuestionAction && selectedAuditQuestion) {
        const actions = selectedAuditQuestion.actions.map(action => {
          if (action.id === actionId) {
            return { ...action, payload: questionActionTask };
          } else return action;
        });
        await onChangeAuditQuestion({ ...selectedAuditQuestion, actions });
      }
    },
    [onChangeAuditQuestion, selectedAuditQuestion, selectedAuditQuestionAction]
  );

  const onSelectAuditQuestion = useCallback(
    async (questionId: string | null) => {
      if (conditionPickerQuestion && questionId) {
        const newConditions = conditionPickerQuestion.conditions || {};
        newConditions[questionId] = "";
        const question = {
          ...conditionPickerQuestion,
          conditions: newConditions
        };
        await onChangeAuditQuestion(question);
        setSelectedAuditQuestion(question);
        setConditionPickerQuestion(null);
      } else {
        setSelectedAuditQuestionId(questionId || null);
      }
    },
    [conditionPickerQuestion, onChangeAuditQuestion]
  );

  const onRemoveAuditQuestion = useCallback(
    async (question: AuditQuestionDTO) => {
      await deleteAuditTemplateQuestion({ templateId: id, id: question.id });

      setQuestions(currentQuestions => currentQuestions.filter(({ id }) => id !== question.id));
      // await reloadTemplateCallback();
    },
    [id]
  );

  const onCopyAuditQuestion = useCallback(
    async (question: AuditQuestionDTO) => {
      const newQuestionId = await copyAuditTemplateQuestion({ templateId: id, questionId: question.id });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(newQuestionId || null);
    },
    [id, reloadTemplateCallback]
  );

  const onReorderQuestion = useCallback(
    async (questionId, payload) => {
      await patchAuditTemplateQuestion({
        templateId: id,
        id: questionId,
        payload
      });

      await reloadTemplateCallback();
      setSelectedAuditQuestionId(questionId || null);
    },
    [id, reloadTemplateCallback]
  );

  const onShowQuestionAction = useCallback(
    (action: AuditQuestionPayloadActionDTO) => setSelectedAuditQuestionActiion(action),
    []
  );
  const onCloseQuestionAction = useCallback(() => {
    setSelectedAuditQuestionActiion(null);
  }, []);

  const confirmationDialogEl = useMemo(
    () => (
      <ConfirmationModal
        variant="info"
        modalOpen={confirmationOpen}
        onClose={closeConfirmation}
        modalTitle={t("common:information")}
        modalText={t(`confirm_unpublish`)}
        buttons={[
          {
            title: t("common:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: closeConfirmation
          },
          {
            confirmButton: true,
            title: t("unpublish"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: onUnPublish
          }
        ]}
      />
    ),
    [closeConfirmation, confirmationOpen, onUnPublish, t]
  );

  const addQuestionButtonEl = useMemo(
    () => !blockSelector && !published && <AuditAddQuestionButton onClick={showQuestionSelectorCallback} />,
    [blockSelector, published, showQuestionSelectorCallback]
  );

  const questionSelectorEl = useMemo(
    () =>
      blockSelector && (
        <ClickAwayListener onClickAway={hideQuestionSelectorCallback}>
          <AuditQuestionSelector onSelect={onAddAuditQuestion} onClose={hideQuestionSelectorCallback} />
        </ClickAwayListener>
      ),
    [blockSelector, hideQuestionSelectorCallback, onAddAuditQuestion]
  );

  const auditQuestionsRenderEl = useMemo(
    () =>
      template && (
        <AuditQuestionsRender
          conditionPickerQuestion={conditionPickerQuestion}
          templateStatus={template.status as AUDIT_TEMPLATE_STATUS_TYPES}
          selectedId={selectedAuditQuestionId}
          questions={questions}
          onSelectQuestion={onSelectAuditQuestion}
          onCopyQuestion={onCopyAuditQuestion}
          onRemoveQuestion={onRemoveAuditQuestion}
          onReorderQuestion={onReorderQuestion}
          onAddQuestionToGroup={onAddQuestionToGroup}
          onDropQuestionToGroup={onDropQuestionToGroup}
          onDropQuestionToRoot={onDropQuestionToRoot}
        />
      ),
    [
      template,
      conditionPickerQuestion,
      selectedAuditQuestionId,
      questions,
      onSelectAuditQuestion,
      onCopyAuditQuestion,
      onRemoveAuditQuestion,
      onReorderQuestion,
      onAddQuestionToGroup,
      onDropQuestionToGroup,
      onDropQuestionToRoot
    ]
  );

  const [metaViewContent, setMetaViewContent] = useState<React.ReactNode>(<></>);
  useEffect(() => {
    if (selectedAuditQuestionAction) {
      setMetaViewContent(
        <ActionTaskDetails
          actionId={selectedAuditQuestionAction.id}
          actionPayload={selectedAuditQuestionAction.payload}
          onActionTaskEdit={onChangeAuditQuestionActionTask}
          onCloseActionTaskDetails={onCloseQuestionAction}
        />
      );
    } else if (selectedAuditQuestion) {
      setMetaViewContent(
        <Box p={3}>
          <AuditQuestionSettings
            allQuestions={questions}
            selectedQuestion={selectedAuditQuestion}
            onChangeQuestion={onChangeAuditQuestion}
            onSelectQuestion={onSelectAuditQuestion}
            onSetConditionPickerQuestion={setConditionPickerQuestion}
            onShowQuestionAction={onShowQuestionAction}
          />
        </Box>
      );
    } else {
      setMetaViewContent(<MetaView translationKey={"audits_overview"} />);
    }
  }, [
    id,
    onChangeAuditQuestion,
    onChangeAuditQuestionActionTask,
    onCloseQuestionAction,
    onSelectAuditQuestion,
    onShowQuestionAction,
    questions,
    selectedAuditQuestion,
    selectedAuditQuestionAction
  ]);

  if (notFound) {
    return <DocumentNotFoundPage collection={"auditTemplates"} />;
  }

  return (
    <DocMetaView metaViewContent={metaViewContent}>
      <DocView>
        <Box>
          <AuditTemplateDesignerHeader template={template} onUpdate={reloadTemplateCallback} />
          <Box onClick={hideQuestionSelectorCallback}>
            {auditQuestionsRenderEl}
            {questionSelectorEl}
            {addQuestionButtonEl}
          </Box>
          {confirmationDialogEl}
        </Box>
      </DocView>
    </DocMetaView>
  );
};
