export type AUDIT_QUESTION_TYPES =
  | "heading"
  | "subHeading"
  | "paragraph"
  | "textInputSingle"
  | "textInputMultiple"
  | "checkBox"
  | "selectBoxSingle"
  | "selectBoxMultiple"
  | "radioBox"
  | "date"
  | "divider"
  | "group"
  | "orgProcessingConsent";

export const AUDIT_QUESTION = {
  HEADING: "heading",
  SUB_HEADING: "subHeading",
  PARAGRAPH: "paragraph",
  TEXT_INPUT_SINGLE: "textInputSingle",
  TEXT_INPUT_MULTIPLE: "textInputMultiple",
  CHECK_BOX: "checkBox",
  SELECT_BOX_SINGLE: "selectBoxSingle",
  SELECT_BOX_MULTIPLE: "selectBoxMultiple",
  RADIO_BOX: "radioBox",
  DATE: "date",
  DIVIDER: "divider",
  GROUP: "group",
  ORG_PROCESSING_CONSENT: "orgProcessingConsent"
} as const satisfies Record<string, AUDIT_QUESTION_TYPES>;
