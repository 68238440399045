import { Box } from "@material-ui/core";
import React from "react";
import useSWR from "swr";
import { getDataCategoryUsageApi } from "../../../api/deletionConceptApi";
import DeletionConceptStoragePeriodMetaView from "./DeletionConceptStoragePeriodMetaView";
import { DeletionConceptProcessMetaView } from "./DeletionConceptProcessMetaView";

export const DeletionConceptOverviewMetaView = (props: {
  readonly personGroupId: string;
  readonly dataCategoryId: string;
}) => {
  const { data } = useSWR(`deletion-concept-metaview-${props.personGroupId}-${props.dataCategoryId}`, () =>
    getDataCategoryUsageApi({
      personGroupId: props.personGroupId,
      dataCategoryId: props.dataCategoryId
    })
  );
  return (
    <Box p={3}>
      <DeletionConceptStoragePeriodMetaView dataStorage={data?.usagePerStoragePeriod || emptyArray} />
      <DeletionConceptProcessMetaView processes={data?.usagePerProcess || emptyArray} />
    </Box>
  );
};

const emptyArray: any[] = [];
