import { defaultAxios } from "app/api/axios/axiosProvider";
import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { isAxiosErrorWithCode } from "../axios/axiosErrorHandler";
import {
  OAuth2MFALoginPayload,
  OAuth2PasswordLoginPayload,
  OAuth2RefreshTokenLoginPayload,
  OAuth2ResponseDTO,
  OAuth2SSOLoginPayload
} from "./userOAuth2ApiInterfaces";

export const axiosInstance: AxiosInstance = defaultAxios({
  baseURL: `${apiEndpoints.userUrl}/api/oauth2`
});

const oauth2Authorization = () => window.btoa("caralegal-webapp:not-a-secret-dont-report");
export const oauth2LoginApi = async (
  loginData: OAuth2PasswordLoginPayload | OAuth2RefreshTokenLoginPayload | OAuth2MFALoginPayload | OAuth2SSOLoginPayload
): Promise<OAuth2ResponseDTO | null> => {
  try {
    const payloadInURLEncodedForm = toUrlEncodedForm(loginData);
    const response = await axiosInstance.post<OAuth2ResponseDTO>("/token", payloadInURLEncodedForm, {
      headers: {
        Authorization: `Basic ${oauth2Authorization()}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return response.data || {};
  } catch (error) {
    if (isAxiosErrorWithCode(error, 400, "invalid_grant")) {
      // invalid grant means credentials is wrong, this have to return null always, and parents have to handle
      // this is because it's context dependent, on username password login, it means user types wrong password
      // on refresh token login, it means the login have expired,.. so no error handling here
      return null;
    }
    throw error;
  }
};

const toUrlEncodedForm = (
  loginData: OAuth2PasswordLoginPayload | OAuth2RefreshTokenLoginPayload | OAuth2MFALoginPayload | OAuth2SSOLoginPayload
): URLSearchParams => {
  const searchParams = new URLSearchParams();
  Object.entries(loginData).forEach(([key, value]) => searchParams.append(key, value));
  return searchParams;
};
