import { getHasuraClientSDK } from "../api/hasuraApi";
import { ProfileGetAllQuery } from "../api/generated/graphql/hasura";
import { v4 } from "uuid";

export type PROFILE_TYPE =
  | "dataController"
  | "jointController"
  | "controllerRepresentative"
  | "dataProtectionOfficer"
  | "dataProcessor";

export interface ProfileData {
  readonly profileTitle: string;
  readonly ZIP: string;
  readonly city: string;
  readonly contactPerson: string;
  readonly email: string;
  readonly name: string;
  readonly phone: string;
  readonly street: string;
}

export interface Profile extends ProfileData {
  readonly type: string;
  readonly id: string;
}

export interface AllProfileData {
  readonly dataController: ProfileData;
  readonly jointController: ProfileData;
  readonly controllerRepresentative: ProfileData;
  readonly dataProtectionOfficer: ProfileData;
  readonly dataProcessor: ProfileData;
}

const toProfileData = (data: ProfileGetAllQuery["frontend_access_profile"][0]): Profile => {
  return {
    id: data.id,
    type: data.type,
    profileTitle: data.profile_title || "",
    ZIP: data.zip || "",
    city: data.city || "",
    contactPerson: data.contact_person || "",
    email: data.email || "",
    name: data.name || "",
    phone: data.phone || "",
    street: data.street || ""
  };
};

export const getDefaultExportProfileData = async (tenantId: string): Promise<AllProfileData> => {
  const client = await getHasuraClientSDK();
  const response = await client.profileGetAll({
    tenant_id: tenantId
  });
  if (response.frontend_access_profile.length === 0) {
    return {
      ...emptyTenantProfileInformation
    };
  }

  return {
    controllerRepresentative: response.frontend_access_profile
      .filter(it => it.type === "controllerRepresentative")
      .map(toProfileData)[0] || { ...emptyProfileData },
    dataController: response.frontend_access_profile
      .filter(it => it.type === "dataController")
      .map(toProfileData)[0] || { ...emptyProfileData },
    dataProtectionOfficer: response.frontend_access_profile
      .filter(it => it.type === "dataProtectionOfficer")
      .map(toProfileData)[0] || { ...emptyProfileData },
    jointController: response.frontend_access_profile
      .filter(it => it.type === "jointController")
      .map(toProfileData)[0] || { ...emptyProfileData },
    dataProcessor: response.frontend_access_profile.filter(it => it.type === "dataProcessor").map(toProfileData)[0] || {
      ...emptyProfileData
    }
  };
};

export const updateDefaultExportProfile = async (input: {
  readonly tenantId: string;
  readonly updateData: Partial<ProfileData>;
  readonly profileType: PROFILE_TYPE;
}): Promise<void> => {
  const { tenantId, updateData, profileType } = input;
  const client = await getHasuraClientSDK();
  const response = await client.profileGetAllByType({
    tenant_id: tenantId,
    type: profileType
  });

  const existingProfile = response.frontend_access_profile[0];
  if (!existingProfile) {
    await client.profileUpsert({
      type: profileType,
      id: v4(),
      profile_title: "",
      city: updateData.city === undefined ? null : updateData.city,
      contact_person: updateData.contactPerson === undefined ? null : updateData.contactPerson,
      email: updateData.email === undefined ? null : updateData.email,
      name: updateData.name === undefined ? null : updateData.name,
      phone: updateData.phone === undefined ? null : updateData.phone,
      street: updateData.street === undefined ? null : updateData.street,
      zip: updateData.ZIP === undefined ? null : updateData.ZIP,
      deleted_at: null
    });
    return;
  }

  await client.profileUpsert({
    type: profileType,
    id: existingProfile.id,
    profile_title: "",
    city: updateData.city === undefined ? existingProfile.city : updateData.city,
    contact_person: updateData.contactPerson === undefined ? existingProfile.contact_person : updateData.contactPerson,
    email: updateData.email === undefined ? existingProfile.email : updateData.email,
    name: updateData.name === undefined ? existingProfile.name : updateData.name,
    phone: updateData.phone === undefined ? existingProfile.phone : updateData.phone,
    street: updateData.street === undefined ? existingProfile.street : updateData.street,
    zip: updateData.ZIP === undefined ? existingProfile.zip : updateData.ZIP,
    deleted_at: null
  });
};

export const getExportProfilesPerPage = async (input: {
  readonly tenantId: string;
  readonly profileType: PROFILE_TYPE;
}): Promise<Profile[]> => {
  const { tenantId, profileType } = input;
  const client = await getHasuraClientSDK();
  const response = await client.profileGetAllByType({
    tenant_id: tenantId,
    type: profileType
  });

  return response.frontend_access_profile.map(toProfileData);
};

export const getExportProfileById = async (input: {
  readonly tenantId: string;
  readonly profileType: PROFILE_TYPE;
  readonly profileId: string;
}): Promise<Profile | null> => {
  const { tenantId, profileType, profileId } = input;
  const client = await getHasuraClientSDK();
  const response = await client.profileGetAllByType({
    tenant_id: tenantId,
    type: profileType
  });

  const profile = response.frontend_access_profile.find(({ id }) => id === profileId);
  return profile ? toProfileData(profile) : null;
};

export const updateExportProfile = async (input: {
  readonly tenantId: string;
  readonly updateData: Partial<ProfileData>;
  readonly profileType: PROFILE_TYPE;
  readonly profileId: string;
}): Promise<void> => {
  const { tenantId, updateData, profileType, profileId } = input;

  const client = await getHasuraClientSDK();
  const response = await client.profileGetById({
    tenant_id: tenantId,
    type: profileType,
    id: profileId
  });
  const existingProfile = response.frontend_access_profile_by_pk;
  if (!existingProfile) {
    throw new Error(`Profile with id ${profileId} does not exist`);
  }

  await client.profileUpsert({
    type: profileType,
    id: existingProfile.id,
    profile_title: updateData.profileTitle === undefined ? existingProfile.profile_title : updateData.profileTitle,
    city: updateData.city === undefined ? existingProfile.city : updateData.city,
    contact_person: updateData.contactPerson === undefined ? existingProfile.contact_person : updateData.contactPerson,
    email: updateData.email === undefined ? existingProfile.email : updateData.email,
    name: updateData.name === undefined ? existingProfile.name : updateData.name,
    phone: updateData.phone === undefined ? existingProfile.phone : updateData.phone,
    street: updateData.street === undefined ? existingProfile.street : updateData.street,
    zip: updateData.ZIP === undefined ? existingProfile.zip : updateData.ZIP,
    deleted_at: null
  });
};

export const deleteExportProfile = async (input: {
  readonly tenantId: string;
  readonly profileType: PROFILE_TYPE;
  readonly profileId: string;
}): Promise<void> => {
  const { tenantId, profileType, profileId } = input;
  const client = await getHasuraClientSDK();
  const response = await client.profileGetById({
    tenant_id: tenantId,
    type: profileType,
    id: profileId
  });
  const existingProfile = response.frontend_access_profile_by_pk;
  if (!existingProfile) {
    return;
  }

  await client.profileUpsert({
    type: profileType,
    id: existingProfile.id,
    profile_title: existingProfile.profile_title,
    city: existingProfile.city,
    contact_person: existingProfile.contact_person,
    email: existingProfile.email,
    name: existingProfile.name,
    phone: existingProfile.phone,
    street: existingProfile.street,
    zip: existingProfile.zip,
    deleted_at: new Date()
  });
};

export const createExportProfile = async (input: {
  readonly profileData: Partial<ProfileData>;
  readonly profileType: PROFILE_TYPE;
  readonly profileId: string;
}) => {
  const { profileData, profileType, profileId } = input;
  const client = await getHasuraClientSDK();
  await client.profileUpsert({
    type: profileType,
    id: profileId,
    profile_title: profileData.profileTitle || null,
    city: profileData.city || null,
    contact_person: profileData.contactPerson || null,
    email: profileData.email || null,
    name: profileData.name || null,
    phone: profileData.phone || null,
    street: profileData.street || null,
    zip: profileData.ZIP || null,
    deleted_at: null
  });
};

export const emptyProfileData: ProfileData = {
  profileTitle: "",
  name: "",
  street: "",
  ZIP: "",
  city: "",
  contactPerson: "",
  email: "",
  phone: ""
};

export const emptyTenantProfileInformation: AllProfileData = {
  dataController: {
    ...emptyProfileData
  },
  jointController: {
    ...emptyProfileData
  },
  controllerRepresentative: {
    ...emptyProfileData
  },
  dataProtectionOfficer: {
    ...emptyProfileData
  },
  dataProcessor: {
    ...emptyProfileData
  }
};
