import { createNaturalSorter } from "./naturalSort";

export const translateAndSortCountriesOrAreas = (countriesOrAreas, translateObject) => {
  const naturalSorter = createNaturalSorter();
  return countriesOrAreas
    .map(countryOrArea => {
      const translationKey = "countries:" + countryOrArea.id;
      return {
        ...countryOrArea,
        name: translateObject(translationKey, countryOrArea.id)
      };
    })
    .sort((a, b) => naturalSorter(a.name, b.name));
};
