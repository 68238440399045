import { AxiosInstance, AxiosRequestConfig } from "axios";
import { getLoginPreference } from "../../handlers/authentication/authenticationStorage";
import { toUTF8Base64 } from "../../utils/utf8Base64";
import { defaultAxios } from "./axiosProvider";
import { assertGetUserAccessToken } from "../../handlers/authentication/authenticationTokenHandler";
import { getCustomDualMode } from "../../pages/debug/customDualMode";
import i18n from "app/i18n";

export const defaultOTCAuthenticatedAxios = (axiosOptions?: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = defaultAxios(axiosOptions);

  axiosInstance.interceptors.request.use(
    async config => {
      if (!config?.headers) {
        return config;
      }

      config.headers.Authorization = `Bearer ${await assertGetUserAccessToken()}`;
      const loginPreference = await getLoginPreference();
      if (loginPreference?.tenantId && !config.headers["x-caralegal-tenant-id"]) {
        config.headers["x-caralegal-tenant-id"] = `base64:${toUTF8Base64(loginPreference.tenantId)}`;
      }

      const paDualMode = getCustomDualMode();
      if (paDualMode && !config.headers["x-caralegal-pa-dual-mode"]) {
        config.headers["x-caralegal-pa-dual-mode"] = paDualMode;
      }

      const languageCode = i18n.language || "en-US";
      if (!config.headers["Accept-Language"]) {
        config.headers["Accept-Language"] = languageCode;
      }

      return config;
    },
    error => Promise.reject(error)
  );

  return axiosInstance;
};
