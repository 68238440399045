import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { AxiosInstance, AxiosResponse } from "axios";
import { useNavigateWithQueryParams } from "app/router/router-custom-hooks";
import { useUserGroups } from "app/contexts/group-context";

const GroupOverviewController = (axiosInstance: AxiosInstance): OverviewController => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { reloadGroups } = useUserGroups();
  const { t: tFilter } = useTranslation("filter_criteria");
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.GROUP);

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: tFilter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: tFilter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: tFilter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: tFilter("oldFirst")
      }
    ],
    [tFilter]
  );

  const getOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup, `/overview/`);
    if (!data) {
      return null;
    }

    return {
      ...data,
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/groups/${id}`);
  };

  const addItem = async (data: OverviewNewItem): Promise<AxiosResponse> => {
    const response = await baseController.addItem({
      name: data?.title
    });
    reloadGroups();
    return response;
  };

  const addItemAndGo = async () => {
    navigateWithQueryParams("/groups/new");
  };

  const deleteItem = async (id: string): Promise<void> => {
    const response = await baseController.deleteItem(id);
    reloadGroups();
    return response;
  };

  const patchItem = async (id: string, data: object) => {
    const response = await baseController.patchItem(id, {
      name: "title" in data && data.title
    });
    reloadGroups();
    return response;
  };

  return {
    ...baseController,
    getOverview,
    addItemAndGo,
    addItem,
    goToItem,
    patchItem,
    deleteItem
  };
};

export default GroupOverviewController;
