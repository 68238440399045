import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { apiEndpoints } from "./apiEndpoint";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";

export interface OrgUnitDTO {
  readonly tenantId: string;
  readonly id: string;
  readonly name: string;
  readonly fullName: string;
  readonly parentId: string | null;
  readonly type: "internal" | "external";
}

export interface OrgUnitsDTO {
  readonly orgUnits: OrgUnitDTO[];
}

export interface UpdateOrgUnitDTO {
  readonly name?: string;
  readonly parentId?: string;
}

export interface CreateOrgUnitDTO {
  readonly name: string;
  readonly parentId: string;
}

export interface ExpandedOrgUnitResponseDTO {
  readonly ids: string[];
}

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.orgUnitUrl}/api`
});

export const getOrgUnitsApi = async (ids?: string[], type?: "internal" | "external"): Promise<OrgUnitDTO[]> => {
  const response = await axiosInstance.get<OrgUnitsDTO>("/orgunits", {
    params: {
      ids,
      type: type || "internal"
    }
  });

  return response.data.orgUnits || [];
};

export const getOrgUnitApi = async (id: string): Promise<OrgUnitDTO | null> => {
  try {
    const response = await axiosInstance.get<OrgUnitDTO>(`/orgunits/${id}`);
    return response.data;
  } catch (error: unknown) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const postOrgUnitApi = async (orgUnit: CreateOrgUnitDTO): Promise<string> => {
  const response = await axiosInstance.post("/orgunits", orgUnit);
  return response.headers["x-resource-id"] || "";
};

export const patchOrgUnitApi = async (id: string, payload: UpdateOrgUnitDTO): Promise<void> => {
  await axiosInstance.patch(`/orgunits/${id}`, payload);
};

export const deleteOrgUnitApi = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/orgunits/${id}`);
};

export const expandOrgUnitsApi = async (ids: string[]): Promise<string[]> => {
  const response = await axiosInstance.post<ExpandedOrgUnitResponseDTO>("/orgunits/expand", { ids });
  return response.data.ids || [];
};
