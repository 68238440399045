import { toJSDateObjectIfFirebaseDate, toJSDateObjectIfISO8601 } from "./utility/date-helper";
import {
  createDataSubjectRequestTasksApi,
  DataSubjectRequestDTO,
  deleteDataSubjectRequestsApi,
  DSRUpdateRequestDTO,
  getDataSubjectRequestApi,
  updateDataSubjectRequestsApi
} from "../api/dataSubjectRequestApi";

export const STATUSES = {
  done: "DONE",
  open: "OPEN"
} as const;

export const verificationQuestionId = "data_subject_verification";

export type DataSubjectRequest = ReturnType<typeof dtoToDomainDSR>;

const dtoToDomainDSR = (dsrDTO: Partial<DataSubjectRequestDTO>) => {
  const dsrMetadata = {
    ...dsrDTO,
    created: toJSDateObjectIfISO8601(dsrDTO.created)
  };

  const dsrInputData = dsrMetadata.inputData;
  if (!dsrInputData) {
    return dsrMetadata;
  }

  return {
    ...dsrMetadata,
    inputData: {
      ...dsrInputData,
      dueDate: toJSDateObjectIfISO8601(dsrInputData.dueDate),
      receivedOn: toJSDateObjectIfISO8601(dsrInputData.receivedOn)
    }
  };
};

export const getDataSubjectRequest = async (dataSubjectRequestId: string) => {
  try {
    return dtoToDomainDSR(await getDataSubjectRequestApi(dataSubjectRequestId));
  } catch (error: any) {
    if (error.response?.status === 404) {
      // 404 is not found, just return null to signify the DSR does not exist
      return null;
    }
    throw error;
  }
};

export const deleteDataSubjectRequest = async (dataSubjectRequestId: string) =>
  await deleteDataSubjectRequestsApi(dataSubjectRequestId);

export const renameDataSubjectRequest = async (dataSubjectRequestId: string, title: string) => {
  await updateDataSubjectRequestsApi(dataSubjectRequestId, {
    title: title
  });
};

export const markDataSubjectRequestAsDone = async (dataSubjectRequestId: string) => {
  await updateDataSubjectRequestsApi(dataSubjectRequestId, {
    status: STATUSES.done
  });
};

export type BasicDSRUpdatePayload = Pick<
  DSRUpdateRequestDTO,
  | "orgUnitId"
  | "furtherOrgUnitIds"
  | "type"
  | "description"
  | "receivedOn"
  | "personGroups"
  | "isDataSubjectVerified"
  | "dataTypeIds"
  | "labelIds"
  | "firstName"
  | "lastName"
  | "email"
>;

// for updating of data subject request fields which requires no special business logic
export const updateBasicDataSubjectRequestData = async (
  dataSubjectRequestId: string,
  {
    orgUnitId,
    furtherOrgUnitIds,
    type,
    description,
    receivedOn,
    personGroups,
    isDataSubjectVerified,
    dataTypeIds,
    labelIds,
    firstName,
    lastName,
    email
  }: BasicDSRUpdatePayload
) => {
  const updatePayload = {
    orgUnitId: orgUnitId,
    furtherOrgUnitIds,
    type: type,
    description: description,
    receivedOn: toJSDateObjectIfFirebaseDate(receivedOn)?.toISOString?.(),
    personGroups: personGroups,
    dataTypeIds: dataTypeIds,
    isDataSubjectVerified: isDataSubjectVerified,
    labelIds: labelIds,
    firstName: firstName,
    lastName: lastName,
    email: email
  };
  await updateDataSubjectRequestsApi(dataSubjectRequestId, updatePayload);
};

export const assignsDataSubjectRequest = async (dataSubjectRequestId: string, assigneeUID: string) => {
  await updateDataSubjectRequestsApi(dataSubjectRequestId, {
    assignedTo: assigneeUID
  });
};

export const changeDataSubjectRequestDueDate = async (dataSubjectRequestId: string, dueDate: string | null) => {
  await updateDataSubjectRequestsApi(dataSubjectRequestId, {
    dueDate: dueDate
  });
};

export const createTasksBasedOnRequestType = async (dataSubjectRequestId: string, language: string) => {
  await createDataSubjectRequestTasksApi(dataSubjectRequestId, language);
};
