import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { ListItem, ListItemIcon } from "@material-ui/core";
import RenderIcon from "components/RenderIcon/RenderIcon";

const useStyles = makeStyles(theme => ({
  paginationRow: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "2px hidden",
    borderBottom: "2px hidden"
  },
  addDocumentIcon: {
    color: theme.palette.text.primary
  },
  innerForm: {
    display: "flex",
    alignItems: "center"
  },
  text: {}
}));

export default function MetaViewPagination({
  toolTipNext,
  toolTipPrevious,
  setDisplayableChunk,
  setIndexOffset,
  list,
  numberDescriptionText = "........",
  selectedElementId,
  preselectChunkFn
}) {
  const classes = useStyles();
  const [lengthPerChunk] = useState(1);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [chunkedLists, setChunkedLists] = useState([[]]);
  const [preselectionDone, setPreselectionDone] = useState(false);

  const totalItems = list?.length;
  const currentChunkStartIndex = currentChunkIndex * lengthPerChunk + 1;
  const chunkEndIndex = currentChunkIndex * lengthPerChunk + lengthPerChunk;
  const chunkEndIndexConsideringTotalItems = chunkEndIndex < totalItems ? chunkEndIndex : totalItems;

  const nextDisabled = currentChunkIndex > chunkedLists.length - 2;
  const previousDisabled = currentChunkIndex < 1;
  const displayNextChunk = function () {
    const newChunk = currentChunkIndex + 1;
    if (newChunk < chunkedLists.length) {
      setCurrentChunkIndex(newChunk);
    }
  };
  const displayPreviousChunk = function () {
    const newChunk = currentChunkIndex - 1;
    if (newChunk >= 0) {
      setCurrentChunkIndex(newChunk);
    }
  };

  // set pagination index to 0 when switching between elements
  useEffect(() => {
    if (selectedElementId) {
      setCurrentChunkIndex(0);
      setIndexOffset(0);
    }
  }, [selectedElementId, setIndexOffset]);

  useEffect(() => {
    if (list.length === 0) {
      setDisplayableChunk([]);
      setChunkedLists([[]]);
      setCurrentChunkIndex(0);
    }

    if (list.length > 0) {
      const newChunkedLists = [];

      for (let i = 0; i < totalItems; i = i + lengthPerChunk) {
        const chunkedList = list.slice(i, i + lengthPerChunk);
        newChunkedLists.push(chunkedList);
      }

      setChunkedLists(newChunkedLists);
      if (currentChunkIndex > newChunkedLists.length - 1) {
        setCurrentChunkIndex(newChunkedLists.length - 1);
      }
    }
  }, [currentChunkIndex, lengthPerChunk, list, setDisplayableChunk, totalItems]);

  useEffect(() => {
    if (chunkedLists[currentChunkIndex]) {
      setDisplayableChunk(chunkedLists[currentChunkIndex]);
      setIndexOffset(currentChunkIndex * lengthPerChunk);
    } else {
      setDisplayableChunk([]);
      setIndexOffset(0);
    }
  }, [currentChunkIndex, chunkedLists, setDisplayableChunk, setIndexOffset, lengthPerChunk]);

  useEffect(() => {
    if (!preselectionDone && chunkedLists.length && chunkedLists[0].length && preselectChunkFn) {
      setPreselectionDone(true);
      for (const [chunkIndex, chunkedList] of chunkedLists.entries()) {
        if (chunkedList.some(it => preselectChunkFn(it))) {
          setCurrentChunkIndex(chunkIndex);
          return;
        }
      }
    }
  }, [chunkedLists, preselectChunkFn, preselectionDone]);

  return (
    <>
      {list.length > 0 && (
        <>
          <ListItem className={classes.paginationRow}>
            <ListItemIcon className={classes.addDocumentIcon}>
              <RenderIcon
                icon={"ArrowBackIcon"}
                iconSize={"small"}
                toolTipTitle={toolTipPrevious}
                callBackFunction={displayPreviousChunk}
                disabled={previousDisabled}
              />
            </ListItemIcon>
            <div className={classes.innerForm}>
              {lengthPerChunk === 1 && (
                <span className={classes.text}>
                  <b>{currentChunkStartIndex} </b> {numberDescriptionText} <b>{totalItems}</b>
                </span>
              )}
              {lengthPerChunk > 1 && (
                <span className={classes.text}>
                  <b>{currentChunkStartIndex} </b> - <b>{chunkEndIndexConsideringTotalItems}</b> {numberDescriptionText}{" "}
                  <b>{totalItems}</b>
                </span>
              )}
            </div>
            <ListItemIcon className={classes.addDocumentIcon}>
              <RenderIcon
                icon={"ArrowForwardIcon"}
                iconSize={"small"}
                toolTipTitle={toolTipNext}
                callBackFunction={displayNextChunk}
                disabled={nextDisabled}
              />
            </ListItemIcon>
          </ListItem>
        </>
      )}
    </>
  );
}
