import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import DataBreachesOverviewController from "app/pages/data-breaches/overview/DataBreachesOverviewController";
import DataSubjectRequestOverviewController from "app/pages/data-subject-requests/overview/DataSubjectRequestOverviewController";
import DeletionConceptOverviewController from "app/pages/deletion-concept/overview/DeletionConceptOverviewController";
import WebsiteOverviewController from "app/pages/website/WebsiteOverviewController";
import TaskOverviewController from "app/pages/tasks/overview/TaskOverviewController";
import AssetOverviewController from "app/pages/assets/overview/AssetsOverviewController";
import { apiEndpoints } from "app/api/apiEndpoint";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import ExternalRecipientOverviewController from "app/pages/service-providers/overview/ExternalRecipientOverviewController";
import DataLocationOverviewController from "app/pages/datalocations/DataLocationOverviewController";
import ProcessesOverviewController from "app/pages/processes/overview/ProcessesOverviewController";
import AuditsOverviewController from "app/pages/audits/overview/AuditsOverviewContoller";
import { defaultOTCAuthenticatedAxios } from "app/api/axios/loggedInAxiosProvider";
import TomOverviewController from "app/pages/toms/overview/TomOverviewController";
import RiskOverviewController from "app/pages/risks/overview/RiskOverviewController";
import UsersOverviewController from "app/pages/users/overview/UsersOverviewController";
import GroupOverviewController from "app/pages/groups/overview/GroupOverviewController";
import RoleOverviewController from "app/pages/roles/overview/RoleOverviewController";
import i18n from "app/i18n";
import TokenOverviewController from "app/pages/tokens/overview/TokenOverviewController";
import ResourceTypeOverviewController from "app/pages/resources/overview/resources/ResourceTypeOverviewController";
import PersonGroupsOverviewController from "app/pages/resources/overview/person-groups/PersonGroupsOverviewController";
import ProcessorPAOverviewController from "app/pages/processor-pas/overview/ProcessorPAOverviewController";
import DocumentOverviewController from "app/pages/documents/overview/DocumentOverviewController";
import AssessmentResponseOverviewController from "app/pages/audits/assessment/overview/AssessmentResponseOverviewController";
import AITechDocsOverviewController from "app/pages/ai-tech-docs/overview/AITechDocsOverviewController";
import TomRiskOverviewController from "../../../app/pages/toms/overview/TomRiskOverviewController";
import PATechDocsOverviewController from "../../../app/pages/processes/overview/PATechDocsOverviewController";

interface FabricConfig {
  readonly baseURL: string;
  readonly instance: (axiosInstance: AxiosInstance, instanceParams?: any) => any; // should actually returns base controller type
  readonly axiosOptions?: AxiosRequestConfig;
}

const fabricConfig = {
  [COLLECTIONS.DATA_BREACHES]: {
    baseURL: `${apiEndpoints.dataBreachUrl}/api/databreaches`,
    instance: DataBreachesOverviewController
  },
  [COLLECTIONS.DATA_SUBJECT_REQUESTS]: {
    baseURL: `${apiEndpoints.dataSubjectRequestUrl}/api/v1/datasubjectrequests`,
    instance: DataSubjectRequestOverviewController
  },
  [COLLECTIONS.RISK]: {
    baseURL: `${apiEndpoints.riskUrl}/api/overview`,
    instance: RiskOverviewController
  },
  [COLLECTIONS.DELETION_CONCEPT]: {
    baseURL: `${apiEndpoints.deletionConceptUrl}/api/deletionconcepts`,
    instance: DeletionConceptOverviewController,
    axiosOptions: {
      timeout: 0 // no fix yet on the BE for this
    }
  },
  [COLLECTIONS.WEBSITES]: {
    baseURL: `${apiEndpoints.cookieBoxUrl}/api/websites`,
    instance: WebsiteOverviewController,
    axiosOptions: {
      timeout: 0 // depends on slow 3rd party, no choice
    }
  },
  [COLLECTIONS.TASKS]: {
    baseURL: `${apiEndpoints.taskUrl}/api/v1/overview`,
    instance: TaskOverviewController
  },
  [COLLECTIONS.RESOURCES]: {
    baseURL: `${apiEndpoints.resourceUrl}/api/resources`,
    instance: ResourceTypeOverviewController
  },
  [COLLECTIONS.PERSON_GROUPS]: {
    baseURL: `${apiEndpoints.dataAssets}/api/v1/data-assets`,
    instance: PersonGroupsOverviewController
  },
  [COLLECTIONS.ASSETS]: {
    baseURL: `${apiEndpoints.assetUrl}/api/v1/assets`,
    instance: AssetOverviewController
  },
  [COLLECTIONS.EXTERNAL_RECIPIENTS]: {
    baseURL: `${apiEndpoints.externalRecipientUrl}/api/external-recipients`,
    instance: ExternalRecipientOverviewController
  },
  [COLLECTIONS.DATA_LOCATIONS]: {
    baseURL: `${apiEndpoints.externalRecipientUrl}/api`,
    instance: DataLocationOverviewController
  },
  [COLLECTIONS.AUDITS]: {
    baseURL: `${apiEndpoints.auditUrl}/api`,
    instance: AuditsOverviewController
  },
  [COLLECTIONS.PROCESSES]: {
    baseURL: `${apiEndpoints.paUrl}/api/v1/pa/overview`,
    instance: ProcessesOverviewController
  },
  [COLLECTIONS.PROCESSES_AITECHDOCS]: {
    baseURL: `${apiEndpoints.paUrl}/api/v1/pa`,
    instance: PATechDocsOverviewController
  },
  [COLLECTIONS.PROCESSOR_PAS]: {
    baseURL: `${apiEndpoints.paUrl}/api/v1/processor-pa/overview`,
    instance: ProcessorPAOverviewController
  },
  [COLLECTIONS.TOM]: {
    baseURL: `${apiEndpoints.tomUrl}/api/toms`,
    instance: TomOverviewController
  },
  [COLLECTIONS.TOM_RISKS]: {
    baseURL: `${apiEndpoints.tomUrl}/api/toms`,
    instance: TomRiskOverviewController
  },
  [COLLECTIONS.USER]: {
    baseURL: `${apiEndpoints.userUrl}/api/users`,
    instance: UsersOverviewController
  },
  [COLLECTIONS.GROUP]: {
    baseURL: `${apiEndpoints.userUrl}/api/groups`,
    instance: GroupOverviewController
  },
  [COLLECTIONS.ROLE]: {
    baseURL: `${apiEndpoints.userUrl}/api/roles`,
    instance: RoleOverviewController
  },
  [COLLECTIONS.TOKEN]: {
    baseURL: `${apiEndpoints.userUrl}/api/tokens`,
    instance: TokenOverviewController
  },
  [COLLECTIONS.DOCUMENTS]: {
    baseURL: `${apiEndpoints.documentUrl}/api/v1/document-center`,
    instance: DocumentOverviewController
  },
  [COLLECTIONS.ASSESSMENT_RESPONSE]: {
    baseURL: `${apiEndpoints.auditUrl}/api`,
    instance: AssessmentResponseOverviewController
  },
  [COLLECTIONS.AI_TECH_DOCS]: {
    baseURL: `${apiEndpoints.aiTechDocUrl}/api/aitechdocs`,
    instance: AITechDocsOverviewController
  },
  [COLLECTIONS.NOTIFICATIONS]: null,
  [COLLECTIONS.TASK_DETAIL]: null,
  [COLLECTIONS.AUDIT_TEMPLATES]: null,
  [COLLECTIONS.FILE_STORAGE]: null,
  [COLLECTIONS.DPIAS]: null,
  [COLLECTIONS.RECURRING_TASKS]: null,
  [COLLECTIONS.RISK_MATRIX]: null
} as const satisfies Record<COLLECTION_TYPES, FabricConfig | null>;

const overviewControllerFabric = (collection: COLLECTION_TYPES, instanceParams?: any) => {
  const collectionConfig: FabricConfig | null = fabricConfig[collection];
  if (!collectionConfig) {
    throw new Error(`Unknown fabric collection: ${collection}`);
  }
  const axiosBaseConfig: AxiosRequestConfig = {
    ...(collectionConfig.axiosOptions || {}),
    baseURL: collectionConfig.baseURL,
    headers: { "Accept-Language": i18n.language || "en" }
  };
  const axiosInstance = defaultOTCAuthenticatedAxios(axiosBaseConfig);
  return collectionConfig.instance(axiosInstance, instanceParams);
};

export default overviewControllerFabric;
