import { makeStyles } from "@material-ui/styles";
import { useResources } from "../../../contexts/resource-context";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUserDepartments } from "../../../contexts/department-context";
import { getProcessStatusIconProps } from "../../questionnaires/utils/get-process-status-icon-props";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { getTextFromTextEditorJsonString } from "../../questionnaires/utils/textEditorConverter";
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { RenderPlainIcon } from "../../../../components/RenderIcon/RenderIcon";
import Collapse from "@material-ui/core/Collapse";
import GoTo from "../../shared/GoTo/GoTo";
import { COLLECTIONS } from "../../../collections";
import { chunk } from "lodash-es";
import BasicPagination from "../../../../components/BasicPagination/BasicPagination";
import { DataCategoryUsagePerProcessDTO } from "../../../api/deletionConceptApi";

import { displayArrayTextInDeletionConcept } from "./displayArrayTextInDeletionConcept";

const primaryTypographyProps = { noWrap: true };
const secondaryTypographyProps = { ...primaryTypographyProps, fontSize: "12px" };
const useStylesProcesses = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.blue[50],
    borderRadius: "10px",
    "& .MuiList-root": {
      padding: 0
    }
  },
  fieldName: {
    color: theme.palette.grey[500],
    fontSize: "11px"
  },
  fieldValue: { fontSize: "13px", whiteSpace: "pre-wrap" }
}));
const DeletionConceptProcessRowMetaView = ({ process }: { readonly process: DataCategoryUsagePerProcessDTO }) => {
  const cls = useStylesProcesses();
  const { translateById } = useResources();
  const { t } = useTranslation("processActivitiesInMetaView");
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded(lastExpandedValue => !lastExpandedValue), []);
  const { getDepartmentWithParentName } = useUserDepartments();
  const icon = getProcessStatusIconProps(process.status).icon;

  const getStoragePeriodText = useCallback(
    (storagePeriod: DataCategoryUsagePerProcessDTO) => {
      if (!storagePeriod?.storagePeriod || !storagePeriod?.storagePeriodUnit || storagePeriod?.storagePeriod === "0") {
        return t("notSet");
      }
      return `${storagePeriod.storagePeriod} ${t(storagePeriod.storagePeriodUnit)}`;
    },
    [t]
  );

  const fieldsToDisplay = useMemo(
    () => ({
      purposes: process.purposes.reduce(displayArrayTextInDeletionConcept, ""),
      storagePeriod: getStoragePeriodText(process),
      deletionTriggerId: translateById(RESOURCE_TYPES.DELETION_TRIGGER, process.deletionTriggerId || ""),
      justification: getTextFromTextEditorJsonString(process.justificationOfStoragePeriod || ""),
      deletionTypeId: process.deletionTypeIds
        .map(it => translateById(RESOURCE_TYPES.DATA_DELETION, it))
        .reduce(displayArrayTextInDeletionConcept, ""),
      deletionPractice: getTextFromTextEditorJsonString(process.deletionPractice || ""),
      orgUnitName: getDepartmentWithParentName(process.orgUnitId)
    }),
    [getDepartmentWithParentName, getStoragePeriodText, process, translateById]
  );

  const bodyEl = Object.keys(fieldsToDisplay).map(field => (
    <Box key={field}>
      <Box my={2}>
        <Typography className={cls.fieldName}>{t(field)}</Typography>
        <Typography className={cls.fieldValue}>
          {fieldsToDisplay[field as keyof typeof fieldsToDisplay] || t("notSet")}
        </Typography>
      </Box>
    </Box>
  ));
  const rowEl = (
    <>
      <ListItem button onClick={toggle}>
        <ListItemIcon>
          <RenderPlainIcon
            icon={icon}
            iconSize={"small"}
            toolTipTitle={t(`processes_overview:${process.status}`)}
            elementId={undefined}
            disabled={undefined}
            classes={undefined}
            additionalClasses={undefined}
          />
        </ListItemIcon>
        <ListItemText
          primary={process.title}
          secondary={fieldsToDisplay.storagePeriod}
          primaryTypographyProps={primaryTypographyProps}
          secondaryTypographyProps={secondaryTypographyProps}
        />
      </ListItem>
      <Collapse in={expanded}>
        <Box mx={1}>{bodyEl}</Box>
        <Box my={3} textAlign={"center"}>
          <GoTo
            collection={COLLECTIONS.PROCESSES}
            documentId={process.processActivityId}
            pageId={"periods"}
            tooltipText={t("goTo")}
            openInNewTab={true}
            questionId={undefined}
            taskId={undefined}
          />
        </Box>
      </Collapse>
    </>
  );

  return (
    <>
      {rowEl}
      <Divider />
    </>
  );
};
export const DeletionConceptProcessMetaView = ({
  processes
}: {
  readonly processes: DataCategoryUsagePerProcessDTO[];
}) => {
  const cls = useStylesProcesses();
  const { t } = useTranslation("resources_lists_data_types_categories_person_groups");
  const [page, setPage] = useState(1);
  const elementsPerPage = 5;

  useEffect(() => setPage(1), [processes]);

  const pages = chunk(processes, elementsPerPage);

  const paginationEl = processes.length > 5 && (
    <BasicPagination
      elementsPerPage={elementsPerPage}
      numberElements={processes.length}
      page={page}
      setPage={setPage}
    />
  );

  const listEl = (
    <Box>
      <Divider />
      <List>
        {(pages[page - 1] || []).map(process => (
          <DeletionConceptProcessRowMetaView key={process.processActivityId} process={process} />
        ))}
      </List>
    </Box>
  );

  const noProcessesEl = useMemo(
    () =>
      !processes?.length && (
        <Box my={2} mx={1} p={1} mt={3}>
          <Typography>{t("no_processes_category")}</Typography>
        </Box>
      ),
    [processes?.length, t]
  );

  return (
    <Box className={cls.card} p={3} mt={3}>
      <Box mb={3}>
        <Typography align="center" variant="h3">
          {t("service_providers_overview:processingActivities")}
        </Typography>
      </Box>
      {listEl}
      {paginationEl}
      {noProcessesEl}
    </Box>
  );
};
