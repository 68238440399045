import { Box } from "@material-ui/core";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import React from "react";

const SeenBadgeComponent = () => {
  return (
    <Box color="error.main" mr={2} pt={1}>
      <FiberNewIcon />
    </Box>
  );
};

export const SeenBadge = React.memo(SeenBadgeComponent);
