import React from "react";
import { useTranslation } from "react-i18next";

export default function BygoneTime({ timestamp }) {
  const { t } = useTranslation("date");
  return <span>{calculateBygoneTime(timestamp, t)}</span>;
}

export const calculateBygoneTime = function (timestamp, t) {
  const currentTime = new Date();
  let dateObj = null;
  if (timestamp instanceof Date) {
    dateObj = timestamp;
  } else {
    if (!timestamp || !timestamp.seconds) {
      return "n/a";
    }
    let seconds = timestamp.seconds * 1000;
    dateObj = new Date(seconds);
  }
  const dateDiff = currentTime - dateObj;
  const differenceInMinutes = Math.floor(dateDiff / 60000);
  const differenceInHours = Math.floor(dateDiff / 3600000);
  const differenceInDays = Math.floor(dateDiff / 86400000);

  if (differenceInMinutes < 1) {
    return t("seconds_ago");
  } else if (differenceInMinutes === 1) {
    return t("one_minute_ago");
  } else if (differenceInMinutes <= 59) {
    return t("minutes_ago", { min: differenceInMinutes });
  } else if (differenceInHours === 1) {
    return t("one_hour_ago");
  } else if (differenceInHours < 24) {
    return t("hours_ago", { hrs: differenceInHours });
  } else if (differenceInDays === 1) {
    return t("yesterday");
  } else if (differenceInDays < 7) {
    return t(dateObj.getDay().toString());
  } else {
    return dateObj.toLocaleDateString();
  }
};

export function calculateByGoneTimeUTC(timestamp, t) {
  const currentTime = new Date();
  let dateObj = null;
  if (timestamp instanceof Date) {
    dateObj = timestamp;
  } else {
    if (!timestamp || !timestamp.seconds) {
      return "n/a";
    }
    let seconds = timestamp.seconds * 1000;
    dateObj = new Date(seconds);
  }
  const timeZoneOffset = timestamp.getTimezoneOffset(); // calculate difference between utc & local time in minutes
  const offSetInMilliseconds = timeZoneOffset * 60 * 1000;
  const dateDiff = currentTime - dateObj + offSetInMilliseconds;
  const differenceInMinutes = Math.floor(dateDiff / 60000);
  const differenceInHours = Math.floor(dateDiff / 3600000);
  const differenceInDays = Math.floor(dateDiff / 86400000);
  if (differenceInMinutes <= 59) {
    return t("minutes_ago", { min: differenceInMinutes });
  } else if (differenceInHours === 1) {
    return t("one_hour_ago");
  } else if (differenceInHours < 24) {
    return t("hours_ago", { hrs: differenceInHours });
  } else if (differenceInDays === 1) {
    return t("yesterday");
  } else {
    const dateStringOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };
    return dateObj.toLocaleDateString("de-de", dateStringOptions);
  }
}
