import { getDeletionConceptExports } from "app/api/deletionConceptApi";
import { getTenantInformation } from "app/handlers/tenantHandler";
import { getDocumentTitle } from "app/utils/get-title-for-exported-document";
import { TFunction } from "i18next";

export async function exportDeletionConceptExcel(input: {
  readonly tenantId: string;
  readonly t: TFunction;
  readonly orgUnitIds?: string[];
  readonly personGroupIds?: string[];
  readonly dataStorageIds?: string[];
}) {
  const excelBlob = await getDeletionConceptExports(input);
  const tenantData = await getTenantInformation(input.tenantId);
  if (!tenantData) {
    throw new Error(`Current tenant does not exist?! ${input.tenantId}`);
  }

  const fileName = `${getDocumentTitle(tenantData.name, input.t("deletion_concept_overview:header"))}.xlsx`;

  const url = window.URL.createObjectURL(excelBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}
