import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetProcessorApi, useUpdateProcessorApi } from "../../../api/processorPAApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useProcessorPAEditConfirmation } from "../ProcessorPAEditConfirmation";
import { useMetaView } from "../../../contexts/meta-view-context";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { PatchProcessorPADTO } from "../../../api/generated/process-service";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";
import { COLLECTIONS } from "app/collections";

export const ProcessorCategoriesOfProcessing = ({ readonly }: { readonly?: boolean }) => {
  const { id } = useParams();
  const { t } = useTranslation("processor_pa_page");
  const { data } = useGetProcessorApi({ documentId: id || "" });
  const { updateProcessor } = useUpdateProcessorApi({ documentId: id || "" });
  const { beforeProcessUpdate, hardResetTrigger } = useProcessorPAEditConfirmation();
  const { queueUpdates } = useUpdateQueues<PatchProcessorPADTO, PatchProcessorPADTO>({
    triggerUpdate: updateProcessor
  });

  const [processingCategoryIds, setProcessingCategoryIds] = useState<string[]>([]);
  const [processingCategoryDescription, setProcessingCategoryDescription] = useState<string>("");
  useEffect(() => {
    if (data?.processorPA) {
      setProcessingCategoryIds(data.processorPA.processingCategoryIds || []);
      setProcessingCategoryDescription(data.processorPA.processingCategoryDescription || "");
    }
  }, [data?.processorPA]);

  const onProcessingCategoryIdsChange = useCallback(
    (processingCategoryIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setProcessingCategoryIds(processingCategoryIds);
        return queueUpdates({ processingCategoryIds });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const onProcessingCategoryDescriptionChange = useCallback(
    (processingCategoryDescription: string) => {
      return beforeProcessUpdate(async () => {
        setProcessingCategoryDescription(processingCategoryDescription);
        return queueUpdates({ processingCategoryDescription });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const infoCard = useMemo(
    () => ({
      enteringPA: {
        title: t("enteringInfoCardTitle", ""),
        text: t("enteringInfoCardText", "")
      },
      processingCategoryIds: {
        title: t("processingCategoryIds-title", ""),
        text: t("processingCategoryIds-info", "")
      },
      processingCategoryDescription: {
        title: t("processingCategoryDescription-title", ""),
        text: t("processingCategoryDescription-info", "")
      }
    }),
    [t]
  );

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo(infoCard.enteringPA);
  }, [setInfo, infoCard.enteringPA]);

  /*  ATTACHMENTS */
  const isFileUploadOnProcessingActivitiesAllowed = useIsFeaturePresent(FEATURES.FILE_UPLOAD_ON_PROCESSING_ACTIVITIES);
  const attachmentEl = isFileUploadOnProcessingActivitiesAllowed ? (
    <Box mt={4}>
      <QuestionnaireSubHeader text={t("questionnaires:attachments")} />
      <AttachmentsOverviewOBS docId={id} category={COLLECTIONS.PROCESSOR_PAS + "-processing"} disabled={readonly} />
    </Box>
  ) : (
    <></>
  );

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("categories-of-processing")} />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.RESOURCE}
          resourceType={RESOURCE_TYPES.PROCESSING_CATEGORY}
          value={processingCategoryIds}
          translationId={"processingCategoryIds"}
          questionId="processingCategoryIds"
          questionName={t("processingCategoryIds")}
          onChange={onProcessingCategoryIdsChange}
          info={infoCard.processingCategoryIds}
          multiSelect={true}
          allowAdd={true}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          // this component have problem with controlled state, so force re-render when dialog is changed
          key={`processingCategoryDescription-${hardResetTrigger}`}
          qType={QUESTION_TYPE.TEXT_EDITOR}
          questionId={"processingCategoryDescription"}
          questionName={t("processingCategoryDescription")}
          info={infoCard.processingCategoryDescription}
          value={processingCategoryDescription}
          onChange={onProcessingCategoryDescriptionChange}
          disabled={readonly}
        />
      </Box>
      {attachmentEl}
    </Box>
  );
};
