import React, { useEffect, useMemo, useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Alert, AlertProps } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";

const infoSx: SxProps = {
  alignItems: "self-start",
  color: "grey.800",
  backgroundColor: "alert.infoBackground",
  "& .MuiAlert-icon": {
    color: "alert.info"
  }
};

const successSx: SxProps = {
  alignItems: "self-start",
  color: "grey.800",
  backgroundColor: "alert.successBackground",
  "& .MuiAlert-icon": {
    color: "alert.success"
  }
};

const warningSx: SxProps = {
  alignItems: "self-start",
  color: "grey.800",
  backgroundColor: "alert.warningBackground",
  "& .MuiAlert-icon": {
    color: "alert.warning"
  }
};

const errorSx: SxProps = {
  alignItems: "self-start",
  color: "grey.800",
  backgroundColor: "alert.errorBackground",
  "& .MuiAlert-icon": {
    color: "alert.error"
  },
  paddingRight: "25px",
  paddingBottom: "8px"
};

const fullWidthSx: SxProps = {
  width: "100%",
  fontWeight: 400
};

export interface CustomAlertProps extends Pick<AlertProps, "severity" | "onClose" | "icon" | "id" | "style"> {
  readonly children?: React.ReactNode;
  readonly fullWidth?: boolean;
}

export default function CustomAlert({ severity, onClose, icon, id, children, style, fullWidth }: CustomAlertProps) {
  const [sxProps, setSxProps] = useState<SxProps>({});
  useEffect(() => {
    const fullWidthSxValue = fullWidth ? fullWidthSx : {};
    switch (severity) {
      case "info":
        setSxProps({
          ...fullWidthSxValue,
          ...infoSx
        } as SxProps);
        break;
      case "warning":
        setSxProps({
          ...fullWidthSxValue,
          ...warningSx
        } as SxProps);
        break;
      case "success":
        setSxProps({
          ...fullWidthSxValue,
          ...successSx
        } as SxProps);
        break;
      case "error":
        setSxProps({
          ...fullWidthSxValue,
          ...errorSx
        } as SxProps);
        break;
      default:
        setSxProps({
          ...fullWidthSxValue,
          ...infoSx
        } as SxProps);
    }
  }, [severity, fullWidth]);

  const newIcon = useMemo(() => {
    switch (severity) {
      case "info":
        return <InfoIcon />;
      case "warning":
        return <WarningIcon />;
      case "success":
        return <CheckCircleIcon />;
      case "error":
        return <ErrorIcon />;
      default:
        return <ErrorIcon />;
    }
  }, [severity]);

  return (
    <Alert sx={sxProps} style={style} severity={severity} onClose={onClose} icon={newIcon} id={id}>
      {children}
    </Alert>
  );
}
