import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EditTextFieldItem from "components/EditTextFieldItem/EditTextFieldItem";
import InlineIcons from "components/InlineIcons/InlineIcons";
import EllipsisMenu from "components/EllipsisMenu/EllipsisMenu";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  checkboxAllWrapper: {
    padding: 0
  },
  checkboxAll: {
    paddingRight: 40
  },
  checkbox: {
    position: "absolute",
    marginTop: "0.5%"
  },
  rowHeight: {
    height: "54px"
  },
  rowHeightPlusPaddingLeft: {
    height: "54px",
    paddingLeft: 74
  },
  documentViewContent: {
    padding: "30px"
  },
  chip: {
    width: 70,
    height: 25,
    marginRight: 40
  },
  root: {
    "& p": {
      fontWeight: 700,
      color: theme.palette.grey[500],
      paddingTop: 3
    }
  },
  noDetermineStatusIcon: {
    marginLeft: "55px"
  },
  regularIcon: {
    marginRight: "5px",
    cursor: "pointer",
    color: theme.palette.grey[700]
  },
  textWithEllipsis: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    wordBreak: "break-all",
    wordWrap: "break-word"
  },
  subText: {
    color: theme.palette.text.disabled,
    fontSize: 12,
    fontWeight: 400
  }
}));

export default function Row({
  selected,
  docId,
  inlineConfirmAction,
  handleCheckboxSelect,
  selectedCheckboxes,
  showCheckboxes,
  index,
  rowDataItemText,
  rowDataItemSubText,
  rowDataItemValue,
  rowDataItemLabels,
  determineStatusIcon,
  inlineIcons,
  ellipsisMenu,
  callOnMouseOver,
  callOnMouseLeave,
  onEdit,
  successfulEdit,
  successfulDelete,
  successfulConfirmAction,
  editToolTipText,
  cancelToolTipText,
  onClick,
  inlineDeletion,
  chip
}) {
  const classes = useStyles();
  const [rowBeingEdited, setRowBeingEdited] = useState(null);
  const [rowBeingDeleted, setRowBeingDeleted] = useState(null);
  const [rowConfirmAction, setRowConfirmAction] = useState(null);
  const [inlineIconsMod, setInlineIconsMod] = useState(inlineIcons);

  const cancelDeleteMode = useCallback(() => {
    setRowBeingDeleted(null);
  }, [setRowBeingDeleted]);

  const cancelConfirmAction = useCallback(() => {
    setRowConfirmAction(null);
  }, [setRowConfirmAction]);

  const cancelEditMode = useCallback(() => {
    setRowBeingEdited(null);
  }, [setRowBeingEdited]);

  const onSelectConfirmAction = useCallback(
    index => {
      setRowConfirmAction(index);
    },
    [setRowConfirmAction]
  );

  const onSelectToEdit = useCallback(
    index => {
      cancelDeleteMode();
      setRowBeingEdited(index);
    },
    [cancelDeleteMode, setRowBeingEdited]
  );

  const onSelectToDelete = useCallback(
    index => {
      cancelEditMode();
      setRowBeingDeleted(index);
    },
    [cancelEditMode, setRowBeingDeleted]
  );

  const rowIcon = useMemo(() => {
    if (isNaN(index) || !determineStatusIcon) {
      return <></>;
    } else {
      return determineStatusIcon(String(index));
    }
  }, [index, determineStatusIcon]);

  useEffect(() => {
    if (successfulEdit) {
      cancelEditMode();
    } else if (successfulDelete) {
      cancelDeleteMode();
    } else if (successfulConfirmAction) {
      cancelConfirmAction();
    }
  }, [
    successfulEdit,
    successfulDelete,
    successfulConfirmAction,
    cancelEditMode,
    cancelDeleteMode,
    cancelConfirmAction
  ]);

  const checkIconsForEditDeleteConfirmActions = useCallback(() => {
    setInlineIconsMod(
      inlineIcons.map(inlineIcon => {
        const newIcon = {};
        switch (inlineIcon.icon) {
          case "CreateIcon":
            newIcon.icon = inlineIcon.icon;
            newIcon.callBackFunction = inlineIcon.callBackFunction ?? onSelectToEdit;
            newIcon.toolTipTitle = inlineIcon.toolTipTitle;
            return newIcon;
          // cases for confirm actions
          case "ConfirmIcon":
          case "UnlinkIcon":
            newIcon.icon = inlineIcon.icon;
            newIcon.callBackFunction = inlineIcon.callBackFunction ?? onSelectConfirmAction;
            newIcon.toolTipTitle = inlineIcon.toolTipTitle;
            return newIcon;
          case "DeleteIcon":
            newIcon.icon = inlineIcon.icon;
            newIcon.callBackFunction = inlineIcon.callBackFunction ?? onSelectToDelete;
            newIcon.toolTipTitle = inlineIcon.toolTipTitle;
            return newIcon;
          default:
            return inlineIcon;
        }
      })
    );
  }, [inlineIcons, setInlineIconsMod, onSelectToEdit, onSelectConfirmAction, onSelectToDelete]);

  useEffect(() => {
    if (inlineIcons) {
      checkIconsForEditDeleteConfirmActions();
    }
  }, [inlineIcons, checkIconsForEditDeleteConfirmActions]);

  const handleCheckboxSelectCallback = useCallback(() => {
    return handleCheckboxSelect(docId);
  }, [handleCheckboxSelect, docId]);

  const onMouseOverCallback = useCallback(() => {
    callOnMouseOver?.(index);
  }, [callOnMouseOver, index]);

  const onClickCallback = useCallback(() => {
    onClick?.(index);
  }, [onClick, index]);

  const onEditCallback = useCallback(
    value => {
      onEdit?.(index, value);
    },
    [onEdit, index]
  );

  const cancelConfirmActionElement = useMemo(() => {
    if (!inlineConfirmAction) {
      return <></>;
    }
    return React.cloneElement(
      inlineConfirmAction,
      {
        onCancel: cancelConfirmAction
      },
      null
    );
  }, [inlineConfirmAction, cancelConfirmAction]);

  const cancelDeleteActionElement = useMemo(() => {
    if (!inlineDeletion) {
      return <></>;
    }
    return React.cloneElement(inlineDeletion, { onCancel: cancelDeleteMode }, null);
  }, [inlineDeletion, cancelDeleteMode]);

  const listItemClass = showCheckboxes === true ? classes.rowHeightPlusPaddingLeft : classes.rowHeight;
  return (
    <>
      {rowBeingEdited !== index && rowBeingDeleted !== index && rowConfirmAction !== index && (
        <>
          {showCheckboxes && (
            <div className={classes.checkboxAll}>
              <Checkbox
                color={"primary"}
                key={"Checkbox" + index}
                className={classes.checkbox}
                onChange={handleCheckboxSelectCallback}
                checked={selectedCheckboxes.includes(docId)}
              />
            </div>
          )}
          <ListItem
            selected={selected}
            data-testid={`row-${index}`}
            key={"ListItem" + index}
            onMouseEnter={onMouseOverCallback}
            onMouseLeave={callOnMouseLeave}
            onClick={onClickCallback}
            button
            className={listItemClass}
          >
            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
              {determineStatusIcon && !showCheckboxes && (
                <Grid item>
                  <Grid container alignItems="center">
                    <ListItemIcon>{rowIcon}</ListItemIcon>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={true} zeroMinWidth>
                <Grid container wrap="nowrap" alignItems="center">
                  {chip && <Grid item>{chip}</Grid>}
                  {rowDataItemText && (
                    <Grid item zeroMinWidth>
                      <Grid container alignItems="center">
                        <Grid item xs={12} zeroMinWidth>
                          <Grid container alignItems="center" wrap="nowrap">
                            <Grid item zeroMinWidth>
                              <div className={classes.textWithEllipsis}>{rowDataItemText}</div>
                            </Grid>
                            {rowDataItemLabels && <Grid item>{rowDataItemLabels}</Grid>}
                          </Grid>
                        </Grid>
                        {rowDataItemSubText && (
                          <Grid item xs={12} zeroMinWidth>
                            <div className={`${classes.textWithEllipsis} ${classes.subText}`}>{rowDataItemSubText}</div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {(inlineIcons || ellipsisMenu) && (
                <Grid item>
                  <Grid container alignItems="center">
                    {inlineIcons && (
                      <InlineIcons
                        iconSize={"small"}
                        data={inlineIconsMod}
                        elementId={index}
                        additionalIconClasses={classes}
                      />
                    )}
                    {ellipsisMenu && <EllipsisMenu iconSize={"small"} data={ellipsisMenu} elementId={docId} />}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ListItem>
        </>
      )}
      {rowBeingEdited === index && (
        <ListItem button data-testid={`row-edit-${index}`}>
          <EditTextFieldItem
            value={rowDataItemValue ?? rowDataItemText}
            onConfirm={onEditCallback}
            onCancel={cancelEditMode}
            editToolTip={editToolTipText}
            cancelToolTip={cancelToolTipText}
          />
        </ListItem>
      )}
      {inlineConfirmAction && rowConfirmAction !== null && (
        <ListItem button data-testid={`row-confirm-${index}`} className={listItemClass}>
          {cancelConfirmActionElement}
        </ListItem>
      )}
      {inlineDeletion && rowBeingDeleted !== null && (
        <ListItem button data-testid={`row-delete-${index}`} className={listItemClass}>
          {cancelDeleteActionElement}
        </ListItem>
      )}
    </>
  );
}
