import useSWR, { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { DefaultApi } from "../../../api/generated/process-service";
import { apiEndpoints } from "../../../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../../api/axios/loggedInAxiosProvider";

export const mutatePAAiTechDoc = (id: string) => mutate(key => Array.isArray(key) && key[0] === `ai_precheck_${id}`); // mutate the precheck data to update the status

export const usePAAITechDoc = (id: string) => {
  const { i18n } = useTranslation();
  const { data, mutate } = useSWR(id ? [`ai_precheck_${id}`, "techdoc", i18n.language] : null, async () => {
    return processClient
      .getAiTechDocsPrecheckStatus(id || "", {
        headers: {
          "Accept-Language": i18n?.language || "en"
        }
      })
      .then(resp => resp.data);
  });
  return { data, mutate };
};
const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());
