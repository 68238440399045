import { getHasuraClientSDK } from "../app/api/hasuraApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export interface UserNotification {
  readonly notificationId: string;
  readonly collection: string;
  readonly docId: string;
  readonly senderUID: string;
  readonly docName: string | null;
  readonly pageId: string | null;
  readonly idempotencyId: string | null;
  readonly createdAt: string;
  readonly clickedAt: string | null;
  readonly readAt: string | null;
  readonly title: string | null;
  readonly origin: {
    readonly taskId: string | null;
    readonly questionId: string | null;
    readonly type: string | null;
    readonly commentId: string | null;
    readonly commentReplyId: string | null;
  };
}

export const useUserNotifications = () => {
  const { auth } = useAuthentication();
  const {
    data: notifications,
    isLoading,
    isValidating,
    mutate
  } = useSWR(auth ? ["notifications", auth.tenantId, auth.uid] : null, async keys => {
    const [_, tenantId, userId] = keys;
    const client = await getHasuraClientSDK();
    const response = await client.userNotificationGetAll({
      tenant_id: tenantId,
      user_id: userId
    });

    return response.frontend_access_user_notification.map(
      it =>
        ({
          notificationId: it.notification_id,
          collection: it.collection,
          docId: it.doc_id,
          senderUID: it.sender_uid,
          docName: it.doc_name || null,
          pageId: it.page_id || null,
          idempotencyId: it.idempotency_id || null,
          createdAt: it.created_at,
          clickedAt: it.clicked_at || null,
          readAt: it.read_at || null,
          title: it.title || null,
          origin: {
            taskId: it.origin_task_id || null,
            questionId: it.origin_question_id || null,
            type: it.origin_type || null,
            commentId: it.origin_comment_id || null,
            commentReplyId: it.origin_comment_reply_id || null
          }
        }) satisfies UserNotification
    );
  });

  return {
    notifications,
    isLoading,
    isValidating,
    mutate
  };
};

export const useMarkNotificationsAsRead = () => {
  const { auth } = useAuthentication();
  const { trigger, isMutating } = useSWRMutation<
    any,
    any,
    [string, string, string] | null,
    { readonly notificationIds: string[] }
  >((auth?.tenantId && auth?.uid && ["notifications", auth?.tenantId, auth?.uid]) || null, async (keys, { arg }) => {
    const [, tenantId, uid] = keys || [];
    const client = await getHasuraClientSDK();
    await client.userNotificationMarkAsRead({
      tenant_id: tenantId,
      user_id: uid,
      notification_ids: arg.notificationIds,
      time: new Date()
    });
  });

  return {
    markAsRead: trigger,
    isMutating
  };
};
