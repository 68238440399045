import { useGetAITechDocApi, useUpdateAITechDocApi } from "../../../api/aiTechDocApi";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { UpdateAITechDocDTO } from "../../../api/generated/aitechdoc-service";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";

export const AITechDocTrainingData = ({ readonly, id }: { readonly readonly?: boolean; readonly id: string }) => {
  const { data: aiTechDoc } = useGetAITechDocApi({ documentId: id || "" });
  const { updateAITechDoc } = useUpdateAITechDocApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAITechDocDTO, UpdateAITechDocDTO>({
    triggerUpdate: updateAITechDoc
  });

  const { auth } = useAuthentication();

  const { t } = useTranslation("aitechdoc_details");
  const { setInfo } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const [isTrainedOrFineTuned, setIsTrainedOrFineTuned] = useState<boolean | null>(null);
  const [trainingMethodIds, setTrainingMethodIds] = useState<string[]>([]);
  const [trainingMethodExplanation, setTrainingMethodExplanation] = useState<string>("");
  const [trainingMethodDecisionExplanation, setTrainingMethodDecisionExplanation] = useState<string>("");
  const [trainingDataSelectionExplanation, setTrainingDataSelectionExplanation] = useState<string>("");
  const [trainingDataModificationExplanation, setTrainingDataModificationExplanation] = useState<string>("");
  const [isTrainingDataAnonymized, setIsTrainingDataAnonymized] = useState<boolean | null>(null);
  const [trainingDataAnonymizationExplanation, setTrainingDataAnonymizationExplanation] = useState<string>("");
  const [isTrainingDataUsingSyntheticData, setIsTrainingDataUsingSyntheticData] = useState<boolean | null>(null);
  const [trainingDataSyntheticDataExplanation, setTrainingDataSyntheticDataExplanation] = useState<string>("");

  useEffect(() => {
    if (aiTechDoc) {
      setIsTrainedOrFineTuned(aiTechDoc.isTrainedOrFineTuned === undefined ? null : aiTechDoc.isTrainedOrFineTuned);
      setTrainingMethodIds(aiTechDoc.trainingMethodIds || []);
      setTrainingMethodExplanation(aiTechDoc.trainingMethodExplanation || "");
      setTrainingMethodDecisionExplanation(aiTechDoc.trainingMethodDecisionExplanation || "");
      setTrainingDataSelectionExplanation(aiTechDoc.trainingDataSelectionExplanation || "");
      setTrainingDataModificationExplanation(aiTechDoc.trainingDataModificationExplanation || "");
      setIsTrainingDataAnonymized(
        aiTechDoc.isTrainingDataAnonymized === undefined ? null : aiTechDoc.isTrainingDataAnonymized
      );
      setTrainingDataAnonymizationExplanation(aiTechDoc.trainingDataAnonymizationExplanation || "");
      setIsTrainingDataUsingSyntheticData(
        aiTechDoc.isTrainingDataUsingSyntheticData === undefined ? null : aiTechDoc.isTrainingDataUsingSyntheticData
      );
      setTrainingDataSyntheticDataExplanation(aiTechDoc.trainingDataSyntheticDataExplanation || "");
    }
  }, [aiTechDoc]);

  const onIsTrainedOrFineTunedChange = useCallback(
    (value: boolean) => {
      setIsTrainedOrFineTuned(value);
      queueUpdates({ isTrainedOrFineTuned: value });
    },
    [queueUpdates]
  );

  const onTrainingMethodIdsChange = useCallback(
    (value: string[]) => {
      setTrainingMethodIds(value);
      queueUpdates({ trainingMethodIds: value });
    },
    [queueUpdates]
  );

  const onTrainingMethodExplanationChange = useCallback(
    (value: string) => {
      setTrainingMethodExplanation(value);
      queueUpdates({ trainingMethodExplanation: value });
    },
    [queueUpdates]
  );

  const onTrainingMethodDecisionExplanationChange = useCallback(
    (value: string) => {
      setTrainingMethodDecisionExplanation(value);
      queueUpdates({ trainingMethodDecisionExplanation: value });
    },
    [queueUpdates]
  );

  const onTrainingDataSelectionExplanationChange = useCallback(
    (value: string) => {
      setTrainingDataSelectionExplanation(value);
      queueUpdates({ trainingDataSelectionExplanation: value });
    },
    [queueUpdates]
  );

  const onTrainingDataModificationExplanationChange = useCallback(
    (value: string) => {
      setTrainingDataModificationExplanation(value);
      queueUpdates({ trainingDataModificationExplanation: value });
    },
    [queueUpdates]
  );

  const onIsTrainingDataAnonymizedChange = useCallback(
    (value: boolean) => {
      setIsTrainingDataAnonymized(value);
      queueUpdates({ isTrainingDataAnonymized: value });
    },
    [queueUpdates]
  );

  const onTrainingDataAnonymizationExplanationChange = useCallback(
    (value: string) => {
      setTrainingDataAnonymizationExplanation(value);
      queueUpdates({ trainingDataAnonymizationExplanation: value });
    },
    [queueUpdates]
  );

  const onIsTrainingDataUsingSyntheticDataChange = useCallback(
    (value: boolean) => {
      setIsTrainingDataUsingSyntheticData(value);
      queueUpdates({ isTrainingDataUsingSyntheticData: value });
    },
    [queueUpdates]
  );

  const onTrainingDataSyntheticDataExplanationChange = useCallback(
    (value: string) => {
      setTrainingDataSyntheticDataExplanation(value);
      queueUpdates({ trainingDataSyntheticDataExplanation: value });
    },
    [queueUpdates]
  );

  const docOrgUnitIds = useMemo(() => {
    return [aiTechDoc?.mainOrgUnitId, ...(aiTechDoc?.furtherOrgUnitIds || [])].filter((it): it is string => !!it);
  }, [aiTechDoc?.furtherOrgUnitIds, aiTechDoc?.mainOrgUnitId]);

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("trainingData")} />
      </Box>
      <Box mt={4}>
        <Question
          qType={QUESTION_TYPE.YES_NO}
          questionId={"isTrainedOrFineTuned"}
          questionName={t("isTrainedOrFineTuned")}
          title={t("isTrainedOrFineTuned")}
          value={isTrainedOrFineTuned}
          onChange={onIsTrainedOrFineTunedChange}
          disabled={readonly}
        />
      </Box>
      {isTrainedOrFineTuned ? (
        <Box>
          <Box>
            <Question
              qType={QUESTION_TYPE.RESOURCE}
              resourceType={RESOURCE_TYPES.AI_TRAINING_METHOD}
              questionId={"trainingMethodIds"}
              questionName={t("trainingMethodIds")}
              value={trainingMethodIds}
              onChange={onTrainingMethodIdsChange}
              disabled={readonly}
              allowAdd={true}
              orgUnitIds={docOrgUnitIds}
            />
          </Box>
          <Box>
            <Question
              qType={QUESTION_TYPE.TEXT_EDITOR}
              questionId={"trainingMethodExplanation"}
              questionName={t("trainingMethodExplanation")}
              value={trainingMethodExplanation}
              onChange={onTrainingMethodExplanationChange}
              disabled={readonly}
            />
          </Box>
          <Box>
            <Question
              qType={QUESTION_TYPE.TEXT_EDITOR}
              questionId={"trainingMethodDecisionExplanation"}
              questionName={t("trainingMethodDecisionExplanation")}
              value={trainingMethodDecisionExplanation}
              onChange={onTrainingMethodDecisionExplanationChange}
              disabled={readonly}
            />
          </Box>
          <Box>
            <Question
              qType={QUESTION_TYPE.TEXT_EDITOR}
              questionId={"trainingDataSelectionExplanation"}
              questionName={t("trainingDataSelectionExplanation")}
              value={trainingDataSelectionExplanation}
              onChange={onTrainingDataSelectionExplanationChange}
              disabled={readonly}
            />
          </Box>
          <Box>
            <Question
              qType={QUESTION_TYPE.TEXT_EDITOR}
              questionId={"trainingDataModificationExplanation"}
              questionName={t("trainingDataModificationExplanation")}
              value={trainingDataModificationExplanation}
              onChange={onTrainingDataModificationExplanationChange}
              disabled={readonly}
            />
          </Box>
          <Box mt={4}>
            <Question
              qType={QUESTION_TYPE.YES_NO}
              questionId={"isTrainingDataAnonymized"}
              questionName={t("isTrainingDataAnonymized")}
              title={t("isTrainingDataAnonymized")}
              value={isTrainingDataAnonymized}
              onChange={onIsTrainingDataAnonymizedChange}
              disabled={readonly}
            />
          </Box>
          {isTrainingDataAnonymized ? (
            <Box mt={-4}>
              <Question
                qType={QUESTION_TYPE.TEXT_EDITOR}
                questionId={"trainingDataAnonymizationExplanation"}
                questionName={t("trainingDataAnonymizationExplanation")}
                value={trainingDataAnonymizationExplanation}
                onChange={onTrainingDataAnonymizationExplanationChange}
                disabled={readonly}
              />
            </Box>
          ) : (
            <></>
          )}
          <Box mt={4}>
            <Question
              qType={QUESTION_TYPE.YES_NO}
              questionId={"isTrainingDataUsingSyntheticData"}
              questionName={t("isTrainingDataUsingSyntheticData")}
              title={t("isTrainingDataUsingSyntheticData")}
              value={isTrainingDataUsingSyntheticData}
              onChange={onIsTrainingDataUsingSyntheticDataChange}
              disabled={readonly}
            />
          </Box>
          {isTrainingDataUsingSyntheticData === false ? (
            <Box mt={-4}>
              <Question
                qType={QUESTION_TYPE.TEXT_EDITOR}
                questionId={"trainingDataSyntheticDataExplanation"}
                questionName={t("trainingDataSyntheticDataExplanation")}
                value={trainingDataSyntheticDataExplanation}
                onChange={onTrainingDataSyntheticDataExplanationChange}
                disabled={readonly}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
