import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, TextField, Typography } from "@material-ui/core";
import AuthenticationPageTemplate, { AuthenticationAlert } from "./AuthenticationPageTemplate";
import { useNavigate } from "react-router-dom";
import { isPasswordStrongEnough } from "app/pages/authentication/checkPasswordUtils";
import { resetPasswordApi, setInitialPasswordApi } from "app/api/user/userPasswordResetApi";
import { useHashFragments } from "app/router/router-custom-hooks";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { softCheckJWTTokenExpired, tryToParseJWTToken } from "../../handlers/authentication/jwtTokenSoftParse";
import { MFALoginRequired } from "../../handlers/authentication/authenticationError";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

export default function ResetPassword({ initialPassword }: { initialPassword: boolean }) {
  const navigate = useNavigate();
  const toLoginPage = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const { signInUserHook } = useAuthentication();
  const { token, url } = useHashFragments();
  const { t } = useTranslation("authentication");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const passwordsAreNotEqual = passwordConfirmation !== password;
  const formSubmittable = password && password !== "" && !passwordsAreNotEqual && isPasswordStrongEnough(password);
  const [alertNotification, setAlertNotification] = useState<AuthenticationAlert | null>(null);
  const [autoDismissAlert, setAutoDismissAlert] = useState<{
    timeoutMs: number | null;
    callback?: () => Promise<void>;
  }>({
    timeoutMs: null
  });

  const [resetInProgress, setResetInProgress] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);

  useEffect(() => {
    if (autoDismissAlert.timeoutMs) {
      const timeoutId = setTimeout(() => {
        setAlertNotification(null);
        autoDismissAlert.callback?.();
        setAutoDismissAlert({
          timeoutMs: null
        });
      }, autoDismissAlert.timeoutMs);
      return () => clearTimeout(timeoutId);
    }
  }, [autoDismissAlert]);

  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (token) {
      setUserId(tryToParseJWTToken(token)?.sub || "");
    }
  }, [token]);

  const executePasswordReset = useCallback(async () => {
    try {
      setResetInProgress(true);
      if (initialPassword) {
        await setInitialPasswordApi({ token, newPassword: password });
      } else {
        await resetPasswordApi({ token, newPassword: password });
      }
    } catch (error) {
      setResetInProgress(false);
      if (isAxiosErrorWithCode(error, 400, "Invalid Token and Password")) {
        setAlertNotification({
          title: t("error_expired_action_code"),
          severity: "error"
        });
      } else {
        console.error(error);
        setAlertNotification({
          title: t(initialPassword ? "error_unable_to_set_password" : "error_unable_to_reset_password"),
          severity: "error"
        });
      }

      setAutoDismissAlert({
        timeoutMs: 10000
      });
      return;
    }
    setResetInProgress(false);
    setResetSuccessful(true);
    setAlertNotification({
      title: t(initialPassword ? "set_password_successful" : "password_successfully_reset"),
      severity: "success"
    });

    try {
      await signInUserHook(userId, password);
    } catch (error) {
      if (error instanceof MFALoginRequired) {
        setAutoDismissAlert({
          timeoutMs: 3000,
          callback: async () => navigate("login")
        });
        return;
      }
      throw error;
    }
    navigate(url || "/login");
  }, [t, initialPassword, navigate, url, token, password, signInUserHook, userId]);

  useEffect(() => {
    const tokenExpired = softCheckJWTTokenExpired(token);
    if (tokenExpired) {
      setAlertNotification({
        title: t("error_link_expired"),
        severity: "error"
      });
      setAutoDismissAlert({
        timeoutMs: 10000
      });
      return;
    }

    setAlertNotification(null);
    setAutoDismissAlert({
      timeoutMs: null
    });
  }, [token, navigate, t]);

  const setPasswordCallback = useCallback(event => {
    setPassword(event.target.value);
  }, []);

  const setPasswordConfirmationCallback = useCallback(event => {
    setPasswordConfirmation(event.target.value);
  }, []);

  const onEnterKeyPressAttemptPasswordChange = useCallback(
    async event => {
      if (event.key === "Enter" && formSubmittable) {
        return executePasswordReset();
      }
    },
    [executePasswordReset, formSubmittable]
  );

  return (
    <AuthenticationPageTemplate alertNotification={alertNotification}>
      {!resetSuccessful && (
        <Box>
          <CustomAlert severity={"info"}>
            <Typography align="left">{parse(DOMPurify.sanitize(t("password_requirements")))}</Typography>
          </CustomAlert>
          <Box mt={2} mb={1}>
            <TextField
              fullWidth
              autoComplete="off"
              type="password"
              value={password}
              onChange={setPasswordCallback}
              variant="outlined"
              label={t("set_password")}
            />
          </Box>
          <Box mt={2} mb={1}>
            <TextField
              fullWidth
              autoComplete="off"
              type="password"
              value={passwordConfirmation}
              onChange={setPasswordConfirmationCallback}
              variant="outlined"
              label={t("password_confirm")}
              onKeyDown={onEnterKeyPressAttemptPasswordChange}
              error={passwordsAreNotEqual || (!isPasswordStrongEnough(password) && passwordConfirmation !== "")}
              helperText={
                passwordsAreNotEqual
                  ? t("authentication:error_passwords_not_equal")
                  : !isPasswordStrongEnough(password) && passwordConfirmation !== ""
                    ? t("authentication:error_password_not_strong")
                    : ""
              }
            />
          </Box>
          <Box mt={2} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!formSubmittable}
              onClick={executePasswordReset}
            >
              {resetInProgress && (
                <Box mr={1}>
                  <CircularProgress color="inherit" size={14} />
                </Box>
              )}
              {t("common:save")}
            </Button>
          </Box>
        </Box>
      )}
      <Box mt={2} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Button color="primary" onClick={toLoginPage}>
          {t("back_to_login")}
        </Button>
      </Box>
    </AuthenticationPageTemplate>
  );
}
