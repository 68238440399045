import React, { useCallback, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import QuestionTitle from "../QuestionTitle/QuestionTitle";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import CustomAlert from "../CustomAlert/CustomAlert";

const useStyles = makeStyles(theme => ({
  radioChecked: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {
    color: theme.palette.primary.main
  },
  alertText: {
    fontSize: 10
  },
  radioLabel: {
    paddingLeft: "5px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

export interface RadioButtonQuestionProps {
  defaultValue?: string;
  disabled?: boolean;
  disabledOptions?: string[];
  id?: string;
  index: number;
  infoAlertText?: string;
  infoIcon?: React.ReactNode;
  initialValue?: string | number | boolean;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  optionLabels?: string[];
  question: string;
  questionId: string;
  radioOptions: string[];
  onChange: (questionId: string, value: string, index: number) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export default function RadioButtonQuestion({
  defaultValue,
  disabled,
  disabledOptions,
  id,
  index,
  infoAlertText,
  infoIcon,
  initialValue,
  labelPlacement = "start",
  optionLabels,
  question,
  questionId,
  radioOptions,
  onChange,
  onFocus
}: RadioButtonQuestionProps) {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);
  const handleChange = useCallback(
    event => {
      setValue(event.target.value);
      onChange(questionId, event.target.value, index);
    },
    [onChange, questionId, index]
  );

  const radioClasses = useMemo(() => ({ root: classes.radioChecked, checked: classes.checked }), [classes]);
  return (
    <Box display="flex" alignItems="center" className={classes.radioLabel}>
      <Box flex={1} pt={1} pr={2}>
        <QuestionTitle>{question}</QuestionTitle>
        {infoAlertText && (
          <CustomAlert severity={"info"}>
            <span className={classes.alertText}>{infoAlertText}</span>
          </CustomAlert>
        )}
      </Box>
      {infoIcon && <Box>{infoIcon}</Box>}
      <Box>
        <RadioGroup
          defaultValue={defaultValue}
          id={id}
          name={questionId}
          row
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
        >
          {radioOptions.map((option, index) => (
            <Box mx={2} key={`${questionId}_${index}`}>
              <FormControlLabel
                control={<Radio classes={radioClasses} disabled={disabled} />}
                disabled={disabledOptions?.includes(option) || disabled}
                label={optionLabels && optionLabels[index]}
                labelPlacement={labelPlacement}
                value={option}
              />
            </Box>
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
}
