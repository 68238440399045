export function shouldShowEmailInputError(email) {
  // we return false here to not show an error if input hasn't been added yet
  if (!email) {
    return false;
  }
  return !isEmailInCorrectFormat(email);
}

export function isEmailInCorrectFormat(email) {
  return !!email.match(
    /^\S.+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  );
}

export function shouldShowPhoneInputError(phone) {
  if (!phone) {
    return false;
  }
  return !isPhomeInCorrectFormat(phone);
}

function isPhomeInCorrectFormat(phone) {
  return !!phone.match(/^(?=.*[0-9])[- +()0-9]+$/);
}
