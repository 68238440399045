import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useExternalRecipients } from "app/contexts/external-recipient-context";
import {
  ExternalRecipientOverviewItemDTO,
  PaginatedExternalRecipientsApiFilter,
  usePaginatedExternalRecipientsApi
} from "app/api/externalRecipientApi";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, makeStyles, Tooltip } from "@material-ui/core";
import { useResources } from "app/contexts/resource-context";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PolicyIcon from "@material-ui/icons/Policy";
import { isEUCountryOrArea } from "app/handlers/countryHandler";
import { AutocompleteGetTagProps } from "@material-ui/lab";
import ExternalRecipient from "app/pages/service-providers/ExternalRecipient";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useSnackbar } from "notistack";
import { useMetaView } from "app/contexts/meta-view-context";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useDataLocations } from "hook/useDataLocations";
import { debounce, isArray } from "lodash-es";
import DocTitle from "components/DocTitle/DocTitle";
import { useAuthentication } from "../../../../app/handlers/authentication/authentication-context";
import { Box, Chip } from "@mui/material";
import SuggestionChips from "./../../../SuggestionChips/SuggestionChips";
import { ChipProps } from "@mui/material/Chip/Chip";
import { tDeletedEntry } from "../../../../app/handlers/dataTypeTranslatorHandler";

const useStyles = makeStyles((theme: any) => ({
  colorErrorIcon: {
    color: `${theme.palette.error.main}!important`
  },
  colorInfoIcon: {
    color: `${theme.palette.success.main}!important`
  },
  colorLightWarningIcon: {
    color: `${theme.palette.warning.main}!important`
  },
  chipStyles: {
    maxWidth: "22em"
  },
  wrapperTags: {
    maxWidth: "-webkit-fill-available"
  },
  externalRecipientChip: {
    backgroundColor: theme.palette.blue[50]
  },
  externalRecipientChipDeleteIcon: {
    color: theme.palette.blue[200],
    "&:hover": {
      color: theme.palette.blue[400]
    }
  },
  chip: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
}));

const ExternalDataRecipientTag = ({
  externalRecipient,
  chipProps,
  onClick
}: {
  readonly externalRecipient: ExternalRecipientOverviewItemDTO | null;
  readonly chipProps: ChipProps;
  readonly onClick: (externalRecipient: ExternalRecipientOverviewItemDTO) => void;
}) => {
  const cls = useStyles();
  const { t } = useTranslation("questionnaires");
  const { translateById } = useResources();
  const { setMeta } = useMetaView();

  const [icon, setIcon] = useState<React.ReactElement | undefined>(undefined);
  const [status, setStatus] = useState<string | null>(null);
  const tooltipTitle = useMemo(() => externalRecipient?.title || tDeletedEntry({ t }), [externalRecipient?.title, t]);

  const defineIconAndStatus = useCallback(() => {
    if (!(externalRecipient && externalRecipient.dpLocations)) {
      setIcon(<ErrorOutlineOutlinedIcon className={cls.colorLightWarningIcon} />);
      setStatus(t("incompleteInformation"));
      return;
    }

    const dataProcessingNotEu = externalRecipient.dpLocations.some(countryId => !isEUCountryOrArea(countryId));
    const externalRecipientApproved = !!externalRecipient.approvedBy;

    if (dataProcessingNotEu && !externalRecipientApproved) {
      setIcon(<WarningIcon className={cls.colorErrorIcon} />);
      setStatus(t("nonEuNotApproved"));
    } else if (dataProcessingNotEu && externalRecipientApproved) {
      setIcon(<VerifiedUserIcon className={cls.colorInfoIcon} />);
      setStatus(t("nonEuApproved"));
    } else if (!dataProcessingNotEu && !externalRecipientApproved) {
      setIcon(<PolicyIcon className={cls.colorLightWarningIcon} />);
      setStatus(t("euNotApproved"));
    } else if (!dataProcessingNotEu && externalRecipientApproved) {
      setIcon(<VerifiedUserIcon className={cls.colorInfoIcon} />);
      setStatus(t("euApproved"));
    } else {
      setIcon(<ErrorOutlineOutlinedIcon className={cls.colorLightWarningIcon} />);
      setStatus(t("incompleteInformation"));
    }
  }, [cls.colorErrorIcon, cls.colorInfoIcon, cls.colorLightWarningIcon, externalRecipient, t]);

  useEffect(() => {
    defineIconAndStatus();
  }, [defineIconAndStatus, externalRecipient]);

  const onMouseOverCallback = useCallback(() => {
    if (!externalRecipient) {
      return;
    }

    setMeta({
      [t("common:name")]: externalRecipient.title,
      [t("locationDataProcessing")]: externalRecipient.dpLocations
        ? externalRecipient.dpLocations.map(element => " " + t("countries:" + element))
        : t("noInformation"),
      [t("headquarter")]: externalRecipient.country ? t("countries:" + externalRecipient.country) : t("noInformation"),
      [t("contractType")]: externalRecipient.contractTypes.length
        ? externalRecipient.contractTypes.map(
            option => ` ${translateById(RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE, option)}`
          )
        : t("noInformation"),
      [t("safeguards")]: externalRecipient.safeguards.length
        ? externalRecipient.safeguards.map(option => ` ${translateById(RESOURCE_TYPES.SAFEGUARD, option)}`)
        : t("noInformation"),
      [t("status")]: status
    });
  }, [externalRecipient, setMeta, status, t, translateById]);

  const onMouseLeaveCallback = useCallback(() => {
    setMeta(null);
  }, [setMeta]);

  const onClickCallback = useCallback(
    () => externalRecipient && onClick(externalRecipient),
    [externalRecipient, onClick]
  );

  const classes = useMemo(() => {
    return { root: cls.externalRecipientChip, deleteIcon: cls.externalRecipientChipDeleteIcon };
  }, [cls.externalRecipientChip, cls.externalRecipientChipDeleteIcon]);

  return (
    <span style={{ marginLeft: "6px", marginBottom: "6px" }}>
      <Tooltip title={tooltipTitle}>
        <Chip
          classes={classes}
          icon={icon || undefined}
          onMouseOver={onMouseOverCallback}
          onMouseLeave={onMouseLeaveCallback}
          label={tooltipTitle}
          {...chipProps}
          onClick={onClickCallback}
        />
      </Tooltip>
    </span>
  );
};

const ExternalDataRecipientsQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange,
  orgUnitIds,
  suggestionValueIds,
  multiSelect = true,
  allowAdd = true,
  blackListedIds
}: QuestionProps) => {
  const { t } = useTranslation("questionnaires");
  const { t: tErrorMessages } = useTranslation("error_messages");
  const { createExternalRecipientHook } = useExternalRecipients();
  const cls = useStyles();
  const { loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { enqueueSnackbar } = useSnackbar();
  const { dataLocationsReload } = useDataLocations();

  const [currentValue, setCurrentValue] = useState<string | string[] | null>(multiSelect ? [] : "");
  const currentValueAsArray = useMemo(
    () =>
      (currentValue ? (Array.isArray(currentValue) ? currentValue : [currentValue]) : []).filter(
        (it): it is string => typeof it === "string"
      ),
    [currentValue]
  );
  const selectedERFilter = useMemo<PaginatedExternalRecipientsApiFilter>(() => {
    return {
      id: {
        in: currentValueAsArray
      }
    };
  }, [currentValueAsArray]);

  const suggestionERFilter = useMemo<PaginatedExternalRecipientsApiFilter>(() => {
    return {
      id: {
        in: suggestionValueIds
      }
    };
  }, [suggestionValueIds]);

  const selectedERs = usePaginatedExternalRecipientsApi({
    filter: selectedERFilter,
    limit: currentValueAsArray.length ? 100 : 0
  });
  const suggestionERs = usePaginatedExternalRecipientsApi({
    filter: suggestionERFilter,
    limit: suggestionValueIds?.length ? 100 : 0
  });
  const selectedERsDict = useMemo(
    () =>
      selectedERs.items.reduce<{ [key: string]: ExternalRecipientOverviewItemDTO }>((acc, er) => {
        acc[er.id] = er;
        return acc;
      }, {}),
    [selectedERs.items]
  );
  const suggestionERsDict = useMemo(
    () =>
      suggestionERs.items.reduce<{ [key: string]: ExternalRecipientOverviewItemDTO }>((acc, er) => {
        acc[er.id] = er;
        return acc;
      }, {}),
    [suggestionERs.items]
  );

  const [externalDataRecipientModal, setExternalDataRecipientModal] = useState<ExternalRecipientOverviewItemDTO | null>(
    null
  );
  const [newExternalDataRecipientId, setNewExternalDataRecipientId] = useState<string | null>(null);
  const [notYetSelectedChips, setNotYetSelectedChips] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce((event: unknown, value: string) => {
      setSearch(value);
    }, 500),
    []
  );

  const filter = useMemo<PaginatedExternalRecipientsApiFilter>(() => {
    return {
      title: {
        contains: search
      },
      id: {
        notIn: currentValueAsArray.length ? currentValueAsArray : []
      },
      ...(orgUnitIds
        ? ({
            allOrgUnitIds: {
              in: orgUnitIds
            }
          } satisfies PaginatedExternalRecipientsApiFilter)
        : {})
    };
  }, [search, currentValueAsArray, orgUnitIds]);

  const sort = useMemo(() => {
    return [
      {
        title: "asc"
      }
    ];
  }, []);
  const resultERs = usePaginatedExternalRecipientsApi({
    limit: 20,
    filter,
    sort
  });
  const resultERsDict = useMemo(
    () =>
      resultERs.items.reduce<{ [key: string]: ExternalRecipientOverviewItemDTO }>((acc, er) => {
        acc[er.id] = er;
        return acc;
      }, {}),
    [resultERs.items]
  );

  const externalRecipientsFromLatestApiCall = resultERs.items;
  const externalRecipientsDict = useMemo(() => {
    return { ...resultERsDict, ...selectedERsDict, ...suggestionERsDict };
  }, [resultERsDict, selectedERsDict, suggestionERsDict]);

  useEffect(() => {
    if (newExternalDataRecipientId !== null) {
      setExternalDataRecipientModal(externalRecipientsDict[newExternalDataRecipientId] || null);
      onChange?.(multiSelect ? [...currentValueAsArray, newExternalDataRecipientId] : newExternalDataRecipientId);
      setNewExternalDataRecipientId(null);
    }
  }, [newExternalDataRecipientId, onChange, externalRecipientsDict, multiSelect, currentValueAsArray]);

  useEffect(() => {
    if (multiSelect) {
      const valueAsArray = (isArray(value) ? value : [value]).filter((it): it is string => typeof it === "string");
      setCurrentValue(valueAsArray);
    } else {
      const valueAsString = typeof value === "string" ? value : Array.isArray(value) && value[0] ? value[0] : "";
      setCurrentValue(valueAsString);
    }
  }, [multiSelect, value]);

  // update the current value when the dictionary is populated
  useEffect(() => {
    if (!multiSelect && currentValue && typeof currentValue === "string" && externalRecipientsDict[currentValue]) {
      setCurrentValue(externalRecipientsDict[currentValue].title);
    }
  }, [externalRecipientsDict, currentValue, multiSelect]);

  const optionIds = useMemo(() => {
    return externalRecipientsFromLatestApiCall.map(({ id }) => id).filter(id => !blackListedIds?.includes(id));
  }, [externalRecipientsFromLatestApiCall, blackListedIds]);

  const onChangeCallback = useCallback(
    (ids: string | string[] | null) => {
      onChange?.(ids);
    },
    [onChange]
  );

  const showExternalRecipientModal = useCallback(
    (externalRecipient: ExternalRecipientOverviewItemDTO) => setExternalDataRecipientModal(externalRecipient),
    []
  );
  const closeExternalRecipientModal = useCallback(() => setExternalDataRecipientModal(null), []);

  const onExternalRecipientSave = useCallback(async () => {
    closeExternalRecipientModal();
    dataLocationsReload();
  }, [closeExternalRecipientModal, dataLocationsReload]);

  const onAddCallback = useCallback(
    async options => {
      const newName = options[options.length - 1];
      const newNameAlreadyExisting = Object.values(externalRecipientsDict).some(er => er.title === newName);
      if (newNameAlreadyExisting) {
        return;
      }

      try {
        const createdExternalDataRecipientId = await createExternalRecipientHook(
          {
            name: newName,
            serviceType: "SERVICEPROVIDER"
          },
          false
        );

        if (createdExternalDataRecipientId) {
          await loadSeenItemsOfUserHook();

          dataLocationsReload();
          setNewExternalDataRecipientId(createdExternalDataRecipientId);
        }
      } catch (error: any) {
        enqueueSnackbar(tErrorMessages(error?.message), { variant: "error" });
      }
    },
    [
      createExternalRecipientHook,
      dataLocationsReload,
      enqueueSnackbar,
      externalRecipientsDict,
      loadSeenItemsOfUserHook,
      tErrorMessages
    ]
  );

  const newOptionEntryAlreadyExistsCallback = useCallback(
    (newTypedOption: string) => Boolean(externalRecipientsDict[newTypedOption]),
    [externalRecipientsDict]
  );

  const getOptionLabelCallback = useCallback(
    (id: string | undefined) => {
      if (!id) {
        return "";
      }
      const er = externalRecipientsDict[id];
      return (er && er.title) || id;
    },
    [externalRecipientsDict]
  );

  const renderTagsCallback = useCallback(
    (externalRecipientIds: string[], getTagProps: AutocompleteGetTagProps) => {
      return externalRecipientIds.map((externalRecipientId: string, index: number) => {
        const externalRecipient = externalRecipientsDict[externalRecipientId];
        const props = getTagProps({ index });
        if (!externalRecipient && selectedERs.isValidating) {
          return (
            <Chip
              key={`${externalRecipientId}-${index}-notfound`}
              {...props}
              label={
                <Box mt={0.5}>
                  <CircularProgress size={16} />
                </Box>
              }
            />
          );
        }
        return (
          <ExternalDataRecipientTag
            key={`${externalRecipientId}-${index}`}
            externalRecipient={externalRecipient || null}
            chipProps={props}
            onClick={showExternalRecipientModal}
          />
        );
      });
    },
    [showExternalRecipientModal, externalRecipientsDict, selectedERs.isValidating]
  );

  const externalRecipientModalEl = (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={Boolean(externalDataRecipientModal && externalDataRecipientModal.id)}
        onClose={closeExternalRecipientModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DocTitle title={externalDataRecipientModal?.title || ""} />
          <ExternalRecipient documentId={externalDataRecipientModal?.id || ""} renderOnlyDetailsView={true} />
        </DialogContent>
        <DialogActions>
          <Box mr={4} display={"flex"}>
            <Button variant="outlined" color="primary" onClick={closeExternalRecipientModal}>
              {t("questionnaires:cancel")}
            </Button>
            <Box mr={2} />
            <Button variant="contained" color="primary" onClick={onExternalRecipientSave}>
              {t("questionnaires:save")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );

  const handleBlur = useCallback(() => {
    onBlur?.();
    setSearch("");
  }, [onBlur]);

  const { auth } = useAuthentication();
  const userHasExternalRecipientWritePermission = auth?.permissions?.some(
    permission => permission === "er_write_org" || permission === "er_write_all"
  );

  useEffect(() => {
    if (suggestionValueIds && suggestionValueIds.length > 0) {
      const notYetSelected = suggestionValueIds.filter(value => !currentValueAsArray.includes(value));
      setNotYetSelectedChips(notYetSelected);
    }
  }, [suggestionValueIds, currentValueAsArray]);

  const onAddSuggestionCallback = useCallback(
    ids => {
      const allIds = [...ids, ...currentValueAsArray];
      onChange?.(allIds);
    },
    [onChange, currentValueAsArray]
  );

  return (
    <>
      <MultiAutocomplete
        onFocus={onFocus}
        onBlur={handleBlur}
        isLoading={resultERs.isValidating}
        onScrollEndReached={resultERs.loadMore}
        limitTags={8}
        options={optionIds}
        renderTags={renderTagsCallback}
        selected={currentValue}
        updateSelected={onChangeCallback}
        onInputChange={handleInputChange}
        disableDefaultOptionsSorting
        updateOptions={onAddCallback}
        newOptionEntryAlreadyExists={newOptionEntryAlreadyExistsCallback}
        getOptionLabel={getOptionLabelCallback}
        hasMultiSelect={multiSelect}
        addText={allowAdd && userHasExternalRecipientWritePermission ? t("add_pg") : undefined}
        placeholder={t("questionnaires:searchInput")}
        label={questionName || t("dataRecipients:external")}
        disableClearable={false}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
      <SuggestionChips
        modalTitle={t("processor_pa_page:addProcessorExternalRecipients")}
        optionIds={notYetSelectedChips}
        getOptionLabel={getOptionLabelCallback}
        allowAdd={false}
        onSuggestionsAdded={onAddSuggestionCallback}
      />
      {externalRecipientModalEl}
    </>
  );
};

export default ExternalDataRecipientsQuestion;
