export class NotLoggedInError extends Error {}

export class LoggedOutInactiveError extends Error {}

export class MFALoginRequired extends Error {}

export class MFAEnforced extends Error {}

export class TooManyWrongPasswordAttempts extends Error {}

export class MFACheckSMS extends Error {}

export class MFATokenError extends Error {}

export class WrongCredentials extends Error {}
