import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import ExternalDataRecipientsQuestion from "../../../components/Question/types/resources/ExternalDataRecipientsQuestion";

export default function ExternalRecipientsPickerModal({
  open,
  onChange,
  selectedIDs,
  label,
  onClose,
  orgUnitIds
}: {
  readonly open: boolean;
  readonly selectedIDs: string[];
  readonly label?: string;
  readonly onChange: (erIds: string[]) => void;
  readonly onClose: () => void;
  readonly orgUnitIds?: string[];
}) {
  const { t } = useTranslation();
  const [selectedExternalRecipientIds, setSelectedExternalRecipientIds] = useState<string[]>([]);
  const onSave = useCallback(() => onChange(selectedExternalRecipientIds), [onChange, selectedExternalRecipientIds]);
  useEffect(() => {
    setSelectedExternalRecipientIds(selectedIDs);
  }, [selectedIDs]);
  const onChangeSelection = useCallback(ids => {
    setSelectedExternalRecipientIds(ids);
  }, []);
  const modalBodyEl = useMemo(
    () => (
      <ExternalDataRecipientsQuestion
        onChange={onChangeSelection}
        value={selectedExternalRecipientIds}
        orgUnitIds={orgUnitIds}
      />
    ),
    [onChangeSelection, orgUnitIds, selectedExternalRecipientIds]
  );
  const buttons: ConfirmationModalButtonProps[] = [
    {
      confirmButton: false,
      title: t("common:cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: onClose
    },
    {
      confirmButton: true,
      title: t("service_providers_overview:save"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: onSave
    }
  ];
  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onClose}
      modalTitle={t("service_providers_overview:link_tooltip_activate_external_recipient")}
      modalText={label || ""}
      buttons={buttons}
      modalBody={modalBodyEl}
      variant={"info"}
    />
  );
}
