import { useCallback, useState } from "react";

type PromisedCallbackHooks<T extends (...args: any[]) => Promise<any>> = {
  trigger: (...args: Parameters<T>) => void;
  pending: boolean;
  result?: ReturnType<T> extends Promise<infer R> ? R : never;
  error?: Error;
};
/**
 * A hook that wraps a promise-returning callback and returns the promise status
 * @param callback a useCallback function that returns a promise
 * @returns PromisedCallbackHooks, an object containing the trigger function, pending status, result and error
 * @example
 * const updateStatusCallback = React.useCallback(
 *   async (status: string) => updateStatusApi(status),
 *   [updateStatusApi]
 * );
 * const { trigger, pending, result, error } = usePromisedCallback(updateStatusCallback);
 * return (
 *  <div>
 *   <button onClick={() => trigger("newStatus")} disabled={pending}>Update Status</button>
 *   {pending && <span>Updating...</span>}
 *   {result && <span>Updated at {result.updated_at}</span>}
 *   {error && <span>Error: {error.message}</span>}
 * </div>
 */
export function usePromisedCallback<T extends (...args: any[]) => Promise<any>>(callback: T): PromisedCallbackHooks<T> {
  const [pending, setPending] = useState(false);
  const [result, setResult] = useState<ReturnType<T> extends Promise<infer R> ? R : never>();
  const [error, setError] = useState<Error>();
  const trigger = useCallback(
    (...args) => {
      setPending(true);
      callback(...args)
        .then(result => {
          setResult(result);
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setPending(false);
        });
    },
    [callback]
  );

  return {
    trigger,
    pending,
    result,
    error
  };
}
