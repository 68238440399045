import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { withAbortController } from "../api/axios/axiosErrorHandler";
import { createGroup, deleteGroup, getGroups, updateGroup } from "../handlers/groupHandler";
import { GroupDTO, SingleNewGroup } from "app/api/groupApi";
import { NotLoggedInError } from "../handlers/authentication/authenticationError";

export interface GroupContextValue {
  readonly groupsLoaded: boolean;
  readonly groups: GroupDTO[];
  createGroupHook: (input: SingleNewGroup) => Promise<string>;
  updateGroupHook: (id: string, input: SingleNewGroup) => Promise<void>;
  deleteGroupHook: (id: string) => Promise<void>;
  getGroupHook: (id: string) => Promise<GroupDTO | null>;
  getGroupNameHook: (id: string) => string;
  readonly reloadGroups: () => void;
}

export const GroupContext = createContext<GroupContextValue>({
  groups: [],
  groupsLoaded: false,
  getGroupNameHook: id => "",
  createGroupHook: async () => {
    throw new Error("Not implemented");
  },
  updateGroupHook: async () => {
    throw new Error("Not implemented");
  },
  deleteGroupHook: async () => {
    throw new Error("Not implemented");
  },
  getGroupHook: async () => {
    throw new Error("Not implemented");
  },
  reloadGroups: () => {
    throw new Error("Not implemented");
  }
});

export const GroupProvider = ({ children }: { children: React.ReactNode }) => {
  const [groupsLoaded, setGroupsLoaded] = useState(false);
  const [groups, setGroups] = useState<GroupDTO[]>([]);
  const { auth } = useAuthentication();

  const [abortReloadGroupFn, setAbortReloadGroupFn] = useState<() => void>(() => () => {
    /* empty */
  });
  const reloadGroups = useCallback(() => {
    setAbortReloadGroupFn(lastAbortReloadGroup => {
      lastAbortReloadGroup();
      return withAbortController({
        executeFn: abortController => getGroups({ abortController }).then(setGroups)
      });
    });
    setGroupsLoaded(true);
  }, []);

  const withReloadGroups = useCallback(
    async (changerFn: () => Promise<any>) => {
      setGroupsLoaded(false);
      const result = await changerFn().catch((error: any) => {
        setGroupsLoaded(true);
        throw error;
      });
      reloadGroups();
      setGroupsLoaded(true);
      return result;
    },
    [setGroupsLoaded, reloadGroups]
  );

  const getGroupNameHook = useCallback(
    (id: string) => {
      const groupData = groups.find(group => group.id === id) || null;
      return groupData?.name ?? "";
    },
    [groups]
  );

  useEffect(() => {
    if (!auth?.tenantId) {
      return;
    }
    reloadGroups();
  }, [reloadGroups, auth?.tenantId]);

  const getGroupHook = useCallback(
    async (id: string) => {
      return groups.find(group => group.id === id) || null;
    },
    [groups]
  );

  const createGroupHook = useCallback(
    async (input: SingleNewGroup) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadGroups(() => createGroup(auth.tenantId, input));
    },
    [withReloadGroups, auth?.tenantId]
  );

  const updateGroupHook = useCallback(
    (id: string, input: SingleNewGroup) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadGroups(() => updateGroup(auth.tenantId, id, input));
    },
    [withReloadGroups, auth?.tenantId]
  );
  const deleteGroupHook = useCallback(
    (id: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadGroups(() => deleteGroup(auth.tenantId, id));
    },
    [withReloadGroups, auth?.tenantId]
  );

  const [contextValue, setContextValue] = useState<GroupContextValue>({
    groupsLoaded,
    groups,
    createGroupHook,
    updateGroupHook,
    deleteGroupHook,
    getGroupHook,
    getGroupNameHook,
    reloadGroups
  });
  useEffect(() => {
    setContextValue({
      groupsLoaded,
      groups,
      createGroupHook,
      updateGroupHook,
      deleteGroupHook,
      getGroupHook,
      getGroupNameHook,
      reloadGroups
    });
  }, [
    groupsLoaded,
    groups,
    createGroupHook,
    updateGroupHook,
    deleteGroupHook,
    getGroupHook,
    getGroupNameHook,
    reloadGroups
  ]);

  return <GroupContext.Provider value={contextValue}>{children}</GroupContext.Provider>;
};

export const useUserGroups = () => useContext(GroupContext);
