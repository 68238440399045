import React, { useCallback, useMemo } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { useMetaView } from "app/contexts/meta-view-context";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";

const TokensOverview = () => {
  const { t } = useTranslation();
  const { setInfo, setMeta } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("tokens_overview:defaultInfoTitle"),
        text: t("tokens_overview:defaultInfoText")
      }
    }),
    [t]
  );

  const showEnterInfo = useCallback(() => {
    setMeta(null);
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo, setMeta]);

  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }], []);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"tokens_overview"} />}>
      <Overview
        collection={COLLECTIONS.TOKEN}
        onAddClose={showEnterInfo}
        onRowLeave={showEnterInfo}
        header={t("tokens_overview:title")}
        addActions={addActions}
        checkable={true}
      />
    </DocMetaView>
  );
};

export default TokensOverview;
