import { oauth2LoginApi } from "../../api/user/userOAuth2Api";
import { defaultScope } from "./authenticationHandlerInterfaces";
import { assertAccessToken, assertAndSaveToken } from "./authenticationTokenHandler";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import { storeAccessTokenInSession } from "./authenticationStorage";
import {
  LoggedOutInactiveError,
  MFAEnforced,
  MFALoginRequired,
  TooManyWrongPasswordAttempts,
  WrongCredentials
} from "./authenticationError";

export const signInWithPassword = async ({ emailOrUserId, password }: { emailOrUserId: string; password: string }) => {
  let response;
  try {
    response = await oauth2LoginApi({
      // eslint-disable-next-line camelcase
      grant_type: "password",
      scope: defaultScope,
      username: emailOrUserId,
      password: password
    });
  } catch (error) {
    if (isAxiosErrorWithCode(error, 400, "invalid_request", "password_mfa")) {
      throw new MFALoginRequired();
    }
    if (isAxiosErrorWithCode(error, 400, "invalid_request", "mfa_enforced")) {
      throw new MFAEnforced();
    }
    if (isAxiosErrorWithCode(error, 429)) {
      throw new TooManyWrongPasswordAttempts();
    }
    throw error;
  }

  if (!response) {
    throw new WrongCredentials();
  }
  return await assertAndSaveToken(response);
};

export const signInWithMFA = async ({
  emailOrUserId,
  password,
  mfaToken
}: {
  emailOrUserId: string;
  password: string;
  mfaToken: string;
}) => {
  let response;
  try {
    response = await oauth2LoginApi({
      // eslint-disable-next-line camelcase
      grant_type: "password_mfa",
      scope: defaultScope,
      username: emailOrUserId,
      password: password,
      // eslint-disable-next-line camelcase
      mfa_token: mfaToken
    });
  } catch (error) {
    if (isAxiosErrorWithCode(error, 429)) {
      throw new TooManyWrongPasswordAttempts();
    }
    throw error;
  }
  if (!response) {
    throw new WrongCredentials();
  }
  return await assertAndSaveToken(response);
};

export const signInWithSSOToken = async ({ ssoToken }: { ssoToken: string }) => {
  const response = await oauth2LoginApi({
    // eslint-disable-next-line camelcase
    grant_type: "external_oidc",
    scope: defaultScope,
    // eslint-disable-next-line camelcase
    sso_token: ssoToken
  });
  if (!response) {
    throw new WrongCredentials();
  }
  return await assertAndSaveToken(response);
};

export const signInWithRefreshToken = async ({ refreshToken }: { refreshToken: string }) => {
  const response = await oauth2LoginApi({
    // eslint-disable-next-line camelcase
    grant_type: "refresh_token",
    scope: defaultScope,
    // eslint-disable-next-line camelcase
    refresh_token: refreshToken
  });
  // failed to issue a new access token, refresh token might be expired
  if (!response) {
    throw new LoggedOutInactiveError();
  }
  const token = assertAccessToken(response);
  await storeAccessTokenInSession(token);
  return token;
};
