import React, { useCallback, useMemo } from "react";
import { QuestionProps } from "../../Question";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";

const PAsQuestions = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onBlur,
  options,
  onFocus
}: QuestionProps) => {
  const { t } = useTranslation();

  const optionIds = useMemo(() => (options || []).map(option => option.id), [options]);
  const getOptionLabel = useCallback(
    (id: string) => (options || []).find(option => option.id === id)?.name || "",
    [options]
  );
  const _value = useMemo(() => (Array.isArray(value) ? value : [value as string].filter(notNull => notNull)), [value]);

  return (
    <MultiAutocomplete
      label={questionName || t("collection:processes_cap")}
      options={optionIds}
      selected={_value}
      updateSelected={onChange}
      getOptionLabel={getOptionLabel}
      hasMultiSelect
      disableClearable={false}
      onBlur={onBlur}
      disabled={disabled}
      onFocus={onFocus}
      error={error}
      helperText={helperText}
    />
  );
};

export default PAsQuestions;
