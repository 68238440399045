import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import { STATUSES as TASK_STATUS_OPTIONS, TYPES } from "../../../../handlers/tasksHandler";
import ChipsStack from "components/ChipsStack/ChipsStack";
import TextEditor from "../../../questionnaires/utils/TextEditor";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import AssignUsersMultiAutocomplete from "../../../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import GoTo from "../../../shared/GoTo/GoTo";
import { LabelField } from "../../../../../components/LabelField";
import { COLLECTIONS } from "app/collections";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { isEqual } from "lodash-es";
import { useResources } from "../../../../contexts/resource-context";
import { RESOURCE_TYPES } from "../../../../handlers/resourceHandler";

const useStyles = makeStyles(theme => ({
  button: {
    padding: "0px 0px 0px 0px",
    maxWidth: "140px",
    width: "10vw",
    maxHeight: "25px",
    height: "10vh",
    color: theme.palette.error.main
  },
  buttonText: {
    fontSize: "11px",
    color: theme.palette.error.main
  },
  buttonDelete: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light
    }
  },
  statusMenu: {
    marginTop: "56px"
  },
  currentStatus: {
    background: theme.palette.grey[100],
    cursor: "default"
  },
  header: {
    background: theme.palette.blue[50],
    borderBottom: `"2px solid ${theme.palette.primary.main}"`,
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-root": {
      color: theme.palette.primary.main
    }
  },
  taskDetailInfo: {
    display: "flex",
    flexDirection: "column"
  }
}));

const RecurringTaskHeader = () => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  return (
    <Box className={cls.header} mb={2} mt={-3} mr={-3} ml={-3} p={2} display="flex" alignItems={"center"}>
      <Typography variant="h3">{t("repetition")}</Typography>
    </Box>
  );
};
const GroupTaskHeader = () => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  return (
    <Box className={cls.header} mb={2} mt={-3} mr={-3} ml={-3} p={2} display="flex" alignItems={"center"}>
      <Typography variant="h3">{t("group")}</Typography>
    </Box>
  );
};

const TaskTitle = ({ title, onChangeTitle, onCloseDetails, disabled = false }) => {
  const cls = useStyles();
  const { t } = useTranslation();
  const tooltip = t("common:close");
  const [editMode, setEditMode] = useState(false);
  const [titleValue, setTitleValue] = useState("");

  useEffect(() => {
    setTitleValue(title);
  }, [title]);

  const onBlurCallback = useCallback(() => {
    setEditMode(false);
    onChangeTitle(titleValue);
  }, [onChangeTitle, titleValue]);
  const onFocusCallback = useCallback(() => {
    setEditMode(true);
  }, []);
  const onButtonClick = useCallback(() => {
    onCloseDetails();
  }, [onCloseDetails]);
  const onKeyPress = useCallback(event => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  }, []);
  const editTitleEl = (
    <Box flex={1}>
      <Tooltip title={titleValue}>
        <TextField
          data-testid="edit-text-field"
          fullWidth
          type="string"
          className={cls.titleEditor}
          disabled={disabled}
          value={titleValue}
          onKeyPress={onKeyPress}
          onFocus={onFocusCallback}
          onBlur={onBlurCallback}
          onChange={event => setTitleValue(event.target.value)}
          InputProps={{
            disableUnderline: !editMode
          }}
        />
      </Tooltip>
    </Box>
  );
  const closeEl = !editMode && (
    <Tooltip title={tooltip}>
      <Box>
        <IconButton onClick={onButtonClick}>{<Close />}</IconButton>
      </Box>
    </Tooltip>
  );
  return (
    <Box display="flex" alignItems={"center"}>
      {editTitleEl}
      {closeEl}
    </Box>
  );
};

const TaskActionButtons = ({
  recurringTask = false,
  item,
  onChangeStatus,
  disabledGoTo = false,
  groupTask = false
}) => {
  const { t } = useTranslation("task_details");
  const cls = useStyles();
  const [statusMenu, setStatusMenu] = useState();
  const closeStatusMenu = useCallback(() => setStatusMenu(null), []);
  const RECURRING_TASK_STATUS_OPTIONS = useMemo(() => ["active", "inactive"], []);
  const openStatusMenu = useCallback(event => {
    setStatusMenu(event?.currentTarget);
  }, []);
  const changeStatus = useCallback(
    status => {
      closeStatusMenu();
      onChangeStatus(status);
    },
    [onChangeStatus, closeStatusMenu]
  );

  const statusText = useMemo(() => {
    return recurringTask
      ? item.active
        ? t("status_active")
        : t("status_inactive")
      : item.status
        ? t("status_" + item.status)
        : t("status");
  }, [t, item, recurringTask]);

  const actionEl = useMemo(
    () => (
      <Box mt={2} mb={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              endIcon={<ExpandMore />}
              aria-controls="simple-menu"
              aria-haspopup="true"
              data-testid="task-status-button"
              onClick={openStatusMenu}
            >
              {statusText}
            </Button>
            <Menu
              className={cls.statusMenu}
              id="simple-menu"
              anchorEl={statusMenu}
              open={Boolean(statusMenu)}
              onClose={closeStatusMenu}
            >
              {Object.values(recurringTask ? RECURRING_TASK_STATUS_OPTIONS : TASK_STATUS_OPTIONS)
                .filter(option => recurringTask || option !== TASK_STATUS_OPTIONS.deleted)
                .map((status, index) => (
                  <MenuItem
                    key={index}
                    className={status === item.status ? cls.currentStatus : ""}
                    onClick={() => changeStatus(status)}
                  >
                    {t("status_" + status)}
                  </MenuItem>
                ))}
            </Menu>
          </Grid>
          {!recurringTask && !disabledGoTo && item.type !== TYPES.individual && (
            <Grid item>
              <GoTo
                collection={item.collection}
                documentId={item.documentId}
                pageId={item.pageId}
                questionId={item.questionId}
                goToType={"button"}
                showIconText={false}
                tooltipText={t("go_to_task")}
                taskId={item.id}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    ),
    [
      openStatusMenu,
      statusText,
      cls.statusMenu,
      cls.currentStatus,
      statusMenu,
      closeStatusMenu,
      recurringTask,
      RECURRING_TASK_STATUS_OPTIONS,
      disabledGoTo,
      item.type,
      item.collection,
      item.documentId,
      item.pageId,
      item.questionId,
      item.id,
      item.status,
      t,
      changeStatus
    ]
  );
  return (
    <>
      {actionEl}
      <Divider />
    </>
  );
};

const TaskDropDownAssignType = ({ assignToGroup, saveOperationInProgress, onChangeAssignType }) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const [assignMenu, setAssignMenu] = useState();
  const [showModal, setShowModal] = useState(false);
  const [assignTo, setAssignTo] = useState("");
  const closeAssignMenu = useCallback(() => setAssignMenu(null), []);
  const TASK_ASSIGNED_OPTIONS = useMemo(() => ["user", "group"], []);
  const openAssignMenu = useCallback(event => {
    setAssignMenu(event?.currentTarget);
  }, []);

  const changeAssignTo = useCallback(() => {
    if (assignTo) {
      onChangeAssignType(assignTo);
    }
  }, [onChangeAssignType, assignTo]);

  const dropdownAssignedTo = (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        endIcon={<ExpandMore />}
        aria-controls="simple-menu"
        aria-haspopup="true"
        data-testid="task-assign-button"
        disabled={saveOperationInProgress}
        onClick={openAssignMenu}
      >
        {assignToGroup ? t("metaview_groupdialog:group") : t("metaview_groupdialog:user")}
      </Button>
      <Menu
        className={cls.statusMenu}
        id="simple-menu"
        anchorEl={assignMenu}
        open={Boolean(assignMenu)}
        onClose={closeAssignMenu}
      >
        {Object.values(TASK_ASSIGNED_OPTIONS).map((assign, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setAssignTo(assign);
              setShowModal(true);
            }}
          >
            {t("metaview_groupdialog:" + assign)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  const assignTaskModalButtons = [
    {
      confirmButton: false,
      title: t("cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: () => {
        setShowModal(false); // close the modal
      }
    },
    {
      confirmButton: true,
      disabled: saveOperationInProgress,
      title: t("common:save"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: async () => {
        changeAssignTo(), setShowModal(false), closeAssignMenu();
      }
    }
  ];

  return (
    <>
      <ConfirmationModal
        modalOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        modalTitle={t("common:information")}
        modalText={t("metaview_groupdialog:confirm_delete")}
        buttons={assignTaskModalButtons}
        variant="info"
      />
      <Grid item style={{ paddingTop: "2%" }}>
        <Typography variant="h5" component="span">
          {t("assigned_to")}
        </Typography>
      </Grid>
      <Grid item style={{ paddingTop: "1.2%", paddingBottom: "3%" }}>
        {dropdownAssignedTo}
      </Grid>
      <Divider />
    </>
  );
};
const TaskAssignTo = ({ task, onChangeAssignment, saveOperationInProgress, disabled = false }) => {
  const { t } = useTranslation("task_details");
  const { getUserNameHook } = useUserAndTenantData();
  const [assignTaskActive, setAssignTaskActive] = useState(false);
  const [userSelectedToAssignTask, setUserSelectedToAssignTask] = useState(null);

  const selectUserForTaskAssignment = function (userIds) {
    setUserSelectedToAssignTask(userIds[0] || "");
  };
  const getAssigneeAndParticipantName = function (userId) {
    const name = getUserNameHook(userId);
    return name === "" ? t("deleted") : name;
  };

  const assignTaskModalBody = (
    <AssignUsersMultiAutocomplete
      id={"assignee"}
      docAssignedUserIds={[userSelectedToAssignTask ?? task.assigneeUID]}
      onDocAssignedUserIdsChanged={selectUserForTaskAssignment}
      hasMultiSelect={false}
      variant="outlined"
      freeSolo={true}
      disableClearable={true}
      getOptionLabel={option => option.namePlusEmail ?? ""}
      permissionDeniedMessage={t("pa_general:noPermission")}
      label={t("assigned_to")}
      excludedUserIds={[]}
      collection={"tasks"}
      required={true}
      hasDefaultValue={false}
      disabled={disabled}
    />
  );
  const assignTaskModalButtons = [
    {
      confirmButton: false,
      title: t("cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: () => {
        setAssignTaskActive(false); // close the modal
        setUserSelectedToAssignTask(null);
      }
    },
    {
      confirmButton: true,
      disabled: saveOperationInProgress || !userSelectedToAssignTask || userSelectedToAssignTask === task.assigneeUID,
      title: t("assign"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: async () => {
        await onChangeAssignment(userSelectedToAssignTask);
        setAssignTaskActive(false);
      }
    }
  ];

  return (
    <>
      <ConfirmationModal
        modalOpen={assignTaskActive}
        onClose={() => {
          setAssignTaskActive(false);
          setUserSelectedToAssignTask(null);
        }}
        modalTitle={t("assign_task")}
        modalBody={assignTaskModalBody}
        buttons={assignTaskModalButtons}
        variant="info"
      />
      <ChipsStack
        chips={[
          task.assigneeUID
            ? {
                label: getAssigneeAndParticipantName(task.assigneeUID),
                color: "primary",
                variant: "outlined",
                size: "medium",
                leftIcon: "AccountCircleIcon",
                selected: true,
                onClick: () => setAssignTaskActive(true)
              }
            : {
                label: t("nobody"),
                variant: "outlined",
                size: "medium",
                leftIcon: "AccountCircleIcon",
                onClick: () => setAssignTaskActive(true)
              }
        ]}
        header={t("assigned_to")}
      />
      <Divider />
    </>
  );
};
const TaskParticipants = ({ task, onChangeParticipants, saveOperationInProgress }) => {
  const { t } = useTranslation("task_details");
  const { getUserNameHook } = useUserAndTenantData();
  const [addParticipantsActive, setAddParticipantsActive] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const resetTaskParticipants = function () {
    const taskParticipants = task.participants || [];
    setSelectedParticipants(taskParticipants.filter(participantId => participantId !== task.assigneeUID));
  };
  const getAssigneeAndParticipantName = function (userId) {
    const name = getUserNameHook(userId);
    return name === "" ? t("deleted") : name;
  };

  const participantsAreUnchanged = isEqual(
    new Set(selectedParticipants || []),
    new Set(task.participants ? task.participants.filter(participantId => participantId !== task.assigneeUID) : [])
  );

  const addParticipantsModalBody = (
    <AssignUsersMultiAutocomplete
      id={"assignee"}
      docAssignedUserIds={selectedParticipants}
      onDocAssignedUserIdsChanged={setSelectedParticipants}
      freeSolo={true}
      disableClearable={true}
      label={t("add_participant")}
      hasMultiSelect={true}
      excludedUserIds={[task.assigneeUID]}
      hasDefaultValue={false}
    />
  );

  const addParticipantsModalButtons = [
    {
      confirmButton: false,
      title: t("cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: () => {
        resetTaskParticipants();
        setAddParticipantsActive(false);
      }
    },
    {
      confirmButton: true,
      disabled: participantsAreUnchanged || saveOperationInProgress,
      title: t("save"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: async () => {
        await onChangeParticipants(selectedParticipants);
        setAddParticipantsActive(false);
      }
    }
  ];

  return (
    <>
      <ConfirmationModal
        modalOpen={addParticipantsActive}
        onClose={() => {
          resetTaskParticipants();
          setAddParticipantsActive(false);
        }}
        modalTitle={t("add_participant_modal_title")}
        modalText={t("add_participant_modal_text")}
        modalBody={addParticipantsModalBody}
        buttons={addParticipantsModalButtons}
        variant="info"
      />
      <ChipsStack
        chips={[
          ...(task.participants && task.participants.length > 0
            ? task.participants.map(participant => ({
                label: getAssigneeAndParticipantName(participant),
                color: "primary",
                variant: "outlined",
                size: "medium",
                leftIcon: "AccountCircleIcon",
                rightIcon: "CancelIcon",
                rightIconToolTip: t("remove_participant"),
                onRightIconClick: async () => {
                  const temp = selectedParticipants.filter(user => user !== participant);
                  setSelectedParticipants(temp);
                  onChangeParticipants(temp);
                },
                selected: true
              }))
            : []),
          {
            label: t("add_participant"),
            variant: "outlined",
            size: "medium",
            leftIcon: "AddCircleOutlineIcon",
            onClick: () => setAddParticipantsActive(true)
          }
        ]}
        header={t("participants")}
      />
      <Divider />
    </>
  );
};

const TaskDescription = ({ description, onChageDescription, disabled = false }) => {
  const { t } = useTranslation("task_details");
  const [value, setValue] = useState(description || "");
  return (
    <Box mt={2}>
      <TextEditor
        disabled={disabled}
        titleComponent={
          <Typography variant="h5" component="span">
            {t("description")}
          </Typography>
        }
        onBlur={() => onChageDescription(value)}
        onChange={setValue}
        inputValue={value}
      />
      <Divider style={{ marginTop: 16 }} />
    </Box>
  );
};
const AttachmentComponentForTasksFeature = ({ docId, onDeleteAllFiles, onChangeFiles, category = "tasks" }) => {
  const { t } = useTranslation("task_details");
  return (
    <Box mt={3}>
      <Typography variant="h5" component="span">
        {t("headline_attachments")}
      </Typography>
      <AttachmentsOverviewOBS
        docId={docId}
        category={category}
        deleteAllFiles={onDeleteAllFiles}
        onSuccessfulFileDelete={onChangeFiles}
        onSuccessfulFileUpload={onChangeFiles}
      />
    </Box>
  );
};
const TaskLabels = ({ task, saveOperationInProgress, onChangeLabels }) => {
  const { t } = useTranslation("task_details");
  const [addLabelsActive, setAddLabelsActive] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const { translateById } = useResources();

  const addLabelsModalBody = (
    <LabelField selectedIDs={selectedLabels} onSelectionChanged={value => setSelectedLabels(value)} />
  );
  const labelsAreUnchanged = isEqual(new Set(selectedLabels || []), new Set(task.labels));
  const addLabelsModalButtons = [
    {
      confirmButton: false,
      title: t("cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: () => {
        resetTaskLabels();
        setAddLabelsActive(false); // close the modal
      }
    },
    {
      confirmButton: true,
      disabled: labelsAreUnchanged || saveOperationInProgress,
      title: t("save"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: async () => {
        await onChangeLabels(selectedLabels);
        setAddLabelsActive(false);
      }
    }
  ];

  const getLabelName = useCallback(
    labelId => {
      return translateById(RESOURCE_TYPES.LABEL, labelId);
    },
    [translateById]
  );
  const resetTaskLabels = function () {
    const taskLabels = task.labels || [];
    setSelectedLabels(taskLabels);
  };
  return (
    <Box mt={3}>
      <ConfirmationModal
        modalOpen={addLabelsActive}
        onClose={() => {
          resetTaskLabels();
          setAddLabelsActive(false);
        }}
        modalTitle={t("add_labels_modal_title")}
        modalText={t("add_labels_modal_text")}
        modalBody={addLabelsModalBody}
        buttons={addLabelsModalButtons}
        variant="info"
      />
      <ChipsStack
        chips={[
          ...(task.labels && task.labels.length > 0
            ? task.labels.map(labelId => ({
                label: getLabelName(labelId),
                color: "primary",
                variant: "outlined",
                size: "medium",
                leftIcon: "StyleIcon",
                rightIcon: "CancelIcon",
                rightIconToolTip: t("remove_participant"),
                onRightIconClick: async () => {
                  const temp = selectedLabels.filter(selectedLabel => selectedLabel !== labelId);
                  setSelectedLabels(temp);
                  onChangeLabels(temp);
                },
                selected: true
              }))
            : []),
          {
            label: t("add_label"),
            variant: "outlined",
            size: "medium",
            leftIcon: "AddCircleOutlineIcon",
            onClick: () => setAddLabelsActive(true)
          }
        ]}
        header={t("labels")}
      />
    </Box>
  );
};

export {
  AttachmentComponentForTasksFeature,
  GroupTaskHeader,
  RecurringTaskHeader,
  TaskActionButtons,
  TaskAssignTo,
  TaskDescription,
  TaskDropDownAssignType,
  TaskLabels,
  TaskParticipants,
  TaskTitle
};
