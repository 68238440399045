import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { apiEndpoints } from "./apiEndpoint";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.mlUrl}`
});

export interface SuggestLegalBasisRequest {
  readonly message: string;
}

export interface SuggestLegalBasisResponse {
  readonly categoryKey: string | null;
  readonly input: string | null;
}

export async function postSuggestLegalBasis(input: { readonly message: string }): Promise<SuggestLegalBasisResponse> {
  const response = await axiosInstance.post<Partial<SuggestLegalBasisResponse>>("/suggestions", input);
  return {
    categoryKey: response.data?.categoryKey || null,
    input: response.data?.input || null
  };
}
