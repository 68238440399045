import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";

// this components queues your update to prevent race condition on updates
// it will make sure that only 1 update is run at all times
// if there are multiple updates, they will be merged into 1 update as well
export const useUpdateQueues = <UpdatePayload, PartialUpdateType>({
  triggerUpdate,
  mergeUpdates,
  resetSignal
}: {
  readonly triggerUpdate: (payload: Partial<UpdatePayload>) => Promise<void>;
  readonly mergeUpdates?: (acc: Partial<UpdatePayload>, newValue: PartialUpdateType) => Partial<UpdatePayload>;
  readonly resetSignal?: number;
}) => {
  const [updateQueues, setUpdateQueues] = useState<(PartialUpdateType & { readonly updateId: string })[]>([]);
  const queueUpdates = useCallback(async (value: PartialUpdateType) => {
    setUpdateQueues(prevValue => [...prevValue, { ...value, updateId: v4() }]);
  }, []);

  const mergeUpdatesCallback = useCallback(
    (acc: Partial<UpdatePayload>, newValue: PartialUpdateType) => {
      if (mergeUpdates) {
        return mergeUpdates(acc, newValue);
      }

      return {
        ...acc,
        ...newValue
      };
    },
    [mergeUpdates]
  );

  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    if (isUpdating) {
      return;
    }

    if (updateQueues.length === 0) {
      return;
    }

    const action = async () => {
      setIsUpdating(true);
      const updateObject: Partial<UpdatePayload> = updateQueues.reduce<Partial<UpdatePayload>>(
        mergeUpdatesCallback,
        {}
      );
      const lastUpdateId = updateQueues[updateQueues.length - 1]?.updateId;

      try {
        const updatePayloadWithoutUpdateId = { ...updateObject };
        delete (updatePayloadWithoutUpdateId as any)["updateId"];
        await triggerUpdate(updatePayloadWithoutUpdateId);
      } catch (e) {
        setIsUpdating(false);
        throw e;
      }

      setUpdateQueues(prevValue => {
        const lastUpdatedIndex = prevValue.findIndex(x => x.updateId === lastUpdateId);
        if (lastUpdatedIndex === -1) {
          return prevValue;
        }

        return prevValue.slice(lastUpdatedIndex + 1);
      });
      setIsUpdating(false);
    };

    action();
  }, [isUpdating, updateQueues, triggerUpdate, mergeUpdatesCallback]);

  useEffect(() => {
    if (resetSignal) {
      setUpdateQueues([]);
      setIsUpdating(false);
    }
  }, [resetSignal]);

  return {
    queueUpdates
  };
};
