import React, { memo, useCallback } from "react";
import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SidebarCaralegalLogo from "assets/images/caralegal_logo/square/caralegal_symbol_neutral.svg?img";
import SidebarCarlegalLogoWithText from "assets/images/caralegal_logo/with_text/caralegal_logotype_neutral.svg?img";

// memoizing to prevent unnecessary rerenders
const LogoSidebar = memo(({ open }: { readonly open: boolean }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const onClickCallback = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        height: "60px",
        position: "relative"
      }}
    >
      <Box
        sx={{
          "& svg": {
            display: "block",
            flexShrink: 0,
            height: "60px",
            left: 0,
            marginLeft: 0,
            opacity: open ? 1 : 0,
            overflow: "hidden",
            padding: theme.spacing(2),
            position: "absolute",
            top: 0,
            transition: "opacity 0.2s",
            userSelect: "none",
            width: "auto",
            zIndex: 2
          }
        }}
      >
        <SidebarCarlegalLogoWithText onClick={onClickCallback} />
      </Box>
      <Box
        sx={{
          "& svg": {
            height: "60px",
            marginLeft: "5px",
            opacity: open ? 0 : 1,
            padding: "8px 12px",
            transition: "opacity 0.2s",
            width: "60px",
            zIndex: 1
          }
        }}
      >
        <SidebarCaralegalLogo onClick={onClickCallback} />
      </Box>
    </Box>
  );
});

LogoSidebar.displayName = "LogoSidebar";

export default LogoSidebar;
