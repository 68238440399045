import { Typography, Box, Icon, makeStyles } from "@material-ui/core";
import { TaskInfoLabel } from "./TaskInfoLabel";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddIcon from "@mui/icons-material/Add";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";

const useStyles = makeStyles(theme => ({
  attachmentsContainer: {
    marginBottom: 0
  },
  attachmentsList: {
    marginTop: 0,
    marginLeft: -theme.spacing(2),
    fontSize: "14px",
    "& ul div.MuiListItem-button": {
      height: "auto"
    },
    "& .MuiGrid-item .MuiListItemIcon-root": {
      minWidth: "30px"
    }
  },
  addAttachment: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    marginTop: 0,
    marginLeft: theme.spacing(2),
    "& svg": {
      color: theme.palette.primary.main,
      width: "20px"
    },
    "& p": {
      fontWeight: 600,
      letterSpacing: "1px",
      color: theme.palette.primary.main
    }
  }
}));

interface CustomAddAttachmentButtonProps {
  readonly onClick?: () => void;
}
const CustomAddAttachmentButton = ({ onClick }: CustomAddAttachmentButtonProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  return (
    <Box onClick={onClick} ml={1} mt={2} p={1} className={cls.addAttachment}>
      <Icon component={AddIcon} />
      <Box ml={1}>
        <Typography variant="body1">{t("task_details:add_attachment").toLocaleUpperCase()}</Typography>
      </Box>
    </Box>
  );
};

interface TaskAttachmentsProps {
  docId: string;
  groupId?: string;
  assigneeUID?: string;
  onChangeFiles: (files: File[]) => void;
}
export const TaskAttachments = ({ docId, groupId, assigneeUID, onChangeFiles }: TaskAttachmentsProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const customUploadButton = <CustomAddAttachmentButton />;
  const documentId = groupId ? (assigneeUID ? `${docId}${groupId}${assigneeUID}` : `${docId}${groupId}`) : docId;
  return (
    <Box mt={2} mb={2} className={cls.attachmentsContainer}>
      <TaskInfoLabel icon={AttachFileIcon} text={t("task_details:attachments")} />
      <Box mt={1} className={cls.attachmentsList}>
        <AttachmentsOverviewOBS
          docId={documentId}
          category={"tasks"}
          deleteAllFiles={undefined}
          onSuccessfulFileDelete={onChangeFiles}
          onSuccessfulFileUpload={onChangeFiles}
          customUploadButton={customUploadButton}
          showUploadButtonBelow={true}
          showDividers={false}
          hidePagination={true}
        />
      </Box>
    </Box>
  );
};
