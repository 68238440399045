import * as Sentry from "@sentry/react";
import {
  clearAccessTokenInSession,
  clearPersistentRefreshToken,
  storeLoginPreferenceInSession
} from "app/handlers/authentication/authenticationStorage";
import { deleteMyMFAApi, getMeApi, updateMyMFAApi } from "app/api/user/userMeApi";
import { UnauthorizedTenantAccess } from "app/handlers/authentication/authentication-context";
import { getOAuth2SSOAuthUrlApi, SSOConfigDTO } from "app/api/user/userSSOApi";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import { assertGetUserAccessToken } from "./authenticationTokenHandler";
import { LoggedInUser } from "./authenticationHandlerInterfaces";
import { MeTenantDTO, UpdateMFAPayload } from "../../api/user/userMeApiInterfaces";
import { getAuthenticator } from "./mfaAuthenticator";
import { MFACheckSMS, MFATokenError, NotLoggedInError } from "./authenticationError";
import { SmartLookTracker } from "../../../tool/tracker";

export const getLoggedInUser = async (): Promise<LoggedInUser | null> => {
  try {
    await assertGetUserAccessToken();
  } catch (error) {
    if (error instanceof NotLoggedInError) {
      return null;
    }
    throw error;
  }
  const user = await getMeApi();
  if (!user.id || !user.firstName || !user.lastName || !user.email) {
    return null;
  }
  const toTenantDTOs = (tenants?: MeTenantDTO[]): Required<MeTenantDTO>[] => {
    return (tenants || []).flatMap(tenant => {
      if (!tenant.tenantId || !tenant.userRole || !tenant.orgUnitId) {
        return [];
      }

      return [
        {
          tenantId: tenant.tenantId,
          tenantName: tenant.tenantName || tenant.tenantId,
          userRole: tenant.userRole,
          userRoleName: tenant.userRoleName || tenant.userRole,
          orgUnitId: tenant.orgUnitId,
          featureIds: tenant.featureIds || [],
          furtherOrgUnitIds: tenant.furtherOrgUnitIds || [],
          permissions: tenant.permissions || []
        }
      ];
    });
  };
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber || null,
    mfaActivated: !!user.mfaActivated,
    tenants: toTenantDTOs(user.tenants),
    blockedTenants: toTenantDTOs(user.blockedTenants)
  };
};

export const changeTenant = async (
  user: Pick<LoggedInUser, "tenants" | "id">,
  tenantId: string
): Promise<MeTenantDTO> => {
  if (!user?.tenants) {
    throw new UnauthorizedTenantAccess();
  }
  const tenantInfo = user.tenants.find(tenant => tenant.tenantId === tenantId);
  if (!tenantInfo) {
    throw new UnauthorizedTenantAccess();
  }

  await storeLoginPreferenceInSession(pref => ({
    ...pref,
    tenantId: tenantInfo.tenantId
  }));

  // inform sentry of the claims
  Sentry.setUser({
    id: user.id,
    tenantInfo
  });
  // disable smartlook proactively on tenant change since next tenant might have it off
  await SmartLookTracker.disableSmartLook();

  return tenantInfo;
};

export const signOut = async () => {
  await Promise.all([clearPersistentRefreshToken(), clearAccessTokenInSession()]);
};

export const generateMFASecret = (): Promise<string> => {
  return getAuthenticator().generateSecret(32);
};

export const generateMFASecretURI = ({ mfaSecret, email }: { mfaSecret: string; email: string }): Promise<string> => {
  return getAuthenticator().keyuri(email, "caralegal", mfaSecret);
};

export const setupMFA = async (payload: UpdateMFAPayload): Promise<void> => {
  try {
    await updateMyMFAApi(payload);
  } catch (error: any) {
    const invalidToken = isAxiosErrorWithCode(error, 400, "BadRequest", "Invalid token");
    if (invalidToken && payload.phoneNumber && !payload.token) {
      throw new MFACheckSMS();
    }
    if (invalidToken && payload.token) {
      throw new MFATokenError();
    }
    throw error;
  }
};

export const removeMFA = (): Promise<void> => {
  return deleteMyMFAApi();
};

export const getSSOAuthUrl = async (ssoConfig: Pick<SSOConfigDTO, "callbackIdentifier">): Promise<string> => {
  const ssoID = ssoConfig.callbackIdentifier;
  if (!ssoID) {
    throw new Error("Can't get auth url from unknown SSO");
  }

  const authUrl = await getOAuth2SSOAuthUrlApi({
    callbackIdentifier: ssoID
  });
  if (!authUrl) {
    throw new Error(`${ssoID} is missing auth url`);
  }

  await storeLoginPreferenceInSession(pref => ({
    ...pref,
    ssoIds: [...new Set([ssoID, ...pref.ssoIds])]
  }));
  return authUrl;
};
