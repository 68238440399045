import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { AccountSettingsMFABanner } from "app/pages/account-settings/mfa/AccountSettingsMFABanner";
import { useNavigate } from "react-router-dom";
import { useMetaView } from "app/contexts/meta-view-context";
import useSWR from "swr";
import { getEnforcementInfoApi } from "../../api/user/userMeApi";
import { Box, Typography } from "@material-ui/core";

export default function AccountSettingsSecurity() {
  const navigate = useNavigate();

  const { t } = useTranslation("accountSettingsSecurity");

  const changePasswordCallback = useCallback(() => {
    navigate("/account-settings/password");
  }, [navigate]);

  const manageMFACallback = useCallback(() => {
    navigate("/account-settings/mfa");
  }, [navigate]);

  const { setInfo } = useMetaView();

  useEffect(() => {
    setInfo({
      title: t("accountSettingsSecurity:infoCardEnterTitle"),
      text: t("accountSettingsSecurity:infoCardEnterBody")
    });
  }, [setInfo, t]);

  const { data: enforcementInfo } = useSWR("/me/enforcements", () => getEnforcementInfoApi());

  const freeToSetupMFA = !enforcementInfo?.mfaEnforced;
  const inGracePeriodToSetupMFA = enforcementInfo?.mfaEnforced && enforcementInfo.mfaGracePeriodEndsAt;

  return (
    <Box>
      <Typography variant="h2">{t("title")}</Typography>
      <Box mt={3} />
      <Button tabIndex={3} variant="contained" type="submit" color="primary" onClick={changePasswordCallback}>
        {t("change_password")}
      </Button>
      <Box mt={3}>
        <Typography variant="h2">{t("multiFactorAuthentication")}</Typography>
        <Box mt={3} />
        <Typography>{t("protectYourAccount")}</Typography>
        <Box mt={3} />
        <AccountSettingsMFABanner />
        <Box mt={3} />
        {(freeToSetupMFA || inGracePeriodToSetupMFA) && (
          <Link href="#" style={{ textDecoration: "none" }} onClick={manageMFACallback}>
            {t("manageMultiFactor")}
          </Link>
        )}
        {!freeToSetupMFA && !inGracePeriodToSetupMFA && <p>{t("mfaEnforced")}</p>}
      </Box>
    </Box>
  );
}
