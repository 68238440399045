import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import { useErrorSnackbar } from "../../hook/errorSnackbar";
import { getAllTasksByDocumentId } from "../handlers/tasksHandler";
import { useParams } from "react-router-dom";
import { TaskDTO } from "app/api/taskApi";

export interface TaskContextValue {
  readonly loading: boolean;
  readonly initialized: boolean;
  readonly reloadDocRelatedTasks: () => Promise<void>;
  readonly docTasks: TaskDTO[];
}

export const TasksContext = createContext<TaskContextValue>({
  loading: true,
  initialized: false,
  reloadDocRelatedTasks: async () => {},
  docTasks: []
});

export const TasksProvider = ({ children, docPrefix }: { children: React.ReactNode; docPrefix?: string }) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [docTasks, setDocTasks] = useState<TaskDTO[]>([]);
  const { auth } = useAuthentication();

  const params = useParams();
  const documentId = params?.id;
  const pageParam = params?.page;
  const documentIdWithPrefix = pageParam === "responses" ? documentId : undefined;
  const { catchAsSnackbar } = useErrorSnackbar();

  const reloadDocRelatedTasks = useCallback(() => {
    let documentIdWithAuditId: string = documentId || "";
    if (docPrefix && docPrefix === "/answerset/") {
      const auditId = params?.auditid;
      documentIdWithAuditId = encodeURIComponent(`${auditId}${docPrefix}${documentId}`);
    }
    const init = async () => {
      if (auth?.uid) {
        const documentTasks = await getAllTasksByDocumentId(documentIdWithAuditId, documentIdWithPrefix);
        setDocTasks(documentTasks);
        setLoading(false);
        setInitialized(true);
      }
    };
    setLoading(true);
    return init();
  }, [auth?.uid, documentId, docPrefix, params?.auditid, documentIdWithPrefix]);

  useEffect(() => {
    if (documentId) {
      reloadDocRelatedTasks().catch(catchAsSnackbar("Failed to load tasks"));
    }
  }, [documentId, catchAsSnackbar, auth, reloadDocRelatedTasks]);

  return (
    <TasksContext.Provider
      value={{
        loading,
        initialized,
        reloadDocRelatedTasks,
        docTasks
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = () => useContext(TasksContext);
