import i18n from "app/i18n";
import { OverviewItem, OverviewNewItem } from "components/Overview/controllers/overviewBaseController";

export const sortings = [
  {
    field: "title",
    type: "asc",
    label: i18n.t("filter_criteria:aToZ")
  },
  {
    field: "title",
    type: "desc",
    label: i18n.t("filter_criteria:zToA")
  },
  {
    field: "updated",
    type: "desc",
    label: i18n.t("filter_criteria:newFirst")
  },
  {
    field: "updated",
    type: "asc",
    label: i18n.t("filter_criteria:oldFirst")
  },
  {
    field: "size",
    type: "desc",
    label: i18n.t("filter_criteria:smallestFirst")
  },
  {
    field: "size",
    type: "asc",
    label: i18n.t("filter_criteria:largestFirst")
  },
  {
    field: "fileType",
    type: "asc",
    label: i18n.t("filter_criteria:foldersFirst")
  },
  {
    field: "fileType",
    type: "desc",
    label: i18n.t("filter_criteria:filesFirst")
  }
];

export const appendParentNodeForMove = (items: OverviewItem[], title: string): OverviewNewItem[] => {
  return [
    {
      path: "moveToRoot",
      title: title,
      fileType: false,
      size: 0,
      updated: new Date(),
      children: items,
      orgUnitIds: ["*"]
    }
  ];
};
