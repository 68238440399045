import { OverviewItem } from "../controllers/overviewBaseController";

const _childrenIncludeTerm = (item: OverviewItem, lowerCaseTerm: string): boolean => {
  return Boolean(
    item.children?.some(
      child => child.title.toLowerCase().includes(lowerCaseTerm) || _childrenIncludeTerm(child, lowerCaseTerm)
    )
  );
};

const searchOverviewItems = (items: OverviewItem[], term: string): OverviewItem[] => {
  const lowerCaseTerm = term.toLowerCase();

  return items.reduce((acc: OverviewItem[], item: OverviewItem) => {
    const itemTitle = item?.title || item?.name;
    const titleIncludesTerm = itemTitle?.toLowerCase().includes(lowerCaseTerm);
    const childrenIncludeTerm = _childrenIncludeTerm(item, lowerCaseTerm);

    if (titleIncludesTerm || childrenIncludeTerm) {
      if (item.children) {
        acc.push({
          ...item,
          expanded: childrenIncludeTerm,
          children: titleIncludesTerm ? item.children : searchOverviewItems(item.children || [], term)
        });
      } else {
        acc.push(item);
      }
    }
    return acc;
  }, []);
};

export { searchOverviewItems };
