import React, { useCallback } from "react";
import { Box, Button, Icon, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RepeatIcon from "@mui/icons-material/Repeat";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

const useStyles = makeStyles(theme => ({
  root: {
    background: "rgba(33, 33, 33, 0.08)",
    height: theme.spacing(6),
    padding: "12px 12px 12px 8px",
    borderRadius: "6px",
    justifyContent: "space-between",
    "& svg": {
      color: "rgba(164, 167, 168, 1)"
    }
  },
  button: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.25px",
    lineHeight: "24px"
  }
}));

interface RecurringTaskInfoBannerProps {
  readonly recurringTaskId: string;
  readonly assigneeUID: string;
  readonly participants: string[];
  readonly onPageChange?: (page: {
    route: string;
    id: string;
    detailType: "DEFAULT" | "RECURRING" | "GROUP";
    withReload: boolean;
  }) => void;
}
const RecurringTaskInfoBanner = ({
  recurringTaskId,
  assigneeUID,
  participants,
  onPageChange
}: RecurringTaskInfoBannerProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { auth } = useAuthentication();

  const navigateToParentTask = useCallback(() => {
    const myTask = assigneeUID === auth?.uid || participants.includes(auth?.uid || "");
    const route = `${myTask ? "my" : "other"}/${recurringTaskId}`;
    onPageChange?.({ route, id: recurringTaskId, detailType: "RECURRING", withReload: false });
  }, [assigneeUID, auth?.uid, onPageChange, participants, recurringTaskId]);

  return (
    <Box display={"flex"} alignItems={"center"} className={cls.root}>
      <Box display={"flex"} alignItems={"center"}>
        <Icon component={RepeatIcon} />
        <Box ml={1}>
          <Typography>{t("recurring_info")}</Typography>
        </Box>
      </Box>
      <Button color="primary" variant="text" onClick={navigateToParentTask} className={cls.button}>
        {t("edit_schedule")}
      </Button>
    </Box>
  );
};

export { RecurringTaskInfoBanner };
