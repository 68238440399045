import { Box, Button, Checkbox, Divider, FormControlLabel, TextField, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { SxProps } from "@mui/system/styleFunctionSx";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { SearchOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { createNaturalSorter } from "../../app/utils/naturalSort";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

export interface MultiPickerProps {
  readonly value: string[];
  readonly onChange: (value: string[]) => void;
  readonly options: string[];
  readonly getOptionName: (option: string) => string;
  readonly getOptionGroup?: (option: string) => string[];
  readonly groupsOrder?: string[];
  readonly getIsOptionDisabled?: (option: string) => boolean;
  readonly allowAdd?: boolean;
  readonly onOptionAdd?: (name: string) => void;
}

export const MultiPicker = ({
  value,
  onChange,
  options,
  getOptionName,
  getOptionGroup,
  groupsOrder,
  getIsOptionDisabled,
  onOptionAdd,
  allowAdd
}: MultiPickerProps) => {
  const { t } = useTranslation("common");
  const valueSet = useMemo(() => new Set(value), [value]);

  const [searchText, setSearchText] = useState("");
  const onSearchTextChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  const items = useMemo<GroupedItems[]>(() => {
    const groupedItems: Map<string, GroupedItemOption[]> = new Map();
    for (const option of options) {
      const name = getOptionName(option);
      let displayName: React.ReactNode = name;
      if (searchText) {
        const searchMatchPosition = name.toLowerCase().indexOf(searchText.toLowerCase());
        if (searchMatchPosition === -1) {
          continue;
        }
        const beforeMatch = name.substring(0, searchMatchPosition);
        const match = name.substring(searchMatchPosition, searchMatchPosition + searchText.length);
        const afterMatch = name.substring(searchMatchPosition + searchText.length);
        displayName = (
          <>
            {beforeMatch}
            <strong>{match}</strong>
            {afterMatch}
          </>
        );
      }
      const item: GroupedItemOption = {
        id: option,
        originalName: name,
        name: displayName,
        checked: valueSet.has(option),
        onChange: () => {
          if (valueSet.has(option)) {
            onChange(value.filter(value => value !== option));
          } else {
            onChange([...value, option]);
          }
        },
        disabled: getIsOptionDisabled ? getIsOptionDisabled(option) : false
      };
      const groupNames = getOptionGroup?.(option) || [""];
      for (const groupName of groupNames) {
        const group = groupedItems.get(groupName);
        if (group) {
          group.push(item);
        } else {
          groupedItems.set(groupName, [item]);
        }
      }
    }
    const natSorter = createNaturalSorter();
    const result: GroupedItems[] = [];
    for (const groupName of groupsOrder || []) {
      const options = groupedItems.get(groupName);
      if (options) {
        result.push({
          groupName,
          options: options.sort((a, b) => natSorter(a.originalName, b.originalName))
        });
      }
    }
    for (const [groupName, options] of groupedItems) {
      const alreadyProcessed = result.some(group => group.groupName === groupName);
      if (alreadyProcessed) {
        continue;
      }
      result.push({
        groupName,
        options: options.sort((a, b) => natSorter(a.originalName, b.originalName))
      });
    }
    return result;
  }, [options, getOptionName, searchText, valueSet, getIsOptionDisabled, getOptionGroup, onChange, value, groupsOrder]);

  const rows: React.ReactNode[] = useMemo(
    () =>
      items.flatMap(({ groupName, options }) =>
        [
          groupName ? (
            <Box key={groupName} display="flex" alignItems="center" height={fixedHeightForVirtualList}>
              <Typography variant="body2" color="text.secondary">
                {groupName}
              </Typography>
            </Box>
          ) : null,
          ...options.map(option => {
            const label = (
              <FormControlLabel
                sx={{
                  width: "100%"
                }}
                color="primary"
                control={<Checkbox />}
                slotProps={{
                  typography: { textOverflow: "ellipsis", noWrap: true }
                }}
                label={option.name}
                checked={option.checked}
                onChange={option.onChange}
                disabled={option.disabled}
              />
            );
            const labelWithTooltip =
              option.originalName.length > 100 ? (
                <Tooltip title={option.originalName} placement="right">
                  {label}
                </Tooltip>
              ) : (
                label
              );

            return (
              <Box key={option.id} ml={3} display="flex" alignItems="center" height={fixedHeightForVirtualList}>
                {labelWithTooltip}
              </Box>
            );
          })
        ].filter(notNull => notNull)
      ),
    [items]
  );

  const onAddCallback = useCallback(() => {
    if (onOptionAdd) {
      onOptionAdd(searchText);
    }
  }, [onOptionAdd, searchText]);

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    return <Box style={style}>{rows[index]}</Box>;
  };

  const searchEl = useMemo(() => {
    if (searchText && !rows.length) {
      return (
        <Box>
          <Box display="flex" justifyContent="center" mt={4}>
            <Typography variant="body2" color="text.secondary">
              {t("multipicker:no_results_found")}
            </Typography>
          </Box>
          {allowAdd && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="outlined" color="primary" onClick={onAddCallback}>
                {t("multipicker:add_new")}
              </Button>
            </Box>
          )}
        </Box>
      );
    } else {
      return <></>;
    }
  }, [searchText, rows.length, allowAdd, t, onAddCallback]);

  return (
    <Box height="65vh">
      <Box width="100%">
        <TextField
          value={searchText}
          onChange={onSearchTextChanged}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
          placeholder={t("common:search")}
          fullWidth={true}
        />
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      {searchEl}
      <Box height="100%">
        <AutoSizer>
          {({ height, width }: Size) => (
            <FixedSizeList height={height} width={width} itemSize={fixedHeightForVirtualList} itemCount={rows.length}>
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

const fixedHeightForVirtualList = 40;

interface GroupedItems {
  readonly groupName: string;
  readonly options: GroupedItemOption[];
}

interface GroupedItemOption {
  id: string;
  name: React.ReactNode;
  originalName: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const textFieldSx: SxProps = {};

const textFieldInputProps: Partial<OutlinedInputProps> = {
  startAdornment: (
    <SearchOutlined
      sx={{
        color: "#23252980",
        marginRight: "8px"
      }}
    />
  )
};
