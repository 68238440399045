import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import { ListItemText, Menu, MenuItem } from "@material-ui/core";
import { ItemStatus, listItemDefaultStatuses, ListViewItemProps, StyledListItemIcon } from "./ListViewItem";
import { LazySvgIcon } from "../LazySvgIcon/LazySvgIcon";
import { usePromisedCallback } from "./usePromisedCallback";
import stopEvent from "tool/stopEvent";

export const StatusDropdown = (props: Pick<ListViewItemProps, "allowedStatuses" | "status" | "onStatusChange">) => {
  const didMount = useRef(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { allowedStatuses = listItemDefaultStatuses, status, onStatusChange } = props;
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(status);
  const { trigger, pending } = usePromisedCallback(
    useCallback(async (status: string) => onStatusChange?.(status), [onStatusChange])
  );
  const theme = useTheme();

  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    selectedStatus && trigger(selectedStatus);
  }, [selectedStatus, trigger]);

  const itemStatusByStatuses = useMemo(() => {
    return allowedStatuses.reduce(
      (acc, allowedStatus) => {
        acc[allowedStatus.status] = allowedStatus;
        return acc;
      },
      {} as Record<string, ItemStatus>
    );
  }, [allowedStatuses]);

  const activeStatus = itemStatusByStatuses[selectedStatus ?? allowedStatuses[0].status];
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    stopEvent(event);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }, []);

  const handleClose = useCallback((event: React.MouseEvent) => {
    setAnchorEl(null);
    event.stopPropagation();
  }, []);

  const isOpen = Boolean(anchorEl);

  if (!activeStatus) {
    return null;
  }

  return (
    <Box onClick={stopEvent}>
      <Tooltip title={activeStatus?.label ?? activeStatus?.status} placement="top">
        <Box
          onClick={onStatusChange ? handleClick : undefined}
          sx={{
            cursor: onStatusChange ? "pointer" : undefined,
            height: 24,
            opacity: pending ? 0.5 : 1
          }}
        >
          {activeStatus?.icon}
        </Box>
      </Tooltip>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {allowedStatuses?.map(({ status: allowedStatus, label, icon }) => (
          <MenuItem
            key={allowedStatus}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(event: React.MouseEvent<HTMLLIElement>) => {
              setSelectedStatus(allowedStatus);
              handleClose(event);
            }}
          >
            <StyledListItemIcon>{icon}</StyledListItemIcon>
            <ListItemText>{label ?? allowedStatus}</ListItemText>
            {selectedStatus === allowedStatus ? (
              <LazySvgIcon name="Check" color={theme.palette.text.secondary} />
            ) : (
              <Box sx={{ width: 24, height: 24 }} />
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
