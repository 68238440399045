import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chip: {
    marginRight: "3px"
  }
});

export default function Display({ withoutComma = false, elements }) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!elements || !elements.length) {
    return <span className="faded">{t("common:not_available")}</span>;
  }

  return (
    <span className={classes.chip}>
      {elements.map((x, k) => (
        <span key={k}>
          {k > 0 && withoutComma === true && <span> </span>}
          {k > 0 && withoutComma === false && <span>, </span>}
          {x}
        </span>
      ))}
    </span>
  );
}
