import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type AssignableFieldsCheckboxesProps = {
  readonly name: string;
  readonly assignableFields: string[];
  readonly selectedAssignableFields: string[];
  readonly updateSelected: (selectedAssignableFields: string[]) => void;
};

export const AssignableFieldsCheckBoxes = ({
  name,
  assignableFields,
  selectedAssignableFields,
  updateSelected
}: AssignableFieldsCheckboxesProps) => {
  const { t } = useTranslation("manage-user-page");

  const getLabel = useCallback(
    fieldName => {
      return t("assignable_" + fieldName);
    },
    [t]
  );

  const handleCheck = useCallback(
    event => {
      const fieldName = event.target.value;
      if (selectedAssignableFields.includes(fieldName)) {
        updateSelected(selectedAssignableFields.filter(selectedField => selectedField !== fieldName));
      } else {
        updateSelected([...selectedAssignableFields, fieldName]);
      }
    },
    [selectedAssignableFields, updateSelected]
  );

  return (
    <Box>
      <Box mt={3}>
        <Box mb={2}>
          <Typography className="pre-wrap" variant="h2">
            {t(name.toLowerCase().replace(" ", "_"))}
          </Typography>
        </Box>
        <div className="list-container">
          {assignableFields.map(assignableField => (
            <Box mb={1} key={"box " + assignableField}>
              <FormControlLabel
                key={assignableField}
                control={
                  <Checkbox
                    checked={selectedAssignableFields.includes(assignableField)}
                    value={assignableField}
                    onChange={handleCheck}
                    color="primary"
                    name={assignableField}
                  />
                }
                label={getLabel(assignableField)}
              />
            </Box>
          ))}
        </div>
      </Box>
    </Box>
  );
};
