import { useParams } from "react-router-dom";
import ResourceTypeOverview from "app/pages/resources/overview/resources/ResourceTypeOverview";
import { ALL_RESOURCE_TYPES, RESOURCE_TYPE } from "app/handlers/resourceHandler";

export const ResourceTypeOverviewPage = () => {
  const params = useParams();
  const rawResourceType = params?.resourceType || "";

  if (!rawResourceType) {
    return <></>;
  }

  const resourceType = rawResourceType as RESOURCE_TYPE; // force casting, needs to check in next line
  if (!ALL_RESOURCE_TYPES.includes(resourceType)) {
    return <></>;
  }

  return <ResourceTypeOverview resourceType={resourceType} />;
};
