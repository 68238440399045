import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useState } from "react";
import TabsLayout from "../../../components/TabsLayout/TabsLayout";
import { RiskGeneral } from "./RiskGeneralPage";
import { RiskFirstAssessment } from "./RiskFirstAssessmentPage";
import { RiskTreatment } from "./RiskTreatmentPage";
import { RiskSecondAssessment } from "./RiskSecondAssessmentPage";
import { useRisk } from "../../contexts/risk-context";
import { EntityMetaViewProvider } from "../../contexts/meta-view-context";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import { tenantRiskId } from "../../handlers/risksHandler";
import { LinkText } from "../../router/router-filters";
import GeneralPageButtons from "../../../components/Pagination/GeneralPageButtons";
import { Box, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DocumentNotFound } from "../shared/DocumentNotFound/DocumentNotFound";
import { RiskDTO } from "../../api/riskApi";

export const RiskAsTabs = ({
  onClose,
  selectedTab,
  updateSelectedTab
}: {
  readonly onClose: () => void;
  readonly selectedTab: number;
  readonly updateSelectedTab: (tab: number) => void;
}) => {
  const { t } = useTranslation("risks_overview");
  const { initialized, risk } = useRisk();

  const infoBox = useMemo(() => {
    if (risk?.type !== "general") {
      return <></>;
    }

    return <CustomAlert severity="info">{t("alert_general")}</CustomAlert>;
  }, [risk?.type, t]);

  const tabContents = useMemo(() => {
    return [
      {
        title: t("pagination:general"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskGeneral />
          </RelativeBox>
        )
      },
      {
        title: t("pagination:risk_assessments_first"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskFirstAssessment />
          </RelativeBox>
        )
      },
      {
        title: t("pagination:risk_treatment"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskTreatment />
          </RelativeBox>
        )
      },
      {
        title: t("pagination:risk_assessments_second"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskSecondAssessment />
          </RelativeBox>
        )
      }
    ];
  }, [t, infoBox]);

  if (!initialized) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <LinkText pathname={`/risks/${risk.id}/general`} onClick={onClose}>
          {tenantRiskId(risk)}
        </LinkText>
        <IconButton>
          <CloseIcon onClick={onClose} />
        </IconButton>
      </Box>
      <TabsLayout tabContents={tabContents} setSelectedTab={updateSelectedTab} selectedTab={selectedTab} />
    </Box>
  );
};

const RelativeBox = ({ children }: { readonly children: React.ReactNode }) => {
  return <Box position="relative">{children}</Box>;
};

const TabsEnum = {
  general: 0,
  firstAssessment: 1,
  treatment: 2,
  secondAssessment: 3
};

export const RiskDialog = ({
  open,
  onClose
}: {
  readonly open: boolean;
  readonly onClose: (id: string, risk: RiskDTO) => void;
}) => {
  const { t } = useTranslation("risk-dialog");

  const [selectedTab, setSelectedTab] = useState(0);

  const goToNextTabCallback = useCallback(
    () => setSelectedTab(selectedTab => Math.min(selectedTab + 1, TabsEnum.secondAssessment)),
    []
  );
  const goToPreviousTabCallback = useCallback(
    () => setSelectedTab(selectedTab => Math.max(selectedTab - 1, TabsEnum.general)),
    []
  );

  const { risk, latestRisk, isMissing } = useRisk();

  const onCloseCallback = useCallback(() => {
    onClose(risk?.id || "", latestRisk as RiskDTO);
  }, [onClose, risk?.id, latestRisk]);

  const primaryButton = useMemo(() => {
    const isLastTab = selectedTab === TabsEnum.secondAssessment;
    if (isLastTab) {
      return {
        onClick: onCloseCallback,
        label: t("common:close")
      };
    }

    return {
      onClick: goToNextTabCallback
    };
  }, [selectedTab, goToNextTabCallback, onCloseCallback, t]);

  const secondaryButton = useMemo(() => {
    const isFirstTab = selectedTab === TabsEnum.general;
    if (isFirstTab) {
      return undefined;
    }
    return {
      onClick: goToPreviousTabCallback
    };
  }, [selectedTab, goToPreviousTabCallback]);

  return (
    <Dialog open={open} onClose={onCloseCallback} fullWidth={true} maxWidth="md">
      {isMissing ? (
        <DialogContent>
          <DocumentNotFound onContinue={onCloseCallback} />
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <EntityMetaViewProvider>
              <RiskAsTabs onClose={onCloseCallback} updateSelectedTab={setSelectedTab} selectedTab={selectedTab} />
            </EntityMetaViewProvider>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="space-between" mr={2} mb={3} ml={2}>
              <Box flex={1} />
              <Box display="flex" justifyContent="space-between" pt={3}>
                <GeneralPageButtons primaryButton={primaryButton} secondaryButton={secondaryButton} />
              </Box>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
