import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { RiskPageButtons, RiskPageStepper } from "./RiskPagination";
import { RiskPhaseAssessment } from "./assessments/RiskPhaseAssessment";
import { RiskImplementedMeasureIDs } from "./assessments/RiskImplementedMeasureIDs";
import { useRisk } from "../../contexts/risk-context";
import { COLLECTIONS } from "../../collections";
import { Box, Typography } from "@mui/material";

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const RiskFirstAssessmentPage = () => {
  const { t } = useTranslation("risk_first_assessment_page");

  const {
    risk: { title: riskTitle },
    riskId
  } = useRisk();
  const { setInfo } = useMetaView();

  const infoCardEntering = useMemo(
    () => ({
      title: t("risk_first_assessment_page:enteringInfoCardTitle"),
      text: t("risk_first_assessment_page:enteringInfoCardText")
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCardEntering);
  }, [setInfo, infoCardEntering]);

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          translationKey={"risk_first_assessment_page"}
          docName={riskTitle}
          docId={riskId}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={riskTitle} pagination={<RiskPageStepper />}>
        <Box mt={6}>
          <QuestionnaireSubHeader text={t("subTitle")} />
        </Box>
        <RiskFirstAssessment />
        <Box mt={3}>
          <RiskPageButtons />
        </Box>
      </DocView>
    </DocMetaView>
  );
};

export const RiskFirstAssessment = () => {
  const { t } = useTranslation("risk_first_assessment_page");

  const { setInfo } = useMetaView();

  const infoCardEntering = useMemo(
    () => ({
      title: t("risk_first_assessment_page:enteringInfoCardTitle"),
      text: t("risk_first_assessment_page:enteringInfoCardText")
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCardEntering);
  }, [setInfo, infoCardEntering]);

  return (
    <Box mt={3}>
      <RiskPhaseAssessment phaseId="first">
        <Typography variant="subtitle2">{t("alreadyImplementedMeasures")}</Typography>
        <Box mt={1}>
          <RiskImplementedMeasureIDs />
        </Box>
      </RiskPhaseAssessment>
    </Box>
  );
};
