import React, { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { isEqual } from "lodash-es";
import { COLLECTIONS } from "app/collections";
import AssignUsersMultiAutocomplete from "../../../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import { Box, Avatar, makeStyles } from "@material-ui/core";
import ChipsStack from "components/ChipsStack/ChipsStack";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { TaskInfoLabel } from "./TaskInfoLabel";
import ParticipantsIcon from "assets/images/tasks/participants-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import { TaskDetailsDTO } from "app/api/taskApi";

const useStyles = makeStyles(theme => ({
  chipStack: {
    marginLeft: theme.spacing(4),
    marginTop: -theme.spacing(0.25)
  }
}));

interface TaskParticipantsProps {
  readonly task: TaskDetailsDTO;
  readonly onChangeParticipants: (participants: string[]) => void;
  readonly saveOperationInProgress: boolean;
}
export const TaskParticipants = ({ task, onChangeParticipants, saveOperationInProgress }: TaskParticipantsProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { getUserNameHook } = useUserAndTenantData();
  const [addParticipantsActive, setAddParticipantsActive] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);

  const resetTaskParticipants = useCallback(() => {
    const taskParticipants = task.participants || [];
    setSelectedParticipants(taskParticipants.filter((participantId: string) => participantId !== task.assigneeUID));
  }, [task.participants, task.assigneeUID]);

  const participantsAreUnchanged = useMemo(
    () =>
      isEqual(
        new Set(selectedParticipants || []),
        new Set(
          task.participants
            ? task.participants.filter((participantId: string) => participantId !== task.assigneeUID)
            : []
        )
      ),
    [selectedParticipants, task.participants, task.assigneeUID]
  );

  const addParticipantsModalBody = useMemo(
    () => (
      <AssignUsersMultiAutocomplete
        id={"assignee"}
        docAssignedUserIds={selectedParticipants}
        onDocAssignedUserIdsChanged={setSelectedParticipants}
        freeSolo={true}
        disableClearable={true}
        label={t("add_participant")}
        hasMultiSelect={true}
        excludedUserIds={[task.assigneeUID || ""]}
        hasDefaultValue={false}
      />
    ),
    [selectedParticipants, setSelectedParticipants, t, task.assigneeUID]
  );

  const addParticipantsModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: () => {
          resetTaskParticipants();
          setAddParticipantsActive(false);
        }
      },
      {
        confirmButton: true,
        disabled: participantsAreUnchanged || saveOperationInProgress,
        title: t("save"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: () => {
          onChangeParticipants(selectedParticipants);
          setAddParticipantsActive(false);
        }
      }
    ],
    [
      resetTaskParticipants,
      setAddParticipantsActive,
      participantsAreUnchanged,
      saveOperationInProgress,
      t,
      onChangeParticipants,
      selectedParticipants
    ]
  );

  const closeConfirmationModal = useCallback(() => {
    resetTaskParticipants();
    setAddParticipantsActive(false);
  }, [resetTaskParticipants]);

  const getUserAvatarIcon = function (userId: string) {
    return <Avatar>{getUserNameHook(userId)?.substring(2, 0).toUpperCase()}</Avatar>;
  };

  return (
    <Box mt={2} mb={1} display={"flex"} alignItems={"center"}>
      <TaskInfoLabel icon={ParticipantsIcon} text={t("task_details:participants")} inline={true} />
      <ConfirmationModal
        modalOpen={addParticipantsActive}
        onClose={closeConfirmationModal}
        modalTitle={t("add_participant_modal_title")}
        modalText={t("add_participant_modal_text")}
        modalBody={addParticipantsModalBody}
        buttons={addParticipantsModalButtons}
        variant="info"
      />
      <Box className={cls.chipStack}>
        <ChipsStack
          chips={[
            ...(task.participants && task.participants.length
              ? task.participants.map((userId: string) => ({
                  variant: "circleAvatar",
                  icon: getUserAvatarIcon(userId),
                  onClick: () => {
                    setSelectedParticipants(task.participants || []);
                    setAddParticipantsActive(true);
                  }
                }))
              : []),
            {
              variant: "circleDashed",
              icon: AddIcon,
              onClick: () => {
                setSelectedParticipants(task.participants || []);
                setAddParticipantsActive(true);
              }
            }
          ]}
        />
      </Box>
    </Box>
  );
};
