// generate title for the newly copied PA

export const sortNameWithoutBracketNumber = (a, b, criteria) => {
  const [aName, aVersion] = getNameWithBracketNumber(a);
  const [bName, bVersion] = getNameWithBracketNumber(b);

  if (aName !== bName) {
    if (criteria === "alphabeticalAsc") {
      return a.localeCompare(b);
    }
    if (criteria === "alphabeticalDesc") {
      return b.localeCompare(a);
    }
  }

  if (criteria === "alphabeticalAsc") {
    if (aVersion < bVersion) {
      return -1;
    }
    return 1;
  }

  if (criteria === "alphabeticalDesc") {
    if (bVersion < aVersion) {
      return -1;
    }
    return 1;
  }

  return 0;
};

export const getNameWithBracketNumber = nameWithNumber => {
  //match if name contains () with a number indicating a version
  const matchVersion = nameWithNumber.match(/\((\d*)\)/);
  const isCopiedName = matchVersion && matchVersion[1];
  const name = isCopiedName ? nameWithNumber.split(matchVersion[0])[0] : nameWithNumber;
  const number = isCopiedName ? parseInt(matchVersion[1]) : 0;

  return [name.trim(), number];
};

export function generateVersionedTitle(name, usedNames, existingNames) {
  const nameAndVersion = getNameWithBracketNumber(name);
  //merge already copied and names
  const dataNames = [...usedNames, ...existingNames];
  const sortedNames = dataNames.sort((a, b) => {
    return sortNameWithoutBracketNumber(a, b, "alphabeticalDesc");
  });
  //get latest counter from existing titles
  const findByLatestName = sortedNames.filter(dataName => dataName.includes(nameAndVersion[0]));
  if (findByLatestName.length !== 0) {
    const latestCounterSecond = getNameWithBracketNumber(findByLatestName[0]);
    let number = latestCounterSecond[1];
    const newVersion = number + 1;
    //format intial title e.g PA name (1)
    return `${nameAndVersion[0]} (${newVersion})`;
  }
  return nameAndVersion[0];
}
