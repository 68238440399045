const passwordContainsSpecialCharacter = (passwordString: string): boolean => {
  const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

  return format.test(passwordString);
};

const passwordContainsNumber = (passwordString: string): boolean => /\d/.test(passwordString);

export const isPasswordStrongEnough = (passwordString: string): boolean =>
  passwordContainsNumber(passwordString) &&
  passwordString.length > 11 &&
  passwordContainsSpecialCharacter(passwordString);
