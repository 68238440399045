import MultiAutocomplete from "../../../components/MultiAutocomplete/MultiAutocomplete";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { tSimpleTranslate } from "../../handlers/dataTypeTranslatorHandler";
import { makeStyles } from "@material-ui/core/styles";
import { toJSDateObjectIfFirebaseDate } from "../../handlers/utility/date-helper";
import { DateField } from "../../../components/DateField";
import { ResourceField } from "../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../handlers/resourceHandler";
import { useUserDepartments } from "../../contexts/department-context";
import { useResources } from "../../contexts/resource-context";
import { useDataTypeTree } from "app/api/dataAssetApi";

export const DSRRequestTypeField = ({ onFocus, values, onChanges, orgUnitId, disabled, furtherOrgUnitIds }) => {
  const { t } = useTranslation("data_subject_requests_overview_general_page");
  const { departments } = useUserDepartments();
  const { resources } = useResources();
  const requestTypes = resources[RESOURCE_TYPES.DSR_REQUEST_TYPE];
  const [whitelistedResourceTypeIDs, setWhitelistedResourceTypeIDs] = useState([]);
  useEffect(() => {
    if (!orgUnitId) {
      setWhitelistedResourceTypeIDs([]);
      return;
    }

    setWhitelistedResourceTypeIDs((requestTypes || []).map(resource => resource.id));
  }, [departments, orgUnitId, requestTypes]);

  const docOrgUnitIds = useMemo(() => [orgUnitId, ...(furtherOrgUnitIds || [])], [orgUnitId, furtherOrgUnitIds]);

  return (
    <ResourceField
      id="request_type"
      value={values}
      onChange={onChanges}
      onFocus={onFocus}
      resourceType={RESOURCE_TYPES.DSR_REQUEST_TYPE}
      label={t("request_type")}
      whitelistedIDs={whitelistedResourceTypeIDs}
      disabled={disabled}
      docOrgUnitIds={docOrgUnitIds}
    />
  );
};

DSRRequestTypeField.propTypes = {
  onFocus: PropTypes.func.isRequired,
  onChanges: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  furtherOrgUnitIds: PropTypes.arrayOf(PropTypes.string),
  orgUnitId: PropTypes.string
};

DSRRequestTypeField.defaultProps = {
  values: []
};

export const DSRPersonGroupField = ({ values, onChanges, onBlur, disabled }) => {
  const { t } = useTranslation();

  const dataTypeTree = useDataTypeTree();

  const getOptionLabel = useCallback(
    option => {
      const pg = dataTypeTree.dataById?.personGroups?.[option];
      if (!pg) return "";
      return tSimpleTranslate({ t, typeCategoryOrPersonGroup: pg.personGroupKey });
    },
    [t, dataTypeTree.dataById?.personGroups]
  );
  const personGroupIds = React.useMemo(
    () => Object.keys(dataTypeTree.dataById?.personGroups || {}),
    [dataTypeTree.dataById?.personGroups]
  );

  return (
    <MultiAutocomplete
      hasMultiSelect={true}
      getOptionLabel={getOptionLabel}
      options={personGroupIds}
      selected={values}
      updateSelected={onChanges}
      onBlur={onBlur}
      placeholder={t("common:search")}
      id="person_groups"
      label={t("personGroup:questionTitle")}
      elementId={"person_groups"}
      disabled={disabled}
    />
  );
};

DSRPersonGroupField.propTypes = {
  onChanges: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
};

DSRPersonGroupField.defaultProps = {
  values: []
};

const useDSRReceivedOnStyles = makeStyles(theme => ({
  datePickerBottom: {
    "& button": {
      color: theme.palette.primary.main,
      position: "relative",
      left: "17px"
    },
    "& .MuiFormControl-marginNormal": {
      margin: 0
    }
  }
}));

export const DSRReceivedOnField = ({ onFocus, onBlur, createdDate, value, onChange, disabled }) => {
  const classes = useDSRReceivedOnStyles();
  const { t } = useTranslation("data_subject_requests_overview_general_page");

  return (
    <div className={classes.datePickerBottom}>
      <DateField
        maxDate={toJSDateObjectIfFirebaseDate(createdDate) || null}
        date={toJSDateObjectIfFirebaseDate(value) || null}
        onDateChange={onChange}
        label={t("received_on")}
        onFocus={onFocus}
        onBlue={onBlur}
        withTime={true}
        disabled={disabled}
      />
    </div>
  );
};

DSRReceivedOnField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  createdDate: PropTypes.instanceOf(Date),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool
};

DSRReceivedOnField.defaultProps = {};
