import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, makeStyles, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { Close, MoreHoriz } from "@material-ui/icons";
import TaskDeleteConfirmation from "../../TaskDeleteConfirmation";
import { FEATURES } from "app/features";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { TaskDetailsDTO } from "app/api/taskApi";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";

const useStyles = makeStyles(theme => ({
  moreButton: {
    padding: 2
  },
  closeIcon: {
    padding: 2
  },
  deleteMenuItem: {
    color: theme.palette.error.main
  },
  menuStyle: {
    marginTop: "40px",
    marginLeft: "-70px",
    "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded": {
      boxShadow: "0px 3px 6px 0px #00000026"
    }
  },
  deleteConfirmationDialog: {
    position: "absolute",
    marginTop: 0,
    zIndex: 1,
    maxWidth: "368px",
    width: "-webkit-fill-available"
  }
}));

interface TaskTopRowActionsProps {
  readonly assignedToType?: string | null;
  readonly item: TaskDetailsDTO;
  readonly onChangeAssignType: (assignType: string) => void;
  readonly onCloseDetails: () => void;
  readonly onDelete: (id: string) => void;
  readonly readOnly?: boolean;
  readonly setIsSlidedIn?: (taskLoaded: boolean) => void;
}
const TaskTopRowActions = ({
  assignedToType,
  item,
  onChangeAssignType,
  onCloseDetails,
  onDelete,
  readOnly,
  setIsSlidedIn
}: TaskTopRowActionsProps) => {
  const cls = useStyles();
  const { t } = useTranslation();
  const isGroupFeatureEnabled = useIsFeaturePresent(FEATURES.USERGROUPS_FEATURES);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [showInlineTaskDeletion, setShowInlineDeletion] = useState(false);

  const onCloseButtonClick = useCallback(() => {
    setIsSlidedIn?.(false);
    // give closing animation some time
    setTimeout(() => {
      onCloseDetails();
    }, 100);
  }, [onCloseDetails, setIsSlidedIn]);

  const onMoreMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget as Element),
    []
  );
  const closeMenu = useCallback(() => setMenuAnchorEl(null), []);

  const convertToGroupTask = useCallback(() => {
    closeMenu();
    onChangeAssignType("group");
  }, [closeMenu, onChangeAssignType]);

  const convertToUserTask = useCallback(() => {
    closeMenu();
    onChangeAssignType("user");
  }, [closeMenu, onChangeAssignType]);

  const onDeleteClick = useCallback(() => {
    closeMenu();
    setShowInlineDeletion(true);
  }, [closeMenu]);
  const onDeleteConfirm = useCallback(() => {
    setShowInlineDeletion(false);
    onDelete(item.id);
  }, [item.id, onDelete]);
  const onDeleteCancel = useCallback(() => setShowInlineDeletion(false), []);

  const onOpenModal = useCallback(() => setIsModalOpen(true), []);
  const onCloseModal = useCallback(() => setIsModalOpen(false), []);

  const moreMenuButton = (
    <Tooltip title={t("common:More")}>
      <Box pr={0}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={onMoreMenuClick}
          className={cls.moreButton}
        >
          {<MoreHoriz />}
        </IconButton>
      </Box>
    </Tooltip>
  );

  const closeEl = (
    <Tooltip title={t("common:close")}>
      <Box>
        <IconButton onClick={onCloseButtonClick} className={cls.closeIcon}>
          <Close />
        </IconButton>
      </Box>
    </Tooltip>
  );

  const deleteEl = (
    <Box my={2} className={cls.deleteConfirmationDialog}>
      <TaskDeleteConfirmation onConfirm={onDeleteConfirm} onCancel={onDeleteCancel} />
    </Box>
  );

  const assignTaskModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: () => {
          setIsModalOpen(false);
          closeMenu();
        }
      },
      {
        confirmButton: true,
        title: t("common:save"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: async () => {
          setIsModalOpen(false);
          closeMenu();
          if (assignedToType === "USER") {
            convertToGroupTask();
          } else if (assignedToType === "GROUP") {
            convertToUserTask();
          }
        }
      }
    ],
    [t, closeMenu, assignedToType, convertToGroupTask, convertToUserTask]
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems={"space-evenly"} mr={-1}>
        {!readOnly && moreMenuButton}
        {closeEl}
      </Box>
      <Menu
        id="long-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        className={cls.menuStyle}
      >
        {isGroupFeatureEnabled && assignedToType === "USER" ? (
          <MenuItem onClick={onOpenModal}>{t("task_details:convert_to_group_task")}</MenuItem>
        ) : assignedToType === "GROUP" ? (
          <MenuItem onClick={onOpenModal}>{t("task_details:convert_to_regular_task")}</MenuItem>
        ) : null}
        <MenuItem onClick={onDeleteClick} className={cls.deleteMenuItem}>
          {t("common:delete")}
        </MenuItem>
      </Menu>

      {showInlineTaskDeletion ? deleteEl : null}
      <ConfirmationModal
        modalOpen={isModalOpen}
        onClose={onCloseModal}
        modalTitle={t("common:information")}
        modalText={t("metaview_groupdialog:confirm_delete")}
        buttons={assignTaskModalButtons}
        variant="info"
      />
    </>
  );
};

export { TaskTopRowActions };
