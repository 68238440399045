import React, { useCallback, useMemo } from "react";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { Avatar, Box, Tooltip, useTheme } from "@mui/material";
import { UserDTO } from "app/api/user/userApi";
import UserAvatarRemovalIcon from "assets/images/icons/UserAvatarRemoval.svg";

export interface UserAvatarProps {
  readonly userId?: string;
  readonly label?: string;
  readonly color?: string;
  readonly size?: "small" | "large";

  readonly onRemove?: (val: string) => void;
}

interface UserAvatarLabelModelProps {
  readonly label: string;
  readonly tooltip?: string;
}

export const UserAvatar = ({ userId, label, color, size, onRemove }: UserAvatarProps) => {
  const theme = useTheme();

  const sx = {
    root: {
      position: "relative",
      cursor: "pointer",
      "& .removal": {
        display: "none"
      },
      "&:hover": {
        "& .removal": {
          display: "block"
        }
      }
    },
    small: {
      width: "24px",
      height: "24px",
      fontSize: "10px",
      fontFamily: "SuisseIntl"
    },
    large: {
      width: "36px",
      height: "36px",
      fontSize: "14px",
      fontFamily: "SuisseIntl"
    },
    removal: {
      small: {
        position: "absolute",
        top: 0,
        borderRadius: "50%",
        backgroundColor: "rgba(0,0,0, 0.25)",
        width: "24px",
        height: "24px",
        padding: "2px"
      },
      large: {
        position: "absolute",
        top: 0,
        borderRadius: "50%",
        backgroundColor: "rgba(0,0,0, 0.25)",
        width: "36px",
        height: "36px",
        padding: "9px"
      }
    }
  };

  const { getUserHook } = useUserAndTenantData();

  const model: UserAvatarLabelModelProps = useMemo(() => {
    if (userId) {
      const user: UserDTO | null = getUserHook(userId);
      if (user) {
        return {
          tooltip: `${user.firstName} ${user.lastName}`,
          label: `${(user.firstName || "").substring(1, 0).toUpperCase()}${(user.lastName || "").substring(1, 0).toUpperCase()}`
        };
      }
      return { label: label || "" };
    } else {
      return { label: label || "" };
    }
  }, [getUserHook, label, userId]);

  const onRemoveCallback = useCallback(
    e => {
      e.stopPropagation();
      onRemove?.(userId || label || "");
    },
    [label, onRemove, userId]
  );

  const removalEl = useMemo(() => {
    if (onRemove) {
      return (
        <Box sx={sx.removal[size || "small"]} className="removal" onClick={onRemoveCallback}>
          <UserAvatarRemovalIcon />
        </Box>
      );
    } else return <></>;
  }, [onRemove, onRemoveCallback, size, sx.removal]);

  return (
    <Tooltip title={model.tooltip || model.label}>
      <Box sx={sx.root}>
        <Avatar
          aria-label="headerIcon"
          sx={{
            ...sx[size || "small"],
            backgroundColor: color || theme.palette.primary.main,
            ...(onRemove ? sx.removal : {})
          }}
        >
          {model.label}
        </Avatar>
        {removalEl}
      </Box>
    </Tooltip>
  );
};

export default UserAvatar;
