import { getAllAttachmentsApi, getAttachmentsAsZipApi, getFileAsBlob } from "../api/file-storage/attachmentsApi";
import streamSaver from "streamsaver";
import { InputWithSizeMeta, makeZip } from "client-zip";
import { createAndDownloadFile } from "../export/createAndDownloadFile";

export interface ExportDefinition {
  readonly category: string;
  readonly categoryName: string;
  readonly docIds: string[];
  readonly docIdToFolderName: (docId: string) => string;
  readonly withPrefixQuery: boolean;
}

// the problem is, since axios is handling the download, it have to store it in memory for in between result
export const streamFilesToZipBEVersion = async (input: {
  readonly exportDefinitions: ExportDefinition[];
  readonly zipFileName?: string;
}) => {
  const response = await getAttachmentsAsZipApi({
    zipSpec: {
      folders: input.exportDefinitions.flatMap(exportDef =>
        exportDef.docIds.map(docId => ({
          tenantFolderName: `${exportDef.category}-${docId}`,
          inZipFolderName: `${exportDef.docIdToFolderName(docId)}/${exportDef.categoryName}`
        }))
      )
    }
  });

  createAndDownloadFile(new Blob([response.data]), input.zipFileName || `attachments-${new Date().toISOString()}.zip`);
};

// only 1 thing is important here, that we don't download the file and save to memory
// we should use stream, stream the file out to the output zip as we download it, we output it
// otherwise, we will hit out of memory. that's why we make heavy use of generators here
export const streamFilesToZipFEVersion = async (input: {
  readonly exportDefinitions: ExportDefinition[];
  readonly zipFileName?: string;
}) => {
  const fileStream = getStreamSaver().createWriteStream(
    input.zipFileName || `attachments-${new Date().toISOString()}.zip`
  );

  const zipStream = makeZip(yieldExportDefs(input.exportDefinitions));
  return zipStream.pipeTo(fileStream);
};

const yieldExportDef = async function* (exportDefinition: ExportDefinition): AsyncIterable<InputWithSizeMeta> {
  for (const docId of exportDefinition.docIds) {
    const apiFolderName = `${exportDefinition.category}-${docId}`;
    const zipFolderName = exportDefinition.docIdToFolderName(docId);
    const files = await getAllAttachmentsApi({
      folderName: apiFolderName,
      withPrefix: exportDefinition.withPrefixQuery
    });
    for (const file of files) {
      const fileBlob = await getFileAsBlob({ folderName: apiFolderName, fileName: file.name });
      if (!fileBlob) {
        console.error("downloading empty file", apiFolderName, file.name);
        continue;
      }

      const fileInfo: InputWithSizeMeta = {
        name: `${zipFolderName}/${exportDefinition.categoryName}/${file.name}`,
        lastModified: file.timeCreated,
        size: fileBlob.size,
        input: fileBlob
      };

      yield fileInfo;
    }
  }
};

const yieldExportDefs = async function* (exportDefinitions: ExportDefinition[]): AsyncIterable<InputWithSizeMeta> {
  for (const exportDefinition of exportDefinitions) {
    for await (const fileInfo of yieldExportDef(exportDefinition)) {
      if (fileInfo) {
        yield fileInfo;
      }
    }
  }
};

const getStreamSaver = () => {
  // switch the mitm service worker to the local ones to avoid CSP issues
  streamSaver.mitm = "/streamsaver.html";
  return streamSaver;
};

export const streamFilesToZip = streamFilesToZipFEVersion;
