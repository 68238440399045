import { Box, Divider } from "@material-ui/core";
import TextBody2 from "components/TextBody2/TextBody2";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { linkDataLocationApi } from "../../api/externalRecipientApi";
import { DataLocationsPicker } from "./DataLocationsPicker";
import { DataLocationAffectedProcesses } from "./DataLocationAffectedProcesses";

export interface DataLocationUnlinkItem {
  readonly id: string;
}
export interface DataLocationUnlinkModalProps {
  readonly selectedDataLocations: DataLocationUnlinkItem[];
  readonly onClose: () => void;
  readonly onCancel: () => void;
}
export const DataLocationUnlinkModal = ({ selectedDataLocations, onClose, onCancel }: DataLocationUnlinkModalProps) => {
  const { t } = useTranslation("service_providers_overview");

  const [selectedDataLocationIds, setSelectedDataLocationIds] = useState<string[]>([]);
  useEffect(() => {
    setSelectedDataLocationIds(selectedDataLocations.map(dl => dl.id));
  }, [selectedDataLocations]);

  const unlinkDataLocations = useCallback(async () => {
    await linkDataLocationApi(
      {
        toLinkIDs: selectedDataLocationIds,
        externalRecipientId: null
      },
      {
        wait: true
      }
    );
    onClose();
  }, [onClose, selectedDataLocationIds]);

  const [buttons, setButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(
    () =>
      setButtons([
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancel
        },
        {
          confirmButton: true,
          title: t("service_providers_overview:save"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: unlinkDataLocations
        }
      ]),
    [onCancel, onClose, t, unlinkDataLocations]
  );

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box>
        <TextBody2 text={t("datalocations_unlink_confirmation")} />
        <Box mt={2}>
          <DataLocationsPicker selectedIDs={selectedDataLocationIds} onChange={setSelectedDataLocationIds} />
        </Box>
      </Box>
      {!!selectedDataLocationIds.length && (
        <Box mt={3}>
          <Box mb={1}>
            <TextBody2 text={t("affected_pas")} />
          </Box>
          <Divider />
          <Box>
            <DataLocationAffectedProcesses selectedDataLocationIds={selectedDataLocationIds} />
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );

  return (
    <ConfirmationModal
      modalOpen={!!selectedDataLocations.length}
      onClose={onCancel}
      modalTitle={t("unlink_data_source_modal_title")}
      modalText={t("unlink_data_source_warning_text")}
      buttons={buttons}
      modalBody={modalBody}
    />
  );
};
