import * as Sentry from "@sentry/react";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import { LoggedOutInactiveError, NotLoggedInError } from "../app/handlers/authentication/authenticationError";

const selfHostedSentryDSN = "https://1828218b05f14286a73f066970c51bf4@sentry.caralegal.de/2";

const getSentryConfigBasedOnHostname = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case "dev.app.caralegal.eu":
      return {
        dsn: selfHostedSentryDSN,
        environment: hostname
      };
    case "stage.app.caralegal.eu":
      return {
        dsn: selfHostedSentryDSN,
        environment: hostname
      };
    case "app.caralegal.eu":
      return {
        dsn: selfHostedSentryDSN,
        environment: hostname,
        tracesSampleRate: 0.1
      };
    default:
      if (hostname.endsWith("web.app")) {
        return {
          dsn: selfHostedSentryDSN,
          environment: "PR-preview",
          debug: true
        };
      }
      // uncomment to test sentry locally
      // setTimeout(() => {
      //   Sentry.captureException(new Error("test"));
      // }, 10000);
      // return {
      //   dsn: selfHostedSentryDSN,
      //   environment: "local",
      //   debug: true
      // };
      return null;
  }
};

export const setupSentryIfNecessary = () => {
  const sentryConfig = getSentryConfigBasedOnHostname();
  if (!sentryConfig?.dsn) {
    return;
  }

  Sentry.init({
    beforeSend(event, hint) {
      if (
        hint.originalException &&
        // no need to send sentry error if just login is expired
        (hint.originalException instanceof NotLoggedInError || hint.originalException instanceof LoggedOutInactiveError)
      ) {
        return null;
      }
      return event;
    },
    integrations: [
      new Sentry.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ["error"]
      }),
      // breadcrumbs log all console logs, we don't want that as it's noise and might contain some information
      new Sentry.Integrations.Breadcrumbs({ console: false })
    ],
    tracesSampleRate: 1.0,
    release: import.meta.env.VITE_RELEASE_ID || "local",
    ...sentryConfig
  });
};
