import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Question from "../../../../components/Question/Question";
import RadioButtonQuestion from "../../../../components/RadioButtonQuestion/RadioButtonQuestion";

interface DpiaRadioButtonQuestionProps {
  keyName: string;
  readonly: boolean;
  index: number;
  value: string | number | boolean | undefined;
  vulnerablePersonIsYes: boolean;
  onFocus: (key: string) => void;
  onChange: (key: string, value: string | number | boolean | undefined) => void;
}

const DpiaRadioButtonQuestion: React.FC<DpiaRadioButtonQuestionProps> = ({
  keyName,
  readonly,
  index,
  value,
  vulnerablePersonIsYes,
  onFocus,
  onChange
}) => {
  const { t } = useTranslation("pa_assessment");
  const options = useMemo(() => ["yes", "no", "unsure"], []);
  const optionLabels = useMemo(() => options.map(option => t(`fields:${option}`)), [t, options]);

  const onFocusCallback = useCallback(() => onFocus(keyName), [keyName, onFocus]);
  const onChangeCallback = useCallback(
    (questionId: string, value: string | number | boolean | undefined) => onChange(keyName, value),
    [keyName, onChange]
  );

  return (
    <Question questionId={keyName} questionName={t(keyName)} onFocus={onFocusCallback} disabled={readonly}>
      <RadioButtonQuestion
        key={keyName + String(value)}
        disabled={readonly}
        questionId={keyName}
        radioOptions={options}
        optionLabels={optionLabels}
        question={t(keyName)}
        initialValue={vulnerablePersonIsYes ? "yes" : value}
        labelPlacement="top"
        onChange={onChangeCallback}
        index={index}
        disabledOptions={vulnerablePersonIsYes ? ["no", "unsure"] : []}
        infoAlertText={vulnerablePersonIsYes ? t("minority_selected") : ""}
        id={undefined}
        defaultValue={undefined}
        onFocus={undefined}
        infoIcon={undefined}
      />
    </Question>
  );
};

export default DpiaRadioButtonQuestion;
