import { CustomTableLayout, TDocumentDefinitions } from "pdfmake/interfaces";

export const getPdfMaker = async () => {
  const pdfMake = await import("pdfmake/build/pdfmake");
  const { vfs } = await import("./pdfFontsLoader");

  const fonts = {
    NotoSansKR: {
      normal: "NotoSansKR-Regular.ttf",
      bold: "NotoSansKR-Bold.ttf",
      italics: "NotoSansKR-Bold.ttf"
    },
    Roboto: {
      normal: "Roboto-Regular.ttf",
      italics: "Roboto-Italic.ttf",
      bold: "Roboto-Medium.ttf",
      bolditalics: "Roboto-MediumItalic.ttf"
    }
  };

  return {
    createPdf: (
      documentDefinitions: TDocumentDefinitions,
      tableLayouts?: { [name: string]: CustomTableLayout }
      // fonts?: TFontDictionary
    ) => pdfMake.createPdf(documentDefinitions, tableLayouts, fonts, vfs)
  };
};
