import AccordionMultiField from "../../../../components/AccordionMultiField/AccordionMultiField";
import TextEditor from "../utils/TextEditor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiskRatingAlert } from "../../risks/assessments/RiskRating";
import {
  awaitRiskVersion,
  copyRiskAsSpecific,
  createRisk,
  risksAvailableForPAorAssets
} from "../../../handlers/risksHandler";
import MultiAutocomplete from "../../../../components/MultiAutocomplete/MultiAutocomplete";
import { v4 } from "uuid";
import uniq from "lodash-es/uniq";
import isEqual from "lodash-es/isEqual";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import { RiskDialog } from "../../risks/RiskDialog";
import { tDeletedEntry } from "../../../handlers/dataTypeTranslatorHandler";
import Question from "components/Question/Question";
import { RiskDTO } from "../../../api/riskApi";
import useSWR from "swr";
import { getPAsForOverview } from "../../../api/paApi";
import { getAssetOverview } from "../../../api/assetApi";
import { useUserDepartments } from "../../../contexts/department-context";
import { Box, Button, CircularProgress, Grid, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { RiskProvider } from "../../../contexts/risk-context";
import { isAxiosErrorWithCode } from "../../../api/axios/axiosErrorHandler";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";

const RiskIdAccordion = ({
  id,
  riskId,
  selectableRisks,
  allRisks,
  type,
  onChangeCallback,
  onFocusCallback,
  onDeleteCallback,
  onEditCallback,
  onCreateCallback,
  onCopyCallback,
  disabled
}: {
  id: string;
  riskId: string;
  selectableRisks: RiskDTO[];
  allRisks: RiskDTO[];
  type: "processing-activity" | "asset";
  onChangeCallback: (params: { id: string; riskId: string }) => void;
  onFocusCallback?: (params: { id: string; riskId: string }) => void;
  onDeleteCallback?: (params: { id: string; riskId: string }) => void;
  onEditCallback?: (params: { id: string; riskId: string }) => void;
  onCreateCallback?: (params: { id: string; title: string }) => void;
  onCopyCallback?: (params: { id: string; riskId: string }) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const { catchAsSnackbar } = useErrorSnackbar();
  const { isPartOfUserDepartments } = useUserDepartments();
  const [initiallyExpanded] = useState(!riskId);

  const [isLoading, setIsLoading] = useState(false);
  const [risk, setRisk] = useState<RiskDTO | null>(null);
  useEffect(() => {
    const risk = allRisks.find(risk => risk.id === riskId);
    setRisk(risk || null);
    setIsLoading(false);
  }, [riskId, allRisks]);

  const [selectableRiskIds, setSelectableRiskIds] = useState<string[]>([]);
  useEffect(() => {
    setSelectableRiskIds(selectableRisks.map(risk => risk.id));
  }, [selectableRisks]);

  const editRisk = useCallback(async () => {
    try {
      setIsLoading(true);
      await onEditCallback?.({ id, riskId });
    } catch (error) {
      setIsLoading(false);
      catchAsSnackbar("failed to edit risk")(error);
    }
    setIsLoading(false);
  }, [id, onEditCallback, riskId, catchAsSnackbar]);

  const onFocus = useCallback(() => {
    onFocusCallback?.({ id, riskId });
  }, [id, onFocusCallback, riskId]);

  const onDelete = useCallback(() => {
    onDeleteCallback?.({ id, riskId });
  }, [id, onDeleteCallback, riskId]);

  const onChange = useCallback(
    newRiskId => {
      const optionIsInRiskId = selectableRiskIds.includes(newRiskId);
      if (!optionIsInRiskId) {
        return;
      }
      onChangeCallback?.({ id, riskId: newRiskId });
    },
    [selectableRiskIds, onChangeCallback, id]
  );

  const copyRisk = useCallback(async () => {
    try {
      setIsLoading(true);
      await onCopyCallback?.({ id, riskId });
    } catch (error) {
      setIsLoading(false);
      catchAsSnackbar("failed to copy risk")(error);
    }
    setIsLoading(false);
  }, [id, onCopyCallback, riskId, catchAsSnackbar]);

  const getRiskName = useCallback(
    riskId => {
      const risk = selectableRisks.find(risk => risk.id === riskId);
      if (!risk?.title) {
        return riskId;
      }

      return risk.title;
    },
    [selectableRisks]
  );

  const updateRiskOption = useCallback(
    async options => {
      const newlyAddedRiskOption = options[options.length - 1];

      try {
        setIsLoading(true);
        await onCreateCallback?.({ id, title: newlyAddedRiskOption });
      } catch (error) {
        setIsLoading(false);
        catchAsSnackbar("failed to create new risk")(error);
      }
      setIsLoading(false);
    },
    [onCreateCallback, id, catchAsSnackbar]
  );

  const editButtons = useMemo(() => {
    if (!risk?.type) {
      return <></>;
    }

    const normalEditButton = (
      <Tooltip
        title={t(
          type === "processing-activity"
            ? "dpia_four_four_page:edit_process_risk_hint"
            : "dpia_four_four_page:edit_asset_risk_hint"
        )}
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={editRisk}
          disabled={isLoading || disabled}
        >
          {t(
            type === "processing-activity"
              ? "dpia_four_four_page:edit_process_risk"
              : "dpia_four_four_page:edit_asset_risk"
          )}
        </Button>
      </Tooltip>
    );
    const normalEditButtonButWithEditGeneralText = (
      <Tooltip title={t("dpia_four_four_page:edit_general_risk_hint")}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={editRisk}
          disabled={isLoading || disabled}
        >
          {t("dpia_four_four_page:edit_general_risk")}
        </Button>
      </Tooltip>
    );
    const copyAsProcessSpecificEditButton = (
      <Tooltip title={t("dpia_four_four_page:copy_as_process_risk_hint")}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={copyRisk}
          disabled={isLoading || disabled}
        >
          {t("dpia_four_four_page:copy_as_process_risk")}
        </Button>
      </Tooltip>
    );
    const copyAsAssetSpecificEditButton = (
      <Tooltip title={t("dpia_four_four_page:copy_as_asset_risk_hint")}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={copyRisk}
          disabled={isLoading || disabled}
        >
          {t("dpia_four_four_page:copy_as_asset_risk")}
        </Button>
      </Tooltip>
    );

    return (
      <Box display="flex" gap={1}>
        {risk?.type === "general" && (
          <>
            <Box>
              {type === "processing-activity" ? copyAsProcessSpecificEditButton : copyAsAssetSpecificEditButton}
            </Box>
            <Box>{normalEditButtonButWithEditGeneralText}</Box>
          </>
        )}
        {risk?.type === "processing-activity" && <Box>{normalEditButton}</Box>}
        {risk?.type === "asset" && <Box>{normalEditButton}</Box>}
      </Box>
    );
  }, [risk?.type, t, editRisk, isLoading, disabled, copyRisk, type]);

  const [riskName, setRiskName] = useState("");
  useEffect(() => {
    if (!risk?.title) {
      setRiskName(riskId ? tDeletedEntry({ t }) : ""); // if risk id is not null, show deleted text
      return;
    }

    setRiskName(risk.title);
  }, [risk?.title, risk?.type, t, riskId]);

  const [multiAutoCompleteInput, setMultiAutoCompleteInput] = useState("");
  const onMultiAutoCompleteInputChange = useCallback(
    (event, value) => {
      // for handling case when we are selecting an id which is not in option
      if (value === riskId) {
        setMultiAutoCompleteInput(riskName);
      } else {
        setMultiAutoCompleteInput(value);
      }
    },
    [riskId, riskName]
  );
  useEffect(() => {
    if (riskName === tDeletedEntry({ t })) {
      setMultiAutoCompleteInput("");
      return;
    }

    let nameWithType = riskName;
    if (risk?.type === "general") {
      nameWithType = `${t("risks_overview:general")}: ${riskName}`;
    }

    setMultiAutoCompleteInput(nameWithType);
  }, [riskName, t, risk?.type]);

  const getRiskGroup = useCallback(
    riskId => {
      const risk = selectableRisks.find(risk => risk.id === riskId);
      if (risk?.type === "general") {
        return t("risks_overview:generalRisks");
      }
      if (risk?.type === "processing-activity") {
        return t("risks_overview:processRisks");
      }
      if (risk?.type === "asset") {
        return t("risks_overview:assetRisks");
      }

      return t("risks_overview:generalRisks");
    },
    [selectableRisks, t]
  );

  return (
    <AccordionMultiField
      id={id}
      index={id}
      key={id}
      onFocus={onFocus}
      isNewMultiFiled={initiallyExpanded}
      cancelButtonText={t("questionnaires:cancel")}
      saveButtonText={t("questionnaires:save")}
      deleteButtonText={t("dpia_four_four_page:delete_risk")}
      deleteButtonHint={t("dpia_four_four_page:delete_risk_hint")}
      deleteMultiField={onDelete}
      placeholder={t("dpia_four_four_page:add_risk_placeholder")}
      title={riskName}
      hasCancelAndSave={false}
      additionalLeftButton={editButtons}
      loading={isLoading}
      disableButton={disabled}
      field={undefined}
      titleType={undefined}
      onClickSave={undefined}
      onClickCancel={undefined}
      accordionsExpanded={undefined}
      setAccordionsExpanded={undefined}
      editButtonText={undefined}
      onClickEdit={undefined}
    >
      <Grid container spacing={3}>
        {isLoading && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isLoading && (
          <Grid item xs={12}>
            <MultiAutocomplete
              label={t("dpia_four_four_page:risk_label")}
              hasMultiSelect={false}
              freeSolo={false}
              options={selectableRiskIds}
              selected={riskId}
              updateSelected={onChange}
              getOptionLabel={getRiskName}
              groupBy={getRiskGroup}
              placeholder={""}
              id={id}
              disabled={disabled}
              updateOptions={updateRiskOption}
              addText={`${t("risks_overview:create_new_process_specific_risk")}: `}
              inputValue={multiAutoCompleteInput}
              onInputChange={onMultiAutoCompleteInputChange}
            />
          </Grid>
        )}
        {!isLoading && risk !== null && (
          <Grid item xs={12} key={`${risk.id}-description`}>
            <TextEditor
              testId={`risk-${risk.id}-description`}
              title={t("risk_general_page:description")}
              inputValue={risk.description || ""}
              disabled={true}
            />
          </Grid>
        )}
        {!isLoading && risk !== null && (
          <Grid item xs={12} key={`${risk.id}-rating`}>
            <RiskRatingAlert riskRating={risk.rating} />
          </Grid>
        )}
      </Grid>
    </AccordionMultiField>
  );
};

export const RiskIdsAccordion = ({
  riskIds,
  riskOptions: inputRiskOptions,
  title,
  type,
  onRiskIdsChange,
  documentId,
  expandedOrgUnitIds,
  newRiskOrgUnitId,
  newRiskFurtherOrgUnitIds,
  newRiskPrivacyRelevant,
  disabled
}: {
  riskIds: string[];
  riskOptions: RiskDTO[];
  title: string;
  type: "processing-activity" | "asset";
  onRiskIdsChange: (riskIds: string[]) => void;
  documentId: string;
  expandedOrgUnitIds?: string[];
  newRiskOrgUnitId?: string;
  newRiskFurtherOrgUnitIds?: string[];
  newRiskPrivacyRelevant?: boolean;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const { auth } = useAuthentication();

  const [riskOptions, setRiskOptions] = useState<RiskDTO[]>([]);
  useEffect(() => {
    setRiskOptions(inputRiskOptions || []);
  }, [inputRiskOptions]);

  const { data: processes } = useSWR(
    type === "processing-activity" ? `risk-ids-accordion-processes-${documentId}` : null,
    () => getPAsForOverview()
  );
  const { data: assets } = useSWR(type === "asset" ? `risk-ids-accordion-assets-${documentId}` : null, () =>
    getAssetOverview()
  );

  const [selectableRisks, setSelectableRisks] = useState<RiskDTO[]>([]);
  const [dataLocationsIds, setDataLocationsIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectableRisks(
      risksAvailableForPAorAssets({
        type,
        risks: riskOptions || [],
        processes: processes?.items || [],
        assets: assets?.items || [],
        currentDocumentId: documentId,
        expandedOrgUnitIds: expandedOrgUnitIds ? new Set(expandedOrgUnitIds) : null
      })
    );
    setDataLocationsIds((processes?.items || []).find(pa => pa.id === documentId)?.allDataLocationIds || []);
  }, [riskOptions, assets?.items, documentId, processes?.items, type, expandedOrgUnitIds]);

  const [riskAccordions, setRiskAccordions] = useState<
    {
      id: string;
      riskId: string | null;
      isCreatedNew?: boolean;
    }[]
  >(
    riskIds.map(riskId => ({
      id: riskId,
      riskId: riskId
    }))
  );
  useEffect(() => {
    setRiskAccordions(
      riskIds.map(riskId => ({
        id: riskId,
        riskId: riskId
      }))
    );
  }, [riskIds]);

  const addNewAccordion = useCallback(() => {
    setRiskAccordions(riskAccordions => [...riskAccordions, { id: v4(), riskId: null, isCreatedNew: true }]);
  }, []);

  const [dirty, setDirty] = useState(false);
  const onRiskIdChange = useCallback(({ id, riskId }) => {
    setRiskAccordions(riskAccordions =>
      riskAccordions.map(riskAccordion => (riskAccordion.id === id ? { id, riskId } : riskAccordion))
    );
    setDirty(true);
  }, []);

  const onRiskIdDeleted = useCallback(({ id }) => {
    setRiskAccordions(riskAccordions => riskAccordions.filter(riskAccordion => riskAccordion.id !== id));
    setDirty(true);
  }, []);

  const onRiskCreate = useCallback(
    async ({ id, title }) => {
      const riskId = await createRisk(title, {
        type: type,
        privacyRelevant: true,
        dataLocationIds: dataLocationsIds,
        orgUnitId: newRiskOrgUnitId
      });
      setRiskOptions(riskOptions => [
        ...riskOptions,
        {
          id: riskId,
          title,
          type,
          privacyRelevant: true,
          dataLocationIds: dataLocationsIds,
          orgUnitId: newRiskOrgUnitId
        } as RiskDTO
      ]);
      await awaitRiskVersion(riskId);
      setRiskAccordions(riskAccordions =>
        riskAccordions.map(riskAccordion => (riskAccordion.id === id ? { id, riskId } : riskAccordion))
      );
      setEditedRiskId(riskId);
      setDirty(true);
    },
    [type, dataLocationsIds, newRiskOrgUnitId]
  );

  const onRiskCopy = useCallback(
    async ({ id, riskId }) => {
      let copiedRisk: RiskDTO;
      try {
        copiedRisk = await copyRiskAsSpecific({
          riskId,
          type,
          orgUnitId: newRiskOrgUnitId,
          furtherOrgUnitIds: newRiskFurtherOrgUnitIds,
          privacyRelevant: newRiskPrivacyRelevant,
          ownerId: auth?.uid
        });
      } catch (error: unknown) {
        if (isAxiosErrorWithCode(error, 403)) {
          setEditedRiskId(riskId); // so that we open a dialog with no access to edit
          return;
        }
        throw error;
      }

      setRiskOptions(riskOptions => [...riskOptions, copiedRisk]);
      setRiskAccordions(riskAccordions =>
        riskAccordions.map(riskAccordion => (riskAccordion.id === id ? { id, riskId: copiedRisk.id } : riskAccordion))
      );
      setEditedRiskId(copiedRisk.id);
      setDirty(true);
    },
    [type, newRiskOrgUnitId, newRiskFurtherOrgUnitIds, newRiskPrivacyRelevant, auth?.uid]
  );

  const [editedRiskId, setEditedRiskId] = useState("");
  const onRiskEdit = useCallback(({ id, riskId }) => {
    setEditedRiskId(riskId);
  }, []);
  const onRiskEditClose = useCallback(async (id: string, risk: RiskDTO | null) => {
    setRiskOptions(riskOptions => riskOptions.map(it => (it.id === id && risk ? risk : it)));
    setEditedRiskId("");
  }, []);

  const [currentRiskIds, setCurrentRiskIds] = useState<string[]>(riskIds || []);
  useEffect(() => {
    if (!dirty) {
      return;
    }

    const uniqRiskIds = uniq(riskAccordions.map(riskAccordion => riskAccordion.riskId).flatMap(riskId => riskId || []));
    if (!isEqual(uniqRiskIds, currentRiskIds)) {
      setCurrentRiskIds(uniqRiskIds);
      onRiskIdsChange(uniqRiskIds);
    }
    setDirty(false);
  }, [riskAccordions, currentRiskIds, onRiskIdsChange, dirty]);

  return (
    <>
      {riskAccordions.map(riskAccordion => (
        <Question
          questionId={"riskId_" + riskAccordion.id}
          key={riskAccordion.id}
          questionName={title}
          disabled={disabled}
        >
          <RiskIdAccordion
            id={riskAccordion.id}
            riskId={riskAccordion.riskId || ""}
            selectableRisks={selectableRisks}
            allRisks={riskOptions || defaultRisks}
            type={type}
            onChangeCallback={onRiskIdChange}
            onDeleteCallback={onRiskIdDeleted}
            onCreateCallback={onRiskCreate}
            onEditCallback={onRiskEdit}
            onCopyCallback={onRiskCopy}
            disabled={disabled}
          />
        </Question>
      ))}
      {editedRiskId && (
        <RiskProvider
          riskId={editedRiskId}
          customLoadScreen={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
        >
          <RiskDialog open={!!editedRiskId} onClose={onRiskEditClose} />
        </RiskProvider>
      )}
      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={addNewAccordion} disabled={disabled}>
          {t("dpia_four_four_page:add_risk")}
        </Button>
      </Box>
    </>
  );
};

const defaultRisks: RiskDTO[] = [];
