import React, { Suspense, useEffect } from "react";
import AppRouter from "./router/router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, ThemeProvider as MUI4ThemeProvider } from "@material-ui/core/styles";
import mui4Theme, { mui5Theme } from "../theme/theme";
import { AuthenticationProvider } from "./handlers/authentication/authentication-context";
import { TranslatorProvider } from "./contexts/translator-context";
import { logSecurityMessage } from "../tool/securityInfo";
import { UserAndTenantDataProvider } from "./handlers/userAndTenant/user-tenant-context";
import { NavigateAwayProvider } from "../components/NavigateAway";
import { OverviewProvider } from "app/contexts/overview-context";
import { DepartmentProvider } from "app/contexts/department-context";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { GroupProvider } from "./contexts/group-context";
import { RoleProvider } from "./contexts/role-context";
import { SmartlookLoader } from "./utils/SmartlookLoader";
import { ThemeProvider as MUI5ThemeProvider } from "@mui/material/styles";

export const BaseProviders = ({ children }: { readonly children: React.ReactNode }) => {
  useEffect(() => {
    logSecurityMessage();
  }, []);

  return (
    <Suspense fallback="">
      <MUI5ThemeProvider theme={mui5Theme}>
        <MUI4ThemeProvider theme={mui4Theme}>
          <CssBaseline />
          <SnackbarProviderWithStyles>
            <TranslatorProvider>
              <NavigateAwayProvider>{children}</NavigateAwayProvider>
            </TranslatorProvider>
          </SnackbarProviderWithStyles>
        </MUI4ThemeProvider>
      </MUI5ThemeProvider>
    </Suspense>
  );
};

const App = () => {
  useEffect(() => {
    logSecurityMessage();
  }, []);

  return (
    <BaseProviders>
      <BrowserRouter>
        <AuthenticationProvider>
          <UserAndTenantDataProvider>
            <DepartmentProvider>
              <GroupProvider>
                <RoleProvider>
                  <OverviewProvider>
                    <AppRouter />
                  </OverviewProvider>
                </RoleProvider>
              </GroupProvider>
            </DepartmentProvider>
            <SmartlookLoader />
          </UserAndTenantDataProvider>
        </AuthenticationProvider>
      </BrowserRouter>
    </BaseProviders>
  );
};

const useSnackbarStyles = makeStyles(theme => ({
  // default variant
  contentRoot: {
    backgroundColor: theme.palette.primary.main
  },
  variantSuccess: {
    backgroundColor: theme.palette.success.main
  },
  variantError: {
    backgroundColor: theme.palette.error.main
  },
  variantInfo: {
    backgroundColor: theme.palette.primary.main
  },
  variantWarning: {
    backgroundColor: theme.palette.warning.main
  }
}));

const SnackbarProviderWithStyles = (props: { children: React.ReactNode }) => {
  // this can only be called when the theme is already applied, otherwise we use the color of default theme and not ours
  // previously this was declared in the root and then passed to snackbar provider, so the code runs before theme is applied
  const classes = useSnackbarStyles();
  return (
    <SnackbarProvider classes={classes} maxSnack={5}>
      {props.children}
    </SnackbarProvider>
  );
};

export default App;
