import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import { AssetShortDTO, AssetUpdateDTO } from "../types/AssetTypes";
import { createAsset, getAsset, getAssets } from "../handler/assetHandler";
import AssetDetails from "./AssetDetails";
import { LinkText } from "app/router/router-filters";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import colors from "theme/palette/colors";
import { tDeletedEntry } from "../../../handlers/dataTypeTranslatorHandler";

/* ASSET MULTISELECT CHIP */
const multiselectChipStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: theme.palette.blue[100],
    "& .MuiChip-deleteIcon": {
      color: theme.palette.blue[300],
      "&:hover": {
        color: colors.blue.blue400
      }
    }
  }
}));

type MultiSelectTagProps = {
  readonly "data-tag-index": number;
  readonly className: string;
  readonly disabled: boolean;
  readonly key: number;
  readonly onDelete: () => void;
  readonly tabIndex: number;
};

type MultiSelectChipProps = {
  readonly getTagProps: ({ index }: { index: number }) => MultiSelectTagProps;
  readonly label: string;
  readonly option: string;
  readonly index: number;
  readonly onClick: (val: string) => void;
};

const AssetsMultiSelectChip = ({ getTagProps, label, option, index, onClick }: MultiSelectChipProps) => {
  const { t } = useTranslation("asset_details");
  const cls = multiselectChipStyles();
  const stopEvent = useCallback(event => event.stopPropagation(), []);
  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      onClick(option);
    },
    [onClick, option, stopEvent]
  );
  const defaultTagProps = getTagProps({ index });
  const tagProps = { ...defaultTagProps, className: `${defaultTagProps.className} ${cls.chip}` };
  return (
    <Tooltip title={t("edit_details")}>
      <Chip {...tagProps} label={label} onClick={onClickCallback} />
    </Tooltip>
  );
};

/* ASSET MILTISELECT CHIP */
type AssetMultiselectProps = {
  readonly disabled?: boolean;
  readonly orgUnitIds?: string[] | undefined;
  readonly selectedAssetIds: string[];
  readonly onAddNew?: (val: string) => void;
  readonly onBlur?: () => void;
  readonly onChange?: (val: string[]) => void;
  readonly onFocus?: () => void;
};

const AssetsMultiSelect = ({
  disabled,
  onAddNew,
  onBlur,
  onChange,
  onFocus,
  orgUnitIds,
  selectedAssetIds
}: AssetMultiselectProps) => {
  const { t } = useTranslation("asset_details");
  const { loadSeenItemsOfUserHook } = useUserAndTenantData();

  const [currentSelectedIds, setCurrentSelectedIds] = useState<string[]>([]);
  const [availableAssets, setAvailableAssets] = useState<AssetShortDTO[]>([]);
  const [availableAssetsNameById, setAvailableAssetsNameById] = useState<{ [key: string]: string }>();
  const [selectedAssetsNameById, setSelectedAssetsNameById] = useState<{ [key: string]: string }>();
  const [assetData, setAssetData] = useState<AssetUpdateDTO | null>(null);

  useEffect(() => {
    setCurrentSelectedIds(selectedAssetIds);
  }, [selectedAssetIds]);

  const fetchData = useCallback(async () => {
    const [aAssets, sAssets] = await Promise.all([
      await getAssets({ anyOrgUnitIds: orgUnitIds }),
      await getAssets({ assetIds: selectedAssetIds })
    ]);
    if (aAssets?.items) {
      setAvailableAssets(aAssets.items);
      setAvailableAssetsNameById(
        aAssets.items.reduce((a: { [key: string]: string }, v: AssetShortDTO) => ({ ...a, [v.id]: v.name }), {})
      );
    }

    if (sAssets?.items) {
      setSelectedAssetsNameById(
        sAssets.items.reduce((a: { [key: string]: string }, v: AssetShortDTO) => ({ ...a, [v.id]: v.name }), {})
      );
    }
  }, [orgUnitIds, selectedAssetIds]);

  useEffect(() => {
    const fetch = async () => {
      fetchData();
    };
    fetch();
  }, [fetchData]);

  const showAssetDetails = useCallback(async id => {
    const data = await getAsset(id);
    if (data) {
      setAssetData(data as any);
    } else {
      // do nothing
    }
  }, []);

  /* ASSETS EDIT DIALOG */

  const closeEditAsset = useCallback(() => {
    setAssetData(null);
    fetchData();
  }, [fetchData]);

  const editAssetDialogEl = (
    <Dialog open={Boolean(assetData)} onClose={closeEditAsset} aria-labelledby="form-dialog-title" maxWidth={"xl"}>
      <Card style={{ overflow: "auto" }}>
        <CardContent>
          <Box mx={4} mt={2} mb={4}>
            <LinkText pathname={`/asset-management/${assetData?.id}/general`}>{assetData?.name}</LinkText>
          </Box>
          <Box mx={4}>
            <AssetDetails id={assetData?.id || ""} />
          </Box>
        </CardContent>
        <DialogActions>
          <Box p={2}>
            <Button variant="outlined" color="primary" onClick={closeEditAsset}>
              {t("common:close")}
            </Button>
          </Box>
        </DialogActions>
      </Card>
    </Dialog>
  );

  /* ASSETS AUTOCOMPLETE */
  const assetOptions: string[] = useMemo(() => availableAssets.flatMap(a => a.id), [availableAssets]);

  const onChangeAssetAutocomplete = useCallback(
    newValues => {
      onChange?.(newValues);
      setCurrentSelectedIds(newValues);
    },
    [onChange]
  );

  const getAvailableOptionLabel = useCallback(
    id => {
      return availableAssetsNameById?.[id] || id;
    },
    [availableAssetsNameById]
  );

  const getSelectedOptionLabel = useCallback(
    id => {
      return selectedAssetsNameById?.[id] || tDeletedEntry({ t });
    },
    [selectedAssetsNameById, t]
  );

  const renderTagsEl = useCallback(
    (value, getTagProps) =>
      value?.map((option: string, index: number) => {
        const labelFn = availableAssetsNameById?.[option] ? getAvailableOptionLabel : getSelectedOptionLabel;
        return (
          <AssetsMultiSelectChip
            key={index}
            index={index}
            getTagProps={getTagProps}
            option={option}
            label={labelFn(option)}
            onClick={showAssetDetails}
          />
        );
      }),
    [availableAssetsNameById, getAvailableOptionLabel, getSelectedOptionLabel, showAssetDetails]
  );
  const renderOptionEl = useCallback(
    (option, { selected }) => (
      <React.Fragment>
        <Checkbox color={"primary"} style={{ marginRight: 8 }} checked={selected} />
        {getAvailableOptionLabel(option)}
      </React.Fragment>
    ),
    [getAvailableOptionLabel]
  );

  const newOptionEntryAlreadyExists = useCallback(
    newAssetName => availableAssets.some(({ name }) => newAssetName === name),
    [availableAssets]
  );

  const updateOptions = useCallback(
    async options => {
      const newOptionName = options.find((newName: string) => !assetOptions.includes(newName));
      if (newOptionName) {
        onAddNew?.(newOptionName);
        const newId: string = await createAsset({ name: newOptionName });
        await loadSeenItemsOfUserHook();

        if (newId) {
          const newValues: string[] = [...new Set([...currentSelectedIds, newId])];
          onChange?.(newValues);
          setCurrentSelectedIds(newValues);
          showAssetDetails(newId);
        }
      }
    },
    [assetOptions, currentSelectedIds, loadSeenItemsOfUserHook, onAddNew, onChange, showAssetDetails]
  );

  const { auth } = useAuthentication();
  const userHasAssetWritePermission = auth?.permissions?.some(
    permission => permission === "asset_write_org" || permission === "asset_write_all"
  );

  const autocompleteEl = (
    <MultiAutocomplete<string, true, false, false>
      addText={userHasAssetWritePermission ? t("add_text") : undefined}
      disableClearable={false}
      disabled={disabled}
      getOptionLabel={getAvailableOptionLabel}
      hasMultiSelect={true}
      id={"assignedAssets"}
      label={t("asset_details:search")}
      newOptionEntryAlreadyExists={newOptionEntryAlreadyExists}
      onBlur={onBlur}
      onFocus={onFocus}
      options={assetOptions}
      placeholder={t("search")}
      renderOption={renderOptionEl}
      renderTags={renderTagsEl}
      selected={currentSelectedIds}
      updateOptions={updateOptions}
      updateSelected={onChangeAssetAutocomplete}
    />
  );

  return (
    <>
      {autocompleteEl}
      {editAssetDialogEl}
    </>
  );
};

export default AssetsMultiSelect;
