import { createApi, deleteApi, getManyApi, getSingleApi, updateApi } from "../../../api/assetApi";
import { AssetCreateDTO, AssetShortDTO, AssetUpdateDTO } from "../types/AssetTypes";
import { isAxiosErrorWithCode } from "../../../api/axios/axiosErrorHandler";

export type AssetFilterProps = {
  readonly assetIds?: string[];
  readonly orgUnitIds?: string[];
  readonly mainOrgUnitId?: string | undefined;
  readonly anyOrgUnitIds?: string[];
  readonly statuses?: string[];
  readonly resourceAssetTypeIds?: string[];
  readonly dataLocationIds?: string[];
  readonly riskIds?: string[];
  readonly externalRecipientIds?: string[];
  readonly labelIds?: string[];
  readonly aiTechDocIds?: string[];
};

export interface AssetShortProps {
  readonly id: string;
  readonly name: string;
  readonly status: string;
  readonly mainOrgUnitId: string;
}

export const createAsset = async (payload: AssetCreateDTO): Promise<string> => {
  return await createApi(payload);
};

export const getAsset = async (id: string) => {
  try {
    return await getSingleApi(id);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const getAssets = async (filter: AssetFilterProps = {}) => {
  try {
    return await getManyApi(filter);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const getAssetsWithAdditionalIDs = async (filter: AssetFilterProps, additionalIDs: string[]) => {
  const noNeedForAdditionalQuery = additionalIDs.length === 0 || Object.keys(filter).length === 0;
  const [assetsWithFilter, assetsWithAdditionalIDs] = await Promise.all([
    getAssets(filter),
    noNeedForAdditionalQuery
      ? Promise.resolve({
          items: []
        })
      : getAssets({ assetIds: additionalIDs })
  ]);
  const result: AssetShortDTO[] = [];
  const addedIDs = new Set<string>();
  for (const asset of assetsWithFilter.items) {
    result.push(asset);
    addedIDs.add(asset.id);
  }
  for (const asset of assetsWithAdditionalIDs.items) {
    if (!addedIDs.has(asset.id)) {
      result.push(asset);
    }
  }
  return {
    items: result
  };
};

export const updateAsset = async (id: string, asset: AssetUpdateDTO) => {
  try {
    return await updateApi(id, asset);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const deleteAsset = async (id: string) => {
  try {
    await deleteApi(id);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};
