import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { isUndefined, omitBy } from "lodash-es";
import isEqual from "lodash-es/isEqual";
import {
  RiskDTO,
  RiskPayloadDTO,
  TreatmentDTO,
  updateRiskAssessmentApi,
  updateRiskAssessmentSettingApi,
  updateRiskTreatmentApi
} from "../api/riskApi";
import DocMetaView from "../../components/DocMetaView/DocMetaView";
import DocView from "../../components/DocView/DocView";
import { CircularProgress } from "@material-ui/core";
import { awaitRiskVersion, getRisk, incrementVersion, RiskVersionError, updateRisk } from "../handlers/risksHandler";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export const RiskContext = createContext<{
  riskId: string;
  risk: RiskDTO;
  latestRisk: RiskDTO | null;
  loading: boolean;
  initialized: boolean;
  isMissing: boolean;
  reloadHook: () => Promise<RiskDTO>;
  reloadToVersionHook: (versions: {
    riskVersion?: number;
    assessmentsVersion?: { [phaseId: string]: { [protectionObjectiveId: string]: number } };
    treatmentVersion?: number;
  }) => Promise<RiskDTO>;
  updateBasicInfoHook: (input: Partial<RiskPayloadDTO>) => Promise<void>;
  toggleIndividualAssessmentHook: (phaseId: "first" | "second", enabled: boolean) => Promise<void>;
  updateAssessmentHook: (
    phaseId: "first" | "second",
    protectionObjectiveId: string,
    input: { occurrenceId?: string; damageExtendId?: string; impactDescription?: string; reasonDescription?: string }
  ) => Promise<void>;
  updateAllIndividualAssessmentsOccurrence: (
    phaseId: "first" | "second",
    input: { occurrenceId?: string; reasonDescription?: string }
  ) => Promise<void>;
  updateTreatmentHook: (input: { type?: string; measureIds?: string[]; description?: string }) => Promise<void>;
}>({
  riskId: "",
  isMissing: false,
  risk: {
    id: "",
    title: "",
    type: "general",
    permission: "read",
    orgUnitId: "",
    furtherAffectedOrgUnitIds: [],
    description: "",
    ownerUID: "",
    privacyRelevant: false,
    riskSourceIds: [],
    riskAssetIds: [],
    dataLocationIds: [],
    protectionObjectiveIds: [],
    implementedMeasureIds: [],
    labelIds: [],
    tenantRiskId: 0,
    rating: null,
    ratingLevel: null,
    treatment: null,
    assessments: {
      first: {
        individualAssessmentEnabled: false,
        rating: null,
        version: 0
      },
      second: {
        individualAssessmentEnabled: false,
        rating: null,
        version: 0
      }
    },
    version: 0,
    creatorUID: "",
    updaterUID: "",
    createdAt: "",
    updatedAt: ""
  },
  latestRisk: null,
  loading: true,
  initialized: false,
  reloadHook: async () => Promise.reject(),
  reloadToVersionHook: async () => Promise.reject(),
  updateBasicInfoHook: async () => {},
  toggleIndividualAssessmentHook: async () => {},
  updateAllIndividualAssessmentsOccurrence: async () => {},
  updateAssessmentHook: async () => {},
  updateTreatmentHook: async () => {}
});

export interface RiskProviderProps {
  riskId: string;
  customLoadScreen?: React.ReactNode;
  children?: React.ReactNode;
}

export const RiskProvider = ({ children, riskId, customLoadScreen }: RiskProviderProps) => {
  const swrKey = riskId ? ["risk", riskId] : null;
  const { data: risk, isLoading, isValidating } = useSWR(swrKey, () => getRisk(riskId));

  // this is just dirty title so that everyone can update parent for the title easier
  const [latestRisk, setLatestRisk] = useState<RiskDTO | null>(null);
  useEffect(() => {
    setLatestRisk(risk || null);
  }, [risk]);

  const { trigger: mutateToVersion } = useSWRMutation(
    swrKey,
    (
      url,
      {
        arg
      }: {
        arg: [
          string,
          {
            riskVersion?: number;
            assessmentsVersion?: Record<"first" | "second", Record<string, number>>;
            treatmentVersion?: number;
          }
        ];
      }
    ) => {
      const [riskId, { riskVersion, treatmentVersion, assessmentsVersion }] = arg;
      return awaitRiskVersion(riskId, { riskVersion, assessmentsVersion, treatmentVersion });
    },
    {
      revalidate: false,
      populateCache: true
    }
  );

  const [isMissing, setIsMissing] = useState(false);
  useEffect(() => {
    if (!isLoading && risk === null) {
      setIsMissing(true);
    }

    return () => {
      setIsMissing(false);
    };
  }, [isLoading, risk]);

  const reloadToVersionHook = useCallback(
    ({ riskVersion, assessmentsVersion, treatmentVersion }) => {
      return mutateToVersion([riskId, { riskVersion, assessmentsVersion, treatmentVersion }]);
    },
    [mutateToVersion, riskId]
  );

  const retryWithLatestRisk = useCallback(
    async (actionFn: (risk: RiskDTO) => Promise<RiskDTO>, name?: string, debugPayload?: unknown) => {
      let currentRisk = await getRisk(riskId);
      const maxTries = 3;
      let tryCount = 1;

      const applyChange = async () => {
        try {
          return await actionFn(currentRisk!);
        } catch (error) {
          if (!(error instanceof RiskVersionError) || tryCount >= maxTries) {
            throw error;
          }
          tryCount += 1;
          console.warn(`${name || "anonymous"} concurrent edit. ${tryCount} attempt.`, debugPayload);
          currentRisk = await getRisk(riskId);
          return await applyChange();
        }
      };

      return await applyChange();
    },
    [riskId]
  );

  const nonUndefinedPayload = (input: any) => {
    const withoutUndefined = omitBy(input || {}, isUndefined);
    if (Object.keys(withoutUndefined).length === 0) {
      return null;
    }
    return withoutUndefined;
  };

  const updateBasicInfoHook = useCallback(
    async (payload: Partial<RiskPayloadDTO> = {}) => {
      const updatePayload = nonUndefinedPayload(payload);
      if (!updatePayload) {
        return;
      }

      // just for local display
      setLatestRisk(risk =>
        risk
          ? { ...risk, ...updatePayload }
          : ({
              ...updatePayload
            } as any)
      );

      await retryWithLatestRisk(
        async risk => {
          const changed = Object.entries(updatePayload).some(([key, value]) => !isEqual((risk as any)[key], value));
          if (!changed) {
            return risk;
          }

          await updateRisk(riskId, risk.version, updatePayload);
          return reloadToVersionHook({ riskVersion: incrementVersion(risk.version) });
        },
        "update-basic",
        updatePayload
      );
    },
    [retryWithLatestRisk, riskId, reloadToVersionHook]
  );

  const toggleIndividualAssessmentHook = useCallback(
    async (phaseId: "first" | "second", enabled: boolean) => {
      if (enabled === undefined || enabled === null) {
        return;
      }

      await retryWithLatestRisk(
        async risk => {
          const existingValue = risk.assessments[phaseId].individualAssessmentEnabled;
          if (enabled === existingValue) {
            return risk;
          }

          await updateRiskAssessmentSettingApi(riskId, phaseId, risk.version, {
            individualAssessmentEnabled: enabled
          });
          return reloadToVersionHook({ riskVersion: incrementVersion(risk.version) });
        },
        "toggle-individual-assessment",
        enabled
      );
    },
    [retryWithLatestRisk, riskId, reloadToVersionHook]
  );

  const updateAssessmentHook = useCallback(
    async (
      phaseId: "first" | "second",
      protectionObjectiveId: string,
      {
        occurrenceId,
        damageExtendId,
        impactDescription,
        reasonDescription
      }: {
        occurrenceId?: string | null;
        damageExtendId?: string | null;
        impactDescription?: string | null;
        reasonDescription?: string | null;
      }
    ) => {
      const updatePayload = nonUndefinedPayload({
        occurrenceId,
        damageExtendId,
        impactDescription,
        reasonDescription
      });
      if (!updatePayload) {
        return;
      }

      await retryWithLatestRisk(
        async risk => {
          let existingAssessment;
          if (protectionObjectiveId === "main") {
            existingAssessment = risk.assessments[phaseId].combinedAssessment || {};
          } else {
            existingAssessment =
              risk.assessments[phaseId]?.individualAssessments?.find(
                assessment => assessment.protectionObjectiveId === protectionObjectiveId
              ) || {};
          }

          const changed = Object.entries(updatePayload).some(
            ([key, value]) => !isEqual((existingAssessment as any)[key], value)
          );
          if (!changed) {
            return risk;
          }

          let autoOccurrenceOrDamageExtend = {};
          if (
            !existingAssessment.occurrenceId &&
            !existingAssessment.damageExtendId &&
            (updatePayload.occurrenceId || updatePayload.damageExtendId)
          ) {
            autoOccurrenceOrDamageExtend = {
              occurrenceId: "probability-low",
              damageExtendId: "damage-low"
            };
          }

          await updateRiskAssessmentApi(riskId, phaseId, protectionObjectiveId, existingAssessment.version || 0, {
            ...autoOccurrenceOrDamageExtend,
            ...updatePayload
          });
          return reloadToVersionHook({
            assessmentsVersion: {
              [phaseId]: { [protectionObjectiveId]: incrementVersion(existingAssessment?.version) }
            }
          });
        },
        "update-assessment",
        { ...updatePayload, phaseId, protectionObjectiveId }
      );
    },
    [retryWithLatestRisk, riskId, reloadToVersionHook]
  );

  const updateAllIndividualAssessmentsOccurrence = useCallback(
    async (
      phaseId: "first" | "second",
      {
        occurrenceId,
        reasonDescription
      }: {
        occurrenceId?: string | null;
        reasonDescription?: string | null;
      }
    ) => {
      if (occurrenceId === undefined && reasonDescription === undefined) {
        return;
      }

      await retryWithLatestRisk(
        async risk => {
          const protectionObjectiveIds = risk.protectionObjectiveIds;

          const batchUpdates = protectionObjectiveIds
            .map(protectionObjectiveId => {
              const existingAssessment = risk.assessments[phaseId]?.individualAssessments?.find(
                assessment => assessment.protectionObjectiveId === protectionObjectiveId
              );
              return { protectionObjectiveId, existingAssessment };
            })
            .map(({ protectionObjectiveId, existingAssessment }) => {
              const updatePromise = updateRiskAssessmentApi(
                riskId,
                phaseId,
                protectionObjectiveId,
                existingAssessment?.version || 0,
                {
                  occurrenceId,
                  reasonDescription,
                  // set to low if empty, otherwise undefined for no change
                  damageExtendId: occurrenceId && !existingAssessment?.damageExtendId ? "damage-low" : undefined
                }
              );
              return {
                protectionObjectiveId,
                existingAssessment,
                updatePromise,
                expectedVersion: incrementVersion(existingAssessment?.version)
              };
            });

          await Promise.all(batchUpdates.map(update => update.updatePromise));

          const expectedAssessmentsVersions = batchUpdates.reduce(
            (versions, { protectionObjectiveId, expectedVersion }) => ({
              ...versions,
              [protectionObjectiveId]: expectedVersion
            }),
            {}
          );
          return reloadToVersionHook({ assessmentsVersion: { [phaseId]: expectedAssessmentsVersions } });
        },
        "update-all-occurrences",
        occurrenceId
      );
    },
    [retryWithLatestRisk, reloadToVersionHook, riskId]
  );

  const updateTreatmentHook = useCallback(
    async ({ type, measureIds, description }) => {
      const updatePayload = nonUndefinedPayload({
        type,
        measureIds,
        description
      });
      if (!updatePayload) {
        return;
      }

      await retryWithLatestRisk(
        async risk => {
          const existingTreatment: Partial<TreatmentDTO> = risk.treatment || {};
          const changed = Object.entries(updatePayload).some(
            ([key, value]) => !isEqual((existingTreatment as any)[key], value)
          );
          if (!changed) {
            return risk;
          }

          await updateRiskTreatmentApi(riskId, existingTreatment.version || 0, updatePayload);
          return reloadToVersionHook({ treatmentVersion: incrementVersion(existingTreatment?.version) });
        },
        "update-treatment",
        updatePayload
      );
    },
    [retryWithLatestRisk, reloadToVersionHook, riskId]
  );

  const reloadHook = useCallback(async () => {
    const riskDTO = await reloadToVersionHook(riskId);
    if (!riskDTO) {
      throw new Error("Risk not found");
    }
    return riskDTO;
  }, [reloadToVersionHook, riskId]);

  return (
    <RiskContext.Provider
      value={{
        initialized: !isLoading,
        loading: isValidating,
        isMissing,
        riskId,
        risk: risk || ({} as any),
        latestRisk: latestRisk,
        reloadHook,
        reloadToVersionHook,
        updateBasicInfoHook,
        toggleIndividualAssessmentHook,
        updateAssessmentHook,
        updateAllIndividualAssessmentsOccurrence,
        updateTreatmentHook
      }}
    >
      {isLoading
        ? customLoadScreen || (
            <DocMetaView
              docViewContent={
                <DocView>
                  <CircularProgress color="inherit" />
                </DocView>
              }
            />
          )
        : children}
    </RiskContext.Provider>
  );
};
export const useRisk = () => useContext(RiskContext);
