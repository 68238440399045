import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.cookieBoxUrl}/api/websites`
});

export async function triggerScan(websiteId: string): Promise<void> {
  await axiosInstance.post(`/${websiteId}/scans`);
}
