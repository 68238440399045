import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  pagination: {
    marginTop: theme.spacing(2),
    justifyContent: "center",
    display: "flex"
  }
}));

export default function BasicPagination({ elementsPerPage, numberElements, page, setPage }) {
  const classes = useStyles();

  return (
    <Pagination
      classes={{ root: classes.pagination }}
      className={classes.pagination}
      color="primary"
      page={page}
      count={Math.ceil(numberElements / elementsPerPage)} // calculate number pages
      onChange={(event, value) => {
        setPage(value);
      }}
    />
  );
}
