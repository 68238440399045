import useSWR from "swr";
import { getPAsForOverview, ProcessFilterOptionsQueryParam, ProcessingActivityOverviewDTO } from "../app/api/paApi";

export function useUserProcesses(params?: Partial<ProcessFilterOptionsQueryParam>) {
  const { data, isValidating, mutate, isLoading } = useSWR(["userProcesses", params], () =>
    getPAsForOverview(params || {})
  );

  return {
    processesLoaded: !isValidating && !!data,
    processes: data?.items || emptyProcesses,
    data,
    isLoading,
    isValidating,
    mutate
  };
}

const emptyProcesses: ProcessingActivityOverviewDTO[] = [];
