import { createTomApi, getTomApi, TomModelDTO } from "../../../api/tomApi";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Dialog, DialogContent, FormControlLabel } from "@mui/material";
import { Checkbox, CircularProgress, Typography } from "@material-ui/core";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import Button from "@material-ui/core/Button";
import { TomContent, TomModalData } from "./TomModal";

export interface CreateTOMModalProps {
  readonly docOrgUnitIds?: string[];
  readonly additionalData?: Partial<TomModelDTO>;
  readonly onCreated: (data: TomModelDTO) => void;
  readonly onCancel: () => void;
  readonly open: boolean;
  readonly initialName?: string;
}

export const CreateTOMModal = ({
  open,
  onCancel,
  onCreated,
  docOrgUnitIds,
  additionalData,
  initialName
}: CreateTOMModalProps) => {
  const { t } = useTranslation("tom_description_tab");

  const [data, setData] = useState<TomModalData>({
    name: "",
    status: "PLANNED",
    statusDate: null,
    description: "",
    protectionObjectiveIds: [],
    labelIds: []
  });
  const [processSpecific, setProcessSpecific] = useState(false);
  const onProcessSpecificChanged = useCallback((event: React.SyntheticEvent, checked: boolean) => {
    setProcessSpecific(checked);
  }, []);
  const [saving, setSaving] = useState(false);

  const createTOMCallback = useCallback(async () => {
    setSaving(true);
    try {
      const createdTOMId = await createTomApi(data.name || "", {
        ...(additionalData || {}),
        status: data.status,
        statusDate: data.statusDate,
        description: data.description,
        protectionObjectiveIds: data.protectionObjectiveIds,
        labelIds: data.labelIds,
        processSpecific,
        furtherAffectedOrgUnitIds: docOrgUnitIds
      });
      onCreated(await getTomApi(createdTOMId));
    } catch (e: unknown) {
      setSaving(false);
      throw e;
    }
    setSaving(false);
  }, [
    additionalData,
    data.description,
    data.labelIds,
    data.name,
    data.protectionObjectiveIds,
    data.status,
    data.statusDate,
    docOrgUnitIds,
    onCreated,
    processSpecific
  ]);

  useEffect(() => {
    if (open) {
      setData({
        name: initialName || "",
        status: additionalData?.status || "PLANNED",
        statusDate: additionalData?.statusDate || null,
        description: additionalData?.description || "",
        protectionObjectiveIds: additionalData?.protectionObjectiveIds || [],
        labelIds: additionalData?.labelIds || []
      });
      setProcessSpecific(false);
      setSaving(false);
    }
  }, [
    additionalData?.description,
    additionalData?.labelIds,
    additionalData?.protectionObjectiveIds,
    additionalData?.status,
    additionalData?.statusDate,
    initialName,
    open
  ]);

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
      <DialogContent>
        <Typography variant="h5" component="div">
          {t("dpia_four_four_page:create_measure")}
        </Typography>
        <CustomAlert severity="info">{t("dpia_four_four_page:alert_creating_measure")}</CustomAlert>
        <TomContent data={data} onDataChanged={setData} docOrgUnitIds={docOrgUnitIds} />
        <Box mb={4}>
          <FormControlLabel
            control={<Checkbox />}
            label={t("dpia_four_four_page:checkbox_process_specific")}
            color="primary"
            id={"processSpecificCheckbox"}
            checked={processSpecific}
            onChange={onProcessSpecificChanged}
          />
        </Box>
        <Box display="flex" gap={1} justifyContent="space-between">
          <Button disabled={saving} variant="outlined" color="primary" onClick={onCancel}>
            {t("dpia_four_four_page:cancel")}
          </Button>
          <Button disabled={saving} variant="contained" color="primary" onClick={createTOMCallback}>
            {saving && (
              <Box mr={1}>
                <CircularProgress color="inherit" size={14} />
              </Box>
            )}
            {t("questionnaires:add_pg")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
