import { Box, Button, Dialog, DialogContent } from "@material-ui/core";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import LoginLogo from "../assets/images/caralegal_logo/with_text/caralegal_logotype_neutral.svg?img";
import Typography from "@material-ui/core/Typography";

export const NavigateAwayModal = ({
  onConfirm,
  open,
  onClose
}: {
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullScreen={true}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "85%" }}>
          <Box sx={{ width: 515 }}>
            <Box>
              <LoginLogo alt="Logo" style={{ width: "100%" }} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flex: "1 1 auto",
                  height: 199
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box mb={2}>
                    <Typography variant="h4" component="p">
                      {t("navigateAway:title")}
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h4" component="p">
                      {t("navigateAway:subtitle")}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Button variant="outlined" color="primary" onClick={onClose}>
                    {t("common:goback")}
                  </Button>
                  <Box m={1} />
                  <Button variant="contained" color="primary" onClick={onConfirm}>
                    {t("common:continue")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export interface NavigateAwayContextType {
  readonly link: string;
  readonly onCancel: () => void;
  readonly setLink: (input: string) => void;
}

export const NavigateAwayContext = createContext<NavigateAwayContextType>({
  link: "",
  onCancel: () => {
    /* empty */
  },
  setLink: (link: string) => {
    /* empty */
  }
});

export const NavigateAwayProvider = function ({ children }: { children: React.ReactNode }) {
  const [link, setLink] = useState("");
  const onCancel = useCallback(() => {
    setLink("");
  }, []);

  const onConfirm = useCallback(() => {
    window.open(link);
    setLink("");
  }, [link]);

  const value: NavigateAwayContextType = {
    link,
    onCancel,
    setLink
  };
  return (
    <NavigateAwayContext.Provider value={value}>
      {children}
      <NavigateAwayModal onConfirm={onConfirm} open={!!link} onClose={onCancel} />
    </NavigateAwayContext.Provider>
  );
};

export const useAwayNavigation = (): NavigateAwayContextType => useContext(NavigateAwayContext);
