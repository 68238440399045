import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, InputAdornment, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OverviewSort, SortingItemProps } from "./OverviewSort";
import { OverviewFilter } from "./OverviewFilter";
import OverviewAddButton, { OverviewAddButtonActionProps } from "./OverviewAddButton";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import OverviewPagesMenu from "./OverviewPagesMenu";
import { OverviewSelectAction } from "./OverviewSelectActions";
import { COLLECTION_TYPES } from "app/collections";
import { ExportButton, OverviewExport } from "./OverviewExport";
import { OverviewContextFilter, OverviewContextSort } from "app/contexts/overview-context";
import { FilterItemProps, OverviewItemsCount } from "../controllers/overviewBaseController";
import OverviewMarkAllAsReadButton from "./OverviewMarkAllAsReadButton";
import OverviewIconButton from "./OverviewIconButton";
import { OverviewSelectAllCheckbox } from "./OverviewSelectAllCheckbox";

const useStyles = makeStyles(theme => ({
  searchInput: {
    "& input": {
      padding: theme.spacing(2)
    }
  },
  emptyButton: {
    "&:hover": {
      backgroundColor: theme.palette.blue[50],
      color: theme.palette.primary.main
    }
  }
}));

export interface OverviewToolbarActionProps {
  readonly action: string;
  readonly title?: string;
  readonly tooltip?: string;
  readonly icon?: React.ReactNode;
  readonly dropdownActions?: {
    readonly title: string;
    readonly onHandle: (val?: any) => void;
  }[];
  readonly disabled?: boolean;
  readonly onHandle?: (val: any) => void;
}

export interface OverviewPageProps {
  readonly title: string;
  readonly route: string;
  readonly current: boolean;
  readonly unseenCount?: number;
}

export interface OverviewToolbarProps {
  readonly collection: COLLECTION_TYPES;
  readonly searchTerm: string;
  readonly sort: OverviewContextSort;
  readonly sortings: SortingItemProps[];
  readonly filter: OverviewContextFilter;
  readonly filters: FilterItemProps[];
  readonly actions?: OverviewToolbarActionProps[];
  readonly addActions?: OverviewAddButtonActionProps[];
  readonly selectionActions?: OverviewToolbarActionProps[];
  readonly pages?: OverviewPageProps[];
  readonly itemsCount?: OverviewItemsCount | null;
  readonly checkedItems: any[];
  readonly hideSearch?: boolean;
  readonly hideCount?: boolean;
  readonly toolbarHeader?: string;
  readonly toolbarAddControl?: React.ReactNode;
  readonly showSelectAll?: boolean;
  readonly numberSelectableItems?: number;
  readonly numberTotalItems?: number;
  readonly numberTotalSelectableItems: number;
  readonly isPaginatedApi?: boolean;

  readonly onAdd: (val: any) => void;
  readonly onSearch: (val: string) => void;
  readonly onSort: (val: SortingItemProps) => void;
  readonly onFilter: (val: object) => void;
  readonly onReset: (val: string) => void;
  readonly onPageChange?: (val: OverviewPageProps) => void;
  readonly onResetChecked: () => void;
  readonly onDuplicate: (val: string[] | undefined) => void;
  readonly onDelete: () => void;
  readonly onExport: (format: string) => void;
  readonly onExportAll: (format: string) => void;
  readonly onMarkAllAsRead: () => Promise<void>;
  readonly onInlineButtonClick?: () => void;
  readonly onSelectAllCheckbox: (checked: boolean) => void;
  readonly onSelectAllText: () => void;
  readonly onToolbarSelectAllHover: (checked: boolean) => void;
}

export const OverviewPagesToolbar = ({
  collection,
  searchTerm,
  sort,
  sortings,
  filter,
  filters,
  actions,
  addActions,
  selectionActions,
  pages,
  itemsCount,
  numberTotalSelectableItems,
  checkedItems,
  hideSearch,
  hideCount,

  onAdd,
  onSearch,
  onSort,
  onFilter,
  onReset,
  onPageChange,
  onResetChecked,
  onDuplicate,
  onDelete,
  onExport,
  onExportAll,
  onMarkAllAsRead,
  onToolbarSelectAllHover,
  showSelectAll,
  onSelectAllCheckbox
}: OverviewToolbarProps) => {
  const { t } = useTranslation("overview");
  const { t: tTaskDetails } = useTranslation("task_details");
  const cls = useStyles();
  const [searchtext, setSearchText] = useState(searchTerm || "");
  const onChangeSearchText = useCallback(event => {
    setSearchText(event.target.value);
  }, []);
  useEffect(
    function triggerSearch() {
      const timeoutID = setTimeout(() => {
        onSearch(searchtext);
      }, 100);
      return () => clearTimeout(timeoutID);
    },
    [searchtext, onSearch]
  );
  const clearSearch = useCallback(() => {
    setSearchText("");
    onSearch("");
  }, [onSearch]);

  const searchEl = useMemo(() => {
    return hideSearch ? (
      <></>
    ) : (
      <Box width={240} mr={2}>
        <TextField
          fullWidth
          id="overview-search-input"
          variant="outlined"
          value={searchtext}
          onChange={onChangeSearchText}
          placeholder={t("search_placeholder")}
          className={cls.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="disabled" />
              </InputAdornment>
            ),
            endAdornment: searchtext && (
              <InputAdornment position="end">
                <Tooltip title={tTaskDetails("clear")}>
                  <IconButton onClick={clearSearch} size="small" aria-label="toggle password visibility">
                    <Close />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Box>
    );
  }, [clearSearch, cls.searchInput, hideSearch, onChangeSearchText, searchtext, t, tTaskDetails]);

  const actionsEl = actions
    ?.filter(a => a.action !== "add")
    .map(a => {
      if (a.action === "sort") {
        return (
          <React.Fragment key={a.action}>
            <OverviewSort sortings={sortings} sort={sort} onSort={onSort} onReset={onReset} />
            <Box mr={1} />
          </React.Fragment>
        );
      } else if (a.action === "filter") {
        return (
          <React.Fragment key={a.action}>
            <OverviewFilter filters={filters} filter={filter} onFilter={onFilter} onReset={onReset} />
            <Box mr={1} />
          </React.Fragment>
        );
      } else if (a.action === "export-all") {
        return (
          <React.Fragment key={a.action}>
            <OverviewExport onExport={onExportAll} />
            <Box mr={1} />
          </React.Fragment>
        );
      } else if (a.action === "export-xlsx") {
        return (
          <React.Fragment key={a.action}>
            <ExportButton handleClick={onExportAll} />
          </React.Fragment>
        );
      } else if (a.action === "mark-all-as-read") {
        return (
          <React.Fragment key={a.action}>
            <OverviewMarkAllAsReadButton collection={collection} onMarkAllAsRead={onMarkAllAsRead} />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={a.action}>
            <OverviewIconButton onClick={a.onHandle} disabled={a.disabled} tooltip={a.tooltip}>
              {a.icon}
            </OverviewIconButton>
          </React.Fragment>
        );
      }
    });

  const addButtonEl = useMemo(
    () =>
      !checkedItems.length &&
      addActions && (
        <Box flex={1} justifyContent={"end"}>
          <OverviewAddButton onAdd={onAdd} actions={addActions} />
        </Box>
      ),
    [addActions, checkedItems.length, onAdd]
  );

  const selectedButtonEl = useMemo(
    () =>
      checkedItems.length > 0 && (
        <OverviewSelectAction
          collection={collection}
          onCancel={onResetChecked}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          onExport={onExport}
          checkedItems={checkedItems}
          actions={selectionActions}
        />
      ),
    [checkedItems, collection, onDelete, onDuplicate, onExport, onResetChecked, selectionActions]
  );

  const viewsMenuEl = useMemo(
    () =>
      pages ? (
        <Box mr={2}>
          <OverviewPagesMenu onPageChange={onPageChange} pages={pages} />
        </Box>
      ) : (
        <></>
      ),
    [onPageChange, pages]
  );

  const allCount =
    itemsCount &&
    typeof itemsCount.allCount === "number" &&
    t(itemsCount.allCount === 1 ? "item_single" : "items_number", { number: itemsCount.allCount });

  const currentCount =
    itemsCount && typeof itemsCount?.currentCount === "number"
      ? t("items_number_partial", { number: itemsCount.currentCount })
      : "";

  const countText = `${currentCount || ""} ${allCount || ""}`;

  const itemsNumberEl = useMemo(
    () =>
      hideCount ? (
        <></>
      ) : (
        <Tooltip title={countText}>
          <Typography noWrap style={{ fontSize: "12px" }}>
            {countText}
          </Typography>
        </Tooltip>
      ),
    [countText, hideCount]
  );

  const checkAllEl = (
    <Box pl={0.4} mr={1}>
      {showSelectAll ? (
        <OverviewSelectAllCheckbox
          collection={collection}
          onChange={onSelectAllCheckbox}
          checkedItemsNumber={checkedItems.length}
          onHover={onToolbarSelectAllHover}
          numberTotalSelectableItems={numberTotalSelectableItems}
        />
      ) : (
        <></>
      )}
    </Box>
  );

  return (
    <Box display="flex" alignItems="center">
      {checkAllEl}
      {searchEl}
      {viewsMenuEl}
      {itemsNumberEl}
      <Box flex={1} mx={2} display="flex">
        {actionsEl}
      </Box>
      {addButtonEl}
      {selectedButtonEl}
    </Box>
  );
};
