import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import TextBody2 from "components/TextBody2/TextBody2";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { mergeResourceApi } from "../../../../api/resourceApi";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "app/collections";
import { RESOURCE_TYPE } from "app/handlers/resourceHandler";
import { ResourceField } from "components/ResourceField";
import { useResources } from "../../../../contexts/resource-context";
import MergeTypeSelector, { MERGE_TYPES, MergeType } from "../../components/MergeTypeSelector";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useMultilingualEditor, useSupportedLanguages } from "app/api/multilingualApi";
import { useTLng } from "components/Multilingual/MultilingualModal";
import { Alert } from "@mui/material";
import PersonGroupsMergeTranslations from "../person-groups/PersonGroupsMergeTranslations";
import { ExecutionMode } from "app/api/generated/multilingual-service";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import { useSnackbar } from "notistack";
import { useErrorSnackbar } from "hook/errorSnackbar";

export interface ResourceMergeItem {
  readonly id: string;
  readonly defaultResource: boolean;
}

export interface ResourceMergeModalProps {
  readonly resourceType: RESOURCE_TYPE;
  readonly selectedResources: Pick<ResourceMergeItem, "id">[];
  readonly onClose: () => void;
  readonly onCancel: () => void;
}

export const ResourceMergeModal = ({
  resourceType,
  selectedResources,
  onClose,
  onCancel: onParentCancel
}: ResourceMergeModalProps) => {
  const { t } = useTranslation(`resources_${resourceType}_overview`);
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  const isMultilingualEnabled = useIsFeaturePresent(FEATURES.MULTILINGUAL);
  const supportedLanguages = useSupportedLanguages();
  const [step, setStep] = useState<number>(0);
  const [mergedTranslations, setMergedTranslations] = useState<Record<string, string>>({});
  const isDefaultLanguageEmpty = useMemo(() => {
    return !supportedLanguages.data?.mainLanguage || !mergedTranslations[supportedLanguages.data?.mainLanguage];
  }, [mergedTranslations, supportedLanguages.data?.mainLanguage]);
  const tLng = useTLng();
  const [toMergeResourceIds, setToMergeResourceIds] = useState<string[]>([]);
  const [newName, setNewName] = useState("");
  const [mergeIntoResourceId, setMergeIntoResourceId] = useState<string>("");
  const [mergeType, setMergeType] = useState<MergeType>(MERGE_TYPES.createNew);

  const { resources } = useResources();
  const resourceOfTypes = resources[resourceType];

  const resourceNamesById = useMemo(
    () =>
      (resourceOfTypes || []).reduce(
        (acc, it) => {
          if (!it.nameKey) return acc;
          acc[it.id] = it.nameKey;
          return acc;
        },
        {} as Record<string, string>
      ),
    [resourceOfTypes]
  );

  const { originalNames, translationKeys } = useMemo(() => {
    const translationKeys: string[] = [];
    const originalNames: string[] = [];
    if (newName && mergeType === MERGE_TYPES.createNew) {
      originalNames.push(newName);
    }
    if (mergeIntoResourceId && mergeType === MERGE_TYPES.mergeIntoExisting) {
      translationKeys.push(mergeIntoResourceId);
      originalNames.push(resourceNamesById[mergeIntoResourceId]);
    }
    selectedResources.forEach(r => {
      translationKeys.push(r.id);
      originalNames.push(resourceNamesById[r.id]);
    });
    return { translationKeys, originalNames: Array.from(new Set(originalNames)) };
  }, [mergeType, newName, mergeIntoResourceId, selectedResources, resourceNamesById]);

  const { updateTranslations } = useMultilingualEditor({
    translationKey: mergeIntoResourceId || ""
  });

  const handleCleanup = useCallback(() => {
    setStep(0);
    setNewName("");
    setMergeIntoResourceId("");
    setMergeType(MERGE_TYPES.createNew);
    setMergedTranslations({});
  }, []);

  const onCancel = useCallback(() => {
    handleCleanup();
    onParentCancel();
  }, [onParentCancel, handleCleanup]);

  useEffect(() => {
    setToMergeResourceIds(selectedResources.map(dl => dl.id));
    setNewName("");
    setMergeIntoResourceId("");
  }, [selectedResources]);

  const onNewNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value), []);

  const { catchAsSnackbar } = useErrorSnackbar();

  const mergeResources = useCallback(async () => {
    let mergedIntoId: string | null = null;
    const mergeIntoNewResourceAndMarkAsRead = async () => {
      const createdMergedIntoResourceId = await mergeResourceApi(resourceType, {
        toMergeIDs: toMergeResourceIds,
        nameKey: newName
      });
      if (createdMergedIntoResourceId) {
        await addToSeenItemsOfUserHook(COLLECTIONS.RESOURCES, createdMergedIntoResourceId);
      }
      return createdMergedIntoResourceId;
    };

    try {
      switch (mergeType) {
        case MERGE_TYPES.createNew:
          mergedIntoId = await mergeIntoNewResourceAndMarkAsRead();
          break;
        case MERGE_TYPES.mergeIntoExisting:
          mergedIntoId = await mergeResourceApi(resourceType, {
            toMergeIDs: toMergeResourceIds,
            mergeIntoId: mergeIntoResourceId
          });
          break;
        default:
          throw new Error(`Unknown merge type: ${mergeType}`);
      }

      if (mergedIntoId && isMultilingualEnabled) {
        await updateTranslations(mergedTranslations, mergedIntoId, ExecutionMode.ExecuteOnly);
      }
    } catch (e) {
      if (isAxiosErrorWithCode(e, 409)) {
        catchAsSnackbar(t("error_messages:generic_already_exists"))({});
      } else {
        catchAsSnackbar(e);
      }
    }
    handleCleanup();
    onClose();
  }, [
    mergeType,
    resourceType,
    toMergeResourceIds,
    newName,
    addToSeenItemsOfUserHook,
    mergeIntoResourceId,
    mergedTranslations,
    isMultilingualEnabled,
    updateTranslations,
    onClose,
    handleCleanup,
    t,
    catchAsSnackbar
  ]);

  const originalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: onCancel
      },
      {
        confirmButton: true,
        title: t("common:merge"),
        variant: "contained",
        color: "primary",
        size: "medium",
        disabled:
          !(mergeType === MERGE_TYPES.createNew && newName) &&
          !(mergeType === MERGE_TYPES.mergeIntoExisting && mergeIntoResourceId),
        onClick: mergeResources
      }
    ],
    [onCancel, t, mergeResources, mergeIntoResourceId, mergeType, newName]
  );

  const multilingualButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: step > 0 ? () => setStep(step => step - 1) : onCancel
      },
      step === 0
        ? {
            confirmButton: true,
            title: t("common:next"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: () => setStep(1)
          }
        : {
            confirmButton: true,
            title: t("common:merge"),
            variant: "contained",
            color: "primary",
            size: "medium",
            disabled: isDefaultLanguageEmpty,
            onClick: mergeResources
          }
    ],
    [t, onCancel, mergeResources, step, isDefaultLanguageEmpty]
  );

  const buttons = isMultilingualEnabled ? multilingualButtons : originalButtons;

  const onResourceIdsChanged = useCallback(value => {
    setToMergeResourceIds(value);
  }, []);

  const onMergeIntoIdChanged = useCallback(value => {
    setMergeIntoResourceId(value);
  }, []);

  const alwaysNotDeletable = useCallback(() => {
    return true;
  }, []);

  const modalBody =
    step === 0 ? (
      <Box mt={3} mb={4}>
        <Box>
          <Box mt={2}>
            <ResourceField
              id="request_type"
              value={toMergeResourceIds}
              onChange={onResourceIdsChanged}
              resourceType={resourceType}
              label={""}
              multiSelect={true}
              isNotDeletable={alwaysNotDeletable}
              disabled={true}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <TextBody2 text={t(`mergeModal:merge_name_input_title`, t("common:name"))} />
        </Box>
        <MergeTypeSelector
          mergeDecision={mergeType}
          onMergeDecisionChanged={setMergeType}
          resourceType={resourceType}
        />
        {mergeType === MERGE_TYPES.createNew && (
          <Box mt={2}>
            <TextField
              id={"new-datatype-name"}
              label={t("name", t("common:name"))}
              fullWidth={true}
              variant="outlined"
              value={newName}
              onChange={onNewNameChanged}
              required={true}
            />
          </Box>
        )}
        {mergeType === MERGE_TYPES.mergeIntoExisting && (
          <Box mt={2}>
            <ResourceToMergeInto
              mergeIntoResourceId={mergeIntoResourceId}
              resourceType={resourceType}
              onMergeIntoIdChanged={onMergeIntoIdChanged}
              toMergeResourceIds={toMergeResourceIds}
            />
          </Box>
        )}
      </Box>
    ) : (
      <>
        {isDefaultLanguageEmpty && (
          <Alert severity="warning" sx={{ my: 2 }}>
            {t("multilingual:defaultLanguageEmpty", {
              language: tLng(supportedLanguages.data?.mainLanguage ?? "")
            })}
          </Alert>
        )}
        <PersonGroupsMergeTranslations
          translationKeys={translationKeys}
          originalNames={originalNames}
          onChange={setMergedTranslations}
        />
      </>
    );

  return (
    <ConfirmationModal
      modalOpen={!!selectedResources.length}
      onClose={onCancel}
      modalTitle={t(`merge_title`)}
      modalText={t(`merge_text`)}
      buttons={buttons}
      modalBody={modalBody}
      key={step}
    />
  );
};

const ResourceToMergeInto = ({
  resourceType,
  toMergeResourceIds,
  mergeIntoResourceId,
  onMergeIntoIdChanged
}: {
  readonly resourceType: RESOURCE_TYPE;
  readonly toMergeResourceIds: string[];
  readonly mergeIntoResourceId: string;
  readonly onMergeIntoIdChanged: (value: string | null) => void;
}) => {
  const { t } = useTranslation(`resources_${resourceType}_overview`);
  const { resources } = useResources();
  const resourceOfTypes = resources[resourceType];
  const [whitelistedIDs, setWhitelistedIds] = useState<string[]>([]);
  useEffect(() => {
    const allowedIDsToUse = (resourceOfTypes || []).map(it => it.id).filter(it => !toMergeResourceIds.includes(it));
    setWhitelistedIds(allowedIDsToUse);
  }, [resourceOfTypes, toMergeResourceIds]);

  const onChangeCallback = useCallback(
    (values: string | string[] | null) => {
      onMergeIntoIdChanged(Array.isArray(values) ? values[0] : values);
    },
    [onMergeIntoIdChanged]
  );

  return (
    <ResourceField
      id="request_type"
      value={mergeIntoResourceId}
      onChange={onChangeCallback}
      resourceType={resourceType}
      label={t("merge_into_placeholder", t("mergeModal:merge_into_placeholder", ""))}
      multiSelect={false}
      allowAdd={false}
      whitelistedIDs={whitelistedIDs}
    />
  );
};
