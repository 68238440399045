import { DefaultApi, VersionHistoriesDTO } from "../generated/process-service";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import useSWRInfinite from "swr/infinite";
import { useTranslation } from "react-i18next";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const usePAVersionHistory = (args: { readonly documentId: string; readonly refreshInterval?: number }) => {
  const { i18n } = useTranslation();
  const getInfiniteSWRKey = (pageIndex: number, previousPageData?: VersionHistoriesDTO) => {
    const notYetInitialized = !args.documentId;
    if (notYetInitialized) {
      return null;
    }
    const reachedEndOfPage = previousPageData && !previousPageData.nextCursor;
    if (reachedEndOfPage) {
      return null;
    }
    return ["pa_version_history", i18n.language, args.documentId, previousPageData?.nextCursor || undefined];
  };

  return useSWRInfinite<VersionHistoriesDTO>(
    getInfiniteSWRKey,
    async (keys: ReturnType<typeof getInfiniteSWRKey>) => {
      const nextCursor = keys?.[3];
      return processClient
        .getProcessingActivityHistories(args.documentId, nextCursor, undefined, {
          headers: {
            "Accept-Language": i18n.language
          }
        })
        .then(resp => resp.data);
    },
    {
      refreshInterval: args.refreshInterval
    }
  );
};
