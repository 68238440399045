import useSWR, { useSWRConfig } from "swr";
import {
  getNumberUnseenDataLocationsOfUserApi,
  getNumberUnseenExternalRecipientsOfUserApi
} from "../../../api/externalRecipientApi";
import { getResourcesUnseenApi } from "../../../api/resourceApi";
import { getNumberUnseenAssetsApi } from "../../../api/assetApi";
import { COLLECTIONS } from "../../../collections";
import { getNumberUnseenTasksApi } from "../../../api/taskApi";
import { useUserNotifications } from "../../../../hook/useUserNotifications";
import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { AITechDocsApi } from "../../../api/generated/aitechdoc-service";
import { apiEndpoints } from "../../../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../../api/axios/loggedInAxiosProvider";
import { isUnseenSwrKey } from "./unseenSwrKey";

export interface SidebarUnseen {
  readonly tasks: number;
  readonly notifications: number;
  readonly serviceProviders: number;
  readonly resources: number;
  readonly resourcesByType: { type: string; _count: number }[];
  readonly dataLocations: number;
  readonly assets: number;
  readonly aiTechDocs: {
    unseenCount: number;
    totalCount: number;
  };
}

export const useSidebarSWR = () => {
  const {
    data: unseenTasks,
    isValidating: unseenTasksValidating,
    mutate: unseenTasksMutate
  } = useSWR("unseen-tasks", () => getNumberUnseenTasksApi());

  const {
    data: unseenERs,
    isValidating: unseenERsValidating,
    mutate: unseenERsMutate
  } = useSWR("unseen-ers", () => getNumberUnseenExternalRecipientsOfUserApi());

  const {
    data: unseenDLs,
    isValidating: unseenDLsValidating,
    mutate: unseenDLsMutate
  } = useSWR("unseen-dls", () => getNumberUnseenDataLocationsOfUserApi());

  const {
    data: unseenResources,
    isValidating: unseenResourcesValidating,
    mutate: unseenResourcesMutate
  } = useSWR("unseen-resources-per-type", () => getResourcesUnseenApi());

  const {
    data: unseenAssets,
    isValidating: unseenAssetsValidating,
    mutate: unseenAssetsMutate
  } = useSWR("unseen-assets", () => getNumberUnseenAssetsApi());

  const {
    data: unseenAITechDocs,
    isValidating: unseenAITechDocsValidating,
    mutate: unseenAITechDocsMutate
  } = useSWR("unseen-aitechdocs", () => aiTechDocClient.getAITechDocUnseenCount().then(it => it.data));

  const { mutate: globalMutate } = useSWRConfig();

  const sidebarNewItemsMutate = useCallback(() => {
    return globalMutate(isUnseenSwrKey);
  }, [globalMutate]);

  return {
    unseenTasks,
    unseenTasksValidating,
    unseenTasksMutate,
    unseenERs,
    unseenERsValidating,
    unseenERsMutate,
    unseenDLs,
    unseenDLsValidating,
    unseenDLsMutate,
    unseenResources,
    unseenResourcesValidating,
    unseenResourcesMutate,
    unseenAssets,
    unseenAssetsValidating,
    unseenAssetsMutate,
    unseenAITechDocs,
    unseenAITechDocsValidating,
    unseenAITechDocsMutate,
    sidebarNewItemsMutate
  };
};
const aiTechDocClient = new AITechDocsApi(undefined, apiEndpoints.aiTechDocUrl, defaultOTCAuthenticatedAxios());

export const useSidebarUnseen = () => {
  const { auth } = useAuthentication();

  const [sidebarNewItems, setSidebarNewItems] = useState<SidebarUnseen>({
    [COLLECTIONS.TASKS]: 0,
    [COLLECTIONS.NOTIFICATIONS]: 0,
    [COLLECTIONS.EXTERNAL_RECIPIENTS]: 0,
    [COLLECTIONS.RESOURCES]: 0,
    resourcesByType: [],
    [COLLECTIONS.DATA_LOCATIONS]: 0,
    [COLLECTIONS.ASSETS]: 0,
    [COLLECTIONS.AI_TECH_DOCS]: {
      unseenCount: 0,
      totalCount: 0
    }
  });

  const { notifications } = useUserNotifications();
  useEffect(() => {
    if (notifications?.length) {
      setSidebarNewItems(sidebarNewItems => ({
        ...sidebarNewItems,
        [COLLECTIONS.NOTIFICATIONS]: notifications.filter(i => !i.readAt && i.senderUID !== auth?.uid).length
      }));
    }
  }, [notifications, auth?.uid]);

  const { unseenTasks, unseenERs, unseenDLs, unseenResources, unseenAssets, unseenAITechDocs } = useSidebarSWR();

  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.TASKS]: unseenTasks || 0
    }));
  }, [unseenTasks]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.EXTERNAL_RECIPIENTS]: unseenERs || 0
    }));
  }, [unseenERs]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.DATA_LOCATIONS]: unseenDLs || 0
    }));
  }, [unseenDLs]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.RESOURCES]: unseenResources?.unseenCount || 0
    }));
  }, [unseenResources?.unseenCount]);
  useEffect(() => {
    if (unseenResources?.unseenCountPerType) {
      setSidebarNewItems(sidebarNewItems => ({
        ...sidebarNewItems,
        resourcesByType: unseenResources?.unseenCountPerType || {}
      }));
    }
  }, [unseenResources?.unseenCountPerType]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.ASSETS]: unseenAssets || 0
    }));
  }, [unseenAssets]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.AI_TECH_DOCS]: {
        unseenCount: unseenAITechDocs?.unseenCount || 0,
        totalCount: unseenAITechDocs?.totalCount || 0
      }
    }));
  }, [unseenAITechDocs]);

  return {
    sidebarNewItems
  };
};
