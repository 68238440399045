import useSWR from "swr";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import { DefaultApi, GetProportionality200Response, UpdateProportionalityDTO } from "../generated/process-service";
import { useCallback, useMemo } from "react";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const useProportionality = (args: { documentId: string }) => {
  const responseProportionality = useSWR<GetProportionality200Response>(
    args.documentId ? "proportionality" + args.documentId : null,
    async () => {
      return processClient.getProportionality(args.documentId).then(resp => resp.data);
    }
  );

  const emptyProcessData: GetProportionality200Response = useMemo(
    () => ({
      processMeta: { id: args.documentId, status: "", relativeOrgUnitIds: [], assignUserIds: [], title: "" },
      processPage: {
        proportionality: ""
      }
    }),
    [args.documentId]
  );

  const { mutate } = responseProportionality;

  const updateProportionality = useCallback(
    async (processID: string, payload: UpdateProportionalityDTO) => {
      await mutate(
        async data => {
          await processClient.updateProportionality(args.documentId, payload);
          return data;
        },
        {
          populateCache: false,
          optimisticData: prevData => {
            if (!prevData) {
              return emptyProcessData;
            }
            return {
              ...prevData,
              processPage: {
                ...prevData.processPage,
                proportionality: payload.proportionality
              }
            } satisfies GetProportionality200Response;
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate]
  );

  const actions = useMemo(
    () => ({
      updateProportionality: updateProportionality
    }),
    [updateProportionality]
  );

  return {
    ...responseProportionality,
    actions: actions
  };
};
