import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import { useParams } from "react-router-dom";
import { AuditRemarkContext, deleteAuditRemark, postAuditRemark } from "app/api/auditApi";
import AuditRemarkEdit from "app/pages/audits/remarks/AuditRemarkEdit";
import AuditRemarkView from "app/pages/audits/remarks/AuditRemarkView";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { deleteAnswersetRemark, getSingleAnswerSetApi, postAnswersetRemark } from "app/api/assessmentApi";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useAuditRemarks } from "app/contexts/remarks-context";

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    borderRadius: "6px",
    background: theme.palette.blue[50]
  }
}));

const MetaViewRemarksTab = ({ docId }: { readonly docId: string }) => {
  const cls = useStyles();
  const { t } = useTranslation();
  const params = useParams();
  const documentId = params?.id;
  const [auditRemarkForQuestion, setAuditRemarkForQuestion] = useState<AuditRemarkContext | null>(null);
  const docuId = docId.split("/answerset/");
  const { questionId, newRemarkForm, setNewRemarkForm } = useMetaView();
  const isPublicAssessment = useIsFeaturePresent(FEATURES.PUBLIC_ASSESSMENT);
  const { reloadAuditRemarks, auditRemarks } = useAuditRemarks();

  const reloadAnswersetRemark = useCallback(async () => {
    if (docuId.length > 0 && isPublicAssessment) {
      const answersetDetail = await getSingleAnswerSetApi({ auditId: docuId[0], id: docuId[1] });
      if (answersetDetail && answersetDetail.questions) {
        const remarks: AuditRemarkContext[] = answersetDetail.questions
          .filter(({ remark }) => remark)
          .map(({ id, remark }) => ({ questionId: id, ...remark }) as unknown as AuditRemarkContext);
        const remark = remarks.find(remark => remark.questionId === questionId);
        setAuditRemarkForQuestion(remark || null);
      }
    }
  }, [docuId, isPublicAssessment, questionId]);

  useEffect(() => {
    reloadAnswersetRemark();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auditRemarks && questionId) {
      const remark = auditRemarks.find(remark => remark.questionId === questionId);
      setAuditRemarkForQuestion(remark || null);
    }
  }, [questionId, auditRemarks]);

  const cancelEditRemark = useCallback(() => setNewRemarkForm(false), [setNewRemarkForm]);
  const createAuditRemark = useCallback(
    async (value: string) => {
      if (documentId && questionId) {
        await postAuditRemark({
          auditId: documentId,
          questionId: questionId,
          payload: value
        });
        setNewRemarkForm(false);
        reloadAuditRemarks();
      }
    },
    [documentId, questionId, reloadAuditRemarks, setNewRemarkForm]
  );

  const createAnswersetRemark = useCallback(
    async (value: string) => {
      if (docuId.length > 0 && questionId) {
        await postAnswersetRemark({
          auditId: docuId[0],
          questionId: questionId,
          answersetId: docuId[1],
          payload: value
        });
        setNewRemarkForm(false);
        reloadAuditRemarks();
      }
    },
    [docuId, questionId, reloadAuditRemarks, setNewRemarkForm]
  );

  const onEditCallback = useCallback(() => setNewRemarkForm(true), [setNewRemarkForm]);
  const onDeleteCallback = useCallback(async () => {
    if (documentId && questionId) {
      await deleteAuditRemark({
        auditId: documentId,
        questionId: questionId
      });
      setNewRemarkForm(false);
      reloadAuditRemarks();
    }
  }, [documentId, questionId, reloadAuditRemarks, setNewRemarkForm]);

  const onDeleteAnswersetRemarkCallback = useCallback(async () => {
    if (docuId.length > 0 && questionId) {
      await deleteAnswersetRemark({
        auditId: docuId[0],
        answersetId: docuId[1],
        questionId: questionId
      });
      setNewRemarkForm(false);
      reloadAuditRemarks();
    }
  }, [docuId, questionId, reloadAuditRemarks, setNewRemarkForm]);

  return (
    <>
      {newRemarkForm && (
        <AuditRemarkEdit
          value={auditRemarkForQuestion?.value || ""}
          onSave={isPublicAssessment ? createAnswersetRemark : createAuditRemark}
          onCancel={cancelEditRemark}
        />
      )}
      {!newRemarkForm && auditRemarkForQuestion && (
        <AuditRemarkView
          onEdit={onEditCallback}
          onDelete={isPublicAssessment ? onDeleteAnswersetRemarkCallback : onDeleteCallback}
          auditRemark={auditRemarkForQuestion}
        />
      )}
      <Box className={cls.info} p={3} mt={3}>
        {t("audit_details:remarkInfoMessage")}
      </Box>
    </>
  );
};

export default MetaViewRemarksTab;
