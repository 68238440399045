import React, { CSSProperties, useCallback } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import { changeLastUrlPath } from "./url-handler";
import { useNavigate } from "react-router-dom";
import { usePathName } from "../../app/router/router-custom-hooks";
import Box from "@material-ui/core/Box";

export interface PageStep {
  readonly path: string;
  readonly label: string;
  readonly icon?: React.ReactNode;
}

export default function GeneralPageStepper({
  currentPath,
  steps,
  onStepChange,
  skipNavigate
}: {
  readonly currentPath?: string;
  readonly steps: PageStep[];
  readonly onStepChange?: (step: PageStep) => void;
  readonly skipNavigate?: boolean;
}) {
  const navigate = useNavigate();
  const path = usePathName();

  const stepClicked = useCallback(
    step => {
      if (!skipNavigate) {
        navigate(changeLastUrlPath(path, step.path));
      }
      if (onStepChange) {
        onStepChange(step);
      }
    },
    [skipNavigate, onStepChange, navigate, path]
  );

  const currentPathValue = currentPath || path.split("/").pop();

  return (
    <Box width="100%">
      <Stepper nonLinear alternativeLabel style={stepperStyle}>
        {steps.map((step, index) => {
          const isActiveStep = currentPathValue === step.path;

          return (
            <Step style={stepStyle} active={isActiveStep} key={`step-${index}`}>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <StepButton onClick={() => stepClicked(step)} icon={step.icon || index + 1}>
                <StepLabel>{step.label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

const stepperStyle: CSSProperties = {
  marginTop: 20
};

const stepStyle: CSSProperties = {
  minWidth: 70
};
