import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../../../contexts/meta-view-context";
import { Box } from "@mui/material";
import { AuditQuestionsRender } from "app/pages/audits/designer/AuditQuestionsRender";
import { getSingleAnswerSetApi, postAnswerForAnswerSet } from "app/api/assessmentApi";
import { AuditQuestionWithAnswerAndRemarkDTO } from "app/api/auditApi";
import { useAuditActionTasks } from "app/contexts/audit-action-tasks-context";
import Question from "components/Question/Question";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";
import { COLLECTIONS } from "app/collections";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

const AnswersetQuestionnaire = ({
  auditid,
  id,
  onAnswer
}: {
  readonly auditid: string;
  readonly id: string;
  readonly onAnswer: () => void;
}) => {
  const { t } = useTranslation("assessment_answerset");
  const { setInfo } = useMetaView();
  const { isExternalUser } = useUserAndTenantData();
  const { auditActionTasksReload } = useAuditActionTasks(auditid, id);
  const { user } = useAuthentication();

  const [questions, setQuestions] = useState<AuditQuestionWithAnswerAndRemarkDTO[]>([]);
  const [answerSetStatus, setAnswerSetStatus] = useState<string>("");
  const [auditStatus, setAuditStatus] = useState<string>("");

  const _isExternalUser = useMemo(() => Boolean(user?.id && isExternalUser(user?.id)), [isExternalUser, user?.id]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitleQuestionnaire"),
        text: t("enteringInfoCardTextQuestionnaire")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const loadQuestionnaire = useCallback(async () => {
    const data = await getSingleAnswerSetApi({ auditId: auditid || "", id: id || "" });
    if (data) {
      setAnswerSetStatus(data.status);
      setAuditStatus(data.audit.status);
      setQuestions(data.questions);
    }
  }, [id, auditid]);

  useEffect(() => {
    loadQuestionnaire();
  }, [loadQuestionnaire]);

  const onAnswerCallback = useCallback(
    async (questionId, payload) => {
      if (auditid && id) {
        await postAnswerForAnswerSet({ auditId: auditid, answerSetId: id, questionId, payload });
        onAnswer();
        loadQuestionnaire();
        auditActionTasksReload();
      }
    },
    [loadQuestionnaire, auditid, id, onAnswer, auditActionTasksReload]
  );

  const auditQuestionsRenderEl = useMemo(
    () => (
      <AuditQuestionsRender
        allowAnswer={answerSetStatus === "completed" ? false : true}
        templateStatus={"active"}
        selectedId={null}
        questions={questions}
        onSelectQuestion={undefined}
        auditStatus={auditStatus}
        onAnswer={onAnswerCallback}
        documentId={`${auditid}/answerset/${id}`}
      />
    ),
    [questions, onAnswerCallback, answerSetStatus, auditStatus, auditid, id]
  );

  const assessmentAttachmentEl = useMemo(
    () => (
      <Question>
        <QuestionnaireSubHeader text={t("common:attachments")} />
        <AttachmentsOverviewOBS
          docId={`${auditid}/answerset/${id}`}
          category={`${COLLECTIONS.ASSESSMENT_RESPONSE}-questionnaire`}
          disabled={Boolean(answerSetStatus === "completed" || _isExternalUser)}
        />
      </Question>
    ),
    [t, auditid, id, answerSetStatus, _isExternalUser]
  );

  return (
    <Box>
      {auditQuestionsRenderEl}
      {assessmentAttachmentEl}
    </Box>
  );
};

export default AnswersetQuestionnaire;
