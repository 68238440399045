import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isPasswordStrongEnough } from "../../authentication/checkPasswordUtils";
import { useMetaView } from "../../../contexts/meta-view-context";
import { updateMyPasswordApi } from "app/api/user/userMeApi";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "8px 0",
    lineHeight: "72px"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  fields: {
    width: "350px",
    margin: "10px 0"
  },
  buttons: {
    margin: "10px 0"
  }
}));

export default function AccountSettingsChangePassword({
  oldPassword,
  onSuccess
}: {
  oldPassword: string;
  onSuccess?: () => Promise<void>;
}) {
  const classes = useStyles();
  const { t } = useTranslation("accountSettingsChangePassword");
  const { setInfo } = useMetaView();
  // change of password change
  const [loading, setLoading] = useState(false);
  const [changePasswordButtonDisabled, setChangePasswordButtonDisabled] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [confirmNewPasswordVisibility, setConfirmNewPasswordVisibility] = useState(false);
  const passwordsAreNotEqual = confirmNewPassword !== newPassword;

  useEffect(() => {
    setInfo({
      title: t("accountSettingsChangePassword:infoCardEnterTitle"),
      text: t("accountSettingsChangePassword:infoCardEnterBody")
    });
  }, [setInfo, t]);

  const changePasswordCallback = useCallback(
    async event => {
      event?.preventDefault?.();
      setLoading(true);
      await updateMyPasswordApi({
        newPassword: newPassword,
        oldPassword: oldPassword
      });
      onSuccess?.();
    },
    [newPassword, oldPassword, onSuccess]
  );

  const preventSubmitCallback = useCallback(event => {
    event.preventDefault();
  }, []);

  const toggleNewPasswordVisibility = useCallback(() => {
    setNewPasswordVisibility(prevState => !prevState);
  }, []);

  const toggleConfirmNewPasswordVisibility = useCallback(() => {
    setConfirmNewPasswordVisibility(prevState => !prevState);
  }, []);

  const newPasswordChangeCallback = useCallback(
    event => {
      setNewPassword(event?.target?.value || "");
    },
    [setNewPassword]
  );
  const confirmNewPasswordCallback = useCallback(
    event => {
      setConfirmNewPassword(event?.target?.value || "");
    },
    [setConfirmNewPassword]
  );
  const submitIfEnterPressedCallback = useCallback(
    event => {
      if (event.key === "Enter") {
        return changePasswordCallback(event);
      }
    },
    [changePasswordCallback]
  );

  useEffect(() => {
    if (loading) {
      setChangePasswordButtonDisabled(true);
      return;
    }

    if (!(newPassword && confirmNewPassword)) {
      setChangePasswordButtonDisabled(true);
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setChangePasswordButtonDisabled(true);
      return;
    }

    if (!isPasswordStrongEnough(newPassword)) {
      setChangePasswordButtonDisabled(true);
      return;
    }

    setChangePasswordButtonDisabled(false);
  }, [loading, newPassword, confirmNewPassword]);

  const onNewPasswordFocused = useCallback(() => {
    setInfo({
      title: t("accountSettingsChangePassword:infoCardEnterTitle"),
      text: t("accountSettingsChangePassword:infoCardEnterBody")
    });
  }, [t, setInfo]);

  return (
    <div>
      <h2 className={classes.title}>{t("title")}</h2>
      <div>
        <TextField
          id="newPassword"
          tabIndex={1}
          placeholder={t("new_password")}
          variant="outlined"
          className={classes.fields}
          type={newPasswordVisibility ? "text" : "password"}
          value={newPassword}
          onChange={newPasswordChangeCallback}
          onFocus={onNewPasswordFocused}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={toggleNewPasswordVisibility}
                  onMouseDown={preventSubmitCallback}
                  edge="end"
                >
                  {newPasswordVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          label={t("new_password")}
        />
      </div>
      <div>
        <TextField
          onFocus={onNewPasswordFocused}
          error={passwordsAreNotEqual || (!isPasswordStrongEnough(newPassword) && confirmNewPassword !== "")}
          helperText={
            passwordsAreNotEqual
              ? t("authentication:error_passwords_not_equal")
              : !isPasswordStrongEnough(newPassword) && confirmNewPassword !== ""
                ? t("authentication:error_password_not_strong")
                : ""
          }
          id="confirmNewPassword"
          tabIndex={2}
          placeholder={t("confirm_new_password")}
          variant="outlined"
          className={classes.fields}
          type={confirmNewPasswordVisibility ? "text" : "password"}
          value={confirmNewPassword}
          onChange={confirmNewPasswordCallback}
          onKeyDown={submitIfEnterPressedCallback}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={toggleConfirmNewPasswordVisibility}
                  onMouseDown={preventSubmitCallback}
                  edge="end"
                >
                  {confirmNewPasswordVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          label={t("confirm_new_password")}
        />
      </div>
      <div>
        <Button
          className={classes.buttons}
          tabIndex={3}
          variant="contained"
          type="submit"
          color="primary"
          disabled={changePasswordButtonDisabled}
          onClick={changePasswordCallback}
        >
          {loading && <CircularProgress color="inherit" size={14} className={classes.icon} />} {t("change_password")}
        </Button>
      </div>
    </div>
  );
}
