import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import {
  AUDIT_METHODOLOGY_TYPES,
  AUDIT_STATUS_TYPES,
  AUDIT_TEMPLATE_STATUS_TYPES
} from "app/pages/audits/audit/AuditTypes";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.auditUrl}/api/audits`
});
export interface AuditStatValue {
  readonly draft: number;
  readonly edit: number;
  readonly review: number;
  readonly completed: number;
}

export interface AssessmentAffectedUsers {
  readonly userIds: string[];
}

export interface AuditStat {
  readonly date: string;
  readonly value: AuditStatValue;
}

export interface AuditStats {
  readonly stats: AuditStat[];
}

export type QUESTION_ACTIONS_TYPES = "task";
export const QUESTION_ACTIONS = {
  TASK: "task"
} as const satisfies Record<string, QUESTION_ACTIONS_TYPES>;

export const postAudit = async (input: {
  readonly title: string;
  readonly methodology?: AUDIT_METHODOLOGY_TYPES;
}): Promise<string> => {
  const response = await axiosInstance.post("", input);
  return response.headers["x-resource-id"] || "";
};

export const getAuditDetail = async (input: {
  readonly id: string;
  readonly abortController?: AbortController;
}): Promise<Partial<AuditDetailDTO> | null> => {
  try {
    const response = await axiosInstance.get<Partial<AuditDetailDTO>>(`/${input.id}`, {
      signal: input.abortController?.signal
    });
    return response.data;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const getAuditStats = async (params: {
  readonly labelId?: string;
  readonly orgUnitId?: string;
}): Promise<any> => {
  const response = await axiosInstance.get<any>(`/stats`, {
    params: params
  });
  return response.data.stats || [];
};

export const patchAuditDetail = async (input: {
  readonly id: string;
  readonly payload: Partial<AuditPayloadDTO>;
  readonly abortController?: AbortController;
  readonly wait?: boolean;
}): Promise<void> => {
  await axiosInstance.patch(`/${input.id}`, input.payload, {
    signal: input.abortController?.signal,
    params: { wait: input.wait }
  });
};

export const getAllAuditTemplates = async (input: {
  readonly abortController?: AbortController;
}): Promise<AuditTemplateDetailDTO[]> => {
  try {
    const response = await axiosInstance.get<{ templates: AuditTemplateDetailDTO[] }>(`/templates/`, {
      signal: input.abortController?.signal
    });
    return response?.data?.templates;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      return [];
    }
    throw error;
  }
};

export const getAuditTemplate = async (input: {
  readonly id: string;
  readonly abortController?: AbortController;
}): Promise<Partial<AuditTemplateDetailDTO> | null> => {
  try {
    const response = await axiosInstance.get<Partial<AuditTemplateDetailDTO>>(`/templates/${input.id}`, {
      signal: input.abortController?.signal
    });
    return response.data;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const patchAuditTemplate = async (input: {
  readonly id: string;
  readonly payload: Partial<AuditTemplatePayloadDTO>;
  readonly abortController?: AbortController;
}): Promise<void> => {
  await axiosInstance.patch(`/templates/${input.id}`, input.payload, {
    signal: input.abortController?.signal
  });
};

export const postAuditTemplateQuestion = async (input: {
  readonly templateId: string;
  readonly payload: AuditQuestionPayloadDTO;
  readonly abortController?: AbortController;
}): Promise<string> => {
  const response = await axiosInstance.post(`/templates/${input.templateId}/questions`, input.payload, {
    signal: input.abortController?.signal
  });
  return response.headers["x-resource-id"] || "";
};

export const copyAuditTemplateQuestion = async (input: {
  readonly templateId: string;
  readonly questionId: string;
  readonly abortController?: AbortController;
}): Promise<string> => {
  const response = await axiosInstance.post(
    `/templates/${input.templateId}/questions/${input.questionId}/copies`,
    {},
    {
      signal: input.abortController?.signal
    }
  );
  return response.headers["x-resource-id"] || "";
};

export const copyAuditInlineTemplate = async (input: {
  readonly templateId: string;
  readonly abortController?: AbortController;
}): Promise<string> => {
  const response = await axiosInstance.post(
    `/templates/copies`,
    {
      ids: [input.templateId],
      inlineTemplate: true
    },
    {
      signal: input.abortController?.signal
    }
  );
  return response.headers["x-resource-id"] || "";
};

export const patchAuditTemplateQuestion = async (input: {
  readonly templateId: string;
  readonly id: string;
  readonly payload: Partial<AuditQuestionPayloadDTO>;
  readonly abortController?: AbortController;
}): Promise<void> => {
  await axiosInstance.patch(`/templates/${input.templateId}/questions/${input.id}`, input.payload, {
    signal: input.abortController?.signal
  });
};

export const deleteAuditTemplateQuestion = async (input: {
  readonly templateId: string;
  readonly id: string;
  readonly abortController?: AbortController;
}): Promise<void> => {
  await axiosInstance.delete(`/templates/${input.templateId}/questions/${input.id}`, {
    signal: input.abortController?.signal
  });
};

export const postAuditAnswer = async (input: {
  readonly auditId: string;
  readonly questionId: string;
  readonly payload: string | null;
  readonly abortController?: AbortController;
}): Promise<string> => {
  const response = await axiosInstance.post(
    `/${input.auditId}/questions/${input.questionId}/answers`,
    { value: input.payload },
    {
      signal: input.abortController?.signal
    }
  );
  return response.headers["x-resource-id"] || "";
};

export async function downloadAuditReportWordDocumentFromAPI(auditId: string, language: string) {
  const response = await axiosInstance.get("/" + auditId + "/reportDocument", {
    headers: { "Accept-Language": language },
    responseType: "blob"
  });
  return {
    data: new Blob([response.data]),
    fileName: getFileNameFromContentDispositionHeader(response.headers["content-disposition"])
  };
}

export async function getAuditTaskExports(auditId: string, language: string) {
  const response = await axiosInstance.get("/" + auditId + "/exportTasks", {
    headers: { "Accept-Language": language },
    responseType: "blob"
  });
  return new Blob([response.data]);
}

export async function getAuditActionTasks(auditId: string) {
  return await axiosInstance.get("/" + auditId + "/actionTasks");
}

const getFileNameFromContentDispositionHeader = (header: string) => {
  return header.split(";")[1].split("=")[1].replace('"', "").replace('"', "");
};

export const postAuditRemark = async (input: {
  readonly auditId: string;
  readonly questionId: string;
  readonly payload: string | null;
  readonly abortController?: AbortController;
}): Promise<string> => {
  const response = await axiosInstance.post(
    `/${input.auditId}/questions/${input.questionId}/remarks`,
    { value: input.payload },
    {
      signal: input.abortController?.signal
    }
  );
  return response.headers["x-resource-id"] || "";
};

export const deleteAuditRemark = async (input: {
  readonly auditId: string;
  readonly questionId: string;
  readonly abortController?: AbortController;
}) => {
  await axiosInstance.delete(`/${input.auditId}/questions/${input.questionId}/remarks`, {
    signal: input.abortController?.signal
  });
};

export const createQuestionnaireFromJsonData = async (input: {
  readonly payload: any;
  readonly abortController?: AbortController;
}) => {
  const response = await axiosInstance.post(`/templates/json`, input.payload, {
    signal: input.abortController?.signal
  });
  return response.headers["x-resource-id"] || "";
};

export const createInlineTemplate = async (input: {
  readonly payload: any;
  readonly abortController?: AbortController;
}) => {
  const response = await axiosInstance.post(`/templates/`, input.payload, {
    signal: input.abortController?.signal
  });
  return response.headers["x-resource-id"] || "";
};

export const getAuditAffectedUsers = async (input: {
  readonly id: string;
  readonly abortController?: AbortController;
}): Promise<AssessmentAffectedUsers | null> => {
  try {
    const response = await axiosInstance.get<AssessmentAffectedUsers>(`/${input.id}/affected-users`, {
      signal: input.abortController?.signal
    });
    return response.data;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export interface AuditTemplatePayloadDTO {
  readonly title: string;
  readonly status: string;
}

export enum COLLECTION_TYPES {
  AUDITS = "audits",
  AUDIT_TEMPLATES = "auditTemplates",
  ASSESSMENT_RESPONSE = "assessmentResponse"
}

export interface AuditTemplateDetailDTO extends AuditTemplatePayloadDTO {
  readonly id: string;
  readonly version: number;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly inlineTemplate?: boolean;
}

export interface AuditQuestionPayloadOptionDTO {
  readonly id: string;
  readonly title: string;
  readonly tooltip: string | null;
}

export interface AuditQuestionPayloadActionDTO {
  readonly id: string;
  readonly actionType: QUESTION_ACTIONS_TYPES;
  readonly answerValueId: string;
  readonly payload: any;
}
export interface AuditQuestionPayloadDTO {
  readonly type: string;
  readonly title: string | null;
  readonly description: string | null;
  readonly placeholder: string | null;
  readonly contentIds: string[];
  readonly htmlContent: string | null;
  readonly order: {
    afterQuestionId: string | null;
  };
  readonly options: AuditQuestionPayloadOptionDTO[];
  readonly groupId?: string | null;
  readonly conditions?: Record<string, string>;
  readonly actions: AuditQuestionPayloadActionDTO[];
}

export interface AuditQuestionDTO extends AuditQuestionPayloadDTO {
  readonly id: string;
  readonly version: number;
}

export interface AuditPayloadDTO {
  readonly title: string;
  readonly template: {
    readonly id: string;
    readonly version: number;
  } | null;
  readonly status: string;
  readonly methodology: string;
  readonly mainOrgUnitId: string | null;
  readonly associatedOrgUnitIds: string[];
  readonly auditorUID: string | null;
  readonly furtherAuditorUIDs: string[] | null;
  readonly dataCollectionDueDate: Date;
  readonly auditReportDueDate: Date;
  readonly participantsUIDs: string[] | null;
  readonly labelIds: string[];
  readonly auditScope: string;
  readonly auditResult: string;
  readonly openTasks: string;
}

export interface AuditAnswerDTO {
  readonly value: string | null;
}

export interface AuditRemarkDTO {
  readonly creatorUID: string;
  readonly updaterUID: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly value: string | null;
}

export interface AuditRemarkContext extends AuditRemarkDTO {
  readonly questionId: string;
}

export interface AuditQuestionWithAnswerAndRemarkDTO extends AuditQuestionDTO {
  readonly answer: AuditAnswerDTO;
  readonly remark: AuditRemarkDTO | null;
}

export interface AuditDetailDTO extends AuditPayloadDTO {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly migratedAt?: string;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly permissions: {
    readonly list: boolean;
    readonly readMetadata: boolean;
    readonly writeMetadata: boolean;
    readonly readAnswers: boolean;
    readonly writeAnswers: boolean;
    readonly readResult: boolean;
    readonly writeResult: boolean;
    readonly readReport: boolean;
    readonly writeReport: boolean;
  };
  readonly pages: string[];
  readonly pagesWithWrite: string[];
}

export interface AuditOverviewItem extends OverviewItem {
  status: AUDIT_STATUS_TYPES | AUDIT_TEMPLATE_STATUS_TYPES;
  auditReportDueDate?: string;
  collection: COLLECTION_TYPES;
}
