import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../app/utils/tab-panel";
import { useSearchParams } from "react-router-dom";
import { useAllQueryParams } from "../../app/router/router-custom-hooks";
import { TabsProps } from "@material-ui/core/Tabs/Tabs";

const useStyles = makeStyles(theme => ({
  tabsWithToolbar: {
    "& .MuiTab-root": {
      padding: "6px 0px"
    },
    top: "8px",
    width: "60%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  tabsFull: {
    "& .MuiTab-root": {
      padding: "6px 0px"
    },
    top: "8px",
    width: "100%"
  },
  tabsHalf: {
    "& .MuiTab-root": {
      padding: "6px 0px"
    },
    top: "8px",
    width: "50%"
  },
  tab: {
    minWidth: 0,
    fontSize: "14px"
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main
  },
  selectedTab: {
    color: theme.palette.primary.main
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  bottomContainer: {
    marginLeft: "25px",
    marginTop: "10px"
  }
}));

export interface TabContent {
  readonly title: string;
  readonly content?: React.ReactNode;
  readonly queryParamName?: string;
}

TabsLayout.defaultProps = {
  selectedTab: 0,
  toolbarLocation: "right",
  variant: "fullWidth"
};

export default function TabsLayout({
  tabContents,
  selectedTab,
  setSelectedTab,
  toolbarComponent,
  toolbarLocation,
  variant
}: {
  readonly tabContents: TabContent[];
  readonly selectedTab: number;
  readonly setSelectedTab?: (input: number) => void;
  readonly toolbarComponent?: React.ReactNode;
  readonly toolbarLocation: "left" | "right" | "bottom";
  readonly variant: TabsProps["variant"];
}) {
  const classes = useStyles();
  const [tabsValues, setTabsValues] = useState(selectedTab);

  const [, setSearchParams] = useSearchParams();
  const { tab: tabQueryParam, ...restQueryParams } = useAllQueryParams();

  useEffect(() => {
    setTabsValues(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (tabQueryParam && setSelectedTab) {
      const tabIndex = tabContents.findIndex(tabContent => tabContent.queryParamName === tabQueryParam);
      if (tabIndex !== -1) {
        setSelectedTab(tabIndex);
        setSearchParams({
          ...restQueryParams
        });
      }
    }
  }, [tabQueryParam, tabContents, restQueryParams, setSearchParams, setSelectedTab]);

  // tab switch
  const handleTabChange = useCallback(
    (event, newValue) => {
      setTabsValues(newValue);
      if (setSelectedTab) {
        setSelectedTab(newValue);
      }
    },
    [setSelectedTab]
  );

  const tabsClassNames = () => {
    if (!toolbarComponent) {
      return classes.tabsFull;
    }

    if (["left", "right"].includes(toolbarLocation)) {
      return classes.tabsWithToolbar;
    }

    return classes.tabsHalf;
  };

  return (
    <>
      <div className={classes.tabsContainer}>
        {toolbarComponent && toolbarLocation === "left" && <div>{toolbarComponent}</div>}
        <Tabs
          value={tabsValues}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          className={tabsClassNames()}
          classes={{ indicator: classes.tabIndicator }}
          variant={variant}
          centered
          selectionFollowsFocus={false}
        >
          {tabContents.map((tab, index) => {
            return (
              <Tab
                wrapped
                key={index}
                classes={{
                  root: toolbarComponent ? classes.tabsWithToolbar : classes.tabsFull,
                  selected: classes.selectedTab
                }}
                className={classes.tab}
                label={tab.title}
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
              />
            );
          })}
        </Tabs>
        {toolbarComponent && toolbarLocation === "right" && <div>{toolbarComponent}</div>}
      </div>
      <div className={classes.bottomContainer}>
        {toolbarComponent && toolbarLocation === "bottom" && <div>{toolbarComponent}</div>}
      </div>

      {tabContents.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsValues} index={index}>
            {item.content}
          </TabPanel>
        );
      })}
    </>
  );
}
