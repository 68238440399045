import React from "react";
import RenderIcon from "components/RenderIcon/RenderIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  inlineIconsContainer: {
    border: "none",
    marginLeft: "5px",
    display: "flex"
  }
}));

/**
 *This component houses list of inline icons
 */
export default function InlineIcons({ iconSize, data, elementId, className, additionalIconClasses }) {
  const classes = useStyles();

  return (
    <div className={className ?? classes.inlineIconsContainer}>
      {data.map((item, key) => {
        return (
          <RenderIcon
            iconSize={iconSize}
            {...item}
            key={key}
            elementId={elementId}
            disabled={item.disabled}
            additionalClasses={additionalIconClasses}
          />
        );
      })}
    </div>
  );
}
