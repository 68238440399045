import {
  AuthenticatorAsync,
  CreateDigest,
  CreateRandomBytes,
  HashAlgorithms,
  HexString,
  KeyDecoder,
  KeyEncoder,
  KeyEncodings
} from "@otplib/core-async";
import { keyDecoder, keyEncoder } from "@otplib/plugin-base32-enc-dec";

export const getAuthenticator = (): AuthenticatorAsync => {
  return new AuthenticatorAsync({
    createDigest,
    createRandomBytes,
    keyEncoder: keyEncoder as unknown as KeyDecoder<Promise<string>>,
    keyDecoder: keyDecoder as unknown as KeyEncoder<Promise<string>>
  });
};

const createDigest: CreateDigest<Promise<string>> = async (
  algorithm: HashAlgorithms,
  hmacKey: HexString,
  counter: HexString
): Promise<HexString> => {
  const subtleCryptoAlgorithm =
    algorithm === "sha1" ? "SHA-1" : algorithm === "sha256" ? "SHA-256" : algorithm === "sha512" ? "SHA-512" : null;
  if (!subtleCryptoAlgorithm) {
    throw new Error(`Unsupported algorithm ${algorithm}`);
  }
  const hmacKeyInSubtleCrypto = await window.crypto.subtle.importKey(
    "raw",
    toArrayBuffer(Buffer.from(hmacKey, "hex")),
    {
      name: "HMAC",
      hash: { name: subtleCryptoAlgorithm }
    },
    false,
    ["sign", "verify"]
  );
  const signedResult = await window.crypto.subtle.sign(
    "HMAC",
    hmacKeyInSubtleCrypto,
    toArrayBuffer(Buffer.from(counter, "hex"))
  );
  return Buffer.from(signedResult).toString("hex");
};

const createRandomBytes: CreateRandomBytes<Promise<string>> = async (
  size: number,
  encoding: KeyEncodings
): Promise<string> => {
  const randomBytes = new Uint8Array(size);
  window.crypto.getRandomValues(randomBytes);
  return Buffer.from(randomBytes).toString(encoding);
};

const toArrayBuffer = (buffer: Buffer) => {
  const ab = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
};
