import DocMetaView from "../../../../components/DocMetaView/DocMetaView";
import React, { useCallback } from "react";
import StaticDocView from "../../../../components/StaticDocView/StaticDocView";
import StaticDocViewHeader from "../../../../components/StaticDocViewHeader/StaticDocViewHeader";
import StaticDocViewTextContent from "../../../../components/StaticDocViewTextContent/StaticDocViewTextContent";
import StaticDocViewSubmitButton from "../../../../components/StaticDocViewSubmitButton/StaticDocViewSubmitButton";
import MetaView from "../../../../components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";

export default function DocumentNotFoundPage({ collection }: { collection: COLLECTION_TYPES }) {
  const navigate = useNavigate();
  const acknowledgeNavigate = useCallback(() => {
    switch (collection) {
      case COLLECTIONS.EXTERNAL_RECIPIENTS:
        navigate("/service-providers");
        break;
      case COLLECTIONS.PROCESSES:
        navigate("/processes");
        break;
      case COLLECTIONS.PROCESSOR_PAS:
        navigate("/processor-pas");
        break;

      case COLLECTIONS.TOM:
        navigate("/toms");
        break;

      case COLLECTIONS.TASKS:
        navigate("/tasks/my");
        break;

      case COLLECTIONS.DATA_SUBJECT_REQUESTS:
        navigate("/data-subject-requests");
        break;

      case COLLECTIONS.DATA_BREACHES:
        navigate("/data-breaches");
        break;

      case COLLECTIONS.RISK:
        navigate("/risks");
        break;

      case COLLECTIONS.ASSETS:
        navigate("/asset-management");
        break;
      case COLLECTIONS.AUDITS:
        navigate("/audits/instances");
        break;
      case COLLECTIONS.AUDIT_TEMPLATES:
        navigate("/audits/templates");
        break;
      case COLLECTIONS.ASSESSMENT_RESPONSE:
        navigate("/audits/answersets");
        break;
      case COLLECTIONS.TOKEN:
        navigate("/tokens");
        break;
    }
  }, [collection, navigate]);

  return (
    <DocMetaView metaViewContent={<MetaView />}>
      <DocumentNotFound onContinue={acknowledgeNavigate} />
    </DocMetaView>
  );
}

export function DocumentNotFound({ onContinue }: { onContinue: () => void }) {
  const { t } = useTranslation("documentNotFound");
  return (
    <StaticDocView icon={"ErrorOutline"} type={"error"}>
      <StaticDocViewHeader text={t("deletedInfoHeader")} />
      <StaticDocViewTextContent text={t(`deletedInfoText`)} parseHtml={true} />
      <StaticDocViewSubmitButton text={t("deletedAcknowledgeButtonText")} onClick={onContinue} />
    </StaticDocView>
  );
}
