import { useMemo } from "react";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useIsFeaturePresent } from "./useIsFeaturePresent";
import { useUserDepartments } from "app/contexts/department-context";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";

function useDocumentPermissions(item?: OverviewItem) {
  const { auth } = useAuthentication();

  const isFoldersEnabled = useIsFeaturePresent("documentCenterFolders");

  const userHasFoldersWritePermission = useMemo(
    () =>
      auth?.permissions?.some(
        permission => permission === "filestoragefolders_write_all" || permission === "filestoragefolders_write_org"
      ),
    [auth]
  );

  const userHasUploadPermission = useMemo(() => auth?.permissions?.includes("filestorage_write_all"), [auth]);

  const userOrgUnitIds = useMemo(() => (auth ? [auth?.orgUnitId, ...(auth?.furtherOrgUnitIds || [])] : []), [auth]);

  const { authUserSelectableDepartmentIds } = useUserDepartments();

  const folderInUserOrgUnit = useMemo(() => {
    const haveGlobalPermission = auth?.permissions.includes("filestoragefolders_write_all");
    if (haveGlobalPermission) {
      return true;
    }
    if (!item?.orgUnitIds || item.orgUnitIds.length === 0) {
      return false;
    }
    const itemHaveWildcard = item.orgUnitIds.includes("*");
    if (itemHaveWildcard) {
      return true;
    }
    return authUserSelectableDepartmentIds.some(
      authOrgUnitId => item?.orgUnitIds?.includes(authOrgUnitId) || item.orgUnitIds?.[0] === "*"
    );
  }, [authUserSelectableDepartmentIds, item?.orgUnitIds, auth?.permissions]);

  const canEditCurrentFolder = useMemo(() => {
    if (!auth?.permissions) {
      return false;
    }
    const haveGlobalPermission = auth.permissions.includes("filestoragefolders_write_all");
    if (haveGlobalPermission) {
      return true;
    }
    const haveOrgUnitPermission = auth.permissions.includes("filestoragefolders_write_org");
    if (!haveOrgUnitPermission) {
      return false;
    }
    return folderInUserOrgUnit;
  }, [auth?.permissions, folderInUserOrgUnit]);

  const hasDeletePermission = useMemo(() => {
    return !!auth?.permissions.includes("filestoragefolders_delete_org");
  }, [auth?.permissions]);

  const canDeleteCurrentFolder = useMemo(() => {
    if (!auth?.permissions) {
      return false;
    }
    if (!hasDeletePermission) {
      return false;
    }

    return folderInUserOrgUnit;
  }, [auth?.permissions, folderInUserOrgUnit, hasDeletePermission]);

  // if item has no org unit we can assume its a file in the root folder
  const itemHasNoOrgUnits = !item?.orgUnitIds;

  //
  // lets treat files permission same as folder ->  user can not edit files without parent folder permission
  //
  const canMoveFileOrFolder = useMemo(() => {
    return itemHasNoOrgUnits || canEditCurrentFolder;
  }, [canEditCurrentFolder, itemHasNoOrgUnits]);

  const canCreateFolder = useMemo(() => {
    return canEditCurrentFolder;
  }, [canEditCurrentFolder]);

  const canUploadFile = useMemo(() => {
    return canEditCurrentFolder;
  }, [canEditCurrentFolder]);

  const canRenameFileOrFolder = useMemo(() => {
    return itemHasNoOrgUnits || canEditCurrentFolder;
  }, [canEditCurrentFolder, itemHasNoOrgUnits]);

  const canDeleteFileOrFolder = useMemo(() => {
    return itemHasNoOrgUnits || canDeleteCurrentFolder;
  }, [canDeleteCurrentFolder, itemHasNoOrgUnits]);

  return {
    canEditCurrentFolder,
    isFoldersEnabled,
    userHasFoldersWritePermission,
    userHasUploadPermission,
    userOrgUnitIds,
    canMoveFileOrFolder,
    canCreateFolder,
    canUploadFile,
    canRenameFileOrFolder,
    canDeleteFileOrFolder
  };
}

export { useDocumentPermissions };
