import React, { useCallback, useMemo, useState } from "react";
import { Grid, Slider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TextEditor from "../../questionnaires/utils/TextEditor";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import QuestionTitle from "../../../../components/QuestionTitle/QuestionTitle";
import { RiskRatingAlert } from "./RiskRating";
import { RiskHeatMap } from "./RiskHeatMap";
import { useLocation } from "react-router-dom";
import Question from "components/Question/Question";

export const RiskAssessment = ({
  hideOccurrence,
  id,
  occurrenceId,
  damageExtendId,
  impactDescription,
  reasonDescription,
  riskRating,
  riskRatingIsLoading,
  onOccurrenceIdChange,
  onDamageExtendIdChange,
  onImpactDescriptionChange,
  onReasonDescriptionChange,
  disabled
}) => {
  const riskHeatMapContainer = (
    <Grid container justifyContent={"center"}>
      <RiskRatingAlert riskRating={riskRating} riskRatingIsLoading={riskRatingIsLoading} />
      <RiskHeatMap damageExtendId={damageExtendId} occurrenceId={occurrenceId} />
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={6} justifyContent="space-between">
          {!hideOccurrence && (
            <Grid item xs={6}>
              <OccurrenceAssessment
                occurrenceId={occurrenceId}
                defaultReasonDescription={reasonDescription}
                onOccurrenceIdChange={onOccurrenceIdChange}
                onReasonDescriptionChange={onReasonDescriptionChange}
                disabled={disabled}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <DamageExtendAssessment
              damageExtendId={damageExtendId}
              defaultImpactDescription={impactDescription}
              onDamageExtendIdChange={onDamageExtendIdChange}
              onImpactDescriptionChange={onImpactDescriptionChange}
              disabled={disabled}
            />
          </Grid>
          {hideOccurrence && (
            <Grid item xs={6}>
              <Grid container justifyContent={"center"}>
                {riskHeatMapContainer}
              </Grid>
            </Grid>
          )}
        </Grid>
        {!hideOccurrence && (
          <Grid item xs={12} style={{ marginTop: 22 }}>
            <Grid container justifyContent={"center"}>
              {riskHeatMapContainer}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

RiskAssessment.propTypes = {
  hideOccurrence: PropTypes.bool,
  id: PropTypes.string.isRequired,
  occurrenceId: PropTypes.string,
  damageExtendId: PropTypes.string,
  riskRating: PropTypes.number,
  riskRatingIsLoading: PropTypes.bool,
  impactDescription: PropTypes.string,
  reasonDescription: PropTypes.string,
  onOccurrenceIdChange: PropTypes.func,
  onDamageExtendIdChange: PropTypes.func,
  onImpactDescriptionChange: PropTypes.func,
  onReasonDescriptionChange: PropTypes.func,
  disabled: PropTypes.bool
};

RiskAssessment.defaultProps = {
  hideOccurrence: false,
  riskRating: null,
  riskRatingIsLoading: false,
  occurrenceId: null,
  damageExtendId: null,
  impactDescription: null,
  reasonDescription: null,
  onOccurrenceIdChange: () => {},
  onDamageExtendIdChange: () => {},
  onImpactDescriptionChange: () => {},
  onReasonDescriptionChange: () => {},
  disabled: false
};

const RainbowSlider = withStyles(theme => ({
  root: {
    height: 4,
    width: 300
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: theme.palette.primary.main,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {},
  mark: {
    height: 3,
    width: 3,
    borderRadius: 3,
    background: theme.palette.grey[500],
    '&[data-index="3"]': {
      transform: "translateX(-100%)"
    }
  },
  markLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
    '&[data-index="0"]': {
      transform: "none"
    },
    '&[data-index="3"]': {
      transform: "translateX(-100%)"
    }
  },
  markLabelActive: {
    color: theme.palette.common.black
  },
  track: {
    height: 4,
    borderRadius: 4,
    backgroundImage: "linear-gradient(45deg, #34A159 0px, #D1BB55 50px, #FFC354 100px, #FBA752 150px, #EC4D4C 250px)"
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundImage: "linear-gradient(45deg, #34A159 0px, #D1BB55 50px, #FFC354 100px, #FBA752 150px, #EC4D4C 250px)"
  }
}))(Slider);

export const useAssessmentResources = () => {
  const occurrences = useMemo(() => {
    const staticOccurrenceProbabilities = {
      "probability-low": 1,
      "probability-medium": 2,
      "probability-high": 3,
      "probability-veryHigh": 4
    };
    return Object.entries(staticOccurrenceProbabilities).map(([key, value]) => ({
      id: key,
      nameKey: key,
      value
    }));
  }, []);
  const damageExtends = useMemo(() => {
    const staticDamageExtends = {
      "damage-low": 1,
      "damage-medium": 2,
      "damage-high": 3,
      "damage-veryHigh": 4
    };
    return Object.entries(staticDamageExtends).map(([key, value]) => ({
      id: key,
      nameKey: key,
      value
    }));
  }, []);

  const findValueById = (id, items) => {
    const item = items.find(item => item.id === id);
    if (!item) {
      return null;
    }
    return item.value;
  };

  const findIdByValue = (value, items) => {
    const item = items.find(item => item.value === value);
    if (!item) {
      return null;
    }
    return item.id;
  };

  const occurrenceIdToValue = useCallback(
    occurrenceId => {
      return findValueById(occurrenceId, occurrences);
    },
    [occurrences]
  );

  const damageExtendIdToValue = useCallback(
    damageExtendId => {
      return findValueById(damageExtendId, damageExtends);
    },
    [damageExtends]
  );

  const occurrenceValueToId = useCallback(
    occurrenceValue => {
      return findIdByValue(occurrenceValue, occurrences);
    },
    [occurrences]
  );

  const damageExtendValueToId = useCallback(
    damageExtendValue => {
      return findIdByValue(damageExtendValue, damageExtends);
    },
    [damageExtends]
  );

  return {
    occurrences,
    damageExtends,
    occurrenceIdToValue,
    damageExtendIdToValue,
    occurrenceValueToId,
    damageExtendValueToId
  };
};

export const OccurrenceSlider = ({ occurrenceId, onOccurrenceIdChange, disabled }) => {
  const { t } = useTranslation("risk_assessment");
  const { occurrences, occurrenceIdToValue, occurrenceValueToId } = useAssessmentResources();
  const [uncontrolledInitialValue] = useState(occurrenceIdToValue(occurrenceId));

  const occurrenceMarks = useMemo(() => {
    return occurrences
      .map(occurrence => ({
        value: occurrence.value,
        label: t(`risk-probability:${occurrence.nameKey}`)
      }))
      .sort((a, b) => a.value - b.value);
  }, [t, occurrences]);

  const onChange = useCallback(
    (event, value) => {
      onOccurrenceIdChange(occurrenceValueToId(value));
    },
    [onOccurrenceIdChange, occurrenceValueToId]
  );

  return (
    <RainbowSlider
      data-testid="occurrenceSlider"
      marks={occurrenceMarks}
      min={1}
      max={4}
      defaultValue={uncontrolledInitialValue}
      onChangeCommitted={onChange}
      disabled={disabled}
    />
  );
};

OccurrenceSlider.propTypes = {
  occurrenceId: PropTypes.string,
  onOccurrenceIdChange: PropTypes.func,
  disabled: PropTypes.bool
};

OccurrenceSlider.defaultProps = {
  occurrenceId: null,
  onOccurrenceIdChange: () => {},
  disabled: false
};

export const DamageExtendSlider = ({ damageExtendId, onDamageExtendIdChange, disabled }) => {
  const { t } = useTranslation("risk_assessment");
  const { damageExtends, damageExtendIdToValue, damageExtendValueToId } = useAssessmentResources();
  const [uncontrolledInitialValue] = useState(damageExtendIdToValue(damageExtendId));

  const damageExtendMarks = useMemo(() => {
    return damageExtends
      .map(damageExtend => ({
        value: damageExtend.value,
        label: t(`risk-damage:${damageExtend.nameKey}`)
      }))
      .sort((a, b) => a.value - b.value);
  }, [t, damageExtends]);

  const onChange = useCallback(
    (event, value) => {
      onDamageExtendIdChange(damageExtendValueToId(value));
    },
    [onDamageExtendIdChange, damageExtendValueToId]
  );

  return (
    <RainbowSlider
      data-testid="damageExtendSlider"
      marks={damageExtendMarks}
      min={1}
      max={4}
      defaultValue={uncontrolledInitialValue}
      onChangeCommitted={onChange}
      disabled={disabled}
    />
  );
};

DamageExtendSlider.propTypes = {
  damageExtendId: PropTypes.string,
  onDamageExtendIdChange: PropTypes.func,
  disabled: PropTypes.bool
};

DamageExtendSlider.defaultProps = {
  damageExtendId: null,
  onDamageExtendIdChange: () => {},
  disabled: false
};

const useAssessmentStyles = makeStyles(() => ({
  relativeBoxForActionButton: {
    position: "relative"
  }
}));

export const OccurrenceAssessment = ({
  occurrenceId,
  onOccurrenceIdChange,
  defaultReasonDescription,
  onReasonDescriptionChange,
  disabled
}) => {
  const { t } = useTranslation("risk_assessment");
  const classes = useAssessmentStyles();
  const [internalReasonDescription, setInternalReasonDescription] = useState(defaultReasonDescription);
  const [focused, setFocused] = useState(false);
  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const probabilityOccurrenceInfoCard = useMemo(() => {
    return {
      title:
        path === "initial-assessment"
          ? t("risk_first_assessment_page:initialReasonDescriptionInfoTitle")
          : t("risk_second_assessment_page:finalReasonDescriptionInfoTitle"),
      text:
        path === "initial-assessment"
          ? t("risk_first_assessment_page:initialReasonDescriptionInfoCard")
          : t("risk_second_assessment_page:finalReasonDescriptionInfoCard")
    };
  }, [path, t]);

  const onReasonFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onReasonBlur = useCallback(() => {
    onReasonDescriptionChange(internalReasonDescription);
  }, [onReasonDescriptionChange, internalReasonDescription]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <QuestionTitle>{t("occurrence")}</QuestionTitle>
        <OccurrenceSlider occurrenceId={occurrenceId} onOccurrenceIdChange={onOccurrenceIdChange} disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.relativeBoxForActionButton}>
          <Question
            translatable={internalReasonDescription}
            translationId={"reasonDescription"}
            title={t("reasonDescription")}
            questionId={`reasonDescription-${path}`}
            questionName={t(`reasonDescription-${path}`)}
            info={probabilityOccurrenceInfoCard}
          >
            <TextEditor
              testId="reasonDescription"
              onBlur={onReasonBlur}
              onChange={setInternalReasonDescription}
              inputValue={internalReasonDescription}
              onFocus={onReasonFocus}
              toolbarHidden={!focused}
              disabled={disabled}
            />
          </Question>
        </div>
      </Grid>
    </Grid>
  );
};

OccurrenceAssessment.propTypes = {
  occurrenceId: PropTypes.string,
  onOccurrenceIdChange: PropTypes.func,
  defaultReasonDescription: PropTypes.string,
  onReasonDescriptionChange: PropTypes.func,
  disabled: PropTypes.bool
};

OccurrenceAssessment.defaultProps = {
  occurrenceId: null,
  defaultReasonDescription: null,
  onOccurrenceIdChange: () => {},
  onReasonDescriptionChange: () => {},
  disabled: false
};

export const DamageExtendAssessment = ({
  damageExtendId,
  onDamageExtendIdChange,
  defaultImpactDescription,
  onImpactDescriptionChange,
  disabled
}) => {
  const { t } = useTranslation("risk_assessment");
  const classes = useAssessmentStyles();
  const [internalImpactDescription, setInternalImpactDescription] = useState(defaultImpactDescription);
  const [focused, setFocused] = useState(false);
  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const probabilityOccurrenceInfoCard = useMemo(() => {
    return {
      title:
        path === "initial-assessment"
          ? t("risk_first_assessment_page:initialDamageExtentInfoTitle")
          : t("risk_second_assessment_page:finalDamageExtentInfoTitle"),
      text:
        path === "initial-assessment"
          ? t("risk_first_assessment_page:initialDamageExtentInfoCard")
          : t("risk_second_assessment_page:finalDamageExtentInfoCard")
    };
  }, [path, t]);

  const onDescriptionFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onDescriptionChange = useCallback(() => {
    onImpactDescriptionChange(internalImpactDescription);
  }, [onImpactDescriptionChange, internalImpactDescription]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <QuestionTitle>{t("damageExtend")}</QuestionTitle>
        <DamageExtendSlider
          damageExtendId={damageExtendId}
          onDamageExtendIdChange={onDamageExtendIdChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.relativeBoxForActionButton}>
          <Question
            translatable={internalImpactDescription}
            translationId={"impactDescription"}
            title={t("impactDescription")}
            questionId={`impactDescription-${path}`}
            questionName={t(`impactDescription-${path}`)}
            info={probabilityOccurrenceInfoCard}
          >
            <TextEditor
              testId="impactDescription"
              onBlur={onDescriptionChange}
              onChange={setInternalImpactDescription}
              inputValue={internalImpactDescription}
              onFocus={onDescriptionFocus}
              toolbarHidden={!focused}
              disabled={disabled}
            />
          </Question>
        </div>
      </Grid>
    </Grid>
  );
};

DamageExtendAssessment.propTypes = {
  damageExtendId: PropTypes.string,
  onDamageExtendIdChange: PropTypes.func,
  defaultImpactDescription: PropTypes.string,
  onImpactDescriptionChange: PropTypes.func,
  disabled: PropTypes.bool
};

DamageExtendAssessment.defaultProps = {
  damageExtendId: null,
  defaultImpactDescription: null,
  onDamageExtendIdChange: () => {},
  onImpactDescriptionChange: () => {},
  disabled: false
};
