export interface FlatDataType {
  readonly id: string;
  readonly dataTypeKey: string | null;
  readonly personGroupKey: string | null;
  readonly dataCategoryKey: string | null;
  readonly mergedInto: string | null;
  readonly dataClassificationId: string | null;
}

export const flattenDataTypes = (personGroupCategoryTypeList: any): FlatDataType[] => {
  if (!Array.isArray(personGroupCategoryTypeList)) {
    return [];
  }

  return personGroupCategoryTypeList.flatMap(personGroup =>
    personGroup.dataCategories.flatMap((dataCategory: any) =>
      dataCategory.dataTypes.map((dataType: any) => ({
        id: dataType.id,
        dataTypeKey: dataType.id || null,
        personGroupKey: personGroup.id || null,
        dataCategoryKey: dataCategory.id || null,
        mergedInto: dataType.mergedIntoId || null,
        dataClassificationId: dataType.dataClassificationId || null
      }))
    )
  );
};
