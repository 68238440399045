import { useCallback } from "react";
import overviewBaseController, {
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  getDataBreachesIncidentFilter,
  getDataBreachesStatusFilter,
  getDPOFilter,
  getLabelsFilter,
  getProcessTaskFilter
} from "app/pages/shared/Filters/filters";
import { tDeletedEntry } from "../../../handlers/dataTypeTranslatorHandler";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { AxiosInstance, AxiosResponse } from "axios";
import { usersDecorator } from "../../../../components/Overview/controllers/decorator/usersDecorator";
import { getOrganizationFilter } from "../../shared/Filters/OrganizationFilter";

const DataBreachesOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: {
    readonly exportToXLSX: (ids: string[]) => Promise<any>;
  }
) => {
  const { auth } = useAuthentication();
  const { t } = useTranslation();
  const { t: t_filter } = useTranslation("filter_criteria");
  const { t: t_data_breaches_general_page } = useTranslation("data_breaches_general_page");

  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.DATA_BREACHES, translateItem, [
    resourcesDecorator,
    departmentsDecorator,
    usersDecorator
  ]);

  const getFilters = useCallback(
    data => [
      getOrganizationFilter("allOrgUnitIds", data._departments, t, "department"),
      getOrganizationFilter("orgUnitId", data._departments, t, "affectedDepartment"),
      getOrganizationFilter("furtherOrgUnitIds", data._departments, t, "furtherDepartments"),
      getDataBreachesStatusFilter("status", t),
      getDataBreachesIncidentFilter("reportIncident", t),
      getLabelsFilter("labelIds", data._resources, t),
      getProcessTaskFilter("openTasks", t),
      ...(data.dpoUIDs ? [getDPOFilter("dpoUID", data._tenantUsers, data.dpoUIDs, t)] : []) // dpoUIDs is null if feature is not active
    ],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t_filter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t_filter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: t_filter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: t_filter("oldFirst")
      },
      {
        field: "detectedAt",
        type: "desc",
        label: `${t_data_breaches_general_page("detectedAt")} - ${t_filter("newFirst").toLowerCase()}`
      },
      {
        field: "detectedAt",
        type: "asc",
        label: `${t_data_breaches_general_page("detectedAt")} - ${t_filter("oldFirst").toLowerCase()}`
      }
    ],
    [t_filter, t_data_breaches_general_page]
  );

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/data-breaches/${id}/general`);
  };

  const addItem = async (data: OverviewNewItem): Promise<AxiosResponse> => {
    return await baseController.addItem({
      title: data?.title || createOverviewItemDefaultName("data_breaches"),
      assigneeUID: auth?.uid
    });
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, data);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await baseController.addItem({
      title: data?.title || createOverviewItemDefaultName("data_breaches"),
      assigneeUID: auth?.uid
    });
    goToItem(response.headers["x-resource-id"] || "");
  };

  const exportItems = async (format: string, ids: string[]) => {
    await collectionParams.exportToXLSX(ids);
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup);
    if (data) {
      const ids = data.allItems.map(({ id }) => id);
      await collectionParams.exportToXLSX(ids);
    }
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    addItemAndGo,
    exportItems,
    exportAllItems
  };
};

export default DataBreachesOverviewController;
