import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LanguageIcon from "@mui/icons-material/Language";
import { useAvailableLanguages } from "../../../../hook/useAvailableLanguages";
import { sidebarZIndex } from "./sidebarZIndex";

export const LanguageSideBar = ({
  language,
  sidebarState,
  onClicked
}: {
  readonly language: string;
  readonly sidebarState: boolean;
  readonly onClicked?: () => void;
}) => {
  const { t, i18n } = useTranslation("sidebar");
  const { availableLanguages } = useAvailableLanguages();

  const changeLanguage = useCallback(
    async lang => {
      await i18n.changeLanguage(lang);
      onClicked?.();
    },
    [i18n, onClicked]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    const currentTarget = event.currentTarget;
    setAnchorEl(anchorEl => (anchorEl ? null : currentTarget));
  }, []);

  useEffect(() => {
    setAnchorEl(null);
  }, [sidebarState, language]);

  const languageOptions = useMemo(
    () =>
      availableLanguages
        .map(l => ({ lang: l, translatedLang: t(l) }))
        .sort(({ translatedLang: lang1 }, { translatedLang: lang2 }) => lang1.localeCompare(lang2))
        .map(({ lang: l, translatedLang }) => (
          // eslint-disable-next-line react/jsx-no-bind
          <MenuItem key={l} data-testid={`button-translate-to-${l}`} onClick={() => changeLanguage(l)}>
            {translatedLang}
          </MenuItem>
        )),
    [t, changeLanguage, availableLanguages]
  );

  const clickAwayCallback = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <ListItemButton
        data-testid="translate-button"
        sx={{ marginBottom: 4, position: "relative" }}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ color: "grey.500", ml: 1 }}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={t("lng")} />
        <ArrowRightIcon />
      </ListItemButton>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="right-end" style={{ zIndex: sidebarZIndex + 1 }}>
        <ClickAwayListener onClickAway={clickAwayCallback}>
          <Paper>
            <MenuList
              id="composition-menu"
              aria-labelledby="composition-button"
              sx={{
                maxHeight: "80vh",
                overflowY: "auto"
              }}
            >
              {languageOptions}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default LanguageSideBar;
