import DocView from "../../../components/DocView/DocView";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AccountSettingsProfile from "./AccountSettingsProfile";
import AccountSettingsNotifications from "./AccountSettingsNotifications";
import AccountSettingsSecurity from "./AccountSettingsSecurity";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import useSWR from "swr";
import { getEnforcementInfoApi } from "../../api/user/userMeApi";
import Tabs, { TabProps } from "components/TabsLayout/Tabs";

export const AccountSettings = () => {
  const { t } = useTranslation("accountSettings");
  const { data: enforcementInfo } = useSWR("/me/enforcements", () => getEnforcementInfoApi());

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const tabs = useMemo(() => {
    return [
      {
        label: t("tabTitleProfile"),
        content: <AccountSettingsProfile />
      },
      {
        label: t("tabTitleNotifications"),
        content: <AccountSettingsNotifications />
      },
      !enforcementInfo?.ssoOnly && {
        label: t("tabTitleSecurity"),
        content: <AccountSettingsSecurity />
      }
    ].filter(notNull => notNull) as TabProps[];
  }, [enforcementInfo?.ssoOnly, t]);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey="accountSettings" />}>
      <DocView header={t("title")}>
        {enforcementInfo && (
          <>
            <Tabs tabs={tabs} selected={selectedTabIndex} onTabChange={setSelectedTabIndex} />
          </>
        )}
      </DocView>
    </DocMetaView>
  );
};
