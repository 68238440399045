import React, { useCallback } from "react";
import Box from "@material-ui/core/Box";
import Sidebar from "../Sidebar/Sidebar";
import { WhereIAmContextProvider } from "../../../contexts/where-i-am-context";
import { ResourceProvider, useResources } from "../../../contexts/resource-context";
import { useSidebarSWR } from "../Sidebar/useSidebarUnseen";
import { ExternalRecipientProvider } from "../../../contexts/external-recipient-context";

/**
 *This is the root component of the application
 */
export default function PageTemplate({ children }: { readonly children: React.ReactNode }) {
  return (
    <Box height="100%" overflow="hidden" pt={3}>
      <ResourceProvider>
        <ExternalRecipientProvider>
          <Sidebar />
          <WhereIAmContextProvider>{children}</WhereIAmContextProvider>
        </ExternalRecipientProvider>
      </ResourceProvider>
    </Box>
  );
}

/**
 * reloads all context in {@see PageTemplate}
 */
export const usePageContextRefresher = () => {
  const { refreshResources } = useResources();
  const { sidebarNewItemsMutate } = useSidebarSWR();

  return useCallback(() => {
    return Promise.all([refreshResources(), sidebarNewItemsMutate()]);
  }, [refreshResources, sidebarNewItemsMutate]);
};
