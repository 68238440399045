import { createTheme as createMUI4Theme } from "@material-ui/core/styles";
import { createTheme as createMUI5Theme } from "@mui/material/styles";

import palette from "./palette/palette";
import typography from "./typography/typography";
import overrides from "./overrides/overrides";
import shape from "./shapes/shape";
import colors from "./palette/colors";
import breakpoints from "./breakpoints/breakpoints";
import { suisseIntlFonts } from "./fonts/fonts";

declare module "@material-ui/core/styles" {
  interface Theme {
    colors: typeof colors;
  }

  interface ThemeOptions {
    colors: typeof colors;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    colors: typeof colors;
  }

  interface ThemeOptions {
    colors: typeof colors;
  }
}

export default createMUI4Theme({
  colors,
  palette,
  typography,
  overrides,
  breakpoints,
  shape
});

export const mui5Theme = createMUI5Theme({
  colors,
  palette,
  typography,
  breakpoints,
  shape,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${suisseIntlFonts.forEach(
          font => `@font-face {
              font-family: '${font.fontFamily}';
              font-style: ${font.fontStyle};
              font-weight: ${font.fontWeight};
              src: ${font.src};
              unicode-range: ${font.unicodeRange};
            }`
        )}
      `
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: colors.grey.grey400
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "SuisseIntl"
        }
      }
    }
  }
});
