import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import { ProcessesOverviewReadOnly } from "../../processes/overview/ProcessesOverviewReadOnly";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";

const AssetsProcessesOverview = ({ assetId }: { assetId: string }) => {
  const { t } = useTranslation("");
  const { setInfo, setMeta } = useMetaView();
  const [hoveredPaId, setHoveredPaId] = useState<string | null>(null);
  const filter = useMemo(() => ({ assetIDs: [assetId] }), [assetId]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("asset_details:enteringInfoCardTitle"),
        text: t("asset_details:enteringInfoCardTextProcessView")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const handleRowOver = useCallback(
    item => {
      if (hoveredPaId === item.id) {
        return;
      }
      setHoveredPaId(item.id);
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} separator={undefined} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("processes_overview:status_" + item.status)
      });
    },
    [hoveredPaId, setMeta, t]
  );

  return <ProcessesOverviewReadOnly filter={filter} onRowOver={handleRowOver} />;
};

export default AssetsProcessesOverview;
