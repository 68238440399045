import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import CustomAlert from "components/CustomAlert/CustomAlert";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { CSSProperties } from "react";

const ConfirmationDialog = ({
  open,
  onCancel,
  onConfirm,
  title,
  text
}: {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly title: string;
  readonly text: string;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent style={minWidth}>
        <CustomAlert severity={"info"}>{title}</CustomAlert>
        <Box mt={4}>{parse(DOMPurify.sanitize(text))}</Box>
      </DialogContent>
      <DialogActions>
        <Box mr={2} mb={2} display={"flex"}>
          <Button onClick={onCancel}>{t("common:cancel")}</Button>
          <Box mx={1} />
          <Button onClick={onConfirm} color="primary" variant="contained">
            {t("common:confirm")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const minWidth: CSSProperties = {
  minWidth: 600
};

export default ConfirmationDialog;
