import { useCallback, useMemo, useState } from "react";
import useSWR from "swr";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import {
  DefaultApi,
  GetProcessing200Response,
  ImpactedAssetDTO,
  UpdateAssetIdsDTO,
  UpdateDescriptionDTO,
  UpdatePersonGroupDTO,
  UpdatePurposeDTO
} from "../generated/process-service";
import { retryUntil } from "../../handlers/utility/retry";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const useProcessing = (args: { documentId: string }) => {
  const responseProcessing = useSWR(args.documentId ? "processing_" + args.documentId : null, async () => {
    return processClient.getProcessing(args.documentId).then(resp => resp.data);
  });

  const { mutate } = responseProcessing;

  const emptyProcessData: GetProcessing200Response = useMemo(
    () => ({
      processMeta: { id: args.documentId, status: "", relativeOrgUnitIds: [], assignUserIds: [], title: "" },
      processPage: {
        description: "",
        assetIds: [],
        purposes: [],
        personGroups: []
      }
    }),
    [args.documentId]
  );

  const [isImpactedAssetsCreating, setIsImpactedAssetsCreating] = useState<boolean>(false);
  const [isPersonGroupCreating, setIsPersonGroupCreating] = useState<boolean>(false);
  const [isPurposeCreating, setIsPurposeCreating] = useState<boolean>(false);

  const [createdNewImpactedAssetsIds, setCreatedNewImpactedAssetsIds] = useState<string[]>([]);
  const [createdNewPersonGroupIds, setCreatedNewPersonGroupIds] = useState<string[]>([]);
  const [createdNewPurposeIds, setCreatedNewPurposeIds] = useState<string[]>([]);

  const updateDescription = useCallback(
    async (processID: string, payload: UpdateDescriptionDTO) => {
      await mutate(async data => {
        await processClient.updateDescription(args.documentId, payload);
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const updateAssetIds = useCallback(
    async (processID: string, payload: UpdateAssetIdsDTO) => {
      await mutate(async data => {
        await processClient.updateAssetIds(args.documentId, payload);
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const createPurpose = useCallback(
    async (processID: string, purposeId?: string) => {
      setIsPurposeCreating(true);
      await mutate(async data => {
        await processClient.createPurpose(args.documentId, { id: purposeId });
        setCreatedNewPurposeIds(createdNewPurposeIds => createdNewPurposeIds.concat([purposeId || ""]));
        setIsPurposeCreating(false);
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const updatePurpose = useCallback(
    async (processID: string, purposeId: string, updatedPurpose: UpdatePurposeDTO) => {
      await mutate(async data => {
        await processClient.updatePurpose(args.documentId, purposeId, updatedPurpose);
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const deletePurpose = useCallback(
    async (processID: string, purposeId: string) => {
      await mutate(
        async data => {
          await processClient.deletePurpose(args.documentId, purposeId);
          return data;
        },
        {
          populateCache: false,
          revalidate: false,
          optimisticData(currentData) {
            if (!currentData) {
              return emptyProcessData;
            }

            const newPurposes = currentData.processPage.purposes.filter(purpose => purpose.id !== purposeId);
            return {
              ...currentData,
              processPage: {
                ...currentData?.processPage,
                purposes: newPurposes
              }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate]
  );

  const createPersonGroup = useCallback(
    async (processID: string, personGroupId?: string) => {
      setIsPersonGroupCreating(true);
      await mutate(async data => {
        await processClient.createPersonGroup(args.documentId, { id: personGroupId });
        setCreatedNewPersonGroupIds(createdNewPersonGroupIds => createdNewPersonGroupIds.concat([personGroupId || ""]));
        setIsPersonGroupCreating(false);
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const updatePersonGroup = useCallback(
    async (processID: string, purposeId: string, updatedPersonGroup: UpdatePersonGroupDTO) => {
      await mutate(async data => {
        const timeoutMs = 10000;
        const intervalMs = 1000;
        // on new data type creation, pa would need sometime to register it
        await retryUntil(
          async () => {
            await processClient.updatePersonGroup(args.documentId, purposeId, updatedPersonGroup);
          },
          timeoutMs,
          intervalMs
        );
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const deletePersonGroup = useCallback(
    async (processID: string, personGroupId: string) => {
      await mutate(
        async data => {
          await processClient.deletePersonGroup(args.documentId, personGroupId);
          return data;
        },
        {
          populateCache: false,
          revalidate: false,
          optimisticData(currentData) {
            if (!currentData) {
              return emptyProcessData;
            }

            const newPersonGroups = currentData.processPage.personGroups.filter(
              personGroup => personGroup.id !== personGroupId
            );
            return {
              ...currentData,
              processPage: {
                ...currentData?.processPage,
                personGroups: newPersonGroups
              }
            };
          }
        }
      );
    },
    [args.documentId, emptyProcessData, mutate]
  );

  const createImpactedAsset = useCallback(async () => {
    let impactedAssetId = "";
    await mutate(async data => {
      const res = await processClient.addImpactedAsset(args.documentId);
      impactedAssetId = res.headers["x-resource-id"];
      setCreatedNewImpactedAssetsIds(createdNewAiAssetIds => createdNewAiAssetIds.concat([impactedAssetId || ""]));
      setIsImpactedAssetsCreating(false);
      return data;
    });
    return impactedAssetId;
  }, [args.documentId, mutate]);

  const updateImpactedAsset = useCallback(
    async (processID: string, accordionId: string, updatedImpactedAsset: ImpactedAssetDTO) => {
      const optimisticUpdate = (currentData: GetProcessing200Response) => {
        if (!currentData) {
          return emptyProcessData;
        }

        const newImpactedAssets =
          currentData.processPage.impactedAssets?.map(impactedAsset => {
            if (impactedAsset.id === accordionId) {
              return { ...impactedAsset, ...updatedImpactedAsset };
            }
            return impactedAsset;
          }) || [];

        return {
          ...currentData,
          processPage: {
            ...currentData.processPage,
            impactedAssets: newImpactedAssets
          }
        };
      };

      await mutate(
        async data => {
          await processClient.updateImpactedAsset(args.documentId, accordionId, updatedImpactedAsset);
          return data ? optimisticUpdate(data) : data;
        },
        {
          optimisticData: data => (data ? optimisticUpdate(data) : emptyProcessData)
        }
      );
    },
    [args.documentId, emptyProcessData, mutate]
  );

  const deleteImpactedAsset = useCallback(
    async (processID: string, accordionId: string) => {
      await mutate(async data => {
        await processClient.deleteImpactedAsset(args.documentId, accordionId);
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const actions = useMemo(
    () => ({
      createImpactedAsset: createImpactedAsset,
      createPersonGroup: createPersonGroup,
      createPurpose: createPurpose,
      deleteImpactedAsset: deleteImpactedAsset,
      deletePersonGroup: deletePersonGroup,
      deletePurpose: deletePurpose,
      updateAssetIds: updateAssetIds,
      updateDescription: updateDescription,
      updateImpactedAsset: updateImpactedAsset,
      updatePersonGroup: updatePersonGroup,
      updatePurpose: updatePurpose
    }),
    [
      createImpactedAsset,
      createPersonGroup,
      createPurpose,
      deleteImpactedAsset,
      deletePersonGroup,
      deletePurpose,
      updateAssetIds,
      updateDescription,
      updateImpactedAsset,
      updatePersonGroup,
      updatePurpose
    ]
  );

  return {
    ...responseProcessing,
    actions: actions,
    createdNewImpactedAssetsIds,
    createdNewPersonGroupIds,
    createdNewPurposeIds,
    isImpactedAssetsCreating,
    isLoading: responseProcessing.isValidating && responseProcessing.data === null,
    isPersonGroupCreating: isPersonGroupCreating,
    isPurposeCreating: isPurposeCreating
  };
};
