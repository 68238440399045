import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

interface TextBody1Props {
  readonly text: string;
  readonly translationKey: string;
}
const TextBody1 = ({ text, translationKey }: TextBody1Props) => {
  const { t } = useTranslation(translationKey);
  return <Typography variant="body1">{translationKey ? t(text) : text}</Typography>;
};

export default TextBody1;
