import { defaultAxios } from "app/api/axios/axiosProvider";
import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";

export const axiosInstance: AxiosInstance = defaultAxios({
  baseURL: `${apiEndpoints.userUrl}/api/public/resets`
});

export const triggerResetPasswordApi = async ({ email }: { email: string }): Promise<void> => {
  await axiosInstance.post("/triggers/passwords", {
    email
  });
};

export const resetPasswordApi = async ({
  token,
  newPassword
}: {
  token: string;
  newPassword: string;
}): Promise<void> => {
  await axiosInstance.post("/passwords", {
    token: token,
    newPassword: newPassword,
    initial: false
  });
};

export const setInitialPasswordApi = async ({
  token,
  newPassword
}: {
  token: string;
  newPassword: string;
}): Promise<void> => {
  await axiosInstance.post("/passwords", {
    token: token,
    newPassword: newPassword,
    initial: true
  });
};
